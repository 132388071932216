import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, useParams, withRouter } from "react-router-dom";
import {
  DeleteAllNotification,
  DeleteAllSessionsService,
  DeleteNotification,
  DeleteSessionService,
  getNotifications,
  getToriChatSessions,
  GetUserData,
  isAuthenticated,
  MarkAllNotificationRead,
  MarkAsReadNotification,
  UpdateChatSetting,
} from "../../Services/Service";
import logo from "../../assets/torisidebar_logo.svg";
import toriLogoRed from "../../assets/torilogoredhair.svg";
import OverviewLogo from "../../assets/ic_analytics.svg";
import HomeLogo from "../../assets/homelogo.png";
import brandCompilance from "../../assets/brandcompilance.png";
import NewScriptLogo from "../../assets/ic_edit.svg";
import InoviceLogo from "../../assets/ic_invoice.svg";
import UserProfileLogo from "../../assets/ic_user.svg";
import notifcationpending from "../../assets/notifcationpending.png";
import { HiBell } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { logOut } from "../../Services/Service";
import { ShimmerCircularImage, ShimmerBadge } from "react-shimmer-effects";
import "./Style.css";
import { Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { FiClock } from "react-icons/fi";
import { AiOutlineDelete, AiFillDelete } from "react-icons/ai";
import { SlEnvelopeOpen } from "react-icons/sl";
import { IoCheckmarkDone } from "react-icons/io5";
import { VscClearAll } from "react-icons/vsc";
import { BsChatLeftDots } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { generateKey, onMessageListener } from "../../Services/firebaseInit";
import { Avatar, Tooltip } from "antd";
import { Accordion } from "react-bootstrap";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import {
  useDeleteToriChatSessionMutation,
  useGetToriChatSessionsQuery,
} from "../../../Redux/Slices";
import chatSettingContext from "../../../context/ChatSettingContext";
import { AiFillSetting } from 'react-icons/ai';
import DisplayModal from "../../Screens/CreateDraft/Components/Modal";


function SideBar(props) {
  const [accessType, setAccessType] = useState("");
  const { updateChatSetting, getUserDetailContext } = useContext(chatSettingContext);

  // const fetchData = useSelector((state) => state);
  const { data: fetchData } = useGetToriChatSessionsQuery();
  const [deleteId, { data: deleteData, isLoading: LoadingChat }] =
    useDeleteToriChatSessionMutation();

  useEffect(() => {
    if (deleteData?.status) {
      history.push("/user/tori-chat/help", { from: "tori-help" });
    } else {
      // history.push("/user/tori-chat/demostore", { from: 'demostore' })
    }
  }, [deleteData]);

  const [data, setData] = useState();
  const [notificationsData, setNotificationsData] = useState();
  const [loader, setLoader] = useState(true);
  const [userdata, setuserData] = useState();
  const [unreadNotification, setunreadNotification] = useState();
  const [sessionData, setSessionData] = useState([]);
  const [clickedIndex, setClickedIndex] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(true);
  const [alternateLinks, setAlternateLinks] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const [customModalMessage, setCustomModalMessage] = useState("Given the focus on streamlining AI activities at SAP, the strategic direction moving forward is to transition these capabilities the SIX organization who will be assessing how to bring these capabilities into the tools they offer.\n TORI Chat will be unsupported as of March 15, 2024.\nTori will be switched off starting on Dec 1st 2024\n\nPlease save your work if necessary as Tori will not be available after that date");

  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateSidebar = () => {
    getUserDetailContext().then((res) => {
      if (res.status) {
        setAlternateLinks(res?.data?.chat_settings?.alternate_links)
        setShowMore(res?.data?.chat_settings?.show_more)
        localStorage.setItem("userData", JSON.stringify(res.data));
        localStorage.setItem("chatSetting", JSON.stringify(res.data?.chat_settings));
        setuserData(res.data);
      }
    });
  };

  useEffect(() => {
    generateKey().then((token) => {
      if (token) {
        localStorage.setItem("FCMToken", token);
      }
    });

    getUserDetailContext().then((res) => {
      if (res.status) {
        // setAlternateLinks(res?.data?.chat_settings?.alternate_links)
        // setShowMore(res?.data?.chat_settings?.show_more)
        // localStorage.setItem("chatSetting", JSON.stringify(res.data?.chat_settings));
        setData(res.data);
        setLoader(false);
      }
    });

    updateSidebar();
  }, []);


  const handleChatSetting = (e) => {
    setCustomModal(true)
    return
    const { name, checked } = e.target;

    const updatedShowMore = name === "showMore" ? checked : showMore;
    const updatedAlternateLinks = name === "alternativeLinks" ? checked : alternateLinks;

    const data = {
      show_more: updatedShowMore ? 1 : 0,
      alternate_links: updatedAlternateLinks ? 1 : 0,
    };

    Promise.all([updateChatSetting(data), updateChatSetting(data)])
      .then(([res1, res2]) => {
        console.log("🚀 ~ file: SideBar.js:139 ~ .then ~ res2:", res2)
        console.log("🚀 ~ file: SideBar.js:139 ~ .then ~ res1:", res1)
        setAlternateLinks(res1?.data?.alternate_links);
        setShowMore(res2?.data?.show_more);
        localStorage.setItem("chatSetting", JSON.stringify(res1.data));
        localStorage.setItem("chatSetting", JSON.stringify(res2.data));
      })
      .catch((err) => console.log(err));

    if (name === "showMore") {
      setShowMore(updatedShowMore);
    } else if (name === "alternativeLinks") {
      setAlternateLinks(updatedAlternateLinks);
    }
  };


  // useLayoutEffect(() => {
  //   getToriChatSessions().then((res) => {
  //     if (res.status) {
  //       setSessionData(res.data);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (fetchData) {
      setSessionData(fetchData?.data);
    }
  }, [fetchData]);

  useEffect(() => {
    getNotifications().then((res) => {
      if (res.status) {
        setNotificationsData(res.data);
        setunreadNotification(res.unread);
      }
    });
  }, [props.unreadNotifications]);

  const logOutPress = () => {
    logOut();
  };
  const DeteleNotificationHandler = (data, index) => {
    try {
      notificationsData && notificationsData.splice(index, 1);
      setNotificationsData([...notificationsData]);
      if (data.status === 0) {
        setunreadNotification(
          unreadNotification > 1 ? unreadNotification - 1 : 0
        );
      }
      let id = data?.id;
      DeleteNotification(id).then((res) => {
        if (res.status) {
          getNotifications().then((res) => {
            if (res.status) {
              setNotificationsData(res.data);
              setunreadNotification(res.unread);
            }
          });
        }
      });
    } catch (error) { }
  };
  const ReadNotificationHandler = (data, index, type) => {
    try {
      let items = [...notificationsData];
      let item = { ...items[index] };
      item.status = 1;
      items[index] = item;
      setNotificationsData(items);
      if (type === "delete") {
        setunreadNotification(unreadNotification > 1 ? unreadNotification : 0);
      } else {
        setunreadNotification(
          unreadNotification > 1 ? unreadNotification - 1 : 0
        );
      }

      let id = data?.id;
      MarkAsReadNotification(id).then((res) => { });
    } catch (error) { }
  };
  const NotificationClickHandler = (type, id, isScriptProvided) => {
    if (isScriptProvided == 1) {
      history.push(`/draft/scriptprovided/${id}`, {
        from: "notification",
      });
    } else {
      history.push(`/draft/${type.toLowerCase()}/${id}`, {
        from: "notification",
      });
    }
    if (userdata.role == "Admin" && type == "user") {
      history.push("/user/admin", { from: "notification" });
    }
  };
  const markAllNotificationread = () => {
    try {
      MarkAllNotificationRead().then((res) => {
        if (res.status) {
          getNotifications().then((res) => {
            if (res.status) {
              setNotificationsData(res.data);
              setunreadNotification(res.unread);
            }
          });
        }
      });
    } catch (er) { }
  };
  const clearAllNotification = () => {
    try {
      DeleteAllNotification().then((res) => {
        if (res.status) {
          getNotifications().then((res) => {
            if (res.status) {
              setNotificationsData(res.data);
              setunreadNotification(res.unread);
            }
          });
        }
      });
    } catch (er) { }
  };

  const DeleteSession = (id) => {
    deleteId(id);
  };

  const chatSessionNavigate = (session, path) => {
    setCustomModal(true)
    return
    setClickedIndex(session?.id);
    setDeleteConfirm(false);

    if (session?.model === "vector_store") {
      history.push(`/user/tori-chat/vectorstore/${session?.id}`, {
        from: "vector_store_session",
      });
    }

    if (session?.model === "tori_chat") {
      history.push(`/user/tori-chat/chat/${session?.id}`, {
        from: "tori_chat_session",
      });
    }

    if (session?.model === "tori_help") {
      history.push(`/user/tori-chat/torihelp/${session?.id}`, {
        from: "tori_help_session",
      });
    }

    if (session?.model === "demo") {
      history.push(`/user/tori-chat/demostore/${session?.id}`, {
        from: "demostore_session",
      });
    }
    if (session?.model === "sap") {
      history.push(`/user/tori-chat/sapwebsite/${session?.id}`, {
        from: "sap_session",
      });
    }
  };

  const ClearConversation = () => {
    setCustomModal(true)
    return
    DeleteAllSessionsService().then((res) => {
      getToriChatSessions().then((res) => {
        if (res.status) {
          setSessionData(res.data);
          history.push("/user/tori-chat/help", { from: "tori-help" });
        }
      });
    });
  };
  const userRole = localStorage.getItem("userRole");
  const userData = JSON.parse(localStorage.getItem("userData"));
  return isAuthenticated() ? (
    userRole == "Chat User" ? (
      <div>
        <div id="wrapper" class="active">
          <div id="sidebar-wrapper">
            <ul id="sidebar_menu" class="sidebar-nav">
              <li class="sidebar-brand" style={{ marginTop: "29px" }}>
                <img src={logo} />

                <span
                  style={{ display: "none" }}
                  id="main_icon"
                  class="glyphicon glyphicon-align-justify"
                ></span>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#F8FAFC",
                borderRadius: 10,
                paddingInline: 15,
                paddingBlock: 24,
                marginTop: 95,
                marginLeft: 5,
                marginRight: 15,
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {localStorage.getItem("userName")}
              </span>
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 18,
                marginRight: 15,
                marginBottom: -22,
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: 22 }}>TORI-CHAT</p>
            </div>
            <ul class="sidebar-nav" id="sidebar">
              <li>
                <Accordion
                  // defaultActiveKey="0"
                  className={` remove-accordian-focus`}
                  style={{ border: "none" }}
                >
                  <Accordion.Item eventKey="0" style={{ border: "none" }}>
                    <Accordion.Header>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                          border: "none",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={NewScriptLogo} />
                        </span>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          Open Chat
                        </span>
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body style={{ border: "none" }}>
                      <div
                        onClick={() =>
                          setCustomModal(true)
                          // history.push("/user/tori-chat/help", {
                          //   from: "tori-help",
                          // })
                        }
                        className={`chat-beta w3-bar-item w3-button ${isActive(
                          history,
                          "/user/tori-chat/help"
                        )}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "2px",
                          border: "none",
                          paddingLeft: "14px",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={NewScriptLogo} />
                        </span>
                        <span style={{ fontSize: 13 }}>SAP S/4HANA Help</span>
                      </div>

                      {/* {userData?.role === "Admin" ||
                      userData?.email === "girish.kini@sap.com" ||
                      userData?.email === "tim.hatcher@sap.com" ||
                      userData?.email === "marcel.lindsay@sap.com" ||
                      userData?.email === "ana.vibancos@sap.com" ||
                      userData?.email === "jorge.ivan.arellano@sap.com" ||
                      userData?.email === "philip.transfeld@sap.com" ||
                      userData?.email === "murali.reddygari@sap.com" ||
                      userData?.email === "tr.smith@sap.com" ||
                      userData?.email === "testuserqa@sap.com" ||
                      userData?.email === "michele.krom@sap.com" ? (*/}
                      {userData?.role === "Admin" ? (
                        <div
                          onClick={() =>
                            setCustomModal(true)
                            // history.push("/user/tori-chat/vectorstore", {
                            //   from: "vector-store",
                            // })
                          }
                          className={`chat-beta w3-bar-item w3-button ${isActive(
                            history,
                            "/user/tori-chat/vectorstore"
                          )}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            border: "none",
                            paddingLeft: "14px",
                          }}
                        >
                          <span>
                            <img width={22} height={22} src={NewScriptLogo} />
                          </span>
                          <span style={{ fontSize: 13 }}>Tori Chat</span>
                        </div>
                      ) : null}
                      {/* ) : null}*/}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" style={{ border: "none" }}>
                    <Accordion.Header>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                          border: "none",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={InoviceLogo} />
                        </span>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          My Chat Sessions
                        </span>
                        <div style={{ paddingRight: 5 }}>
                          {/* {loader ? (
                      <div className="topSY">
                        <ShimmerCircularImage size={20} />
                      </div>
                    ) : (
                      <div className="count">
                        <span style={{ fontSize: 14 }} className="font_count">
                          {sessionData&& sessionData.length}
                        </span>
                      </div>
                    )} */}
                        </div>
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body style={{ border: "none" }}>
                      {sessionData?.map((session) => (
                        <div
                          key={session?.id}
                          className={`chat-beta w3-bar-item w3-button ${isActive(
                            history,
                            `/user/tori-chat/${session.model === "demo"
                              ? "demostore"
                              : session.model === "sap"
                                ? "sapwebsite"
                                : session.model === "vector_store"
                                  ? "vectorstore"
                                  : session.model === "tori_help"
                                    ? "torihelp"
                                    : "chat"
                            }/${session?.id}`
                          )}`}
                          onClick={() => chatSessionNavigate(session)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            border: "none",
                            paddingLeft: "14px",
                          }}
                        >
                          <span className="col-2">
                            <BsChatLeftDots fontSize={18} color="#637381" />
                          </span>
                          <span className="col-7" style={{ fontSize: 14 }}>
                            {session?.name}
                          </span>
                          {clickedIndex === session?.id ? (
                            // <BsChatLeftDots fontSize={18} color="#637381" /> :
                            <span
                              className="col-2"
                              onClick={() => DeleteSession(session?.id)}
                            >
                              <AiOutlineDelete fontSize={18} color="#637381" />
                            </span>
                          ) : null}
                        </div>
                      ))}
                      <Divider />

                      <div className="clearsessionbtn">
                        <Stack
                          onClick={ClearConversation}
                          paddingBlock={1}
                          // style={{ border: "1px solid black", borderRadius: "5px" }}
                          spacing={-1}
                          paddingLeft={"14px"}
                          direction="row"
                        >
                          <AiFillDelete fontSize={20} color="#637381" />
                          <Typography fontSize={14}>
                            Clear Conversation
                          </Typography>
                        </Stack>
                      </div>
                    </Accordion.Body>

                    {/*Chat Setting*/}
                    <Accordion.Item eventKey="2" style={{ border: "none" }}>
                      <Accordion.Header>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "14px",
                            border: "none",
                          }}
                        >
                          <span>
                            <AiFillSetting size={22} color="#637381" />
                          </span>
                          <span style={{ fontSize: 14, fontWeight: 400 }}>
                            Chat Settings
                          </span>
                          <div style={{ paddingRight: 5 }}></div>
                        </Stack>
                      </Accordion.Header>
                      <Accordion.Body style={{ border: "none" }}>
                        {/* Alternative Links Checkbox */}
                        <div style={{ display: "flex", alignItems: "center", padding: "2px", border: "none", paddingLeft: "25px" }}>
                          <input type="checkbox" id="alternativeLinks" name="alternativeLinks" checked={alternateLinks} onChange={handleChatSetting} />
                          <label htmlFor="alternativeLinks" style={{ fontWeight: 400, width: "160px" }}>More links sidebar</label>
                        </div>

                        {/* Show More Checkbox */}
                        <div style={{ display: "flex", alignItems: "center", padding: "2px", border: "none", paddingLeft: "25px" }}>
                          <input type="checkbox" id="showMore" name="showMore" checked={showMore} onChange={handleChatSetting} />
                          <label htmlFor="showMore" style={{ fontWeight: 400, width: "150px" }}>Show More</label>
                        </div>

                      </Accordion.Body>

                    </Accordion.Item>
                  </Accordion.Item>


                </Accordion>
              </li>
              <li>
                <Link
                  className={"w3-bar-item w3-button"}
                  style={{ marginTop: 0 }}
                ></Link>
              </li>
            </ul>

            <ul class="sidebar-nav">
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/profile"
                  )}`}
                  to="/user/profile"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={UserProfileLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>Profile</span>
                  </div>
                </Link>
              </li>

              <li onClick={logOutPress} style={{ cursor: "pointer" }}>
                <a style={{ fontSize: 14 }}>Log out</a>
              </li>
            </ul>
          </div>
          {/* <!-- Page content --> */}
          <div id="page-content-wrapper">
            <div class="page-content inset">
              <div class="row">
                <div class="col-md-12">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div id="wrapper" class="active">
          <div id="sidebar-wrapper">
            <ul id="sidebar_menu" class="sidebar-nav">
              <li class="sidebar-brand">
                <a id="menu-toggle" href="#">
                  <Link to="/user/welcome">
                    <img src={logo} />
                  </Link>
                  <span
                    style={{ display: "none" }}
                    id="main_icon"
                    class="glyphicon glyphicon-align-justify"
                  ></span>
                </a>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#F8FAFC",
                borderRadius: 10,
                paddingInline: 15,
                paddingBlock: 24,
                marginTop: 95,
                marginLeft: 5,
                marginRight: 15,
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                {localStorage.getItem("userName")}
              </span>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "25vw",
                    borderRadius: 11,
                    paddingBlock: 6,
                  },
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 170,
                      width: 15,
                      height: 15,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <div
                  className={"notifications-header"}
                  style={{ paddingInline: 0, width: "25vw" }}
                >
                  <div>
                    <div
                      style={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#212B36",
                        }}
                      >
                        Notifications
                      </p>
                      <div className="notification_clear_mark_all">
                        <div
                          style={{
                            fontSize: 13,
                            color: "#637381",
                          }}
                        >
                          You have {unreadNotification} unread Requests
                        </div>
                        <div className="notification_btn_contaniner">
                          <div>
                            {unreadNotification > 0 && (
                              <Tooltip
                                color="#87d068"
                                zIndex={9999999}
                                autoAdjustOverflow={true}
                                title={"Mark all as read"}
                                placement={"topRight"}
                                overlayStyle={{ fontSize: "10px" }}
                                overlayInnerStyle={{ padding: "5px" }}
                                arrowPointAtCenter={true}
                              >
                                <IoCheckmarkDone
                                  cursor={"pointer"}
                                  fontSize={20}
                                  color="#919EAB"
                                  onClick={markAllNotificationread}
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div>
                            {notificationsData &&
                              notificationsData.length > 0 && (
                                <Tooltip
                                  color="#87d068"
                                  zIndex={9999999}
                                  autoAdjustOverflow={true}
                                  title={"Clear all"}
                                  placement={"topRight"}
                                  overlayStyle={{ fontSize: "10px" }}
                                  overlayInnerStyle={{ padding: "5px" }}
                                  arrowPointAtCenter={true}
                                >
                                  <VscClearAll
                                    cursor={"pointer"}
                                    fontSize={20}
                                    color="#919EAB"
                                    onClick={clearAllNotification}
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid  rgba(145, 158, 171, 0.24)",
                        width: "25vw",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className={"notifications-list"}
                  style={{ paddingLeft: 0, marginTop: "10px" }}
                >
                  <Stack
                    id="notificationsWrapper"
                    class="notifications-wrapper"
                    spacing={1}
                    style={{ height: 300, overflowY: "scroll", width: "100%" }}
                  >
                    {notificationsData &&
                      notificationsData.map((data, index) => (
                        <div>
                          <Stack direction={"row"} width={"100%"}></Stack>
                          <Stack
                            className={`notification-li ${data.status == 0 ? "notification_li_unread" : ""
                              }`}
                            width={"100%"}
                            direction="row"
                            spacing={2}
                            onClick={() => {
                              NotificationClickHandler(
                                data.draft_type,
                                data.draft_id,
                                data.is_script_provided
                              );
                              ReadNotificationHandler(data.id, index);
                            }}
                          >
                            <div style={{ paddingTop: "6px" }}>
                              {/* <Tooltip
                            color="#108ee9"
                            zIndex={9999999}
                            autoAdjustOverflow={true}
                            title={data.source_name}
                            placement={"topRight"}
                          > */}
                              <Avatar
                                style={{ backgroundColor: "#87d068" }}
                                icon={<UserOutlined />}
                              />
                              {/* </Tooltip> */}
                            </div>

                            <div>
                              <Stack
                                direction={"row"}
                                width="100%"
                                justifyContent={"space-between"}
                              >
                                <Stack spacing={-1.5}>
                                  <p
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      // color: "#637381",
                                    }}
                                  >
                                    {data.source_name &&
                                      data.source_name.length > 25
                                      ? `${data.source_name.slice(0, 25)}...`
                                      : data.source_name}
                                  </p>
                                  <p style={{ fontSize: 13, fontWeight: 600 }}>
                                    {data.title && data.title.length > 35
                                      ? `${data.title.slice(0, 35)}...`
                                      : data.title}
                                  </p>
                                </Stack>
                                <Stack
                                  marginTop={0}
                                  alignItems={"center"}
                                  direction="row"
                                  spacing={0.8}
                                >
                                  {data.status == 0 && (
                                    <SlEnvelopeOpen
                                      color="#919EAB"
                                      size="17px"
                                      // color="#919EAB"
                                      onMouseOver={({ target }) =>
                                        (target.style.color = "green")
                                      }
                                      onMouseOut={({ target }) =>
                                        (target.style.color = "#919EAB")
                                      }
                                      cursor={"pointer"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        ReadNotificationHandler(data, index);
                                      }}
                                    />
                                  )}
                                  &nbsp; &nbsp;
                                  <AiOutlineDelete
                                    size="17px"
                                    color="#919EAB"
                                    onMouseOver={({ target }) =>
                                      (target.style.color = "red")
                                    }
                                    onMouseOut={({ target }) =>
                                      (target.style.color = "#919EAB")
                                    }
                                    cursor={"pointer"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      DeteleNotificationHandler(data, index);
                                      ReadNotificationHandler(
                                        data,
                                        index,
                                        "delete"
                                      );
                                    }}
                                  />
                                </Stack>
                              </Stack>

                              <div className="notification_lower">
                                <p
                                  className="notification-content"
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#637381",
                                  }}
                                >
                                  {data.content && data.content.length > 25
                                    ? `${data.content.slice(0, 25)}...`
                                    : data.content}
                                </p>
                                <Stack
                                  marginTop={0}
                                  alignItems={"center"}
                                  direction="row"
                                  spacing={0.8}
                                >
                                  <FiClock color="#919EAB" />
                                  <p style={{ color: "#919EAB", fontSize: 12 }}>
                                    {moment(data.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </Stack>
                              </div>
                            </div>
                          </Stack>
                        </div>
                      ))}
                  </Stack>
                </div>
              </Menu>
              <span onClick={handleClick} className="notifications">
                <HiBell style={{ fontSize: 24, color: "#637381" }} />
                <div
                  style={{
                    position: "absolute",
                    top: "110px",
                    left: "207px",
                    height: 20,
                    width: 20,
                    backgroundColor: "#E7AE3C",
                    borderRadius: "50%",
                    color: "white",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  <p>{unreadNotification}</p>
                </div>
              </span>
            </div>
            <div
              style={{
                marginTop: 20,
                marginLeft: 18,
                marginRight: 15,
                marginBottom: -22,
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: 22 }}>TORI-CHAT</p>
            </div>
            <ul class="sidebar-nav" id="sidebar">
              <li>
                <Accordion
                  // defaultActiveKey="0"
                  className={` remove-accordian-focus`}
                  style={{ border: "none" }}
                >
                  <Accordion.Item eventKey="0" style={{ border: "none" }}>
                    <Accordion.Header>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                          border: "none",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={NewScriptLogo} />
                        </span>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          Open Chat
                        </span>
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body style={{ border: "none" }}>
                      {/* <SideBarChats /> */}
                      <div
                        onClick={() =>
                          setCustomModal(true)
                          // history.push("/user/tori-chat/help", {
                          //   from: "tori-help",
                          // })
                        }
                        className={`chat-beta w3-bar-item w3-button ${isActive(
                          history,
                          "/user/tori-chat/help"
                        )}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "2px",
                          border: "none",
                          paddingLeft: "14px",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={NewScriptLogo} />
                        </span>
                        <span style={{ fontSize: 13 }}>SAP S/4HANA Help</span>
                      </div>
                      {/* ) : null}*/}

                      {/*// {userData?.role === "Admin" ||
                      // userData?.email === "girish.kini@sap.com" ||
                      // userData?.email === "tim.hatcher@sap.com" ||
                      // userData?.email === "marcel.lindsay@sap.com" ||
                      // userData?.email === "ana.vibancos@sap.com" ||
                      // userData?.email === "jorge.ivan.arellano@sap.com" ||
                      // userData?.email === "philip.transfeld@sap.com" ||
                      // userData?.email === "murali.reddygari@sap.com" ||
                      // userData?.email === "tr.smith@sap.com" ||
                      // userData?.email === "michele.krom@sap.com" ? (*/}
                      {userData?.role === "Admin" ? (
                        <div
                          onClick={() =>
                            setCustomModal(true)
                            // history.push("/user/tori-chat/vectorstore", {
                            //   from: "vector-store",
                            // })
                          }
                          className={`chat-beta w3-bar-item w3-button ${isActive(
                            history,
                            "/user/tori-chat/vectorstore"
                          )}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            border: "none",
                            paddingLeft: "14px",
                          }}
                        >
                          <span>
                            <img width={22} height={22} src={NewScriptLogo} />
                          </span>
                          <span style={{ fontSize: 13 }}>Tori Chat</span>
                        </div>
                      ) : null}
                      {/*) : null}*/}
                    </Accordion.Body>
                  </Accordion.Item>
                  {/*Chat Sessions*/}
                  <Accordion.Item eventKey="1" style={{ border: "none" }}>
                    <Accordion.Header>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                          border: "none",
                        }}
                      >
                        <span>
                          <img width={22} height={22} src={InoviceLogo} />
                        </span>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          My Chat Sessions
                        </span>
                        <div style={{ paddingRight: 5 }}></div>
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body style={{ border: "none" }}>
                      {sessionData?.map((session) => (
                        <div
                          key={session?.id}
                          className={`chat-beta w3-bar-item w3-button ${isActive(
                            history,
                            `/user/tori-chat/${session.model === "demo"
                              ? "demostore"
                              : session.model === "sap"
                                ? "sapwebsite"
                                : session.model === "vector_store"
                                  ? "vectorstore"
                                  : session.model === "tori_help"
                                    ? "torihelp"
                                    : "chat"
                            }/${session?.id}`
                          )}`}
                          onClick={() => chatSessionNavigate(session)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            border: "none",
                            paddingLeft: "14px",
                          }}
                        >
                          <span className="col-2">
                            <BsChatLeftDots fontSize={18} color="#637381" />
                          </span>
                          <span className="col-7" style={{ fontSize: 14 }}>
                            {session?.name}
                          </span>
                          {clickedIndex === session?.id ? (
                            // <BsChatLeftDots fontSize={18} color="#637381" /> :
                            <span
                              className="col-2"
                              onClick={() => DeleteSession(session?.id)}
                            >
                              <AiOutlineDelete fontSize={18} color="#637381" />
                            </span>
                          ) : null}
                        </div>
                      ))}
                      <Divider />

                      <div className="clearsessionbtn">
                        <Stack
                          onClick={ClearConversation}
                          paddingBlock={1}
                          // style={{ border: "1px solid black", borderRadius: "5px" }}
                          spacing={-1}
                          paddingLeft={"14px"}
                          direction="row"
                        >
                          <AiFillDelete fontSize={20} color="#637381" />
                          <Typography fontSize={14}>
                            Clear Conversation
                          </Typography>
                        </Stack>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/*Chat Setting*/}
                  <Accordion.Item eventKey="2" style={{ border: "none" }}>
                    <Accordion.Header>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "14px",
                          border: "none",
                        }}
                      >
                        <span>
                          <AiFillSetting size={22} color="#637381" />
                        </span>
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                          Chat Settings
                        </span>
                        <div style={{ paddingRight: 5 }}></div>
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body style={{ border: "none" }}>
                      {/* Alternative Links Checkbox */}
                      <div style={{ display: "flex", alignItems: "center", padding: "2px", border: "none", paddingLeft: "25px" }}>
                        <input type="checkbox" id="alternativeLinks" name="alternativeLinks" checked={alternateLinks} onChange={handleChatSetting} />
                        <label htmlFor="alternativeLinks" style={{ fontWeight: 400, width: "160px" }}>More links sidebar</label>
                      </div>

                      {/* Show More Checkbox */}
                      <div style={{ display: "flex", padding: "2px", border: "none", paddingLeft: "25px" }}>
                        <input type="checkbox" id="showMore" name="showMore" checked={showMore} onChange={handleChatSetting} />
                        <label htmlFor="showMore" style={{ fontWeight: 400, width: "150px" }}>Show More</label>
                      </div>

                    </Accordion.Body>

                  </Accordion.Item>
                </Accordion>
              </li>
              <li>
                <Link
                  className={"w3-bar-item w3-button"}
                  style={{ marginTop: 0 }}
                ></Link>
              </li>
            </ul>
            <ul class="sidebar-nav">
              <li>
                <div
                  style={{ marginTop: 20, marginRight: 15, marginBottom: -22 }}
                >
                  <p style={{ fontWeight: "bold" }}>SCRIPT FACTORY</p>
                </div>
              </li>
            </ul>
            <ul class="sidebar-nav" id="sidebar">
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/welcome"
                  )}`}
                  to="/user/welcome"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={HomeLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>Getting Started</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/home"
                  )}`}
                  to="/user/home"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    {" "}
                    <span>
                      <img width={22} height={22} src={NewScriptLogo} />{" "}
                    </span>{" "}
                    <span style={{ fontSize: 14 }}> Create New Script</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/drafts"
                  )}`}
                  to="/user/drafts"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px",
                      }}
                    >
                      {" "}
                      <span>
                        <img width={22} height={22} src={InoviceLogo} />
                      </span>
                      <span style={{ fontSize: 14 }}>Scripts</span>
                    </div>
                    <div style={{ paddingRight: 20 }}>
                      {loader ? (
                        <div className="topSY">
                          <ShimmerCircularImage size={20} />
                        </div>
                      ) : (
                        <div className="count">
                          <span style={{ fontSize: 14 }} className="font_count">
                            {/* {data && data.drafts} */}
                            {localStorage.getItem("draftCount")}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/approved"
                  )}`}
                  to="/user/approved"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px",
                      }}
                    >
                      <span>
                        <img width={22} height={22} src={InoviceLogo} />
                      </span>
                      <span style={{ fontSize: 14 }}>Tori Trained</span>
                    </div>

                    <div style={{ paddingRight: 20 }}>
                      {loader ? (
                        <div className="topSY">
                          <ShimmerCircularImage size={20} />
                        </div>
                      ) : (
                        <div style={{ fontSize: 14 }} className="m-0 count">
                          {localStorage.getItem("finalReviewCount")}
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
            {/*  
            <ul class="sidebar-nav">
              <li>
                <div
                  style={{ marginTop: 20, marginRight: 15, marginBottom: -22 }}
                >
                  <p style={{ fontWeight: "bold" }}>TORI'S TRAINING</p>
                </div>
              </li>
            </ul>
            <ul class="sidebar-nav">
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/sap-education"
                  )}`}
                // to="/user/sap-education"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={OverviewLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>SAP Education</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/graphs"
                  )}`}
                  to="/user/graphs"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={OverviewLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>Video Script Types</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/heat-map"
                  )}`}
                  to="/user/heat-map"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={OverviewLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>Scripting Heat Map</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/sap-education"
                  )}`}
                // to="/user/sap-education"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={brandCompilance} />
                    </span>
                    <span style={{ fontSize: 14 }}>Brand Compilance</span>
                  </div>
                </Link>
              </li>
            </ul>*/}
            <ul class="sidebar-nav">
              <li>
                <div
                  style={{ marginTop: 20, marginRight: 15, marginBottom: -22 }}
                >
                  <p style={{ fontWeight: "bold" }}>ACCOUNT</p>
                </div>
              </li>
            </ul>

            <ul class="sidebar-nav">
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/profile"
                  )}`}
                  to="/user/profile"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                    }}
                  >
                    <span>
                      <img width={22} height={22} src={UserProfileLogo} />
                    </span>
                    <span style={{ fontSize: 14 }}>Profile</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/admin"
                  )}`}
                  to="/user/admin"
                >
                  {loader ? (
                    // <ShimmerCircularImage size={20} /> :
                    <ShimmerBadge width={200} />
                  ) : (
                    <span>
                      {data && data.role == "Admin" && (
                        <span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "4px",
                            }}
                          >
                            {" "}
                            <span>
                              <FiUsers fontSize={18} color="#637381" />
                            </span>
                            <span style={{ fontSize: 14 }}>Users</span>
                          </div>
                        </span>
                      )}
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link
                  className={`w3-bar-item w3-button ${isActive(
                    history,
                    "/user/chatusers"
                  )}`}
                  to="/user/chatusers"
                >
                  {loader ? (
                    // <ShimmerCircularImage size={20} /> :
                    <ShimmerBadge width={200} />
                  ) : (
                    <span>
                      {data && data.role == "Admin" && (
                        <span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "4px",
                            }}
                          >
                            {" "}
                            <span>
                              <FaUsers fontSize={18} color="#637381" />
                            </span>
                            <span style={{ fontSize: 14 }}>Users Activity</span>
                          </div>
                        </span>
                      )}
                    </span>
                  )}
                </Link>
              </li>
              <li onClick={logOutPress} style={{ cursor: "pointer" }}>
                <a style={{ fontSize: 14 }}>Log out</a>
              </li>
            </ul>
          </div>
          {/* <!-- Page content --> */}
          <div id="page-content-wrapper">
            <div class="page-content inset">
              <div class="row">
                <div class="col-md-12">{props.children}</div>
              </div>
            </div>
          </div>
        </div>

        <DisplayModal
          open={customModal}
          onClose={() => {
            setCustomModal(false)
            // setisSendReqMsg(false);
            // if (chatArray.length) {
            //   chatArray.pop();
            //   setShowFirstInput(false);
            //   setNewQuestion(newQuestion);
            // } else {
            //   setShowFirstInput(true);
            //   setChatArray([]);
            //   setNewQuestion("");
            //   setAskQuery(askQuery);
            // }
          }}
          modalMsg={customModalMessage}
        />
      </div>
    )
  ) : (
    <Redirect to="/"></Redirect>
  );
}
export const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return "activate";
  }
};
export default withRouter(SideBar);

// <div
// onClick={() =>
//   history.push("/user/tori-chat/chat", {
//     from: "tori-chat",
//   })
// }
// className={`chat-beta w3-bar-item w3-button ${isActive(
//   history,
//   "/user/tori-chat/chat"
// )}`}
// style={{
//   display: "flex",
//   alignItems: "center",
//   padding: "2px",
//   border: "none",
//   paddingLeft: "14px",
// }}
// >
// <span>
//   <img width={22} height={22} src={NewScriptLogo} />
// </span>
// <span style={{ fontSize: 13 }}>Tori Chat</span>
// </div>

// <div
// onClick={() =>
//   history.push("/user/tori-chat/demostore", {
//     from: "demostore",
//   })
// }
// // className="chat-beta"
// className={`chat-beta w3-bar-item w3-button ${isActive(
//   history,
//   "/user/tori-chat/demostore"
// )}`}
// style={{
//   display: "flex",
//   alignItems: "center",
//   padding: "2px",
//   border: "none",
//   paddingLeft: "14px",
// }}
// >
// <span>
//   <img width={22} height={22} src={NewScriptLogo} />
// </span>
// <span style={{ fontSize: 13 }}> Demo Store</span>
// </div>
// <div
// onClick={() =>
//   history.push("/user/tori-chat/sapwebsite", {
//     from: "sapwebsite",
//   })
// }
// className={`chat-beta w3-bar-item w3-button ${isActive(
//   history,
//   "/user/tori-chat/sapwebsite"
// )}`}
// style={{
//   display: "flex",
//   alignItems: "center",
//   padding: "2px",
//   border: "none",
//   paddingLeft: "14px",
// }}
// >
// <span>
//   <img width={22} height={22} src={NewScriptLogo} />
// </span>
// <span style={{ fontSize: 13 }}>SAP website</span>
// </div>
