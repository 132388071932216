import React, { useEffect, useState, useRef } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
import Sort from "../../assets/icons-sorting.png";
import SortUp from "../../assets/icon_up.png";
import {
  GetAllUsers,
  InviteUser,
  GetUserData,
  searchUsersData,
  GetAllChatUsers,
  searchChatUsersActitvityData,
} from "../../../components/Services/Service";
import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import "../DraftTable/Style.css";
import Modal from "react-modal";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import AdminMenu from "./AdminMenu";
import { Button, Pagination } from "@mui/material";
import SelectDropdown from "../../reactSelectDropdown/SelectDropdown";
import { Input, Select } from "antd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DisplayModal from "../CreateDraft/Components/Modal";
import { FiSearch } from "react-icons/fi";
import { baseUrl } from "../../../environment";

function ChatUsers(props) {
  const history = useHistory();
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      neutral: {
        main: "#EAF1FF",
        contrastText: "#0A6ED1",
      },
    },
  });

  const [dataa, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loaderInviteUser, isloadingInvite] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modalMessage1, setModalMessage1] = useState("");
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [lastName, setLastName] = useState();
  const [role, setRole] = useState();
  const [day, setDay] = useState("30");
  const [session_count, setsession_count] = useState([]);
  const [paginationRows, setPaginationRows] = useState(10);
  const [page, setPage] = useState(1);
  const totalUsers = useRef();
  const selectedRowId = useRef();
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [searchText, setsearchText] = useState();
  const [columnName, setColumnName] = useState();
  const [order, setOrder] = useState();
  const [showNameIcon, setShowNameIcon] = useState(false);
  const [showEmailIcon, setShowEmailIcon] = useState(false);
  const [showRoleIcon, setShowRoleIcon] = useState(false);
  const [showSessionCountIcon, setshowSessionCountIcon] = useState(false);
  const [showScriptsIcon, setShowScriptsIcon] = useState(false);
  const [nameIcon, setNameIcon] = useState(Sort);
  const [emailIcon, setemailIcon] = useState(Sort);
  const [roleIcon, setroleIcon] = useState(Sort);
  const [session_countIcon, setsession_countIcon] = useState(Sort);
  const [scriptIcon, setscriptIcon] = useState(Sort);
  const [showStatusIcon, setShowStatusIcon] = useState(false);
  const handleExportCsv = () => {
    const url = `${baseUrl}/api/users-activity?days=${day}`; // Replace this with your desired URL
    window.open(url, "_blank"); // Opens the URL in a new window or tab
  };
  const onRowChange = async (value) => {
    setLoader1(true);
    if (
      Math.ceil(totalUsers.current / value) !=
      Math.ceil(totalUsers.current / paginationRows)
    ) {
      const oldRatio = page / Math.ceil(totalUsers.current / paginationRows);
      setPaginationRows(value);
      const newPageNumber = Math.ceil((totalUsers.current / value) * oldRatio);
      if (searchText) {
        searchUsersData(newPageNumber, value, searchText, columnName, order)
          .then((res) => {
            setPage(newPageNumber);
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalUsers.current = res.total_count;
            }
          })
          .catch((error) => {
            setLoader1(false);
          });
      } else {
        GetAllChatUsers(day, page, value, columnName, order)
          .then((res) => {
            // setLoader1(true)
            setPage(newPageNumber);
            setData(res.data);
            totalUsers.current = res.total_count;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setPaginationRows(value);
      if (searchText) {
        searchUsersData(page, value, searchText, columnName, order)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalUsers.current = res.total_count;
            }
          })
          .catch((error) => {
            setLoader1(false);
          });
      } else {
        let data = {
          days: day,
        };
        GetAllChatUsers(day, page, value, columnName, order)
          .then((res) => {
            // setLoader1(true)
            setData(res.data);
            totalUsers.current = res.total_count;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }

    updateSidebar();
  };
  const handleChange = (event, value) => {
    setPage(value);
    updateSidebar();
    setLoader1(true);
    if (searchText) {
      searchUsersData(value, paginationRows, searchText, columnName, order)
        .then((res) => {
          if (res.status) {
            setLoader1(false);
            setData(res.data);
            totalUsers.current = res.total_count;
          }
        })
        .catch((error) => {
          setLoader1(false);
        });
    } else {
      GetAllChatUsers(day, value, paginationRows, columnName, order)
        .then((res) => {
          setData(res.data);
          totalUsers.current = res.total_count;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
            setLoader(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const searchData = (text) => {
    setOrder();
    setShowNameIcon(false);
    setShowEmailIcon(false);
    setShowRoleIcon(false);
    setshowSessionCountIcon(false);
    setShowStatusIcon(false);
    if (text.length >= 1) {
      // alert("day");
      setPage(1);
      searchChatUsersActitvityData(day, page, text, paginationRows)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalUsers.current = res.total_count;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("day", day);
      let data = {
        days: day,
      };
      GetAllChatUsers(day, page, paginationRows)
        .then((res) => {
          if (res.status) {
            console.log("🚀 ~ file: ChatUsers.js:230 ~ .then ~ res:", res.data);

            setData(res.data);
            totalUsers.current = res.total_count;
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          } else {
            console.log("🚀 ~ file: ChatUsers.js:230 ~ .then ~ res:", res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const onChangeUser = (e) => {
    setUserName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    updateSidebar();
    setLoader(true);
    GetAllChatUsers(day, page, paginationRows)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          totalUsers.current = res.total_count;
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoader1(false);
        setLoader(false);
      });
  }, []);

  const handleGetAllChatUser = (val) => {
    setLoader(true);
    setLoader1(true);
    // updateSidebar();

    GetAllChatUsers(val, page, paginationRows, columnName, order)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          totalUsers.current = res.total_count;
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoader1(false);
        setLoader(false);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const onSendInvite = () => {
    isloadingInvite(true);
    InviteUser(userName, email, lastName, role, session_count)
      .then((res) => {
        if (res.status) {
          isloadingInvite(false);
          location.reload();
          setIsOpen(false);
        } else {
          isloadingInvite(false);
          setModalMessage1(res.message);
          setModal1(true);
          setIsOpen(false);
          // alert(res.message)
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsOpen(false);
        isloadingInvite(true);
      });
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "30%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      overflow: "unset",
    },
  };
  const data = () => ({
    columns: [],
    rows: dataa,
  });
  const ActionClick = (id) => {
    selectedRowId.current = id;
  };
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };
  const sortingFunc = (ord, col) => {
    setColumnName(col);
    setOrder(ord);
    if (searchText) {
      searchUsersData(day, page, paginationRows, searchText, col, ord)
        .then((res) => {
          if (col == "name") {
            setShowNameIcon(true);
            if (ord == "asc") {
              setNameIcon(SortUp);
            } else {
              setNameIcon(Sort);
            }
          } else {
            setShowNameIcon(false);
          }
          if (col == "email") {
            setShowEmailIcon(true);
            if (ord == "asc") {
              setemailIcon(SortUp);
            } else {
              setemailIcon(Sort);
            }
          } else {
            setShowEmailIcon(false);
          }
          if (col == "role") {
            setShowRoleIcon(true);
            if (ord == "asc") {
              setroleIcon(SortUp);
            } else {
              setroleIcon(Sort);
            }
          } else {
            setShowRoleIcon(false);
          }
          if (col == "session_count") {
            setshowSessionCountIcon(true);
            if (ord == "asc") {
              setsession_countIcon(SortUp);
            } else {
              setsession_countIcon(Sort);
            }
          } else {
            setshowSessionCountIcon(false);
          }
          if (col == "script_count") {
            setShowScriptsIcon(true);
            if (ord == "asc") {
              setscriptIcon(SortUp);
            } else {
              setscriptIcon(Sort);
            }
          } else {
            setShowScriptsIcon(false);
          }
          if (res.status) {
            setData(res.data);
            totalUsers.current = res.total_count;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      GetAllChatUsers(day, page, paginationRows, col, ord).then((res) => {
        if (col == "name") {
          setShowNameIcon(true);
          if (ord == "asc") {
            setNameIcon(SortUp);
          } else {
            setNameIcon(Sort);
          }
        } else {
          setShowNameIcon(false);
        }
        if (col == "email") {
          setShowEmailIcon(true);
          if (ord == "asc") {
            setemailIcon(SortUp);
          } else {
            setemailIcon(Sort);
          }
        } else {
          setShowEmailIcon(false);
        }
        if (col == "role") {
          setShowRoleIcon(true);
          if (ord == "asc") {
            setroleIcon(SortUp);
          } else {
            setroleIcon(Sort);
          }
        } else {
          setShowRoleIcon(false);
        }
        if (col == "session_count") {
          setshowSessionCountIcon(true);
          if (ord == "asc") {
            setsession_countIcon(SortUp);
          } else {
            setsession_countIcon(Sort);
          }
        } else {
          setshowSessionCountIcon(false);
        }
        if (col == "script_count") {
          setShowScriptsIcon(true);
          if (ord == "asc") {
            setscriptIcon(SortUp);
          } else {
            setscriptIcon(Sort);
          }
        } else {
          setShowScriptsIcon(false);
        }
        setLoader(false);
        if (res.status) {
          setData(res.data);
          setLoader1(false);
          totalUsers.current = res.total_count;
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      });
    }
  };
  const linksData = {
    columns: [
      {
        label: (
          <div
            onClick={() => {
              if (order == "desc") {
                sortingFunc("asc", "name");
              } else if (order == "asc") {
                sortingFunc("desc", "name");
              } else {
                sortingFunc("asc", "name");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "160px",
            }}
          >
            <span>Name</span>
            {showNameIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={nameIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "name",
        sort: "asc",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "desc") {
                sortingFunc("asc", "email");
              } else if (order == "asc") {
                sortingFunc("desc", "email");
              } else {
                sortingFunc("asc", "email");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "250px",
            }}
          >
            <span>Email</span>
            {showEmailIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={emailIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "email",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "desc") {
                sortingFunc("asc", "role");
              } else if (order == "asc") {
                sortingFunc("desc", "role");
              } else {
                sortingFunc("asc", "role");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "120px",
            }}
          >
            <span>Role</span>
            {showRoleIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={roleIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "role",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "desc") {
                sortingFunc("asc", "session_count");
              } else if (order == "asc") {
                sortingFunc("desc", "session_count");
              } else {
                sortingFunc("asc", "session_count");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "150px",
            }}
          >
            <span>Chat Sessions</span>
            {showSessionCountIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={session_countIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "session_count",
        sort: "asc",
        width: 80,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "desc") {
                sortingFunc("asc", "script_count");
              } else if (order == "asc") {
                sortingFunc("desc", "script_count");
              } else {
                sortingFunc("asc", "script_count");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: 80,
            }}
          >
            <span>Scripts</span>
            {showScriptsIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={scriptIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "scripts",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      ...data().rows.map((row, order) => ({
        name: (
          <span searchvalue={`${row.name && row.name.toLowerCase()}`}>
            {row.name && row.name.length > 15
              ? `${row.name.slice(0, 15)}...`
              : row.name}
          </span>
        ),
        email: (
          <span searchvalue={`${row.email && row.email.toLowerCase()}`}>
            {row.email && row.email.length > 25
              ? `${row.email.slice(0, 25)}...`
              : row.email}
          </span>
        ),

        role: (
          <span searchvalue={`${row.role && row.role.toLowerCase()}`}>
            {row.role}
          </span>
        ),
        session_count: (
          <span style={{ marginLeft: "40px" }}>
            {row.session_count && row.session_count.length > 30
              ? `${row.session_count.slice(0, 30)}...`
              : row.session_count}
          </span>
        ),
        scripts: <span style={{ marginLeft: "15px" }}>{row.script_count}</span>,
      })),
    ],
  };
  const options = [
    { value: 0, label: "Edit" },
    { value: 1, label: "Create Draft & Comment" },
    { value: 2, label: "Close Comment" },
  ];
  const optionsForRole = [
    { value: 0, label: "Admin" },
    { value: 1, label: "Editor" },
    { value: 2, label: "Hub Lead" },
    { value: 3, label: "SME" },
  ];
  const optionsForDays = [
    { value: 5, label: "Last 05 days" },
    { value: 15, label: "Last 15 days" },
    { value: 30, label: "Last 30 days" },
    { value: 45, label: "Last 45 days" },
    { value: 60, label: "Last 60 days" },
    { value: 90, label: "Last 90 days" },
  ];
  const daysDefaultValue = [{ value: 30, label: "Last 30 days" }];
  // const defaultValue = [{ value: 1, label: "Comment Only & Create Draft" }]
  var multiArr = [];
  const onSelectMultisession_count = (e) => {
    var val = e;
    val.map((a) => {
      multiArr.push(a.label);
    });
    setsession_count(multiArr);
  };
  const onSelectMultiRole = (e) => {
    var val = e;

    setRole(val.label);
  };
  return (
    <div>
      {loader1 && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Users Activity</h3>
        <button
          onClick={handleExportCsv}
          className="primatybutton w-inline-block"
        >
          Export CSV
        </button>
      </div>
      {/* {loader1 ? (
        <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div className="box_s">
        {/* search bar */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              paddingRight: 25,
              paddingBlock: 20,
              width: "20%",
              // display: "flex",
              // justifyContent: "start",
              marginLeft: "10px",
            }}
          >
            <SelectDropdown
              onChange={(e) => {
                setDay(e.value);
                handleGetAllChatUser(e.value);
              }}
              options={optionsForDays}
              isOptionDisabled={() => {}}
              // placeholder="Select your day"
              // onBlur={handleGetAllChatUser}
              defaultValue={daysDefaultValue}
            />
          </div>

          <div
            style={{
              paddingRight: 25,
              paddingBlock: 20,
              // display: "flex",
              // justifyContent: "end",
            }}
          >
            <Input
              className="table-search"
              style={{ width: "200px", height: "35px", borderRadius: 8 }}
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              onKeyUp={() => searchData(searchText)}
              placeholder="Search..."
              prefix={<FiSearch style={{ color: "#637381", fontSize: 20 }} />}
            />
          </div>
        </div>

        {/* table */}
        <div>
          <MDBDataTable
            noBottomColumns={true}
            fixedHeader
            bordered={false}
            entries={50}
            data={linksData}
            responsive
            className="your-custom-styles"
          />
        </div>
        {/* material pagination */}
        <div className="table-pagination">
          {/* choose rows */}
          <div className="paginationrows-dropdown">
            <label for="cars">Rows per page:</label>
            <Select
              defaultValue={paginationRows}
              onChange={(value) => onRowChange(value)}
              bordered={false}
              options={[
                {
                  value: "10",
                  label: "10",
                },
                {
                  value: "15",
                  label: "15",
                },
                {
                  value: "20",
                  label: "20",
                },
              ]}
            />
          </div>
          {/* pagination row */}
          <ThemeProvider theme={theme}>
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  fontSize: "14px !important",
                },
                ".MuiPaginationItem-root.Mui-selected": {
                  fontWeight: "600 !important",
                },
              }}
              count={Math.ceil(totalUsers.current / paginationRows)}
              page={page}
              color={"neutral"}
              onChange={handleChange}
            />
          </ThemeProvider>
        </div>
      </div>
      {/* )} */}

      {/* invite user modal */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="hide_scroll">
            <div
              class="modal-header"
              style={{
                borderBottom: 0,
                padding: 40,
                paddingBottom: 10,
                paddingTop: 15,
              }}
            >
              <h5
                class="modal-title"
                id="staticBackdropLabel"
                style={{
                  position: "absolute",
                  left: 25,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Invite User
              </h5>
            </div>
            <div style={{ padding: 20 }}>
              <div className="row p-0">
                <div className="col-6 pl-0" style={{ paddingLeft: 0 }}>
                  <div>
                    <label for="exampleInputEmail1">First Name</label>
                    <input
                      type="email"
                      onChange={onChangeUser}
                      style={{ height: 40 }}
                      class="form-control form-control-lg"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
                <div className="col-6" style={{ paddingRight: 0 }}>
                  <div>
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="email"
                      onChange={onChangeLastName}
                      style={{ height: 40 }}
                      class="form-control form-control-lg"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  onChange={onChangeEmail}
                  style={{ height: 40 }}
                  class="form-control form-control-lg"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Role</label>
                <SelectDropdown
                  onChange={onSelectMultiRole}
                  options={optionsForRole}
                  isOptionDisabled={() => {}}
                  placeholder="Select or begin typing"
                />
              </div>
              <div>
                <label for="exampleInputEmail1">session_count</label>
                <SelectDropdown
                  onChange={onSelectMultisession_count}
                  options={options}
                  isMulti={true}
                  isOptionDisabled={() => {}}
                  placeholder="Select or begin typing"
                />
              </div>
            </div>

            <div
              className="pt-3"
              style={{ float: "right", display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: 10 }}>
                <button
                  onClick={closeModal}
                  className="primatybuttonwhite w-inline-block"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  onClick={onSendInvite}
                  className="primatybutton w-inline-block"
                >
                  {loaderInviteUser ? (
                    <div class=" text-dark" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Send Invite"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {/* invite user error messsage modal */}
      <div>
        <Modal
          isOpen={modal1}
          onRequestClose={() => {
            setModal1(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{`${modalMessage1}`}</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setModal1(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
        <DisplayModal
          open={isSendReqMsg}
          onClose={() => {
            setisSendReqMsg(false);
          }}
          modalMsg={sendReqMsg}
        />
      </div>
    </div>
  );
}

export default ChatUsers;
