import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  approvalHubleadmsg,
  approvalSmeMsg,
  domainTooltip,
  industryTooltip,
  lineOfBusinessTooltip,
  productNameTooltip,
  startingPointTooltip,
  videoNameTooltip,
  businessOutcomsTooltip,
} from "../../../utils/tooltips/Tooltips";
import DatalistInput from "react-datalist-input";
import plus from "../../assets/appPlus.png";
import Minus from "../../assets/minus.png";
import { BODropdownData } from "../../../utils/lists/CreateDraftLists";

import InputField from "../../inputfield/InputField";
import SelectDropdown from "../../reactSelectDropdown/SelectDropdown";
import "./Style.css";
import info from "../../assets/info.svg";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../environment";
import { Tooltip, Typography, Stack } from "@mui/material";
import {
  scrollToBottom,
  scrollToTop,
} from "../../../utils/helperfunctions/HelperFunctions";
import {
  GetSavedDraftDetails,
  ClearDraft,
  GetLineOfBusinessData,
  GetIndustriesData,
  GetDomainsData,
  DHLApproveRequest,
  TeachTori,
  getBusinessOutcomesList,
  CancelDHLRequest,
  SaveProcessState,
} from "../../Services/Service";
import {
  generateStepModal,
  verbs,
  gettingDemo,
  BusinessProcessFlow,
  BPFIntro,
  BPFOutro,
  GettingSummaryDonePreviously,
} from "../../Service";
import PrcoessDemoSteps from "./PrcoessDemoSteps";
import OutcomeValues from "../CreateDraft/Components/OutcomeValues";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dummyData } from "../../DummyData";
import $ from "jquery";
import ProductName from "../CreateDraft/Components/ProductName";
import GenerateOutroIntro from "../CreateDraft/Components/GenerateOutroIntro";
import Header from "../CreateDraft/Components/Header";
import ApprovalInfo from "../CreateDraft/Components/ApprovalInfo";
import DisplayModal from "../CreateDraft/Components/Modal";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { debounce } from "lodash";
function Process() {
  const [modal1, setmodal1] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [modal3, setmodal3] = useState(false);
  const [modal4, setmodal4] = useState(false);
  const [modal5, setmodal5] = useState(false);
  const [modal6, setmodal6] = useState(false);
  const [modal7, setmodal7] = useState(false);
  const [modal8, setmodal8] = useState(false);
  const [modal9, setmodal9] = useState(false);
  const [modal10, setmodal10] = useState(false);
  const [modal11, setmodal11] = useState(false);
  const [modal12, setmodal12] = useState(false);
  const [modal13, setmodal13] = useState(false);
  const [modal14, setmodal14] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState(false);
  const [modalMsg1, setModalMsg1] = useState("");
  const [modalMsg2, setModalMsg2] = useState("");
  const [modalMsg3, setModalMsg3] = useState("");
  const [modalMsg4, setModalMsg4] = useState("");
  const [modalMsg5, setModalMsg5] = useState("");
  const [modalMsg6, setModalMsg6] = useState("");
  const [modalMsg7, setModalMsg7] = useState("");
  const [modalMsg8, setModalMsg8] = useState("");
  const [modalMsg9, setModalMsg9] = useState("");
  const [modalMsg10, setModalMsg10] = useState("");
  const [modalMsg11, setModalMsg11] = useState("");
  const [modalMsg12, setModalMsg12] = useState("");
  const [modalMsg13, setModalMsg13] = useState("");
  const [modalMsg14, setModalMsg14] = useState("");
  const [stepDraftColor, setstepDraftColor] = useState();
  const [lineOfBusinessData, setLineOfBusinessData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [domainsData, setDomainsData] = useState([]);
  const [demo1Regen, setdemo1Regen] = useState(false);
  const [regenLoading, setRegenLoading] = useState(false);
  const [loadingTori, setloadingTori] = useState(false);
  const [disableAllinputs, setdisableAllinputs] = useState(false);
  const [isDisableDemoScript, setisDisableDemoScript] = useState(false);
  const [isDisableLineOfBusiness, setIsDisableLineOfBusiness] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [draftCreatedBy, setdraftCreatedBy] = useState();
  const [loadingcancel, setLoadingcancel] = useState(false);
  const [boDropdownData, setboDropdownData] = useState([]);
  const [selectDemoOne, setSelectDemoOne] = useState(false);
  const [selectDemoTwo, setSelectDemoTwo] = useState(false);
  const [selectDemoThree, setSelectDemoThree] = useState(false);
  const [selectDemoFour, setSelectDemoFour] = useState(false);
  const [selectDemoFive, setSelectDemoFive] = useState(false);
  const [selectDemoSix, setSelectDemoSix] = useState(false);
  const [selectDemoSeven, setSelectDemoSeven] = useState(false);

  const [businessOutcomesArray, setBusinessOutcomesArray] = useState([
    { name: "" },
  ]);
  const [businessOutcomesString, setBusinessOutcomesString] = useState("");
  const [businessOutcomesLength, setBusinessOutcomesLength] = useState([]);
  const [businessOutcomesPrompt, setBusinessOutcomesPrompt] = useState("");
  const [boRequired, setBORequired] = useState(false);
  const [atLeastTwoBo, setAtLeastBo] = useState(false);

  const [intro1Value, setintro1Value] = useState();
  const [canApprove, setcanApprove] = useState(false);
  const [isDisableReq, setisDisableReq] = useState(true);
  const [isDisableTeachTori, setisDisableTeachTori] = useState(true);
  const [isDisableApproveButton, setisDisableApproveButton] = useState(true);
  const [disAbleDemoKey, setDisAbleDemoKey] = useState(false);
  const [disAbleDemoKey2, setDisAbleDemoKey2] = useState(false);
  const [disAbleDemoKey3, setDisAbleDemoKey3] = useState(false);
  const [disAbleDemoKey4, setDisAbleDemoKey4] = useState(false);
  const [disAbleDemoKey5, setDisAbleDemoKey5] = useState(false);
  const [disAbleDemoKey6, setDisAbleDemoKey6] = useState(false);
  const [disAbleDemoKey7, setDisAbleDemoKey7] = useState(false);
  const [showGenerateBPF, setshowGenerateBPF] = useState(false);
  const [showReGenBPF, setshowReGenBPF] = useState(false);
  const [loadingReqApp, setloadingReqApp] = useState(false);
  const [showapprovalInfoHublead, setshowapprovalInfoHublead] = useState(false);
  const [showapprovalInfoSme, setshowapprovalInfoSme] = useState(false);
  const [approvalHublead, setapprovalHublead] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [saveloading, setsaveLoading] = useState(false);
  const [selectedIntro, setselectedIntro] = useState("");
  const [CancelDHLRequestMsg, setCancelDHLRequestMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState("");
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isDHLRequest, setisCancelDHLRequest] = useState(false);
  const [hubleadNames, setHubleadNames] = useState();
  const [isShowOutroAddToScriptIntro, setisShowOutroAddToScriptIntro] =
    useState(false);
  const [userIdDraftOwner, setUserIdDraftOwner] = useState("");
  const [isDisableReqOnEditing, setisDisableReqOnEditing] = useState(true);
  const summaryTooltip =
    'This will end up being the description of the video once published, it also helps guide Tori on key thoughts and the ideal end state of the video. Do not worry, it doesn’t have to be perfect, copywriting will refine it as needed.\n\nExample : " This demo showcases how SAP S/4HANA helps you execute a great financial close with the previously defined tasks and setting you up to with the closing game."';
  const demoArr = [];
  const onEnterDemo1 = () => {
    setDisAbleDemoKey(true);
    setSelectDemoOne(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal1(true);
          setModalMsg1("No Demo Key Points available. Please use input field.");
          setSelectDemoOne(false);
          setDisAbleDemoKey(false);
        } else {
          setDisAbleDemoKey(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo2 = () => {
    // setDisAbleDemoKey2(true);
    setSelectDemoTwo(true);
    // setDemoKeyPointloaderDemo2(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal2(true);
          setModalMsg2("No Demo Key Points available. Please use input field.");
          setSelectDemoTwo(false);
        } else {
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo3 = () => {
    setDisAbleDemoKey3(true);
    setSelectDemoThree(true);
    // setDemoKeyPointloaderDemo3(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal3(true);
          setModalMsg3("No Demo Key Points available. Please use input field.");
          setSelectDemoThree(false);
          setDisAbleDemoKey3(false);
        } else {
          setDisAbleDemoKey3(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo4 = () => {
    setDisAbleDemoKey4(true);
    setSelectDemoFour(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal4(true);
          setModalMsg14(
            "No Demo Key Points available. Please use input field."
          );
          setSelectDemoFour(false);
          setDisAbleDemoKey4(false);
        } else {
          setDisAbleDemoKey4(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo5 = () => {
    setDisAbleDemoKey5(true);
    setSelectDemoFive(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal4(true);
          setModalMsg14(
            "No Demo Key Points available. Please use input field."
          );
          setSelectDemoFive(false);
          setDisAbleDemoKey5(false);
        } else {
          setDisAbleDemoKey5(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo6 = () => {
    setDisAbleDemoKey6(true);
    setSelectDemoSix(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal4(true);
          setModalMsg14(
            "No Demo Key Points available. Please use input field."
          );
          setSelectDemoSix(false);
          setDisAbleDemoKey6(false);
        } else {
          setDisAbleDemoKey6(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onEnterDemo7 = () => {
    setDisAbleDemoKey7(true);
    setSelectDemoSeven(true);
    gettingDemo(pName, pName2, Indursty, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setmodal4(true);
          setModalMsg14(
            "No Demo Key Points available. Please use input field."
          );
          setSelectDemoSeven(false);
          setDisAbleDemoKey7(false);
        } else {
          setDisAbleDemoKey7(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey(false);
        setSelectDemoOne(false);
      });
  };
  const onBlurProductName1 = (isProductName) => {
    CallingSaveDraftAPI({
      productName_1: pName1Ref.current ? pName1Ref.current : pName,
    });
    // isProductName && setproer(pName && pName.length != 0 ? false : true);
  };
  const onBlurProductName2 = () => {
    CallingSaveDraftAPI({
      productName_2: pName2Ref.current ? pName2Ref.current : pName2,
    });
  };
  const onBlurBusinessOutcome1 = () => {
    CallingSaveDraftAPI({
      businessOutcome_1: BO1Ref.current ? BO1Ref.current : BO1,
    });
  };
  const onBlurBusinessOutcome2 = () => {
    CallingSaveDraftAPI({
      businessOutcome_2: BO2Ref.current ? BO2Ref.current : SBO2,
    });
  };
  const onBlurBusinessOutcome3 = () => {
    CallingSaveDraftAPI({
      businessOutcome_3: BO3Ref.current ? BO3Ref.current : SBO3,
    });
  };
  const onBlurBusinessOutcome4 = () => {
    CallingSaveDraftAPI({
      businessOutcome_4: BO4Ref.current ? BO4Ref.current : SBO4,
    });
  };
  const onBlurBusinessOutcome5 = () => {
    CallingSaveDraftAPI({
      businessOutcome_5: BO5Ref.current ? BO5Ref.current : SBO5,
    });
  };
  const onBlurBusinessOutcome6 = () => {
    CallingSaveDraftAPI({
      businessOutcome_6: BO6Ref.current ? BO6Ref.current : SBO6,
    });
  };
  const onBlurBusinessOutcome7 = () => {
    CallingSaveDraftAPI({
      businessOutcome_7: BO7Ref.current ? BO7Ref.current : SBO7,
    });
  };
  let history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [Lob1, setLob1] = useState([]);
  const [Domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const CallingSaveDraftAPI = ({
    isFromSentRequest = false,
    productName_1 = pName,
    productName_2 = pName2,
    summaryValue = useCase,
    industryValue = Indursty,
    lineOfBusiness = Lob1,
    domainValue = Domain,
    video_name = VideoName,
    productURL_1 = firstProductUrl,
    productURL_2 = SecondProductUrl,
    businessOutcome_1 = BO1,
    businessOutcome_2 = SBO2,
    businessOutcome_3 = SBO3,
    businessOutcome_4 = SBO4,
    businessOutcome_5 = SBO5,
    businessOutcome_6 = SBO6,
    businessOutcome_7 = SBO7,
    business_outcomes_array = businessOutcomesArray,

    role_1 = Protagonist,
    role_2 = protagnist2,
    role_3 = protagnist3,
    role_4 = protagnist4,
    role_5 = protagnist5,
    role_6 = protagnist6,
    role_7 = protagnist7,

    demo_1 = Demo1,
    demo_2 = Demo2,
    demo_3 = Demo3,
    demo_4 = Demo4,
    demo_5 = Demo5,
    demo_6 = Demo6,
    demo_7 = Demo7,

    right_business_outcomes = businessOutcomesString,
    right_demo_1 = rightDemo1,
    right_demo_2 = rightDemo2,
    right_demo_3 = rightDemo3,
    right_demo_4 = rightDemo4,
    right_demo_5 = rightDemo5,
    right_demo_6 = rightDemo6,
    right_demo_7 = rightDemo7,

    is_AddToScript_1 = !disableDemo1RightFields,
    is_AddToScript_2 = !disableDemo2RightFields,
    is_AddToScript_3 = !disableDemo3RightFields,
    is_AddToScript_4 = !disableDemo4RightFields,
    is_AddToScript_5 = !disableDemo5RightFields,
    is_AddToScript_6 = !disableDemo6RightFields,
    is_AddToScript_7 = !disableDemo7RightFields,

    selectedDemo_1 = selectedDemo1Val,
    selectedDemo_2 = selectedDemo2Val,
    selectedDemo_3 = selectedDemo3Val,
    selectedDemo_4 = selectedDemo4Val,
    selectedDemo_5 = selectedDemo5Val,
    selectedDemo_6 = selectedDemo6Val,
    selectedDemo_7 = selectedDemo7Val,

    stepValue_1 = stepValue,
    stepValue_2 = step2Value,
    stepValue_3 = step3Value,
    stepValue_4 = step4Value,
    stepValue_5 = step5Value,
    stepValue_6 = step6Value,
    stepValue_7 = step7Value,

    startingPoint_1 = startingPoint,
    startingPoint_2 = startingPoint2,
    startingPoint_3 = startingPoint3,
    startingPoint_4 = startingPoint4,
    startingPoint_5 = startingPoint5,
    startingPoint_6 = startingPoint6,
    startingPoint_7 = startingPoint7,

    introValue = intro1Value,
    grabberValue = grabber,
    solutionValue = newIntro2,
    outroValue = newOutro,
    selected_outro = selectedOutro1,
    selected_business_process_flow = SelectedBusinessProcessFlow,
    stepDraftColorValue = stepDraftColor,
    BPF = BPFValue,
    is_added_to_script_bpf = !disableBPFField,
    is_added_to_script_intro = !disableGrabberAndSolution,
    is_added_to_script_outro = !disableOutroField,
    isClonedDraft = isDisableLineOfBusiness ? 0 : 1,
  }) => {
    setisDisableReq(
      !rightDemo1 ||
        rightDemo1.trim().length === 0 ||
        !grabberValue ||
        grabberValue.trim().length === 0 ||
        !solutionValue ||
        solutionValue.trim().length === 0 ||
        // !rightBusinessOutcomes ||
        // rightBusinessOutcomes.trim().length === 0 ||
        !rightDemo2 ||
        rightDemo2.trim().length === 0 ||
        !outroValue ||
        outroValue.trim().length === 0 ||
        !BPFValue ||
        BPFValue.trim().length === 0 ||
        stepDraftColor == "Request" ||
        stepDraftColor == "Editing" ||
        stepDraftColor == "Teach Tori"
        ? true
        : false
    );
    setisDisableTeachTori(
      !rightDemo1 ||
        rightDemo1.trim().length === 0 ||
        !grabberValue ||
        grabberValue.trim().length === 0 ||
        !solutionValue ||
        solutionValue.trim().length === 0 ||
        !rightBusinessOutcomes ||
        rightBusinessOutcomes.trim().length === 0 ||
        !rightDemo2 ||
        rightDemo2.trim().length === 0 ||
        !outroValue ||
        outroValue.trim().length === 0 ||
        !BPFValue ||
        BPFValue.trim().length === 0
        ? true
        : false
    );
    setisDisableApproveButton(
      !rightDemo1 ||
        rightDemo1.trim().length === 0 ||
        !grabberValue ||
        grabberValue.trim().length === 0 ||
        !solutionValue ||
        solutionValue.trim().length === 0 ||
        !rightBusinessOutcomes ||
        rightBusinessOutcomes.trim().length === 0 ||
        !rightDemo2 ||
        rightDemo2.trim().length === 0 ||
        !outroValue ||
        outroValue.trim().length === 0 ||
        !BPFValue ||
        BPFValue.trim().length === 0
        ? true
        : false
    );
    const p1 = pName1Ref.current ? pName1Ref.current : productName_1;
    const p2 = pName2Ref.current ? pName2Ref.current : productName_2;
    const bo1Val = BO1Ref.current ? BO1Ref.current : businessOutcome_1;
    const bo2Val = BO2Ref.current ? BO2Ref.current : businessOutcome_2;
    const bo3Val = BO3Ref.current ? BO3Ref.current : businessOutcome_3;
    const bo4Val = BO4Ref.current ? BO4Ref.current : businessOutcome_4;
    const bo5Val = BO5Ref.current ? BO5Ref.current : businessOutcome_5;
    const bo6Val = BO6Ref.current ? BO6Ref.current : businessOutcome_6;
    const bo7Val = BO7Ref.current ? BO7Ref.current : businessOutcome_7;

    const demo_steps = [
      {
        role: role_1,
        step: stepValue_1,
        business_outcome: bo1Val,
        starting_point: startingPoint_1,
        features: demo_1,
        sequence: 1,
        selected_demo: selectedDemo_1,
        right_demo: right_demo_1,
        is_added_to_script: is_AddToScript_1,
      },
      {
        role: role_2,
        step: stepValue_2,
        business_outcome: bo2Val,
        starting_point: startingPoint_2,
        features: demo_2,
        sequence: 2,
        selected_demo: selectedDemo_2,
        right_demo: right_demo_2,
        is_added_to_script: is_AddToScript_2,
      },
      {
        role: role_3,
        step: stepValue_3,
        business_outcome: bo3Val,
        starting_point: startingPoint_3,
        features: demo_3,
        sequence: 3,
        selected_demo: selectedDemo_3,
        right_demo: right_demo_3,
        is_added_to_script: is_AddToScript_3,
      },
      {
        role: role_4,
        step: stepValue_4,
        business_outcome: bo4Val,
        starting_point: startingPoint_4,
        features: demo_4,
        sequence: 4,
        selected_demo: selectedDemo_4,
        right_demo: right_demo_4,
        is_added_to_script: is_AddToScript_4,
      },
      {
        role: role_5,
        step: stepValue_5,
        business_outcome: bo5Val,
        starting_point: startingPoint_5,
        features: demo_5,
        sequence: 5,
        selected_demo: selectedDemo_5,
        right_demo: right_demo_5,
        is_added_to_script: is_AddToScript_5,
      },
      {
        role: role_6,
        step: stepValue_6,
        business_outcome: bo6Val,
        starting_point: startingPoint_6,
        features: demo_6,
        sequence: 6,
        selected_demo: selectedDemo_6,
        right_demo: right_demo_6,
        is_added_to_script: is_AddToScript_6,
      },
      {
        role: role_7,
        step: stepValue_7,
        business_outcome: bo7Val,
        starting_point: startingPoint_7,
        features: demo_7,
        sequence: 7,
        selected_demo: selectedDemo_7,
        right_demo: right_demo_7,
        is_added_to_script: is_AddToScript_7,
      },
    ];
    setIsSaved(false);
    SaveProcessState(
      id,
      video_name,
      p1,
      productURL_1,
      p2,
      productURL_2,
      industryValue,
      lineOfBusiness,
      domainValue,
      summaryValue,
      newIntro,
      outroValue,
      solutionValue,
      grabberValue,
      demo_steps,
      introValue,
      BPF,
      stepDraftColorValue,
      right_business_outcomes,
      selected_outro,
      selected_business_process_flow,
      is_added_to_script_bpf,
      is_added_to_script_intro,
      is_added_to_script_outro,
      business_outcomes_array,
      isClonedDraft
    )
      .then((res) => {
        setIsAutoSaved(true);
        setIsSaved(true);
        setsaveLoading(false);
        if (res.status == false) {
          setmodal1(true);
          setModalMsg1(res.message);
        }
        if (disableConcatenatedBO) {
          setrightBusinessOutcomes(res.concatenated_business_outcomes);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsAutoSaved(true);
        setIsSaved(true);
        setsaveLoading(false);
      });
  };

  const handleCancelSme = () => {
    setapprovalSme(true);
    setIsDisableLineOfBusiness(true);
    CallingSaveDraftAPI({
      isFromSentRequest: true,
      isClonedDraft: 0,
      stepDraftColorValue: "Request",
    });
    gettingSavedDraft();
  };
  const handleCancelHublead = () => {
    setapprovalHublead(true);
    gettingSavedDraft(true);
  };
  const closeHubleadInfo = () => {
    setshowapprovalInfoHublead(false);
  };
  const approveDrfatByDHL = () => {
    setloadingReqApp(true);
    DHLApproveRequest("Editing", draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setloadingReqApp(false);
          gettingSavedDraft(true);
          setshowapprovalInfoSme(false);
          setapprovalHublead(true);
          setcanApprove(false);
        } else {
          setloading(false);
          setsendReqMsg(res.message);
          setisSendReqMsg(true);
          handleApprovalClose();
          setcanApprove(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloadingReqApp(false);
      });
  };
  const cancelRequestDHLApproval = () => {
    setLoadingcancel(true);
    CancelDHLRequest(draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setstepDraftColor("Draft");
          setLoadingcancel(false);
          setshowapprovalInfoSme(false);
          setisDisableReq(
            !rightDemo1 ||
              rightDemo1.trim().length === 0 ||
              !grabber ||
              grabber.trim().length === 0 ||
              !newIntro2 ||
              newIntro2.trim().length === 0 ||
              !rightBusinessOutcomes ||
              rightBusinessOutcomes.trim().length === 0 ||
              !rightDemo2 ||
              rightDemo2.trim().length === 0 ||
              !newOutro ||
              newOutro.trim().length === 0 ||
              !BPFValue ||
              BPFValue.trim().length === 0
              ? true
              : false
          );
        } else {
          setCancelDHLRequestMsg(res.message);
          setisCancelDHLRequest(true);
          setLoadingcancel(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoadingcancel(false);
      });
  };
  const TeachToriHandle = () => {
    setloadingTori(true);
    TeachTori(draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setstepDraftColor("Teach Tori");
          setisDisableReq(true);
          setisDisableTeachTori(true);
          setdisableAllinputs(true);
          setshowapprovalInfoHublead(false);
          setteachTorimodal(true);
          setteachTorimodalMsg(res.message);
          setloadingTori(false);
        } else {
          setloadingTori(false);
          setteachTorimodal(true);
          setteachTorimodalMsg(res.message);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloadingTori(false);
      });
  };
  const listOfProductNames = dummyData;
  const listOfProductNamesFilters = dummyData;

  var multiArr = [];

  const onselectLOB = (e) => {
    var val = e;
    val.map((a) => {
      multiArr.push(a.label);
    });
    setLob1(multiArr);
    if (!multiArr) {
      setlobEr(true);
    } else {
      setlobEr(false);
    }
    setlobEr(multiArr && multiArr.length != 0 ? false : true);
    CallingSaveDraftAPI({ lineOfBusiness: multiArr });
  };

  const onselectDomain = (e) => {
    setDomain(e.label);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ domainValue: e.label });
    }, 500);
    debounceFunction();
  };

  const onRemoveL = (selectedList, removedItem) => {
    Lob1.splice(indexOf(removedItem.name), 1);
  };

  const onselectInd = (e) => {
    setIndursty(e.label);
    if (!e.label) {
      setindEr(true);
    } else {
      setindEr(false);
    }
    CallingSaveDraftAPI({ industryValue: e.label });
    setindEr(e.label ? false : true);
  };
  const onRemoveI = (selectedList, removedItem) => {
    Indursty.splice(indexOf(removedItem.name), 1);
  };
  const onRemove = (selectedList, removedItem) => { };
  const customStylesSelect = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
    }),
  };

  const [draftStateData, setDraftStatedata] = useState({});
  const [step1DataObj, setstep1DataObj] = useState({
    sequence: 1,
  });

  const [SaveDrftStateOne, setSaveDrftStateOne] = useState(false);
  const [SaveDrftStateTwo, setSaveDrftStateTwo] = useState(false);
  const [SaveDrftStateThree, setSaveDrftStateThree] = useState(false);
  const [SaveDrftStateFour, setSaveDrftStateFour] = useState(false);
  const [SaveDrftStateFive, setSaveDrftStateFive] = useState(false);
  const [SaveDrftStateSix, setSaveDrftStateSix] = useState(false);

  const [SaveDrftStateButtonOne, setSaveDrftStateButtonOne] = useState(true);
  const [SaveDrftStateButtonFour, setSaveDrftStateButtonFour] = useState(true);
  const [SaveDrftStateButtonFive, setSaveDrftStateButtonFive] = useState(true);
  const [SaveDrftStateButtonSix, setSaveDrftStateButtonSix] = useState(true);
  const [SaveDrftStateButtonSeven, setSaveDrftStateButtonSeven] =
    useState(true);

  const [SaveDrftStateButtonTwo, setSaveDrftStateButtonTwo] = useState(true);
  const [SaveDrftStateButtonThree, setSaveDrftStateButtonThree] =
    useState(true);
  const [valueLO, setValueLO] = useState([]);

  const gettingSavedDraft = (approve) => {
    const islod = approve ? approve : false;
    if (!islod) {
      setIsLoading(true);
    }
    GetSavedDraftDetails(id)
      .then((res) => {
        if (res.status) {
          setUserIdDraftOwner(res?.data?.user_id)
          setisDisableReqOnEditing(res.data.draft_state == "Editing" || res.data.draft_state == "Draft" || res.data.draft_state == "Request" ? false : true);
          setBusinessOutcomesArray(
            res.data?.business_outcomes == null
              ? businessOutcomesArray
              : res.data?.business_outcomes
          );
          setBusinessOutcomesLength(
            res.data?.business_outcomes == null
              ? businessOutcomesArray
              : res.data?.business_outcomes
          );
          let newLinePrompt = res.data?.business_outcomes?.map(
            (itm) => itm?.name
          );
          setBusinessOutcomesPrompt(newLinePrompt && newLinePrompt?.join("\n"));
          setBusinessOutcomesString(res.data?.right_business_outcomes);
          let newArrLo = [];
          res.data.line_of_business.map((er, i) => {
            newArrLo.push({ value: i, label: er });
          });
          setValueLO(newArrLo);
          setIsDisableLineOfBusiness(res.data.just_cloned == 1 ? false : true);
          setisDisableReq(
            !res.data.right_business_outcomes ||
              !res.data.demo_steps[0].right_demo ||
              !res.data.demo_steps[1].right_demo ||
              !res.data.intro_solution ||
              !res.data.intro ||
              !res.data.outro ||
              !res.data.business_process_flow ||
              res.data.draft_state == "Request" ||
              res.data.draft_state == "Editing" ||
              res.data.draft_state == "Teach Tori"
              ? true
              : false
          );
          setisDisableTeachTori(
            !res.data.right_business_outcomes ||
              !res.data.demo_steps[0].right_demo ||
              !res.data.demo_steps[1].right_demo ||
              !res.data.intro_solution ||
              !res.data.intro ||
              !res.data.outro ||
              !res.data.business_process_flow
              ? true
              : false
          );
          setisDisableApproveButton(
            !res.data.right_business_outcomes ||
              !res.data.demo_steps[0].right_demo ||
              !res.data.demo_steps[1].right_demo ||
              !res.data.intro_solution ||
              !res.data.intro ||
              !res.data.outro ||
              !res.data.business_process_flow
              ? true
              : false
          );
          if (
            res.data.demo_steps[0].selected_demo ||
            res.data.demo_steps[0].is_added_to_script == 1
          ) {
            setShowPlusBtn(false);
            setShowMinusBtn(false);
            setSaveDrftStateOne(true);
            setdisableDemo1Fields(true);
            setdisableDemo1RightFields(false);
            setSaveDrftStateButtonOne(false);
            setSaveDrftStateButtonTwo(true);
            setRegenerateDemoScript(false);
          }
          if (
            res.data.demo_steps[0].selected_demo &&
            res.data.right_business_outcomes
          ) {
            setCombinedBusinessOutcomes(res.data.right_business_outcomes);
          }
          if (
            res.data.demo_steps[1].selected_demo ||
            res.data.demo_steps[1].is_added_to_script == 1
          ) {
            setSaveDrftStateTwo(true);
            setdisableDemo2Fields(true);
            setdisableDemo2RightFields(false);
            setSaveDrftStateOne(true);
            setSaveDrftStateButtonOne(false);
            setSaveDrftStateButtonTwo(false);
            setSaveDrftStateButtonThree(true);
            setGenerateDemoScript(false);
          }
          if (
            res.data.demo_steps[2].selected_demo ||
            res.data.demo_steps[2].is_added_to_script == 1
          ) {
            setGenerateDemoScript1(false);
            setSaveDrftStateThree(true);
            setSaveDrftStateButtonTwo(false);
            setSaveDrftStateButtonThree(false);
            setdisableDemo3Fields(true);
            setdisableDemo3RightFields(false);
          }

          if (
            (res.data.selected_business_process_flow &&
              !res.data.intro_solution) ||
            res.data.is_added_to_script_bpf == 1
          ) {
            setSelectionDemoGenerate(true);
            setoutNon1(false);
            setSaveDrftStateButtonThree(false);
          }
          if (
            res.data.demo_steps[6].selected_demo ||
            res.data.is_added_to_script_bpf == 1
          ) {
            setDisableConcatenatedBO(false);
          }
          if (res.data.second_product_name != null) {
            setShowProductNext(true);
            setplusIcoState(false);
          }
          if (!res.data.outro && res.data.intro_solution) {
            setoutNon1(true);
            setSaveDrftStateButtonThree(false);
          }
          if (res.data.draft_state == "Request") {
            setshowapprovalInfoSme(true);
          }
          if (res.data.draft_state == "Editing") {
            setshowapprovalInfoHublead(true);
          }
          if (
            res.data.draft_state == "Teach Tori" ||
            res.data.draft_state == "Trained"
          ) {
            setdisableAllinputs(true);
            setisDisableReq(true);
          }
          if (res.data.second_product_name) {
            setShowPlusBtn(false);
          }
          if (res.data.outro) {
            setSaveDrftStateButtonThree(false);
            setSaveDrftStateButtonFour(false);
            setSaveDrftStateButtonFive(false);
            setSaveDrftStateButtonSix(false);

            setdisableOutroField(false);
            setdisableDemo3Fields(true);
          }
          if (res.data.intro_solution) {
            setdisableGrabberAndSolution(false);
            setdisableDemo3Fields(true);
          }
          if (
            res.data.intro_model_response ||
            res.data.is_added_to_script_intro == 1
          ) {
            setdisableGrabberAndSolution(false);
          }
          if (
            res.data.selected_outro ||
            res.data.is_added_to_script_outro == 1
          ) {
            setdisableOutroField(false);
            setselectedOutro1(res.data.selected_outro);
          }
          if (
            (res.data.demo_steps[3].business_outcome ||
              res.data.demo_steps[3].starting_point ||
              res.data.demo_steps[3].step ||
              res.data.demo_steps[3].features) &&
            res.data.is_added_to_script_bpf == 1
          ) {
            setSaveDrftStateThree(true);
          }
          if (
            (res.data.demo_steps[4].business_outcome ||
              res.data.demo_steps[4].starting_point ||
              res.data.demo_steps[4].step ||
              res.data.demo_steps[4].features) &&
            res.data.is_added_to_script_bpf == 1
          ) {
            setSaveDrftStateFour(true);
          }
          if (
            (res.data.demo_steps[5].business_outcome ||
              res.data.demo_steps[5].starting_point ||
              res.data.demo_steps[5].step ||
              res.data.demo_steps[5].features) &&
            res.data.is_added_to_script_bpf == 1
          ) {
            setSaveDrftStateFive(true);
          }
          if (
            (res.data.demo_steps[6].business_outcome ||
              res.data.demo_steps[6].starting_point ||
              res.data.demo_steps[6].step ||
              res.data.demo_steps[6].features) &&
            res.data.is_added_to_script_bpf == 1
          ) {
            setSaveDrftStateSix(true);
          }
          if (
            res.data.demo_steps[3].selected_demo ||
            res.data.demo_steps[3].is_added_to_script == 1
          ) {
            setdisableDemo4RightFields(false);
            setdisableDemo4Fields(true);
            setGenerateDemoScript4(false);
            // setshowGenerateBPF(true);

            setSaveDrftStateThree(true);
            setSaveDrftStateButtonThree(false);
            setSaveDrftStateButtonFour(false);

            setSaveDrftStateFour(true);
          }
          if (
            res.data.demo_steps[4].selected_demo ||
            res.data.demo_steps[4].is_added_to_script == 1
          ) {
            setSaveDrftStateFour(true);
            setSaveDrftStateButtonFour(false);
            setSaveDrftStateButtonFive(false);

            setdisableDemo5RightFields(false);
            setdisableDemo5Fields(true);
            setGenerateDemoScript5(false);
            setSaveDrftStateFive(true);
          }
          if (
            res.data.demo_steps[5].selected_demo ||
            res.data.demo_steps[5].is_added_to_script == 1
          ) {
            setSaveDrftStateFive(true);
            setSaveDrftStateButtonFive(false);
            setSaveDrftStateButtonSix(false);
            setdisableDemo6RightFields(false);
            setdisableDemo6Fields(true);
            setGenerateDemoScript6(false);

            setSaveDrftStateButtonSeven(true);
            if (!res.data.is_added_to_script_bpf == 1) {
              setSaveDrftStateSix(true);
            }
          }
          if (
            res.data.demo_steps[6].selected_demo ||
            res.data.demo_steps[6].is_added_to_script == 1
          ) {
            setSaveDrftStateSix(true);
            setSaveDrftStateButtonSeven(false);

            setdisableDemo7RightFields(false);
            setdisableDemo7Fields(true);
            setGenerateDemoScript7(false);
            if (res.data.is_added_to_script_bpf == 1) {
              setshowGenerateBPF(false);
            } else {
              setshowGenerateBPF(true);
            }
          }
          if (
            res.data.selected_business_process_flow ||
            res.data.is_added_to_script_bpf == 1
          ) {
            setshowGenerateBPF(false);
            setSaveDrftStateButtonTwo(false);
            setdisableDemo3Fields(true);
            setdisableDemo4Fields(true);

            setdisableDemo5Fields(true);
            setdisableDemo6Fields(true);
            setdisableDemo7Fields(true);

            setSaveDrftStateButtonThree(false);
            setSaveDrftStateButtonFour(false);
            setSaveDrftStateButtonFive(false);
            setSaveDrftStateButtonSix(false);
            setSaveDrftStateButtonSeven(false);

            setdisableBPField(false);
            if (
              res.data.demo_steps[2].selected_demo ||
              res.data.demo_steps[2].role ||
              res.data.demo_steps[2].starting_point ||
              res.data.demo_steps[2].step ||
              res.data.demo_steps[2].business_outcome ||
              (res.data.demo_steps[2].features &&
                res.selected_business_process_flow)
            ) {
              setSaveDrftStateTwo(true);
            } else {
              setSaveDrftStateTwo(false);
            }
            setSaveDrftStateThree(
              res.data.demo_steps[3].selected_demo ||
                res.data.demo_steps[3].role ||
                res.data.demo_steps[3].starting_point ||
                res.data.demo_steps[3].step ||
                res.data.demo_steps[3].business_outcome ||
                (res.data.demo_steps[3].features &&
                  res.selected_business_process_flow)
                ? true
                : false
            );
            setSaveDrftStateFour(
              res.data.demo_steps[4].selected_demo ||
                res.data.demo_steps[4].role ||
                res.data.demo_steps[4].starting_point ||
                res.data.demo_steps[4].step ||
                res.data.demo_steps[4].business_outcome ||
                (res.data.demo_steps[4].features &&
                  res.selected_business_process_flow)
                ? true
                : false
            );
            setSaveDrftStateFive(
              res.data.demo_steps[5].selected_demo ||
                res.data.demo_steps[5].role ||
                res.data.demo_steps[5].starting_point ||
                res.data.demo_steps[5].step ||
                res.data.demo_steps[5].business_outcome ||
                (res.data.demo_steps[5].features &&
                  res.selected_business_process_flow)
                ? true
                : false
            );
            setSaveDrftStateSix(
              res.data.demo_steps[6].selected_demo ||
                res.data.demo_steps[6].role ||
                res.data.demo_steps[6].starting_point ||
                res.data.demo_steps[6].step ||
                res.data.demo_steps[6].business_outcome ||
                (res.data.demo_steps[6].features &&
                  res.selected_business_process_flow)
                ? true
                : false
            );
          }
          if (
            res.data.is_added_to_script_intro == 1 &&
            res.data.intro_solution
          ) {
            setSelectionDemoGenerate(false);
          }
          setDraftStatedata(res.data);
          setdraftSharedWith(res.data.share_user_names);
          setdraftCreatedBy(res.data.created_by);
          setstepDraftColor(res.data.draft_state);
          setcanApprove(res.data.approve_button);
          setVideoName(res.data.video_name);
          setFirstProductUrl(res.data.first_product_url);
          setSecondProductUrl(res.data.second_product_url);
          setBO1(res.data.demo_steps[0].business_outcome);
          setStepValue(res.data.demo_steps[0].step);
          setStep2Value(res.data.demo_steps[1].step);
          setStep3Value(res.data.demo_steps[2].step);
          setStep4Value(res.data.demo_steps[3].step);
          setStep5Value(res.data.demo_steps[4].step);
          setStep6Value(res.data.demo_steps[5].step);
          setStep7Value(res.data.demo_steps[6].step);

          setPname(res.data.first_product_name);
          setProduct1Name(res.data.first_product_name);
          setPname2(res.data.second_product_name);
          setProduct2Name(res.data.second_product_name);
          setUseCase(res.data.description);

          setProtagonist(res.data.demo_steps[0].role);
          setStartingPoint(res.data.demo_steps[0].starting_point);
          setStartingPoint2(res.data.demo_steps[1].starting_point);
          setStartingPoint3(res.data.demo_steps[2].starting_point);
          setStartingPoint4(res.data.demo_steps[3].starting_point);
          setStartingPoint5(res.data.demo_steps[4].starting_point);
          setStartingPoint6(res.data.demo_steps[5].starting_point);
          setStartingPoint7(res.data.demo_steps[6].starting_point);

          setrightBusinessOutcomes(res.data.right_business_outcomes);
          setDemo1(res.data.demo_steps[0].features);
          setDemo2(res.data.demo_steps[1].features);
          setDemo3(res.data.demo_steps[2].features);
          setDemo4(res.data.demo_steps[3].features);
          setDemo5(res.data.demo_steps[4].features);
          setDemo6(res.data.demo_steps[5].features);
          setDemo7(res.data.demo_steps[6].features);

          setSBO2(res.data.demo_steps[1].business_outcome);
          setselectedDemo1Val(res.data.demo_steps[0].selected_demo);
          setselectedDemo2Val(res.data.demo_steps[1].selected_demo);
          setselectedDemo3Val(res.data.demo_steps[2].selected_demo);
          setselectedDemo4Val(res.data.demo_steps[3].selected_demo);
          setselectedDemo5Val(res.data.demo_steps[4].selected_demo);
          setselectedDemo6Val(res.data.demo_steps[5].selected_demo);
          setselectedDemo7Val(res.data.demo_steps[6].selected_demo);

          setgrabber(res.data.intro);
          setFinalOutro(res.data.outro);
          setBPFValue(res.data.business_process_flow);
          setSelectedBusinessProcessFlow(
            res.data.selected_business_process_flow
          );
          setprotagnist2(res.data.demo_steps[1].role);
          setnewIntro2(res.data.intro_solution);
          setSBO3(res.data.demo_steps[2].business_outcome);
          setprotagnist3(res.data.demo_steps[2].role);
          setSBO4(res.data.demo_steps[3].business_outcome);
          setSBO5(res.data.demo_steps[4].business_outcome);
          setSBO6(res.data.demo_steps[5].business_outcome);
          setSBO7(res.data.demo_steps[6].business_outcome);
          setHubleadNames(res.data.hublead_name);

          setprotagnist4(res.data.demo_steps[3].role);
          setprotagnist5(res.data.demo_steps[4].role);
          setprotagnist6(res.data.demo_steps[5].role);
          setprotagnist7(res.data.demo_steps[6].role);

          setIndursty(res.data.industry);
          setLob1(res.data.line_of_business);
          setDomain(res.data.domain);
          setintro1Value(res.data.intro_model_response);
          setRightDemo1(res.data.demo_steps[0].right_demo);
          setRightDemo2(res.data.demo_steps[1].right_demo);
          setRightDemo3(res.data.demo_steps[2].right_demo.trim());
          setRightDemo4(res.data.demo_steps[3].right_demo);
          setRightDemo5(res.data.demo_steps[4].right_demo);
          setRightDemo6(res.data.demo_steps[5].right_demo);
          setRightDemo7(res.data.demo_steps[6].right_demo);

          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsLoading(false);
      });
  };
  const [canClear, setCanClear] = useState(false);
  const [showClear, setShowclear] = useState(false);
  const [showClearMsg, setShowclearMsg] = useState("");
  const ClearAll = () => {
    setclearLoading(true);
    ClearDraft(draftStateData && draftStateData.id)
      .then((res) => {
        setCanClear(res.status);
        if (res.status) {
          setclearLoading(false);
          setClearAllModal(false);
          window.location.reload(false);
        } else {
          setclearLoading(false);
          setClearAllModal(false);
          setShowclear(true);
          setShowclearMsg(res.message);
        }
        // res.stal();tus && ClearAl
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setclearLoading(false);
        setClearAllModal(false);
      });
  };
  const [selectedDemo1, setselectedDemo1] = useState("");
  const [selectedDemo2, setselectedDemo2] = useState("");
  const [selectedDemo3, setselectedDemo3] = useState("");
  const [selectedDemo4, setselectedDemo4] = useState("");
  const [selectedDemo5, setselectedDemo5] = useState("");
  const [selectedDemo6, setselectedDemo6] = useState("");
  const [selectedDemo7, setselectedDemo7] = useState("");

  const [StateOne, setStateOne] = useState(false);
  const [StateTwo, setStateTwo] = useState(false);
  const [StateThree, setStateThree] = useState(false);
  const [StateFour, setStateFour] = useState(false);
  const [StateFive, setStateFive] = useState(false);
  const [StateSix, setStateSix] = useState(false);
  const [StateSeven, setStateSeven] = useState(false);

  const [pName, setPname] = useState("");
  const [pName2, setPname2] = useState("");
  const [firstProductUrl, setFirstProductUrl] = useState("");
  const [SecondProductUrl, setSecondProductUrl] = useState("");
  const [useCase, setUseCase] = useState("");
  const [Indursty, setIndursty] = useState();
  const [Protagonist, setProtagonist] = useState("");
  const [stepValue, setStepValue] = useState("");
  const [step2Value, setStep2Value] = useState("");
  const [step3Value, setStep3Value] = useState("");
  const [step4Value, setStep4Value] = useState("");
  const [step5Value, setStep5Value] = useState("");
  const [step6Value, setStep6Value] = useState("");
  const [step7Value, setStep7Value] = useState("");

  const [startingPoint, setStartingPoint] = useState("");
  const [startingPoint2, setStartingPoint2] = useState("");
  const [startingPoint3, setStartingPoint3] = useState("");
  const [startingPoint4, setStartingPoint4] = useState("");
  const [startingPoint5, setStartingPoint5] = useState("");
  const [startingPoint6, setStartingPoint6] = useState("");
  const [startingPoint7, setStartingPoint7] = useState("");

  const [BO1, setBO1] = useState("");
  const [SBO2, setSBO2] = useState("");
  const [SBO3, setSBO3] = useState("");
  const [SBO4, setSBO4] = useState("");
  const [SBO5, setSBO5] = useState("");
  const [SBO6, setSBO6] = useState("");
  const [SBO7, setSBO7] = useState("");

  const [rightBusinessOutcomes, setrightBusinessOutcomes] = useState("");
  const [combinedBusinessOutcomes, setCombinedBusinessOutcomes] = useState("");
  const [VideoName, setVideoName] = useState("");
  const [Demo1, setDemo1] = useState("");
  const [Demo1Two, setDemo1Two] = useState("");
  const [Demo1Three, setDemo1Three] = useState("");
  const [rightDemo1, setRightDemo1] = useState("");
  const [rightDemo2, setRightDemo2] = useState("");
  const [rightDemo3, setRightDemo3] = useState("");
  const [rightDemo4, setRightDemo4] = useState("");
  const [rightDemo5, setRightDemo5] = useState("");
  const [rightDemo6, setRightDemo6] = useState("");
  const [rightDemo7, setRightDemo7] = useState("");

  const [Demo2, setDemo2] = useState("");
  const [Demo3, setDemo3] = useState("");
  const [Demo4, setDemo4] = useState("");
  const [Demo5, setDemo5] = useState("");
  const [Demo6, setDemo6] = useState("");
  const [Demo7, setDemo7] = useState("");

  const [resA1, setResA1] = useState("");
  const [resA2, setResA2] = useState("");
  const [resB1, setResB1] = useState("");
  const [resB2, setResB2] = useState("");
  const [resC1, setResC1] = useState("");
  const [resC1_2, setResC1_2] = useState("");
  const [resC2, setResC2] = useState("");
  const [resC2_1, setResC2_1] = useState("");
  const [demo5opt1val, setdemo5opt1val] = useState("");
  const [demo5opt2val, setdemo5opt2val] = useState("");
  const [demo6opt1val, setdemo6opt1val] = useState("");
  const [demo6opt2val, setdemo6opt2val] = useState("");
  const [demo7opt1val, setdemo7opt1val] = useState("");
  const [demo7opt2val, setdemo7opt2val] = useState("");

  const [loading, setloading] = useState(false);
  const [isGenerateDemoloading, setIsGenerateDemoloading] = useState(false);
  const [disabledInputs, setdisabledInputs] = useState(false);
  const [disableDemo1Fields, setdisableDemo1Fields] = useState(false);
  const [disableDemo2Fields, setdisableDemo2Fields] = useState(false);
  const [disableDemo3Fields, setdisableDemo3Fields] = useState(false);
  const [disableDemo4Fields, setdisableDemo4Fields] = useState(false);
  const [disableDemo5Fields, setdisableDemo5Fields] = useState(false);
  const [disableDemo6Fields, setdisableDemo6Fields] = useState(false);
  const [disableDemo7Fields, setdisableDemo7Fields] = useState(false);

  const [disableDemo1RightFields, setdisableDemo1RightFields] = useState(true);
  const [disableDemo2RightFields, setdisableDemo2RightFields] = useState(true);
  const [disableDemo3RightFields, setdisableDemo3RightFields] = useState(true);
  const [disableDemo4RightFields, setdisableDemo4RightFields] = useState(true);
  const [disableDemo5RightFields, setdisableDemo5RightFields] = useState(true);
  const [disableDemo6RightFields, setdisableDemo6RightFields] = useState(true);
  const [disableDemo7RightFields, setdisableDemo7RightFields] = useState(true);

  const [disableGrabberAndSolution, setdisableGrabberAndSolution] =
    useState(true);
  const [disableOutroField, setdisableOutroField] = useState(true);
  const [disableBPFField, setdisableBPField] = useState(true);
  const [disableConcatenatedBO, setDisableConcatenatedBO] = useState(true);
  const [selectedOutro, setselectedOutro] = useState("");
  const [disabledInputsOnNext, setdisabledInputsOnNext] = useState(false);
  const [disabledInputsd2, setdisabledInputsd2] = useState(false);
  const [disabledInputsd3, setdisabledInputsd3] = useState(false);
  const [disabledInputsd4, setdisabledInputsd4] = useState(false);
  const [disabledInputsd5, setdisabledInputsd5] = useState(false);
  const [disabledInputsd6, setdisabledInputsd6] = useState(false);
  const [disabledInputsd7, setdisabledInputsd7] = useState(false);

  const [isChecked, setisChecked] = useState(false);
  const [isChecked2, setisChecked2] = useState(false);
  const [isChecked3, setisChecked3] = useState(false);
  const [isChecked4, setisChecked4] = useState(false);
  const [isChecked5, setisChecked5] = useState(false);
  const [isChecked6, setisChecked6] = useState(false);
  const [isChecked12, setisChecked12] = useState(false);
  const [isChecked11, setisChecked11] = useState(false);
  const [isChecked13, setisChecked13] = useState(false);
  const [isChecked14, setisChecked14] = useState(false);
  const [isChecked15, setisChecked15] = useState(false);
  const [isChecked16, setisChecked16] = useState(false);
  const [isChecked17, setisChecked17] = useState(false);
  const [isChecked18, setisChecked18] = useState(false);

  const [isChecked7, setisChecked7] = useState(false);
  const [isCheckedBPF1, setisCheckedBPF1] = useState(false);
  const [isShowOutroAddToScriptBPF, setisShowOutroAddToScriptBPF] =
    useState(false);
  const [isCheckedBPF2, setisCheckedBPF2] = useState(false);

  const [isChecked8, setisChecked8] = useState(false);
  const [isChecked9, setisChecked9] = useState(false);
  const [isChecked10, setisChecked10] = useState(false);
  const [selectedDemo1Val, setselectedDemo1Val] = useState("");
  const [selectedDemo2Val, setselectedDemo2Val] = useState("");
  const [selectedDemo3Val, setselectedDemo3Val] = useState("");
  const [selectedDemo4Val, setselectedDemo4Val] = useState("");
  const [selectedDemo5Val, setselectedDemo5Val] = useState("");
  const [selectedDemo6Val, setselectedDemo6Val] = useState("");
  const [selectedDemo7Val, setselectedDemo7Val] = useState("");

  const [grabber, setgrabber] = useState("");
  const [newIntro, setnewIntro] = useState("");
  const [newIntroCheckValue, setnewIntroCheckValue] = useState("");
  const [SelectedBusinessProcessFlow, setSelectedBusinessProcessFlow] =
    useState("");

  const [newIntro2CheckValue, setnewIntro2CheckValue] = useState("");
  const [newIntro2, setnewIntro2] = useState("");

  const [BPFValue, setBPFValue] = useState("");
  const [BPFValueCheck, setBPFValueCheck] = useState("");

  const [newOutro, setnewOutro] = useState("");
  const [lastLoading, setlastLoading] = useState(false);
  const [BPFLoading, setBPFLoading] = useState(false);

  const [outroLoading, setoutroLoading] = useState(false);
  const [outroState1, setoutroState1] = useState("");
  const [outroState2, setoutroState2] = useState("");
  const [ShowProductNext, setShowProductNext] = useState(false);
  const [plusIcoState, setplusIcoState] = useState(true);
  const [demo3dis, setdemo3dis] = useState(false);
  const [DEMO4OP, setDEMO4OP] = useState(false);
  const [DEMO5OP, setDEMO5OP] = useState(false);
  const [DEMO6OP, setDEMO6OP] = useState(false);
  const [DEMO7OP, setDEMO7OP] = useState(false);

  const [isFinish, setIsFinish] = useState(false);
  const [approvalSme, setapprovalSme] = useState(false);
  const [selectedOutro1, setselectedOutro1] = useState("");

  const pName1Ref = React.useRef(pName);
  const pName2Ref = React.useRef(pName2);
  const BO1Ref = React.useRef(BO1);
  const BO2Ref = React.useRef(SBO2);
  const BO3Ref = React.useRef(SBO3);
  const BO4Ref = React.useRef(SBO4);
  const BO5Ref = React.useRef(SBO5);
  const BO6Ref = React.useRef(SBO6);
  const BO7Ref = React.useRef(SBO7);

  const finalSelectedOutro = React.useRef(newOutro);

  function setProduct1Name(p) {
    pName1Ref.current = p;
    setPname(p);
  }
  function setProduct2Name(p) {
    pName2Ref.current = p;
    setPname2(p);
  }
  function setBusinessOutcome1(p) {
    BO1Ref.current = p;
    setBO1(p);
  }
  function setBusinessOutcome2(p) {
    BO2Ref.current = p;
    setSBO2(p);
    CallingSaveDraftAPI({ businessOutcome_2: p });
  }
  function setBusinessOutcome3(p) {
    BO3Ref.current = p;
    setSBO3(p);
    CallingSaveDraftAPI({ businessOutcome_3: p });
  }
  function setBusinessOutcome4(p) {
    BO4Ref.current = p;
    setSBO4(p);
    CallingSaveDraftAPI({ businessOutcome_4: p });
  }
  function setBusinessOutcome5(p) {
    BO5Ref.current = p;
    setSBO5(p);
    CallingSaveDraftAPI({ businessOutcome_5: p });
  }
  function setBusinessOutcome6(p) {
    BO6Ref.current = p;
    setSBO6(p);
    CallingSaveDraftAPI({ businessOutcome_6: p });
  }
  function setBusinessOutcome7(p) {
    BO7Ref.current = p;
    setSBO7(p);
    CallingSaveDraftAPI({ businessOutcome_7: p });
  }
  function setFinalOutro(p) {
    finalSelectedOutro.current = p;
    setnewOutro(p);
  }

  const out1 = (e) => {
    setoutroState1(e.target.value);
  };
  const out2 = (e) => {
    setoutroState2(e.target.value);
  };

  const [Fintro1, setFintro1] = useState("");
  const [BPFOpt1, setBPFOpt1] = useState("");
  const [BPFOpt2, setBPFOpt2] = useState("");

  const [Foutro1, setFoutro1] = useState("");
  const [Fintro2, setFintro2] = useState("");
  const [Foutro2, setFoutro2] = useState("");
  const [protagnist2, setprotagnist2] = useState("");
  const [protagnist3, setprotagnist3] = useState("");
  const [protagnist4, setprotagnist4] = useState("");
  const [protagnist5, setprotagnist5] = useState("");
  const [protagnist6, setprotagnist6] = useState("");
  const [protagnist7, setprotagnist7] = useState("");

  const [Floading, setFloading] = useState(false);
  const [RegenerateDemoScript, setRegenerateDemoScript] = useState(true);
  const [next1None, setnext1None] = useState(false);
  const [next2None, setnext2None] = useState(false);
  const [next3None, setnext3None] = useState(false);
  const [showAddToScriptBtn5, setshowAddToScriptBtn5] = useState(false);
  const [showAddToScriptBtn6, setshowAddToScriptBtn6] = useState(false);
  const [showAddToScriptBtn7, setshowAddToScriptBtn7] = useState(false);

  const [SelectionDemoGenerate, setSelectionDemoGenerate] = useState(false);
  const [showAddToScriptBtn4, setshowAddToScriptBtn4] = useState(false);
  const [outNon1, setoutNon1] = useState(false);
  const [section1dis, setsection1dis] = useState(true);
  const [outNon2, setoutNon2] = useState(false);
  const [finishClick, setfinishClick] = useState(true);
  const [section2dis, setsection2dis] = useState(true);
  const [state1, setstate1] = useState(false);
  const [isGenerateDemoScript, setGenerateDemoScript] = useState(true);
  const [regenerate, setregenerate] = useState(false);
  const [isGenerateDemoScript1, setGenerateDemoScript1] = useState(true);
  const [isGenerateDemoScript4, setGenerateDemoScript4] = useState(true);
  const [isGenerateDemoScript5, setGenerateDemoScript5] = useState(true);
  const [isGenerateDemoScript6, setGenerateDemoScript6] = useState(true);
  const [isGenerateDemoScript7, setGenerateDemoScript7] = useState(true);

  const [dis76546_1, setdis76546_1] = useState(true);
  const [dis0988_11, setdis0988_11] = useState(false);
  const [lastDivNone, setlastDivNone] = useState(false);
  const [showBPF, setshowBPF] = useState(false);

  const [reGenIntro, setreGenIntro] = useState(false);
  const [FOROutro, setFOROutro] = useState(false);
  const [wordFileData, setwordFileData] = useState();
  const [regenerateDemo, setregenerateDemo] = useState(true);
  const [ShowPlusBtn, setShowPlusBtn] = useState(true);
  const [ShowMinusBtn, setShowMinusBtn] = useState(true);
  const [draftSharedWith, setdraftSharedWith] = useState();
  const [BusinessOutcomes, setBusinessOutcomes] = useState("");
  const [teachTorimodalMsg, setteachTorimodalMsg] = useState("");
  const [teachTorimodal, setteachTorimodal] = useState(false);
  const [clearAllModal, setClearAllModal] = useState(false);
  const [clearloading, setclearLoading] = useState(false);

  const int1 = (e) => {
    setFintro1(e.target.value);
  };
  const int2 = (e) => {
    setFintro2(e.target.value);
  };
  const BPFOpt1Change = (e) => {
    setBPFOpt1(e.target.value);
  };
  const BPFOpt2Change = (e) => {
    setBPFOpt2(e.target.value);
  };

  const [bo1RES, setbo1RES] = useState();
  const [bo1RES_1, setbo1RES_1] = useState("");
  const [bo1RES_2, setbo1RES_2] = useState("");
  const [bo1RES_3, setbo1RES_3] = useState("");
  const [previousPath, setPreviousPath] = useState("unknown");

  useEffect(() => {
    if (sessionStorage.getItem("ParemId")) {
      sessionStorage.removeItem("ParemId");
    } else {
      sessionStorage.setItem("ParemId", pathname);
    }
    if (history.location.state == undefined) {
      setPreviousPath("unknown");
    } else {
      setPreviousPath(history.location.state && history.location.state.from);
    }
    scrollToTop();
  }, []);

  useEffect(() => {
    GetLineOfBusinessData().then((res) => {
      if (res.status) {
        setLineOfBusinessData(res.data);
      }
    });
    GetIndustriesData().then((res) => {
      if (res.status) {
        setIndustryData(res.data);
      }
    });
    GetDomainsData().then((res) => {
      if (res.status) {
        setDomainsData(res.data);
      }
    });
    getBusinessOutcomesList().then((res) => {
      if (res.status) {
        setboDropdownData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    $("form").submit(function () {
      var radioValue = $("input[name='options']:checked").val();
      if (radioValue) {
        setmodal5(true);
        setModalMsg5("You selected - " + radioValue);
      }
      return false;
    });

    autosize();
    function autosize() {
      var text = $(".autosize");

      text.each(function () {
        $(this).attr("rows", 1);
        resize($(this));
      });

      text.on("input", function () {
        resize($(this));
      });

      function resize($text) {
        $text.css("height", "auto");
        $text.css("height", $text[0].scrollHeight + "px");
      }
    }
  });

  useEffect(() => {
    gettingSavedDraft();
  }, []);
  const onBackClick = () => {
    if (previousPath == "startdraft" || previousPath == "drafttable") {
      history.goBack();
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    } else if (previousPath == "login") {
      history.push("/user/drafts");
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    } else {
      history.push("/user/drafts");
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    }
    sessionStorage.removeItem("ParemId");
  };
  const onChangeRightDemo1 = (e) => {
    setRightDemo1(e.target.value);
    setStateOne(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeRightDemo2 = (e) => {
    setRightDemo2(e.target.value);
    setStateTwo(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_2: e.target.value });
    }, 1000);
    debounceFunction();
  };

  const OnChnageOutroF = (e) => {
    setnewOutro(e.target.value);
  };
  const OnchangeDEmoF3 = (e) => {
    setselectedDemo3Val(e.target.value);
  };
  const OnchangeDemoRes4 = (e) => {
    setselectedDemo4Val(e.target.value);
  };
  const onChangeBoF3 = (e) => {
    setSBO3(e.target.value);
  };
  const onChangeBoF55 = (e) => {
    setSBO4(e.target.value);
  };
  const OnChnageBof2 = (e) => {
    setSBO2(e.target.value);
  };
  const yess = (e) => {
    setselectedDemo1Val(e.target.value);
    setStateOne(true);
  };
  const yess2 = (e) => {
    setselectedDemo2Val(e.target.value);
    setStateTwo(true);
  };
  const yess3 = (e) => {
    setRightDemo3(e.target.value.trim());
    setStateThree(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const yess4 = (e) => {
    setRightDemo4(e.target.value);
    setStateFour(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_4: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const yess5 = (e) => {
    setRightDemo5(e.target.value);
    setStateFive(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_5: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onchangeGrabber = (e) => {
    setgrabber(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ grabberValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const yess6 = (e) => {
    setRightDemo6(e.target.value);
    setStateSix(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_6: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const changeoutro = (e) => {
    setnewOutro(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ outroValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const yess7 = (e) => {
    setRightDemo7(e.target.value);
    setStateSeven(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_7: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeSolution = (e) => {
    setnewIntro2(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ solutionValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeBPF = (e) => {
    setBPFValue(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ BPF: e.target.value });
    }, 1000);
    debounceFunction();
  };

  const ONchangeboo1 = (e) => {
    setrightBusinessOutcomes(e.target.value);
    setBusinessOutcomesString(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_business_outcomes: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChanegINTRO = (e) => {
    setnewIntro(e.target.value);
  };

  const onChangePname = (e) => {
    pName1Ref.current = e.target.value;
    setPname(e.target.value);
    // setproer(pName1Ref.current.length != 0 ? false : true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productName_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangePname2 = (e) => {
    pName2Ref.current = e.target.value;
    setPname2(e.target.value);
    setDraftStatedata({
      ...draftStateData,
      second_product_name: e.target.value,
    });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productName_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeFirstProductUrl = (e) => {
    setFirstProductUrl(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productURL_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeSecondProductUrl = (e) => {
    setSecondProductUrl(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productURL_2: e.target.value });
    }, 1000);
    debounceFunction();
  };

  const onFocusProductName2 = () => {
    setSearchBox2(true);
    setDummyArr(tree);
  };
  const onFoucusOutP2 = () => {
    setTimeout(() => {
      setSearchBox2(false);
    }, 200);
    // CallingSaveDraftAPI({})
  };
  const selectTextPN = (e) => {
    setProduct1Name(e);
    // setproer(pName1Ref.current.length != 0 ? false : true);
    CallingSaveDraftAPI({});
  };
  const selectTextPN2 = (e) => {
    setProduct2Name(e);
    CallingSaveDraftAPI({});
  };
  const selectedTextBO = (e) => {
    setBusinessOutcome1(e);
  };
  const selectedTextBO2 = (e) => {
    setBusinessOutcome2(e);
  };
  const selectedTextBO3 = (e) => {
    setBusinessOutcome3(e);
  };
  const selectedTextBO4 = (e) => {
    setBusinessOutcome4(e);
  };
  const selectedTextBO5 = (e) => {
    setBusinessOutcome5(e);
  };
  const selectedTextBO6 = (e) => {
    setBusinessOutcome6(e);
  };
  const selectedTextBO7 = (e) => {
    setBusinessOutcome7(e);
  };

  const onChangeUseCase = (e) => {
    setUseCase(e.target.value);
    setsummaryEr(e.target.value && e.target.value.length != 0 ? false : true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ summaryValue: e.target.value });
    }, 1000);
    debounceFunction();
    setDraftStatedata({ ...draftStateData, description: e.target.value });
  };
  const onChangeIndursty = (e) => {
    let abc = e.target.value;
    setIndursty(abc);
  };
  const onChangeProtagonist = (e) => {
    setProtagonist(e.target.value);
    setDraftStatedata({ ...draftStateData, role1: e.target.value });
    setstep1DataObj({ ...step1DataObj, role: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStepValue = (e) => {
    setstep1Error(e.target.value.length != 0 ? false : true);
    setStepValue(e.target.value);
    setDraftStatedata({ ...draftStateData, step1: e.target.value });
    setstep1DataObj({ ...step1DataObj, step: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep2Value = (e) => {
    setstep2Er(e.target.value.length != 0 ? false : true);
    setStep2Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step2: e.target.value });
    // setstep1DataObj({ ...step1DataObj, step1: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep3Value = (e) => {
    setstep3Er(e.target.value.length != 0 ? false : true);
    setStep3Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step3: e.target.value });
    // setstep1DataObj({ ...step1DataObj, step1: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep4Value = (e) => {
    setstep4Er(e.target.value.length != 0 ? false : true);
    setStep4Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step4: e.target.value });
    // setstep1DataObj({ ...step1DataObj, step1: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_4: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep5Value = (e) => {
    setstep5Er(e.target.value.length != 0 ? false : true);
    setStep5Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step5: e.target.value });
    // setstep1DataObj({ ...step1DataObj, step1: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_5: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep6Value = (e) => {
    setstep6Er(e.target.value.length != 0 ? false : true);
    setStep6Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step6: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_6: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStep7Value = (e) => {
    setstep7Er(e.target.value.length != 0 ? false : true);
    setStep7Value(e.target.value);
    setDraftStatedata({ ...draftStateData, step7: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ stepValue_7: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint = (e) => {
    setStartingPoint(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint1: e.target.value });
    setstep1DataObj({ ...step1DataObj, starting_point: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint2 = (e) => {
    setStartingPoint2(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint2: e.target.value });
    // setstep1DataObj({ ...step1DataObj, startingPoint1: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint3 = (e) => {
    setStartingPoint3(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint3: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint4 = (e) => {
    setStartingPoint4(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint4: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_4: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint5 = (e) => {
    setStartingPoint5(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint5: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_5: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint6 = (e) => {
    setStartingPoint6(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint6: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_6: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeStartingPoint7 = (e) => {
    setStartingPoint7(e.target.value);
    setDraftStatedata({ ...draftStateData, startingPoint7: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ startingPoint_7: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeBO1 = (e) => {
    setBO1(e.value);
    BO1Ref.current = e.value;
    setDraftStatedata({
      ...draftStateData,
      business_outcomes: e.label,
    });
    setstep1DataObj({ ...step1DataObj, business_outcome: e.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_1: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeVideoName = (e) => {
    setVideoName(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ video_name: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnchangeCheck = (e) => {
    setnext1None(true);
    setisChecked(true);
    setisChecked2(false);
    CallingSaveDraftAPI({});
    setselectedDemo1("A1");
    scrollToBottom();
  };
  const OnchangeCheck2 = (e) => {
    setnext1None(true);
    setisChecked(false);
    setisChecked2(true);
    CallingSaveDraftAPI({});
    setselectedDemo1("A2");
    scrollToBottom();
  };
  const OnchangeCheck3 = (e) => {
    setnext2None(true);
    setisChecked3(true);
    setisChecked4(false);
    CallingSaveDraftAPI({});
    setselectedDemo2("B1");
    scrollToBottom();
  };
  const OnchangeCheck4 = (e) => {
    setnext2None(true);
    setisChecked3(false);
    setisChecked4(true);
    CallingSaveDraftAPI({});
    setselectedDemo2("B2");
    scrollToBottom();
  };
  const OnchangeCheck5 = (e) => {
    setnext3None(true);
    setisChecked5(true);
    setisChecked6(false);
    CallingSaveDraftAPI({});
    setselectedDemo3("C1");
    scrollToBottom();
  };
  const OnchangeCheck6 = (e) => {
    setnext3None(true);
    setisChecked5(false);
    setisChecked6(true);
    CallingSaveDraftAPI({});
    setselectedDemo3("C2");
    scrollToBottom();
  };
  const OnchangeCheck11 = (e) => {
    setshowAddToScriptBtn4(true);
    setselectedDemo4("D4_1");
    setisChecked11(true);
    CallingSaveDraftAPI({});
    setisChecked12(false);
    scrollToBottom();
  };
  const OnchangeCheck12 = (e) => {
    setshowAddToScriptBtn4(true);
    setselectedDemo4("D4_2");
    setisChecked11(false);
    CallingSaveDraftAPI({});
    setisChecked12(true);
    scrollToBottom();
  };
  const OnchangeCheck13 = (e) => {
    setshowAddToScriptBtn5(true);
    setselectedDemo5("D5_1");
    CallingSaveDraftAPI({});
    setisChecked13(true);
    setisChecked14(false);
    scrollToBottom();
  };
  const OnchangeCheck14 = (e) => {
    setshowAddToScriptBtn5(true);
    setselectedDemo5("D5_2");
    CallingSaveDraftAPI({});
    setisChecked13(false);
    setisChecked14(true);
    scrollToBottom();
  };
  const OnchangeCheck15 = (e) => {
    setshowAddToScriptBtn6(true);
    setselectedDemo6("D6_1");
    CallingSaveDraftAPI({});
    setisChecked15(true);
    setisChecked16(false);
    scrollToBottom();
  };
  const OnchangeCheck16 = (e) => {
    setshowAddToScriptBtn6(true);
    setselectedDemo6("D6_2");
    setisChecked15(false);
    setisChecked16(true);
    scrollToBottom();
  };
  const OnchangeCheck17 = (e) => {
    setshowAddToScriptBtn7(true);
    setselectedDemo7("D7_1");
    setisChecked17(true);
    setisChecked18(false);
    scrollToBottom();
  };
  const OnchangeCheck18 = (e) => {
    setshowAddToScriptBtn7(true);
    setselectedDemo7("D7_2");
    setisChecked17(false);
    setisChecked18(true);
    scrollToBottom();
  };

  const OnchangeCheck7 = (e) => {
    setisChecked7(true);
    setisChecked8(false);
    setisShowOutroAddToScriptIntro(true);
    setselectedIntro("AAA");
    setnext3None(false);
    let a = document.getElementById("AAA").value;
    setintro1Value(a);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const OnchangeCheck8 = (e) => {
    setisChecked7(false);
    setisChecked8(true);
    setselectedIntro("BBB");
    setnext3None(false);
    setisShowOutroAddToScriptIntro(true);
    let a = document.getElementById("BBB").value;
    setintro1Value(a);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const addtoScriptIntro = () => {
    let a = document.getElementById(selectedIntro).value;
    const count = (a.match(/#Solution#/g) || []).length;
    if (
      a &&
      a.includes("#Grabber#") &&
      a &&
      a.includes("#Solution#")
    ) {
      var index = a.indexOf("#Solution#");
      var p1 = a.slice(0, index);
      var p2 = a.slice(index);
      setgrabber(p1.replace("#Grabber#", "").trim());
      if (count > 1) {
        var newSolution = p2
          .split("#Solution#")
          .pop()
          .split("#Solution#")[0];
        setnewIntro2(newSolution.trim());
      } else {
        setnewIntro2(p2.replace("#Solution#", "").trim());
      }
    } else if (
      a &&
      !a.includes("#Solution#") &&
      a &&
      a.includes("#Grabber#")
    ) {
      var index = a.indexOf("#Grabber#");
      const p = a.slice(index);
      var p2 = p.replace("#Grabber#", "").trim();
      setnewIntro2(p2.replace("#Grabber#", "").trim());
    } else if (
      a &&
      a.includes("#Solution#") &&
      a &&
      !a.includes("#Grabber#")
    ) {
      var index = a.indexOf("#Solution#");
      var p2 = a.slice(index);
      setnewIntro2(p2.replace("#Solution#", "").trim());
    } else {
      setnewIntro2(a.trim());
      if (a && a.includes("#Grabber#")) {
        var p2 = a.replace("#Grabber#", "").trim();
      } else {
        var p2 = a.trim();
      }
    }
    CallingSaveDraftAPI({
      introValue: intro1Value,
      grabberValue: p1 && p1.replace("#Grabber#", "").trim(),
      solutionValue:
        p2 && newSolution
          ? newSolution
          : p2.replace("#Solution#", "").trim(),
      is_added_to_script_intro: true,
    });
    setdisableGrabberAndSolution(false);
    setisShowOutroAddToScriptIntro(false);
    setlastDivNone(false);
    setoutNon1(!newOutro ? true : false);
    setreGenIntro(false);
  };

  const OnchangeCheckBPF1 = (e) => {
    setBPFValueCheck(document.getElementById("BP1").value);
    setisCheckedBPF1(true);
    setisCheckedBPF2(false);
    setisShowOutroAddToScriptBPF(true);
    scrollToBottom();
  };
  const OnchangeCheckBPF2 = (e) => {
    setBPFValueCheck(document.getElementById("BP2").value);
    setisCheckedBPF1(false);
    setisCheckedBPF2(true);
    setisShowOutroAddToScriptBPF(true);
    scrollToBottom();
  };
  const addtoScriptBPF = () => {
    setBPFValue(BPFValueCheck.trim());
    setshowBPF(false);
    setisShowOutroAddToScriptBPF(false);
    setSelectionDemoGenerate(true);
    setshowReGenBPF(false);
    setSelectedBusinessProcessFlow(BPFValueCheck);
    setdisableBPField(false);
    setDisableConcatenatedBO(false);
    CallingSaveDraftAPI({
      BPF: BPFValueCheck,
      selected_business_process_flow: BPFValueCheck,
      is_added_to_script_bpf: true,
    });
  };
  const OnchangeCheck9 = (e) => {
    setselectedOutro("CCC");
    setisChecked9(true);
    setisChecked10(false);
    setIsFinish(true);
    CallingSaveDraftAPI({
      // outroValue: document.getElementById("CCC").value,
      selected_outro: document.getElementById("DDD").value,
    });
    scrollToBottom();
  };
  const OnchangeCheck10 = (e) => {
    setselectedOutro("DDD");
    setisChecked9(false);
    setisChecked10(true);
    setIsFinish(true);
    CallingSaveDraftAPI({
      // outroValue: document.getElementById("DDD").value,
      selected_outro: document.getElementById("DDD").value,
    });
    scrollToBottom();
  };
  const messagesEndRef = useRef(null);
  const onBackClick1 = () => {
    setdisableDemo1Fields(false);
    setdisabledInputsOnNext(false);
    setdisabledInputs(false);
    setisChecked(false);
    setisChecked2(false);
    setSaveDrftStateOne(false);
    setdemo1Regen(true);
    setsection1dis(true);
    setSaveDrftStateButtonOne(true);
    setstep2Er(false);
    setActions2Error(false);
    setStartingPoint2Error(false);
    setprotagnist2("");
    setStep2Value("");
    setSBO2(null);
    BO2Ref.current = "";
    setStartingPoint2("");
    setDemo2("");
    CallingSaveDraftAPI({
      businessOutcome_2: "",
      role_2: "",
      demo_2: "",
      stepValue_2: "",
      startingPoint_2: "",
    });
  };
  const onBackClick2 = () => {
    setdisableDemo2Fields(false);
    setdisabledInputsd2(false);
    setisChecked3(false);
    setisChecked4(false);
    setSaveDrftStateTwo(false);
    setsection2dis(true);
    setSaveDrftStateButtonTwo(true);
    setstep3Er(false);
    setActions3Error(false);
    setStartingPoint3Error(false);
    setprotagnist3("");
    setStep3Value("");
    setSBO3(null);
    BO3Ref.current = "";
    setStartingPoint3("");
    setDemo3("");
    CallingSaveDraftAPI({
      businessOutcome_3: "",
      role_3: "",
      demo_3: "",
      stepValue_3: "",
      startingPoint_3: "",
    });
  };
  const onBackClick4 = () => {
    setdisableDemo3Fields(false);
    setdisabledInputsd3(false);
    setisChecked5(false);
    setisChecked6(false);
    setSaveDrftStateThree(false);
    setSaveDrftStateButtonFour(false);
    setfinishClick(true);
    setSaveDrftStateButtonThree(true);
    setregenerateDemo(true);
    setstep4Er(false);
    setActions4Error(false);
    setStartingPoint4Error(false);
    setprotagnist4("");
    setStep4Value("");
    setSBO4(null);
    BO4Ref.current = "";
    setStartingPoint4("");
    setDemo4("");
    CallingSaveDraftAPI({
      businessOutcome_4: "",
      role_4: "",
      demo_4: "",
      stepValue_4: "",
      startingPoint_4: "",
    });
  };
  const onBackClick5 = () => {
    setdisableDemo4Fields(false);
    setdisabledInputsd4(false);
    setisChecked13(false);
    setisChecked14(false);
    setSaveDrftStateFour(false);
    setSaveDrftStateButtonFive(false);
    setSaveDrftStateButtonFour(true);
    setregenerateDemo(true);
    setstep5Er(false);
    setActions5Error(false);
    setStartingPoint5Error(false);
    setprotagnist5("");
    setStep5Value("");
    setSBO5(null);
    BO5Ref.current = "";
    setStartingPoint5("");
    setDemo5("");
    CallingSaveDraftAPI({
      businessOutcome_5: "",
      role_5: "",
      demo_5: "",
      stepValue_5: "",
      startingPoint_5: "",
    });
  };
  const onBackClick6 = () => {
    setdisableDemo5Fields(false);
    setdisabledInputsd5(false);
    setisChecked15(false);
    setisChecked16(false);
    setSaveDrftStateFive(false);
    setSaveDrftStateButtonSix(false);
    setSaveDrftStateButtonFive(true);
    setregenerateDemo(true);
    setstep6Er(false);
    setActions6Error(false);
    setStartingPoint6Error(false);
    setprotagnist6("");
    setStep6Value("");
    setSBO6(null);
    BO6Ref.current = "";
    setStartingPoint6("");
    setDemo6("");
    CallingSaveDraftAPI({
      businessOutcome_6: "",
      role_6: "",
      demo_6: "",
      stepValue_6: "",
      startingPoint_6: "",
    });
  };
  const onBackClick7 = () => {
    setdisableDemo6Fields(false);
    setdisabledInputsd6(false);
    setisChecked17(false);
    setisChecked18(false);
    setSaveDrftStateSix(false);
    setSaveDrftStateButtonSeven(false);
    setSaveDrftStateButtonSix(true);
    setregenerateDemo(true);
    setstep7Er(false);
    setActions7Error(false);
    setStartingPoint7Error(false);
    setprotagnist7("");
    setStep7Value("");
    setSBO7(null);
    BO7Ref.current = "";
    setStartingPoint7("");
    setDemo7("");
    CallingSaveDraftAPI({
      businessOutcome_7: "",
      role_7: "",
      demo_7: "",
      stepValue_7: "",
      startingPoint_7: "",
    });
  };
  const bottomRef = useRef();
  const [errMessageCreateDraft, setErrMessageCreateDraft] = useState();
  const [bo1Er, setBo1er] = useState(false);
  const [step1Error, setstep1Error] = useState(false);
  const [startingPoint1Error, setStartingPoint1Error] = useState(false);
  const [actions1Error, setActions1Error] = useState(false);
  const [proEr, setproer] = useState(false);
  const [bo2Er, setBo2er] = useState(false);
  const [bo3Er, setBo3er] = useState(false);
  const [bo4Er, setBo4er] = useState(false);
  const [bo5Er, setBo5er] = useState(false);

  const [summaryEr, setsummaryEr] = useState(false);
  const [lobEr, setlobEr] = useState(false);
  const [indEr, setindEr] = useState(false);
  const [step2Er, setstep2Er] = useState(false);
  const [step3Er, setstep3Er] = useState(false);
  const [step4Er, setstep4Er] = useState(false);
  const [step5Er, setstep5Er] = useState(false);
  const [step6Er, setstep6Er] = useState(false);
  const [step7Er, setstep7Er] = useState(false);

  const [startingPoint2Error, setStartingPoint2Error] = useState(false);
  const [startingPoint3Error, setStartingPoint3Error] = useState(false);
  const [startingPoint4Error, setStartingPoint4Error] = useState(false);
  const [startingPoint5Error, setStartingPoint5Error] = useState(false);
  const [startingPoint6Error, setStartingPoint6Error] = useState(false);
  const [startingPoint7Error, setStartingPoint7Error] = useState(false);

  const [actions2Error, setActions2Error] = useState(false);
  const [actions3Error, setActions3Error] = useState(false);
  const [actions4Error, setActions4Error] = useState(false);
  const [actions5Error, setActions5Error] = useState(false);
  const [actions6Error, setActions6Error] = useState(false);
  const [actions7Error, setActions7Error] = useState(false);

  const generate1Press = () => {
    if (
      !pName ||
      (pName && pName.trim().length === 0) ||
      !Demo1 ||
      (Demo1 && Demo1.trim().length === 0) ||
      // !startingPoint ||
      // (startingPoint && startingPoint.trim().length === 0) ||
      !stepValue ||
      (stepValue && stepValue.trim().length === 0) ||
      !useCase ||
      (useCase && useCase.trim().length === 0) ||
      !Indursty ||
      Lob1.length == 0 ||
      businessOutcomesLength.length == 0 ||
      businessOutcomesLength.length == 1
    ) {
      setActions1Error(false);
      // setStartingPoint1Error(false);
      setstep1Error(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo1 || (Demo1 && Demo1.trim().length === 0)) {
        setActions1Error(true);
      }
      // if (
      //   !startingPoint ||
      //   (startingPoint && startingPoint.trim().length === 0)
      // ) {
      //   setStartingPoint1Error(true);
      // }
      if (!stepValue || (stepValue && stepValue.trim().length === 0)) {
        setstep1Error(true);
      }
      if (!pName || (pName && pName.trim().length === 0)) {
        setproer(true);
      }
      if (!useCase || (useCase && useCase.trim().length === 0)) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
      if (businessOutcomesLength.length == 0) {
        setBORequired(true);
        setAtLeastBo(true);
      }
      if (businessOutcomesLength.length == 1) {
        setAtLeastBo(true);
        // setBORequired(false);
      }
    } else {
      setActions1Error(false);
      // setStartingPoint1Error(false);
      setAtLeastBo(false);
      setBORequired(false);
      setstep1Error(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setRegenerateDemoScript(false);
      setloading(true);
      setIsGenerateDemoloading(true);
      setdemo1Regen(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        Protagonist,
        BO1,
        startingPoint,
        Lob1,
        stepValue,
        Demo1,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal6(true);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setResA1(data[0]["message"].content);
            if (data.length == 2) {
              setResA2(data[1]["message"].content);
            }
            setloading(false);
            // document.getElementById("state1").style.display = "block";
            scrollToBottom();

            setstate1(true);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
      setdisabledInputs(true);
    }
  };
  const onClickGenDemo2 = () => {
    if (
      !Demo2 ||
      (Demo2 && Demo2.trim().length === 0) ||
      !step2Value ||
      (step2Value && step2Value.trim().length === 0)
    ) {
      setActions2Error(false);
      setstep2Er(false);
      if (!Demo2 || (Demo2 && Demo2.trim().length === 0)) {
        setActions2Error(true);
      }
      if (!step2Value || (step2Value && step2Value.trim().length === 0)) {
        setstep2Er(true);
      }
    } else {
      setActions2Error(false);
      setstep2Er(false);
      onRegenerate1();
    }
  };
  const onClickGenDemo3 = () => {
    if (
      !Demo3 ||
      !step3Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions3Error(false);
      setstep3Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo3) {
        setActions3Error(true);
      }
      if (!step3Value) {
        setstep3Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      regenerate3();
      setActions3Error(false);
      setstep3Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
    }
  };
  const onClickGenDemo4 = () => {
    if (
      !Demo4 ||
      !step4Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions4Error(false);
      setstep4Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo4) {
        setActions4Error(true);
      }
      if (!step4Value) {
        setstep4Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      regenerate4();
      setActions4Error(false);
      setstep4Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
    }
  };
  const onClickGenDemo5 = () => {
    if (
      !Demo5 ||
      !step5Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions5Error(false);
      setstep5Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo5) {
        setActions5Error(true);
      }
      if (!step5Value) {
        setstep5Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions5Error(false);
      setstep5Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      regenerate5();
    }
  };
  const onClickGenDemo6 = () => {
    if (
      !Demo6 ||
      !step6Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions6Error(false);
      setstep6Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo6) {
        setActions6Error(true);
      }
      if (!step6Value) {
        setstep6Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions6Error(false);
      setstep6Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      regenerate6();
    }
  };
  const onClickGenDemo7 = () => {
    if (
      !Demo7 ||
      !step7Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions7Error(false);
      setstep7Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo7) {
        setActions7Error(true);
      }
      if (!step7Value) {
        setstep7Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions7Error(false);
      setstep7Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      regenerate7();
    }
  };
  const regenerate1Press = () => {
    setisChecked(false);
    setisChecked2(false);
    setnext1None(false);
    if (
      !pName ||
      (pName && pName.trim().length === 0) ||
      !Demo1 ||
      (Demo1 && Demo1.trim().length === 0) ||
      // !startingPoint ||
      // (startingPoint && startingPoint.trim().length === 0) ||
      !stepValue ||
      (stepValue && stepValue.trim().length === 0) ||
      !useCase ||
      (useCase && useCase.trim().length === 0) ||
      !Indursty ||
      Lob1.length == 0 ||
      businessOutcomesLength.length == 0 ||
      businessOutcomesLength.length == 1
    ) {
      setActions1Error(false);
      // setStartingPoint1Error(false);
      setstep1Error(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo1 || (Demo1 && Demo1.trim().length === 0)) {
        setActions1Error(true);
      }
      // if (
      //   !startingPoint ||
      //   (startingPoint && startingPoint.trim().length === 0)
      // ) {
      //   setStartingPoint1Error(true);
      // }
      if (!stepValue || (stepValue && stepValue.trim().length === 0)) {
        setstep1Error(true);
      }
      if (!pName || (pName && pName.trim().length === 0)) {
        setproer(true);
      }
      if (!useCase || (useCase && useCase.trim().length === 0)) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
      if (businessOutcomesLength.length == 0) {
        setBORequired(true);
        setAtLeastBo(true);
      }
      if (businessOutcomesLength.length == 1) {
        setAtLeastBo(true);
        // setBORequired(false);
      }
    } else {
      setBORequired(false);
      setAtLeastBo(false);
      setActions1Error(false);
      // setStartingPoint1Error(false);
      setstep1Error(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        Protagonist,
        BO1,
        startingPoint,
        Lob1,
        stepValue,
        Demo1,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          if (result.status == "failed") {
            setmodal7(true);
            setModalMsg7(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setResA1(data[0]["message"].content);
            if (data.length == 2) {
              setResA2(data[1]["message"].content);
            }
            setloading(false);
            scrollToBottom();

            setstate1(true);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
      setdisabledInputs(true);
    }
  };

  const onRegenerate1 = () => {
    setnext2None(false);
    setisChecked3(false);
    setisChecked4(false);
    if (
      !Demo2 ||
      (Demo2 && Demo2.trim().length === 0) ||
      !step2Value ||
      (step2Value && step2Value.trim().length === 0)
    ) {
      setActions2Error(false);
      setStartingPoint2Error(false);
      setstep2Er(false);
      if (!Demo2 || (Demo2 && Demo2.trim().length === 0)) {
        setActions2Error(true);
      }
      if (!step2Value || (step2Value && step2Value.trim().length === 0)) {
        setstep2Er(true);
      }
    } else {
      setActions2Error(false);
      setstep2Er(false);
      CallingSaveDraftAPI({});
      setGenerateDemoScript(false);
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist2,
        SBO2,
        startingPoint2,
        Lob1,
        step2Value,
        Demo2,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal8(true);
            setModalMsg8(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setResB1(data[0]["message"].content);
            if (data.length == 2) {
              setResB2(data[1]["message"].content);
            }
            setloading(false);
            setregenerate(true);
          }
          scrollToBottom();
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const regenerate3 = () => {
    setnext3None(false);
    setisChecked5(false);
    setisChecked6(false);
    if (
      !Demo3 ||
      // !startingPoint3 ||
      !step3Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions3Error(false);
      setStartingPoint3Error(false);
      setstep3Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo3) {
        setActions3Error(true);
      }
      if (!startingPoint3) {
        setStartingPoint3Error(true);
      }
      if (!step3Value) {
        setstep3Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions3Error(false);
      setStartingPoint3Error(false);
      setstep3Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setGenerateDemoScript1(false);
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist3,
        SBO3,
        startingPoint3,
        Lob1,
        step3Value,
        Demo3,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal9(true);
            setModalMsg9(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setResC1(data[0]["message"].content);
            if (data.length == 2) {
              setResC2(data[1]["message"].content);
            }
            setloading(false);
            setdemo3dis(true);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const regenerate4 = () => {
    setGenerateDemoScript4(false);
    setshowAddToScriptBtn4(false);
    setisChecked11(false);
    setisChecked12(false);
    if (
      !Demo4 ||
      // !startingPoint4 ||
      !step4Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions4Error(false);
      setStartingPoint4Error(false);
      setstep4Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo4) {
        setActions4Error(true);
      }
      if (!startingPoint4) {
        setStartingPoint4Error(true);
      }
      if (!step4Value) {
        setstep4Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions4Error(false);
      setStartingPoint4Error(false);
      setstep4Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist4,
        SBO4,
        startingPoint4,
        Lob1,
        step4Value,
        Demo4,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal10(true);
            setModalMsg10(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setResC1_2(data[0]["message"].content);
            if (data.length == 2) {
              setResC2_1(data[1]["message"].content);
            }
            setloading(false);
            setDEMO4OP(true);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const regenerate5 = () => {
    setGenerateDemoScript5(false);
    setshowAddToScriptBtn5(false);
    setisChecked13(false);
    setisChecked14(false);
    if (
      !Demo5 ||
      // !startingPoint5 ||
      !step5Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions5Error(false);
      setStartingPoint5Error(false);
      setstep5Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo5) {
        setActions5Error(true);
      }
      if (!startingPoint5) {
        setStartingPoint5Error(true);
      }
      if (!step5Value) {
        setstep5Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions5Error(false);
      setStartingPoint5Error(false);
      setstep5Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist5,
        SBO5,
        startingPoint5,
        Lob1,
        step5Value,
        Demo5,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal10(true);
            setModalMsg10(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setdemo5opt1val(data[0]["message"].content);
            if (data.length == 2) {
              setdemo5opt2val(data[1]["message"].content);
            }
            setloading(false);
            setDEMO5OP(true);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const regenerate6 = () => {
    setGenerateDemoScript6(false);
    setshowAddToScriptBtn6(false);
    setisChecked15(false);
    setisChecked16(false);
    if (
      !Demo6 ||
      // !startingPoint6 ||
      !step6Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions6Error(false);
      setStartingPoint6Error(false);
      setstep6Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo6) {
        setActions6Error(true);
      }
      if (!startingPoint6) {
        setStartingPoint6Error(true);
      }
      if (!step6Value) {
        setstep6Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions6Error(false);
      setStartingPoint6Error(false);
      setstep6Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist6,
        SBO6,
        startingPoint6,
        Lob1,
        step6Value,
        Demo6,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal10(true);
            setModalMsg10(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setdemo6opt1val(data[0]["message"].content);
            if (data.length == 2) {
              setdemo6opt2val(data[1]["message"].content);
            }
            setloading(false);
            setDEMO6OP(true);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const regenerate7 = () => {
    setGenerateDemoScript7(false);
    setshowAddToScriptBtn7(false);
    setisChecked17(false);
    setisChecked18(false);
    if (
      !Demo7 ||
      // !startingPoint7 ||
      !step7Value ||
      !pName ||
      !useCase ||
      !Indursty ||
      Lob1.length == 0
    ) {
      setActions7Error(false);
      setStartingPoint7Error(false);
      setstep7Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      if (!Demo7) {
        setActions7Error(true);
      }
      if (!startingPoint7) {
        setStartingPoint7Error(true);
      }
      if (!step7Value) {
        setstep7Er(true);
      }
      if (!pName) {
        setproer(true);
      }
      if (!useCase) {
        setsummaryEr(true);
      }
      if (!Indursty) {
        setindEr(true);
      }
      if (Lob1.length == 0) {
        setlobEr(true);
      }
    } else {
      setActions7Error(false);
      setStartingPoint7Error(false);
      setstep7Er(false);
      setproer(false);
      setsummaryEr(false);
      setlobEr(false);
      setindEr(false);
      CallingSaveDraftAPI({});
      setloading(true);
      setIsGenerateDemoloading(true);
      generateStepModal(
        pName,
        pName2,
        useCase,
        Indursty,
        Domain,
        protagnist7,
        SBO7,
        startingPoint7,
        Lob1,
        step7Value,
        Demo7,
        businessOutcomesPrompt
      )
        .then(async (result) => {
          setIsGenerateDemoloading(false);
          if (result.status == "failed") {
            setmodal10(true);
            setModalMsg10(result.message);
            setloading(false);
          } else {
            let data = result.model_response.choices;
            if (data.length == 0) {
              setloading(false);
            }
            setdemo7opt1val(data[0]["message"].content);
            if (data.length == 2) {
              setdemo7opt2val(data[1]["message"].content);
            }
            setloading(false);
            setDEMO7OP(true);
            scrollToBottom();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const OnAddToScript1 = () => {
    const selectedDemo = document.getElementById(selectedDemo1).value;
    setActions1Error(false);
    // setStartingPoint1Error(false);
    setstep1Error(false);
    setproer(false);
    setsummaryEr(false);
    setlobEr(false);
    setindEr(false);
    setShowMinusBtn(false);
    setShowPlusBtn(false);
    setselectedDemo1Val(selectedDemo);
    setRightDemo1(selectedDemo.trim());
    setdemo1Regen(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    GettingSummaryDonePreviously(stepValue, selectedDemo.trim())
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint2(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_2: result.data.summary_done_previously,
            right_demo_1: selectedDemo,
            selectedDemo_1: selectedDemo,
            stepDraftColorValue: selectedDemo ? "Draft" : null,
            is_AddToScript_1: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setdisabledInputsOnNext(true);
    setsection1dis(false);
    setstate1(false);
    setSaveDrftStateOne(true);
    setstepDraftColor(
      document.getElementById(selectedDemo1).value ? "Draft" : null
    );
    // gettingSavedDraft(true);
    setdisableDemo1Fields(true);
    setdisableDemo1RightFields(false);
  };
  const OnAddToScript2 = () => {
    const selectedDemo = document.getElementById(selectedDemo2).value;
    setActions2Error(false);
    setStartingPoint2Error(false);
    setstep2Er(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setselectedDemo2Val(selectedDemo);
    setRightDemo2(selectedDemo.trim());

    setdisabledInputsd2(true);
    setdisableDemo2Fields(true);
    setdisableDemo2RightFields(false);

    setsection2dis(false);
    setregenerate(false);
    setSaveDrftStateTwo(true);
    GettingSummaryDonePreviously(step2Value, selectedDemo)
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint3(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_3: result.data.summary_done_previously,
            right_demo_2: selectedDemo,
            selectedDemo_2: selectedDemo,
            right_business_outcomes: businessOutcomesString,
            is_AddToScript_2: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OnAddToScript3 = () => {
    const selectedDemo = document.getElementById(selectedDemo3).value;
    setselectedDemo3Val(selectedDemo);
    setdemo3dis(false);
    setregenerateDemo(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setRightDemo3(selectedDemo.trim());

    setSaveDrftStateThree(true);
    setdisableDemo3Fields(true);
    setdisableDemo3RightFields(false);
    setSaveDrftStateButtonFour(true);
    setdisabledInputsd3(true);
    GettingSummaryDonePreviously(step3Value, selectedDemo)
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint4(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_4: result.data.summary_done_previously,
            right_demo_3: selectedDemo,
            selectedDemo_3: selectedDemo,
            right_business_outcomes: businessOutcomesString,
            is_AddToScript_3: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OnAddToScript4 = () => {
    const selectedDemo = document.getElementById(selectedDemo4).value;
    setselectedDemo4Val(selectedDemo);
    setdemo3dis(false);
    setregenerateDemo(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setRightDemo4(selectedDemo.trim());

    setSaveDrftStateFour(true);
    setSaveDrftStateButtonFive(true);
    setSaveDrftStateButtonFour(false);
    setdisableDemo4Fields(true);
    setdisableDemo4RightFields(false);
    setdisabledInputsd4(true);
    setDEMO4OP(false);
    GettingSummaryDonePreviously(step4Value, selectedDemo)
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint5(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_5: result.data.summary_done_previously,
            right_demo_4: selectedDemo,
            selectedDemo_4: selectedDemo,
            right_business_outcomes: businessOutcomesString,
            is_AddToScript_4: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OnAddToScript5 = () => {
    const selectedDemo = document.getElementById(selectedDemo5).value;
    setselectedDemo5Val(selectedDemo);
    setDEMO5OP(false);
    setregenerateDemo(false);
    setshowAddToScriptBtn5(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setRightDemo5(selectedDemo.trim());

    setSaveDrftStateFive(true);
    setSaveDrftStateButtonSix(true);
    setSaveDrftStateButtonFive(false);
    setdisableDemo5Fields(true);
    setdisableDemo5RightFields(false);
    setdisabledInputsd5(true);
    GettingSummaryDonePreviously(step5Value, selectedDemo)
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint6(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_6: result.data.summary_done_previously,
            right_demo_5: selectedDemo,
            selectedDemo_5: selectedDemo,
            right_business_outcomes: businessOutcomesString,
            is_AddToScript_5: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OnAddToScript6 = () => {
    const selectedDemo = document.getElementById(selectedDemo6).value;
    setselectedDemo6Val(selectedDemo);
    setDEMO6OP(false);
    setregenerateDemo(false);
    setshowAddToScriptBtn6(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setRightDemo6(selectedDemo.trim());
    setdisableDemo6Fields(true);
    setdisableDemo6RightFields(false);
    setSaveDrftStateButtonSix(false);
    setdisabledInputsd6(true);
    setSaveDrftStateSix(true);
    setSaveDrftStateButtonSeven(true);
    GettingSummaryDonePreviously(step6Value, selectedDemo)
      .then((result) => {
        if (result.data.statusCode == 200) {
          setStartingPoint7(result.data.summary_done_previously);
          CallingSaveDraftAPI({
            startingPoint_7: result.data.summary_done_previously,
            right_demo_6: selectedDemo,
            selectedDemo_6: selectedDemo,
            right_business_outcomes: businessOutcomesString,
            is_AddToScript_6: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OnAddToScript7 = () => {
    setselectedDemo7Val(document.getElementById(selectedDemo7).value);
    setDEMO7OP(false);
    setregenerateDemo(false);
    setshowAddToScriptBtn7(false);
    setCombinedBusinessOutcomes(rightBusinessOutcomes);
    setRightDemo7(document.getElementById(selectedDemo7).value.trim());
    CallingSaveDraftAPI({
      right_demo_7: document.getElementById(selectedDemo7).value,
      selectedDemo_7: document.getElementById(selectedDemo7).value,
      right_business_outcomes: businessOutcomesString,
      is_AddToScript_7: true,
    });
    setdisableDemo7Fields(true);
    setdisableDemo7RightFields(false);
    setSaveDrftStateButtonSeven(false);
    setdisabledInputsd7(true);
    setshowGenerateBPF(true);
    setDisableConcatenatedBO(false);
  };
  const onChangeDO1O = (e) => {
    setResA1(e.target.value);
    setselectedDemo1Val(e.target.value);
  };
  const onChangeDO1O1 = (e) => {
    setResA2(e.target.value);
    setselectedDemo1Val(e.target.value);
  };
  const onChangeDO2O = (e) => {
    setResB1(e.target.value);
    setselectedDemo2Val(e.target.value);
  };
  const onChangeDO2O1 = (e) => {
    setResB2(e.target.value);
    setselectedDemo2Val(e.target.value);
  };
  const onChangeDO3O = (e) => {
    setResC1(e.target.value);
    setselectedDemo3Val(e.target.value);
  };
  const onChangeDO4O = (e) => {
    setResC1_2(e.target.value);
    setselectedDemo4Val(e.target.value);
  };
  const onChangeDO3O1 = (e) => {
    setResC2(e.target.value);
    setselectedDemo3Val(e.target.value);
  };
  const onChangeDO4O1 = (e) => {
    setResC2_1(e.target.value);
    setselectedDemo4Val(e.target.value);
  };
  const onChangeDO5O = (e) => {
    setdemo5opt1val(e.target.value);
    setselectedDemo5Val(e.target.value);
  };
  const onChangeDO5O1 = (e) => {
    setdemo5opt2val(e.target.value);
    setselectedDemo5Val(e.target.value);
  };
  const onChangeDO6O = (e) => {
    setdemo6opt1val(e.target.value);
    setselectedDemo6Val(e.target.value);
  };
  const onChangeDO6O1 = (e) => {
    setdemo6opt2val(e.target.value);
    setselectedDemo6Val(e.target.value);
  };
  const onChangeDO7O = (e) => {
    setdemo7opt1val(e.target.value);
    setselectedDemo7Val(e.target.value);
  };
  const onChangeDO7O1 = (e) => {
    setdemo7opt2val(e.target.value);
    setselectedDemo7Val(e.target.value);
  };
  const onChangeSBO2 = (e) => {
    setSBO2(e.value);
    BO2Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_2: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeSBO3 = (e) => {
    setSBO3(e.value);
    BO3Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_3: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeSBO4 = (e) => {
    setSBO4(e.value);
    BO4Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_4: e.value });
    }, 500);
    debounceFunction();
  };

  const onChangeSBO5 = (e) => {
    setSBO5(e.value);
    BO5Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_5: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeSBO6 = (e) => {
    setSBO6(e.value);
    BO6Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_6: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeSBO7 = (e) => {
    setSBO7(e.value);
    BO7Ref.current = e.value;
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ businessOutcome_7: e.value });
    }, 500);
    debounceFunction();
  };
  const onChangeProtagnest2 = (e) => {
    setprotagnist2(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeProtagnest3 = (e) => {
    setprotagnist3(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeProtagnest4 = (e) => {
    setprotagnist4(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_4: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeProtagnest5 = (e) => {
    setprotagnist5(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_5: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeProtagnest6 = (e) => {
    setprotagnist6(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_6: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const onChangeProtagnest7 = (e) => {
    setprotagnist7(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ role_7: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const GenerateBusinessPrecessFlow = () => {
    setActions3Error(false);
    setstep3Er(false);
    setStartingPoint3Error(false);
    setActions4Error(false);
    setstep4Er(false);
    setStartingPoint4Error(false);
    setActions5Error(false);
    setstep5Er(false);
    setStartingPoint5Error(false);
    setActions6Error(false);
    setstep6Er(false);
    setStartingPoint6Error(false);
    setActions7Error(false);
    setstep7Er(false);
    setStartingPoint7Error(false);
    setisShowOutroAddToScriptBPF(false);
    setisCheckedBPF1(false);
    setisCheckedBPF2(false);
    setisDisableDemoScript(true);
    setBPFLoading(true);
    setloading(true);
    setSelectionDemoGenerate(false);
    BusinessProcessFlow(
      pName,
      pName2,
      Indursty,
      useCase,
      Lob1,
      Protagonist,
      stepValue,
      BO1,
      protagnist2,
      step2Value,
      SBO2,
      protagnist3,
      step3Value,
      SBO3,
      protagnist4,
      step4Value,
      SBO4,
      protagnist5,
      step5Value,
      SBO5,
      protagnist6,
      step6Value,
      SBO6,
      protagnist7,
      step7Value,
      SBO7
    )
      .then(async (result) => {
        setDisableConcatenatedBO(false);
        setloading(false);
        if (!SBO3 && !step3Value && !protagnist3 && !startingPoint3 && !Demo3) {
          setSaveDrftStateTwo(false);
        } else {
          setdisableDemo3Fields(true);
        }
        if (!SBO4 && !step4Value && !protagnist4 && !startingPoint4 && !Demo4) {
          setSaveDrftStateThree(false);
        } else {
          setdisableDemo4Fields(true);
        }
        if (!SBO5 && !step5Value && !protagnist5 && !startingPoint5 && !Demo5) {
          setSaveDrftStateFour(false);
        } else {
          setdisableDemo5Fields(true);
        }
        if (!SBO6 && !step6Value && !protagnist6 && !startingPoint6 && !Demo6) {
          setSaveDrftStateFive(false);
        } else {
          setdisableDemo6Fields(true);
        }
        if (!SBO7 && !step7Value && !protagnist7 && !startingPoint7 && !Demo7) {
          setSaveDrftStateSix(false);
        } else {
          setdisableDemo7Fields(true);
        }

        setisDisableDemoScript(false);
        setshowReGenBPF(true);
        setSaveDrftStateButtonThree(false);
        setSaveDrftStateButtonFour(false);
        setSaveDrftStateButtonFive(false);
        setSaveDrftStateButtonSix(false);
        setSaveDrftStateButtonSeven(false);

        if (result.status == "failed") {
          setBPFLoading(false);
        }
        else {
          setshowBPF(true);
          setBPFLoading(false);
          setshowGenerateBPF(false);

          let Fdata = result.model_response.choices;
          // setFintro1(Fdata[0]["text"]);
          setBPFOpt1(Fdata[0]["message"].content);
          if (Fdata.length == 2) {
            // setFintro2(Fdata[1]["text"]);
            setBPFOpt2(Fdata[1]["message"].content);
          }
          // setFloading(false);
          // setlastLoading(false);
          setBPFLoading(false);
          scrollToBottom();
        }

      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setBPFLoading(false);
      });
  };
  const onClickGenIntro = () => {
    CallingSaveDraftAPI({});
    setshowAddToScriptBtn4(false);
    setfinishClick(false);
    setDEMO4OP(false);
    setdemo3dis(false);
    setdis0988_11(false);
    setdis76546_1(false);
    scrollToBottom();
    setshowBPF(false);
    setshowReGenBPF(false);
    setdisabledInputsd4(true);
    setdisabledInputsd5(true);
    setdisabledInputsd6(true);
    setdisabledInputsd7(true);

    setlastLoading(true);
    setFloading(true);
    BPFIntro(
      pName,
      pName2,
      Indursty,
      useCase,
      Lob1,
      rightBusinessOutcomes,
      BPFValue
    )
      .then(async (result) => {
        if (result.status == "failed") {
          setmodal11(true);
          setModalMsg11(result.message);
          setFloading(false);
          setlastLoading(false);
        }
        setSelectionDemoGenerate(false);

        setreGenIntro(true);

        setlastDivNone(true);

        let Fdata = result.model_response.choices;
        setFintro1(Fdata[0]["message"].content);
        if (Fdata.length == 2) {
          setFintro2(Fdata[1]["message"].content);
        }
        setFloading(false);
        setlastLoading(false);
        scrollToBottom();
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setFloading(false);
        setlastLoading(false);
      });
  };
  const onFinishClick1 = () => {
    setisChecked7(false);
    setisChecked8(false);
    setisShowOutroAddToScriptIntro(false);
    CallingSaveDraftAPI({});
    setlastDivNone(true);
    setisChecked7(false);
    setisChecked8(false);
    setshowAddToScriptBtn4(false);
    setfinishClick(false);
    setdisabledInputsd3(true);
    setshowBPF(false);
    setshowReGenBPF(false);
    setlastLoading(true);
    setloading(true);
    setFloading(true);
    BPFIntro(
      pName,
      pName2,
      Indursty,
      useCase,
      Lob1,
      rightBusinessOutcomes,
      BPFValue
    )
      .then(async (result) => {
        setloading(false);
        setdisableDemo3Fields(true);
        scrollToBottom();
        if (result.status == "failed") {
          setmodal12(true);
          setModalMsg12(result.message);
          setFloading(false);
          setlastLoading(false);
        }
        let Fdata = result.model_response.choices;
        setreGenIntro(true);

        setFintro1(Fdata[0]["message"].content);
        if (Fdata.length == 2) {
          setFintro2(Fdata[1]["message"].content);
        }
        setFloading(false);
        setlastLoading(false);
        // document.getElementById("next3NoneFinish").style.display = "block"
        scrollToBottom();
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setFloading(false);
        setlastLoading(false);
      });
  };

  const FinClick = () => {
    let a = document.getElementById(selectedOutro).value;
    setdisableDemo3Fields(true);
    setdisableOutroField(false);
    setFinalOutro(a);
    setFOROutro(false);
    setIsFinish(false);
    setoutNon2(false);
    setreGenIntro(false);
    setselectedOutro1(a);
    CallingSaveDraftAPI({
      outroValue: a,
      selected_outro: a,
      is_added_to_script_outro: true,
    });
    scrollToTop();
  };

  const GenerateOutro = () => {
    setloading(true);
    setisChecked9(false);
    setisChecked10(false);
    setisChecked9(false);
    setisChecked10(false);
    CallingSaveDraftAPI({});
    setlastDivNone(false);
    setIsFinish(false);
    setoutNon2(true);
    setoutNon1(false);
    setoutroLoading(true);
    setreGenIntro(false);
    BPFOutro(pName, pName2, useCase, intro1Value)
      .then(async (result) => {
        scrollToBottom();
        if (result.status == "failed") {
          setmodal13(true);
          setModalMsg13(result.message);
        }
        setFOROutro(true);

        let dataa = result.model_response.choices;
        if (dataa) {
          if (businessOutcomesString == "") {
            setoutroState1(dataa[0]["message"].content.trim());
            setoutroState2(dataa[1]["message"].content.trim());
          } else if (businessOutcomesString == null) {
            setoutroState1(dataa[0]["message"].content.trim());
            setoutroState2(dataa[1]["message"].content.trim());
          } else {
            setoutroState1(
              businessOutcomesString + "\n" + dataa[0]["message"].content.trim()
            );
            setoutroState2(
              businessOutcomesString + "\n" + dataa[1]["message"].content.trim()
            );
          }
        }
        setloading(false);
        setoutroLoading(false);
        scrollToBottom();
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setoutroLoading(false);
      });
  };

  const VerbCheckEv1 = () => {
    verbs(BO1)
      .then(async (result) => {
        if (result.statusCode == 100) {
          setbo1RES("Suggestion: " + result.message);
          setTimeout(() => {
            setbo1RES("");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };
  const VerbCheckEv2 = () => {
    verbs(SBO2)
      .then(async (result) => {
        if (result.statusCode == 100) {
          setbo1RES_1("Suggestion: " + result.message);
          setTimeout(() => {
            setbo1RES_1("");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };
  const VerbCheckEv3 = () => {
    verbs(SBO3)
      .then(async (result) => {
        if (result.statusCode == 100) {
          setbo1RES_2("Suggestion: " + result.message);
          setTimeout(() => {
            setbo1RES_2("");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };
  const VerbCheckEv4 = () => {
    verbs(SBO4)
      .then(async (result) => {
        if (result.statusCode == 100) {
          setbo1RES_3("Suggestion: " + result.message);
          setTimeout(() => {
            setbo1RES_3("");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };
  const [NewDemoFilArrDiv, setNewDemoFilArrDiv] = useState(false);
  const [NewDemoFilArrDivDemo2One, setNewDemoFilArrDivDemo2One] =
    useState(false);
  const [NewDemoFilArrDivDemo3One, setNewDemoFilArrDivDemo3One] =
    useState(false);
  const [NewDemoFilArrDivDemo4One, setNewDemoFilArrDivDemo4One] =
    useState(false);
  const [NewDemoFilArrDivDemo5One, setNewDemoFilArrDivDemo5One] =
    useState(false);
  const [NewDemoFilArrDivDemo6One, setNewDemoFilArrDivDemo6One] =
    useState(false);
  const [NewDemoFilArrDivDemo7One, setNewDemoFilArrDivDemo7One] =
    useState(false);
  const [NewDemoFilArrDivDemo4Two, setNewDemoFilArrDivDemo4Two] =
    useState(false);
  const [NewDemoFilArrDivDemo4Three, setNewDemoFilArrDivDemo4Three] =
    useState(false);
  const [NewDemoFilArrDivDemo3Two, setNewDemoFilArrDivDemo3Two] =
    useState(false);
  const [NewDemoFilArrDivDemo3Three, setNewDemoFilArrDivDemo3Three] =
    useState(false);
  const [NewDemoFilArrDivDemo2Two, setNewDemoFilArrDivDemo2Two] =
    useState(false);
  const [NewDemoFilArrDivDemo2Three, setNewDemoFilArrDivDemo2Three] =
    useState(false);
  const [NewDemoFilArrDivOne, setNewDemoFilArrDivOne] = useState(false);
  const [NewDemoFilArrDivTwo, setNewDemoFilArrDivTwo] = useState(false);
  const [NewDemoFilArr, setNewDemoFilArr] = useState([]);
  const [checkedState, setCheckState] = useState();
  const [CheckStateDemo2, setCheckStateDemo2] = useState();
  const [CheckStateDemo3, setCheckStateDemo3] = useState();
  const [CheckStateDemo4, setCheckStateDemo4] = useState();
  const [CheckStateDemo5, setCheckStateDemo5] = useState();
  const [CheckStateDemo6, setCheckStateDemo6] = useState();
  const [CheckStateDemo7, setCheckStateDemo7] = useState();

  const selectNewDemo = (val) => {
    setDemo1(val);
    setNewDemoFilArrDiv(false);
  };
  const selectNewDemo2One = (val) => {
    setDemo2(val);
    setNewDemoFilArrDivDemo2One(false);
  };

  const selectNewDemo3One = (val) => {
    setDemo3(val);
    setNewDemoFilArrDivDemo3One(false);
  };
  const selectNewDemo4One = (val) => {
    setDemo4(val);
    setNewDemoFilArrDivDemo4One(false);
  };
  const selectNewDemo5One = (val) => {
    setDemo5(val);
    setNewDemoFilArrDivDemo5One(false);
  };
  const selectNewDemo6One = (val) => {
    setDemo6(val);
    setNewDemoFilArrDivDemo6One(false);
  };
  const selectNewDemo7One = (val) => {
    setDemo7(val);
    setNewDemoFilArrDivDemo7One(false);
  };

  const onChangeNewDemo1 = (e) => {
    let val = e.target.value;
    setActions1Error(val.length != 0 ? false : true);
    if (checkedState == true) {
      setNewDemoFilArrDiv(true);
    }
    setDemo1(val);
    setDraftStatedata({
      ...draftStateData,
      screen1_highlight: e.target.value,
    });
    setstep1DataObj({ ...step1DataObj, features: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_1: val });
    }, 1000);
    debounceFunction();
  };
  const onChangeNewDemo2 = (e) => {
    let val = e.target.value;
    setActions2Error(val.length != 0 ? false : true);
    if (CheckStateDemo2 == true) {
      setNewDemoFilArrDivDemo2One(true);
    }
    setDemo2(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_2: val });
    }, 1000);
    debounceFunction();
  };

  const onChangeNewDemo3 = (e) => {
    if (CheckStateDemo3 == true) {
      setNewDemoFilArrDivDemo3One(true);
    }
    let val = e.target.value;
    setActions3Error(val.length != 0 ? false : true);
    setDemo3(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_3: val });
    }, 1000);
    debounceFunction();
  };
  const onChangeNewDemo4 = (e) => {
    if (CheckStateDemo4 == true) {
      setNewDemoFilArrDivDemo4One(true);
    }
    let val = e.target.value;
    setActions4Error(val.length != 0 ? false : true);
    setDemo4(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_4: val });
    }, 1000);
    debounceFunction();
  };
  const onChangeNewDemo5 = (e) => {
    if (CheckStateDemo5 == true) {
      setNewDemoFilArrDivDemo5One(true);
    }
    let val = e.target.value;
    setActions5Error(val.length != 0 ? false : true);
    setDemo5(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_5: val });
    }, 1000);
    debounceFunction();
  };
  const onChangeNewDemo6 = (e) => {
    if (CheckStateDemo6 == true) {
      setNewDemoFilArrDivDemo6One(true);
    }
    let val = e.target.value;
    setActions6Error(val.length != 0 ? false : true);
    setDemo6(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_6: val });
    }, 1000);
    debounceFunction();
  };
  const onChangeNewDemo7 = (e) => {
    if (CheckStateDemo7 == true) {
      setNewDemoFilArrDivDemo7One(true);
    }
    let val = e.target.value;
    setActions7Error(val.length != 0 ? false : true);
    setDemo7(val);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_7: val });
    }, 1000);
    debounceFunction();
  };
  const onFoucusOut1 = () => {
    setTimeout(() => {
      setNewDemoFilArrDiv(false);
    }, 200);
    setActions1Error(Demo1 && Demo1.length != 0 ? false : true);
    CallingSaveDraftAPI({});
  };
  const onFoucusOut2 = () => {
    setTimeout(() => {
      setNewDemoFilArrDiv(false);
    }, 200);
    setActions2Error(Demo2 && Demo2.length != 0 ? false : true);
    CallingSaveDraftAPI({});
  };
  const toggleBtn = (e) => {
    setCheckState(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo1();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDiv(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn2 = (e) => {
    setCheckStateDemo2(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo2();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo2One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn3 = (e) => {
    setCheckStateDemo3(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo3();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo3One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn4 = (e) => {
    setCheckStateDemo4(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo4();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo4One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn5 = (e) => {
    setCheckStateDemo5(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo5();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo5One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn6 = (e) => {
    setCheckStateDemo6(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo6();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo6One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };
  const toggleBtn7 = (e) => {
    setCheckStateDemo7(e.target.checked);
    if (e.target.checked == true) {
      onEnterDemo7();
    }
    if (e.target.checked == false) {
      setNewDemoFilArrDivDemo7One(false);
      setNewDemoFilArrDivOne(false);
      setNewDemoFilArrDivTwo(false);
    }
  };

  const onFoucusOut3 = () => {
    setActions3Error(Demo3 && Demo3.length != 0 ? false : true);
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
  };
  const onFoucusOut4 = () => {
    setActions4Error(Demo4 && Demo4.length != 0 ? false : true);
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
  };
  const onFoucusOut5 = () => {
    setActions5Error(Demo5 && Demo5.length != 0 ? false : true);
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
  };
  const onFoucusOut6 = () => {
    setActions6Error(Demo6 && Demo6.length != 0 ? false : true);
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
  };
  const onFoucusOut7 = () => {
    setActions7Error(Demo7 && Demo7.length != 0 ? false : true);
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
  };

  const ShowProductTwo = () => {
    setShowPlusBtn(false);
    setShowProductNext(true);
    setplusIcoState(false);
  };
  const HideProductTwo = () => {
    setShowPlusBtn(true);
    setShowProductNext(false);
    setplusIcoState(true);
    setPname2("");
    pName2Ref.current = "";
    setSecondProductUrl("");
    CallingSaveDraftAPI({ productName_2: "", productURL_2: "" });
  };
  const approveRequest = () => { };
  const RequestToDraftState = () => {
    setstepDraftColor("Draft");
    setshowapprovalInfoSme(false);
  };
  let inDustryDefaultVal = [
    Indursty != null ? { value: 0, label: Indursty && Indursty } : null,
  ];

  // setValueLO(newArrLo);
  let DomainDefaultVal = [
    Domain != null ? { value: "0", label: Domain && Domain } : null,
  ];
  const EditingToRequestState = () => {
    setstepDraftColor("Request");
  };

  const rejectRequestFromAdmin = (hubLeads) => {
    setshowapprovalInfoSme(true);
    setshowapprovalInfoHublead(false);
    setHubleadNames(hubLeads);
    setstepDraftColor("Request");
  };

  // const [data, setData] = useState("");
  // handle input change
  const OnChangeBusinessOutcomeArray = (e, name, index) => {
    const list = [...businessOutcomesArray];
    list[index][name] = e;

    const listArray = list.map((item) => item.name);

    setBusinessOutcomesArray(list);
    setBusinessOutcomesLength(listArray);
    setBusinessOutcomesPrompt(listArray.join("\n"));
    setBusinessOutcomesString(listArray.join(", "));
    CallingSaveDraftAPI({
      business_outcomes_array: [...list],
      right_business_outcomes: listArray.join(", "),
    });
  };

  // handle click event of the Remove button
  const HandleRemoveBO = (index) => {
    const list = [...businessOutcomesArray];
    list.splice(index, 1);
    const listArray = list.map((item) => item.name);
    setBusinessOutcomesLength(listArray);
    setBusinessOutcomesPrompt(listArray.join("\n"));
    setBusinessOutcomesString(listArray.join(","));
    setBusinessOutcomesArray(list);
    CallingSaveDraftAPI({
      business_outcomes_array: [...list],
      right_business_outcomes: listArray.join(","),
    });
  };

  // handle click event of the Add button
  const HandleAddBO = () => {
    setBusinessOutcomesArray([...businessOutcomesArray, { name: "" }]);
    CallingSaveDraftAPI({
      business_outcomes_array: [...businessOutcomesArray, { name: "" }],
    });
  };

  const items = useMemo(
    () =>
      BODropdownData.map((option) => ({
        id: option.text,
        value: option.text,
        ...option,
      })),
    []
  );

  const userData = JSON.parse(localStorage.getItem("userData"));
  const draftOwnerCheck = (userData?.role === 'Admin') ? false : userData?.id === userIdDraftOwner ? true : true

  return (
    <section>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      {/* Header */}
      <Header
        rejectRequestFromAdmin={rejectRequestFromAdmin}
        headingName="Process Video"
        CallingSaveDraftAPI={CallingSaveDraftAPI}
        isAutoSaved={isAutoSaved}
        isSaved={isSaved}
        id={draftStateData && draftStateData.id}
        RequestToDraftState={RequestToDraftState}
        EditingToRequestState={EditingToRequestState}
        lineOfBusinessData={lineOfBusinessData}
        industryData={industryData}
        onselectLOB={onselectLOB}
        onselectInd={onselectInd}
        handleCancelSme={handleCancelSme}
        handleCancelHublead={handleCancelHublead}
        stepDraftColor={stepDraftColor}
        previousPath={previousPath}
        onBackClick={onBackClick}
        canApprove={canApprove}
        loading={isLoading}
        approveDrfatByDHL={approveDrfatByDHL}
        loadingReqApp={loadingReqApp}
        isDisableReq={isDisableReq}
        isDisableTeachTori={isDisableTeachTori}
        isDisableApproveButton={isDisableApproveButton}
        TeachToriHandle={TeachToriHandle}
        loadingTori={loadingTori}
        draftSharedWith={draftSharedWith}
        draftCreatedBy={draftCreatedBy}
        cancelRequestDHLApproval={cancelRequestDHLApproval}
        loadingcancel={loadingcancel}
        videoType={"process"}
        gettingSavedDraft={gettingSavedDraft}
        from={"process"}
      />
      {/* {isLoading ? (
        <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div style={{ marginTop: "175px" }} className="body">
        <div className="row">
          {/* left side */}
          <ApprovalInfo
            showapprovalInfoHublead={showapprovalInfoHublead}
            showapprovalInfoSme={showapprovalInfoSme}
            closeHubleadInfo={closeHubleadInfo}
            draftSharedWith={hubleadNames}
          />
          <div className="col-6" style={{ backgroundColor: "#fff" }}>
            <div>
              {/* Video Name */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="top_input">
                    <InputField
                      className="InputFields"
                      isShowLabel={true}
                      isShowLabelIcon={true}
                      label="Video Name"
                      value={VideoName}
                      onChange={onChangeVideoName}
                      placeholder="Enter Video name here"
                      isShowId={true}
                      draftId={id}
                      tooltipText={videoNameTooltip}
                      // onBlur={() => CallingSaveDraftAPI({})}
                      isDisabled={disableAllinputs}
                    />
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              {/* Product Name */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <ProductName
                    showLinkIcon={true}
                    title={productNameTooltip}
                    disabledInputs={disableDemo1Fields}
                    pName={pName}
                    proEr={proEr}
                    onChangePname={onChangePname}
                    selectTextPN={selectTextPN}
                    ProductUrl={firstProductUrl}
                    onChangeProductUrl={onChangeFirstProductUrl}
                    CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
                    Icon="plus"
                    placeholder="1st Product URL"
                    placeholder1="Select 1st Product Name"
                    ShowProduct={ShowProductTwo}
                    ShowPlusBtn={ShowPlusBtn}
                    onBlur={(isProductName) =>
                      onBlurProductName1(isProductName)
                    }
                  />
                  {/* Product Name 2 */}
                  <div style={{ marginTop: "15px" }}>
                    {ShowProductNext && (
                      <ProductName
                        showLinkIcon={false}
                        disabledInputs={disableDemo1Fields}
                        pName={pName2}
                        onChangePname={onChangePname2}
                        selectTextPN={selectTextPN2}
                        ProductUrl={SecondProductUrl}
                        onChangeProductUrl={onChangeSecondProductUrl}
                        CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
                        Icon="minus"
                        placeholder="2nd Product URL"
                        placeholder1="Select 2nd Product Name"
                        ShowProduct={HideProductTwo}
                        ShowPlusBtn={ShowMinusBtn}
                        onBlur={onBlurProductName2}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Industry & Line Of Business */}
              {!isLoading && (
                <div className="row top_pad">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-6 p-0">
                        <SelectDropdown
                          isShowLabel={true}
                          label="Industry"
                          tooltipText={industryTooltip}
                          onBlur={() => {
                            setindEr(
                              Indursty && Indursty.length != 0 ? false : true
                            );
                            CallingSaveDraftAPI({});
                          }}
                          onChange={onselectInd}
                          placeholder="Select or begin typing"
                          defaultValue={inDustryDefaultVal}
                          options={industryData}
                          isOptionDisabled={() =>
                            disableDemo1Fields && isDisableLineOfBusiness
                          }
                          disabledInputs={
                            disableDemo1Fields && isDisableLineOfBusiness
                          }
                          isShowErrorMessage={indEr}
                          errorMessage="This field is required"
                        />
                      </div>
                      <div className="col-6">
                        <SelectDropdown
                          isShowLabel={true}
                          label="Line of Business"
                          tooltipText={lineOfBusinessTooltip}
                          onBlur={() => {
                            setlobEr(Lob1 && Lob1.length != 0 ? false : true);
                            CallingSaveDraftAPI({});
                          }}
                          onChange={onselectLOB}
                          options={lineOfBusinessData}
                          isMulti={true}
                          placeholder="Select or begin typing"
                          defaultValue={valueLO}
                          isOptionDisabled={() =>
                            (Lob1.length >= 3 || disableDemo1Fields) &&
                            isDisableLineOfBusiness
                          }
                          isShowErrorMessage={lobEr}
                          errorMessage="This field is required"
                          disabledInputs={
                            disableDemo1Fields && isDisableLineOfBusiness
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              )}

              {/* Domain/RBA Process */}
              {!isLoading && (
                <div className="row top_pad">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <SelectDropdown
                      isShowLabel={true}
                      label="Domain/RBA Process"
                      tooltipText={domainTooltip}
                      onBlur={() => CallingSaveDraftAPI({})}
                      onChange={onselectDomain}
                      options={domainsData}
                      placeholder="Select or begin typing"
                      defaultValue={DomainDefaultVal}
                      isOptionDisabled={() => disableDemo1Fields}
                      disabledInputs={disableDemo1Fields}
                    />
                  </div>
                  <div className="col-1"></div>
                </div>
              )}
              {/* summary */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <div style={{ display: "flex", marginBottom: 13 }}>
                    <span className="pName">Summary/Description</span>
                    <Tooltip
                      onMouseEnter={() => setShowMore(false)}
                      arrow
                      placement="right"
                      title={
                        <Typography fontSize={14}>
                          {showMore
                            ? summaryTooltip
                            : `${summaryTooltip.substring(0, 229)}`}
                          <div
                            onClick={() => setShowMore(!showMore)}
                            className={"summary-tooltip"}
                          >
                            {showMore ? "" : "View Example"}
                          </div>
                        </Typography>
                      }
                    >
                      <img
                        style={{
                          marginLeft: 5,
                          width: 20,
                        }}
                        src={info}
                      />
                    </Tooltip>
                  </div>
                  <div className="top_input">
                    <GrammarlyEditorPlugin
                      clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
                    >
                      <textarea
                        row="4"
                        onBlur={() => {
                          setsummaryEr(
                            useCase && useCase.length != 0 ? false : true
                          );
                          CallingSaveDraftAPI({});
                        }}
                        value={useCase}
                        disabled={disableDemo1Fields}
                        onChange={onChangeUseCase}
                        className={
                          disableDemo1Fields
                            ? "InputFields disable-state"
                            : "InputFields"
                        }
                        placeholder="Write 2-3 sentences"
                        type="text"
                      />
                    </GrammarlyEditorPlugin>
                    {summaryEr && (
                      <div style={{ color: "red" }}>This field is required</div>
                    )}
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              {/* Business Outcomes */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  {/* <span className="pName">Business Outcomes</span> */}
                  {businessOutcomsTooltip && (
                    <div className="row">
                      <div className="col-12 p-0">
                        <div style={{ display: "flex", marginBottom: 13 }}>
                          <span className="pName">
                            {"2-3 Business Outcomes"}
                          </span>
                          <Tooltip
                            arrow
                            placement="right"
                            title={
                              <Typography fontSize={14}>
                                {businessOutcomsTooltip}
                              </Typography>
                            }
                          >
                            <img
                              style={{
                                marginLeft: 5,
                                width: 20,
                              }}
                              src={info}
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="col-6"></div>
                    </div>
                  )}

                  <div className="top_input">
                    {(!disableDemo1Fields || stepDraftColor == null) && (
                      <Stack spacing={2}>
                        {businessOutcomesArray &&
                          businessOutcomesArray?.map((x, i) => {
                            return (
                              <Stack
                                width="100%"
                                direction="row"
                                justifyContent={"space-between"}
                              >
                                <div style={{ width: "85%" }}>
                                  <DatalistInput
                                    value={x.name}
                                    placeholder={
                                      "e.g. Create successful projects"
                                    }
                                    disable={disableDemo2Fields}
                                    showLabel={false}
                                    onSelect={(item) => {
                                      OnChangeBusinessOutcomeArray(
                                        item.text,
                                        "name",
                                        i
                                      );
                                      // setbu
                                    }}
                                    onChange={(e) =>
                                      OnChangeBusinessOutcomeArray(
                                        e.target.value,
                                        "name",
                                        i
                                      )
                                    }
                                    items={items}
                                  />
                                </div>{" "}
                                {businessOutcomesArray?.length !== 1 && (
                                  <Stack
                                    style={{
                                      alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      onClick={() => HandleRemoveBO(i)}
                                      src={Minus}
                                      style={{
                                        cursor: "pointer",
                                        width: 24,
                                        height: 24,
                                      }}
                                    />
                                  </Stack>
                                )}
                                {businessOutcomesArray?.length - 1 === i &&
                                  businessOutcomesArray?.length < 3 && (
                                    <Stack
                                      style={{
                                        alignSelf: "center",
                                      }}
                                    >
                                      <img
                                        onClick={HandleAddBO}
                                        src={plus}
                                        style={{
                                          cursor: "pointer",
                                          width: 24,
                                          height: 24,
                                        }}
                                      />
                                    </Stack>
                                  )}
                              </Stack>
                            );
                          })}
                      </Stack>
                    )}
                    {/* disabled business outcomes */}
                    {(disableDemo1Fields ||
                      stepDraftColor == "Request" ||
                      stepDraftColor == "Editing" ||
                      stepDraftColor == "Teach Tori") && (
                        <Stack spacing={2}>
                          {businessOutcomesArray &&
                            businessOutcomesArray.map((x, i) => {
                              return (
                                <Stack
                                  width="100%"
                                  direction="row"
                                  justifyContent={"space-between"}
                                >
                                  <div style={{ width: "100%" }}>
                                    <InputField
                                      className={
                                        disableDemo1Fields
                                          ? "InputFields disable-state"
                                          : "InputFields"
                                      }
                                      isShowLabel={false}
                                      isShowLabelIcon={false}
                                      // label={roleLabel}
                                      // tooltipText={buysiness}
                                      onBlur={() => { }}
                                      value={x.name}
                                      isDisabled={disableDemo1Fields}
                                      onChange={() => { }}
                                      placeholder={"2-3 Business Outcomes"}
                                      onFocus={false}
                                    />
                                  </div>
                                </Stack>
                              );
                            })}
                        </Stack>
                      )}
                  </div>

                  {boRequired && businessOutcomesLength?.length == 0 && (
                    <div style={{ color: "red" }}>This field is required</div>
                  )}
                  {atLeastTwoBo && businessOutcomesLength?.length == 1 && (
                    <div style={{ color: "red" }}>
                      At least two business outcomes is required
                    </div>
                  )}
                </div>
                <div className="col-1"></div>
              </div>
            </div>
            <hr style={{ marginTop: 38 }} />
            {/* Demo Step 1 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              showBusiness={true}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={BO1}
              onBlurBO={() => onBlurBusinessOutcome1({})}
              selectTextBO={selectedTextBO}
              processBusinessOutcome={"process"}
              // businessOutcomeDropdownData={businessOutcomesArray}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeBO1}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv1}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo1Er}
              roleLabel="Role 1"
              roleValue={Protagonist}
              stepLabel="Step 1"
              stepValue={stepValue}
              stepError={step1Error}
              startingPointLabel="Follow this process using"
              startingPointPlaceholder="Dashboard/Mobile Application/System..."
              // startingPointError={startingPoint1Error}
              stepOnchange={onChangeStepValue}
              onBlurStep={() => {
                setstep1Error(
                  stepValue && stepValue.length != 0 ? false : true
                );
                CallingSaveDraftAPI({});
              }}
              actionsError={actions1Error}
              startingPointOnChange={onChangeStartingPoint}
              startingPointTooltip={startingPointTooltip}
              roleInputDisabled={disableAllinputs || disabledInputsOnNext}
              roleOnchange={onChangeProtagonist}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn}
              demoOnfocus={onFoucusOut1}
              demoOnchange={onChangeNewDemo1}
              disabledInputsOnNext={disableAllinputs || disabledInputsOnNext}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo1}
              NewDemoFilArrDiv={NewDemoFilArrDiv}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo}
              showButtons={SaveDrftStateButtonOne}
              finishClick={true}
              isGenerateDemoScript1={RegenerateDemoScript}
              loading={loading}
              demoNo="Actions/Software Features 1"
              isSkipDemo={false}
              isBack={false}
              regenerateDemo={demo1Regen}
              onClickGenerateDemo={generate1Press}
              onClickRegenerateDemo={regenerate1Press}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={state1}
              optionsName="Step 1 Options"
              onChangeOpt1={onChangeDO1O}
              onChangeOpt2={onChangeDO1O1}
              opt1Val={resA1}
              opt1Check={isChecked}
              opt1Id="A1"
              opt2Id="A2"
              onChangeOpt1Check={OnchangeCheck}
              opt2Val={resA2}
              bottomRef={bottomRef}
              opt2Check={isChecked2}
              onChangeOpt2Check={OnchangeCheck2}
              showAddtoscript={next1None}
              onClickAddtoScript={OnAddToScript1}
              disableAllinputs={disableDemo1Fields}
            />
            {/* Demo Step 2 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateOne}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO2}
              onBlurBO={onBlurBusinessOutcome2}
              selectTextBO={selectedTextBO2}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO2}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv2}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo2Er}
              roleLabel="Role 2"
              roleValue={protagnist2}
              stepLabel="Step 2"
              stepValue={step2Value}
              stepError={step2Er}
              // startingPointTooltip="What have you done previously!"
              startingPointLabel="What is done previously?"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint2Error}
              actionsError={actions2Error}
              stepOnchange={onChangeStep2Value}
              onBlurStep={() => {
                setstep2Er(step2Value && step2Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint2}
              // startingPointOnChange={onChangeStartingPoint2}
              roleInputDisabled={disableAllinputs || disabledInputsd2}
              roleOnchange={onChangeProtagnest2}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn2}
              demoOnfocus={onFoucusOut2}
              demoOnchange={onChangeNewDemo2}
              disabledInputsOnNext={disableAllinputs || disabledInputsd2}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo2}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo2One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo2One}
              showButtons={SaveDrftStateButtonTwo}
              finishClick={section2dis}
              isGenerateDemoScript1={isGenerateDemoScript}
              onClickGenerateDemo={onClickGenDemo2}
              onBackClick={onBackClick1}
              loading={loading}
              demoNo="Actions/Software Features 2"
              isSkipDemo={false}
              isBack={true}
              regenerateDemo={true}
              onClickRegenerateDemo={onRegenerate1}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={regenerate}
              optionsName="Demo 2 Options"
              onChangeOpt1={onChangeDO2O}
              onChangeOpt2={onChangeDO2O1}
              opt1Val={resB1}
              opt1Check={isChecked3}
              onChangeOpt1Check={OnchangeCheck3}
              opt2Val={resB2}
              opt1Id="B1"
              opt2Id="B2"
              bottomRef={bottomRef}
              opt2Check={isChecked4}
              onChangeOpt2Check={OnchangeCheck4}
              showAddtoscript={next2None}
              onClickAddtoScript={OnAddToScript2}
              disableAllinputs={disableDemo2Fields || disableAllinputs}
            />
            {/* Demo step 3 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateTwo}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO3}
              onBlurBO={onBlurBusinessOutcome3}
              selectTextBO={selectedTextBO3}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO3}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv3}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo3Er}
              roleLabel="Role 3"
              roleValue={protagnist3}
              stepLabel="Step 3"
              stepValue={step3Value}
              stepError={step3Er}
              startingPointLabel="What is done previously?"
              // startingPointTooltip="What have you done previously!"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint3Error}
              actionsError={actions3Error}
              stepOnchange={onChangeStep3Value}
              onBlurStep={() => {
                setstep3Er(step3Value && step3Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint3}
              // startingPointOnChange={onChangeStartingPoint3}
              roleInputDisabled={disableAllinputs || disabledInputsd3}
              roleOnchange={onChangeProtagnest3}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn3}
              demoOnfocus={onFoucusOut3}
              demoOnchange={onChangeNewDemo3}
              disabledInputsOnNext={disableAllinputs || disabledInputsd3}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo3}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo3One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo3One}
              showButtons={SaveDrftStateButtonThree}
              finishClick={finishClick}
              isGenerateDemoScript1={isGenerateDemoScript1}
              onClickGenerateDemo={onClickGenDemo3}
              onBackClick={onBackClick2}
              loading={loading}
              demoNo="Actions/Software Features 3"
              isSkipDemo={true}
              onSkipClick={GenerateBusinessPrecessFlow}
              isBack={true}
              regenerateDemo={regenerateDemo}
              onClickRegenerateDemo={regenerate3}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={demo3dis}
              optionsName="Demo 3 Options"
              onChangeOpt1={onChangeDO3O}
              onChangeOpt2={onChangeDO3O1}
              opt1Val={resC1}
              opt1Check={isChecked5}
              onChangeOpt1Check={OnchangeCheck5}
              opt2Val={resC2}
              opt1Id="C1"
              opt2Id="C2"
              bottomRef={bottomRef}
              opt2Check={isChecked6}
              onChangeOpt2Check={OnchangeCheck6}
              showAddtoscript={next3None}
              showGenerateBPF={false}
              showReGenBPF={false}
              onClickAddtoScript={OnAddToScript3}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              BPFLoading={BPFLoading}
              lastLoading={lastLoading}
              disableAllinputs={disableDemo3Fields || disableAllinputs}
              isDisableDemoScript={isDisableDemoScript}
            />

            {/* Demo Step 4 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateThree}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO4}
              onBlurBO={onBlurBusinessOutcome4}
              selectTextBO={selectedTextBO4}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO4}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv4}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo4Er}
              roleLabel="Role 4"
              roleValue={protagnist4}
              stepLabel="Step 4"
              stepValue={step4Value}
              stepError={step4Er}
              startingPointLabel="What is done previously?"
              // startingPointTooltip="What have you done previously!"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint4Error}
              actionsError={actions4Error}
              stepOnchange={onChangeStep4Value}
              onBlurStep={() => {
                setstep4Er(step4Value && step4Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint4}
              // startingPointOnChange={onChangeStartingPoint4}
              roleInputDisabled={disableAllinputs || disabledInputsd4}
              roleOnchange={onChangeProtagnest4}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn4}
              demoOnfocus={onFoucusOut4}
              demoOnchange={onChangeNewDemo4}
              disabledInputsOnNext={disableAllinputs || disabledInputsd4}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo4}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo4One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo4One}
              showButtons={SaveDrftStateButtonFour}
              finishClick={finishClick}
              isGenerateDemoScript1={isGenerateDemoScript4}
              onClickGenerateDemo={onClickGenDemo4}
              onBackClick={onBackClick4}
              loading={loading}
              demoNo="Actions/Software Features 4"
              isSkipDemo={true}
              onSkipClick={GenerateBusinessPrecessFlow}
              isBack={true}
              regenerateDemo={true}
              onClickRegenerateDemo={regenerate4}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={DEMO4OP}
              optionsName="Demo 4 Options"
              onChangeOpt1={onChangeDO4O}
              onChangeOpt2={onChangeDO4O1}
              opt1Val={resC1_2}
              opt1Check={isChecked11}
              onChangeOpt1Check={OnchangeCheck11}
              opt2Val={resC2_1}
              opt1Id="D4_1"
              opt2Id="D4_2"
              bottomRef={bottomRef}
              opt2Check={isChecked12}
              onChangeOpt2Check={OnchangeCheck12}
              showAddtoscript={showAddToScriptBtn4}
              showGenerateBPF={false}
              onClickAddtoScript={OnAddToScript4}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              BPFLoading={BPFLoading}
              lastLoading={lastLoading}
              disableAllinputs={disableDemo4Fields || disableAllinputs}
              isDisableDemoScript={isDisableDemoScript}
            />
            {/* Demo Step 5 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateFour}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO5}
              onBlurBO={onBlurBusinessOutcome5}
              selectTextBO={selectedTextBO5}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO5}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv4}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo5Er}
              roleLabel="Role 5"
              roleValue={protagnist5}
              stepLabel="Step 5"
              stepValue={step5Value}
              stepError={step5Er}
              startingPointLabel="What is done previously?"
              // startingPointTooltip="What have you done previously!"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint5Error}
              actionsError={actions5Error}
              stepOnchange={onChangeStep5Value}
              onBlurStep={() => {
                setstep5Er(step5Value && step5Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint5}
              // startingPointOnChange={onChangeStartingPoint5}
              roleInputDisabled={disableAllinputs || disabledInputsd5}
              roleOnchange={onChangeProtagnest5}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn5}
              demoOnfocus={onFoucusOut5}
              demoOnchange={onChangeNewDemo5}
              disabledInputsOnNext={disableAllinputs || disabledInputsd5}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo5}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo5One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo5One}
              showButtons={SaveDrftStateButtonFive}
              finishClick={true}
              isGenerateDemoScript1={isGenerateDemoScript5}
              onClickGenerateDemo={onClickGenDemo5}
              onBackClick={onBackClick5}
              loading={loading}
              demoNo="Actions/Software Features 5"
              isSkipDemo={true}
              onSkipClick={GenerateBusinessPrecessFlow}
              isBack={true}
              regenerateDemo={true}
              onClickRegenerateDemo={regenerate5}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={DEMO5OP}
              optionsName="Demo 5 Options"
              onChangeOpt1={onChangeDO5O}
              onChangeOpt2={onChangeDO5O1}
              opt1Val={demo5opt1val}
              opt1Check={isChecked13}
              onChangeOpt1Check={OnchangeCheck13}
              opt2Val={demo5opt2val}
              opt1Id="D5_1"
              opt2Id="D5_2"
              bottomRef={bottomRef}
              opt2Check={isChecked14}
              onChangeOpt2Check={OnchangeCheck14}
              showAddtoscript={showAddToScriptBtn5}
              showGenerateBPF={false}
              onClickAddtoScript={OnAddToScript5}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              BPFLoading={BPFLoading}
              lastLoading={lastLoading}
              disableAllinputs={disableDemo5Fields || disableAllinputs}
              isDisableDemoScript={isDisableDemoScript}
            />
            {/* Demo Step 6 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateFive}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO6}
              onBlurBO={onBlurBusinessOutcome6}
              selectTextBO={selectedTextBO6}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO6}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv4}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo5Er}
              roleLabel="Role 6"
              roleValue={protagnist6}
              stepLabel="Step 6"
              stepValue={step6Value}
              stepError={step6Er}
              startingPointLabel="What is done previously?"
              // startingPointTooltip="What have you done previously!"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint6Error}
              actionsError={actions6Error}
              stepOnchange={onChangeStep6Value}
              onBlurStep={() => {
                setstep6Er(step6Value && step6Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint6}
              // startingPointOnChange={onChangeStartingPoint6}
              roleInputDisabled={disableAllinputs || disabledInputsd6}
              roleOnchange={onChangeProtagnest6}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn6}
              demoOnfocus={onFoucusOut6}
              demoOnchange={onChangeNewDemo6}
              disabledInputsOnNext={disableAllinputs || disabledInputsd6}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo6}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo6One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo6One}
              showButtons={SaveDrftStateButtonSix}
              finishClick={true}
              isGenerateDemoScript1={isGenerateDemoScript6}
              onClickGenerateDemo={onClickGenDemo6}
              onBackClick={onBackClick6}
              loading={loading}
              demoNo="Actions/Software Features 6"
              isSkipDemo={true}
              onSkipClick={GenerateBusinessPrecessFlow}
              isBack={true}
              regenerateDemo={true}
              onClickRegenerateDemo={regenerate6}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={DEMO6OP}
              optionsName="Demo 6 Options"
              onChangeOpt1={onChangeDO6O}
              onChangeOpt2={onChangeDO6O1}
              opt1Val={demo6opt1val}
              opt1Check={isChecked15}
              onChangeOpt1Check={OnchangeCheck15}
              opt2Val={demo6opt2val}
              opt1Id="D6_1"
              opt2Id="D6_2"
              bottomRef={bottomRef}
              opt2Check={isChecked16}
              onChangeOpt2Check={OnchangeCheck16}
              showAddtoscript={showAddToScriptBtn6}
              showGenerateBPF={false}
              onClickAddtoScript={OnAddToScript6}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              BPFLoading={BPFLoading}
              lastLoading={lastLoading}
              disableAllinputs={disableDemo6Fields || disableAllinputs}
              isDisableDemoScript={isDisableDemoScript}
            />
            {/* Demo Step 7 */}
            <PrcoessDemoSteps
              isLoading={isLoading}
              processBusinessOutcome={"process"}
              showBusiness={SaveDrftStateSix}
              businessLabel={"Business Outcome "}
              optional={"(optional)"}
              businessValue={SBO7}
              onBlurBO={onBlurBusinessOutcome7}
              selectTextBO={selectedTextBO7}
              // businessOutcomeDropdownData={boDropdownData}
              businessOutcomeDropdown={businessOutcomesArray}
              businessOnchange={onChangeSBO7}
              businessPlaceholder="e.g. Create successful projects"
              VerbCheckEv={VerbCheckEv4}
              CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
              businessError={bo5Er}
              roleLabel="Role 7"
              roleValue={protagnist7}
              stepLabel="Step 7"
              stepValue={step7Value}
              stepError={step7Er}
              startingPointLabel="What is done previously?"
              // startingPointTooltip="What have you done previously!"
              // startingPointPlaceholder="Write in 1-2 Sentences..."
              // startingPointError={startingPoint7Error}
              actionsError={actions7Error}
              stepOnchange={onChangeStep7Value}
              onBlurStep={() => {
                setstep7Er(step7Value && step7Value.length != 0 ? false : true);
                CallingSaveDraftAPI({});
              }}
              // startingPoint={startingPoint7}
              // startingPointOnChange={onChangeStartingPoint7}
              roleInputDisabled={disableAllinputs || disabledInputsd7}
              roleOnchange={onChangeProtagnest7}
              rolePlaceholder="e.g. Project Planner"
              demoToggle={toggleBtn7}
              demoOnfocus={onFoucusOut7}
              demoOnchange={onChangeNewDemo7}
              disabledInputsOnNext={disableAllinputs || disabledInputsd7}
              demoDisable={disableAllinputs}
              demoPlaceholder="Add 3 bullets or 2 sentences"
              demoValue={Demo7}
              NewDemoFilArrDiv={NewDemoFilArrDivDemo7One}
              NewDemoFilArr={NewDemoFilArr}
              selectNewDemo={selectNewDemo7One}
              showButtons={SaveDrftStateButtonSeven}
              finishClick={true}
              isGenerateDemoScript1={isGenerateDemoScript7}
              onClickGenerateDemo={onClickGenDemo7}
              onBackClick={onBackClick7}
              loading={loading}
              demoNo="Actions/Software Features 7"
              isSkipDemo={true}
              onSkipClick={GenerateBusinessPrecessFlow}
              isBack={true}
              regenerateDemo={true}
              onClickRegenerateDemo={regenerate7}
              genDemoLoading={isGenerateDemoloading}
              showDemoOptions={DEMO7OP}
              optionsName="Demo 7 Options"
              onChangeOpt1={onChangeDO7O}
              onChangeOpt2={onChangeDO7O1}
              opt1Val={demo7opt1val}
              opt1Check={isChecked17}
              onChangeOpt1Check={OnchangeCheck17}
              opt2Val={demo7opt2val}
              opt1Id="D7_1"
              opt2Id="D7_2"
              bottomRef={bottomRef}
              opt2Check={isChecked18}
              onChangeOpt2Check={OnchangeCheck18}
              showAddtoscript={showAddToScriptBtn7}
              showGenerateBPF={false}
              onClickAddtoScript={OnAddToScript7}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              BPFLoading={BPFLoading}
              lastLoading={lastLoading}
              disableAllinputs={disableDemo7Fields || disableAllinputs}
              isDisableDemoScript={isDisableDemoScript}
            />
            {/* Business process Flow */}
            <GenerateOutroIntro
              loading={loading}
              showdiv={showBPF}
              lastLoading={BPFLoading}
              optionName="Select Business Process"
              opt1Id="BP1"
              opt1Onchange={BPFOpt1Change}
              opt1Val={BPFOpt1}
              opt1Check={isCheckedBPF1}
              opt1Checkchange={OnchangeCheckBPF1}
              opt2Id="BP2"
              opt2Onchange={BPFOpt2Change}
              opt2Val={BPFOpt2}
              opt2Check={isCheckedBPF2}
              opt2Checkchange={OnchangeCheckBPF2}
              showGenerateBPF={showGenerateBPF}
              onClickGenBPF={GenerateBusinessPrecessFlow}
              showReGenBPF={showReGenBPF}
              showGenOutro={false}
              showReGenOutro={false}
              showFinish={false}
              regenLoading={regenLoading}
              disableAllinputs={disableAllinputs}
              showGenerateintro={false}
              showAddtoscript={isShowOutroAddToScriptBPF}
              intro1ReGen={false}
              onClickAddToScript={addtoScriptBPF}
              BPFLoading={BPFLoading}
            />
            {/* Intro */}
            <GenerateOutroIntro
              loading={loading}
              showdiv={lastDivNone}
              lastLoading={lastLoading}
              optionName="Select Intro"
              opt1Id="AAA"
              opt1Onchange={int1}
              opt1Val={Fintro1}
              opt1Check={isChecked7}
              opt1Checkchange={OnchangeCheck7}
              opt2Id="BBB"
              opt2Onchange={int2}
              opt2Val={Fintro2}
              opt2Check={isChecked8}
              opt2Checkchange={OnchangeCheck8}
              showGenOutro={false}
              onClickbtnGen={GenerateOutro}
              showReGenOutro={false}
              onClickbtnReGen={GenerateOutro}
              showGenerateintro={SelectionDemoGenerate}
              onClickGenIntro={onClickGenIntro}
              intro1ReGen={reGenIntro}
              onClickReGenintro={() => {
                onFinishClick1();
                setRegenLoading(true);
              }}
              showFinish={false}
              regenLoading={regenLoading}
              disableAllinputs={disableAllinputs}
              showAddtoscript={isShowOutroAddToScriptIntro}
              onClickAddToScript={addtoScriptIntro}
              fLoading={Floading}
            />
            {/* outro */}
            <GenerateOutroIntro
              loading={loading}
              showdiv={FOROutro}
              lastLoading={outroLoading}
              optionName="Select Outro"
              opt1Id="CCC"
              opt1Onchange={out1}
              opt1Val={outroState1}
              opt1Check={isChecked9}
              opt1Checkchange={OnchangeCheck9}
              opt2Id="DDD"
              opt2Onchange={out2}
              opt2Val={outroState2}
              opt2Check={isChecked10}
              opt2Checkchange={OnchangeCheck10}
              showGenOutro={outNon1}
              onClickbtnGen={GenerateOutro}
              showReGenOutro={outNon2}
              onClickbtnReGen={GenerateOutro}
              showAddtoscript={isFinish}
              onClickAddToScript={FinClick}
              regenLoading={false}
              disableAllinputs={disableAllinputs}
            />
          </div>
          {/* right side */}
          <div className="col-6" style={{ marginTop: "16px" }}>
            <div>
              <div>
                <div className="row m-0">
                  <div className="col-11">
                    <div
                      style={{
                        height: "auto",
                        borderRadius: "8px",
                        padding: "20px",
                        paddingTop: "26px",
                      }}
                    >
                      <div>
                        <OutcomeValues
                          header="Grabber:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={onchangeGrabber}
                          className={
                            disableGrabberAndSolution || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableGrabberAndSolution || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={grabber}
                          disableAllinputs={
                            disableGrabberAndSolution || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableAllinputs || disableGrabberAndSolution || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="Intro/Solution:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={onChangeSolution}
                          className={
                            disableGrabberAndSolution || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableGrabberAndSolution || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={newIntro2}
                          disableAllinputs={
                            disableGrabberAndSolution || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableAllinputs || disableGrabberAndSolution || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="2-3 Business Outcomes:"
                          onBlur={() =>
                            CallingSaveDraftAPI({
                              right_business_outcomes: rightBusinessOutcomes,
                            })
                          }
                          onChange={ONchangeboo1}
                          className={
                            disableConcatenatedBO || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableConcatenatedBO || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={businessOutcomesString}
                          disableAllinputs={
                            disableGrabberAndSolution || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableAllinputs || disableGrabberAndSolution || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="Business Process Flow:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={onChangeBPF}
                          className={
                            disableBPFField || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableBPFField || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={BPFValue}
                          disableAllinputs={disableBPFField || disableAllinputs}
                        // disableAllinputs={
                        //   disableBPFField || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="Demo 1:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={onChangeRightDemo1}
                          className={
                            disableDemo1RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo1RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo1}
                          disableAllinputs={
                            disableDemo1RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo1RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="Demo 2:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={onChangeRightDemo2}
                          className={
                            disableDemo2RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo2RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo2}
                          disableAllinputs={
                            disableDemo2RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo2RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          show={!disableDemo3RightFields}
                          header="Demo 3:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={yess3}
                          className={
                            disableDemo3RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo3RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo3}
                          disableAllinputs={
                            disableDemo3RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo3RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          show={!disableDemo4RightFields}
                          header="Demo 4:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={yess4}
                          className={
                            disableDemo4RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo4RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo4}
                          disableAllinputs={
                            disableDemo4RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo4RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          show={!disableDemo5RightFields}
                          header="Demo 5:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={yess5}
                          className={
                            disableDemo5RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo5RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo5}
                          disableAllinputs={
                            disableDemo5RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo5RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          show={!disableDemo6RightFields}
                          header="Demo 6:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={yess6}
                          className={
                            disableDemo6RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo6RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo6}
                          disableAllinputs={
                            disableDemo6RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo6RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          show={!disableDemo7RightFields}
                          header="Demo 7:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={yess7}
                          className={
                            disableDemo7RightFields || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableDemo7RightFields || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={rightDemo7}
                          disableAllinputs={
                            disableDemo7RightFields || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableDemo7RightFields || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />
                        <OutcomeValues
                          header="Outro:"
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={changeoutro}
                          className={
                            disableOutroField || disableAllinputs
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side right_side autosize"
                          }
                          // className={
                          //   disableAllinputs || disableOutroField || (!isDisableReqOnEditing && draftOwnerCheck)
                          //     ? "InputFields disable-state right_side autosize"
                          //     : "InputFields right_side autosize"
                          // }
                          value={newOutro}
                          disableAllinputs={
                            disableOutroField || disableAllinputs
                          }
                        // disableAllinputs={
                        //   disableOutroField || disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                        // }
                        />

                        <div className="row">
                          <div className="col-12 newpd">
                            <button
                              className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                                }`}
                              disabled={disableAllinputs || loading || Floading}
                              id="generate"
                              onClick={() => {
                                CallingSaveDraftAPI({});
                                setsaveLoading(true);
                              }}
                            >
                              {saveloading == true ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                "Save"
                              )}
                            </button>
                            <button
                              className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                                }`}
                              style={{ marginLeft: "10px" }}
                              id="generate"
                              disabled={
                                disableAllinputs ||
                                canClear ||
                                loading ||
                                Floading
                              }
                              onClick={() => {
                                setClearAllModal(true);
                              }}
                            >
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      <DisplayModal
        open={modal1}
        onClose={() => {
          setmodal1(false);
        }}
        modalMsg={modalMsg1}
      />
      <DisplayModal
        open={showClear}
        onClose={() => {
          setShowclear(false);
        }}
        modalMsg={showClearMsg}
      />
      <DisplayModal
        open={modal2}
        onClose={() => {
          setmodal2(false);
        }}
        modalMsg={modalMsg2}
      />
      <DisplayModal
        open={modal3}
        onClose={() => {
          setmodal3(false);
        }}
        modalMsg={modalMsg3}
      />
      <DisplayModal
        open={modal4}
        onClose={() => {
          setmodal4(false);
        }}
        modalMsg={modalMsg4}
      />
      <DisplayModal
        open={modal5}
        onClose={() => {
          setmodal5(false);
        }}
        modalMsg={modalMsg5}
      />
      <DisplayModal
        open={modal6}
        onClose={() => {
          setmodal6(false);
        }}
        modalMsg={modalMsg6}
      />
      <DisplayModal
        open={modal7}
        onClose={() => {
          setmodal7(false);
        }}
        modalMsg={modalMsg7}
      />
      <DisplayModal
        open={modal8}
        onClose={() => {
          setmodal8(false);
        }}
        modalMsg={modalMsg8}
      />
      <DisplayModal
        open={modal9}
        onClose={() => {
          setmodal9(false);
        }}
        modalMsg={modalMsg9}
      />
      <DisplayModal
        open={modal10}
        onClose={() => {
          setmodal10(false);
        }}
        modalMsg={modalMsg10}
      />
      <DisplayModal
        open={modal11}
        onClose={() => {
          setmodal11(false);
        }}
        modalMsg={modalMsg11}
      />
      <DisplayModal
        open={modal12}
        onClose={() => {
          setmodal12(false);
        }}
        modalMsg={modalMsg12}
      />
      <DisplayModal
        open={modal13}
        onClose={() => {
          setmodal13(false);
        }}
        modalMsg={modalMsg13}
      />
      <DisplayModal
        open={modal14}
        onClose={() => {
          setmodal14(false);
        }}
        modalMsg={modalMsg14}
      />
      <DisplayModal
        open2={approvalSme}
        onClose={() => {
          setapprovalSme(false);
        }}
        heading1="Request Sent!"
        modalMsg={approvalSmeMsg}
      />
      <DisplayModal
        open2={approvalHublead}
        onClose={() => {
          setapprovalHublead(false);
        }}
        heading1="Draft approved and ready for final edits"
        modalMsg={approvalHubleadmsg}
      />
      <DisplayModal
        open={teachTorimodal}
        onClose={() => {
          setteachTorimodal(false);
        }}
        modalMsg={teachTorimodalMsg}
      />
      <DisplayModal
        open={clearAllModal}
        onClose={() => {
          setClearAllModal(false);
        }}
        modalMsg={"Are you sure you want to clear this draft?"}
        isShowConfirm={true}
        clearDraft={ClearAll}
        isLoading={clearloading}
      />
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isDHLRequest}
        onClose={() => {
          setisCancelDHLRequest(false);
        }}
        modalMsg={CancelDHLRequestMsg}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
    </section>
  );
}

export default Process;
