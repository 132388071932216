import React, { useEffect, useState } from "react";
import ProcessTypeBarCharts from "./ProcessTypeBarCharts";
import { Tabs } from "antd";
import SpotlightTypeBarCharts from "./SpotlightBarCharts";

function BarCharts() {
  return (
    <div>
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Tori's Training</h3>
      </div>
      <Tabs
        type="card"
        defaultActiveKey="1"
        centered
        items={[
          {
            label: "Spotlight",
            key: "1",
            children: <SpotlightTypeBarCharts />,
          },
          {
            label: "Process",
            key: "2",
            children: <ProcessTypeBarCharts/>
          },
        ]}
      />
    </div>
  );
}

export default BarCharts;
