import React from "react";

export default function GenerateOutroIntro({
  showdiv,
  lastLoading,
  regenLoading = false,
  optionName,
  opt1Id,
  opt1Onchange,
  opt1Val,
  opt1Check,
  opt1Checkchange,
  opt2Id,
  opt2Onchange,
  opt2Val,
  opt2Check,
  opt2Checkchange,
  showGenOutro,
  onClickbtnGen,
  showReGenOutro,
  onClickbtnReGen,
  showFinish,
  disableAllinputs,
  showGenerateintro = false,
  intro1ReGen = false,
  onClickGenIntro,
  onClickReGenintro,
  fLoading,
  loading,
  isRegenIntroLoading = false,
  showAddtoscript = false,
  onClickAddToScript,
  showGenerateBPF = false,
  showReGenBPF = false,
  onClickGenBPF,
  BPFLoading = false,
}) {
  return (
    <div>
      {(showGenOutro ||
        showGenerateintro ||
        intro1ReGen ||
        showReGenOutro ||
        showFinish ||
        showGenerateBPF ||
        showReGenBPF) && (
          <div className="row m-0 btn_section_next">
            <div className="col-2"></div>
            <div className="col-9">
              <div>
                {showGenerateintro && (
                  <button
                    id="next3None111"
                    onClick={onClickGenIntro}
                    className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                      }`}
                    disabled={fLoading || disableAllinputs}
                    style={{
                      float: "right",
                    }}
                  >
                    {fLoading ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>Generate Intro Script</div>
                    )}
                  </button>
                )}
                {intro1ReGen && (
                  <div>
                    <button
                      className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                        }`}
                      disabled={(regenLoading && lastLoading) || disableAllinputs}
                      onClick={onClickReGenintro}
                      style={{ float: "right", marginTop: "30px" }}
                    >
                      {regenLoading && lastLoading ? (
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div> Re Generate Intro Script</div>
                      )}
                    </button>
                  </div>
                )}
                {showGenerateBPF && (
                  <button
                    id="next3None111"
                    onClick={onClickGenBPF}
                    className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                      }`}
                    disabled={disableAllinputs}
                    style={{
                      float: "right",
                    }}
                  >
                    {BPFLoading ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>Generate Business Process</div>
                    )}
                  </button>
                )}
                {showReGenBPF && (
                  <button
                    className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                      }`}
                    disabled={disableAllinputs || BPFLoading}
                    onClick={onClickGenBPF}
                    style={{ float: "right", marginTop: "30px" }}
                  >
                    {BPFLoading ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>Re Generate Business Process</div>
                    )}
                  </button>
                )}
              </div>
              {showGenOutro && (
                <button
                  id="outNon1"
                  onClick={onClickbtnGen}
                  className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                    }`}
                  style={{ float: "right" }}
                  disabled={disableAllinputs}
                >
                  Generate Outro Script
                </button>
              )}
              {showReGenOutro && (
                <button
                  id="outNon2"
                  onClick={onClickbtnReGen}
                  className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                    }`}
                  style={{ float: "right" }}
                  disabled={disableAllinputs || lastLoading}
                >
                  {lastLoading ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div> Re Generate Outro Script</div>
                  )}
                </button>
              )}
              {showFinish && (
                <button
                  onClick={onClickbtnReGen || disableAllinputs}
                  className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                    }`}
                  style={{
                    float: "right",
                  }}
                  disabled={disableAllinputs}
                >
                  Finish
                </button>
              )}
            </div>
            <div className="col-1"></div>
          </div>
        )}
      {showdiv && (
        <div id="lastDivNone">
          <div id="FINALLAS">
            <section>
              <div className="row">
                <div className="col-12">
                  <section>
                    <div>
                      <div className="row m-0">
                        <div className="col-1"></div>
                        <div className="col-10 mb-5">
                          <div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <span className="pName1">{optionName}</span>
                            </div>
                            <div className="custom-control custom-radio">
                              <textarea
                                id={opt1Id}
                                rows="5"
                                cols="50"
                                onChange={opt1Onchange}
                                value={opt1Val == null || "" ? "" : opt1Val}
                                className="left_from"
                                type="text"
                                disabled={disableAllinputs}
                              ></textarea>
                              <input
                                checked={opt1Check}
                                onChange={opt1Checkchange}
                                id="female"
                                type="checkbox"
                                className="cus_radio"
                                value="female"
                                name="gender"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginTop: "70px",
                                }}
                                disabled={loading || isRegenIntroLoading}
                              />
                            </div>
                          </div>
                          <div className="mt-2">
                            <div className="custom-control custom-radio">
                              <textarea
                                id={opt2Id}
                                rows="5"
                                cols="50"
                                onChange={opt2Onchange}
                                value={opt2Val == null || "" ? "" : opt2Val}
                                className="left_from"
                                type="text"
                                disabled={disableAllinputs}
                              ></textarea>
                              <input
                                checked={opt2Check}
                                onChange={opt2Checkchange}
                                id="female"
                                type="checkbox"
                                className="cus_radio"
                                value="female"
                                name="gender"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginTop: "70px",
                                }}
                                disabled={loading || isRegenIntroLoading}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              {showAddtoscript && (
                <div className="row m-0 mb-5">
                  <div className="col-2"></div>
                  <div className="col-9">
                    <button
                      id="next3None111"
                      onClick={onClickAddToScript}
                      className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                        }`}
                      disabled={disableAllinputs}
                      style={{
                        float: "right",
                      }}
                    >
                      Add to Script
                    </button>
                  </div>
                  <div className="col-1"></div>
                </div>
              )}
            </section>
          </div>
        </div>
      )}
    </div>
  );
}
