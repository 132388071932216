import React, { useState, useEffect, useContext } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
// import "../DraftTable/modals/style.css";
import "./style.css";

import { Modal } from "antd";
import { DeleteUser, GetUserData } from "../../Services/Service";
import { Typography } from "@mui/material";
import DisplayModal from "../CreateDraft/Components/Modal";
import AdminContext from "../../../context/AdminTableContext";
const DeleteAdminModal = ({ id }) => {
  const { removeUser } = useContext(AdminContext);
  const [loaderConfirmDel, setloaderConfirmDel] = useState(false);

  const [onDeleteMsg, setOnDeleteMsg] = useState("");

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };
  useEffect(() => {
    updateSidebar();
  }, []);
  const onConfirmDel = () => {
    setloaderConfirmDel(true);
    removeUser(id)
      .then((res) => {
        if (res.status) {
          setloaderConfirmDel(false);
          handleCancel1();
          setOnDeleteMsg(res.message);
          setTimeout(() => {
            showModal2();
          }, 500);
        } else {
          setloaderConfirmDel(false);
          // setConfirmDel(false);
          setConfirmDelMsg(res.message);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloaderConfirmDel(false);
        setIsModalOpen1(false);
      });
  };

  return (
    <>
      <Typography
        style={{ fontSize: 14, padding: "5px 16px" }}
        onClick={showModal1}
      >
        Delete
      </Typography>
      {/* delete modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title pb-3"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Delete User
          </h5>
        </div>

        <h5>Are you sure you want to delete this User?</h5>

        <div className="pt-3 d-flex justify-content-end">
          <div style={{ marginRight: 10 }}>
            <button
              onClick={handleCancel1}
              className="primatybuttonwhite w-inline-block"
            >
              No
            </button>
          </div>
          <div>
            <button
              onClick={onConfirmDel}
              className="primatybuttonred w-inline-block"
            >
              {loaderConfirmDel ? (
                <div class="spinner-border text-white" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/* delete message modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={handleCancel2}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${onDeleteMsg}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel2}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="primatybuttonred w-inline-block"
          >
            Close
          </button>
        </div>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </>
  );
};

export default DeleteAdminModal;
