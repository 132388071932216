export const PNList1 = [
  "Content and Collaboration",
  "Customer Relationship Management",
  "Digital Manufacturing",
  "Enterprise Management",
  "Financial Management",
  "Health and Safety Management",
  "Human Capital Management",
  "Product Lifecycle Management",
  "Supplier Relationship Management",
  "Supply Chain Management",
  "Technology Platform",
];

export const RBAList = [
  "Plan to Optimize Products/Services",
  "Idea to Requirements",
  "Design to Release",
  "Products/Services to Market",
  "Manage Products/ Services",
  "Plan to Optimize Sourcing and Procurement",
  "Source to Contract",
  "Plan to Optimize Fulfillment",
  "Procure to receipt",
  "Request to Resolution",
  "Invoice TO Pay",
  "Manage Suppliers & Collaboration",
  "Make to Inspect",
  "Deliver Product to Fulfill",
  "Delver Service to Fulfill",
  "Manage Fulfillment",
  "Plan to Optimize Marketing and Sales",
  "Market to Lead",
  "Opportunity to Quote",
  "Order to Fulfill",
  "Request to Service",
  "Invoice to Cash",
  "Manage Customers and Channels",
  "Plan to Optimize the Workforce",
  "Recruit to Onboard",
  "Develop to Grow",
  "Reward to retain",
  "Manage Workforce and Retirement",
  "Plan to Optimize Assets",
  "Acquire to Onboard",
  "Operate to Maintain",
  "Offboard to Decommission",
  "Manage Assets",
  "Plan to Optimize Enterprise",
  "Manage Portfolio and Projects",
  "Manage Sustainable Operations",
  "Manage Governance, Compliance and Risk",
  "Manage Information technology",
  "Plan to Optimize Financials",
  "Invoice t o Pay",
  "Record to Report",
  "Manage Treasury",
  "Manage Real Estate",
];

export const DomainList = [
  "Idea to Market",
  "Source to Pay",
  "Plan to Fulfill",
  "Lead to Cash",
  "Recruit to Retire",
  "Acquire to Decommission",
  "Governance",
  "Finance",
];

export const PNList2 = [
  "SAP  Cloud",
  "SAP  Hub",
  "SAP Digital Boardroom",
  "SAP Global Trade Services",
  "SAP Predictive ",
  "SAP Watch List Screening",
  "SAP Content Stream by Skillsoft",
  "SAP Mobile Documents",
  "SAP Portal Content Management By OpenText",
  "SAP Signature Management by DocuSign",
  "SAP C/4HANA",
  "SAP Cloud for Customer",
  "SAP Commerce",
  "SAP Commerce Cloud",
  "SAP Customer Activity Repository",
  "SAP Customer  for Retail",
  "SAP Customer Data Cloud",
  "SAP Customer Experience Foundation",
  "SAP Customer Order Sourcing",
  "SAP Dynamic Pricing by GK",
  "SAP Field Service Management",
  "SAP Incentive Administration by Vistex and SAP Paybacks and Chargebacks by Vistex",
  "SAP Marketing",
  "SAP Marketing Cloud",
  "SAP Mobile Consumer Assistant by GK",
  "SAP Omnichannel Point-of-Sale by GK",
  "SAP Omnichannel Promotion Pricing",
  "SAP Order Management",
  "SAP Precision Marketing",
  "SAP Sales Cloud",
  "SAP Service Cloud",
  "SAP Store Management by GK",
  "SAP Student Lifecycle Management",
  "SAP Trade Management",
  "SAP Trade Promotion Management",
  "SAP Upscale Commerce",
  "SAP Utilities Customer Engagement",
  "SAP Data Custodian",
  "Digital Manufacturing",
  "Enterprise Management",
  "Manufacturing",
  "SAP Asset Manager",
  "SAP Business ByDesign",
  "SAP Digital Vehicle Suite",
  "SAP Enterprise Asset Management",
  "SAP Entitlement Management",
  "SAP ERP",
  "SAP S/4HANA",
  "SAP S/4HANA Cloud",
  "Banking services from SAP",
  "SAP Access Control",
  "SAP Business Planning and Consolidation",
  "SAP Cloud for Real Estate",
  "SAP Commodity Management",
  "SAP Process Control",
  "SAP Profitability and Performance Management",
  "SAP Risk Management",
  "SAP Spend Performance Management",
  "SAP Sustainability Performance Management",
  "SAP Treasury and Risk Management",
  "Health and Safety Management",
  "SAP Environment",
  "Qualtrics EmployeeXM for IT",
  "SAP Fieldglass Time Entry",
  "SAP Qualtrics 360 Feedback",
  "SAP Qualtrics Employee Benefits Optimizer",
  "SAP Qualtrics Employee Engagement",
  "SAP Qualtrics Employee Lifecycle",
  "SAP SuccessFactors H",
  "SAP SuccessFactors HCM Core",
  "SAP SuccessFactors HXM Suite",
  "SAP Workforce Management",
  "SAP Dairy Management by msg",
  "SAP Enterprise Product Development",
  "SAP Fashion Management",
  "SAP Innovation Management",
  "SAP Intelligent Product Design",
  "SAP Meat and Fish Management by msg",
  "SAP Meat Management by msg",
  "SAP Product Lifecycle Costing",
  "Ariba Network",
  "SAP Ariba Buying",
  "SAP Ariba Buying and Invoicing",
  "SAP Ariba Contracts",
  "SAP Ariba Discount Management",
  "SAP Ariba Invoice Management",
  "SAP Ariba Procurement",
  "SAP Ariba Snap",
  "SAP Ariba Sourcing",
  "SAP Ariba Spend Analysis",
  "SAP Ariba Spot Buy Catalog",
  "SAP Ariba Supplier",
  "SAP Ariba Supplier Information and Performance Management",
  "SAP Ariba Supplier Lifecycle and Performance",
  "SAP Ariba Supplier Risk",
  "SAP Ariba Supply Chain",
  "SAP Fieldglass Contingent Workforce Management",
  "SAP Fieldglass Services Procurement",
  "SAP Fieldglass Vendor Management System",
  "SAP Fieldglass\xa0Assignment Management",
  "SAP Rural Sourcing Management",
  "SAP Strategic Sourcing Suite",
  "SAP Supplier Relationship Management",
  "SAP Digital Supply Chain",
  "SAP Extended Warehouse Management",
  "SAP Farm Management by Vistex",
  "SAP Global Track and Trace",
  "SAP Grower Management for Perishables by Vistex",
  "SAP Integrated Business Planning for Supply Chain",
  "SAP Intelligent Asset Management",
  "SAP Logistics Business Network",
  "SAP S/4HANA Supply Chain",
  "SAP Sales and Operations Planning",
  "SAP Transportation Management",
  "business process intelligence",
  "Qualtrics CoreXM",
  "Qualtrics CustomerXM",
  "Qualtrics EmployeeXM",
  "Qualtrics XM Platform",
  "SAP",
  "SAP Business Technology Platform",
  "SAP Cloud Identity Access Governance",
  "SAP Data Warehouse Cloud",
  "SAP Fiori",
  "SAP HANA",
  "SAP HANA Cloud Services",
  "SAP Landscape Management",
  "SAP Mobile App Protection by Mocana",
  "SAP SuccessFactors Work Zone",
];

export const PNList3 = [
  "SAP  Cloud",
  "SAP  Hub",
  "SAP Digital Boardroom",
  "SAP Global Trade Services",
  "SAP Predictive ",
  "SAP Watch List Screening",
  "SAP Content Stream by Skillsoft",
  "SAP Mobile Documents",
  "SAP Portal Content Management By OpenText",
  "SAP Signature Management by DocuSign",
  "SAP Sales Cloud",
  "SAP Service Cloud",
  "SAP Cloud for Customer",
  "SAP Commerce",
  "SAP Commerce Cloud",
  "SAP Customer Activity Repository",
  "SAP Customer  for Retail",
  "SAP Customer Data Cloud",
  "SAP Customer Data Platform",
  "SAP Customer Order Sourcing",
  "SAP Dynamic Pricing by GK(cloud edition)",
  "SAP Field Service Management",
  "SAP Incentive Administration by Vistex and SAP Paybacks and Chargebacks by Vistex",
  "SAP Marketing",
  "SAP Marketing Cloud",
  "SAP Mobile Consumer Assistant by GK",
  "SAP Omnichannel Point-of-Sale by GK(cloud edition)",
  "SAP Omnichannel Promotion Pricing",
  "SAP Order Management",
  "SAP Precision Marketing",
  "SAP Subscription Billing",
  "SAP Customer Engagement Center",
  "SAP Self-Service Accelerator fo",
  "SAP Self-Service Accelerator for Utilities by SEW",
  "SAP Omnichannel Point-of-Sale by GK",
  "SAP Student Lifecycle Management",
  "SAP Trade Management",
  "SAP Trade Promotion Management",
  "SAP Upscale Commerce",
  "SAP Utilities Customer Engagement",
  "SAP Data Custodian",
  "SAP Digital Manufacturing Cloud",
  "SAP Digital Manufacturing Insights",
  "Enterprise Management",
  "SAP Manufacturing Execution",
  "SAP Manufacturing Integration and Intelligence",
  "SAP Asset Manager",
  "SAP Business ByDesign",
  "SAP Digital Vehicle Suite",
  "SAP Enterprise Asset Management",
  "SAP Entitlement Management",
  "SAP ERP",
  "SAP ERP add-on for retail",
  "SAP S/4HANA",
  "SAP S/4HANA Cloud",
  "SAP S/4HANA Finance",
  "SAP S/4HANA for central procurement",
  "SAP S/4HANA Cloud for central procurement",
  "SAP S/4HANA Cloud for projects(project collaboration)",
  "Banking services from SAP",
  "SAP Access Control for SAP S/4HANA",
  "SAP Business Planning and Consolidation",
  "SAP Cloud for Real Estate",
  "SAP Commodity Management",
  "SAP Process Control",
  "SAP Profitability and Performance Management",
  "SAP",
  "SAP Risk Management",
  "SAP Risk Management for SAP S/4HANA",
  "SAP Spend Performance Management",
  "SAP Sustainability Performance Management",
  "SAP Treasury and Risk Management(version for the United States)",
  "Health and Safety Management",
  "SAP Environment",
  "Qualtrics EmployeeXM for IT",
  "SAP Fieldglass Time Entry",
  "SAP Qualtrics 360 Feedback",
  "SAP Qualtrics Employee Benefits Optimizer",
  "SAP Qualtrics Employee Engagement",
  "SAP Qualtrics Employee Lifecycle",
  "SAP SuccessFactors H",
  "SAP SuccessFactors HCM Core",
  "SAP SuccessFactors",
  "SAP SuccessFactors Compensation",
  "SAP SuccessFactors Employee Central",
  "SAP SuccessFactors Employee Central Payroll",
  "SAP SuccessFactors Employee Central Service",
  "SAP SuccessFactors Employee Central Service Center",
  "SAP SuccessFactors HXM Core",
  "SAP SuccessFactors HXM Suite",
  "SAP SuccessFactors Learning",
  "SAP SuccessFactors Learning Marketplace",
  "SAP SuccessFactors Mobile",
  "SAP SuccessFactors Onboarding",
  "SAP SuccessFactors Opportunity Marketplace",
  "SAP SuccessFactors People ",
  "SAP SuccessFactors Performance & Goals",
  "SAP SuccessFactors platform",
  "SAP SuccessFactors Recruit",
  "SAP SuccessFactors Recruiting",
  "SAP SuccessFactors Succession & Development",
  "SAP SuccessFactors Workforce ",
  "SAP SuccessFactors Work-Life",
  "SAP Workforce Management",
  "SAP Dairy Management by msg",
  "SAP Enterprise Product Development",
  "SAP Fashion Management",
  "SAP Innovation Management",
  "SAP Intelligent Product Design",
  "SAP Meat and Fish Management by msg",
  "SAP Meat Management by msg",
  "SAP Product Lifecycle Costing",
  "Ariba Network",
  "SAP Ariba Buying",
  "SAP Ariba Buying and Invoicing",
  "SAP Ariba Contracts",
  "SAP Ariba Discount Management",
  "SAP Ariba Invoice Management",
  "SAP Ariba Procurement",
  "SAP Ariba Snap",
  "SAP Ariba Sourcing",
  "SAP Ariba Spend Analysis",
  "SAP Ariba Spot Buy Catalog",
  "SAP Ariba Supplier",
  "SAP Ariba Supplier Information and Performance Management",
  "SAP Ariba Supplier Lifecycle and Performance",
  "SAP Ariba Supplier Risk",
  "SAP Ariba Supply Chain",
  "SAP Fieldglass Contingent Workforce Management",
  "SAP Fieldglass Services Procurement",
  "SAP Fieldglass Vendor",
  "SAP Fieldglass Vendor Management System",
  "SAP Fieldglass\xa0Assignment Management",
  "SAP Rural Sourcing Management",
  "SAP Strategic Sourcing Suite",
  "SAP Supplier Relationship Management",
  "SAP Internet of Things",
  "SAP Extended Warehouse Management",
  "SAP Farm Management by Vistex",
  "SAP Global Track and Trace",
  "SAP Grower Management for Perishables by Vistex",
  "SAP Integrated Business Planning for Supply Chain",
  "SAP Asset Intelligence Network",
  "SAP Asset Strategy and Performance Management",
  "SAP Predictive Asset Insights",
  "SAP Logistics Business Network",
  "SAP S/4HANA Supply Chain",
  "SAP Sales and Operations Planning",
  "SAP Transportation Management",
  "SAP Process Insights",
  "Qualtrics CoreXM",
  "Qualtrics CustomerXM",
  "Qualtrics EmployeeXM",
  "Qualtrics XM Platform",
  "business rules",
  "SAP Business Technology Platform",
  "SAP Integration Suite",
  "SAP IoT services for SAP BTP",
  "SAP Cloud Identity Access Governance",
  "SAP Data Warehouse Cloud",
  "SAP Fiori",
  "SAP Fiori Cloud",
  "SAP HANA spatial services",
  "SAP HANA(platform edition)",
  "SAP HANA Cloud",
  "SAP Landscape Management",
  "SAP Mobile App Protection by Mocana",
  "SAP SuccessFactors Work Zone",
  "SAP Work Zone",
];

// LOB List
export const listOfBo = [
  { value: "0", label: "Finance" },
  { value: "1", label: "CX" },
  { value: "2", label: "HXM" },
  { value: "3", label: "Sourcing and Procurement" },
  { value: "4", label: "Travel & Expense" },
  { value: "5", label: "BTP" },
  { value: "6", label: "DSC" },
  { value: "7", label: "ISM" },
];

//  industry suboptions lists
let listOfIndConsumer = [
  { value: "0", label: "Consumer Products" },
  { value: "1", label: "Healthcare" },
  { value: "2", label: "Life Sciences" },
  { value: "3", label: "Retail" },
  { value: "4", label: "Retail- Fashion" },
  { value: "5", label: "Wholesale Distribution" },
];
let listOfIndDiscrete = [
  { value: "6", label: "Aerospace & Defense" },
  { value: "7", label: "Automotive" },
  { value: "8", label: "High Tech" },
  { value: "9", label: "Industrial Manufacturing" },
];
let listOfIndENR = [
  { value: "10", label: "Chemicals" },
  { value: "11", label: "Mill Products & Mining" },
  { value: "12", label: "Oil Gas & Energy" },
  { value: "13", label: "Utilities" },
];
let listOfIndFinServe = [
  { value: "14", label: "Banking" },
  { value: "15", label: "Insurance" },
];
let listOfIndPublicService = [
  { value: "16", label: "Defense & Security" },
  { value: "17", label: "Public Sector" },
];
let listOfIndService = [
  { value: "18", label: "Engineering Construction & Operations" },
  { value: "19", label: "Higher Education & Research" },
  { value: "20", label: "Media" },
  { value: "21", label: "Professional Services" },
  { value: "22", label: "Sports & Entertainment" },
  { value: "23", label: "Telco" },
  { value: "24", label: "Travel & Transport" },
];
let listOfIndOther = [{ value: "25", label: "X-Industry" }];
//   industry grouped options list
export const groupedOptionsInd = [
  {
    label: "Consumer",
    disabled:true,
    options: listOfIndConsumer,
  },
  {
    label: "Discrete",
    options: listOfIndDiscrete,
  },
  {
    label: "ENR",
    options: listOfIndENR,
  },
  {
    label: "Finserve",
    options: listOfIndFinServe,
  },
  {
    label: "Public Services",
    options: listOfIndPublicService,
  },
  {
    label: "Services",
    options: listOfIndService,
  },
  {
    label: "Other",
    options: listOfIndOther,
  },
];
// domain process suboptions lists
let listOfDomainIdea = [
  { value: "0", label: "Plan to Optimize Products/Services" },
  { value: "1", label: "Idea to Requirements" },
  { value: "2", label: "Design to Release" },
  { value: "3", label: "Products/Services to Market" },
  { value: "4", label: "Manage Products/ Services" },
];
let listOfDomainSource = [
  { value: "5", label: "Plan to Optimize Sourcing and Procurement" },
  { value: "6", label: "Source to Contract" },
  { value: "7", label: "Plan to Optimize Fulfillment" },
  { value: "8", label: "Procure to receipt" },
  { value: "9", label: "Request to Resolution" },
  { value: "10", label: "Invoice to Pay" },
  { value: "11", label: "Manage Suppliers & Collaboration" },
];
let listOfDomainPlanToFill = [
  { value: "12", label: "Plan to Optimize Fulfillment" },
  { value: "13", label: "Make to Inspect" },
  { value: "14", label: "Procure to Receipt" },
  { value: "15", label: "Deliver Product to Fulfill" },
  { value: "16", label: "Delver Service to Fulfill" },
  { value: "17", label: "Manage Fulfillment" },
];
let listOfDomainLeadToCash = [
  { value: "18", label: "Plan to Optimize Marketing and Sales" },
  { value: "19", label: "Market to Lead" },
  { value: "20", label: "Opportunity to Quote" },
  { value: "21", label: "Order to Fulfill" },
  { value: "22", label: "Request to Service" },
  { value: "23", label: "Invoice to Cash" },
  { value: "24", label: "Manage Customers and Channels" },
];
let listOfDomainRecuritToRetire = [
  { value: "25", label: "Plan to Optimize the Workforce" },
  { value: "26", label: "Recruit to Onboard" },
  { value: "27", label: "Develop to Grow" },
  { value: "28", label: "Reward to retain" },
  { value: "29", label: "Manage Workforce and Retirement" },
];
let listOfDomainAcquireToDecommission = [
  { value: "30", label: "Plan to Optimize Assets" },
  { value: "31", label: "Acquire to Onboard" },
  { value: "32", label: "Operate to Maintain" },
  { value: "33", label: "Offboard to Decommission" },
  { value: "34", label: "Manage Assets" },
];
let listOfDomainGovernance = [
  { value: "35", label: "Plan to Optimize Enterprise" },
  { value: "36", label: "Manage Portfolio and Projects" },
  { value: "37", label: "Manage Sustainable Operations" },
  { value: "38", label: "Manage Governance, Compliance and Risk" },
  { value: "39", label: "Manage Information technology" },
];
let listOfDomainFinance = [
  { value: "40", label: "Plan to Optimize Financials" },
  { value: "41", label: "Invoice to Pay" },
  { value: "42", label: "Invoice to Cash" },
  { value: "43", label: "Record to Report" },
  { value: "44", label: "Manage Treasury" },
  { value: "45", label: "Manage Real Estate" },
];

//  domain options list
export const groupedOptionsDomain = [
  {
    label: "Idea to Market",
    options: listOfDomainIdea,
  },
  {
    label: "Source to Pay",
    options: listOfDomainSource,
  },
  {
    label: "Plan to Fulfill",
    options: listOfDomainPlanToFill,
  },
  {
    label: "Lead to Cash",
    options: listOfDomainLeadToCash,
  },
  {
    label: "Recruit to Retire",
    options: listOfDomainRecuritToRetire,
  },
  {
    label: "Acquire to Decommission",
    options: listOfDomainAcquireToDecommission,
  },
  {
    label: "Governance",
    options: listOfDomainGovernance,
  },
  {
    label: "Finance",
    options: listOfDomainFinance,
  },
];
export const BODropdownData =[
  {
    text: "Benefitfocus-Improve benefit administration function efficiency"
  },
  {
    text: "Benefitfocus-Red. printing, distri. & shipping cost of paper benefits pkg"
  },
  {
    text: "Benefitfocus-Reduce cost of employee benefits"
  },
  {
    text: "Benefitfocus-Reduce dependent eligibility verification cost"
  },
  {
    text: "Comprehensive improvement on indirect spend"
  },
  {
    text: "Decrease combined ratio"
  },
  {
    text: "Decrease expense ratio"
  },
  {
    text: "HR_SPM_Improve commission spend optimization"
  },
  {
    text: "HR_SPM_Improve incentive administrator efficiency"
  },
  {
    text: "HR_SPM_Improve technical FTE efficiency"
  },
  {
    text: "HR_SPM_Increase sales productivity"
  },
  {
    text: "HR_SPM_Reduce commission overpayment"
  },
  {
    text: "HR_SPM_Reduce compliance liability"
  },
  {
    text: "HR_SPM_Reduce sales representative turnover"
  },
  {
    text: "HR_SPM_Reduce shadow accounting"
  },
  {
    text: "Imp. ext. wrkfrc. purchase order requisitioning & transmission FTE prod."
  },
  {
    text: "Imp. svcs. procrmt. purchase order requisitioning & transmission FTE prod."
  },
  {
    text: "Improve accounts payable FTE productivity"
  },
  {
    text: "Improve admin FTE productivity"
  },
  {
    text: "Improve aircraft dispatch reliability"
  },
  {
    text: "Improve analytics FTE productivity"
  },
  {
    text: "Improve application delivery time"
  },
  {
    text: "Improve application deployment frequency"
  },
  {
    text: "Improve application deployment quality"
  },
  {
    text: "Improve application development FTE productivity"
  },
  {
    text: "Improve asset data accuracy"
  },
  {
    text: "Improve asset data completeness"
  },
  {
    text: "Improve average order value (AOV)"
  },
  {
    text: "Improve billing FTE productivity"
  },
  {
    text: "Improve chat users conversion rate for new customers"
  },
  {
    text: "Improve citizen satisfaction"
  },
  {
    text: "Improve compliance to SLA"
  },
  {
    text: "Improve contact center FTE productivity"
  },
  {
    text: "Improve content search, know. sharing & collaboration"
  },
  {
    text: "Improve control over program budgets"
  },
  {
    text: "Improve customer profitability analysis"
  },
  {
    text: "Improve customer satisfaction"
  },
  {
    text: "Improve customer self-service"
  },
  {
    text: "Improve cycle time for new contract creation"
  },
  {
    text: "Improve data quality"
  },
  {
    text: "Improve database administration FTE productivity"
  },
  {
    text: "Improve days payable outstanding"
  },
  {
    text: "Improve demand forecast accuracy"
  },
  {
    text: "Improve developer satisfaction"
  },
  {
    text: "Improve discount achievement"
  },
  {
    text: "Improve discount achievement for employee initiated spend"
  },
  {
    text: "Improve discount achievement for external workforce and services spend"
  },
  {
    text: "Improve display advertising"
  },
  {
    text: "Improve eMail conversion rate for new customers"
  },
  {
    text: "Improve employee productivity related to EIS activities"
  },
  {
    text: "Improve environmental safety and compliance"
  },
  {
    text: "Improve external worker retention"
  },
  {
    text: "Improve external workforce invoice receipt & processing FTE productivity"
  },
  {
    text: "Improve field service FTE productivity"
  },
  {
    text: "Improve first call resolution rate"
  },
  {
    text: "Improve hiring effectiveness"
  },
  {
    text: "Improve hospital bed utilization"
  },
  {
    text: "Improve HR FTE productivity"
  },
  {
    text: "Improve HR reporting and analytics capability"
  },
  {
    text: "Improve HR shared services efficiency"
  },
  {
    text: "Improve in-store average order value"
  },
  {
    text: "Improve incremental discounts"
  },
  {
    text: "Improve IP inventory data"
  },
  {
    text: "Improve learner effectiveness"
  },
  {
    text: "Improve learning and development function efficiency"
  },
  {
    text: "Improve marketing FTE productivity"
  },
  {
    text: "Improve merchandising FTE productivity"
  },
  {
    text: "Improve on-time delivery performance"
  },
  {
    text: "Improve on-time delivery performance for external workers"
  },
  {
    text: "Improve operational procurement FTE productivity"
  },
  {
    text: "Improve overall equipment effectiveness"
  },
  {
    text: "Improve P card rebates and float"
  },
  {
    text: "Improve payroll management function efficiency"
  },
  {
    text: "Improve planner FTE productivity"
  },
  {
    text: "Improve procurement FTE productivity"
  },
  {
    text: "Improve product traceability"
  },
  {
    text: "Improve production and revenue accounting FTE productivity"
  },
  {
    text: "Improve productivity of FTEs in charge of EPR / plastic tax compliance"
  },
  {
    text: "Improve productivity of low performing employees"
  },
  {
    text: "Improve quality of care"
  },
  {
    text: "Improve quality of leads generated"
  },
  {
    text: "Improve recruiting function efficiency"
  },
  {
    text: "Improve revenue due to reduced order cancellation"
  },
  {
    text: "Improve revenue through website promotion"
  },
  {
    text: "Improve sales margin (petroleum products)"
  },
  {
    text: "Improve savings from shifting peak-hour consumption"
  },
  {
    text: "Improve service entry sheet FTE productivity"
  },
  {
    text: "Improve services procurement invoice receipt & processing FTE productivity"
  },
  {
    text: "Improve source to contract FTE productivity"
  },
  {
    text: "Improve sourcing savings on direct spend"
  },
  {
    text: "Improve sourcing savings on indirect spend"
  },
  {
    text: "Improve sourcing savings on indirect spend (Strategic)"
  },
  {
    text: "Improve strategic business and resource planning"
  },
  {
    text: "Improve student enrollment"
  },
  {
    text: "Improve supplier onboarding FTE productivity"
  },
  {
    text: "Improve supplier/external partner compliance"
  },
  {
    text: "Improve supply chain FTE productivity"
  },
  {
    text: "Improve tactical sourcing savings"
  },
  {
    text: "Improve targeting of training"
  },
  {
    text: "Improve technology adoption"
  },
  {
    text: "Improve time to full productivity of new hires"
  },
  {
    text: "Improve trade margin/profitability"
  },
  {
    text: "Improve trade spend effectiveness"
  },
  {
    text: "Improve user compliance"
  },
  {
    text: "Improve user compliance (Strategic)"
  },
  {
    text: "Improve user compliance for employee initiated spend"
  },
  {
    text: "Improve user experience and interface solutions"
  },
  {
    text: "Improve worker quality"
  },
  {
    text: "Improve wrench time"
  },
  {
    text: "Improved claim handling efficiency"
  },
  {
    text: "Increase % of projects on time, budget and scope"
  },
  {
    text: "Increase active pipeline"
  },
  {
    text: "Increase billable revenue from project changes"
  },
  {
    text: "Increase billable utilization rate"
  },
  {
    text: "Increase buying frequency"
  },
  {
    text: "Increase capacity utilization"
  },
  {
    text: "Increase cash forecast accuracy"
  },
  {
    text: "Increase conversion of leads qualified"
  },
  {
    text: "Increase customer adoption of eCommerce"
  },
  {
    text: "Increase digital channel revenue through increase in conversion rate"
  },
  {
    text: "Increase effectiveness of marketing promotions"
  },
  {
    text: "Increase efficiency  for charge-point operations"
  },
  {
    text: "Increase efficiency of external marketing audit"
  },
  {
    text: "Increase employee engagement"
  },
  {
    text: "Increase employee experience management program efficiency"
  },
  {
    text: "Increase employee initiated spend under management"
  },
  {
    text: "Increase employee technology effectiveness"
  },
  {
    text: "Increase external workforce & service delivery compliance"
  },
  {
    text: "Increase financial forecast accuracy"
  },
  {
    text: "Increase first time resolution rate by field service technicians"
  },
  {
    text: "Increase grant management FTE productivity"
  },
  {
    text: "Increase in-store conversion rate"
  },
  {
    text: "Increase in-store foot traffic"
  },
  {
    text: "Increase interest income on loans"
  },
  {
    text: "Increase internal promotions"
  },
  {
    text: "Increase IT project execution success"
  },
  {
    text: "Increase IT user productivity"
  },
  {
    text: "Increase license revenue"
  },
  {
    text: "Increase number of external workers meeting goals and objectives"
  },
  {
    text: "Increase number of marketing qualified leads (MQL)"
  },
  {
    text: "Increase number of SOW based projects meeting goals and objectives"
  },
  {
    text: "Increase number of website visitors"
  },
  {
    text: "Increase order management FTE productivity"
  },
  {
    text: "Increase overall budget accuracy"
  },
  {
    text: "Increase price elasticity"
  },
  {
    text: "Increase profit with product personalization"
  },
  {
    text: "Increase project margin"
  },
  {
    text: "Increase project reporting & analysis FTE productivity"
  },
  {
    text: "Increase proposal win rate"
  },
  {
    text: "Increase real estate occupancy rate"
  },
  {
    text: "Increase return on marketing spend (ROMI)"
  },
  {
    text: "Increase revenue from cross-sell/up-sell"
  },
  {
    text: "Increase revenue from new business models"
  },
  {
    text: "Increase revenue from new products/services"
  },
  {
    text: "Increase revenue growth with better product personalization"
  },
  {
    text: "Increase revenue share of new customers"
  },
  {
    text: "Increase sales force efficiency"
  },
  {
    text: "Increase sales forecast accuracy"
  },
  {
    text: "Increase sales incentive management FTE productivity"
  },
  {
    text: "Increase sales per in-store employee"
  },
  {
    text: "Increase savings by managing external worker rates"
  },
  {
    text: "Increase savings on services categories by managing worker rates"
  },
  {
    text: "Increase savings through rate management"
  },
  {
    text: "Increase service contract renewals"
  },
  {
    text: "Increase service revenue"
  },
  {
    text: "Increase service revenue from new business models"
  },
  {
    text: "Increase spend under management"
  },
  {
    text: "Increase spend under management (Strategic)"
  },
  {
    text: "Increase tax revenue collection yield"
  },
  {
    text: "Increase total premium earned"
  },
  {
    text: "Increase user confidence in data"
  },
  {
    text: "Increase word of mouth revenue"
  },
  {
    text: "Increase worker capacity utilization"
  },
  {
    text: "Kronos-Imp. time tracking & schedule mgmt. fn. efficiency"
  },
  {
    text: "Kronos-Reduce HR/payroll FTE time on employee information change requests"
  },
  {
    text: "Kronos-Reduce labor fine avoidance"
  },
  {
    text: "Kronos-Reduce leave liability balance"
  },
  {
    text: "Kronos/WFS-Improve payroll accuracy"
  },
  {
    text: "Kronos/WFS-Red. HR/payroll FTE time on tracking & mgmt. of leaves & time accruals"
  },
  {
    text: "Kronos/WFS-Reduce controllable overtime expense/payments"
  },
  {
    text: "Kronos/WFS-Reduce manager time on time keeping & scheduling activities"
  },
  {
    text: "Kronos/WFS-Reduce payroll time inflation and gaming"
  },
  {
    text: "Manage holdback better for services delivery"
  },
  {
    text: "Minimize hydrocarbon production days lost"
  },
  {
    text: "Mitigate Enhanced Producer Responsibility fees / plastics taxes"
  },
  {
    text: "Optimize capital expenditure"
  },
  {
    text: "Optimize data storage cost"
  },
  {
    text: "Optimize digital marketing spend"
  },
  {
    text: "Optimize marketing cost"
  },
  {
    text: "Optimize research and development expense"
  },
  {
    text: "Optimize trade promotion spend"
  },
  {
    text: "Reduce accounts receivable management cost"
  },
  {
    text: "Reduce annual licensor royalty spend"
  },
  {
    text: "Reduce application build and deploy cost"
  },
  {
    text: "Reduce application maintenance and support cost"
  },
  {
    text: "Reduce asset data management cost"
  },
  {
    text: "Reduce asset maintenance cost"
  },
  {
    text: "Reduce audit cost"
  },
  {
    text: "Reduce average call handling time"
  },
  {
    text: "Reduce bad hires"
  },
  {
    text: "Reduce banking fees"
  },
  {
    text: "Reduce bounce rate"
  },
  {
    text: "Reduce BPM subscription/maintenance cost"
  },
  {
    text: "Reduce BPM training cost"
  },
  {
    text: "Reduce budgeting and forecasting cost"
  },
  {
    text: "Reduce business and operations analysis/reporting cost"
  },
  {
    text: "Reduce business process design and modelling cost (internal)"
  },
  {
    text: "Reduce business process implementation cost (external)"
  },
  {
    text: "Reduce business process management cost (internal)"
  },
  {
    text: "Reduce business process mapping and analysis cost (Internal)"
  },
  {
    text: "Reduce business process mapping cost (external)"
  },
  {
    text: "Reduce business process monitoring cost (internal)"
  },
  {
    text: "Reduce business process optimization cost (internal)"
  },
  {
    text: "Reduce business process orchestration cost (internal)"
  },
  {
    text: "Reduce carbon footprint"
  },
  {
    text: "Reduce carbon footprint of product returns"
  },
  {
    text: "Reduce cart abandonment rate"
  },
  {
    text: "Reduce claim processing cost"
  },
  {
    text: "Reduce claim settlement time"
  },
  {
    text: "Reduce COGS with better product design decisions"
  },
  {
    text: "Reduce commission overpayments"
  },
  {
    text: "Reduce commission payouts"
  },
  {
    text: "Reduce complaints and returns cost"
  },
  {
    text: "Reduce compliance and risk management cost"
  },
  {
    text: "Reduce contract administration cost"
  },
  {
    text: "Reduce cost accounting and analysis cost"
  },
  {
    text: "Reduce cost associated with worker onboarding"
  },
  {
    text: "Reduce cost by increasing discount capture on external workforce spend"
  },
  {
    text: "Reduce cost by increasing discount capture on services spend"
  },
  {
    text: "Reduce cost of assets lost tied to external workers"
  },
  {
    text: "Reduce cost of assets lost tied to services categories"
  },
  {
    text: "Reduce cost of billing for EV charge-at-home scenarios"
  },
  {
    text: "Reduce cost of capital"
  },
  {
    text: "Reduce cost of digital channels content management"
  },
  {
    text: "Reduce cost of HR audit"
  },
  {
    text: "Reduce cost to enable disconnected operations"
  },
  {
    text: "Reduce customer billing, credit & collections cost"
  },
  {
    text: "Reduce customer churn"
  },
  {
    text: "Reduce customer consent management cost"
  },
  {
    text: "Reduce data integration cost"
  },
  {
    text: "Reduce data management cost"
  },
  {
    text: "Reduce data quality cost"
  },
  {
    text: "Reduce data security cost"
  },
  {
    text: "Reduce days deductibles outstanding"
  },
  {
    text: "Reduce days in inventory"
  },
  {
    text: "Reduce days In inventory -  raw material"
  },
  {
    text: "Reduce days in inventory - finished goods"
  },
  {
    text: "Reduce days in inventory - returnable packaging"
  },
  {
    text: "Reduce days in inventory - work in progress (WIP)"
  },
  {
    text: "Reduce days sales outstanding"
  },
  {
    text: "Reduce days to close annual books"
  },
  {
    text: "Reduce design and documentation cost"
  },
  {
    text: "Reduce digital asset creation cost"
  },
  {
    text: "Reduce direct recruiting spend"
  },
  {
    text: "Reduce document signature cycle time"
  },
  {
    text: "Reduce document signature error rate"
  },
  {
    text: "Reduce document signature hard cost"
  },
  {
    text: "Reduce downtime losses (application deployment)"
  },
  {
    text: "Reduce effort to implement legislation changes"
  },
  {
    text: "Reduce EH&S management cost"
  },
  {
    text: "Reduce EH&S penalties and fines"
  },
  {
    text: "Reduce emissions cost"
  },
  {
    text: "Reduce employee manual transaction effort"
  },
  {
    text: "Reduce employee support cost"
  },
  {
    text: "Reduce energy cost"
  },
  {
    text: "Reduce energy regulation related penalties and fines"
  },
  {
    text: "Reduce engineering change cost"
  },
  {
    text: "Reduce errors in bills sent out"
  },
  {
    text: "Reduce external workforce cost using tactical bidding"
  },
  {
    text: "Reduce external workforce invoice errors"
  },
  {
    text: "Reduce field engineering time to onboard new EV chargers"
  },
  {
    text: "Reduce field service administration cost"
  },
  {
    text: "Reduce finance cost"
  },
  {
    text: "Reduce financial exposure risk"
  },
  {
    text: "Reduce G/L and financial closing cost"
  },
  {
    text: "Reduce grant funding lost"
  },
  {
    text: "Reduce grant write-offs"
  },
  {
    text: "Reduce HR admin time on compensation management"
  },
  {
    text: "Reduce HR admin time on offboarding"
  },
  {
    text: "Reduce HR admin time on onboarding"
  },
  {
    text: "Reduce HR admin time on performance & goals management"
  },
  {
    text: "Reduce HR admin time on succession planning"
  },
  {
    text: "Reduce HR admin time on talent management"
  },
  {
    text: "Reduce HR compliance cost and mitigate risk"
  },
  {
    text: "Reduce HR cost"
  },
  {
    text: "Reduce HR FTE time on functional and ad hoc reporting"
  },
  {
    text: "Reduce HR technology cost"
  },
  {
    text: "Reduce HR time on manually supported transactions in personnel administration"
  },
  {
    text: "Reduce ideation/innovation management cost"
  },
  {
    text: "Reduce inaccurate utility bills"
  },
  {
    text: "Reduce inventory carrying cost"
  },
  {
    text: "Reduce invoice error rate"
  },
  {
    text: "Reduce invoice errors for external workforce and services spend"
  },
  {
    text: "Reduce IT infrastructure spend"
  },
  {
    text: "Reduce IT spend"
  },
  {
    text: "Reduce lead time for expense reimbursement of EV charge-at-home scenarios"
  },
  {
    text: "Reduce lease compliance errors"
  },
  {
    text: "Reduce leave liability balance for salaried employees"
  },
  {
    text: "Reduce loan servicing Cost"
  },
  {
    text: "Reduce loss ratio"
  },
  {
    text: "Reduce losses due to inactive meters"
  },
  {
    text: "Reduce losses from unforeseen risk (fraud, access risk)"
  },
  {
    text: "Reduce lost time accidents"
  },
  {
    text: "Reduce manager time on compensation management"
  },
  {
    text: "Reduce manager time on employee administration"
  },
  {
    text: "Reduce manager time on performance & goals management"
  },
  {
    text: "Reduce manager time on talent management"
  },
  {
    text: "Reduce manager time on workflow administration (excl. positive time & attendance, travel and purchasing administration)"
  },
  {
    text: "Reduce manual transaction and HR administration effort"
  },
  {
    text: "Reduce manufacturing cycle time"
  },
  {
    text: "Reduce manufacturing direct labor cost"
  },
  {
    text: "Reduce manufacturing direct material cost"
  },
  {
    text: "Reduce manufacturing overheads cost"
  },
  {
    text: "Reduce margin leakage"
  },
  {
    text: "Reduce marketing analytics spend"
  },
  {
    text: "Reduce master data management cost"
  },
  {
    text: "Reduce metadata management cost"
  },
  {
    text: "Reduce meter installation cost"
  },
  {
    text: "Reduce meter reading and data administration cost"
  },
  {
    text: "Reduce meter reading to invoice cycle time"
  },
  {
    text: "Reduce mobile application lifecycle management cost"
  },
  {
    text: "Reduce MRO inventory cost"
  },
  {
    text: "Reduce non-compliant external workforce spend"
  },
  {
    text: "Reduce non-compliant services spend"
  },
  {
    text: "Reduce non-essential travel cost"
  },
  {
    text: "Reduce onboarding time for field sales representatives"
  },
  {
    text: "Reduce ongoing localization cost"
  },
  {
    text: "Reduce order fulfillment lead time"
  },
  {
    text: "Reduce overall supply chain planning cost"
  },
  {
    text: "Reduce payment processing cost"
  },
  {
    text: "Reduce penalties and fines on data protection & identity regulation"
  },
  {
    text: "Reduce product compliance cost"
  },
  {
    text: "Reduce productivity loss to unplanned absenteeism"
  },
  {
    text: "Reduce project cost"
  },
  {
    text: "Reduce project cost overrun"
  },
  {
    text: "Reduce project delays"
  },
  {
    text: "Reduce project delivery cost"
  },
  {
    text: "Reduce project management cost"
  },
  {
    text: "Reduce project subcontracting cost"
  },
  {
    text: "Reduce real estate management cost"
  },
  {
    text: "Reduce research budget overrun"
  },
  {
    text: "Reduce returnable packaging spend"
  },
  {
    text: "Reduce revenue leakage"
  },
  {
    text: "Reduce revenue loss due to fulfillment Issues"
  },
  {
    text: "Reduce revenue loss due to insurance claim denials"
  },
  {
    text: "Reduce revenue loss due to stock-outs"
  },
  {
    text: "Reduce revenue loss in returns"
  },
  {
    text: "Reduce revenue lost due to poor contract management"
  },
  {
    text: "Reduce rework cost"
  },
  {
    text: "Reduce risk associated with external worker classification"
  },
  {
    text: "Reduce risk of data non-compliance losses"
  },
  {
    text: "Reduce risk of quality non-compliance"
  },
  {
    text: "Reduce sales cost"
  },
  {
    text: "Reduce sales cycle time"
  },
  {
    text: "Reduce sales force turnover"
  },
  {
    text: "Reduce scrap cost"
  },
  {
    text: "Reduce security, health, and safety risk"
  },
  {
    text: "Reduce service and support cost"
  },
  {
    text: "Reduce service contract administration cost"
  },
  {
    text: "Reduce services cost by tactical bidding"
  },
  {
    text: "Reduce services invoice errors"
  },
  {
    text: "Reduce social media cost"
  },
  {
    text: "Reduce SOW budgeting & forecasting cost"
  },
  {
    text: "Reduce store operating cost"
  },
  {
    text: "Reduce supervisor/manager time on approval administration"
  },
  {
    text: "Reduce tax accounting and reporting cost"
  },
  {
    text: "Reduce the tax gap"
  },
  {
    text: "Reduce time spent by sales reps on shadow accounting"
  },
  {
    text: "Reduce time to create a configured quote"
  },
  {
    text: "Reduce time to field service invoice"
  },
  {
    text: "Reduce time to fill"
  },
  {
    text: "Reduce time to market for new products"
  },
  {
    text: "Reduce time to report to field service back office"
  },
  {
    text: "Reduce time to steady state production volume"
  },
  {
    text: "Reduce time to update catalog content"
  },
  {
    text: "Reduce total logistics cost"
  },
  {
    text: "Reduce total manufacturing cost"
  },
  {
    text: "Reduce trade regulation penalties"
  },
  {
    text: "Reduce training spend for external learners"
  },
  {
    text: "Reduce transportation planning cycle time"
  },
  {
    text: "Reduce transportation spend"
  },
  {
    text: "Reduce treasury and cash management cost"
  },
  {
    text: "Reduce turnover"
  },
  {
    text: "Reduce un-planned downtime or outages"
  },
  {
    text: "Reduce uncollectible accounts receivable write offs"
  },
  {
    text: "Reduce unplanned downtime of assets (fleet, real property etc.)"
  },
  {
    text: "Reduce unplanned markdowns"
  },
  {
    text: "Reduce vacancies in critical roles"
  },
  {
    text: "Reduce warehouse management cost"
  },
  {
    text: "Reduce warranty cost"
  },
  {
    text: "Reduce waste generation cost"
  },
  {
    text: "Reduce water generation cost"
  },
  {
    text: "Reduce worker onboarding cycle time"
  },
  {
    text: "Reduction in claim write-offs"
  },
  {
    text: "WFS-Reduce paid time-off errors"
  }
]