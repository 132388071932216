import { useEffect } from "react";
import { Provider } from "react-redux";
import "./App.css";
import Routes from "./components/Routes";
import { useNavigate } from "react-router-dom";
import {
  generateKey,
  requestFirebaseNotificationPermission,
} from "./components/Services/firebaseInit";
import { store } from "./store";
import { DraftTableContext } from "./context/DraftTableContext";
import { ApprovedDraftContext } from "./context/ApprovedTableContext";
import { AdminDraftContext } from "./context/AdminTableContext";
import { ChatContext } from "./context/ChatSettingContext";
import { MsalProvider } from "@azure/msal-react";
// import NetworkDetector from './Hoc/NetworkDetector';

function App({ pca }) {
  // const navigate = useNavigate();
  // const navigationClient = new CustomNavigationClient(navigate);
  // pca.setNavigationClient(navigationClient);

  useEffect(() => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        // console.log("Request", firebaseToken);
      })
      .catch((err) => {
        return console.log("=====Error===", err);
      });
  }, []);
  return (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <ChatContext>
          <DraftTableContext>
            <ApprovedDraftContext>
              <AdminDraftContext>
                <Routes />
              </AdminDraftContext>
            </ApprovedDraftContext>
          </DraftTableContext>
        </ChatContext>
      </MsalProvider>
    </Provider>
  );
}

export default App;