import { Select } from "antd";
import React from "react";

export default function DraftSelect({
  index,
  options = null,
  onChange = null,
  mode = null,
  placeholder = null,
  defaultValue,
  disabled,
  width = 240,
  fontSize = 16,
}) {
  const changehandler = (val, e) => {
    index ? onChange(val, e, index) : onChange(val, e, index);
  };
  return (
    <div>
      <Select
        style={{
          width: width,
          fontSize: fontSize,
          fontWeight: "400",
          borderRadius: "8px",
          border: "1px solid #919eab52",
        }}
        showSearch={true}
        onChange={changehandler}
        options={options}
        mode={mode}
        showArrow={true}
        placeholder={placeholder}
        value={defaultValue}
        disabled={disabled}
        bordered={false}
        filterOption={(input, option) => {
          return (option?.label.toLowerCase() ?? "").includes(
            input.toLowerCase()
          );
        }}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
      />
    </div>
  );
}
