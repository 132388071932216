import React from "react";
import ToriLogo from "../../../assets/tori_logo.svg";
// import ToriLogo from "../../../assets/testLogonTori.png";
import Modal from "react-modal";

import "../page1.css";

export default function DisplayModal({
  open,
  onClose,
  modalMsg,
  open2,
  heading1,
  isShowConfirm = false,
  isLoading = false,
  clearDraft,
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "30%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  };
  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "40%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  };
  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${modalMsg}`}</h5>
        </div>

        <div className="pt-3" style={{ float: "right", display: "flex" }}>
          <button
            onClick={onClose}
            className={
              isShowConfirm
                ? "primatybuttonwhite w-inline-block"
                : "primatybuttonred w-inline-block"
            }
          >
            {isShowConfirm ? "No" : "Close"}
          </button>
          {isShowConfirm && (
            <button
              onClick={clearDraft}
              className="ms-3 primatybuttonred w-inline-block"
            >
              {isLoading == true ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                "Yes"
              )}

            </button>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={open2}
        onRequestClose={onClose}
        style={customStyles2}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {heading1}
          </h5>
        </div>
        <div className="approval_msg">
          <div className="approval_msg_h1">
            <h2 className="approval_msg_h1">What Happens Next?</h2>
          </div>
          <div className="approval_msg_h2">{modalMsg}</div>
        </div>

        <div className="pt-3" style={{ float: "right", display: "flex" }}>
          <button onClick={onClose} className="primatybuttonred w-inline-block">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}
