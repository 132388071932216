import React from "react";
import { Avatar, Divider, Tooltip } from "antd";

export default function SharedWith({ draftSharedWith }) {
  return (
    <div style={{marginRight:'10px'}}>
      <Avatar.Group
      maxCount={2}
      maxPopoverTrigger="click"
      maxPopoverPlacement="bottom"
      size="medium"
      maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
      >
        { draftSharedWith&&draftSharedWith.map((user,i) => (
          <>
            <Tooltip title={user.name} placement="bottom" ti>
              <Avatar style={{ backgroundColor:i%3==0?"#E7AE3C":i%3==2?"#3B61C0":"#1C8E7A",cursor:'pointer'}}> {user.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</Avatar>
            </Tooltip>
          </>
        ))}
      </Avatar.Group>
    </div>
  );
}
