import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../environment";
import { getTokens } from "../../utils/helperfunctions/HelperFunctions";
var controller = new AbortController();
var token = "";
token = getTokens();

export const ChatApi = createApi({
  reducerPath: "sessions",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    // headers: {
    //   Authorization: `Bearer ${token}`,
    //   "Content-Type": "application/json",
    // },
    prepareHeaders: (headers, { getState }) => {
      // Get the token from the store
      // const token = getState().auth.token;
      const userToken = localStorage.getItem("token");

      // If the token is available, set the Authorization header
      if (userToken) {
        headers.set("Authorization", `Bearer ${userToken}`);
      }
      headers.set("Content-Type", `application/json`);

      return headers;
    },
    signal: controller.signal,
  }),
  tagTypes: ["Sessions"],
  endpoints: (builder) => ({
    getToriChatSessions: builder.query({
      query: (body) => ({ url: `/api/get-chat-sessions` }),
      providesTags: ["Sessions"],
    }),
    saveToriChat: builder.mutation({
      query: (body) => ({
        url: "/api/save-tori-chat",
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result, error, user) => {
        if (result?.status) return [{ type: 'Sessions' }];
      },
    }),
    saveToriChatForSapHelp: builder.mutation({
      query: (body) => ({
        url: "/api/chat-new/categories",
        body: body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Sessions" }],
    }),
    deleteToriChatSession: builder.mutation({
      query: (body) => ({
        url: `/api/delete-chat-session/${body}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sessions"],
    }),
    getChatMessages: builder.mutation({
      query: (body) => ({
        url: "/api/get-session-chat",
        body: body,
        method: "POST",
      }),
      providesTags: ["Sessions"],
    }),
  }),
});

export const {
  useGetToriChatSessionsQuery,
  useSaveToriChatMutation,
  useSaveToriChatForSapHelpMutation,
  useDeleteToriChatSessionMutation,
  useGetChatMessagesMutation,
} = ChatApi;
