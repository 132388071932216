import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import React from "react";
import "../page1.css";
import {REACT_APP_GRAMMARLY_CLIENT_ID} from '../../../../environment'
export default function OutcomeValues({
  show = true,
  header,
  onBlur,
  onChange,
  className,
  value,
  disableAllinputs,
}) {
  return (
    <>
      {show && (
        <div>
          <div className="row mb-2">
            <div
              className="col-12"
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {header}
            </div>
          </div>
          <div className="row">
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <GrammarlyEditorPlugin
                  clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
                >
                  <textarea
                    wrap="soft"
                    rows="1"
                    onBlur={onBlur}
                    onChange={onChange}
                    className={className}
                    value={value}
                    disabled={disableAllinputs}
                  />
                </GrammarlyEditorPlugin>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      )}
    </>
  );
}
