import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../environment";
import {
  scrollToTop,
  scrollToBottom,
} from "../../../utils/helperfunctions/HelperFunctions";
import {
  scriptOne,
  scriptTwo,
  scriptThree,
  FinalOutPut,
  FinalOutro,
  gettingDemo,
} from "../../Service";
import {
  SaveDraftState,
  GetSavedDraftDetails,
  ClearDraft,
  GetLineOfBusinessData,
  GetIndustriesData,
  GetDomainsData,
  DHLApproveRequest,
  TeachTori,
  getBusinessOutcomesList,
  CancelDHLRequest,
  GetAPIkey,
  GetUserData,
  SaveExaplainerState,
} from "../../Services/Service";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import info from "../../assets/info.svg";
import explainerBg from "../../assets/explainer_bg.png";
import bulb from "../../assets/bulb.png";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import "../../App.css";
import "./explainer.css";
import "../CreateDraft/page1.css";
import InputField from "../../inputfield/InputField";
import {
  videoNameTooltip,
  domainTooltip,
  industryTooltip,
  lineOfBusinessTooltip,
  productNameTooltip,
  approvalSmeMsg,
  approvalHubleadmsg,
} from "../../../utils/tooltips/Tooltips";
import SelectDropdown from "../../reactSelectDropdown/SelectDropdown";
import Header from "../CreateDraft/Components/Header";
import ProductName from "../CreateDraft/Components/ProductName";
import DisplayModal from "../CreateDraft/Components/Modal";
import GenerateOutroIntro from "../CreateDraft/Components/GenerateOutroIntro";
import OutcomeValues from "../CreateDraft/Components/OutcomeValues";
import ApprovalInfo from "../CreateDraft/Components/ApprovalInfo";
import { Select } from "antd";
import ExplainerDemoOptions from "./components/ExplainerDemoOptions";
import { debounce } from "lodash";
const ExplainerVideo = () => {
  const [duration, setDuration] = useState("Target Duration: 5-7 Minutes");
  let history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [demoKeyPointOne, setDemoKeyPointOne] = useState([]);
  const [demoKeyPointLoader, setDemoKeyPointloader] = useState(false);
  const [demoKeyPointLoaderDemo2, setDemoKeyPointloaderDemo2] = useState(false);
  const [demoKeyPointLoaderDemo3, setDemoKeyPointloaderDemo3] = useState(false);
  const [disableDemoKey, setDisableDemoKey] = useState(false);
  const [disableDemoKey2, setDisableDemoKey2] = useState(false);
  const [disAbleDemoKey3, setDisAbleDemoKey3] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stepDraftColor, setstepDraftColor] = useState();
  const [lineOfBusinessData, setLineOfBusinessData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [domainsData, setDomainsData] = useState([]);
  const [showDemo1RegenerateButton, setShowDemo1RegenerateButton] =
    useState(false);
  const [isDisableSaveDataButton, setIsDisableSaveDataButton] = useState(false);
  const [approvalSme, setapprovalSme] = useState(false);
  const [approvalHublead, setapprovalHublead] = useState(false);
  const [showapprovalInfoSme, setshowapprovalInfoSme] = useState(false);
  const [showapprovalInfoHublead, setshowapprovalInfoHublead] = useState(false);
  const [canApprove, setcanApprove] = useState(false);
  const [loadingReqApp, setloadingReqApp] = useState(false);
  const [regenLoading, setRegenLoading] = useState(false);
  const [isDisableReq, setisDisableReq] = useState(true);
  const [isDisableReqOnEditing, setisDisableReqOnEditing] = useState(true);
  const [userIdDraftOwner, setUserIdDraftOwner] = useState("");
  const [isDisableTeachTori, setisDisableTeachTori] = useState(true);
  const [loadingTori, setloadingTori] = useState(false);
  const [disableAllinputs, setdisableAllinputs] = useState(false);
  const [disAbleDemoKeyPoint, setDisAbleDemoKeyPoint] = useState(false);
  const [disableDemo1Fields, setdisableDemo1Fields] = useState(false);
  const [disableDemo2Fields, setdisableDemo2Fields] = useState(false);
  const [disableDemo3Fields, setdisableDemo3Fields] = useState(false);
  const [disableDemo1RightFields, setdisableDemo1RightFields] = useState(true);
  const [disableDemo2RightFields, setdisableDemo2RightFields] = useState(true);
  const [disableDemo3RightFields, setdisableDemo3RightFields] = useState(true);
  const [disableGrabberAndSolution, setdisableGrabberAndSolution] =
    useState(true);
  const [disableOutroField, setdisableOutroField] = useState(true);
  const [isDisableDemoScript, setisDisableDemoScript] = useState(false);
  const [isDisableLineOfBusiness, setIsDisableLineOfBusiness] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [draftCreatedBy, setdraftCreatedBy] = useState();
  const [loadingcancel, setLoadingcancel] = useState(false);
  const [boDropdownData, setboDropdownData] = useState([]);
  const [selectDemoOne, setSelectDemoOne] = useState(false);
  const [selectDemoTwo, setSelectDemoTwo] = useState(false);
  const [selectDemoThree, setSelectDemoThree] = useState(false);
  const [intro1Value, setintro1Value] = useState();
  const [teachTorimodal, setteachTorimodal] = useState(false);
  const [clearAllModal, setClearAllModal] = useState(false);
  const [teachTorimodalMsg, setteachTorimodalMsg] = useState("");
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [CancelDHLRequestMsg, setCancelDHLRequestMsg] = useState("");
  const [isDHLRequest, setisCancelDHLRequest] = useState(false);
  const [hubleadNames, setHubleadNames] = useState();
  const [isDisplay, setIsDisplay] = useState(false);
  var multiArr = [];
  const summaryTooltip =
    'This will end up being the description of the video once published, it also helps guide Tori on key thoughts and the ideal end state of the video. Do not worry, it doesn’t have to be perfect, copywriting will refine it as needed.\n\nExample : " This demo showcases how SAP S/4HANA helps you execute a great financial close with the previously defined tasks and setting you up to with the closing game."';
  const demoArr = [];

  const GettingDemo1Keypoints = () => {
    setDisableDemoKey(true);
    setSelectDemoOne(true);
    setDemoKeyPointloader(true);
    gettingDemo(firstProductName, secondProductName, industry, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setErrorMessageModal(true);
          setErrorMessage(
            "No Demo Key Points available. Please use input field."
          );
          setDemoKeyPointloader(false);
          setSelectDemoOne(false);
          setDisableDemoKey(false);
        } else {
          setDisableDemoKey(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });

          setDemoKeyPointOne(demoArr);
          setDemoKeyPointloader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisableDemoKey(false);
        setSelectDemoOne(false);
        setDemoKeyPointloader(false);
      });
  };
  const GettingDemo2Keypoints = () => {
    setDisableDemoKey2(true);
    setSelectDemoTwo(true);
    setDemoKeyPointloaderDemo2(true);
    gettingDemo(firstProductName, secondProductName, industry, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setErrorMessageModal(true);
          setErrorMessage(
            "No Demo Key Points available. Please use input field."
          );
          setDemoKeyPointloaderDemo2(false);
          setSelectDemoTwo(false);
          setDisableDemoKey2(false);
        } else {
          setDisableDemoKey2(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });

          setDemoKeyPointOne(demoArr);
          setDemoKeyPointloaderDemo2(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisableDemoKey2(false);
        setSelectDemoTwo(false);
        setDemoKeyPointloaderDemo2(false);
      });
  };
  const GettingDemo3Keypoints = () => {
    setDisAbleDemoKey3(true);
    setSelectDemoThree(true);
    setDemoKeyPointloaderDemo3(true);
    gettingDemo(firstProductName, secondProductName, industry, Lob1)
      .then((result) => {
        if (result.statusCode == 400) {
          setErrorMessageModal(true);
          setErrorMessage(
            "No Demo Key Points available. Please use input field."
          );
          setDemoKeyPointloaderDemo3(false);
          setSelectDemoThree(false);
          setDisAbleDemoKey3(false);
        } else {
          setDisAbleDemoKey3(false);
          let data = result.Key_points;
          data.map((row, i) => {
            demoArr.push({ id: i, name: row });
          });

          setDemoKeyPointOne(demoArr);
          setDemoKeyPointloaderDemo3(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setDisAbleDemoKey3(false);
        setSelectDemoThree(false);
        setDemoKeyPointloaderDemo3(false);
      });
  };
  const SavingRole1 = () => {
    CallingSaveDraftAPI({ role_1: role1 });
  };
  const SavingRole2 = () => {
    CallingSaveDraftAPI({ role_2: role2 });
  };
  const SavingRole3 = () => {
    CallingSaveDraftAPI({ role_3: role3 });
  };
  const SavingProductName1 = (isProductName) => {
    CallingSaveDraftAPI({
      productName_1: pName1Ref.current ? pName1Ref.current : firstProductName,
    });
    // isProductName &&
    //   setProductError(
    //     pName1Ref.current && pName1Ref.current != null ? false : true
    //   );
  };
  const SavingProductName2 = () => {
    CallingSaveDraftAPI({
      productName_2: pName2Ref.current ? pName2Ref.current : secondProductName,
    });
  };
  const SavingBusinessOutcome1 = () => {
    CallingSaveDraftAPI({
      businessOutcome_1: BO1Ref.current ? BO1Ref.current : businessOutcome1,
    });
  };
  const SavingBusinessOutcome2 = () => {
    CallingSaveDraftAPI({
      businessOutcome_2: BO2Ref.current ? BO2Ref.current : businessOutcome2,
    });
  };
  const SavingBusinessOutcome3 = () => {
    CallingSaveDraftAPI({
      businessOutcome_3: BO3Ref.current ? BO3Ref.current : businessOutcome3,
    });
  };

  const [Lob1, setLob1] = useState([]);
  const [domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveloading, setsaveLoading] = useState(false);
  const [clearloading, setclearLoading] = useState(false);
  const CallingSaveDraftAPI = ({
    isFromSentRequest = false,
    productName_1 = firstProductName,
    productName_2 = secondProductName,
    businessOutcome_1 = businessOutcome1,
    businessOutcome_2 = businessOutcome2,
    businessOutcome_3 = businessOutcome3,
    businessOutcome_Array = businessOutcomesArray,
    summaryValue = summary,
    industryValue = industry,
    lineOfBusiness = Lob1,
    domainValue = domain,
    video_name = videoName,
    productURL_1 = firstProductUrl,
    productURL_2 = SecondProductUrl,
    demo_1 = demo1,
    demo_2 = Demo2,
    demo_3 = Demo3,
    right_demo_1 = rightDemo1,
    right_demo_2 = rightDemo2,
    right_demo_3 = rightDemo3,
    right_business_outcome_1 = rightBusinessOutcome1,
    right_business_outcome_2 = rightBusinessOutcome2,
    right_business_outcome_3 = rightBusinessOutcome3,
    isAddToScript_Demo1 = isAddToScriptDemo1,
    isAddToScript_Demo2 = isAddToScriptDemo2,
    isAddToScript_Demo3 = isAddToScriptDemo3,
    isaddToScript_Intro = isAddToScriptIntro,
    isaddToScript_Outro = isAddToScriptOutro,
    selectedDemo_1 = extra,
    selectedDemo_2 = extra2,
    selectedDemo_3 = extrasss3 || extra3,
    introValue = intro1Value,
    grabberValue = grabber,
    solutionValue = solution,
    outroValue = outro,
    selected_outro = selectedOutro1,
    stepDraftColorValue = stepDraftColor,
    isClonedDraft = isDisableLineOfBusiness ? 0 : 1,
  }) => {
    setisDisableReq(stepDraftColor == "Draft" ? false : true);
    setIsDisableSaveDataButton(
      stepDraftColor == "Request" ||
        stepDraftColor == "Editing" ||
        stepDraftColor == "Teach Tori"
        ? true
        : false
    );
    setisDisableTeachTori(
      !rightDemo1 ||
        rightDemo1.trim().length === 0 ||
        !grabberValue ||
        grabberValue.trim().length === 0 ||
        !solutionValue ||
        solutionValue.trim().length === 0 ||
        !rightDemo2 ||
        rightDemo2.trim().length === 0 ||
        !outroValue ||
        outroValue.trim().length === 0
        ? true
        : false
    );

    const p1 = pName1Ref.current ? pName1Ref.current : productName_1;
    const p2 = pName2Ref.current ? pName2Ref.current : productName_2;
    const bo1Val = BO1Ref.current ? BO1Ref.current : businessOutcome_1;
    const bo2Val = BO2Ref.current ? BO2Ref.current : businessOutcome_2;
    const bo3Val = BO3Ref.current ? BO3Ref.current : businessOutcome_3;
    const demo_steps = [
      {
        business_outcome: businessOutcome_Array,
        right_business_outcome: right_business_outcome_1,
        demo: demo_1,
        selected_demo: selectedDemo_1,
        is_added_to_script: isAddToScript_Demo1,
        right_demo: right_demo_1,
        sequence: 1,
      },
      {
        business_outcome: [],
        right_business_outcome: right_business_outcome_2,
        demo: "",
        selected_demo: "",
        is_added_to_script: "",
        right_demo: right_demo_2,
        sequence: 2,
      },
      {
        business_outcome: [],
        right_business_outcome: right_business_outcome_3,
        demo: "",
        selected_demo: "",
        is_added_to_script: "",
        right_demo: right_demo_3,
        sequence: 3,
      },
    ];
    setIsSaved(false);
    SaveExaplainerState(
      id,
      p1,
      p2,
      summaryValue,
      industryValue,
      lineOfBusiness,
      domainValue,
      video_name,
      productURL_1,
      productURL_2,
      demo_steps,
      grabber,
      grabberValue,
      solutionValue,
      outroValue,
      introValue,
      selected_outro,
      isaddToScript_Intro,
      isaddToScript_Outro,
      stepDraftColorValue,
      isClonedDraft
    )
      .then((res) => {
        isFromSentRequest && GettingSavedDraft(true);
        setIsAutoSaved(true);
        if (res.status == false) {
          setErrorMessageModal(true);
          setErrorMessage(res.message);
        }
        setIsSaved(true);
        setsaveLoading(false);
        setloading(false);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsAutoSaved(true);
        setIsSaved(true);
        setsaveLoading(false);
        setloading(false);
      });
  };
  const OpenRequestSentModal = () => {
    setapprovalSme(true);
    setIsDisableLineOfBusiness(true);
    setdisableDemo1Fields(true);
    CallingSaveDraftAPI({
      isFromSentRequest: true,
      isClonedDraft: 0,
      stepDraftColorValue: "Request",
    });
  };
  const OpenDraftApprovedModal = () => {
    setapprovalHublead(true);
    GettingSavedDraft(true);
    setIsDisableSaveDataButton(true);
  };
  const HideInfoBar = () => {
    setshowapprovalInfoHublead(false);
  };
  const DraftApprovalRequest = (e) => {
    setloadingReqApp(true);
    // e.currentTarget.disabled = true;
    DHLApproveRequest("Editing", draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setloadingReqApp(false);
          GettingSavedDraft(true);
          setshowapprovalInfoSme(false);
          setapprovalHublead(true);
          setcanApprove(false);
        } else {
          setloading(false);
          setsendReqMsg(res.message);
          setisSendReqMsg(true);
          handleApprovalClose();
          setcanApprove(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloadingReqApp(false);
        setcanApprove(true);
      });
  };
  const CancelApprovalRequest = () => {
    setLoadingcancel(true);
    CancelDHLRequest(draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setstepDraftColor("Draft");
          setdisableDemo1Fields(false);
          setLoadingcancel(false);
          setshowapprovalInfoSme(false);
          setisDisableReq(false);
          setIsDisableSaveDataButton(false);
        } else {
          setCancelDHLRequestMsg(res.message);
          setisCancelDHLRequest(true);
          setLoadingcancel(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoadingcancel(false);
      });
  };
  const MoveToToriTrained = () => {
    setloadingTori(true);
    TeachTori(draftStateData && draftStateData.id)
      .then((res) => {
        if (res.status) {
          setstepDraftColor("Teach Tori");
          setisDisableReq(true);
          setisDisableTeachTori(true);
          setdisableAllinputs(true);
          setshowapprovalInfoHublead(false);
          setShowPlusBtn(false);
          setShowMinusBtn(false);
          setdisableDemo1Fields(true);
          setteachTorimodal(true);
          setteachTorimodalMsg(res.message);
          setloadingTori(false);
        } else {
          setloadingTori(false);
          setteachTorimodal(true);
          setteachTorimodalMsg(res.message);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloadingTori(false);
      });
  };
  const OnSelectLineOfBusiness = (e) => {
    var val = e;
    val.map((a) => {
      multiArr.push(a.label);
    });
    setLob1(multiArr);
    setLineOfBusinessError(multiArr && multiArr.length != 0 ? false : true);
    CallingSaveDraftAPI({ lineOfBusiness: multiArr });
  };
  const OnSelectDomain = (e) => {
    setDomain(e.label);
    CallingSaveDraftAPI({ domainValue: e.label });
  };
  const OnSelectIndustry = (e) => {
    setIndustry(e.label);
    CallingSaveDraftAPI({ industryValue: e.label });
    setIndustryError(e.label ? false : true);
  };

  const [draftStateData, setDraftStatedata] = useState({});
  const [showDemo2State, setShowDemo2State] = useState(false);
  const [SaveDrftStateTwo, setSaveDrftStateTwo] = useState(false);
  const [SaveDrftStateThree, setSaveDrftStateThree] = useState(false);
  const [SaveDrftStateButtonOne, setSaveDrftStateButtonOne] = useState(true);
  const [showDemo2Buttons, setShowDemo2Buttons] = useState(true);
  const [SaveDrftStateButtonThree, setSaveDrftStateButtonThree] =
    useState(true);
  const [lineOfBusiness, setLineOfBusiness] = useState([]);
  const [ApiKey, setApiKey] = useState("");
  const [demoModelName, setdemoModelName] = useState("");
  const [introModelName, setintroModelName] = useState("");
  const [outroModelName, setoutroModelName] = useState("");
  const [canClear, setCanClear] = useState(false);
  const [showClear, setShowclear] = useState(false);
  const [showClearMsg, setShowclearMsg] = useState("");
  const [selectedDemo1, setselectedDemo1] = useState("");
  const [selectedDemo2, setselectedDemo2] = useState("");
  const [selectedDemo3, setselectedDemo3] = useState("");
  const [selectedIntro, setselectedIntro] = useState("");
  const [selectedOutro, setselectedOutro] = useState("");
  const [selectedOutro1, setselectedOutro1] = useState("");
  const [StateOne, setStateOne] = useState(false);
  const [StateTwo, setStateTwo] = useState(false);
  const [StateThree, setStateThree] = useState(false);
  const [StateFour, setStateFour] = useState(false);
  const [StateFive, setStateFive] = useState(false);
  const [StateSix, setStateSix] = useState(false);
  const [firstProductName, setFirstProductName] = useState("");
  const [secondProductName, setSecondProductName] = useState("");
  const [firstProductUrl, setFirstProductUrl] = useState("");
  const [SecondProductUrl, setSecondProductUrl] = useState("");
  const [summary, setSummary] = useState("");
  const [industry, setIndustry] = useState();
  const [role1, setRole1] = useState("");
  const [businessOutcome1, setBusinessOutcome1] = useState("");
  const [rightBusinessOutcome1, setRightBusinessOutcome1] = useState("");
  const [rightBusinessOutcome2, setRightBusinessOutcome2] = useState("");
  const [rightBusinessOutcome3, setRightBusinessOutcome3] = useState("");
  const [businessOutcome2, setBusinessOutcome2] = useState("");
  const [businessOutcome3, setBusinessOutcome3] = useState("");
  const [videoName, setVideoName] = useState("");
  const [demo1, setDemo1] = useState("");
  const [rightDemo1, setRightDemo1] = useState("");
  const [rightDemo2, setRightDemo2] = useState("");
  const [rightDemo3, setRightDemo3] = useState("");
  const [isShowDemo3RightFields, setisShowDemo3RightFields] = useState(false);
  const [Demo1Two, setDemo1Two] = useState("");
  const [Demo1Three, setDemo1Three] = useState("");
  const [Demo2, setDemo2] = useState("");
  const [Demo2Two, setDemo2Two] = useState("");
  const [Demo2Three, setDemo2Three] = useState("");
  const [Demo3, setDemo3] = useState("");
  const [Demo3Two, setDemo3Two] = useState("");
  const [Demo3Three, setDemo3Three] = useState("");
  const [Demo4, setDemo4] = useState("");
  const [Demo4Two, setDemo4Two] = useState("");
  const [Demo4Three, setDemo4Three] = useState("");
  const [demo1Option1Value, setDemo1Option1Value] = useState("");
  const [demo1Option2Value, setDemo1Option2Value] = useState("");
  const [demo2Options1Value, setDemo2Options1Value] = useState("");
  const [demo2Options2Value, setDemo2Options2Value] = useState("");
  const [demo3Options1Value, setDemo3Options1Value] = useState("");
  const [demo3Options2Value, setDemo3Options2Value] = useState("");
  const [loading, setloading] = useState(false);
  const [disabledInputs, setdisabledInputs] = useState(false);
  const [disabledInputsOnNext, setdisabledInputsOnNext] = useState(false);
  const [disabledInputsd2, setdisabledInputsd2] = useState(false);
  const [disabledInputsd3, setdisabledInputsd3] = useState(false);
  const [disabledInputsd4, setdisabledInputsd4] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [isChecked2, setisChecked2] = useState(false);
  const [isChecked3, setisChecked3] = useState(false);
  const [isChecked4, setisChecked4] = useState(false);
  const [isChecked5, setisChecked5] = useState(false);
  const [isChecked6, setisChecked6] = useState(false);
  const [isChecked7, setisChecked7] = useState(false);
  const [isChecked8, setisChecked8] = useState(false);
  const [isChecked9, setisChecked9] = useState(false);
  const [isChecked10, setisChecked10] = useState(false);
  const [extra, setextra] = useState("");
  const [extra2, setextra2] = useState("");
  const [extra3, setextra3] = useState("");
  const [extrasss3, setextrasss3] = useState("");
  const [grabber, setGrabber] = useState("");
  const [solution, setSolution] = useState("");
  const [outro, setOutro] = useState("");
  const [lastLoading, setlastLoading] = useState(false);
  const [outroLoading, setoutroLoading] = useState(false);
  const [outroState1, setoutroState1] = useState("");
  const [outroState2, setoutroState2] = useState("");
  const [ShowProductNext, setShowProductNext] = useState(false);
  const [showSecondBO, setshowSecondBO] = useState(false);
  const [showThirdBO, setshowThirdBO] = useState(false);
  const [plusIcoState, setplusIcoState] = useState(true);
  const [demo3dis, setdemo3dis] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const finalSelectedOutro = React.useRef(outro);
  const [isAddToScriptDemo1, setisAddToScriptDemo1] = useState(false);
  const [isAddToScriptDemo2, setisAddToScriptDemo2] = useState(false);
  const [isAddToScriptDemo3, setisAddToScriptDemo3] = useState(false);
  const [isAddToScriptIntro, setisAddToScriptIntro] = useState(false);
  const [isAddToScriptOutro, setisAddToScriptOutro] = useState(false);
  const pName1Ref = React.useRef(firstProductName);
  const pName2Ref = React.useRef(secondProductName);
  const BO1Ref = React.useRef(businessOutcome1);
  const BO2Ref = React.useRef(businessOutcome2);
  const BO3Ref = React.useRef(businessOutcome3);

  const GettingModelEndpoints = () => {
    GetAPIkey().then((res) => {
      if (res.status) {
        setApiKey(res.data.key);
        setdemoModelName(res.data.spotlight_models[0].endpoint);
        setintroModelName(res.data.spotlight_models[1].endpoint);
        setoutroModelName(res.data.spotlight_models[2].endpoint);
      }
    });
  };
  const GettingSavedDraft = (approve) => {
    const islod = approve ? approve : false;
    if (!islod) {
      setIsLoading(true);
    }
    GetSavedDraftDetails(id)
      .then((res) => {
        if (res.status) {
          let newArrLo = [];
          res.data.line_of_business.map((er, i) => {
            newArrLo.push({ value: i, label: er });
          });
          setLineOfBusiness(newArrLo);
          setIsDisableLineOfBusiness(res.data.just_cloned == 1 ? false : true);
          setisDisableReq(res.data.draft_state == "Draft" ? false : true);
          setisDisableReqOnEditing(res.data.draft_state == "Editing" ? false : true);
          setUserIdDraftOwner(res?.data?.user_id)
          setIsDisableSaveDataButton(
            res.data.draft_state == "Request" ||
              res.data.draft_state == "Editing" ||
              res.data.draft_state == "Teach Tori"
              ? true
              : false
          );
          setisDisableTeachTori(
            !res.data.demo_steps[0].right_demo ||
              !res.data.demo_steps[1].right_demo ||
              !res.data.intro_solution ||
              !res.data.intro ||
              !res.data.outro
              ? true
              : false
          );
          if (res.data.demo_steps[0].is_added_to_script === 1) {
            setShowPlusBtn(false);
            setShowMinusBtn(false);
            setShowDemo2State(true);
            setdisableDemo1Fields(true);
            setdisableDemo1RightFields(false);
            setSaveDrftStateButtonOne(false);
            setRegenerateDemoScript(false);
          }
          setShowPlusBtn(res.data.draft_state == "Teach Tori" ? false : true);
          setShowMinusBtn(res.data.draft_state == "Teach Tori" ? false : true);
          setdisableDemo1Fields(
            res.data.draft_state == "Teach Tori" ||
              res.data.draft_state == "Request" ||
              res.data.draft_state == "Editing"
              ? true
              : false
          );
          if (res.data.demo_steps[1].is_added_to_script === 1) {
            setSaveDrftStateTwo(true);
            setdisableDemo2Fields(true);
            setdisableDemo2RightFields(false);
            setShowDemo2Buttons(false);
            setGenerateDemoScript(false);
          }
          if (
            res.data.demo_steps[1].is_added_to_script === 1 &&
            (res.data.demo_steps[1].business_outcome ||
              res.data.demo_steps[1].role ||
              res.data.demo_steps[1].demo)
          ) {
          }
          if (res.data.demo_steps[2].is_added_to_script === 1) {
            setSaveDrftStateTwo(true);
            setSaveDrftStateThree(true);
            setdisableDemo3Fields(true);
            setdisableDemo3RightFields(false);
            setSaveDrftStateButtonThree(false);
            setisShowDemo3RightFields(true);
          }
          if (
            !res.data.demo_steps[2].business_outcome &&
            !res.data.demo_steps[2].role &&
            !res.data.demo_steps[2].demo &&
            res.data.is_added_to_script_intro === 1
          ) {
            setSaveDrftStateTwo(false);
          }
          if (res.data.second_product_name != null) {
            setShowProductNext(true);
            setplusIcoState(false);
          }
          if (res.data.is_added_to_script_outro === 1) {
            setdisableOutroField(false);
            setselectedOutro1(res.data.selected_outro);
            res.data.outro ? setoutNon1(false) : setoutNon1(true);
          }
          if (
            res.data.is_added_to_script_intro === 0 &&
            res.data.demo_steps[2].is_added_to_script === 1
          ) {
            setSelectionDemoGenerate(true);
          }
          if (
            res.data.is_added_to_script_intro === 1 &&
            !res.data.intro_solution &&
            !res.data.intro
          ) {
            setSelectionDemoGenerate(true);
            setoutNon1(false);
          }
          if (res.data.is_added_to_script_intro === 1) {
            setdisableGrabberAndSolution(false);
            setSaveDrftStateButtonThree(false);
            setdisableGrabberAndSolution(false);
            setdisableDemo3Fields(true);
          }
          if (
            res.data.is_added_to_script_outro === 0 &&
            res.data.is_added_to_script_intro === 1 &&
            (res.data.intro_solution || res.data.intro)
          ) {
            setoutNon1(true);
            setoutNon2(false);
          }
          if (res.data.draft_state == "Request") {
            setshowapprovalInfoSme(true);
          }
          if (res.data.draft_state == "Editing") {
            setshowapprovalInfoHublead(true);
          }
          if (
            res.data.draft_state == "Teach Tori" ||
            res.data.draft_state == "Trained"
          ) {
            setdisableAllinputs(true);
            setisDisableReq(true);
            setisDisableTeachTori(true);
          }
          if (res.data.second_product_name) {
            setShowPlusBtn(false);
          }
          if (res.data.outro) {
            setSaveDrftStateButtonThree(false);
            setdisableOutroField(false);
            setdisableDemo3Fields(true);
          }
          setisAddToScriptDemo1(
            res.data.demo_steps[0].is_added_to_script == 0 ? false : true
          );
          setisAddToScriptDemo2(
            res.data.demo_steps[1].is_added_to_script == 0 ? false : true
          );
          setisAddToScriptDemo3(
            res.data.demo_steps[2].is_added_to_script == 0 ? false : true
          );
          setisAddToScriptIntro(
            res.data.is_added_to_script_intro == 0 ? false : true
          );
          setisAddToScriptOutro(
            res.data.is_added_to_script_outro == 0 ? false : true
          );
          setDraftStatedata(res.data);
          setdraftSharedWith(res.data.share_user_names);
          setdraftCreatedBy(res.data.created_by);
          setstepDraftColor(res.data.draft_state);
          setcanApprove(res.data.approve_button);
          setVideoName(res.data.video_name);
          setFirstProductUrl(res.data.first_product_url);
          setSecondProductUrl(res.data.second_product_url);
          // setBusinessOutcome1(res.data.demo_steps[0].business_outcome[0]);
          setFirstProductName(res.data.first_product_name);
          setSecondProductName(res.data.second_product_name);
          setSummary(res.data.description);
          setRole1(res.data.demo_steps[0].role);
          setDemo1(res.data.demo_steps[0].demo);
          setDemo2(res.data.demo_steps[1].demo);
          setDemo3(res.data.demo_steps[2].demo);
          // setBusinessOutcome2(res.data.demo_steps[0].business_outcome[0]);
          setextra(res.data.demo_steps[0].selected_demo);
          setextra2(res.data.demo_steps[1].selected_demo);
          setextra3(res.data.demo_steps[2].selected_demo);
          setextrasss3(res.data.demo_steps[2].selected_demo);
          setGrabber(res.data.intro);
          setOutro(res.data.outro);
          setRole2(res.data.demo_steps[1].role);
          setSolution(res.data.intro_solution);
          // setBusinessOutcome3(res.data.demo_steps[0].business_outcome[2]);
          setRole3(res.data.demo_steps[2].role);
          setIndustry(res.data.industry);
          setLob1(res.data.line_of_business);
          setDomain(res.data.domain);
          console.log(
            "business_outcome::::>",
            res.data.demo_steps[0].business_outcome
          );
          setBusinessOutcomesArray(res.data.demo_steps[0].business_outcome);
          setRightBusinessOutcome1(
            res.data.demo_steps[0].right_business_outcome
          );
          setintro1Value(res.data.intro_model_response);
          setRightDemo1(res.data.demo_steps[0].right_demo);
          setRightBusinessOutcome2(
            res.data.demo_steps[1].right_business_outcome
          );
          setRightDemo2(res.data.demo_steps[1].right_demo);
          setRightBusinessOutcome3(
            res.data.demo_steps[2].right_business_outcome
          );
          console.log(res.data.demo_steps[0].business_outcome);
          setRightDemo3(res.data.demo_steps[2].right_demo);
          setHubleadNames(res.data.hublead_name);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsLoading(false);
      });
  };
  const ClearAllDraft = () => {
    setclearLoading(true);
    ClearDraft(draftStateData && draftStateData.id)
      .then((res) => {
        setCanClear(res.status);
        if (res.status) {
          setclearLoading(false);
          setClearAllModal(false);
          window.location.reload(false);
        } else {
          setclearLoading(false);
          setClearAllModal(false);
          // setShowclear(true);
          // setShowclearMsg(res.message);
          setErrorMessageModal(true);
          setErrorMessage(res.message);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setclearLoading(false);
        setClearAllModal(false);
      });
  };
  const [businessOutcomesArray, setBusinessOutcomesArray] = useState([
    { name: "" },
  ]);
  // handle input change
  const OnChangeBusinessOutcomeArray = (e, name, index) => {
    const list = [...businessOutcomesArray];
    list[index][name] = e;
    setBusinessOutcomesArray(list);
    CallingSaveDraftAPI({ businessOutcome_Array: list });
  };

  // handle click event of the Remove button
  const HandleRemoveBO = (index) => {
    const list = [...businessOutcomesArray];
    list.splice(index, 1);
    setBusinessOutcomesArray(list);
    CallingSaveDraftAPI({ businessOutcome_Array: list });
  };

  // handle click event of the Add button
  const HandleAddBO = () => {
    setBusinessOutcomesArray([...businessOutcomesArray, { name: "" }]);
    CallingSaveDraftAPI({
      businessOutcome_Array: [...businessOutcomesArray, { name: "" }],
    });
  };
  const [SBO4, setSBO4] = useState("");
  const [Fintro1, setFintro1] = useState("");
  const [Fintro2, setFintro2] = useState("");
  const [role2, setRole2] = useState("");
  const [role3, setRole3] = useState("");
  const [Floading, setFloading] = useState(false);
  const [isRegenIntroLoading, setisRegenIntroLoading] = useState(false);
  const [RegenerateDemoScript, setRegenerateDemoScript] = useState(true);
  const [isShowAddToScriptDemo1Button, setIsShowAddToScriptDemo1Button] =
    useState(false);
  const [next2None, setnext2None] = useState(false);
  const [next3None, setnext3None] = useState(false);
  const [isShowIntroAddToScript, setisShowIntroAddToScript] = useState(false);
  const [isShowOutroAddToScript, setisShowOutroAddToScript] = useState(false);
  const [SelectionDemoGenerate, setSelectionDemoGenerate] = useState(false);
  const [next3None111, setnext3None111] = useState(false);
  const [outNon1, setoutNon1] = useState(false);
  const [section1dis, setsection1dis] = useState(true);
  const [outNon2, setoutNon2] = useState(false);
  const [finishClick, setfinishClick] = useState(true);
  const [isFinishClickDemo2, setIsFinishClickDemo2] = useState(true);
  const [isShowDemo1Options, setIsShowDemo1Options] = useState(false);
  const [isGenerateDemoScript, setGenerateDemoScript] = useState(true);
  const [showDemo2Options, setShowDemo2Options] = useState(false);
  const [isGenerateDemoScript1, setGenerateDemoScript1] = useState(true);
  const [dis76546_1, setdis76546_1] = useState(true);
  const [dis0988_11, setdis0988_11] = useState(false);
  const [lastDivNone, setlastDivNone] = useState(false);
  const [DEMO4OP, setDEMO4OP] = useState(false);
  const [reGenIntro, setreGenIntro] = useState(false);
  const [FOROutro, setFOROutro] = useState(false);
  const [wordFileData, setwordFileData] = useState();
  const [regenerateDemo, setregenerateDemo] = useState(true);
  const [ShowPlusBtn, setShowPlusBtn] = useState(true);
  const [ShowMinusBtn, setShowMinusBtn] = useState(true);
  const [draftSharedWith, setdraftSharedWith] = useState();
  console.log(
    "🚀 ~ file: ExplainerVideo.js:924 ~ ExplainerVideo ~ draftSharedWith:",
    draftSharedWith
  );
  const [previousPath, setPreviousPath] = useState("unknown");
  useEffect(() => {
    GetLineOfBusinessData().then((res) => {
      if (res.status) {
        setLineOfBusinessData(res.data);
      }
    });
    GetIndustriesData().then((res) => {
      if (res.status) {
        setIndustryData(res.data);
      }
    });
    GetDomainsData().then((res) => {
      if (res.status) {
        setDomainsData(res.data);
      }
    });

    getBusinessOutcomesList().then((res) => {
      if (res.status) {
        setboDropdownData(res.data);
      }
    });
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("userData", JSON.stringify(res.data));
        // setuserData(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("ParemId")) {
      sessionStorage.removeItem("ParemId");
    } else {
      sessionStorage.setItem("ParemId", pathname);
    }
    if (history.location.state == undefined) {
      setPreviousPath("unknown");
    } else {
      setPreviousPath(history.location.state && history.location.state.from);
    }

    // GettingModelEndpoints();
    GettingSavedDraft(false);
    scrollToTop();
  }, []);
  useEffect(() => {
    // $("form").submit(function () {
    //   var radioValue = $("input[name='options']:checked").val();
    //   if (radioValue) {
    //     setmodal5(true);
    //     setModalMsg5("You selected - " + radioValue);
    //   }
    //   return false;
    // });

    autosize();
    function autosize() {
      var text = $(".autosize");

      text.each(function () {
        $(this).attr("rows", 1);
        resize($(this));
      });

      text.on("input", function () {
        resize($(this));
      });

      function resize($text) {
        $text.css("height", "auto");
        $text.css("height", $text[0].scrollHeight + "px");
      }
    }
  });

  const CloseDraft = () => {
    if (previousPath == "startdraft" || previousPath == "drafttable") {
      history.goBack();
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    } else if (previousPath == "login") {
      history.push("/user/drafts");
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    } else {
      history.push("/user/drafts");
      // stepDraftColor !== "Teach Tori" && CallingSaveDraftAPI({});
    }
    sessionStorage.removeItem("ParemId");
  };
  const OnChangeRightDemo1 = (e) => {
    setRightDemo1(e.target.value);
    setStateOne(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRightDemo2 = (e) => {
    setRightDemo2(e.target.value);
    setStateTwo(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRightDemo3 = (e) => {
    setRightDemo3(e.target.value);
    setStateThree(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_demo_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeGrabber = (e) => {
    setGrabber(e.target.value);
    setStateFive(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ grabberValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeSolution = (e) => {
    setSolution(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ solutionValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeOutro = (e) => {
    setOutro(e.target.value);
    setStateSix(true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ outroValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRightBusinessOutcome1 = (e) => {
    setRightBusinessOutcome1(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_business_outcome_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRightBusinessOutcome2 = (e) => {
    setRightBusinessOutcome2(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_business_outcome_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRightBusinessOutcome3 = (e) => {
    setRightBusinessOutcome3(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ right_business_outcome_3: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeProductName1 = (e) => {
    pName1Ref.current = e.target.value;
    setFirstProductName(e.target.value);
    // setProductError(pName1Ref.current.length != 0 ? false : true);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productName_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeProductName2 = (e) => {
    pName2Ref.current = e.target.value;
    setSecondProductName(e.target.value);
    setDraftStatedata({
      ...draftStateData,
      second_product_name: e.target.value,
    });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productName_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeFirstProductUrl = (e) => {
    setFirstProductUrl(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productURL_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeSecondProductUrl = (e) => {
    setSecondProductUrl(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ productURL_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnSelectProductName1 = (productName) => {
    pName1Ref.current = productName;
    setFirstProductName(productName);
    // setProductError(productName.length != 0 ? false : true);
    CallingSaveDraftAPI({});
  };
  const OnSelectProductName2 = (productName) => {
    pName2Ref.current = productName;
    setSecondProductName(productName);
    CallingSaveDraftAPI({});
  };
  const OnSelectBusinessOutcome1 = (val) => {
    BO1Ref.current = val;
    setBusinessOutcome1(val);
    CallingSaveDraftAPI({});
  };
  const OnSelectBusinessOutcome2 = (val) => {
    BO2Ref.current = val;
    setBusinessOutcome2(val);
    CallingSaveDraftAPI({});
  };
  const OnSelectBusinessOutcome3 = (val) => {
    BO3Ref.current = val;
    setBusinessOutcome3(val);
    CallingSaveDraftAPI({});
  };
  const OnChangeSummary = (e) => {
    setSummary(e.target.value);
    setSummaryError(
      e.target.value && e.target.value.length != 0 ? false : true
    );
    setDraftStatedata({ ...draftStateData, description: e.target.value });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ summaryValue: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeRole1 = (e) => {
    setRole1(e.target.value);
    setDraftStatedata({ ...draftStateData, role1: e.target.value });
  };
  const f = (e) => {
    BO1Ref.current = e.target.value;
    setBusinessOutcome1(e.target.value);
    setDraftStatedata({
      ...draftStateData,
      business_outcomes: e.label,
    });
  };
  const OnChangeVideoName = (e) => {
    setVideoName(e.target.value);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ video_name: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeDemo1Option1 = (e) => {
    setIsShowAddToScriptDemo1Button(true);
    setisChecked(true);
    setisChecked2(false);
    CallingSaveDraftAPI({});
    setselectedDemo1("A1");
    scrollToBottom();
  };
  const OnChangeDemo1Option2 = (e) => {
    setIsShowAddToScriptDemo1Button(true);
    setisChecked(false);
    setisChecked2(true);
    CallingSaveDraftAPI({});

    setselectedDemo1("A2");
    scrollToBottom();
  };
  const OnChangeDemo2Option1 = (e) => {
    setnext2None(true);
    setisChecked3(true);
    setisChecked4(false);
    setselectedDemo2("B1");
    scrollToBottom();
    CallingSaveDraftAPI({});
  };
  const OnChangeDemo2Option2 = (e) => {
    setnext2None(true);
    setisChecked3(false);
    setisChecked4(true);
    setselectedDemo2("B2");
    scrollToBottom();
    CallingSaveDraftAPI({});
  };
  const OnChangeDemo3Option1 = (e) => {
    setnext3None(true);
    setisChecked5(true);
    setisChecked6(false);
    setselectedDemo3("C1");
    scrollToBottom();
  };
  const OnChangeDemo3Option2 = (e) => {
    setnext3None(true);
    setisChecked5(false);
    setisChecked6(true);
    setselectedDemo3("C2");
    scrollToBottom();
    CallingSaveDraftAPI({});
  };
  const OnChangeIntroOption1 = (e) => {
    setisChecked7(true);
    setisChecked8(false);
    setisShowIntroAddToScript(true);
    setselectedIntro("AAA");
    setnext3None(false);
    let a = document.getElementById("AAA").value;
    setintro1Value(a);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const OnChangeIntroOptions2 = (e) => {
    setisChecked7(false);
    setisChecked8(true);
    setselectedIntro("BBB");
    setnext3None(false);
    setisShowIntroAddToScript(true);
    let a = document.getElementById("BBB").value;
    setintro1Value(a);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const OnChangeOutroOption1 = (e) => {
    setselectedOutro("CCC");
    setisChecked9(true);
    setisChecked10(false);
    setisShowOutroAddToScript(true);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const OnChangeOutroOption2 = (e) => {
    setselectedOutro("DDD");
    setisChecked9(false);
    setisChecked10(true);
    setisShowOutroAddToScript(true);
    CallingSaveDraftAPI({});
    scrollToBottom();
  };
  const GoBackToDemo1 = () => {
    setdisableDemo1Fields(false);
    setdisabledInputsOnNext(false);
    setdisabledInputs(false);
    setisChecked(false);
    setisChecked2(false);
    setShowDemo2State(false);
    setShowDemo1RegenerateButton(true);
    setsection1dis(true);
    setSaveDrftStateButtonOne(true);
    setBusinessOutcome2("");
    BO2Ref.current = "";
    setRole2("");
    setDemo2("");
    CallingSaveDraftAPI({
      businessOutcome_2: "",
      role_2: "",
      demo_2: "",
      isAddToScript_Demo1: true,
    });
  };
  const GoBackToDemo2 = () => {
    setdisableDemo2Fields(false);
    setdisabledInputsd2(false);
    setisChecked3(false);
    setisChecked4(false);
    setSaveDrftStateTwo(false);
    setIsFinishClickDemo2(true);
    setShowDemo2Buttons(true);
    setBusinessOutcome3("");
    BO3Ref.current = "";
    setRole3("");
    setDemo3("");
    CallingSaveDraftAPI({
      businessOutcome_3: "",
      role_3: "",
      demo_3: "",
      isAddToScript_Demo2: true,
    });
  };
  const bottomRef = useRef();
  const [demo1Error, setDemo1Error] = useState(false);
  const [product1Error, setProductError] = useState(false);
  const [demo2Error, setDemo2Error] = useState(false);
  const [demo3Error, setDemo3Error] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [lineOfBusinessError, setLineOfBusinessError] = useState(false);
  const [industryError, setIndustryError] = useState(false);

  const GenerateFirstDemo = () => {
    if (
      !firstProductName ||
      (firstProductName && firstProductName.trim().length === 0) ||
      !summary ||
      (summary && summary.trim().length === 0) ||
      !industry ||
      !(Lob1.length > 0) ||
      !demo1 ||
      (demo1 && demo1.trim().length === 0)
    ) {
      setProductError(false);
      setSummaryError(false);
      setDemo1Error(false);
      setLineOfBusinessError(false);
      setIndustryError(false);
      if (
        !firstProductName ||
        (firstProductName && firstProductName.trim().length === 0)
      ) {
        setProductError(true);
      }
      if (!summary || (summary && summary.trim().length === 0)) {
        setSummaryError(true);
      }
      if (!industry) {
        setIndustryError(true);
      }
      if (!(Lob1.length > 0)) {
        setLineOfBusinessError(true);
      }
      if (!demo1 || (demo1 && demo1.trim().length === 0)) {
        setDemo1Error(true);
      }
    } else {
      setDemo1Error(false);
      setProductError(false);
      setSummaryError(false);
      setLineOfBusinessError(false);
      setIndustryError(false);
      setloading(true);
      CallingSaveDraftAPI({
        stepDraftColorValue: "Draft",
        right_business_outcome_1:
          businessOutcomesArray[0] && businessOutcomesArray[0].name,
        right_business_outcome_2:
          businessOutcomesArray[1] && businessOutcomesArray[1].name,
        right_business_outcome_3:
          businessOutcomesArray[2] && businessOutcomesArray[2].name,
      });
      setstepDraftColor("Draft");
      setisDisableReq(false);
      // const demoSplit = demo1 && demo1.split("\n");
      // setDisAbleDemoKeyPoint(true);
      // setloading(true);
      // setProductError(false);
      // setDemo1Error(false);
      // setSummaryError(false);
      // setLineOfBusinessError(false);
      // setIndustryError(false);
      // CallingSaveDraftAPI({});
      // setRegenerateDemoScript(false);
      // setShowDemo1RegenerateButton(true);
      // scriptOne(
      //   firstProductName,
      //   secondProductName,
      //   summary,
      //   industry,
      //   role1,
      //   demoSplit,
      //   Lob1,
      //   demoModelName
      // )
      //   .then(async (result) => {
      //     setDisAbleDemoKeyPoint(false);
      //     if (result.hasOwnProperty("error")) {
      //       setErrorMessageModal(true);
      //       setErrorMessage(result.error.message);
      //       setloading(false);
      //     }

      //     let data = result.choices;
      //     if (data.length == 0) {
      //       setloading(false);
      //     }
      //     setDemo1Option1Value(data[0]["text"]);
      //     if (data.length == 2) {
      //       setDemo1Option2Value(data[1]["text"]);
      //     }
      //     setloading(false);
      //     scrollToBottom();
      //     setIsShowDemo1Options(true);
      //   })
      //   .catch((error) => {
      //     if (error.name === "AbortError") {
      //       setshowTimeOurError(true);
      //     }
      //     setloading(false);
      //   });
      // setdisabledInputs(true);
    }
  };
  const GenerateSecondDemo = () => {
    if (!Demo2 || Demo2.trim().length === 0) {
      setDemo2Error(true);
    } else {
      RegenerateSecondDemo();
      setDemo2Error(false);
    }
  };
  const GenerateThirdDemo = () => {
    if (!Demo3 || Demo3.trim().length === 0) {
      setDemo3Error(true);
    } else {
      RegenerateThirdDemo();
      setDemo3Error(false);
    }
  };
  const RegenerateFirstDemo = () => {
    if (
      !firstProductName ||
      (firstProductName && firstProductName.trim().length === 0) ||
      !summary ||
      (summary && summary.trim().length === 0) ||
      !industry ||
      !(Lob1.length > 0) ||
      !demo1 ||
      (demo1 && demo1.trim().length === 0)
    ) {
      setDemo1Error(false);
      setProductError(false);
      setSummaryError(false);
      setLineOfBusinessError(false);
      setIndustryError(false);
      if (
        !firstProductName ||
        (firstProductName && firstProductName.trim().length === 0)
      ) {
        setProductError(true);
      }
      if (!summary || (summary && summary.trim().length === 0)) {
        setSummaryError(true);
      }
      if (!industry) {
        setIndustryError(true);
      }
      if (!(Lob1.length > 0)) {
        setLineOfBusinessError(true);
      }
      if (!demo1 || (demo1 && demo1.trim().length === 0)) {
        setDemo1Error(true);
      }
    } else {
      const demoSplit = demo1 && demo1.split("\n");
      setProductError(false);
      setDemo1Error(false);

      setSummaryError(false);
      setLineOfBusinessError(false);
      setIndustryError(false);
      setDisAbleDemoKeyPoint(true);
      setisChecked(false);
      setisChecked2(false);
      CallingSaveDraftAPI({});
      setRegenerateDemoScript(false);
      setloading(true);
      setIsShowAddToScriptDemo1Button(false);
      scriptOne(
        firstProductName,
        secondProductName,
        summary,
        industry,
        role1,
        demoSplit,
        Lob1,
        demoModelName
      )
        .then(async (result) => {
          setDisAbleDemoKeyPoint(false);
          if (result.hasOwnProperty("error")) {
            setErrorMessageModal(true);
            setErrorMessage(result.error.message);
            setloading(false);
          }
          let data = result.choices;
          if (data.length == 0) {
            setloading(false);
          }
          setDemo1Option1Value(data[0]["text"]);
          if (data.length == 2) {
            setDemo1Option2Value(data[1]["text"]);
          }
          setloading(false);
          scrollToBottom();

          setIsShowDemo1Options(true);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
      setdisabledInputs(true);
    }
  };
  const RegenerateSecondDemo = () => {
    if (!Demo2 || Demo2.trim().length === 0) {
      setDemo2Error(true);
    } else {
      const demoSplit = Demo2 && Demo2.split("\n");
      setDemo2Error(false);
      setDisAbleDemoKeyPoint(true);
      setnext2None(false);
      setisChecked3(false);
      setisChecked4(false);
      CallingSaveDraftAPI({});
      setGenerateDemoScript(false);
      setloading(true);
      scriptTwo(
        firstProductName,
        secondProductName,
        summary,
        industry,
        role2,
        demoSplit,
        Lob1,
        demoModelName
      )
        .then(async (result) => {
          setDisAbleDemoKeyPoint(false);
          if (result.hasOwnProperty("error")) {
            setErrorMessageModal(true);
            setErrorMessage(result.error.message);
            setloading(false);
          }
          let data = result.choices;
          if (data.length == 0) {
            setloading(false);
          }
          setDemo2Options1Value(data[0]["text"]);
          if (data.length == 2) {
            setDemo2Options2Value(data[1]["text"]);
          }
          setloading(false);
          setShowDemo2Options(true);
          scrollToBottom();
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const RegenerateThirdDemo = () => {
    if (!Demo3 || Demo3.trim().length === 0) {
      setDemo3Error(true);
    } else {
      const demoSplit = Demo3 && Demo3.split("\n");
      setDemo3Error(false);
      setDisAbleDemoKeyPoint(true);
      setnext3None(false);
      setisChecked5(false);
      setisChecked6(false);
      setGenerateDemoScript1(false);
      setloading(true);
      scriptThree(
        firstProductName,
        secondProductName,
        summary,
        industry,
        role3,
        demoSplit,
        Lob1,
        demoModelName
      )
        .then(async (result) => {
          setDisAbleDemoKeyPoint(false);
          if (result.hasOwnProperty("error")) {
            setErrorMessageModal(true);
            setErrorMessage(result.error.message);
            setloading(false);
          }
          let data = result.choices;
          if (data.length == 0) {
            setloading(false);
          }
          setDemo3Options1Value(data[0]["text"]);
          if (data.length == 2) {
            setDemo3Options2Value(data[1]["text"]);
          }
          setloading(false);
          setdemo3dis(true);
          scrollToBottom();
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  const AddToScriptDemo1 = () => {
    setDemo1Error(false);
    setProductError(false);
    setSummaryError(false);
    setLineOfBusinessError(false);
    setIndustryError(false);
    setShowMinusBtn(false);
    setShowPlusBtn(false);
    setextra(document.getElementById(selectedDemo1).value);
    setRightDemo1(document.getElementById(selectedDemo1).value.trim());
    setRightBusinessOutcome1(businessOutcome1);
    setShowDemo1RegenerateButton(false);
    setisAddToScriptDemo1(true);
    CallingSaveDraftAPI({
      right_business_outcome_1: businessOutcome1,
      right_demo_1: document.getElementById(selectedDemo1).value,
      selectedDemo_1: document.getElementById(selectedDemo1).value,
      isAddToScript_Demo1: true,
      stepDraftColorValue: document.getElementById(selectedDemo1).value
        ? "Draft"
        : null,
    });
    setdisabledInputsOnNext(true);
    setsection1dis(false);
    setIsShowDemo1Options(false);
    setShowDemo2State(true);
    setstepDraftColor(
      document.getElementById(selectedDemo1).value ? "Draft" : null
    );
    setdisableDemo1Fields(true);
    setdisableDemo1RightFields(false);
  };
  const AddToScriptDemo2 = () => {
    setDemo2Error(false);
    setextra2(document.getElementById(selectedDemo2).value);
    setRightDemo2(document.getElementById(selectedDemo2).value.trim());
    setRightBusinessOutcome2(businessOutcome2);
    setisAddToScriptDemo2(true);
    CallingSaveDraftAPI({
      right_business_outcome_2: businessOutcome2,
      right_demo_2: document.getElementById(selectedDemo2).value,
      selectedDemo_2: document.getElementById(selectedDemo2).value,
      isAddToScript_Demo2: true,
    });
    setdisabledInputsd2(true);
    setIsFinishClickDemo2(false);
    setShowDemo2Options(false);
    setSaveDrftStateTwo(true);
    setdisableDemo2Fields(true);
    setdisableDemo2RightFields(false);
  };
  const AddToScriptIntro = () => {
    setdisableGrabberAndSolution(false);
    setlastDivNone(false);
    setreGenIntro(false);
    setisAddToScriptIntro(true);
    let a = document.getElementById(selectedIntro).value;
    const count = (a.match(/#Solution#/g) || []).length;
    if (a && a.includes("#Solution#") && a && a.includes("#Grabber#")) {
      var index = a.indexOf("#Solution#");
      var p1 = a.slice(0, index);
      var p2 = a.slice(index);
      setGrabber(p1.replace("#Grabber#", "").trim());
      if (count > 1) {
        var newSolution = p2.split("#Solution#").pop().split("#Solution#")[0];
        setSolution(newSolution.trim());
      } else {
        setSolution(p2.replace("#Solution#", "").trim());
      }
    } else if (a && !a.includes("#Solution#") && a && a.includes("#Grabber#")) {
      var index = a.indexOf("#Grabber#");
      var p2 = a.slice(index);
      setSolution(p2.replace("#Grabber#", "").trim());
    } else if (a && a.includes("#Solution#") && a && !a.includes("#Grabber#")) {
      var index = a.indexOf("#Solution#");
      var p2 = a.slice(index);
      setSolution(p2.replace("#Solution#", "").trim());
    } else {
      setSolution(a.trim());
      if (a && a.includes("#Grabber#")) {
        var p2 = a.replace("#Grabber#", "").trim();
      } else {
        var p2 = a.trim();
      }
    }
    setoutNon1(!outro ? true : false);
    CallingSaveDraftAPI({
      introValue: intro1Value,
      grabberValue: p1 && p1.replace("#Grabber#", "").trim(),
      solutionValue:
        p2 && newSolution ? newSolution : p2.replace("#Solution#", "").trim(),
      isaddToScript_Intro: true,
    });
  };
  const AddToScriptOutro = () => {
    setdisableOutroField(false);
    setFOROutro(false);
    setoutNon2(false);
    let a = document.getElementById(selectedOutro).value;
    finalSelectedOutro.current = a;
    setOutro(a);
    setselectedOutro1(a);
    setisAddToScriptOutro(true);
    CallingSaveDraftAPI({
      outroValue: a,
      selected_outro: a,
      isaddToScript_Outro: true,
    });
    setdisableDemo3Fields(true);
    scrollToTop();
  };
  const onChangeDemo1TextBox1 = (e) => {
    setDemo1Option1Value(e.target.value);
  };
  const onChangeDemo1TextBox2 = (e) => {
    setDemo1Option2Value(e.target.value);
  };
  const onChangeDemo2TextBox1 = (e) => {
    setDemo2Options1Value(e.target.value);
  };
  const onChangeDemo2TextBox2 = (e) => {
    setDemo2Options2Value(e.target.value);
  };
  const onChangeDemo3TextBox1 = (e) => {
    setDemo3Options1Value(e.target.value);
  };
  const onChangeDemo3TextBox2 = (e) => {
    setDemo3Options2Value(e.target.value);
  };
  const OnChangeBusinessOutcome2 = (e) => {
    BO2Ref.current = e.target.value;
    setBusinessOutcome2(e.target.value);
  };
  const OnChangeBusinessOutcome3 = (e) => {
    BO3Ref.current = e.target.value;
    setBusinessOutcome3(e.target.value);
  };
  const OnChangeRole2 = (e) => {
    setRole2(e.target.value);
  };
  const OnChangeRole3 = (e) => {
    setRole3(e.target.value);
  };
  const AddToScriptDemo3 = () => {
    setDemo3Error(false);
    setisShowDemo3RightFields(true);
    setextrasss3(document.getElementById(selectedDemo3).value);
    setRightBusinessOutcome3(businessOutcome3);
    setRightDemo3(document.getElementById(selectedDemo3).value.trim());
    setdemo3dis(false);
    setregenerateDemo(false);
    setnext3None(false);
    setisAddToScriptDemo3(true);
    CallingSaveDraftAPI({
      right_business_outcome_3: businessOutcome3,
      right_demo_3: document.getElementById(selectedDemo3).value,
      selectedDemo_3: document.getElementById(selectedDemo3).value,
      isAddToScript_Demo3: true,
    });
    setSelectionDemoGenerate(true);
    setdisableDemo3Fields(true);
    setdisableDemo3RightFields(false);
  };
  const GenerateIntro = () => {
    setFloading(true);
    setlastLoading(true);
    CallingSaveDraftAPI({});
    setnext3None111(false);
    setfinishClick(false);
    setDEMO4OP(false);
    setdemo3dis(false);
    setdis0988_11(false);
    setdis76546_1(false);
    scrollToBottom();
    setdisabledInputsd3(true);
    FinalOutPut(
      firstProductName,
      secondProductName,
      summary,
      industry,
      role1,
      businessOutcome1,
      businessOutcome2,
      businessOutcome3,
      SBO4,
      demo1,
      Demo1Two,
      Demo1Three,
      Demo2,
      Demo2Two,
      Demo2Three,
      Demo3,
      Demo3Two,
      Demo3Three,
      Demo4,
      Demo4Two,
      Demo4Three,
      Lob1,
      // ApiKey,
      introModelName
    )
      .then(async (result) => {
        setlastDivNone(true);
        setSelectionDemoGenerate(false);
        setreGenIntro(true);
        if (result.hasOwnProperty("error")) {
          setErrorMessageModal(true);
          setErrorMessage(result.error.message);
          setFloading(false);
          setlastLoading(false);
        }
        let Fdata = result.choices;
        setFintro1(Fdata[0]["text"]);
        if (Fdata.length == 2) {
          setFintro2(Fdata[1]["text"]);
        }
        setFloading(false);
        setlastLoading(false);
        scrollToBottom();
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setFloading(false);
        setlastLoading(false);
      });
  };
  const RegenerateIntro = () => {
    setDemo3Error(false);
    setisShowIntroAddToScript(false);
    setIsShowAddToScriptDemo1Button(false);
    setoutNon1(false);
    setisDisableDemoScript(true);
    CallingSaveDraftAPI({});
    setisRegenIntroLoading(true);
    setisChecked7(false);
    setisChecked8(false);
    setnext3None111(false);
    setdisabledInputsd3(true);
    setlastLoading(true);
    setFloading(true);
    FinalOutPut(
      firstProductName,
      secondProductName,
      summary,
      industry,
      role1,
      businessOutcome1,
      businessOutcome2,
      businessOutcome3,
      SBO4,
      demo1,
      Demo1Two,
      Demo1Three,
      Demo2,
      Demo2Two,
      Demo2Three,
      Demo3,
      Demo3Two,
      Demo3Three,
      Demo4,
      Demo4Two,
      Demo4Three,
      Lob1,
      // ApiKey,
      introModelName
    )
      .then(async (result) => {
        setisRegenIntroLoading(false);
        if (!businessOutcome3 && !role3 && !Demo3) {
          setSaveDrftStateTwo(false);
        }
        setdisableDemo3Fields(true);
        setlastDivNone(true);
        setisDisableDemoScript(false);
        setfinishClick(false);
        scrollToBottom();
        setreGenIntro(true);
        if (result.hasOwnProperty("error")) {
          setErrorMessageModal(true);
          setErrorMessage(result.error.message);
          setFloading(false);
          setlastLoading(false);
        }
        let Fdata = result.choices;

        setFintro1(Fdata[0]["text"]);
        if (Fdata.length == 2) {
          setFintro2(Fdata[1]["text"]);
        }
        setFloading(false);
        setlastLoading(false);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setFloading(false);
        setlastLoading(false);
      });
  };
  const GenerateOutro = () => {
    setloading(true);
    setisShowOutroAddToScript(false);
    setlastLoading(true);
    setisChecked9(false);
    setisChecked10(false);
    CallingSaveDraftAPI({});
    setlastDivNone(false);
    setoutNon2(true);
    setoutNon1(false);
    setoutroLoading(true);
    setreGenIntro(false);
    FinalOutro(
      firstProductName,
      secondProductName,
      summary,
      intro1Value,
      // ApiKey,
      outroModelName
    )
      .then(async (result) => {
        setloading(false);
        setFOROutro(true);
        setlastLoading(false);
        if (result.hasOwnProperty("error")) {
          setErrorMessageModal(true);
          setErrorMessage(result.error.message);
        }
        let dataa = result.choices;
        if (dataa) {
          setoutroState1(dataa[0]["text"].trim());
          setoutroState2(dataa[1]["text"].trim());
        }
        setoutroLoading(false);
        scrollToBottom();
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setoutroLoading(false);
      });
  };
  const [NewDemoFilArrDiv, setNewDemoFilArrDiv] = useState(false);
  const [NewDemoFilArrDivDemo2One, setNewDemoFilArrDivDemo2One] =
    useState(false);
  const [NewDemoFilArrDivDemo3One, setNewDemoFilArrDivDemo3One] =
    useState(false);
  const [NewDemoFilArrDivDemo4One, setNewDemoFilArrDivDemo4One] =
    useState(false);
  const [NewDemoFilArrDivDemo4Two, setNewDemoFilArrDivDemo4Two] =
    useState(false);
  const [NewDemoFilArrDivDemo4Three, setNewDemoFilArrDivDemo4Three] =
    useState(false);
  const [NewDemoFilArrDivDemo3Two, setNewDemoFilArrDivDemo3Two] =
    useState(false);
  const [NewDemoFilArrDivDemo3Three, setNewDemoFilArrDivDemo3Three] =
    useState(false);
  const [NewDemoFilArrDivDemo2Two, setNewDemoFilArrDivDemo2Two] =
    useState(false);
  const [NewDemoFilArrDivDemo2Three, setNewDemoFilArrDivDemo2Three] =
    useState(false);
  const [NewDemoFilArrDivOne, setNewDemoFilArrDivOne] = useState(false);
  const [NewDemoFilArrDivTwo, setNewDemoFilArrDivTwo] = useState(false);
  const [NewDemoFilArr, setNewDemoFilArr] = useState([]);
  const [checkedState, setCheckState] = useState();
  const [CheckStateDemo2, setCheckStateDemo2] = useState();
  const [CheckStateDemo3, setCheckStateDemo3] = useState();
  const [CheckStateDemo4, setCheckStateDemo4] = useState();

  const SelectNewDemo1 = (val) => {
    setDemo1(val);
    setNewDemoFilArrDiv(false);
  };
  const SelectNewDemo2 = (val) => {
    setDemo2(val);
    setNewDemoFilArrDivDemo2One(false);
  };
  const SelectNewDemo3 = (val) => {
    setDemo3(val);
    setNewDemoFilArrDivDemo3One(false);
  };
  const OnChangeNewDemo1 = (e) => {
    if (checkedState == true) {
      setNewDemoFilArrDiv(true);
    }
    let val = e.target.value;
    setDemo1Error(val.length != 0 ? false : true);
    setDemo1(val);
    setNewDemoFilArr([
      ...demoKeyPointOne.filter((e) => {
        if (e.name.toLowerCase().includes(val.toLowerCase())) {
          return e;
        }
      }),
    ]);
    setDraftStatedata({
      ...draftStateData,
      screen1_highlight: e.target.value,
    });
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_1: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeNewDemo2 = (e) => {
    if (CheckStateDemo2 == true) {
      setNewDemoFilArrDivDemo2One(true);
    }
    let val = e.target.value;
    setDemo2(val);
    setNewDemoFilArr([
      ...demoKeyPointOne.filter((e) => {
        if (e.name.toLowerCase().includes(val.toLowerCase())) {
          return e;
        }
      }),
    ]);
    const debounceFunction = debounce(() => {
      CallingSaveDraftAPI({ demo_2: e.target.value });
    }, 1000);
    debounceFunction();
  };
  const OnChangeNewDemo3 = (e) => {
    if (CheckStateDemo3 == true) {
      setNewDemoFilArrDivDemo3One(true);
    }
    let val = e.target.value;
    setDemo3(val);
    setNewDemoFilArr([
      ...demoKeyPointOne.filter((e) => {
        if (e.name.toLowerCase().includes(val.toLowerCase())) {
          return e;
        }
      }),
    ]);
  };
  const SavingDemo1 = () => {
    setTimeout(() => {
      setNewDemoFilArrDiv(false);
    }, 200);
    setDemo1Error(demo1 && demo1.length != 0 ? false : true);
    CallingSaveDraftAPI({});
  };
  const SavingDemo3 = () => {
    setTimeout(() => {
      setNewDemoFilArrDivOne(false);
    }, 200);
    CallingSaveDraftAPI({});
  };
  const ShowSecondProduct = () => {
    setShowPlusBtn(false);
    setShowProductNext(true);
    setplusIcoState(false);
  };
  const HideSecondProduct = () => {
    setShowPlusBtn(true);
    setShowProductNext(false);
    setplusIcoState(true);
    setSecondProductName("");
    pName2Ref.current = "";
    setSecondProductUrl("");
    CallingSaveDraftAPI({ productName_2: "", productURL_2: "" });
  };
  const RequestToDraftState = () => {
    setstepDraftColor("Draft");
    setshowapprovalInfoSme(false);
  };
  const EditingToRequestState = () => {
    setstepDraftColor("Request");
    // setshowapprovalInfoSme(true);
    // setshowapprovalInfoHublead(false);
  };
  let defaultIndustryValue = [
    industry != null ? { value: 0, label: industry && industry } : null,
  ];
  let defaultDomainValue = [
    domain != null ? { value: "0", label: domain && domain } : null,
  ];

  const rejectRequestFromAdmin = (hubLeads) => {
    setshowapprovalInfoSme(true);
    setshowapprovalInfoHublead(false);
    setHubleadNames(hubLeads);
    setstepDraftColor("Request");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  // const draftOwnerCheck = (userData?.role === 'Admin' || userData?.role === "Editor") ? false : userData?.id === userIdDraftOwner ? true : true
  const draftOwnerCheck = (userData?.role === 'Admin') ? false : userData?.id === userIdDraftOwner ? true : true

  return (
    <section>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <Header
        rejectRequestFromAdmin={rejectRequestFromAdmin}
        headingName={"Explainer Video"}
        CallingSaveDraftAPI={() => { }}
        isAutoSaved={isAutoSaved}
        isSaved={isSaved}
        id={draftStateData && draftStateData.id}
        lineOfBusinessData={lineOfBusinessData}
        industryData={industryData}
        onselectLOB={OnSelectLineOfBusiness}
        onselectInd={OnSelectIndustry}
        handleCancelSme={OpenRequestSentModal}
        handleCancelHublead={OpenDraftApprovedModal}
        stepDraftColor={stepDraftColor}
        previousPath={previousPath}
        onBackClick={CloseDraft}
        canApprove={canApprove}
        loading={isLoading}
        approveDrfatByDHL={DraftApprovalRequest}
        loadingReqApp={loadingReqApp}
        isDisableReq={isDisableReq}
        isDisableTeachTori={isDisableTeachTori}
        TeachToriHandle={MoveToToriTrained}
        loadingTori={loadingTori}
        draftSharedWith={draftSharedWith}
        draftCreatedBy={draftCreatedBy}
        cancelRequestDHLApproval={CancelApprovalRequest}
        loadingcancel={loadingcancel}
        isDownloadDisable={true}
        RequestToDraftState={RequestToDraftState}
        EditingToRequestState={EditingToRequestState}
        videoType="explainer"
        from="explainer"
      />
      <div style={{ marginTop: "175px" }} className="body">
        <section>
          <div className="row m-0">
            <ApprovalInfo
              showapprovalInfoHublead={showapprovalInfoHublead}
              showapprovalInfoSme={showapprovalInfoSme}
              closeHubleadInfo={HideInfoBar}
              draftSharedWith={hubleadNames}
            />

            <div className="col-6" style={{ backgroundColor: "#fff" }}>
              {/* duration dropdown */}
              <div id="productName" className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <Select
                    defaultValue="Target Duration: 5-7 Minutes"
                    style={{
                      fontWeight: "lighter",
                    }}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    disabled={disableDemo1Fields}
                    options={[
                      {
                        value: "Target Duration: 5-7 Minutes",
                        label: "Target Duration: 5-7 Minutes",
                      },
                    ]}
                  />
                </div>
                <div className="col-1"></div>
              </div>
              {/* Video Name */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="top_input">
                    <InputField
                      className="InputFields"
                      isShowLabel={true}
                      isShowLabelIcon={true}
                      label="Video Name"
                      value={videoName}
                      isDisabled={disableAllinputs}
                      onChange={OnChangeVideoName}
                      placeholder="Enter video name here"
                      isShowId={true}
                      draftId={id}
                      tooltipText={videoNameTooltip}
                      onBlur={() => CallingSaveDraftAPI({})}
                    />
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              {/* Product Name */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <ProductName
                    showLinkIcon={true}
                    title={productNameTooltip}
                    disabledInputs={disableDemo1Fields || disableAllinputs}
                    pName={firstProductName}
                    proEr={product1Error}
                    onChangePname={OnChangeProductName1}
                    selectTextPN={OnSelectProductName1}
                    ProductUrl={firstProductUrl}
                    onChangeProductUrl={OnChangeFirstProductUrl}
                    CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
                    Icon="plus"
                    placeholder="1st Product URL"
                    placeholder1="Select 1st Product Name"
                    ShowProduct={ShowSecondProduct}
                    ShowPlusBtn={!disableDemo1Fields && ShowPlusBtn}
                    onBlur={(isProductName) =>
                      SavingProductName1(isProductName)
                    }
                  />
                  <div style={{ marginTop: "15px" }}>
                    {ShowProductNext && (
                      <ProductName
                        showLinkIcon={false}
                        disabledInputs={disableDemo1Fields || disableAllinputs}
                        pName={secondProductName}
                        onChangePname={OnChangeProductName2}
                        selectTextPN={OnSelectProductName2}
                        ProductUrl={SecondProductUrl}
                        onChangeProductUrl={OnChangeSecondProductUrl}
                        CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
                        Icon="minus"
                        placeholder="2nd Product URL"
                        placeholder1="Select 2nd Product Name"
                        ShowProduct={HideSecondProduct}
                        ShowPlusBtn={!disableDemo1Fields && ShowMinusBtn}
                        onBlur={SavingProductName2}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Industry & Line Of Business */}
              {!isLoading && (
                <div className="row mt-5">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-6 p-0">
                        <SelectDropdown
                          isShowLabel={true}
                          label="Industry"
                          tooltipText={industryTooltip}
                          onBlur={() => {
                            setIndustryError(
                              industry && industry.length != 0 ? false : true
                            );
                            CallingSaveDraftAPI({});
                          }}
                          onChange={OnSelectIndustry}
                          placeholder="Select or begin typing"
                          defaultValue={defaultIndustryValue}
                          options={industryData}
                          isShowErrorMessage={industryError}
                          errorMessage="This field is required"
                          disabledInputs={
                            disableDemo1Fields && isDisableLineOfBusiness
                          }
                          isOptionDisabled={() => false}
                        />
                      </div>
                      <div className="col-6">
                        <SelectDropdown
                          isShowLabel={true}
                          label="Line of Business"
                          tooltipText={lineOfBusinessTooltip}
                          onBlur={() => {
                            setLineOfBusinessError(
                              Lob1 && Lob1.length != 0 ? false : true
                            );
                            CallingSaveDraftAPI({});
                          }}
                          onChange={OnSelectLineOfBusiness}
                          options={lineOfBusinessData}
                          isMulti={true}
                          placeholder="Select or begin typing"
                          defaultValue={lineOfBusiness}
                          isOptionDisabled={() =>
                            (Lob1.length >= 3 || disableDemo1Fields) &&
                            isDisableLineOfBusiness
                          }
                          disabledInputs={
                            disableDemo1Fields && isDisableLineOfBusiness
                          }
                          isShowErrorMessage={lineOfBusinessError}
                          errorMessage="This field is required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              )}
              {/* Domain/RBA Process */}
              {!isLoading && (
                <div className="row top_pad">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <SelectDropdown
                      isShowLabel={true}
                      label="Domain/RBA Process"
                      disabledInputs={disableDemo1Fields}
                      tooltipText={domainTooltip}
                      onBlur={() => CallingSaveDraftAPI({})}
                      onChange={OnSelectDomain}
                      options={domainsData}
                      placeholder="Select or begin typing"
                      defaultValue={defaultDomainValue}
                      isOptionDisabled={() => disableDemo1Fields}
                    />
                  </div>
                  <div className="col-1"></div>
                </div>
              )}
              {/* Summary */}
              <div className="row top_pad">
                <div className="col-1"></div>
                <div className="col-10">
                  <div style={{ display: "flex", marginBottom: 13 }}>
                    <span className="pName">Summary/Description</span>
                    <Tooltip
                      onMouseEnter={() => setShowMore(false)}
                      arrow
                      placement="right"
                      title={
                        <Typography fontSize={14}>
                          summary
                          {showMore
                            ? summaryTooltip
                            : `${summaryTooltip.substring(0, 229)}`}
                          <div
                            onClick={() => setShowMore(!showMore)}
                            className={"summary-tooltip"}
                          >
                            {showMore ? "" : "View Example"}
                          </div>
                        </Typography>
                      }
                    >
                      <img
                        style={{
                          marginLeft: 5,
                          width: 20,
                        }}
                        src={info}
                      />
                    </Tooltip>
                  </div>
                  <div className="top_input">
                    <GrammarlyEditorPlugin
                      clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
                    >
                      <textarea
                        row="4"
                        onBlur={() => {
                          setSummaryError(
                            summary && summary.length != 0 ? false : true
                          );
                          CallingSaveDraftAPI({});
                        }}
                        value={summary}
                        disabled={disableDemo1Fields}
                        onChange={OnChangeSummary}
                        className={
                          disableDemo1Fields
                            ? "InputFields disable-state"
                            : "InputFields"
                        }
                        placeholder="Write 2-3 sentences"
                        type="text"
                      />
                    </GrammarlyEditorPlugin>
                    {summaryError && (
                      <div style={{ color: "red" }}>This field is required</div>
                    )}
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <hr style={{ marginTop: 38 }} />
              <ExplainerDemoOptions
                draftState={stepDraftColor}
                CallingSaveDraftAPI={() => CallingSaveDraftAPI({})}
                isShowDemoKeyPoint={false}
                showBusiness={true}
                isShowRole={false}
                businessLabel={"Business Outcome 1"}
                businessValue={businessOutcome1}
                onBlurBO={SavingBusinessOutcome1}
                selectTextBO={OnSelectBusinessOutcome1}
                businessOutcomeDropdownData={boDropdownData}
                businessPlaceholder="e.g. Create successful projects"
                businessOnchange={f}
                businessValue2={businessOutcome2}
                onBlurBO2={SavingBusinessOutcome2}
                selectTextBO2={OnSelectBusinessOutcome2}
                businessOnchange2={OnChangeBusinessOutcome2}
                businessValue3={businessOutcome3}
                onBlurBO3={SavingBusinessOutcome3}
                selectTextBO3={OnSelectBusinessOutcome3}
                businessOnchange3={OnChangeBusinessOutcome3}
                demoError={demo1Error}
                demoKeyPointLoader={demoKeyPointLoader}
                disAbleDemoKeyPoint={disAbleDemoKeyPoint}
                demoOnfocus={SavingDemo1}
                demoOnchange={OnChangeNewDemo1}
                disabledInputsOnNext={disableAllinputs || disabledInputsOnNext}
                demoDisable={disableAllinputs || disableDemoKey}
                demoPlaceholder="Add 3 bullet points or sentences that summarizes the video"
                demoValue={demo1}
                NewDemoFilArrDiv={NewDemoFilArrDiv}
                NewDemoFilArr={NewDemoFilArr}
                selectNewDemo={SelectNewDemo1}
                showButtons={SaveDrftStateButtonOne}
                finishClick={true}
                isGenerateDemoScript1={RegenerateDemoScript}
                loading={loading}
                demoNo="Topic"
                isSkipDemo={false}
                isBack={false}
                regenerateDemo={showDemo1RegenerateButton}
                onClickGenerateDemo={GenerateFirstDemo}
                onClickRegenerateDemo={RegenerateFirstDemo}
                showDemoOptions={isShowDemo1Options}
                optionsName="Demo 1 Options"
                onChangeOpt1={onChangeDemo1TextBox1}
                onChangeOpt2={onChangeDemo1TextBox2}
                opt1Val={demo1Option1Value}
                disableSaveButton={isDisableSaveDataButton}
                opt1Check={isChecked}
                opt1Id="A1"
                opt2Id="A2"
                onChangeOpt1Check={OnChangeDemo1Option1}
                onChangeOpt2Check={OnChangeDemo1Option2}
                opt2Val={demo1Option2Value}
                bottomRef={bottomRef}
                opt2Check={isChecked2}
                showAddtoscript={isShowAddToScriptDemo1Button}
                showGenerateintro={false}
                onClickAddtoScript={AddToScriptDemo1}
                disableAllinputs={disableDemo1Fields}
                onchangeBOArray={(val, name, index) =>
                  OnChangeBusinessOutcomeArray(val, name, index)
                }
                addBusinessOutcome={HandleAddBO}
                removeBusinessOutcome={(index) => HandleRemoveBO(index)}
                businessOutcomeArray={businessOutcomesArray}
              />
              <GenerateOutroIntro
                showdiv={lastDivNone}
                lastLoading={lastLoading}
                optionName="Select Intro"
                opt1Id="AAA"
                opt1Onchange={(e) => setFintro1(e.target.value)}
                opt1Val={Fintro1}
                opt1Check={isChecked7}
                opt1Checkchange={OnChangeIntroOption1}
                opt2Id="BBB"
                opt2Onchange={(e) => setFintro2(e.target.value)}
                isRegenIntroLoading={isRegenIntroLoading}
                opt2Val={Fintro2}
                opt2Check={isChecked8}
                opt2Checkchange={OnChangeIntroOptions2}
                showAddtoscript={isShowIntroAddToScript}
                showGenOutro={false}
                showReGenOutro={false}
                loading={loading}
                showFinish={false}
                regenLoading={regenLoading}
                disableAllinputs={disableAllinputs}
                fLoading={Floading}
                onClickAddToScript={AddToScriptIntro}
              />
              <GenerateOutroIntro
                showdiv={FOROutro}
                lastLoading={outroLoading}
                optionName="Select Outro"
                opt1Id="CCC"
                opt1Onchange={(e) => setoutroState1(e.target.value)}
                showAddtoscript={isShowOutroAddToScript}
                opt1Val={outroState1}
                opt1Check={isChecked9}
                opt1Checkchange={OnChangeOutroOption1}
                opt2Id="DDD"
                opt2Onchange={(e) => setoutroState2(e.target.value)}
                opt2Val={outroState2}
                loading={loading}
                opt2Check={isChecked10}
                opt2Checkchange={OnChangeOutroOption2}
                showGenOutro={outNon1}
                onClickbtnGen={GenerateOutro}
                showReGenOutro={outNon2}
                onClickbtnReGen={GenerateOutro}
                showFinish={isFinish}
                disableAllinputs={disableAllinputs}
                onClickAddToScript={AddToScriptOutro}
              />
            </div>

            <div className="col-6" style={{ marginTop: "0px" }}>
              {stepDraftColor == "Draft" ||
                stepDraftColor == "Request" ||
                stepDraftColor == null ? (
                <div className="row m-0">
                  <div className="col-11">
                    <div
                      style={{
                        height: "auto",
                        borderRadius: "8px",
                        padding: "20px",
                        paddingTop: "29px",
                      }}
                    >
                      <img src={explainerBg} />
                      <div className="info-modal">
                        <img src={bulb} style={{ width: 82, height: 106 }} />
                        <Stack spacing={1.2} width="70%">
                          <Typography className="info-modal-heading">
                            {stepDraftColor == "Request"
                              ? "Your Request is off!"
                              : "Hey, You are doing something extremely useful!"}
                          </Typography>
                          <Typography className="info-modal-description">
                            {stepDraftColor == "Request"
                              ? "Once approved you will be contacted by the Digital Factory for next steps. "
                              : "There is no output for this video type yet but there will be. Your intake form is super valuable and will be instrumental in shaping future content, as well as educating Tori. "}
                          </Typography>
                        </Stack>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              ) : (
                <div className="row m-0">
                  <div className="col-11">
                    <div
                      style={{
                        height: "auto",
                        borderRadius: "8px",
                        padding: "20px",
                        paddingTop: "42px",
                      }}
                    >
                      <div>
                        <OutcomeValues
                          header="Grabber:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeGrabber}
                          value={grabber}
                        />
                        <OutcomeValues
                          header="Intro/Solution:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeSolution}
                          value={solution}
                        />
                        <OutcomeValues
                          header="Business Outcome 1:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightBusinessOutcome1}
                          value={rightBusinessOutcome1}
                        />
                        <OutcomeValues
                          header="Demo 1:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightDemo1}
                          value={rightDemo1}
                        />
                        <OutcomeValues
                          header="Business Outcome 2:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightBusinessOutcome2}
                          value={rightBusinessOutcome2}
                        />
                        <OutcomeValues
                          header="Demo 2:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightDemo2}
                          value={rightDemo2}
                        />
                        <OutcomeValues
                          header="Business Outcome 3:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightBusinessOutcome3}
                          value={rightBusinessOutcome3}
                        />
                        <OutcomeValues
                          header="Demo 3:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeRightDemo3}
                          value={rightDemo3}
                        />
                        <OutcomeValues
                          header="Outro:"
                          className={
                            disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)
                              ? "InputFields disable-state right_side autosize"
                              : "InputFields right_side autosize"
                          }
                          disableAllinputs={disableAllinputs || (!isDisableReqOnEditing && draftOwnerCheck)}
                          onBlur={() => CallingSaveDraftAPI({})}
                          onChange={OnChangeOutro}
                          value={outro}
                        />
                        <div className="row">
                          <div className="col-12 newpd">
                            <button
                              className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                                }`}
                              disabled={disableAllinputs || loading || Floading}
                              id="generate"
                              onClick={() => {
                                CallingSaveDraftAPI({}), setsaveLoading(true);
                              }}
                            >
                              {saveloading == true ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                "Save"
                              )}
                            </button>
                            <button
                              className={`primatybutton w-inline-block ${disableAllinputs ? "disable_btn" : ""
                                }`}
                              style={{ marginLeft: "10px" }}
                              id="generate"
                              disabled={
                                disableAllinputs ||
                                canClear ||
                                loading ||
                                Floading
                              }
                              onClick={() => {
                                setClearAllModal(true);
                              }}
                            >
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      {/* )} */}
      {/* Error Modal */}
      <DisplayModal
        open={errorMessageModal}
        onClose={() => {
          setErrorMessageModal(false);
        }}
        modalMsg={errorMessage}
      />
      <DisplayModal
        open={showClear}
        onClose={() => {
          setShowclear(false);
        }}
        modalMsg={showClearMsg}
      />
      <DisplayModal
        open2={approvalSme}
        onClose={() => {
          setapprovalSme(false);
        }}
        heading1="Request Sent"
        modalMsg={approvalSmeMsg}
      />
      <DisplayModal
        open2={approvalHublead}
        onClose={() => {
          setapprovalHublead(false);
        }}
        heading1="Draft approved and ready for final edits"
        modalMsg={approvalHubleadmsg}
      />
      <DisplayModal
        open={teachTorimodal}
        onClose={() => {
          setteachTorimodal(false);
        }}
        modalMsg={teachTorimodalMsg}
      />
      <DisplayModal
        open={clearAllModal}
        onClose={() => {
          setClearAllModal(false);
        }}
        modalMsg={"Are you sure you want to clear this draft?"}
        isShowConfirm={true}
        clearDraft={ClearAllDraft}
        isLoading={clearloading}
      />
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
      <DisplayModal
        open={isDHLRequest}
        onClose={() => {
          setisCancelDHLRequest(false);
        }}
        modalMsg={CancelDHLRequestMsg}
      />
    </section>
  );
};

export default ExplainerVideo;
