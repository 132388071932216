import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Screens/HomeScreen/HomeScreen";
import Header from "./Screens/Header/Header";
import Draft from "./Screens/NewDraftScreen/Draft";
import DraftTable from "./Screens/DraftTable/DraftTable";
import { BrowserRouter } from "react-router-dom";
import SideBar from "./SubComponents/SideBar/SideBar";
import HeatMap from "./Page2";
import SideBar2 from "./SubComponents/SideBar/SideBar2";
import GraphOne from "./SubComponents/Graphs/BarCharts";
import EditingTable from "./Screens/EditingTable/EditingTable";
import FeedBackTable from "./Screens/FeedBackTable/FeedBackTable";
import SpotLightVideo from "./Screens/CreateDraft/SpotLightVideo";
import OverView from "./Screens/OverView/OverView";
import Admin from "./Screens/Admin/Admin";
import ProcessType from "./Screens/ProcessType/ProcessType";
import Newpassword from "./Screens/NewPassword/NewPassword";
import EditingDraft from "./Screens/EditDraft/EditDraft";
import EditingApprovel from "./Screens/EditDraft/EditDraftTwo";
import Approved from "./Screens/Approved/Approved";
import Profile from "./Screens/Profile/Profile";
import FinalReview from "./Screens/EditDraft/EditDraftThree";
import { notification } from "antd";
import { onMessageListener } from "./Services/firebaseInit";
import ProductInfoRetrievel from "./SubComponents/NewDraft/ProductInfoRetrievel";
import SamplerVideo from "./Screens/Sampler/SamplerVideo";
import ExplainerVideo from "./Screens/Explainer/ExplainerVideo";
import TaskVideo from "./Screens/TaskVideo/TaskVideo";
import { getNotifications } from "./Services/Service";
import ScriptProvided from "./Screens/ScriptProvided/ScriptProvided";
import ToriChat from "./Screens/ToriChat/ToriChat";
import ChatUsers from "./Screens/Admin/ChatUsers";
import PublicRoutes from "./PublicRoutes";

function Routes() {
  const [api, contextHolder] = notification.useNotification();
  const [isopen, setisopen] = useState(false);
  const [notificationData, setNotificationsData] = useState();
  const [unreadNotification, setunreadNotification] = useState();

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("====payload===", payload);
        setTimeout(() => {
          api["success"]({
            message: `${payload.notification.title}`,
            description: `${payload.notification.body}`,
            onClose: onCloseHandle,
            duration: 10,
          });
        }, 30);
        setisopen(true);
        getNotifications().then((res) => {
          if (res.status) {
            setNotificationsData(res.data);
            setunreadNotification(res.unread);
          }
        });
      })
      .catch((err) => console.log("failed: ", err));
    setTimeout(() => {
      setisopen(false);
    }, 10000);
  }, [isopen]);
  const onCloseHandle = () => {
    setisopen(false);
  };
  return (
    <>
      {contextHolder}

      <BrowserRouter>
        <Switch>
          <Route path={"/user"}>
            <SideBar
              notifications={notificationData}
              unreadNotifications={unreadNotification}
            >
              <Switch>
                <Route exact path={"/user/profile"} component={Profile}></Route>

                <Route exact path={"/user/home"} component={Draft}></Route>
                <Route
                  exact
                  path={"/user/drafts"}
                  component={DraftTable}
                ></Route>
                <Route
                  exact
                  path={"/user/editing"}
                  component={EditingTable}
                ></Route>
                <Route
                  exact
                  path={"/user/feedBack"}
                  component={FeedBackTable}
                ></Route>
                <Route
                  exact
                  path={"/user/welcome"}
                  component={OverView}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/demostore"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/sapwebsite"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/chat"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/help"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/vectorstore"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/demostore/:id"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/sapwebsite/:id"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/chat/:id"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/torihelp/:id"}
                  component={ToriChat}
                ></Route>
                <Route
                  exact
                  path={"/user/tori-chat/vectorstore/:id"}
                  component={ToriChat}
                ></Route>
                <Route exact path={"/user/admin"} component={Admin}></Route>
                <Route
                  exact
                  path={"/user/chatusers"}
                  component={ChatUsers}
                ></Route>
                <Route
                  exact
                  path={"/user/approved"}
                  component={Approved}
                ></Route>
                <Route exact path={"/user/graphs"} component={GraphOne}></Route>
                <Route
                  exact
                  path={"/user/heat-map"}
                  component={HeatMap}
                ></Route>
              </Switch>
            </SideBar>
          </Route>
        </Switch>
        <PublicRoutes exact path="/" component={Home} />
        <Switch>
          <Route path="/draft">
            <SideBar2>
              <Route
                path={"/draft/spotlight/:id"}
                component={SpotLightVideo}
              ></Route>
              <Route
                path={"/draft/process/:id"}
                component={ProcessType}
              ></Route>
              <Route
                path={"/draft/sampler/:id"}
                component={SamplerVideo}
              ></Route>
              <Route
                path={"/draft/explainer/:id"}
                component={ExplainerVideo}
              ></Route>
              <Route path={"/draft/task/:id"} component={TaskVideo}></Route>
              <Route
                path={"/draft/scriptprovided/:id"}
                component={ScriptProvided}
              ></Route>
              <Route path={"/draft/editing"} component={EditingDraft}></Route>

              <Route
                path={"/draft/approvel"}
                component={EditingApprovel}
              ></Route>
              <Route
                path={"/draft/finalReview"}
                component={FinalReview}
              ></Route>
            </SideBar2>
          </Route>
        </Switch>
        <Switch>
          <Route
            path={"/draft/productInfoRetrievel"}
            component={ProductInfoRetrievel}
          ></Route>
          <Route exact path={"/new-password"} component={Newpassword}></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;

if (document.getElementById("example")) {
  ReactDOM.render(<Routes />, document.getElementById("example"));
}
