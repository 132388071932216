import React, { useEffect, useState } from "react";
import {
  GetUserData,
  GetUserNameEmail,
  ShareDraft,
} from "../../../Services/Service";
import Select from "react-select";
import ToriLogo from "../../../assets/tori_logo.svg";
import ShareLink from "../../../assets/share_link.svg";

import "./style.css";
import { Modal } from "antd";
import { useRef } from "react";
import { Typography, Snackbar, Alert } from "@mui/material";
import DisplayModal from "../../CreateDraft/Components/Modal";
const ShareModal = ({
  id,
  isFromCreateDraft = false,
  isFromActionMenu = false,
  createDraft = false,
  getDraftById,
  type,
  from,
  isDisable,
}) => {
  const [UserDetails, setUserDetails] = useState([]);
  const [loaderDeleteConf, setloaderDeleteConf] = useState(false);
  const [onShareMsg, setOnShareMsg] = useState("");
  const [onShareMsgN, setOnShareMsgN] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isShareErr, setIsShareErr] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const selectRef = useRef(null);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
    setIsShareErr(false);
    selectRef.current.clearValue();
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  useEffect(() => {
    updateSidebar();
    GetUserNameEmail().then((res) => {
      setUserDetails(res.data);
    });
  }, []);

  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };

  const onCopyToClipBordPress = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      window.location.origin + `/draft/${type}/` + id
    );
  };

  const onYesClickShare = () => {
    if (selectRef.current.props.value == null) {
      setIsShareErr(true);
      setOnShareMsgN("User email is required");
    } else {
      setIsShareErr(false);
      setloaderDeleteConf(true);
      ShareDraft(selectRef.current.props.value.value, id)
        .then((res) => {
          if (res.status) {
            setOnShareMsg(res.message);
            showModal2();
            handleCancel1();
            setloaderDeleteConf(false);
            if (createDraft) {
              getDraftById(true);
            }
          } else {
            setloaderDeleteConf(false);
            setIsShareErr(true);
            res.message == "The user id field is required."
              ? setOnShareMsgN("User email is required")
              : setOnShareMsgN(res.message);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloaderDeleteConf(false);
          setIsModalOpen1(false);
        });
    }
  };
  return (
    <>
      <>
        {isFromActionMenu && (
          <Typography
            style={{ fontSize: 14, padding: "5px 16px" }}
            onClick={showModal1}
          >
            Share
          </Typography>
        )}

        {isFromCreateDraft && (
          <div style={{ marginRight: "13px", cursor: "pointer" }}>
            <img
              onClick={!isDisable && showModal1}
              width={21}
              height={21}
              src={ShareLink}
            />
          </div>
        )}
        {/* SHARE MODAL */}
        <Modal
          width={500}
          style={{ borderRadius: "20px" }}
          centered
          footer={[]}
          open={isModalOpen1}
          onCancel={handleCancel1}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Share Draft: ID#{id}
            </h5>
          </div>

          <div style={{ marginTop: 20 }}>
            <label for="exampleInputEmail1">Direct Link</label>
          </div>
          <div className="row">
            <div
              className="col-9"
              style={{ backgroundColor: "#F1F1F1", padding: 8 }}
            >
              {window.location.origin + `/draft/${type}/` + id}
            </div>
            <div className="col-3 mt-3">
              <button
                onClick={onCopyToClipBordPress}
                style={{
                  backgroundColor: "transparent",
                  color: "blue",
                  paddingLeft: 12,
                }}
              >
                Copy Link
              </button>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Alert
              onClose={() => setOpen(false)}
              severity="info"
              sx={{ width: "250px", fontSize: 14 }}
            >
              Copied to clipboard
            </Alert>
          </Snackbar>

          <div style={{ marginTop: 15 }}>
            <label for="exampleInputEmail1">User Email</label>
            <Select
              // onChange={onSelectShareUser}
              options={UserDetails}
              ref={selectRef}
              placeholder="Select or begin typing"
            />
          </div>
          {isShareErr ? (
            <p style={{ marginTop: "10px" }} className="text-danger">
              {onShareMsgN}
            </p>
          ) : (
            ""
          )}
          <div className="pt-3 d-flex justify-content-end">
            <div style={{ marginRight: 10 }}>
              <button
                onClick={handleCancel1}
                className="primatybuttonwhite w-inline-block"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                onClick={onYesClickShare}
                className="primatybuttonmodal w-inline-block"
              >
                {loaderDeleteConf ? (
                  <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Share"
                )}
              </button>
            </div>
          </div>
        </Modal>
        {/* SHARE MESSAGE MODAL */}
        <Modal
          width={400}
          style={{ borderRadius: "20px" }}
          centered
          footer={[]}
          open={isModalOpen2}
          onCancel={handleCancel2}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{`${onShareMsg}`}</h5>
          </div>

          <div className="pt-3 d-flex justify-content-end">
            <button
              onClick={handleCancel2}
              style={{
                height: "34px",
                fontWeight: "bold",
                // backgroundColor: "red",
              }}
              className="primatybuttonred w-inline-block"
            >
              Close
            </button>
          </div>
        </Modal>
        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
      </>
    </>
  );
};

export default ShareModal;
