import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import info from "../../../assets/info.svg";
import { Typography } from "@mui/material";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../../environment";
export default function BusinessOutcomeDropdown({
  tooltip,
  disabledInputs,
  BusinessOutcomeVal,
  onChangeBO,
  selectTextBO,
  placeholder,
  boEr,
  businessOutcomeDropdownData,
  boName,
  dropdownWidth = "39%",
  onBlurBO,
  optional,
  businessOutcomeDropdown,
  processBusinessOutcome,
}) {
  let tree = businessOutcomeDropdownData;

  const [searchBox, setSearchBox] = useState(false);
  const [dummyArr, setDummyArr] = useState(tree);
  const [selectedBo, setSeletedBo] = useState(businessOutcomeDropdown);

  const onFocusBusinessOutcome = () => {
    setSearchBox(true);
    setDummyArr(tree);
    setSeletedBo(businessOutcomeDropdown);
  };
  const onFoucusOut = () => {
    setTimeout(() => {
      setSearchBox(false);
    }, 200);
    onBlurBO();
  };
  function filter(array, text) {
    const getNodes = (result, object) => {
      if (object.text.toLowerCase().includes(text)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.nodes)) {
        const nodes = object.nodes.reduce(getNodes, []);
        if (nodes.length) result.push({ ...object, nodes });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }
  const handleonChangeBO = (e) => {
    onChangeBO(e);
    let val = e.target.value.toLowerCase();
    let filteredArray = filter(tree, val);
    setDummyArr(filteredArray);
    setSearchBox(true);
    if (filteredArray.length == 0) {
      setSearchBox(false);
    }
  };

  return (
    <div>
      {/* label */}
      {tooltip && (
        <div className="row">
          <div className="col-12 p-0">
            <div style={{ display: "flex", marginBottom: 13 }}>
              <span className="pName">{boName}</span>
              <span style={{ marginLeft: "3px", fontSize: "13px" }}>
                {optional}
              </span>
              <Tooltip
                arrow
                placement="right"
                title={<Typography fontSize={14}>{tooltip}</Typography>}
              >
                <img
                  style={{
                    marginLeft: 5,
                    width: 20,
                  }}
                  src={info}
                />
              </Tooltip>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      )}
      <GrammarlyEditorPlugin clientId={REACT_APP_GRAMMARLY_CLIENT_ID}>
        <input
          onFocus={onFocusBusinessOutcome}
          onBlur={onFoucusOut}
          disabled={disabledInputs}
          onChange={handleonChangeBO}
          className="InputFields"
          placeholder={placeholder}
          type="text"
          value={BusinessOutcomeVal === null ? "" : BusinessOutcomeVal}
        />
      </GrammarlyEditorPlugin>
      {boEr && <div style={{ color: "red" }}>This field is required</div>}
      {/* dropdown */}
      {searchBox && (
        <div
          style={{
            position: "absolute",
            zIndex: "99",
            backgroundColor: "white",
            border: "1px solid #ccc",
            maxHeight: 320,
            maxWidth: dropdownWidth,
            width: dropdownWidth,
            overflow: "auto",
            marginTop: "1px",
            borderRadius: "4px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          {dummyArr &&
            dummyArr.map((row) => (
              <div>
                {
                  <div>
                    {row.nodes &&
                      row.nodes.map((i) => (
                        <div>
                          {
                            <p
                              onClick={() => selectTextBO(i.text)}
                              className={
                                i.text == BusinessOutcomeVal
                                  ? "selected_product"
                                  : "selected_bo"
                              }
                            >
                              {i.text}
                            </p>
                          }
                        </div>
                      ))}
                  </div>
                }
              </div>
            ))}

          {selectedBo &&
            selectedBo.map((row) => (
              <div>
                {
                  <p
                    onClick={() => selectTextBO(row.name)}
                    className={
                      row.name == BusinessOutcomeVal
                        ? "selected_product"
                        : "selected_bo"
                    }
                  >
                    {row.name}
                  </p>
                }
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
