import React from "react";
import Warning from "../../../assets/Shape.svg";
import Cross from "../../../assets/Path.svg";

import "../page1.css";
import SharedWith from "./SharedWith";
export default function ({
  showapprovalInfoSme,
  showapprovalInfoHublead,
  closeHubleadInfo,
  draftSharedWith,
}) {
  return (
    <>
      {!showapprovalInfoHublead && showapprovalInfoSme && (
        <div className="col-12 request_pending_container">
          <div className="request_pending">
            <div className="request_pending_right">
              Request pending from DHL Owner
              {draftSharedWith && draftSharedWith.length > 1 ? `'s` : ""}
              <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                {" "}
                <SharedWith draftSharedWith={draftSharedWith} />
              </div>
            </div>

            <div>
              <button
                style={{
                  background: "inherit",
                  color: "#7A4F01",
                  border: "1px solid #7A4F01",
                  borderRadius: "8px",
                }}
                className="primatybutton w-inline-block"
              >
                Request from DHL Owner
              </button>
            </div>
          </div>
        </div>
      )}
      {!showapprovalInfoSme && showapprovalInfoHublead && (
        <div className="col-12 request_pending_container ">
          <div className="request_pending hub_lead">
            <div className="warning_info">
              <div>
                <img src={Warning} />
              </div>
              <div style={{ marginLeft: "20px" }}>
                Note: This script can only be taught to Tori by editors or users
                with permissions.
              </div>
            </div>

            <div>
              <button
                style={{
                  background: "inherit",
                  color: "#7A4F01",
                }}
                className="primatybutton w-inline-block"
                onClick={closeHubleadInfo}
              >
                <img src={Cross} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
