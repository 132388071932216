import React, { useEffect, useState } from "react";

import bg from "../../assets/home_bg.png";
import ProfileUpload from "../../assets/profile_upload.svg";
import LoadingSpinner from "../../assets/spinnerAdam.gif";

import { GetUserData } from "../../Services/Service";
import "./profile.css";
import DisplayModal from "../CreateDraft/Components/Modal";

function Profile(props) {
  const [fName, setName] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [role, setRole] = useState();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  const onChangeFname = (e) => {
    setName(e.target.value);
  };
  const onChangeCity = (e) => {
    setCity(e.target.value);
  };
  const onChangeInviteEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeRole = (e) => {
    setRole(e.target.value);
  };

  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    GetUserData()
      .then((res) => {
        if (res.status) {
          setData(res.data);
          setName(res.data.name);
          setEmail(res.data.email);
          setCity(res.data.city && res.data.city);
          setRole(res.data.role);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoader(false);
      });
  }, []);

  return (
    <div>
      {loader && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Profile</h3>
      </div>
      {/* {loader ? (
          <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div className="row">
        <div className="col-6">
          <div className="profile_data">
            <div className="input_box">
              <p style={{ fontSize: 12 }}>Full Name</p>
              <input
                value={fName}
                className="input_field"
                onChange={onChangeFname}
                placeholder="Enter First Name"
                type="text"
              />
            </div>
            <div className="input_box">
              <p style={{ fontSize: 12 }}>Email</p>
              <input
                value={email}
                className="input_field"
                onChange={onChangeInviteEmail}
                placeholder="name@sap.com"
                type="text"
              />
            </div>
            <div className="input_box">
              <p style={{ fontSize: 12 }}>Role</p>
              <input
                value={role}
                className="input_field"
                onChange={onChangeRole}
                placeholder="Role"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
      {/* )} */}

      <img
        width={755}
        height={755}
        style={{
          position: "absolute",
          marginTop: "-450px",
          right: 187,
          zIndex: -999,
        }}
        src={bg}
      />
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </div>
  );
}

export default Profile;
