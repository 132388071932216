import React, { useEffect, useState } from "react";
import "./Style.css";
import DisplayModal from "../../Screens/CreateDraft/Components/Modal";

const Draft = ({
  name = "",
  point = "",
  view = "",
  discription = "",
  commingSoon = true,
  disableStyle = true,
  btnName = "",
  commingSoonText = "Coming Soom",
  commingSoonStyle = true,
  startDraft,
  loading,
  isDisable,
  btnBg,
}) => {
  const [viewS1, setViewS1] = useState(false);

  useEffect(() => {}, []);

  return (
    <div>
      <div
        style={{
          position: "relative",
          minHeight: 330,
          backgroundColor: "#fff",
          borderRadius: 10,
          paddingTop: 25,
          paddingBottom: 20,
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <div>
          <h6 style={{ fontSize: 18, fontWeight: "bold" }}>{name}</h6>
          <span style={{ fontSize: 14, color: "gray" }}>{point}</span>
          <br />
          <span
            className="mt-2"
            style={{ fontSize: 14, color: "blue", cursor: "pointer" }}
            onClick={() => {
              setViewS1(true);
            }}
          >
            {view}
          </span>
        </div>
        <div>
          <p style={{ fontSize: 14, marginTop: 15 }}>{discription}</p>
        </div>
        <div style={{ position: "absolute", bottom: 20 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 40,
            }}
          >
            <button
              onClick={startDraft}
              disabled={loading || isDisable}
              style={{
                backgroundColor: btnBg,
                color: "rgb(255, 255, 255)",
              }}
              className={
                disableStyle
                  ? "primatybutton w-inline-block disabled"
                  : "btn scriptBtn"
              }
            >
              {loading ? (
                <div style={{ padding: "0px 24.5px 1px" }}>
                  <div
                    class="spinner-border text-dark"
                    style={{ padding: "0px 5px" }}
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div> {btnName}</div>
              )}
            </button>
            {commingSoon && (
              <p
                style={{ marginLeft: 90 }}
                className={commingSoonStyle ? "grayText" : "yellowText"}
              >
                {commingSoonText}
              </p>
            )}
          </div>
        </div>
      </div>
      <div>
        <DisplayModal
          open={viewS1}
          onClose={() => {
            setViewS1(false);
          }}
          modalMsg={"Coming Soon"}
        />
      </div>
    </div>
  );
};

export default Draft;
