import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
import "antd/dist/antd.min.css";
import "../DraftTable/modals/style.css";
import { Typography } from "@mui/material";
const DownloadModal = ({ id }) => {
  const [onDownloadMsg, setOnDownloadMsg] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Typography
        onClick={() => {
          setOnDownloadMsg("Coming Soon");
          showModal();
        }}
        style={{ fontSize: 14,padding: "5px 16px" }}
      >
        Download
      </Typography>
      {/* download modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${onDownloadMsg}`}</h5>
        </div>

        <div className=" d-flex justify-content-end pt-3">
          <button
            onClick={handleCancel}
            style={{
              //   height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="primatybuttonred w-inline-block"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DownloadModal;
