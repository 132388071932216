import React, { useState, useEffect } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
// import "../DraftTable/modals/style.css";
import "./style.css";

import { Modal } from "antd";
import { ActiveUser, GetUserData } from "../../Services/Service";
import { Stack, Typography } from "@mui/material";
import DisplayModal from "../CreateDraft/Components/Modal";
import imageLogo from "../../assets/logo.png";
import { useContext } from "react";
import AdminContext from "../../../context/AdminTableContext";

const ActivateUser = ({ id, textLabel }) => {
  const { activateUser } = useContext(AdminContext);
  const [loaderConfirmDel, setloaderConfirmDel] = useState(false);
  const [loadingAction, setloadingAction] = useState(false);
  const [ActionStatus, setActionStatus] = useState(false);

  const [ResMesg, setResMesg] = useState("");

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const onPress = () => {
    setIsModalOpen1(false);
    setIsModalOpen2(false);
    // window.location.reload();
  };

  useEffect(() => {
    setloadingAction(true);
    GetUserData().then((res) => {
      setActionStatus(res.data);
      console.log("resres", res);
      setloadingAction(false);
    });
  }, []);
  const onConfirmActive = () => {
    setloaderConfirmDel(true);
    activateUser(id)
      .then((res) => {
        if (res.status) {
          setloaderConfirmDel(false);
          setResMesg(res.message);
          // location.reload();
          handleCancel1();
          setIsModalOpen2(true);
        } else {
          setResMesg(res.message);
          setloaderConfirmDel(false);
          setIsModalOpen2(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloaderConfirmDel(false);
      });
  };

  return (
    <>
      <Typography
        style={{ fontSize: 14, padding: "5px 16px" }}
        onClick={showModal1}
      >
        {textLabel}
      </Typography>
      {/* delete modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-titlepb-3"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Activate User
          </h5>
        </div>

        <h5 style={{ fontWeight: "500" }}>
          Are you sure you want to Activate this User?
        </h5>
        <div className="pt-3 d-flex justify-content-end">
          <div style={{ marginRight: 10 }}>
            <button
              onClick={handleCancel1}
              className="primatybuttonwhite w-inline-block"
            >
              No
            </button>
          </div>
          <div>
            <button
              onClick={onConfirmActive}
              className="primatybuttonred w-inline-block"
            >
              {loaderConfirmDel ? (
                <div class="spinner-border text-white" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={() => setIsModalOpen2(false)}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            // padding: 40,
            paddingBottom: 10,
            // paddingTop: 7,
            marginTop: "15px",
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px", marginTop: "15px" }}>{`${
            ResMesg && ResMesg
          }`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={onPress}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "rgb(221, 54, 43)",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </>
  );
};

export default ActivateUser;
