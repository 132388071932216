import { Snackbar, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Progress } from "antd";

import WorldFile from "../../HomeScreen/WorldFile";
import WordFile from "../../ProcessType/WordFile";
import ApprovDHLModal from "../ApproveDHLModal/ApprovDHLModal";
import {
  GetDraftHubLeads,
  GetHubLeadList,
  GetSavedDraftDetails,
  GetSpecificHubLead,
} from "../../../Services/Service";
import logo from "../../../assets/logo.png";
import verticaldivider from "../../../assets/verticaldivider.png";
import "../page1.css";
import SharedWith from "./SharedWith";
import ShareModal from "../../DraftTable/modals/ShareModal";
import DisplayModal from "./Modal";
import DownloadSamplerData from "../../Sampler/DownloadSamplerData";
import RejectRequestModal from "./RejectRequestModal";
import DownloadScriptProvided from "../../ScriptProvided/DownloadScriptProvided";

export default function Header({
  isDownloadDisable = false,
  headingName,
  CallingSaveDraftAPI,
  isSaved,
  isAutoSaved,
  id,
  RequestToDraftState,
  EditingToRequestState,
  handleCancelSme,
  handleCancelHublead,
  stepDraftColor,
  onBackClick,
  canApprove,
  isDisableApproveButton,
  loading,
  approveDrfatByDHL,
  loadingReqApp,
  isDisableReq,
  isDisableTeachTori,
  TeachToriHandle,
  loadingTori,
  draftSharedWith,
  draftCreatedBy,
  cancelRequestDHLApproval,
  loadingcancel,
  videoType,
  gettingSavedDraft,
  from,
  isShareDisable = false,
  rejectRequestFromAdmin,
  isScriptProvided,
}) {

  const [loadingReq, setLoadingReq] = useState(false);
  const [loadingRejectReq, setLoadingRejectReq] = useState(false);
  const [approveLo, setApproveLo] = useState([]);
  const [hubleadDetail, sethubleadDetail] = useState();
  const [assignedHubleads, setAssignedHubleads] = useState();
  const [hubleadDetailData, sethubleadDetailData] = useState();
  const [userId, setUserId] = useState();
  const [Indursty, setIndursty] = useState();
  const [openDHLModal, setOpenDHLModal] = useState(false);
  const [openRejectRequestModal, setOpenRejectRequestModal] = useState(false);
  const [emails, setEmails] = useState();
  const [logedUser, setlogedUser] = useState();
  const [role, setrole] = useState();
  const [allhub, setallhub] = useState();
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [loadReqMsg, setloadReqMsg] = useState("");
  const [isLoadReqMsg, setisLoadReqMsg] = useState(false);
  const [lobReqMsg, setLobReqMsg] = useState("");
  const [isLobReqMsg, setisLobReqMsg] = useState(false);

  // console.log("🚀 ~ file: Header.js:57 ~ draftCreatedBy:", draftCreatedBy)
  // console.log("🚀 ~ file: Header.js:57 ~ draftCreatedBy:", logedUser)
  console.log("🚀 ~ file: Header.js:57 ~ isDisableReq:", isDisableReq)


  const requestDHLApproval = () => {
    setLoadingReq(true);
    CallingSaveDraftAPI({});
    GetSavedDraftDetails(id)
      .then((res) => {
        if (res.status) {
          let newArrLo = [];
          res.data.line_of_business.map((er, i) => {
            newArrLo.push({ value: i, label: er });
          });
          setUserId(res.data.user_id);
          setIndursty(res.data.industry);
          setApproveLo(newArrLo);
          GetSpecificHubLead(
            res.data.line_of_business,
            res.data.industry,
            res.data.id
          ).then((res) => {
            if (res.status) {
              setOpenDHLModal(true);
              sethubleadDetail(res.specific_hubleads);
              setallhub(res.all_hubleads);
              var removed = res.all_hubleads;
              res.specific_hubleads &&
                res.specific_hubleads.forEach((specific) => {
                  removed = removed.filter((e) => e.label !== specific.label);
                });

              sethubleadDetailData(removed);
              setEmails(
                res.specific_hubleads.filter(
                  (users) => users.label && users.label.length > 2
                )
              );
              setLoadingReq(false);
            } else {
              setLobReqMsg(res.message);
              setisLobReqMsg(true);
              setLoadingReq(false);
            }
          });
        } else {
          setloadReqMsg(res.message);
          setisLoadReqMsg(true);
          setLoadingReq(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoadingReq(false);
      });
  };
  const removeHublead = (key, index) => {
    let items = [...hubleadDetail];
    let item = { ...items[index] };
    item.label = "";
    items[index] = item;
    sethubleadDetail(items);
    setEmails(items.filter((users) => users.label && users.label.length > 2));
    removSelectedHubleadFromDropdown(items);
  };
  const changeHublead = (val, op, index) => {
    let items = [...hubleadDetail];
    let item = { ...items[index] };
    item.label = op.label;
    item.value = op.value;
    items[index] = item;
    sethubleadDetail(items);
    setEmails(items.filter((users) => users.label && users.label.length > 2));
    removSelectedHubleadFromDropdown(items);
  };
  const AddEmail = (index) => {
    hubleadDetail.splice(index + 1, 0, { value: 0, label: "" });
    sethubleadDetail([...hubleadDetail]);
    setEmails(
      hubleadDetail.filter((users) => users.label && users.label.length > 2)
    );
  };
  const RemoveEmail = (index) => {
    if (index == 0) {
      hubleadDetail.splice(index, 1);
      sethubleadDetail([...hubleadDetail]);
      setEmails(
        hubleadDetail.filter((users) => users.label && users.label.length > 2)
      );
      removSelectedHubleadFromDropdown([...hubleadDetail]);
    } else {
      hubleadDetail.splice(index, index);
      sethubleadDetail([...hubleadDetail]);
      setEmails(
        hubleadDetail.filter((users) => users.label && users.label.length > 2)
      );
      removSelectedHubleadFromDropdown([...hubleadDetail]);
    }
  };
  const removSelectedHubleadFromDropdown = (selectedHub) => {
    var removed = allhub;
    selectedHub &&
      selectedHub.forEach((specific) => {
        removed = removed.filter((e) => e.label !== specific.label);
      });

    sethubleadDetailData(removed);
  };

  const closeModal = () => {
    // handleCancel();
    setOpenDHLModal(false);
  };
  const handleApprovalClose = () => {
    setOpenDHLModal(false);
  };
  useEffect(() => {
    setlogedUser(localStorage.getItem("userName"));
    const ro = JSON.parse(localStorage.getItem("userData"));
    setrole(ro && ro.role);
  }, []);
  const OpenRejectRequestModalHandler = () => {
    setLoadingRejectReq(true);
    GetDraftHubLeads(id).then((res) => {
      if (res.status) {
        setLoadingRejectReq(false);
        setOpenRejectRequestModal(true);
        setAssignedHubleads(res.data);
      }
    });
  };
  const OpenModal = () => {
    setOpenRejectRequestModal(true);
  };
  return (
    <section>
      <div
        style={{
          position: "fixed",
          top: 0,
          zIndex: 999,
          width: "100%",
          backgroundColor: "#F7FAFC",
          marginLeft: "-85px",
          boxShadow: "0px 11px 10px -15px #111",
          padding: "10px",
        }}
      >
        <div className="row m-0 align-items-center">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="col-5"
          >
            <div
              style={{
                marginLeft: 25,
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div>
                <img
                  onClick={() => !loading && onBackClick()}
                  style={{ cursor: "pointer", height: 48, width: 48 }}
                  src={logo}
                />
              </div>
              {/* Script State Name */}
              <div
                className={
                  stepDraftColor == "Request"
                    ? "request_state"
                    : stepDraftColor == "Editing"
                      ? "editing_state"
                      : stepDraftColor == "Teach Tori"
                        ? "teachtori_state"
                        : "draft-state"
                }
              >
                <p>
                  {stepDraftColor && stepDraftColor == "Request"
                    ? "Requested"
                    : !stepDraftColor
                      ? "Draft"
                      : stepDraftColor}
                </p>
              </div>

              <div
                className="pl-2"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: 12,
                }}
              >
                {headingName}
              </div>
            </div>
            {isAutoSaved && (
              <>
                {isSaved ? (
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    Saved
                  </div>
                ) : (
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    Autosaving...
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="col-7 mt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <SharedWith draftSharedWith={draftSharedWith} />

            <ShareModal
              isDisable={isShareDisable}
              isFromCreateDraft={true}
              id={id}
              getDraftById={gettingSavedDraft}
              createDraft={true}
              type={from}
            />
            {videoType === "process" ? (
              <WordFile draftId={id} autoSaved={CallingSaveDraftAPI} />
            ) : videoType == "spotlight" ? (
              <WorldFile draftId={id} autoSaved={CallingSaveDraftAPI} />
            ) : videoType == "sampler" ? (
              <DownloadSamplerData
                isDownloadDisable={isDownloadDisable}
                draftId={id}
                autoSaved={CallingSaveDraftAPI}
              />
            ) : isScriptProvided == "1" ? (
              <DownloadScriptProvided
                isDownloadDisable={isDownloadDisable}
                draftId={id}
                autoSaved={CallingSaveDraftAPI}
              />
            ) : null}

            <button
              className="close_btn"
              style={{ marginLeft: "10px" }}
              onClick={onBackClick}
              disabled={
                loadingReqApp ||
                loadingReq ||
                loadingcancel ||
                loadingRejectReq ||
                loading
              }
            >
              Close
            </button>
            {!(
              stepDraftColor == "Trained" || stepDraftColor == "Teach Tori"
            ) ? (
              <div className="header_bar">
                <img src={verticaldivider} />
              </div>
            ) : null}

            {loading ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div>
                {!(
                  stepDraftColor == "Request" ||
                  stepDraftColor == "Editing" ||
                  stepDraftColor == "Teach Tori" ||
                  stepDraftColor == "Trained"
                ) && (
                    <button
                      className={`primatybutton w-inline-block ${isDisableReq ||
                        !(draftCreatedBy == logedUser || role == "Admin")
                        ? "disable_btn"
                        : ""
                        }`}
                      style={{ marginLeft: "10px" }}
                      onClick={requestDHLApproval}
                      disabled={
                        loadingReq ||
                        isDisableReq ||
                        !(draftCreatedBy == logedUser || role == "Admin")
                      }
                    >
                      {loadingReq == true ? (
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Request"
                      )}
                    </button>
                  )}
                {stepDraftColor == "Request" && canApprove && (
                  <button
                    className={"primatybutton w-inline-block"}
                    style={{ marginLeft: "10px" }}
                    onClick={OpenModal}
                    disabled={
                      loadingReqApp || isDisableApproveButton || !canApprove
                    }
                  >
                    Reject Request
                  </button>
                )}
                {stepDraftColor == "Request" && canApprove && (
                  <button
                    className={`primatybutton w-inline-block ${(loadingReqApp ||
                      isDisableApproveButton ||
                      !canApprove) &&
                      "disable_btn"
                      }`}
                    style={{ marginLeft: "10px" }}
                    onClick={approveDrfatByDHL}
                    disabled={
                      loadingReqApp || isDisableApproveButton || !canApprove
                    }
                  >
                    {loadingReqApp == true ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Approve Request"
                    )}
                  </button>
                )}
                {(draftCreatedBy == logedUser || role == "Admin") &&
                  stepDraftColor == "Request" && (
                    <button
                      className="primatybutton w-inline-block"
                      style={{ marginLeft: "10px" }}
                      onClick={cancelRequestDHLApproval}
                    >
                      {loadingcancel == true ? (
                        <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Cancel Request"
                      )}
                    </button>
                  )}
                {stepDraftColor == "Editing" && (
                  <button
                    className={`primatybutton w-inline-block ${role != "Admin" ? "disable_btn" : ""
                      }`}
                    style={{ marginLeft: "10px" }}
                    onClick={OpenRejectRequestModalHandler}
                    disabled={
                      loadingTori || loadingRejectReq || role !== "Admin"
                    }
                  >
                    {loadingRejectReq == true ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Reject Request"
                    )}
                  </button>
                )}
                {stepDraftColor == "Editing" && (
                  <button
                    className={`primatybutton w-inline-block ${role != "Admin" || isDisableTeachTori == true
                      ? "disable_btn"
                      : ""
                      }`}
                    style={{ marginLeft: "10px" }}
                    onClick={TeachToriHandle}
                    disabled={
                      loadingTori ||
                      role !== "Admin" ||
                      isDisableTeachTori == true
                    }
                  >
                    {loadingTori == true ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Teach Tori"
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row align-items-center" style={{ marginTop: "20px" }}>
          <div
            style={{
              marginLeft: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
            className="col-10"
          >
            <div className="sub_progress">
              <div>
                <Progress
                  trailColor={"#6373814d"}
                  strokeColor={
                    stepDraftColor == "Draft"
                      ? "#286EB4"
                      : stepDraftColor == "Request"
                        ? "#75BE43"
                        : stepDraftColor == "Editing"
                          ? "#75BE43"
                          : stepDraftColor == "Teach Tori"
                            ? "75BE43"
                            : ""
                  }
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={stepDraftColor ? 100 : 0}
                />
              </div>
              <div className="progress_step">
                Step 1:{" "}
                {videoType == "task" || videoType == "explainer"
                  ? "In-Take"
                  : "Draft"}
              </div>
              <div className="progress_para">
                {videoType == "task" || videoType == "explainer"
                  ? "In-take form for requested script"
                  : "Preliminary version of requested script"}
              </div>
            </div>
            <div className="sub_progress">
              <div>
                <Progress
                  trailColor={"#6373814d"}
                  strokeColor={
                    stepDraftColor == "Request"
                      ? "#286EB4"
                      : stepDraftColor == "Editing"
                        ? "#75BE43"
                        : stepDraftColor == "Teach Tori"
                          ? "#75BE43"
                          : stepDraftColor == "Trained"
                            ? "#75BE43"
                            : ""
                  }
                  showInfo={false}
                  strokeLinecap="butt"
                  percent={
                    stepDraftColor == "Request" ||
                      stepDraftColor == "Editing" ||
                      stepDraftColor == "Teach Tori" ||
                      stepDraftColor == "Trained"
                      ? 100
                      : 0
                  }
                />
              </div>
              <div className="progress_step">Step 2: Request</div>
              <div className="progress_para">
                Once the DHL owner approves, the Digital Factory will start the
                planning process.
              </div>
            </div>
            <div className="sub_progress">
              <div>
                <Progress
                  trailColor={"#6373814d"}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor={
                    stepDraftColor == "Editing"
                      ? "#286EB4"
                      : stepDraftColor == "Teach Tori"
                        ? "#75BE43"
                        : stepDraftColor == "Trained"
                          ? "#75BE43"
                          : ""
                  }
                  percent={
                    stepDraftColor == "Editing" ||
                      stepDraftColor == "Teach Tori" ||
                      stepDraftColor === "Trained"
                      ? 100
                      : 0
                  }
                />
              </div>
              <div className="progress_step">Step 3: Editing</div>
              <div className="progress_para">
                {videoType == "task" || videoType == "explainer"
                  ? "Please gather any relevant materials, PPT or videos, and bring them to the Digital Factory for use in script writing."
                  : "Download and edit the script, the final recommendations can be added here."}
              </div>
            </div>
            <div className="sub_progress">
              <div>
                <Progress
                  trailColor={"#6373814d"}
                  showInfo={false}
                  strokeLinecap="butt"
                  strokeColor={
                    stepDraftColor == "Teach Tori"
                      ? "#286EB4"
                      : stepDraftColor == "Trained"
                        ? "#75BE43"
                        : ""
                  }
                  percent={
                    stepDraftColor == "Teach Tori" ||
                      stepDraftColor == "Trained"
                      ? 100
                      : 0
                  }
                />
              </div>
              <div className="progress_step">Step 4: Teach Tori</div>
              <div className="progress_para">
                {videoType == "task" || videoType == "explainer"
                  ? "Tori will be trained on the approved script and the video production process will begin."
                  : "Approved scripts can be taught to Tori and start the video production process."}
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <ApprovDHLModal
        id={id}
        industry={Indursty}
        valueLO={approveLo}
        handleCancel={closeModal}
        open={openDHLModal}
        hubleadDetail={hubleadDetail}
        removeHublead={removeHublead}
        hubleadDetailData={hubleadDetailData}
        changeHublead={changeHublead}
        userId={userId}
        handleApprovalClose={handleApprovalClose}
        handleCancelSme={handleCancelSme}
        handleCancelHublead={handleCancelHublead}
        AddEmail={AddEmail}
        RemoveEmail={RemoveEmail}
        emails={emails}
      />
      <RejectRequestModal
        open={openRejectRequestModal}
        handleCancel={() => setOpenRejectRequestModal(false)}
        id={id}
        RequestToDraftState={RequestToDraftState}
        EditingToRequestState={EditingToRequestState}
        draftOwner={draftCreatedBy}
        draftState={stepDraftColor}
        assignedHubleads={assignedHubleads}
        rejectRequestFromAdmin={rejectRequestFromAdmin}
      />
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isLoadReqMsg}
        onClose={() => {
          setisLoadReqMsg(false);
        }}
        modalMsg={loadReqMsg}
      />
      <DisplayModal
        open={isLobReqMsg}
        onClose={() => {
          setisLobReqMsg(false);
        }}
        modalMsg={lobReqMsg}
      />
    </section>
  );
}
