import { Checkbox, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import "./ToriChat.css";
import { Avatar } from "antd";
// import imageLogo from "../../assets/testLogonTori.png";
import imageLogo from "../../assets/logo.png";
import { SaveToriChatService } from "../../Services/Service";
import AdditionalInfoModal from "./AdditionalInfoModal";
import Accordion from "react-bootstrap/Accordion";
import { AdditionalInfoWithLinkOrQuery } from "../../Service";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import Logo from "../../assets/chatlogo.png";
// import Logo from "../../assets/testLogonTori.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "26px",
    width: "60%",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "unset",
  },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ComparisonChatModal = ({
  modalIsOpen,
  closeModal,
  disLikeQuestion = {},
  newChatMsg = [],
  changeStateFromChildToParent,
  multipleResp,
  multipleRespArrayProducts,
}) => {
  console.log("🚀 ~ disLikeQuestion:", disLikeQuestion)
  const userName = JSON.parse(localStorage.getItem("userData"));

  const [openAdditionalModal, setOpenAdditionalModal] = useState(false);
  const [closeAdditionalModal, setCloseAdditional] = useState(false);
  // const [newChatMsg, setNewChatMsg] = useState([]);
  const [chatMsg, setChatMsg] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  let [emptyArray, setEmptyArray] = useState([0, 0, 0, 0, 0]);
  let [multipleRespArray, setMultipleRespArray] = useState([0, 0, 0, 0, 0]);

  const handleNewAnswerAdded = () => {
    changeStateFromChildToParent(disLikeQuestion, newChatMsg);
    closeModal();
  };

  function closeModalComapre() {
    setOpenAdditionalModal(false);
  }
  let another;
  const handleMultipleByUrl = (url, index, productName) => {
    console.log(index);

    if (emptyArray[index] === 1) return 1;
    if (multipleRespArray[index] === 1) return 1;

    setChatMsg("");
    setChatLoading(true);

    let baseUrlModal =
      "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/url";
    let modal = "link";
    AdditionalInfoWithLinkOrQuery(
      baseUrlModal,
      disLikeQuestion?.question + " " + productName,
      url,
      modal
    )
      .then((res) => {
        // console.log("🚀 ~ file: ComparisonChatModal.js:72 ~ .then ~ res:", res);
        setChatLoading(true);
        if (res.text) {
          setEmptyArray(
            emptyArray.map((obj, i) => {
              if (i === index) {
                return 1;
              } else return obj;
            })
          );
          setChatLoading(false);
        }

        setMultipleRespArray(
          multipleRespArray.map((itm, ind) => {
            console.log("itm::>", itm);
            if (ind === index) return (multipleRespArray[index] = res.text);
            else return itm;
          })
        );

        setChatMsg(res.text);

        // if (modal === "link") {
        //   changeStateFromChildToParent(disLikeQuestion, res, response);
        // } else {
        //   changeStateFromChildToParent(disLikeQuestion, res);
        // }
        // setNewChatMsg(data.text || data?.responceText);
        // setIsOpenComapre(true);
        // closeModal();

        // setHideTextArea(false);
        // setHideInput(false);
        // setLinkError(false);
      })
      .catch((err) => {
        setLinkError(true);
        setChatLoading(false);
        if (err.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
    emptyArray[index] = 1;
  };
  console.log("multipleRespArray::>", emptyArray);
  console.log("multipleRespArray::>", multipleRespArray);

  const handleSubmitAnswer = (index, refernceText) => {
    let data = {
      text: multipleRespArray[index],
    };
    changeStateFromChildToParent(disLikeQuestion, data, refernceText);
    handleClose();
  };

  console.log(
    "🚀 ~ file: ComparisonChatModal.js:95 ~ .then ~ chatMsg:",
    newChatMsg
  );

  const handleClose = () => {
    closeModal();
    setMultipleRespArray([0, 0, 0, 0, 0]);
    setEmptyArray([0, 0, 0, 0, 0]);
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {multipleResp ? (
          <div>
            {chatLoading && (
              <div className="overlay">
                <div
                  style={{
                    height: "90vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img width={150} height={150} src={LoadingSpinner} />
                </div>
              </div>
            )}
            <Stack
              direction={"row"}
              spacing={1.5}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img
                src={Logo}
                alt="img not found"
                style={{ width: "80px", height: "80px" }}
              />
              {/*<Typography variant="h5" fontWeight={600} fontSize={18}>
                Pick the best answer to improve the model
        </Typography>*/}
              <AiOutlineClose
                onClick={handleClose}
                style={{
                  fontSize: 18,
                  cursor: "pointer",
                  position: "absolute",
                  top: 25,
                  right: 20,
                }}
              />
            </Stack>
            <Typography
              variant="h5"
              fontWeight={600}
              fontSize={20}
              marginTop={1}
              textAlign={"center"}
            >
              Multiple Responses
            </Typography>
            <div
              style={{
                border: "1px solid #d9d9e3",
                padding: "25px",
                borderRadius: "5px",
                margin: " 20px 0px",
              }}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Avatar
                  style={{
                    backgroundColor: "#E7AE3C",
                    cursor: "pointer",
                  }}
                >
                  {userName &&
                    userName.name
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()}
                </Avatar>
                <Typography
                  variant="h5"
                  fontWeight={500}
                  fontSize={17}
                  marginTop={1}
                >
                  {disLikeQuestion?.question}
                </Typography>
              </Stack>
            </div>
            <Accordion
              style={{ maxHeight: "30vh", overflowY: "scroll" }}
              disabled={true}
            >
              {multipleRespArrayProducts?.map((itm, index) => (
                // <ul>
                //   <li>
                //     <a href={itm[1]}>{itm[0]}</a>
                //   </li>
                // </ul>

                <Accordion.Item eventKey={index}>
                  <Accordion.Header
                    onClick={() => handleMultipleByUrl(itm[1], index, itm[0])}
                  >
                    <Typography
                      variant="p"
                      fontWeight={600}
                      fontSize={15}
                      marginTop={1}
                    >
                      {itm[0]}
                    </Typography>
                  </Accordion.Header>
                  <Accordion.Body
                    style={{ backgroundColor: "#F7F7F8" }}
                    aria-disabled={true}
                  >
                    <Typography
                      // style={{ maxHeight: "25vh", overflowY: "scroll" }}
                      variant="p"
                      fontWeight={500}
                      fontSize={15}
                      marginTop={1}
                    >
                      {multipleRespArray[index] === 0
                        ? null
                        : multipleRespArray[index]}
                    </Typography>
                    <br />
                    {chatLoading ? (
                      <div style={{ marginTop: "30px" }}></div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          style={{
                            padding: "7px 10px",
                            backgroundColor: "#1A7F64",
                            color: "white",
                            borderRadius: "5px",
                            marginTop: "7px",
                          }}
                          disabled={
                            multipleRespArray[index] === 0 ? true : false
                          }
                          onClick={() => handleSubmitAnswer(index, itm[1])}
                        >
                          This Answer is better
                        </button>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ) : (
          <div>
            <Stack
              direction={"row"}
              spacing={1.5}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h5" fontWeight={600} fontSize={18}>
                Pick the best answer to improve the model
              </Typography>
              <AiOutlineClose
                onClick={closeModal}
                style={{
                  fontSize: 18,
                  cursor: "pointer",
                  position: "absolute",
                  top: 25,
                  right: 20,
                }}
              />
            </Stack>
            <Typography
              variant="h5"
              fontWeight={500}
              fontSize={17}
              marginTop={1}
              textAlign={"center"}
            >
              The conversation will continue with the answer you choose.
            </Typography>
            <div
              style={{
                border: "1px solid #d9d9e3",
                padding: "25px",
                borderRadius: "5px",
                marginTop: "20px",
              }}
            >
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Avatar
                  style={{
                    backgroundColor: "#E7AE3C",
                    cursor: "pointer",
                  }}
                >
                  {userName &&
                    userName.name
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()}
                </Avatar>
                <Typography
                  variant="h5"
                  fontWeight={500}
                  fontSize={17}
                  marginTop={1}
                >
                  {disLikeQuestion?.question}
                </Typography>
              </Stack>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <div className="new_answer_box" style={{ width: "48%" }}>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    fontSize={15}
                    marginTop={1}
                  >
                    Original Answer
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #d9d9e3",

                      marginTop: "10px",
                      borderRadius: "5px",
                      padding: "20px",
                      backgroundColor: "#F7F7F8",
                    }}
                  >
                    <Stack direction={"row"} spacing={3}>
                      <img
                        src={imageLogo}
                        alt="imageLogo"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <Typography
                        style={{ maxHeight: "25vh", overflowY: "scroll" }}
                        variant="p"
                        fontWeight={500}
                        fontSize={15}
                        marginTop={1}
                      >
                        {disLikeQuestion?.answer || disLikeQuestion?.text}
                        <br></br>
                        <br></br>
                        {disLikeQuestion?.disclaimer}
                      </Typography>


                    </Stack>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "7px 10px",
                          backgroundColor: "grey",
                          color: "white",
                          borderRadius: "5px",
                        }}
                        onClick={closeModal}
                      >
                        Orignal Answer is better
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="original_answer_box"
                  style={{
                    width: "48%",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    fontSize={15}
                    marginTop={1}
                  >
                    New Answer
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #d9d9e3",

                      marginTop: "10px",
                      borderRadius: "5px",
                      padding: "20px",
                      backgroundColor: "#EFF7FF",
                    }}
                  >
                    <Stack direction={"row"} spacing={3}>
                      <img
                        src={imageLogo}
                        alt="imageLogo"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <Typography
                        variant="p"
                        fontWeight={500}
                        fontSize={15}
                        marginTop={1}
                        style={{ maxHeight: "25vh", overflowY: "scroll" }}
                      >
                        {newChatMsg?.answer}
                        <br></br>
                        <br></br>
                        {newChatMsg?.disclaimer}
                      </Typography>
                    </Stack>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      <button
                        style={{
                          padding: "7px 10px",
                          backgroundColor: "#1A7F64",
                          color: "white",
                          borderRadius: "5px",
                        }}
                        onClick={handleNewAnswerAdded}
                      >
                        New Answer is better
                      </button>
                    </div>
                  </div>
                </div>
              </Stack>
            </div>

            <Stack
              justifyContent={"end"}
              direction={"row"}
              marginTop={5}
              spacing={1}
            >
              <button
                style={{
                  padding: "8px 15px",
                  backgroundColor: "#DD362B",
                  color: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setOpenAdditionalModal(true);
                  closeModal();
                }}
              >
                Neither answer is better
              </button>
              {/* <button style={{ borderRadius: "5px" }} onClick={closeModal}>
              Skip this step
            </button>*/}
            </Stack>
          </div>
        )}
      </Modal>
      <AdditionalInfoModal
        modalIsOpen={openAdditionalModal}
        closeModal={closeModalComapre}
        disLikeQuestion={disLikeQuestion}
        // newChatMsg={newChatMsg}
        changeStateFromChildToParent={changeStateFromChildToParent}
      />
    </div>
  );
};

export default ComparisonChatModal;
