import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Space } from "antd";
import "./approvedhl.css";
import DraftSelect from "../../../Page1/Selects/DraftSelect";
import plus from "../../../assets/appPlus.png";
import Caleder from "../../../assets/calender.svg";
import { ApprovalRequest } from "../../../Services/Service";
import moment from "moment";
import DisplayModal from "../Components/Modal";
import { Stack, Typography } from "@mui/material";
export default function ApprovDHLModal({
  id,
  open = null,
  handleCancel = null,
  industry,
  valueLO,
  hubleadDetail,
  hubleadDetailData,
  removeHublead,
  changeHublead,
  userId,
  handleApprovalClose,
  handleCancelSme,
  handleCancelHublead,
  AddEmail,
  RemoveEmail,
  emails,
}) {
  const loggedInUser = JSON.parse(localStorage.getItem("userData"));
  const [deliveryDate, setDeliveryDate] = useState();
  const [approvalComments, setapprovalComments] = useState();
  const [loading, setloading] = useState(false);
  const [proEr, setproEr] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const isHubleadSelected =
    loggedInUser &&
    hubleadDetail &&
    hubleadDetail.filter((user) => user.label === loggedInUser.email);

  const hubleads =
    hubleadDetail && hubleadDetail.filter((user) => user.label !== "");
  const onChange = (date, dateString) => {
    setDeliveryDate(dateString);
    setproEr(dateString ? false : true);
  };

  const handleComment = (e) => {
    setapprovalComments(e.target.value);
  };
  const sendApproval = () => {
    deliveryDate && setloading(true);
    setproEr(deliveryDate ? false : true);
    deliveryDate &&
      ApprovalRequest(hubleads, "Request", id, deliveryDate, approvalComments)
        .then((res) => {
          if (res.status) {
            handleApprovalClose();
            handleCancelSme();
            setloading(false);
            setapprovalComments("");
            setDeliveryDate();
          } else {
            setloading(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
            handleApprovalClose();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
  };
  const sendApprovalOwner = () => {
    deliveryDate && setloading(true);
    const hubleads = hubleadDetail.filter((user) => user.label !== "");
    setproEr(deliveryDate ? false : true);
    deliveryDate &&
      ApprovalRequest(hubleads, "Editing", id, deliveryDate, approvalComments)
        .then((res) => {
          if (res.status) {
            setloading(false);
            handleApprovalClose();
            handleCancelHublead();
            setapprovalComments("");
            setDeliveryDate();
          } else {
            setloading(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
            handleApprovalClose();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
  };
  return (
    <div className="modal_approval">
      <Modal
        centered
        open={open}
        width={594}
        zIndex={999}
        footer={
          [
            // <button
            //   style={{ marginRight: "12px", height: "35px !important" }}
            //   className="dhl-modal-cancel w-inline-block"
            //   onClick={handleCancel}
            //   disabled={loading}
            // >
            //   Cancel
            // </button>,
            // loggedInUser &&
            // loggedInUser.id === userId &&
            // loggedInUser.role == "Hub Lead" &&
            // hubleads &&
            // hubleads.length == 1 &&
            // isHubleadSelected &&
            // isHubleadSelected.length == 1 ? (
            //   <button
            //     style={{ marginBottom: "25px", marginRight: "24px" }}
            //     className="primatybutton w-inline-block"
            //     onClick={sendApprovalOwner}
            //     disabled={loading}
            //   >
            //     {loading == true ? (
            //       <div class="d-flex justify-content-center">
            //         <div class="spinner-border" role="status">
            //           <span class="sr-only">Loading...</span>
            //         </div>
            //       </div>
            //     ) : (
            //       "Approve Request"
            //     )}
            //   </button>
            // ) : (
            //   <button
            //     style={{ marginBottom: "25px", marginRight: "24px" }}
            //     className={`primatybutton w-inline-block ${
            //       emails && emails.length == 0 ? "disable_btn" : ""
            //     }`}
            //     onClick={sendApproval}
            //     disabled={(emails && emails.length == 0) || loading}
            //   >
            //     {loading == true ? (
            //       <div class="d-flex justify-content-center">
            //         <div class="spinner-border" role="status">
            //           <span class="sr-only">Loading...</span>
            //         </div>
            //       </div>
            //     ) : (
            //       "Send Request"
            //     )}
            //   </button>
            // ),
          ]
        }
        onCancel={() => !loading && handleCancel()}
        bodyStyle={{ padding: 24, borderRadius: "6px" }}
      >
        <div className="approval_modal_h1">
          Request DHL Approval for Digital Factory
        </div>
        <div className="approval_modal_h2">
          *Desired Delivery Date for Video
        </div>
        <div>
          <Space direction="vertical">
            <DatePicker
              style={{
                width: "320px",
                height: "40px",
                borderRadius: "8px",
                border: "1px solid #919eab52",
              }}
              suffixIcon={<img src={Caleder} />}
              onChange={onChange}
              // value={deliveryDate}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
            />
          </Space>
        </div>
        {proEr && <div style={{ color: "red" }}>This field is required</div>}
        <div className="approval_modal_h2">*Digital Hub Leaders</div>
        <div className="approval_modal_h3">
          We auto-assigned DHLs based on your draft request
        </div>
        <div className="approve_modal_input_container">
          <div className="approve_modal_inputs1">
            <div className="approve_input1">
              <Stack
                justifyContent={"center"}
                padding="7px"
                width={"inherit"}
                border={"1px solid rgba(145, 158, 171, 0.32)"}
                height="auto"
                borderRadius={"7px"}
                color="#bfbfbf"
              >
                <Typography style={{ fontSize: "12px" }}>{industry}</Typography>
              </Stack>
            </div>
            <div className="approve_input1">
              <Stack
                justifyContent={"center"}
                padding="7px"
                spacing={0.5}
                width={"inherit"}
                border={"1px solid rgba(145, 158, 171, 0.32)"}
                height="auto"
                borderRadius={"7px"}
                fontSize={13}
                color="#bfbfbf"
              >
                {valueLO &&
                  valueLO.map((lo) => (
                    <button
                      style={{
                        border: "1px solid #d9d9d9",
                        width: "fit-content",
                      }}
                    >
                      {lo && lo.label}
                    </button>
                  ))}
              </Stack>
            </div>
          </div>
          <div className="approve_modal_email_container">
            {hubleadDetail &&
              hubleadDetail.length > 0 &&
              hubleadDetail.map((user, i) => (
                <div>
                  {user.label ? (
                    <div className="approve_ad">
                      <div>
                        <DraftSelect
                          placeholder="Select Hub Lead"
                          onChange={changeHublead}
                          options={hubleadDetailData}
                          defaultValue={user}
                          disabled={false}
                          width={160}
                          fontSize={12}
                          index={i}
                        />
                      </div>
                      {hubleadDetail.length == i + 1 &&
                        hubleadDetail.length < 5 && (
                          <div
                            style={{ marginLeft: 10 }}
                            onClick={() => AddEmail(i)}
                          >
                            <img
                              src={plus}
                              style={{
                                cursor: "pointer",
                                width: 20,
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="approve_ad">
                      <div>
                        <DraftSelect
                          placeholder="Select Hub Lead"
                          onChange={changeHublead}
                          options={hubleadDetailData}
                          disabled={false}
                          width={160}
                          fontSize={12}
                          index={i}
                        />
                      </div>
                      {hubleadDetail.length == i + 1 &&
                        hubleadDetail.length < 5 && (
                          <div
                            style={{ marginLeft: 10 }}
                            onClick={() => AddEmail(i)}
                          >
                            <img
                              src={plus}
                              style={{
                                cursor: "pointer",
                                width: 20,
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  )}

                  <div className="approve_mail_btn">
                    <button
                      onClick={() => {
                        removeHublead(user.value, i);
                        RemoveEmail(i);
                      }}
                      className="approve_mail_btn_remove"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            {hubleadDetail && hubleadDetail.length == 0 && (
              <>
                <div className="approve_ad">
                  <div>
                    <DraftSelect
                      placeholder="Select Hub Lead"
                      onChange={changeHublead}
                      options={hubleadDetailData}
                      disabled={false}
                      width={160}
                      fontSize={12}
                      index={0}
                    />
                  </div>

                  <div
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      if (!hubleadDetail.length == 0) {
                        AddEmail(0);
                      }
                    }}
                  >
                    <img
                      src={plus}
                      style={{
                        cursor: "pointer",
                        width: 20,
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
                <div className="approve_mail_btn">
                  <button
                    onClick={() => removeHublead(user.value, i)}
                    className="approve_mail_btn_remove"
                    disabled={true}
                  >
                    Remove
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="approval_modal_h2">Add Comment</div>
        <div className="approval_modal_comment_container">
          <textarea
            placeholder="Add Comments.."
            rows={4}
            className="approve_modal_comment_box"
            value={approvalComments}
            onChange={handleComment}
          />
        </div>
        <div className="approve_modal_h2 margin_top">What Happens Next?</div>
        <div className="approve_modal_msg">
          The Digital Hub Lead will need to review and approve the request. The
          Digital-Factory will be notified of the request but will only take
          action until the hub lead approves.
        </div>
        {hubleadDetailData &&
        hubleadDetailData.filter((user) => user.value == userId).length >= 1 ? (
          <div className="approve_footer_msg">
            <span style={{ fontWeight: "700" }}>You are the Owner! </span> Your
            request will automatically be approved and conitnue to editing.
          </div>
        ) : (
          ""
        )}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="end"
          spacing={0}
          marginTop={3}
        >
          <button
            style={{ marginRight: "12px", height: "35px !important" }}
            className="dhl-modal-cancel w-inline-block"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </button>
          {loggedInUser &&
          loggedInUser.id === userId &&
          loggedInUser.role == "Global Hub Content Lead" &&
          hubleads &&
          hubleads.length == 1 &&
          isHubleadSelected &&
          isHubleadSelected.length == 1 ? (
            <button
              className="primatybutton w-inline-block"
              onClick={sendApprovalOwner}
              disabled={loading}
            >
              {loading == true ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                "Approve Request"
              )}
            </button>
          ) : (
            <button
              className={`primatybutton w-inline-block ${
                emails && emails.length == 0 ? "disable_btn" : ""
              }`}
              onClick={sendApproval}
              disabled={(emails && emails.length == 0) || loading}
            >
              {loading == true ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                "Send Request"
              )}
            </button>
          )}
        </Stack>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
    </div>
  );
}
