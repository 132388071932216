import React, { useState } from "react";
import { dummyData } from "../../../DummyData";
import Tooltip from "@mui/material/Tooltip";
import info from "../../../assets/info.svg";
import plus from "../../../assets/appPlus.png";
import Minus from "../../../assets/minus.png";
import arrowIcon from "../../../assets/arrowIcon.png";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../../environment";
import { Stack, Typography } from "@mui/material";
import InputField from "../../../inputfield/InputField";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { useHistory } from "react-router-dom";

export default function ProductName({
  title,
  disabledInputs,
  pName,
  onChangePname,
  selectTextPN,
  ProductUrl,
  onChangeProductUrl,
  placeholder,
  CallingSaveDraftAPI,
  Icon,
  proEr,
  ShowProduct,
  placeholder1,
  ShowPlusBtn,
  onBlur,
  showLinkIcon = false,
}) {
  console.log("🚀 ~ file: ProductName.js:32 ~ disabledInputs:", disabledInputs);
  let tree = dummyData;
  const history = useHistory();
  const [searchBox, setSearchBox] = useState(false);
  const [dummyArr, setDummyArr] = useState(tree);

  const onFocusProductName = () => {
    setSearchBox(true);
    setDummyArr(tree);
  };
  const onFoucusOut = () => {
    setTimeout(() => {
      setSearchBox(false);
    }, 200);
    onBlur(true);
  };
  function filter(array, text) {
    const getNodes = (result, object) => {
      if (object.text.toLowerCase().includes(text)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.nodes)) {
        const nodes = object.nodes.reduce(getNodes, []);
        if (nodes.length) result.push({ ...object, nodes });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }
  const handleonChangePname = (e) => {
    onChangePname(e);
    let val = e.target.value.toLowerCase();
    let filteredArray = filter(tree, val);
    setDummyArr(filteredArray);
    setSearchBox(true);
    if (filteredArray.length == 0) {
      setSearchBox(false);
    }
  };
  let baseUrl = window.location.origin;
  return (
    <div>
      <div>
        {title && (
          <div className="row">
            <div className="col-6 p-0">
              <div style={{ display: "flex", marginBottom: 8 }}>
                <span className="pName">Product Name</span>
                <Tooltip
                  arrow
                  placement="right"
                  title={<Typography fontSize={14}>{title}</Typography>}
                >
                  <img
                    style={{
                      marginLeft: 5,
                      width: 20,
                    }}
                    src={info}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        )}

        <Stack direction="row">
          <div style={{ width: "50%" }}>
            <div className="top_input">
              <GrammarlyEditorPlugin clientId={REACT_APP_GRAMMARLY_CLIENT_ID}>
                <input
                  onFocus={onFocusProductName}
                  onBlur={onFoucusOut}
                  disabled={disabledInputs}
                  onChange={handleonChangePname}
                  className="InputFields"
                  placeholder={placeholder1}
                  type="text"
                  value={pName === null ? "" : pName}
                />
              </GrammarlyEditorPlugin>
              {proEr && (
                <div style={{ color: "red" }}>This field is required</div>
              )}
              {searchBox && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "99",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    paddingBlock: 8,
                    maxHeight: 350,
                    maxWidth: "19.8%",
                    width: "26%",
                    overflow: "auto",
                    marginTop: "1px",
                    borderRadius: "4px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  {dummyArr &&
                    dummyArr.map((row) => {
                      console.log("🚀 ~ dummyArr.map ~ row:", row)
                      return (
                        <div>
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              marginBottom: 3,
                              paddingLeft: 8,
                            }}
                          >
                            {row.text}
                          </p>
                          {
                            <div>
                              {row.nodes &&
                                row.nodes.map((i) => {
                                  console.log("🚀 ~ i:", i.isSelected)

                                  return (
                                    <div>
                                      {
                                        <p
                                          onClick={
                                            i.isSelected
                                              ? () => selectTextPN(i.text)
                                              // ? () => alert("first")
                                              : undefined
                                          }
                                          className={
                                            i.isSelected && i.text == pName
                                              ? "selected_product"
                                              : i.isSelected
                                                ? "selected_text2"
                                                : "selected_text"
                                          }
                                        >
                                          {i.text}
                                        </p>
                                      }
                                      {i.nodes &&
                                        i.nodes.map((e) => {
                                          // console.log("🚀 ~ dummyArr.map ~ e:", e)

                                          return (
                                            <div>
                                              <p
                                                onClick={
                                                  e.isSelected
                                                    ? () => selectTextPN(e.text)
                                                    // ? () => alert("second")
                                                    : ""
                                                }
                                                className={
                                                  e.isSelected && e.text == pName
                                                    ? "selected_product"
                                                    : e.isSelected
                                                      ? "selected_text4"
                                                      : "selected_text3"
                                                }
                                              >
                                                {e.text}
                                              </p>

                                              {e.nodes &&
                                                e?.nodes.map((j) => {
                                                  return (
                                                    <div>
                                                      <p
                                                        onClick={
                                                          j.isSelected
                                                            // ? () => selectTextPN(j.text)
                                                            ? () => alert("third")
                                                            : ""
                                                        }
                                                        className={
                                                          j.isSelected
                                                            ? "selected_text6"
                                                            : "selected_text5"
                                                        }
                                                      >
                                                        {j.text}
                                                      </p>
                                                      {j.nodes &&
                                                        j.nodes.map((l) => (
                                                          <p
                                                            onClick={() =>
                                                              selectTextPN(l.text)
                                                            }
                                                            style={{
                                                              paddingLeft: 100,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {l.text}
                                                          </p>
                                                        ))}
                                                    </div>
                                                  )
                                                })}
                                            </div>
                                          )
                                        })}
                                    </div>
                                  )
                                })}
                            </div>
                          }
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ marginLeft: 15 }}>
              <div
                className={
                  Icon == "minus"
                    ? "top_input col-12 d-flex align-items-center"
                    : "top_input col-12 d-flex justify-content-between align-items-center"
                }
              >
                <div style={{ width: "65%" }}>
                  <InputField
                    className="col-9 InputFields"
                    isShowLabel={false}
                    value={ProductUrl === null ? "" : ProductUrl}
                    onChange={onChangeProductUrl}
                    placeholder={placeholder}
                    onBlur={() => onBlur(false)}
                    isDisabled={disabledInputs}
                  />
                </div>
                {ShowPlusBtn && (
                  <div style={{ marginLeft: 10 }}>
                    <img
                      onClick={ShowProduct}
                      src={Icon == "plus" ? plus : Minus}
                      style={{
                        cursor: "pointer",
                        width: 24,
                        height: 24,
                      }}
                    />
                  </div>
                )}
                {showLinkIcon && (
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      <Typography fontSize={14}>
                        Product Information Retrieval
                      </Typography>
                    }
                  >
                    <a
                      target="_blank"
                      href={`${baseUrl}/draft/productInfoRetrievel`}
                    >
                      <div
                        // onClick={() =>
                        //   history.push({pathname:"/draft/productInfoRetrievel",state:ProductUrl})
                        // }
                        className="arrow-icon"
                        style={{
                          backgroundColor: "#1371dc",
                          width: 35,
                          height: 26,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 4,
                          marginLeft: 10,
                        }}
                      >
                        <img style={{ width: 15 }} src={arrowIcon} />
                      </div>
                    </a>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Stack>
        {/* {ends here} */}
      </div>
    </div>
  );
}
