import { Stack, Tooltip, Typography } from "@mui/material";
import React, { useState, useMemo } from "react";
import {
  businessOutcomsTooltip,
  demoTooltip,
  roleTooltip,
} from "../../../../utils/tooltips/Tooltips";
import info from "../../../assets/info.svg";
import BtnPlus from "../../../assets/icons8-plus-48.png";
import plus from "../../../assets/appPlus.png";
import Minus from "../../../assets/minus.png";
import InputField from "../../../inputfield/InputField";
import BusinessOutcomeDropdown from "../../CreateDraft/Components/BusinessOutcomeDropdown";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../../environment";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import DisplayModal from "../../CreateDraft/Components/Modal";
import DatalistInput from "react-datalist-input";
import "react-datalist-input/dist/styles.css";
import { BODropdownData } from "../../../../utils/lists/CreateDraftLists";
export default function ExplainerDemoOptions({
  showBusiness,
  demoError,
  businessLabel,
  businessValue,
  businessValue2,
  businessValue3,
  onBlurBO,
  onBlurBO2,
  onBlurBO3,
  businessOnchange,
  businessOnchange2,
  businessOnchange3,
  selectTextBO,
  selectTextBO2,
  selectTextBO3,
  businessOutcomeDropdownData,
  businessPlaceholder,
  businessError,
  businessOutcomeArray = [],
  onchangeBOArray,
  addBusinessOutcome,
  removeBusinessOutcome,
  demoOnfocus,
  disabledInputsOnNext,
  demoDisable,
  demoPlaceholder,
  demoValue,
  demoOnchange,
  NewDemoFilArrDiv,
  NewDemoFilArr,
  selectNewDemo,
  finishClick,
  isGenerateDemoScript1,
  loading,
  demoNo,
  isSkipDemo,
  isBack,
  roleLabel,
  onBlurRole,
  roleValue,
  roleOnchange,
  rolePlaceholder,
  regenerateDemo,
  onClickGenerateDemo,
  onClickRegenerateDemo,
  showDemoOptions,
  optionsName,
  onChangeOpt1,
  onChangeOpt2,
  opt1Val,
  opt1Check,
  opt1Id,
  opt2Id,
  onChangeOpt1Check,
  opt2Val,
  bottomRef,
  opt2Check,
  onChangeOpt2Check,
  showAddtoscript,
  showGenerateintro,
  onClickAddtoScript,
  CallingSaveDraftAPI,
  showButtons,
  onBackClick,
  onSkipClick,
  onClickGenIntro,
  intro1ReGen = false,
  regenLoading = false,
  lastLoading = false,
  onClickReGenintro,
  disableAllinputs,
  disableSaveButton,
  fLoading,
  isDisableDemoScript = false,
  isShowRole = false,
  disable = false,
  draftState,
}) {
  const [modal14, setmodal14] = useState(false);
  const [modalMsg14, setModalMsg14] = useState("");
  const onImgClick = () => {
    setmodal14(true);
    setModalMsg14("Coming Soon");
  };
  const items = useMemo(
    () =>
      BODropdownData.map((option) => ({
        id: option.text,
        value: option.text,
        ...option,
      })),
    []
  );
  return (
    <div style={{ paddingBottom: 3 }}>
      {showBusiness && (
        <div>
          {/* Business Outcome 1 */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="row">
                <div className="col-12 p-0">
                  <div style={{ display: "flex", marginBottom: 13 }}>
                    <span className="pName">Business Outcome 1</span>
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <Typography fontSize={14}>
                          {businessOutcomsTooltip}
                        </Typography>
                      }
                    >
                      <img
                        style={{
                          marginLeft: 5,
                          width: 20,
                        }}
                        src={info}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              {!disableAllinputs && (
                <Stack spacing={2}>
                  {businessOutcomeArray &&
                    businessOutcomeArray.map((x, i) => {
                      return (
                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent={"space-between"}
                        >
                          <div style={{ width: "85%" }}>
                            <DatalistInput
                              value={x.name}
                              placeholder={businessPlaceholder}
                              showLabel={false}
                              onSelect={(item) =>
                                onchangeBOArray(item.text, "name", i)
                              }
                              onChange={(e) =>
                                onchangeBOArray(e.target.value, "name", i)
                              }
                              items={items}
                            />
                          </div>
                          {businessOutcomeArray.length !== 1 && (
                            <Stack
                              style={{
                                alignSelf: "center",
                              }}
                            >
                              <img
                                onClick={() => removeBusinessOutcome(i)}
                                src={Minus}
                                style={{
                                  cursor: "pointer",
                                  width: 24,
                                  height: 24,
                                }}
                              />
                            </Stack>
                          )}
                          {businessOutcomeArray.length - 1 === i &&
                            businessOutcomeArray.length < 3 && (
                              <Stack
                                style={{
                                  alignSelf: "center",
                                }}
                              >
                                <img
                                  onClick={addBusinessOutcome}
                                  src={plus}
                                  style={{
                                    cursor: "pointer",
                                    width: 24,
                                    height: 24,
                                  }}
                                />
                              </Stack>
                            )}
                        </Stack>
                      );
                    })}
                </Stack>
              )}

              {disableAllinputs && (
                <Stack spacing={2}>
                  {businessOutcomeArray &&
                    businessOutcomeArray.map((x, i) => {
                      return (
                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent={"space-between"}
                        >
                          <div style={{ width: "100%" }}>
                            <InputField
                              className="InputFields"
                              isShowLabel={false}
                              isShowLabelIcon={false}
                              // label={roleLabel}
                              // tooltipText={buysiness}
                              onBlur={() => {}}
                              value={x.name}
                              isDisabled={disableAllinputs}
                              onChange={() => {}}
                              placeholder={businessPlaceholder}
                            />
                          </div>
                        </Stack>
                      );
                    })}
                </Stack>
              )}
            </div>
            <div className="col-1"></div>
          </div>
          {/* Role 1 */}
          {isShowRole && (
            <div className="row top_pad">
              <div className="col-1"></div>
              <div className="col-10">
                <InputField
                  className="InputFields"
                  isShowLabel={true}
                  isShowLabelIcon={true}
                  label={roleLabel}
                  tooltipText={roleTooltip}
                  onBlur={onBlurRole}
                  value={roleValue}
                  isDisabled={disableAllinputs}
                  onChange={roleOnchange}
                  placeholder={rolePlaceholder}
                />
              </div>
              <div className="col-1"></div>
            </div>
          )}
          {/* Demo 1 */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div>
                    <span className="pName">{demoNo}</span>
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <Typography fontSize={14}>{demoTooltip}</Typography>
                      }
                    >
                      <img
                        style={{
                          marginLeft: 5,
                          marginTop: -8,
                          width: 20,
                        }}
                        src={info}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="top_input"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  //   alignItems: "center",
                }}
              >
                <div style={{ width: "80%" }}>
                  <GrammarlyEditorPlugin
                    clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
                  >
                    <textarea
                      rows="3"
                      onBlur={demoOnfocus}
                      disabled={
                        disableAllinputs || disabledInputsOnNext || demoDisable
                      }
                      onChange={demoOnchange}
                      placeholder={demoPlaceholder}
                      type="text"
                      className={
                        disableAllinputs
                          ? "InputFields disable-state"
                          : "InputFields"
                      }
                      value={demoValue}
                    />
                  </GrammarlyEditorPlugin>
                  {demoError && (
                    <span style={{ color: "red" }}>This Field is required</span>
                  )}
                </div>
                <div>
                  <button
                    onClick={onImgClick}
                    style={{
                      marginLeft: 20,
                      height: 40,
                      width: 80,
                      color: "#75BE43",
                      borderRadius: 8,
                      background: "white",
                      Padding: "6px, 16px, 6px, 16px",
                      border: "1px solid #75BE43",
                      display: "flex",
                      alignItems: "center",
                    }}
                    disabled={disableAllinputs}
                  >
                    <img
                      style={{
                        marginRight: "4px",
                      }}
                      height={20}
                      width={20}
                      src={BtnPlus}
                    />
                    media
                  </button>
                </div>
                {NewDemoFilArrDiv && (
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: 20,
                      maxHeight: 180,
                      overflow: "auto",
                      marginTop: "1px",
                      borderRadius: "4px",
                    }}
                  >
                    {NewDemoFilArr.slice(0, 8).map((e) => (
                      <div>
                        <div
                          onClick={() => selectNewDemo(e.name)}
                          style={{
                            paddingTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          {e.name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          {/* {inputList.map((x, i) => {
            return (
              <div style={{display:'flex'}} className="box">
                <input
                  name="businessOutcome"
                  placeholder="Enter First Name"
                  value={x.businessOutcome}
                  onChange={(e) => handleInputChange(e, i)}
                />
                <div className="btn-box">
                  {inputList.length !== 1 && (
                    <button
                      className="mr10"
                      onClick={() => handleRemoveClick(i)}
                    >
                      Remove
                    </button>
                  )}
                  {inputList.length - 1 === i && (
                    <button onClick={handleAddClick}>Add</button>
                  )}
                </div>
              </div>
            );
          })} */}
          {/* demo  buttons */}
          {showButtons && (
            <>
              {finishClick && (
                <div id="finishClick">
                  {isGenerateDemoScript1 ? (
                    <div id="dis76546">
                      <div className="row m-0 btn_section">
                        <div className="col-11 p-0">
                          <button
                            onClick={onClickGenerateDemo}
                            className={`primatybutton w-inline-block ${
                              disableSaveButton || disableAllinputs
                                ? "disable_btn"
                                : ""
                            }`}
                            style={{
                              float: "right",
                              // width: 200,
                            }}
                            disabled={
                              disableSaveButton ||
                              disableAllinputs ||
                              lastLoading ||
                              loading
                            }
                          >
                            {loading ? (
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>Save Script</div>
                            )}
                          </button>
                          {isSkipDemo && (
                            <button
                              onClick={onSkipClick}
                              className={`primatybutton w-inline-block ${
                                disableAllinputs ? "disable_btn" : ""
                              }`}
                              disabled={disableAllinputs || lastLoading}
                              style={{
                                float: "right",
                                marginRight: 10,
                              }}
                            >
                              {lastLoading ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div>Skip Demo, Move to Intro</div>
                              )}
                            </button>
                          )}
                          {isBack && (
                            <button
                              onClick={onBackClick}
                              className={`primatybutton w-inline-block ${
                                disableAllinputs ? "disable_btn" : ""
                              }`}
                              disabled={isDisableDemoScript || disableAllinputs}
                              style={{
                                float: "right",
                                marginRight: 10,
                              }}
                            >
                              Back
                            </button>
                          )}
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  ) : (
                    <div id="dis0988">
                      {regenerateDemo && (
                        <div className="row m-0 btn_section">
                          <div className="col-2"></div>
                          <div className="col-9">
                            <button
                              onClick={onClickRegenerateDemo}
                              className={"primatybutton w-inline-block"}
                              disabled={loading || disableAllinputs}
                              style={{
                                float: "right",
                              }}
                            >
                              {loading ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div>Re Generate Demo Script</div>
                              )}
                            </button>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showDemoOptions && (
        <section id="state1">
          <div style={{ paddingBottom: "30px" }}>
            <div className="row m-0">
              <div className="col-1"></div>
              <div className="col-10">
                <div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span className="pName1">{optionsName}</span>
                  </div>
                  <div className="custom-control custom-radio">
                    <textarea
                      id={opt1Id}
                      rows="8"
                      cols="50"
                      onChange={onChangeOpt1}
                      value={opt1Val == null || "" ? "" : opt1Val}
                      className="left_from"
                      type="text"
                    ></textarea>
                    <input
                      checked={opt1Check}
                      onChange={onChangeOpt1Check}
                      disabled={loading}
                      type="checkbox"
                      className="cus_radio"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "70px",
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <div className="custom-control custom-radio">
                    <textarea
                      id={opt2Id}
                      rows="8"
                      cols="50"
                      onChange={onChangeOpt2}
                      value={opt2Val == null || "" ? "" : opt2Val}
                      className="left_from"
                      type="text"
                    ></textarea>
                    <input
                      ref={bottomRef}
                      checked={opt2Check}
                      onChange={onChangeOpt2Check}
                      disabled={loading}
                      type="checkbox"
                      className="cus_radio"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "70px",
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          {showAddtoscript && (
            <div className="row m-0 mb-5">
              <div className="col-2"></div>
              <div className="col-9">
                <button
                  id="next3None111"
                  disabled={disableAllinputs}
                  onClick={onClickAddtoScript}
                  className={`primatybutton w-inline-block ${
                    disableAllinputs ? "disable_btn" : ""
                  }`}
                  // disabled={disableAllinputs}
                  style={{
                    float: "right",
                  }}
                >
                  Add to Script
                </button>
              </div>
              <div className="col-1"></div>
            </div>
          )}
        </section>
      )}
      {(showGenerateintro || intro1ReGen) && (
        <div className="row m-0 btn_section_next show_intro">
          <div className="col-2"></div>
          <div className="col-9">
            {showGenerateintro && (
              <button
                id="next3None111"
                onClick={onClickGenIntro}
                className={`primatybutton w-inline-block ${
                  disable ? "disable_btn" : ""
                }`}
                disabled={fLoading || disable}
                style={{
                  float: "right",
                  marginTop: 30,
                }}
              >
                {fLoading ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>Generate Intro Script</div>
                )}
              </button>
            )}
            {intro1ReGen && (
              <div>
                <button
                  className={`primatybutton w-inline-block ${
                    disable ? "disable_btn" : ""
                  }`}
                  disabled={(regenLoading && lastLoading) || disable}
                  onClick={onClickReGenintro}
                  style={{ float: "right", marginTop: "30px" }}
                >
                  {regenLoading && lastLoading ? (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div> Re Generate Intro Script</div>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="col-1"></div>
        </div>
      )}

      <DisplayModal
        open={modal14}
        onClose={() => {
          setmodal14(false);
        }}
        modalMsg={modalMsg14}
      />
    </div>
  );
}
