import React, { useEffect, useRef, useState } from "react";
import Header from "../../SubComponents/MainHeader/MainHeader";
import Footer from "../../SubComponents/Footer/Footer";
import home_page_img from "../../assets/home_page_img.svg";
import homeBg from "../../assets/home_bg.png";
import arrow from "../../assets/arrow.svg";
import View from "../../assets/view.png";
import Hide from "../../assets/hide.png";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  Login,
  ForgotPass,
  RequestUser,
  RequestUserDirectLogin,
  GetUserRoles,
  LoginSignupWithMicrosoft,
} from "../../Services/Service";
import { useHistory } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-modal";
import ToriLogo from "../../assets/tori_logo.svg";
import "./css/tori-login-page.webflow.css";
import "./css/webflow.css";
import "./css/normalize.css";
import Im1 from "./images/Screen-Shot-2022-10-19-at-5.06.46-AM.png";
import Im2 from "./images/color.png";
import Im3 from "./images/Dark_1Dark.png";
import Lottie from "react-lottie";

import animateData from "./tori-data";
import "./Style.css";
import ReactSelect from "react-select";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../utils/microsoftsignin/authConfig";
import { SSO_APP_POPUP_REDIRECT_URI } from "../../../environment";

function HomeScreen(props) {
  const { instance } = useMsal();
  let history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [signInMessage, setSignInMessage] = useState();
  const [inviteErrMesg, setinviteErrMesg] = useState();
  const [loginLoader, setloginLoader] = useState(false);
  const [inviteLoader, setinviteLoader] = useState(false);
  const [isloginErr, setIsloginErr] = useState(false);
  const [isSignUpErr, setIsSignUpErr] = useState(false);
  const [forgotPass, setforgotPass] = useState();
  const [forgotLoader, setforgotLoader] = useState(false);
  const [inviteFname, setinviteFname] = useState();
  const [inviteLname, setinviteLname] = useState();
  const [inviteEmail, setinviteEmail] = useState();
  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgetPasswordMsg, setForgetPasswordMsg] = useState("");
  const [forgetPasswordN, setForgetPasswordN] = useState(false);
  const [forgetPasswordMsgN, setForgetPasswordMsgN] = useState("");
  const [invite, setInvite] = useState(false);
  const [inviteMsg, setInviteMsg] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [infoMessageModel, setInfoMessgaeModel] = useState(false);
  const [showPassTori, setShowPassTori] = useState(true);
  const [accessType, setAccessType] = useState("full");
  const [rePermission, setRePermission] = useState();
  const [roles, setRoles] = useState();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "./webflow.js";
    script.async = true;
    document.body.appendChild(script);

    const script1 = document.createElement("script");
    script1.src =
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=634fe740f7785f7c38b97ad8";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src =
      "https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script1);

      document.body.removeChild(script2);
    };
  }, []);
  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      onSignInPress();
    }
  };
  const onSignInPress = () => {
    setloginLoader(true);
    Login(email, password)
      .then((res) => {
        console.log(res);
        if (res.status) {
          let token = res.data.access_token;
          console.log("🚀 ~ file: HomeScreen.js:105 ~ .then ~ token:", token)
          let name = res.data.name;
          localStorage.setItem("token", token);
          localStorage.setItem("userName", name);
          setIsloginErr(false);
          setSignInMessage(res);
          setloginLoader(false);
          if (sessionStorage.getItem("ParemId")) {
            history.push(sessionStorage.getItem("ParemId"), {
              from: "login",
            });
          } else {
            if (res?.data?.role === "Chat User") {
              localStorage.setItem("userRole", res?.data?.role);
              history.push("/user/tori-chat/help", { from: "tori-help" });
            } else {
              history.push("/user/welcome");
            }
          }
        } else {
          setIsloginErr(true);
          setSignInMessage(res);
          setloginLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloginLoader(false);
      });
  };
  const onForgotClicl = () => {
    setforgotLoader(true);
    ForgotPass(forgotPass)
      .then((res) => {
        if (res.status) {
          // alert(res.message)
          setForgetPassword(true);
          setForgetPasswordMsg(res.message);
          setforgotLoader(false);
          setIsOpen3(false);
        } else {
          // alert(res.message)
          setForgetPasswordN(true);
          setForgetPasswordMsgN(res.message);
          setforgotLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setforgotLoader(false);
      });
  };

  const onRequestAccess = () => {
    setinviteLoader(true);
    if (sessionStorage.getItem("ParemId")) {
      RequestUserDirectLogin(inviteFname, inviteLname, inviteEmail)
        .then((res) => {
          if (res.status) {
            setinviteErrMesg(res);
            setIsSignUpErr(false);
            setinviteLoader(false);
            // alert(res.message);
            setInvite(true);
            setInviteMsg(res.message);
            setIsOpen2(false);
          } else {
            setinviteErrMesg(res);
            setIsSignUpErr(true);
            setinviteLoader(false);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setinviteLoader(false);
        });
    } else {
      let data = {
        first_name: inviteFname,
        last_name: inviteLname,
        email: inviteEmail,
        password: password,
        role: rePermission,
        access_type: accessType,
      };
      RequestUser(data)
        .then((res) => {
          if (res.status) {
            setinviteErrMesg(res);
            setIsSignUpErr(false);
            setinviteLoader(false);
            // alert(res.message);
            setInvite(true);
            setInviteMsg(res.message);
            setIsOpen2(false);
          } else {
            setinviteErrMesg(res);
            setIsSignUpErr(true);
            setinviteLoader(false);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setinviteLoader(false);
        });
    }
  };
  const showPassword = () => {
    setIsPassword(!isPassword);
  };
  const onChangeEmail = (e) => {
    let val = e.target.value;
    setEmail(val);
  };
  const onChangeEPassword = (e) => {
    let val = e.target.value;
    setPassword(val);
  };
  const onChangeForgotEmail = (e) => {
    setforgotPass(e.target.value);
  };
  const onChangeFname = (e) => {
    setinviteFname(e.target.value);
  };
  const onChangeLname = (e) => {
    setinviteLname(e.target.value);
  };
  const onChangeInviteEmail = (e) => {
    setinviteEmail(e.target.value);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "30%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  };

  const infoMessageCustomStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "40%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    setIsOpen2(false);
    setIsOpen3(false);
  }
  function openModal2() {
    setInfoMessgaeModel(false);
    setIsOpen2(true);
    setIsOpen(false);
    setIsOpen3(false);
  }
  function openModal3() {
    setIsloginErr(false);
    setIsOpen3(true);
    setIsOpen2(false);
    setIsOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
    setIsloginErr(false);
  }
  function closeModal2() {
    setIsOpen2(false);
    setIsSignUpErr(false);
    setShowPassTori(true);
  }
  function closeModal3() {
    setIsOpen3(false);
    setIsSignUpErr(false);
    setForgetPasswordN(false);
    setForgetPasswordMsgN("");
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animateData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const permissions = [
    { value: 0, label: "Admin" },
    { value: 1, label: "Editor" },
    { value: 2, label: "Hub Lead" },
    { value: 3, label: "SME" },
  ];
  const onSelectMultiPermission2 = (e) => {
    setRePermission(e.label);
  };



  const handleLogin = () => {
    instance.loginPopup({
      ...loginRequest,
      redirectUri: SSO_APP_POPUP_REDIRECT_URI, // e.g. /redirect
    });
  }


  const activeAccount = instance.getActiveAccount();

  function useDeepCompareEffect(callback, dependencies) {
    const currentDependenciesRef = useRef();

    if (!deepEqual(currentDependenciesRef.current, dependencies)) {
      currentDependenciesRef.current = dependencies;
    }

    useEffect(callback, [currentDependenciesRef.current]);
  }

  function deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  useDeepCompareEffect(() => {
    if (activeAccount) {
      console.log("🚀 ~ useEffect ~ activeAccount:", activeAccount)
      let data = {
        email: activeAccount?.username,
        name: activeAccount?.name,
        tenet_id: activeAccount?.tenantId
      }
      setloginLoader(true);
      LoginSignupWithMicrosoft(data)
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res)
          console.log(res);
          if (res.status) {
            let token = res.data.access_token;
            console.log("🚀 ~ file: HomeScreen.js:105 ~ .then ~ token:", token)
            let name = res.data.name;
            localStorage.setItem("token", token);
            localStorage.setItem("userName", name);
            setIsloginErr(false);
            setSignInMessage(res);
            setloginLoader(false);
            if (sessionStorage.getItem("ParemId")) {
              history.push(sessionStorage.getItem("ParemId"), {
                from: "login",
              });
            } else {
              if (res?.data?.role === "Chat User") {
                localStorage.setItem("userRole", res?.data?.role);
                history.push("/user/tori-chat/help", { from: "tori-help" });
              } else {
                history.push("/user/welcome");
              }
            }
          } else {
            setIsloginErr(true);
            setSignInMessage(res);
            setloginLoader(false);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloginLoader(false);
        });
      // setName(activeAccount.name.split(' ')[0]);
    } else {
    }
  }, [activeAccount]);

  return (
    <div className="home_scroll">
      <div class="body">
        <div class="section wf-section">
          <img src={Im1} loading="lazy" width={160} alt="" />
        </div>
        <div class="section-3 wf-section">
          <img src={Im2} loading="lazy" size="100vh" alt="" class="image-14" />
          <div class="columns-2 w-row">
            <div class="column w-col w-col-6 w-col-stack w-col-small-small-stack">
              <h1 style={{ fontWeight: "500" }} class="heading">
                Meet Tori, The Best Writer On Your Team. Powered With AI.
              </h1>
              <p
                data-w-id="da0642f3-8931-64a4-bec7-eaeee115abd2"
                class="paragraph"
              >
                Create meaningful, engaging and on-brand PreSales demo video
                scripts in minutes using the power of artificial intelligence.
                <span style={{ color: "#0A6ED1", fontSize: 16 }}>
                  {" "}
                  <a
                    target={"_blank"}
                    href="https://workzone.one.int.sap/site#workzone-home&/wiki/show/CUoXGETrP9BCxD5yQvhG6e?_lightbox=true"
                  >
                    Learn more
                  </a>
                </span>
              </p>
              <div class="div-block-3">
                <button
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={openModal}
                  class="primatybutton w-inline-block"
                >
                  <div class="text-block">Sign In</div>
                  <img
                    src={Im3}
                    loading="lazy"
                    width={17}
                    alt=""
                    class="image-7"
                  />
                </button>
                <button
                  style={{ display: "flex", flexDirection: "row", marginLeft: '15px' }}
                  onClick={handleLogin}
                  class="primatybutton w-inline-block"
                >
                  <div class="text-block">Logon with Single-Sign On</div>
                  <img
                    src={Im3}
                    loading="lazy"
                    width={17}
                    alt=""
                    class="image-7"
                  />
                </button>
                <a
                  onClick={openModal2}
                  style={{ marginTop: "10px" }}
                  class="secondarybutton w-inline-block"
                >
                  <div class="text-block-copy">Request Access</div>
                </a>
              </div>
            </div>
            <div class="column-2 w-col w-col-6 w-col-stack w-col-small-small-stack">
              <div class="lottie-animation">
                <Lottie
                  isClickToPauseDisabled={true}
                  onClick={(e) => e.stopPropagation()}
                  style={{ cursor: "default" }}
                  options={defaultOptions}
                  height={550}
                  width={550}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{ borderBottom: 0, padding: 40, paddingTop: 15 }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Sign into Scriptoria
            </h5>
            <button
              type="button"
              class="close"
              onClick={closeModal}
              style={{ position: "absolute", right: 25 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div>
            <p style={{ fontSize: 14, fontWeight: "700" }}>Email</p>
            <input
              onKeyPress={(e) => {
                handleKeypress(e);
              }}
              onChange={onChangeEmail}
              placeholder="name@sap.com"
              style={{
                outline: "none",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                width: "100%",
                borderBottomColor: "#80848F",
                paddingBottom: 8,
              }}
              type="text"
            />
          </div>
          <div className="mt-5">
            <p style={{ fontSize: 14, fontWeight: "700" }}>Password</p>
            <div className="input_single">
              <input
                onKeyPress={(e) => {
                  handleKeypress(e);
                }}
                type={isPassword ? "text" : "password"}
                onChange={onChangeEPassword}
                placeholder="*********"
                style={{
                  outline: "none",
                  borderTop: 0,
                  borderLeft: 0,
                  borderRight: 0,
                  width: "100%",
                  borderBottomColor: "#80848F",
                  paddingBottom: 8,
                }}
              />
              <div>
                <img
                  onClick={showPassword}
                  className="view_set"
                  width={"20vw"}
                  src={isPassword ? Hide : View}
                />
              </div>
            </div>
          </div>
          {isloginErr ? (
            <p style={{ marginTop: "10px" }} className="text-danger">
              {signInMessage && signInMessage.message}
            </p>
          ) : (
            ""
          )}
          <div className="mt-5">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <input
                  style={{
                    outline: "none",
                    width: 16,
                    height: 16,
                  }}
                  type="checkbox"
                />
                <p className="pt-2" style={{ fontSize: 12, paddingLeft: 8 }}>
                  Remember me
                </p>
              </div>
              <div>
                <a
                  onClick={openModal3}
                  className="pt-2"
                  style={{
                    color: "#0A6ED1",
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <button
              onClick={onSignInPress}
              className="primatybutton w-inline-block"
              style={{
                width: "100%",
              }}
            >
              {loginLoader ? (
                <div class="spinner-border text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Sign In</div>
              )}
            </button>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen3}
          onRequestClose={closeModal3}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{ borderBottom: 0, padding: 40, paddingTop: 15 }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Enter your email
            </h5>
            <button
              type="button"
              class="close"
              onClick={closeModal3}
              style={{ position: "absolute", right: 25 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div>
            <p style={{ fontSize: 12 }}>Email</p>
            <input
              onChange={onChangeForgotEmail}
              placeholder="name@sap.com"
              style={{
                outline: "none",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                width: "100%",
                borderBottomColor: "#80848F",
                paddingBottom: 8,
              }}
              type="text"
            />
          </div>
          {/* <div className="mt-5">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        ></div>
                    </div> */}
          {forgetPasswordN && (
            <p style={{ marginTop: "10px" }} className="text-danger">
              {forgetPasswordMsgN}
            </p>
          )}
          <div className="mt-3">
            <button
              onClick={onForgotClicl}
              className="primatybutton w-inline-block"
              style={{
                width: "100%",
              }}
            >
              {forgotLoader ? (
                <div class="spinner-border text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
          <div className="mt-5"></div>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{ borderBottom: 0, padding: 40, paddingTop: 15 }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Please fill the information
            </h5>
            <button
              type="button"
              class="close"
              onClick={closeModal2}
              style={{ position: "absolute", right: 25 }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {/*<div style={{ marginTop: "-20px !important" }}>
            <p style={{ fontSize: 14 }}>Access type</p>
            <FormControl>
              <RadioGroup
                row
                defaultValue="tori_chat"
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="tori_chat"
                  onChange={() => {
                    setShowPassTori(true);
                    setAccessType("chat_only");
                  }}
                  control={<Radio />}
                  // label="Access to Tori Chat"
                  label={<span style={{ fontSize: "12px" }}>Tori Chat</span>}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />
                <FormControlLabel
                  value="script_factory"
                  onChange={() => {
                    setShowPassTori(false);
                    setAccessType("full");
                    setIsSignUpErr(false);
                  }}
                  control={<Radio />}
                  // label="Access to Script factory"
                  label={
                    <span style={{ fontSize: "12px" }}>Script Factory</span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
                </div>*/}

          <div className="mt-3">
            <p style={{ fontSize: 12 }}>First Name</p>
            <input
              onChange={onChangeFname}
              placeholder="Enter First Name"
              style={{
                outline: "none",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                width: "100%",
                borderBottomColor: "#80848F",
                paddingBottom: 8,
              }}
              type="text"
            />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: 12 }}>Last Name</p>
            <input
              onChange={onChangeLname}
              placeholder="Enter Last Name"
              style={{
                outline: "none",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                width: "100%",
                borderBottomColor: "#80848F",
                paddingBottom: 8,
              }}
              type="text"
            />
          </div>
          <div className="mt-3">
            <p style={{ fontSize: 14 }}>Email</p>
            <input
              onChange={onChangeInviteEmail}
              placeholder="name@sap.com"
              style={{
                outline: "none",
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                width: "100%",
                borderBottomColor: "#80848F",
                paddingBottom: 8,
              }}
              type="text"
            />
          </div>


          {/* {showPassTori && ( */}
          <div>
            <div className="mt-3">
              <p style={{ fontSize: 14 }}>Create Password</p>
              <div className="input_single">
                <input
                  onKeyPress={(e) => {
                    handleKeypress(e);
                  }}
                  type={isPassword ? "text" : "password"}
                  onChange={onChangeEPassword}
                  placeholder="*********"
                  style={{
                    outline: "none",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    width: "100%",
                    borderBottomColor: "#80848F",
                    paddingBottom: 8,
                  }}
                />
                <div>
                  <img
                    onClick={showPassword}
                    className="view_set"
                    width={"20vw"}
                    src={isPassword ? Hide : View}
                  />
                </div>
              </div>
            </div>
            {isloginErr ? (
              <p style={{ marginTop: "10px" }} className="text-danger">
                {signInMessage && signInMessage.message}
              </p>
            ) : (
              ""
            )}
          </div>
          {/* )} */}
          {/* {!showPassTori && (
            <div className="mt-3">
              <p style={{ fontSize: 14 }}>Role</p>
              <ReactSelect
                onChange={onSelectMultiPermission2}
                options={permissions}
                placeholder="Select or begin typing"
              />
            </div>
          )} */}

          {isSignUpErr ? (
            <p style={{ marginTop: "10px" }} className="text-danger">
              {inviteErrMesg && inviteErrMesg.message}
            </p>
          ) : (
            ""
          )}

          <div className="mt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            ></div>
          </div>
          <div className="mt-3">
            <button
              onClick={onRequestAccess}
              className="primatybutton w-inline-block"
              style={{
                width: "100%",
              }}
            >
              {inviteLoader ? (
                <div class="spinner-border text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
          {/* <div className="mt-5"></div> */}
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={forgetPassword}
          onRequestClose={() => {
            setForgetPassword(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{`${forgetPasswordMsg}`}</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setForgetPassword(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={invite}
          onRequestClose={() => {
            setInvite(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{`${inviteMsg}`}</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setInvite(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={showTimeOurError}
          onRequestClose={() => {
            setshowTimeOurError(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>Request Timeout Try Again.</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setshowTimeOurError(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={infoMessageModel}
          onRequestClose={() => setInfoMessgaeModel(false)}
          style={infoMessageCustomStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                left: 25,
                alignItems: "center",
              }}
            >
              <img width={30} height={30} src={ToriLogo} />

              <h5
                class="modal-title"
                id="staticBackdropLabel"
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  // width: "20%",
                  marginLeft: "5px",
                }}
              >
                Tori says
              </h5>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginTop: "20px",
            }}
          >
            <p style={{ marginLeft: "20px" }}>
              Currently TORI is for writing Demo Video scripts that are part of
              the Digital Hub Lead content plans. Please only request a user if
              you are working with a Digital Hub Lead on a video. If you are
              looking to use TORI for another use case, please check back
              towards the end of April 2023.
            </p>
          </div>

          <div className="pt-3 d-flex justify-content-end">
            <button
              onClick={openModal2}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "#dc3545",
              }}
              className="btn btn-primary primaryButton"
            >
              Continue
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default HomeScreen;
