import React, { createContext, useEffect, useState } from "react";
import {
  DeleteDraft,
  GetDraftData,
  MoveToFactoryForTaskAndExplainer,
  searchScriptsData,
} from "../components/Services/Service";

const DraftContext = createContext();
export function DraftTableContext({ children }) {
  const [draftsData, setdraftsData] = useState();
  const getDraftTableData = async (
    pageNum,
    drafts,
    myDraftVal,
    shareVal,
    isInfactory,
    column,
    order
  ) => {
    const response = await GetDraftData(
      pageNum,
      drafts,
      myDraftVal,
      shareVal,
      isInfactory,
      column,
      order
    );
    setdraftsData(response);
    return response;
  };
  const searchDraftTableData = async (
    pageNum,
    drafts,
    myDraftVal,
    shareVal,
    isInfactory,
    searchText,
    columnName,
    order
  ) => {
    const response = await searchScriptsData(
      pageNum,
      drafts,
      myDraftVal,
      shareVal,
      isInfactory,
      searchText,
      columnName,
      order
    );
    setdraftsData(response);
    return response;
  };
  const removeDraft = async (id) => {
    const res = await DeleteDraft(id);
    if (res.status) {
      setdraftsData((prevState) => {
        const newData = prevState.data.filter((draft) => draft.id !== id);
        return {
          ...prevState,
          data: newData,
          total_drafts: prevState.total_drafts,
        };
      });
    }
    return res;
  };
  const moveToFactory = async (targetId) => {
    const res = await MoveToFactoryForTaskAndExplainer(targetId);
    if (res.status) {
      setdraftsData((prevState) => {
        const updatedArray = draftsData.data.map((obj) => {
          if (obj.id === targetId) {
            // Create a new object with updated value for the specific key
            return { ...obj, ["infactory"]: 1 };
          }
          return obj;
        });
        return {
          ...prevState,
          data: updatedArray,
          total_drafts: prevState.total_drafts,
        };
      });
    }

    return res;
  };
  return (
    <DraftContext.Provider
      value={{
        getDraftTableData,
        draftsData,
        removeDraft,
        searchDraftTableData,
        moveToFactory,
      }}
    >
      {children}
    </DraftContext.Provider>
  );
}

export default DraftContext;
