import React, { createContext, useEffect, useState } from "react";
import {
  GetAllUsers,
  DeleteUser,
  searchUsersData,
  ChangeName,
  ChangePermission,
  ChangeRole,
  ActiveUser,
} from "../components/Services/Service";

const AdminContext = createContext();
export function AdminDraftContext({ children }) {
  const [draftsData, setdraftsData] = useState();
  const getAdminTableData = async (pageNum, users, columnName, order) => {
    const response = await GetAllUsers(pageNum, users, columnName, order);
    setdraftsData(response);
    return response;
  };
  const updateUserInfo = (targetId, key, newValue) => {
    setdraftsData((prevState) => {
      const updatedArray = draftsData.data.map((obj) => {
        if (obj.id === targetId) {
          // Create a new object with updated value for the specific key
          return { ...obj, [key]: newValue };
        }
        return obj;
      });
      return {
        ...prevState,
        data: updatedArray,
        total_users: prevState.total_users,
      };
    });
  };
  const editUserName = async (targetId, key, newValue) => {
    const res = await ChangeName(targetId, newValue);
    if (res.status) {
      updateUserInfo(targetId, key, newValue);
    }
    return res;
  };
  const editUserPermission = async (targetId, key, newValue) => {
    const res = await ChangePermission(targetId, newValue);
    if (res.status) {
      const permissionString = newValue.join(",");
      updateUserInfo(targetId, key, permissionString);
    }
    return res;
  };
  const editUserRole = async (targetId, key, newValue) => {
    const res = await ChangeRole(targetId, newValue);
    if (res.status) {
      updateUserInfo(targetId, key, newValue);
    }
    return res;
  };
  const activateUser = async (id) => {
    const res = await ActiveUser(id);
    if (res.status) {
      updateUserInfo(id, "status", "Pending");
    }

    return res;
  };
  const searchAdminTableData = async (
    pageNum,
    users,
    searchText,
    columnName,
    order
  ) => {
    const response = await searchUsersData(
      pageNum,
      users,
      searchText,
      columnName,
      order
    );
    setdraftsData(response);
    return response;
  };
  const removeUser = async (id) => {
    const res = await DeleteUser(id);
    if (res.status) {
      setdraftsData((prevState) => {
        const newData = prevState.data.filter((draft) => draft.id !== id);
        return {
          ...prevState,
          data: newData,
          total_users: prevState.total_users,
        };
      });
    }
    return res;
  };
  return (
    <AdminContext.Provider
      value={{
        getAdminTableData,
        draftsData,
        removeUser,
        searchAdminTableData,
        editUserName,
        editUserPermission,
        editUserRole,
        activateUser,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContext;
