import React, { createContext, useState } from "react";
import {
    GetUserData,
    UpdateChatSetting,
} from "../components/Services/Service";

const chatSettingContext = createContext();
export function ChatContext({ children }) {
    const [alternateLinksContext, setAlternateLinksContext] = useState(false);
    const [showMoreContext, setShowMoreContext] = useState(false);

    const updateChatSetting = async (data) => {
        const res = await UpdateChatSetting(data);
        if (res.status) {
            setAlternateLinksContext(res?.data?.alternate_links);
            setShowMoreContext(res?.data?.show_more);
        }

        return res;
    };

    const getUserDetailContext = async () => {
        const res = await GetUserData();
        if (res.status) {
            setAlternateLinksContext(res?.data?.chat_settings?.alternate_links);
            setShowMoreContext(res?.data?.chat_settings?.show_more);
        }

        return res;
    };
    return (
        <chatSettingContext.Provider
            value={{
                updateChatSetting,
                showMoreContext,
                alternateLinksContext,
                getUserDetailContext
            }}
        >
            {children}
        </chatSettingContext.Provider>
    );
}

export default chatSettingContext;
