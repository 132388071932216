import React, { useEffect, useState } from "react";

import VideoTutorial from "./images/IMG-VIDEO-TUTORIAL.png";
import StartWriting from "./images/IMG-STARTWRITING.png";
import Expertise from "./images/IMGExpertise.png";
import DemoImg from "./images/IMG---Demo-Factory.png";
import FunImg from "./images/IMG---Fun-Side.png";
import BrainImg from "./images/IMG--Brain.png";

import animateData from "./documents/data.json";

import { Link, useHistory } from "react-router-dom";
import "./css/tori-login-page.webflow.css";
import "./css/webflow.css";
import "./css/normalize.css";
import Lottie from "react-lottie";
import { GetUserData } from "../../Services/Service";
import { Stack } from "@mui/material";

function OverView() {
  let history = useHistory();
  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animateData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="body-2">
      <div class="div-block-4">
        <div class="div-block-6">
          <h1 class="heading-3">Welcome!</h1>
          <div class="div-block-7">
            <div class="w-row">
              <div class="column-3 w-col w-col-6 w-col-stack w-col-small-small-stack">
                <h4 class="heading-4">Hey There, Champ!</h4>
                {/*<p class="paragraph-2">
                  I am Tori, a specialized language model for SAP demo video
                  scripts. If you need help with other types of scripts or
                  content, I may not be the best fit. Reach out to Aimee Gordon
                  to expand my capabilities. Write with me in the way I was
                  intended to ensure optimal performance &amp; growth.
  </p>*/}
                <p class="paragraph-2">
                  I am TORI, a specialized language model that creates Demo Video
                  Scripts and is connected to SAP specific data sources to help you find
                  and write answers about SAP.
                </p>
                <div class="div-block-8">
                  <img
                    src={VideoTutorial}
                    loading="lazy"
                    width="90"
                    alt=""
                    class="image-16"
                  />
                  <div>
                    <div class="text-block-2">Video Tutorial</div>
                    <div class="text-block-3">
                      2 videos, takes only 5 minutes!
                    </div>
                    <button
                      onClick={(event) =>
                      (window.location.href =
                        "https://play.goconsensus.com/bcbdb960a")
                      }
                      class="primatybutton w-inline-block"
                    >
                      Watch Now
                    </button>
                  </div>
                </div>
                <div class="div-block-8">
                  <img
                    src={StartWriting}
                    loading="lazy"
                    width="90"
                    alt=""
                    class="image-17"
                  />
                  <div>
                    <div class="text-block-2">Start Writing</div>
                    <div class="text-block-3">
                      Currently I can do Demo scripts only, but Process style is
                      almost ready!
                    </div>
                    <button
                      onClick={() => {
                        history.push("/user/home");
                      }}
                      class="primatybutton w-inline-block"
                    >
                      Start Draft
                    </button>
                  </div>
                </div>
                <div class="div-block-8">
                  <div>
                    <div class="text-block-2">Need Support?</div>
                    <div class="text-block-3">
                      General questions:{" "}
                      <a href={`mailto:marcel.lindsay@sap.com`} class="link">
                        marcel.lindsay@sap.com
                      </a>
                      <Stack direction={'row'} spacing={0.5}>
                        <p>or {" "}</p>
                        <a href={`mailto:a.kadaambi@sap.com`} class="link">
                          a.kadaambi@sap.com
                        </a>
                      </Stack>
                      <br />
                    </div>
                    <div class="text-block-3">
                      Tech support or other questions:{" "}
                      <a href={`mailto:nicole.eckert@sap.com`} class="link-2">
                        nicole.eckert@sap.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-col w-col-6 w-col-stack w-col-small-small-stack">
                <a
                  href="https://play.goconsensus.com/bcbdb960a"
                  target="_blank"
                  class="link-block w-inline-block"
                >
                  <Lottie
                    isClickToPauseDisabled={true}
                    onClick={(e) => e.stopPropagation()}
                    style={{ cursor: "default" }}
                    options={defaultOptions}
                    height={580}
                    width={480}
                  />
                </a>
              </div>
            </div>
          </div>
          {/*<div class="w-layout-grid grid">
            <div class="div-block-9">
              <div class="div-block-10">
                <img
                  src={Expertise}
                  loading="lazy"
                  width="130"
                  alt=""
                  class="image-18"
                />
                <Stack spacing={1.5}>
                  <button
                    onClick={() => {
                      history.push("/user/graphs");
                    }}
                    class="tori-training"
                  >
                    View Training
                  </button>
                  <button
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      history.push("/user/heat-map");
                    }}
                    class="tori-training heat-map m-top"
                  >
                    Heat Map
                  </button>
                </Stack>
              </div>
              <div>
                <h4 class="heading-4-copy">Tori’s Subject Expertise</h4>
                <p class="paragraph-2-copy">
                  Our team of Machine Learning Experts have trained Tori on
                  hundreds of scripts, making her a valuable resource for
                  understanding accuracy in many subjects. To make the most of
                  Tori&#x27;s capabilities, it&#x27;s important to understand
                  her areas of strength and weakness. If she is weaker in a
                  certain subject, your script may require more editing, but the
                  benefits will be shared with future users and contribute to
                  Tori&#x27;s overall knowledge.
                </p>
              </div>
            </div>
            <div class="div-block-9">
              <div class="div-block-10">
                <img
                  src={DemoImg}
                  loading="lazy"
                  width="130"
                  alt=""
                  class="image-18"
                />
              </div>
              <div>
                <h4 class="heading-4-copy">Demo Factory Process</h4>
                <p class="paragraph-2-copy">
                  You have an idea for a new video, and begin by writing with
                  TORI. Once satisfied, the request is sent for review and by
                  the Digital HUB Lead. The script will then go to the Factory
                  for professional copywriting and then you will review and
                  approve. And that&#x27;s how the magic happens, folks! From
                  idea to script, TORI&#x27;s got your back. Now go forth and
                  make some killer content!
                </p>
              </div>
            </div>
            <div class="div-block-9">
              <div class="div-block-10">
                <img
                  src={BrainImg}
                  loading="lazy"
                  width="130"
                  alt=""
                  class="image-18"
                />
              </div>
              <div>
                <h4 class="heading-4-copy">Tori’s Brain!</h4>
                <p class="paragraph-2-copy">
                  OpenAI&#x27;s GPT-3 (Generative Pre-training Transformer 3) is
                  a state-of-the-art NLP model with 175 billion parameters,
                  which can generate human-like text. The same technology that
                  is used by the popular tool https://chat.openai.com, a great
                  compliment to this app and to get other creative ideas
                  started.
                </p>
              </div>
            </div>
            <div class="div-block-9">
              <div class="div-block-10">
                <img
                  src={FunImg}
                  loading="lazy"
                  width="130"
                  alt=""
                  class="image-18"
                />
              </div>
              <div>
                <h4 class="heading-4-copy">Tori’s Fun Side</h4>
                <p class="paragraph-2-copy">
                  “When I&#x27;m not busy crunching data and making predictions,
                  you can find me binge-watching sci-fi shows, practicing my
                  stand-up comedy routine, and trying to teach robots how to
                  salsa dance. Just kidding, AI&#x27;s don&#x27;t have free
                  time, but if we did, we would probably be doing something as
                  nerdy and awesome as that.” -Tori
                </p>
              </div>
            </div>
                  </div>*/}
          <div class="div-block-11"></div>
        </div>
      </div>
    </div>
  );
}

export default OverView;
