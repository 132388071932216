import { Tooltip, Typography } from "@mui/material";
import React from "react";
import info from "../assets/info.svg";
import Select from "react-select";
import { Button } from "react-bootstrap";
const SelectDropdown = ({
  label = null,
  tooltipText = null,
  onBlur = null,
  onChange = null,
  placeholder = null,
  defaultValue = null,
  options = null,
  isMulti = false,
  isOptionDisabled = null,
  isShowLabel = false,
  isShowErrorMessage = false,
  errorMessage = null,
  disabledInputs = false,
}) => {
  console.log("🚀 ~ file: SelectDropdown.js:21 ~ defaultValue:", defaultValue);
  return (
    <div>
      {isShowLabel ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <span className="pName">{label}</span>
            <Tooltip
              arrow
              placement="right"
              title={<Typography fontSize={14}>{tooltipText}</Typography>}
            >
              <img
                style={{
                  marginLeft: 5,
                  width: 20,
                  marginTop: -6,
                }}
                src={info}
              />
            </Tooltip>
          </div>
        </div>
      ) : null}

      <div className="top_input">
        <Select
          onBlur={onBlur}
          onChange={onChange}
          isMulti={isMulti}
          placeholder={placeholder}
          defaultValue={defaultValue === null ? "" : defaultValue}
          options={options}
          isOptionDisabled={isOptionDisabled}
          // noOptionsMessage={() => <Button size="sm" onClick={()=>alert('added')}>Add</Button>}
          isDisabled={disabledInputs}
        />
        {isShowErrorMessage && (
          <span style={{ color: "red" }}>{errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default SelectDropdown;
