import React, { useEffect, useState } from "react";
import NewDraft from "../../SubComponents/NewDraft/NewDraft";
import bg from "../../assets/home_bg.png";
import {
  GetUserData,
  AddDraft,
  SubmitUserReview,
  AddScriptProvidedDraft,
} from "../../Services/Service";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import "../DraftTable/Style.css";
import DisplayModal from "../CreateDraft/Components/Modal";
import CreateDraftSurvey from "../../SubComponents/modal/CreateDraftSurvey";

function Draft() {
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [spotlightLoader, setSpotlightLoader] = useState(false);
  const [samplerLoader, setSamplerLoader] = useState(false);
  const [explainerLoader, setExplainerLoader] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [processLoader, setProcessLoader] = useState(false);
  const [scriptProviderLoader, setScriptProviderLoader] = useState(false);
  const [maintenancemsg, setmaintenancemsg] = useState(false);
  const [showCreateDraftSurvey, setShowCreateDraftSurvey] = useState(false);
  const [draftType, setDraftType] = useState();
  const [data, setData] = useState();
  const [loading, setLoder] = useState(true);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [viewS1, setViewS1] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [watchVideoAns, setWatchVideoAns] = useState();
  const [inputFieldsAns, setInputFieldsAns] = useState();
  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    updateSidebar();
  }, []);
  const CloseSurveyModal = () => {
    setWatchVideoAns();
    setInputFieldsAns();
    setShowCreateDraftSurvey(false);
  };
  const OnChangeWatchVideoAnswer = (e) => {
    setWatchVideoAns(e.target.value);
  };
  const OnChangeInputFieldsAnswer = (e) => {
    setInputFieldsAns(e.target.value);
  };
  const updateSidebar = () => {
    GetUserData()
      .then((res) => {
        if (res.status) {
          let name = res.data.name;
          let role = res.data.role;
          localStorage.setItem("userName", name);
          localStorage.setItem("role", role);
          setLoder(false);
          setData(res.data);
          localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
          localStorage.setItem(
            "feedbackCount",
            JSON.stringify(res.data.feedback)
          );
          localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
          localStorage.setItem(
            "finalReviewCount",
            JSON.stringify(res.data.teach_tori)
          );
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoder(false);
      });
  };
  const OnProcessClick = () => {
    // setmaintenancemsg(true);
    // return
    setDraftType("Process");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartDraft(false, "Process");
    }
  };
  const OnSpotlightClick = () => {
    setDraftType("spotlight");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartDraft(false, "spotlight");
    }
  };
  const OnSamplerClick = () => {
    // setmaintenancemsg(true);
    // return
    setDraftType("sampler");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartDraft(false, "sampler");
    }
  };
  const OnExplainerClick = () => {
    // setmaintenancemsg(true);
    // return
    setDraftType("explainer");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartDraft(false, "explainer");
    }
  };
  const OnTaskClick = () => {
    // setmaintenancemsg(true);
    // return
    setDraftType("task");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartDraft(false, "task");
    }
  };
  const OnScriptProvidedClick = () => {
    // setmaintenancemsg(true);
    // return
    setDraftType("Script Provider");
    if (data && data.created_first_draft === 0) {
      setShowCreateDraftSurvey(true);
    } else {
      StartScriptProvide(false, "script");
    }
  };

  const StartScriptProvide = (hasFirstDraft, type) => {
    setIsDisableButton(true);
    setScriptProviderLoader(true);
    // hasFirstDraft
    //   ? setLoader(true)
    //   : type === "spotlight"
    //   ? setSpotlightLoader(true)
    //   : type === "Process"
    //   ? setProcessLoader(true)
    //   : type === "sampler"
    //   ? setSamplerLoader(true)
    //   : type === "explainer"
    //   ? setExplainerLoader(true)
    //   : type === "task"
    //   ? setTaskLoader(true)
    //   : setScriptProviderLoader(true);

    if (hasFirstDraft) {
      SubmitUserReview(watchVideoAns, inputFieldsAns).then((res) => {
        if (res.status) {
          console.log(res.message);
        } else {
          setisSendReqMsg(true);
          setsendReqMsg(res.message);
          setLoader(false);
          setIsDisableButton(false);
        }
      });
    }
    AddScriptProvidedDraft(type)
      .then((res) => {
        if (res.status) {
          history.push(`/draft/scriptprovided/` + res.id, {
            from: "startdraft",
          });
          // type === "spotlight"
          //   ? setSpotlightLoader(false)
          //   : type === "Process"
          //   ? setProcessLoader(false)
          //   : type === "sampler"
          //   ? setSamplerLoader(true)
          //   : type === "explainer"
          //   ? setExplainerLoader(true)
          //   : type === "task"
          //   ? setTaskLoader(true)
          // :
          setScriptProviderLoader(true);

          setLoader(false);
          setIsDisableButton(false);
        } else {
          setsendReqMsg(res.message);
          setisSendReqMsg(true);
          setIsDisableButton(false);
          // type === "spotlight"
          //   ? setSpotlightLoader(false)
          //   : type === "Process"
          //   ? setProcessLoader(false)
          //   : type === "sampler"
          //   ? setSamplerLoader(true)
          //   : type === "explainer"
          //   ? setExplainerLoader(true)
          //   : type === "task"
          //   ? setTaskLoader(true)
          //   :
          setScriptProviderLoader(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        // type === "spotlight"
        //   ? setSpotlightLoader(false)
        //   : type === "Process"
        //   ? setProcessLoader(false)
        //   : type === "sampler"
        //   ? setSamplerLoader(true)
        //   : type === "explainer"
        //   ? setExplainerLoader(true)
        //   : type === "task"
        //   ? setTaskLoader(true)
        //   :
        setScriptProviderLoader(true);
        setLoader(false);
      });
  };
  const StartDraft = (hasFirstDraft, type) => {
    setIsDisableButton(true);
    hasFirstDraft
      ? setLoader(true)
      : type === "spotlight"
        ? setSpotlightLoader(true)
        : type === "Process"
          ? setProcessLoader(true)
          : type === "sampler"
            ? setSamplerLoader(true)
            : type === "explainer"
              ? setExplainerLoader(true)
              : type === "task"
                ? setTaskLoader(true)
                : setScriptProviderLoader(true);

    if (hasFirstDraft) {
      SubmitUserReview(watchVideoAns, inputFieldsAns).then((res) => {
        if (res.status) {
          console.log(res.message);
        } else {
          setisSendReqMsg(true);
          setsendReqMsg(res.message);
          setLoader(false);
          setIsDisableButton(false);
        }
      });
    }
    AddDraft(type)
      .then((res) => {
        if (res.status) {
          history.push(`/draft/${type}/` + res.id, { from: "startdraft" });
          type === "spotlight"
            ? setSpotlightLoader(false)
            : type === "Process"
              ? setProcessLoader(false)
              : type === "sampler"
                ? setSamplerLoader(true)
                : type === "explainer"
                  ? setExplainerLoader(true)
                  : type === "task"
                    ? setTaskLoader(true)
                    : setScriptProviderLoader(true);

          setLoader(false);
          setIsDisableButton(false);
        } else {
          setsendReqMsg(res.message);
          setisSendReqMsg(true);
          setIsDisableButton(false);
          type === "spotlight"
            ? setSpotlightLoader(false)
            : type === "Process"
              ? setProcessLoader(false)
              : type === "sampler"
                ? setSamplerLoader(true)
                : type === "explainer"
                  ? setExplainerLoader(true)
                  : type === "task"
                    ? setTaskLoader(true)
                    : setScriptProviderLoader(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        type === "spotlight"
          ? setSpotlightLoader(false)
          : type === "Process"
            ? setProcessLoader(false)
            : type === "sampler"
              ? setSamplerLoader(true)
              : type === "explainer"
                ? setExplainerLoader(true)
                : type === "task"
                  ? setTaskLoader(true)
                  : setScriptProviderLoader(true);
        setLoader(false);
      });
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <div>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Create New Script</h3>
      </div>
      {/* {loading ? (
        <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div>
        <div className="row">
          <div className="col-4">
            <div
              style={{
                position: "relative",
                minHeight: 330,
                backgroundColor: "#fff",
                borderRadius: 10,
                paddingTop: 25,
                // paddingBottom: 10,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <div>
                <h6
                  style={{
                    // marginBottom:6,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Highlight/Spotlight{" "}
                </h6>
                <span style={{ fontSize: 14, color: "gray" }}>2-5 mins</span>

                {/*<span
                  className="mt-2"
                  style={{ fontSize: 14, color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    setViewS1(true);
                  }}
                >
                  View Sample
                </span>*/}
                <br />
              </div>
              <div>
                <p style={{ fontSize: 14, marginTop: 15 }}>
                  Spotlight Demo Videos are 2-5 minute demo videos (formerly
                  called Highlight, Deep Dive and Release Highlight) with a
                  Benefit/Demo Benefit/Demo, Benefit/Demo format.​ We combined
                  as the length of the content is searchable.
                </p>
              </div>
              <div style={{ position: "absolute", bottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 40,
                  }}
                >
                  <button
                    // style={{ backgroundColor: userData?.role === 'Admin' ? '' : 'grey' }}
                    onClick={OnSpotlightClick}
                    className="primatybutton w-inline-block"
                    disabled={spotlightLoader || isDisableButton}
                  >
                    {spotlightLoader ? (
                      <div style={{ padding: "0px 24.5px 1px" }}>
                        <div
                          class="spinner-border text-dark"
                          style={{ padding: "0px 5px" }}
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>Start Draft</div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              style={{
                position: "relative",
                minHeight: 330,
                backgroundColor: "#fff",
                borderRadius: 10,
                paddingTop: 25,
                //   paddingBottom: 20,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <div>
                <h6
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Process
                </h6>
                <span style={{ fontSize: 14, color: "gray" }}>5-7 mins</span>

                <br />
              </div>
              <div>
                <p style={{ fontSize: 14, marginTop: 15 }}>
                  Process Demos are a business process focused demo, where there
                  is a defined process as the anchor in this no longer than
                  7-minute demo. They begin with the benefits, then an
                  explanation of the process flow, then the demo is delivered,
                  tying back to the benefits when applicable.
                </p>
              </div>
              <div style={{ position: "absolute", bottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 40,
                  }}
                >
                  <button
                    // style={{ backgroundColor: userData?.role === 'Admin' ? '' : 'grey' }}
                    onClick={OnProcessClick}
                    disabled={processLoader || isDisableButton}
                    className="primatybutton w-inline-block"
                  >
                    {processLoader ? (
                      <div style={{ padding: "0px 24.5px 1px" }}>
                        <div
                          class="spinner-border text-dark"
                          style={{ padding: "0px 5px" }}
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div>Start Draft</div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <NewDraft
              startDraft={OnSamplerClick}
              commingSoon={false}
              name="Sampler"
              point="2-3 Minutes"
              // view="View Sample"
              discription={
                "Sampler demos are designed to be on a feature page on sap.com with a focus on exactly what is covered on that page."
              }
              btnName="Start Draft"
              // btnBg={'grey'}
              loading={samplerLoader}
              isDisable={isDisableButton}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <NewDraft
              startDraft={OnExplainerClick}
              commingSoon={false}
              name="Explainer"
              point="5-7 Minutes"
              // view="View Sample"
              discription={
                "Explainer Demos are demos that Presales normally deliver via a whiteboard, or a PowerPoint.  Largely discussing technical topics when explaining a concept that is not easily shown in a process demo. "
              }
              btnName="Start Draft"
              // btnBg={'grey'}
              loading={explainerLoader}
            // isDisable={userData?.role === 'Admin' ? false : true}
            />
          </div>
          <div className="col-4">
            <NewDraft
              startDraft={OnTaskClick}
              commingSoon={false}
              name="Task"
              point="5-7 Minutes"
              // view="View Sample"
              discription={
                "Task demos are role driven, showing a specific task that the role would do. This allows the viewer to see in more detail what it will be like using a SAP solution."
              }
              btnName="Start Draft"
              // btnBg={'grey'}
              loading={taskLoader}
            // isDisable={userData?.role === 'Admin' ? false : true}
            />
          </div>

          <div className="col-4">
            <NewDraft
              startDraft={OnScriptProvidedClick}
              commingSoon={false}
              name="Script Provided"
              // point="5-7 Minutes"
              // view="View Sample"
              discription={
                "If you have a script already written for a new video, or would like to have an existing one updated, add it here."
              }
              btnName="Start Draft"
              // btnBg="grey"
              loading={scriptProviderLoader}
              isDisable={isDisableButton}
            />
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            marginTop: "-700px",
            right: 70,
            zIndex: -999,
          }}
          src={bg}
        />
      </div>
      {/* )} */}
      <div>
        <DisplayModal
          open={viewS1}
          onClose={() => {
            setViewS1(false);
          }}
          modalMsg={"Coming Soon"}
        />
        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
        <DisplayModal
          open={isSendReqMsg}
          onClose={() => {
            setisSendReqMsg(false);
          }}
          modalMsg={sendReqMsg}
        />
        <DisplayModal
          open={maintenancemsg}
          onClose={() => {
            setmaintenancemsg(false);
          }}
          modalMsg={"Scripting is under maintenance for the next 24 hours to upgrade her brain"}
        />
        <CreateDraftSurvey
          showCreateDraftSurvey={showCreateDraftSurvey}
          handleCancel={CloseSurveyModal}
          startDraft={StartDraft}
          draftType={draftType}
          onChangeVideoTutorial={OnChangeWatchVideoAnswer}
          onChangeInputFields={OnChangeInputFieldsAnswer}
          videoTutorial={watchVideoAns}
          inputField={inputFieldsAns}
          spotlightLoader={spotlightLoader}
          processLoader={processLoader}
          loader={loader}
        />
      </div>
    </div>
  );
}

export default Draft;