import React from "react";
import { isAuthenticated } from "./Services/Service";
import { Redirect, Route } from "react-router-dom";
const PublicRoutes = ({ component: Component, ...rest }) => {
  const isAuth = localStorage.getItem("token") && localStorage.getItem("userName") ? true : false
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/user/home"} />
        )
      }
    />
  );
};

export default PublicRoutes;
