import { Typography } from "@mui/material";
import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import { RejectRequestService } from "../../../Services/Service";
import DisplayModal from "./Modal";
import SharedWith from "./SharedWith";

const RejectRequestModal = ({
  id,
  open = null,
  handleCancel = null,
  RequestToDraftState,
  EditingToRequestState,
  draftOwner,
  draftState,
  assignedHubleads,
  rejectRequestFromAdmin,
}) => {
  const [approvalComments, setapprovalComments] = useState();
  const [loading, setloading] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const handleComment = (e) => {
    setapprovalComments(e.target.value);
  };
  const closeModal = () => {
    setapprovalComments("");
    setCommentError(false);
    handleCancel();
  };

  const RejectRequestHandler = () => {
    if (!approvalComments || approvalComments.trim().length == 0) {
      setCommentError(true);
    } else {
      setCommentError(false);
      setloading(true);
      RejectRequestService(id, approvalComments)
        .then((res) => {
          if (res.status) {
            rejectRequestFromAdmin(assignedHubleads);
            closeModal();
            setisSendReqMsg(true);
            setsendReqMsg(res.message);
            draftState == "Request"
              ? RequestToDraftState()
              : EditingToRequestState();
          } else {
            setCommentError(true);
          }
          setloading(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setloading(false);
        });
    }
  };
  return (
    <div className="modal_approval">
      <Modal
        centered
        open={open}
        width={450}
        zIndex={999}
        footer={[
          <button
            style={{ marginRight: "12px" }}
            className="primatybuttonwhite w-inline-block"
            onClick={closeModal}
            disabled={loading}
          >
            Cancel
          </button>,
          <button
            style={{ marginBottom: "25px", marginRight: "24px" }}
            className="primatybutton w-inline-block"
            onClick={RejectRequestHandler}
            disabled={loading}
          >
            {loading == true ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Reject Request"
            )}
          </button>,
        ]}
        onCancel={() => !loading && handleCancel()}
        bodyStyle={{ padding: 24, borderRadius: "6px" }}
      >
        <div className="approval_modal_h1">Feedback On Draft Rejection</div>
        <div className="approval_modal_h2 mt-3">
          {draftState == "Request" ? "Draft Owner" : "Digital Hub Leads"}
        </div>
        <Typography style={{ fontSize: 14, marginTop: 4 }}>
          {draftState == "Request" ? (
            draftOwner
          ) : (
            <SharedWith
              draftSharedWith={assignedHubleads && assignedHubleads}
            />
          )}
        </Typography>

        <div className="approval_modal_h2 mt-4">Add Comment</div>
        <div className="approval_modal_comment_container">
          <textarea
            placeholder="Add Comments.."
            rows={4}
            className="approve_modal_comment_box"
            value={approvalComments}
            onChange={handleComment}
          />
          {commentError && (
            <div style={{ color: "red" }}>This field is required</div>
          )}
        </div>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
    </div>
  );
};

export default RejectRequestModal;
