import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../environment";
import { getTokens } from "../../utils/helperfunctions/HelperFunctions";
var controller = new AbortController();
var token = "";
token = getTokens();
export const DraftSlice = createApi({
  reducerPath: "drafts",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Get the token from the store
      // const token = getState().auth.token;
      const userToken = localStorage.getItem("token");
      // If the token is available, set the Authorization header
      if (userToken) {
        headers.set("Authorization", `Bearer ${userToken}`);
      }
      headers.set("Content-Type", `application/json`);

      return headers;
    },
    signal: controller.signal,
    cacheTime: 10 * 60 * 1000,
  }),
  tagTypes: ["Draft"],
  endpoints: (builder) => ({
    getDrafts: builder.mutation({
      query: (body) => ({
        url: "/api/getDrafts",
        body: body,
        method: "POST",
      }),
      // invalidatesTags: [{ type: "Draft" }]
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Draft", id })),
              { type: "Draft", id: "LIST" },
            ]
          : [{ type: "Draft", id: "LIST" }],
    }),
    getDraft: builder.mutation({
      query: (draftId) => ({
        url: `/api/getDraftById`,
        method: "POST",
        body: draftId,
      }),
      providesTags: (result, error, arg) => [{ type: "Draft", id: arg }],
    }),
    addDraft: builder.mutation({
      query: (body) => ({
        url: "/api/getDrafts",
        body: body,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Draft", id: "LIST" }],
    }),
    deleteDraft: builder.mutation({
      query: (deletedID) => ({
        url: `/api/deleteDraft`,
        method: "DELETE",
        body: deletedID,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Draft", id: arg.id }],
    }),
    updateDraft: builder.mutation({
      query: (body) => ({
        url: `/api/stateSave`,
        body: body,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Draft", id: arg.id }],
    }),
  }),
});

export const {
  useGetDraftsMutation,
  useDeleteDraftMutation,
  useAddDraftMutation,
  useGetDraftMutation,
  useUpdateDraftMutation,
} = DraftSlice;
