import React, { useState, useEffect } from "react";
import { CloneOfDraft, GetUserData } from "../../../Services/Service";
import ToriLogo from "../../../assets/tori_logo.svg";
import "./style.css";
import { Modal } from "antd";
import { InputLabel, Typography } from "@mui/material";
import Select from "react-select";
import DisplayModal from "../../CreateDraft/Components/Modal";
import { useContext } from "react";
import DraftContext from "../../../../context/DraftTableContext";

const options = [
  {
    value: "spotlight2min",
    label: "Spotlight 2 minutes",
  },
  {
    value: "spotlight4min",
    label: "Spotlight 4-5 minutes",
  },
  {
    value: "Process",
    label: "Process",
  },
  {
    value: "sampler",
    label: "Sampler",
  },
  {
    value: "explainer",
    label: "Explainer",
  },
  {
    value: "task",
    label: "Task",
  },
];

const CloneDraftModal = ({setDefaultState, rowData }) => {
  const { getDraftTableData } = useContext(DraftContext);
  const [loaderDeleteConf, setloaderDeleteConf] = useState(false);
  const [onDeleteMsg, setOnDeleteMsg] = useState("");
  const [duration, setDuration] = useState("");
  const [isVideoTypeError, setIsVideoTypeError] = useState(false);
  const [videoType, setVideoType] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
    setIsVideoTypeError(false);

  };
  useEffect(() => {
    updateSidebar();
  }, []);
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };
  const onClickYesConfirm = () => {
    setloaderDeleteConf(true);
    if (videoType[0].value) {
      setIsVideoTypeError(false);
      CloneOfDraft(
        rowData?.id,
        (videoType[0].value && videoType[0].value == "spotlight2min") ||
          videoType[0].value == "spotlight4min"
          ? "spotlight"
          : videoType[0].value,
        duration
      )
        .then((res) => {
          if (res.status) {
            // location.reload();
            getDraftTableData(1,10,false,false,false).then((res)=> {
              setDefaultState()
              if(res.status) {
                handleCancel1();
                setTimeout(() => {
                  showModal2();
                  setOnDeleteMsg("Draft cloned successfully");
                }, 500);
                setloaderDeleteConf(false);
              }
            })

          } else {
            setloaderDeleteConf(false);
            handleCancel1();
            showModal2();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
        });
    } else {
      setIsVideoTypeError(true);
      setloaderDeleteConf(false);
    }
  };

  const removeTypeFromtheOption = options.filter(
    (itm) => itm.label !== rowData?.type
  );

  const handleCloneTypeToNavigate = (e) => {};

  return (
    <>
      <Typography
        style={{ fontSize: 14, padding: "5px 16px" }}
        onClick={showModal1}
      >
        Clone Draft
      </Typography>
      {/* delete modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title pb-3"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 30,
              fontSize: 18,
              fontWeight: "bold",
              fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
            }}
          >
            Clone Draft
          </h5>
        </div>

        <div id="productName" className="row top_pad">
          <div className="col-12">
            <InputLabel
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
              }}
            >
              Select Video Type
            </InputLabel>
            <Select
              style={{
                fontWeight: "lighter",
                minWidth: "100px",
              }}
              onChange={(e) => {
                setVideoType([{ value: e.value, label: e.label }]);
                e.label == "Spotlight 2 minutes" && setDuration("2min");
                e.label == "Spotlight 4-5 minutes" && setDuration("4-5min");
              }}
              options={options}
            />
            {isVideoTypeError && (
              <span style={{ color: "red" }}>This field is required</span>
            )}
          </div>
        </div>

        <div className="pt-3 d-flex justify-content-end top_pad">
          <div
            style={{
              marginRight: 10,
              fontSize: "15px",
              fontWeight: 600,
              fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
            }}
          >
            <button
              onClick={handleCancel1}
              className="primatybuttonwhite w-inline-block"
              style={{
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              onClick={onClickYesConfirm}
              className="primatybuttonred"
              style={{
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
              }}
            >
              {loaderDeleteConf ? (
                <div
                  class="spinner-border text-white"
                  role="status"
                  style={{
                    marginLeft: "15px",
                    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  }}
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={handleCancel2}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${onDeleteMsg}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel2}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </>
  );
};

export default CloneDraftModal;
