import React, { useState, useEffect } from "react";
import { DeleteDraftFeedBack, GetUserData } from "../../../Services/Service";
import ToriLogo from "../../../assets/tori_logo.svg";
import { Modal } from "antd";
import { Typography } from "@mui/material";
const DeleteDraftModal = ({ id }) => {
  const [loaderDeleteConf, setloaderDeleteConf] = useState(false);
  const [deleteDraftMsg, setDeleteDraftMsg] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  useEffect(() => {
    updateSidebar();
  }, []);
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem("feedbackCount", JSON.stringify(res.data.feedback));
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem("finalReviewCount", JSON.stringify(res.data.teach_tori));
      }
    });
  };
  const onClickYesConfirm = () => {
    setloaderDeleteConf(true);
    DeleteDraftFeedBack(id).then((res) => {
      if (res.status) {
        setloaderDeleteConf(false);
        handleCancel1();
        location.reload();
      } else {
        setloaderDeleteConf(false);
        handleCancel1();
        // alert(res.message)
        showModal2();
        setDeleteDraftMsg(res.message);
      }
    });
  };

  return (
    <>
      <Typography onClick={showModal1} style={{ width: '100%', fontSize: 14 }}>
        Delete
      </Typography>
      {/* DELETE MODAL */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title pb-3"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Delete Draft
          </h5>
        </div>

        <h5>Are you sure you want to delete this Draft?</h5>

        <div className="pt-3 d-flex justify-content-end">
          <div style={{ marginRight: 10 }}>
            <button
              onClick={handleCancel1}
              className="primatybuttonwhite w-inline-block"
            >
              No
            </button>
          </div>
          <div>
            {loaderDeleteConf ? (
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                onClick={onClickYesConfirm}
                className="primatybuttonred w-inline-block"
              >
                Yes
              </button>
            )}
          </div>
        </div>
      </Modal>
      {/* DELETE MESSAGE MODAL */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={handleCancel2}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${deleteDraftMsg}.`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel2}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteDraftModal;
