import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import {  onMessage } from "firebase/messaging";

const config = {
  apiKey: "AIzaSyDjYA9btf8MYNL9fLcQi4kmze8y7E9Jtp4",
  authDomain: "tori-428b9.firebaseapp.com",
  projectId: "tori-428b9",
  storageBucket: "tori-428b9.appspot.com",
  messagingSenderId: "284340756612",
  appId: "1:284340756612:web:2c7d5716f72c31de9762d1",
  measurementId: "G-WNEEZKQLST",
};

initializeApp(config);

// next block of code goes here
export const requestFirebaseNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    Notification.requestPermission()
      .then((permission) => {
        resolve(permission);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const generateKey = () => {
  return new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        "BNFe8mcwBJnOA9K5aVWPYyCtiwoKXKkoxMG6yKliVTMgLNwAxgj7Y9AnavucWUORoi9uq9yjqnjBewI410rjpYs",
    })
      .then((currentToken) => {
        resolve(currentToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const messaging = getMessaging();
//......

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
