import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
import "antd/dist/antd.min.css";
import "./style.css";
import AdminContext from "../../../context/AdminTableContext";
import ReactSelect from "react-select";
import { Typography } from "@mui/material";
import DisplayModal from "../CreateDraft/Components/Modal";
const ChangePermissionModal = ({ id }) => {
  const { editUserPermission } = useContext(AdminContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [changePermissionMsg, setChangePermissionMsg] = useState("");
  const [rePermission, setRePermission] = useState();
  const [loaderChangePermission, setloaderChangePermission] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  var multiArr = [];
  var multiArr2 = [];

  const onSelectMultiPermission2 = (e) => {
    var val = e;
    val.map((a) => {
      multiArr2.push(a.label);
    });
    setRePermission(multiArr2);
  };

  const onClickChangePermission = () => {
    setloaderChangePermission(true);
    editUserPermission(id,'permission', rePermission).then((res) => {
      if (res.status) {
        setloaderChangePermission(false);
        handleCancel();
        setChangePermissionMsg(res.message);
        setTimeout(() => {
          showModal2();
        }, 500);
      } else {
        setloaderChangePermission(false);
        handleCancel();
        showModal2();
        setChangePermissionMsg(res.message);
      }
    }).catch((error) => {
      if (error.name === "AbortError") {
        setshowTimeOurError(true);
      }
      setloaderChangePermission(false);
      setIsModalOpen(false);
    });
  };
  const options = [
    { value: 0, label: "Edit" },
    { value: 1, label: "Create Draft & Comment" },
    { value: 2, label: "Close Comment" },
  ];
  return (
    <>
      <Typography style={{ fontSize: 14,padding: "5px 16px" }} onClick={showModal}>
        Change Permission</Typography>
      {/* permission modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Change Permission
          </h5>
        </div>
        <div style={{ padding: 20 }}>
          <div>
            <label for="exampleInputEmail1">Permission</label>
            <ReactSelect
              onChange={onSelectMultiPermission2}
              options={options}
              isMulti
              placeholder="Select or begin typing"
            />
          </div>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <div style={{ marginRight: 10 }}>
            <button
              onClick={handleCancel}
              className="primatybuttonwhite w-inline-block"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              onClick={onClickChangePermission}
              className="primatybutton w-inline-block"
            >
              {loaderChangePermission ? (
                <div class="spinner-border text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <span> Change</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/*  */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={handleCancel2}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${changePermissionMsg}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel2}
            className="primatybuttonred w-inline-block"
          >
            Close
          </button>
        </div>
      </Modal>
      {/*  */}
      <DisplayModal
              open={showTimeOurError}
             onClose={() => {
             setshowTimeOurError(false);
                 }}
             modalMsg={"Request Timeout Try Again."}
          />
    </>
  );
};

export default ChangePermissionModal;
