import { baseUrl } from "../../../src/environment";
import moment from "moment";

async function fetchWithTimeout(resource, options = {}) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  return response;
}

export function Login(email, password) {
  const fcmtoken = localStorage.getItem("FCMToken");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/login", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
        fcm_token: fcmtoken,
      }),
      signal: controller.signal,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        // console.log(error.name === 'AbortError');
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function LoginSignupWithMicrosoft({ email, name, tenet_id }) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/authenticate-microsoft-user", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: name,
        tenet_id: tenet_id,
      }),
      signal: controller.signal,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        // console.log(error.name === 'AbortError');
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function SignUp(name, email, password) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/signup", {
      method: "POST",
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
      }),
      signal: controller.signal,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function ClearDraft(draft_id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/clear-draft-data", {
      method: "POST",
      body: JSON.stringify({
        draft_id: draft_id,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetUserData() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getUserDetail", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function UpdateChatSetting({ alternate_links, show_more }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/update-chat-settings", {
      method: "POST",
      body: JSON.stringify({ alternate_links, show_more }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteComment(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-comment", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function ClearAllComment(id, name) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/close-comments", {
      method: "POST",
      body: JSON.stringify({ id: id, section: name }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetDraftData(
  pageNum,
  drafts,
  myDraftVal,
  shareVal,
  isInfactory,
  column,
  order
) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getDrafts", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        drafts: drafts,
        user: myDraftVal,
        shared: shareVal,
        infactory: isInfactory,
        column: column,
        order: order,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function searchScriptsData(
  pageNum,
  drafts,
  myDraftVal,
  shareVal,
  isInfactory,
  searchText,
  columnName,
  order
) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/search/drafts", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        drafts: drafts,
        user: myDraftVal,
        shared: shareVal,
        infactory: isInfactory,
        column: columnName,
        order: order,
        search: searchText,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetEditingDraftData(myDraftVal, shareVal) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getEditingDrafts", {
      method: "POST",
      body: JSON.stringify({ user: myDraftVal, shared: shareVal }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetLineOfBusinessData() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-line-of-business", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetIndustriesData() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-industries", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetDomainsData() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-domains", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetFeedBackDraftData(myDraftVal, shareVal) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-feedback-drafts", {
      method: "POST",
      body: JSON.stringify({ user: myDraftVal, shared: shareVal }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function TeachTori(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/teach-tori", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function getNotifications() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  clearTimeout(tid);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-notifications", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetSavedDraftDetails(id) {
  let token = localStorage.getItem("token");
  // let id = localStorage.getItem("draftId");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getDraftById", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function CancelDHLRequest(id) {
  let token = localStorage.getItem("token");
  // let id = localStorage.getItem("draftId");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/cancel-request", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetSavedDraftEditingDetails() {
  let token = localStorage.getItem("token");
  let id = localStorage.getItem("draftId");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getDraftById", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetSavedDraftFinalReview() {
  let token = localStorage.getItem("token");
  let id = localStorage.getItem("draftId");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getDraftById", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function MovetoEditing(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/moveDraftToEditing", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteDraft(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/deleteDraft", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function MoveToFactoryForTaskAndExplainer(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/move-to-factory", {
      method: "POST",
      body: JSON.stringify({ draft_id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function DeleteDraftFinalReview(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-final-review-draft", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteDraftFeedBack(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-feedback-draft", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteDraftCopyWrite(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-copywriter-draft", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function AddDraft(type) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/createDraft", {
      method: "POST",
      body: JSON.stringify({ type: type }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function AddScriptProvidedDraft(type) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/create-script-provided-draft", {
      method: "POST",
      body: JSON.stringify({ type: type }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function SubmitUserReview(videoTutorialAns, inputFieldAns) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/submit-user-review", {
      method: "POST",
      body: JSON.stringify({
        video_tutorail: videoTutorialAns,
        input_field: inputFieldAns,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function SaveDraftState(
  id,
  pName,
  pName2,
  useCase,
  Indursty,
  Lob1,
  Domain,
  VideoName,
  firstProductUrl,
  SecondProductUrl,
  demo_steps,
  extrasss5,
  newIntro,
  newIntro2,
  newOutro,
  intro1Value,
  selected_outro,
  isaddToScript_Intro,
  isaddToScript_Outro,
  draft_state,
  duration,
  just_cloned
) {
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyDate = JSON.stringify({
    id: id,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    third_product_name: "",
    third_product_url: "",
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    demo_steps: demo_steps,
    intro: newIntro,
    intro_solution: newIntro2,
    outro: newOutro,
    is_added_to_script_intro: isaddToScript_Intro,
    is_added_to_script_outro: isaddToScript_Outro,
    intro_model_response: intro1Value,
    selected_outro: selected_outro,
    draft_state: draft_state,
    duration: duration,
    just_cloned,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/stateSave", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else if (response.status == 500) {
          resolve(response);
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// process type save api
export function SaveProcessState(
  id,
  VideoName,
  pName,
  firstProductUrl,
  pName2,
  SecondProductUrl,
  Indursty,
  Lob1,
  Domain,
  useCase,
  newIntro,
  newOutro,
  newIntro2,
  grabber,
  demo_steps,
  intro1Value,
  BPFValue,
  stepDraftColor,
  right_business_outcomes,
  selected_outro,
  selected_business_process_flow,
  is_added_to_script_bpf,
  is_added_to_script_intro,
  is_added_to_script_outro,
  business_outcomes_array,
  just_cloned
) {
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyData = JSON.stringify({
    id,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    third_product_name: "",
    third_product_url: "",
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    intro: grabber || newIntro,
    outro: newOutro,
    intro_solution: newIntro2,
    demo_steps,
    intro_model_response: intro1Value,
    business_process_flow: BPFValue,
    draft_state: stepDraftColor,
    right_business_outcomes: right_business_outcomes,
    selected_business_process_flow: selected_business_process_flow,
    selected_outro: selected_outro,
    is_added_to_script_bpf,
    is_added_to_script_intro,
    is_added_to_script_outro,
    business_outcomes: business_outcomes_array,
    just_cloned,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/save-process-state", {
      method: "POST",
      body: bodyData,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// sampler type save api
export function SaveSamplerState(
  id,
  pName,
  pName2,
  useCase,
  Indursty,
  Lob1,
  Domain,
  VideoName,
  firstProductUrl,
  SecondProductUrl,
  demo_steps,
  extrasss5,
  newIntro,
  newIntro2,
  newOutro,
  intro1Value,
  selected_outro,
  isaddToScript_Intro,
  isaddToScript_Outro,
  draft_state,
  just_cloned
) {
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyDate = JSON.stringify({
    id: id,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    demo_steps: demo_steps,
    intro: newIntro,
    intro_solution: newIntro2,
    outro: newOutro,
    is_added_to_script_intro: isaddToScript_Intro,
    is_added_to_script_outro: isaddToScript_Outro,
    intro_model_response: intro1Value,
    selected_outro: selected_outro,
    draft_state: draft_state,
    just_cloned,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/sampler-save-state", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// explainer save api
export function SaveExaplainerState(
  id,
  pName,
  pName2,
  useCase,
  Indursty,
  Lob1,
  Domain,
  VideoName,
  firstProductUrl,
  SecondProductUrl,
  demo_steps,
  extrasss5,
  newIntro,
  newIntro2,
  newOutro,
  intro1Value,
  selected_outro,
  isaddToScript_Intro,
  isaddToScript_Outro,
  draft_state,
  just_cloned
) {
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyDate = JSON.stringify({
    id: id,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    demo_steps: demo_steps,
    intro: newIntro,
    intro_solution: newIntro2,
    outro: newOutro,
    is_added_to_script_intro: isaddToScript_Intro,
    is_added_to_script_outro: isaddToScript_Outro,
    intro_model_response: intro1Value,
    selected_outro: selected_outro,
    draft_state: draft_state,
    just_cloned,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/explainer-save-state", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// Task video save api
export function SaveTaskState(
  id,
  pName,
  pName2,
  useCase,
  Indursty,
  Lob1,
  Domain,
  VideoName,
  firstProductUrl,
  SecondProductUrl,
  demo_steps,
  extrasss5,
  newIntro,
  newIntro2,
  newOutro,
  intro1Value,
  selected_outro,
  isaddToScript_Intro,
  isaddToScript_Outro,
  draft_state,
  just_cloned
) {
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyDate = JSON.stringify({
    id: id,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    demo_steps: demo_steps,
    intro: newIntro,
    intro_solution: newIntro2,
    outro: newOutro,
    is_added_to_script_intro: isaddToScript_Intro,
    is_added_to_script_outro: isaddToScript_Outro,
    intro_model_response: intro1Value,
    selected_outro: selected_outro,
    draft_state: draft_state,
    just_cloned,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/task-save-state", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function SaveScriptInEditing(
  intro,
  bo1,
  bo2,
  bo3,
  bo4,
  demo1,
  demo2,
  demo3,
  demo4,
  outro
) {
  let token = localStorage.getItem("token");
  let id = localStorage.getItem("draftId");
  var bodyDate = JSON.stringify({
    id: id,
    selected_demo_one: demo1,
    selected_demo_two: demo2,
    selected_demo_three: demo3,
    selected_demo_four: demo4,
    business_outcomes: bo1,
    business_outcomes_two: bo2,
    business_outcomes_three: bo3,
    business_outcomes_four: bo4,
    intro: intro,
    outro: outro,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/state-save-feedback", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function ApprovalRequest(hublead, moveTo, id, deliveryDate, comment) {
  let token = localStorage.getItem("token");
  // let id = localStorage.getItem("draftId");
  var bodyDate = JSON.stringify({
    id: id,
    users: hublead,
    move_to: moveTo,
    due_date: deliveryDate,
    comment: comment,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/send-resquest", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetAllUsers(pageNum, users, columnName, order) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/getAllUsers", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        users: users,
        column: columnName,
        order: order,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetAllChatUsers(day, page, per_page, sort_by, sort_type) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const url =
    sort_by !== undefined
      ? "/api/recent-chat-sessions?days=" +
      day +
      "&page=" +
      page +
      "&per_page=" +
      per_page +
      "&sort_by=" +
      sort_by +
      "&sort_type=" +
      sort_type
      : "/api/recent-chat-sessions?days=" +
      day +
      "&page=" +
      page +
      "&per_page=" +
      per_page;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetUserRoles() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/all-roles", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function searchUsersData(pageNum, users, searchText, columnName, order) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/search/users", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        users: users,
        search: searchText,
        column: columnName,
        order: order,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function searchChatUsersActitvityData(day, page, searchText, per_page) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(
      baseUrl +
      `/api/recent-chat-sessions?search=${searchText}&page=${page}&per_page=${per_page}&days=${day}`,
      {
        method: "GET",
        // body: JSON.stringify({
        //   page: pageNum,
        //   users: users,
        //   search: searchText,
        //   column: columnName,
        //   order: order,
        // }),
        signal: controller.signal,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ApprovedDraftList(pageNum, drafts, columnName, order) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-teach-tori", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        drafts: drafts,
        column: columnName,
        order: order,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function searchToriTrainedData(
  pageNum,
  drafts,
  searchText,
  columnName,
  order
) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/search-teach-tori", {
      method: "POST",
      body: JSON.stringify({
        page: pageNum,
        drafts: drafts,
        search: searchText,
        column: columnName,
        order: order,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function InviteUser(userName, email, lastName, role, permission) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/addUser", {
      method: "POST",
      body: JSON.stringify({
        first_name: userName,
        last_name: lastName,
        email: email,
        role: role,
        permission: permission,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ApproveDraft() {
  let token = localStorage.getItem("token");
  let id = localStorage.getItem("draftId");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/approve-draft", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function DHLApproveRequest(state, id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/change-draft-state", {
      method: "POST",
      body: JSON.stringify({ id: id, state: state }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function SetPassword(password, confirm_password) {
  let token = localStorage.getItem("token2");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/resetPassword", {
      method: "POST",
      body: JSON.stringify({
        password: password,
        confirm_password: confirm_password,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function CheckLink() {
  let token = localStorage.getItem("token2");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/check-reset-link", {
      method: "POST",
      body: "",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status == 401) {
        //     window.location = "/";
        // } else {
        resolve(response.json());
        // }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ForgotPass(email) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/forgotPassword", {
      method: "POST",
      body: JSON.stringify({ email: email }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteUser(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/deleteUser", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteSessionService(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/delete-chat-session/${id}`, {
      method: "DELETE",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteAllSessionsService(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-all-chat-sessions", {
      method: "DELETE",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ActiveUser(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/activate-user", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ChangePermission(id, permission) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/changeUserPermission", {
      method: "POST",
      body: JSON.stringify({ id: id, permission: permission }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ChangeRole(id, role) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/change-user-role", {
      method: "POST",
      body: JSON.stringify({ id: id, role: role }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ChangeName(id, name) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/updateUser", {
      method: "POST",
      body: JSON.stringify({ id: id, name: name }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function EditDraft(id, name) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/editDraft", {
      method: "POST",
      body: JSON.stringify({ id: id, video_name: name }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function RequestUser({
  first_name,
  last_name,
  email,
  password,
  role,
  access_type,
}) {
  console.log(
    "freeeeeeee::>",
    first_name,
    last_name,
    email,
    password,
    role,
    access_type
  );
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/request-user", {
      method: "POST",
      body: JSON.stringify({
        first_name,
        last_name,
        email,
        password,
        role,
        access_type,
      }),
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function RequestUserDirectLogin(Fname, Lname, email) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/direct-login", {
      method: "POST",
      body: JSON.stringify({
        first_name: Fname,
        last_name: Lname,
        email: email,
      }),
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function AddComment(comment, section) {
  let id = localStorage.getItem("draftId");
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/add-comment", {
      method: "POST",
      body: JSON.stringify({
        draft_id: id,
        comment: comment,
        section: section,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ShareDraftHubLead(hubLead, draftId) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/move-draft-to-feedback", {
      method: "POST",
      body: JSON.stringify({ hub_lead: hubLead, draft_id: draftId }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function MoveToFeefBack(draftId) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/move-draft-back-to-feedback", {
      method: "POST",
      body: JSON.stringify({ draft_id: draftId }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DownloadFile(draftId) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-draft-data-for-documnet", {
      method: "POST",
      body: JSON.stringify({ draft_id: draftId }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function MoveToFeefFinalReview(draftId) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/send-draft-for-approval", {
      method: "POST",
      body: JSON.stringify({ id: draftId }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ShareDraft(UserId, DraftId) {
  let id = localStorage.getItem("draftId");
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/share-draft", {
      method: "POST",
      body: JSON.stringify({ user_id: UserId, draft_id: DraftId }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetAPIkey() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-model-endpoints", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetUserNameEmail() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-users-for-sharing", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetHubLeadList() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-hub-leads", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetSpecificHubLead(Lob1, ind, draft_id) {
  let token = localStorage.getItem("token");
  // let id = localStorage.getItem("draftId");
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-dhl-users", {
      method: "POST",
      body: JSON.stringify({
        line_of_business: new1 + new2 + new3,
        industry: ind,
        draft_id,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetDraftHubLeads(draft_id) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-draft-hubLead-names", {
      method: "POST",
      body: JSON.stringify({
        draft_id,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function RejectRequestService(id, comment) {
  let token = localStorage.getItem("token");
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/reject-resquest", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        comment: comment,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function getBusinessOutcomesList() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-business-outcomes", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function MarkAsReadNotification(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/mark-as-read", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function DeleteNotification(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-notification", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function MarkAllNotificationRead() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/mark-all-as-read", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteAllNotification() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-all-notifications", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

// Save Tori Chat
export function SaveToriChatService({
  question,
  answer,
  chat_id,
  chat_session_id,
  model,
  is_liked,
  is_disliked,
  reference_text,
}) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/save-tori-chat", {
      method: "POST",
      body: JSON.stringify({
        question,
        answer,
        chat_id,
        chat_session_id,
        model,
        is_liked,
        is_disliked,
        reference_text,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function OptionsToriChatService({ chat_id, option_index }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/tori-chat-option", {
      method: "POST",
      body: JSON.stringify({
        chat_id,
        option_index,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function DetailedToriChatService({ chat_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/tori-chat-detail", {
      method: "POST",
      body: JSON.stringify({
        chat_id,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function ToriChatDetailedShowHide({ chat_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/tori-chat-detail-show-hide", {
      method: "POST",
      body: JSON.stringify({
        chat_id,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function CategoryToriChatHelpService({ chat_id, category_index }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/chat-new/headings", {
      method: "POST",
      body: JSON.stringify({
        chat_id,
        category_index,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function HeadingToriChatHelpService({ chat_id, heading_index }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/chat-new/final-answer", {
      method: "POST",
      body: JSON.stringify({
        chat_id,
        heading_index,
      }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

// Get Chat Sessions
export function getToriChatSessions() {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-chat-sessions", {
      method: "GET",
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

// Get Session Chat
export function GetSessionChatService({ chat_session_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  const body = new FormData();
  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-session-chat", {
      method: "POST",
      body: JSON.stringify({ chat_session_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

// Post Clone of Draft
export function CloneOfDraft(draft_id, clone_to, duration) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  // body.append()
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/clone-draft", {
      method: "POST",
      body: JSON.stringify({ draft_id, clone_to, duration }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function logOut() {
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/";
}
export function isAuthenticated() {
  if (localStorage.getItem("token") && localStorage.getItem("userName")) {
    return true;
  }
  return false;
}

export function SaveScriptProvidedState(
  id,
  type,
  pName,
  pName2,
  useCase,
  Indursty,
  Lob1,
  Domain,
  VideoName,
  firstProductUrl,
  SecondProductUrl,
  script,
  draft_state
) {
  console.log("type::>", type);
  var new1 = Lob1.slice(0, 1)[0] == null ? "" : Lob1.slice(0, 1)[0] + ",";
  var new2 = Lob1.slice(1, 2)[0] == null ? "" : Lob1.slice(1, 2)[0] + ",";
  var new3 = Lob1.slice(2, 3)[0] == null ? "" : Lob1.slice(2, 3)[0];
  var IN = Indursty == null ? "X-Industry" : Indursty;

  let token = localStorage.getItem("token");
  var bodyDate = JSON.stringify({
    id: id,
    type: type,
    video_name: VideoName,
    first_product_name: pName,
    first_product_url: firstProductUrl,
    second_product_name: pName2,
    second_product_url: SecondProductUrl,
    industry: IN,
    line_of_business: new1 + new2 + new3,
    domain: Domain,
    description: useCase,
    intro_model_response: script,
    draft_state: draft_state,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/script-provided-save-state", {
      method: "POST",
      body: bodyDate,
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear();
          sessionStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
