import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { Tooltip, Typography } from "@mui/material";
import React from "react";
import info from "../assets/info.svg";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../environment";
const InputField = ({
  className = null,
  label = null,
  tooltipText = null,
  draftId = null,
  isShowId = false,
  placeholder = null,
  value = null,
  onChange = null,
  onBlur = null,
  isDisabled = false,
  isShowLabel = false,
  isShowErrorMessage = false,
  errorMessage = null,
  isShowLabelIcon = false,
  responseMessage = null,
  onFocus = null,
}) => {
  return (
    <div>
      {isShowLabel && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div>
            <span className="pName">{label}</span>
            {isShowLabelIcon && (
              <Tooltip
                arrow
                placement="right"
                title={<Typography fontSize={14}>{tooltipText}</Typography>}
              >
                <img
                  style={{
                    marginLeft: 5,
                    width: 20,
                    marginTop: -6,
                  }}
                  src={info}
                />
              </Tooltip>
            )}
          </div>
          {isShowId && (
            <div
              style={{
                float: "right",
                fontWeight: "bold",
                // marginTop: 10,
                fontSize: 15,
              }}
            >
              ID: {draftId}
            </div>
          )}
        </div>
      )}
      <GrammarlyEditorPlugin clientId={REACT_APP_GRAMMARLY_CLIENT_ID}>
        <input
          onFocus={onFocus}
          disabled={isDisabled}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          type="text"
        />
      </GrammarlyEditorPlugin>
      {isShowErrorMessage && (
        <span style={{ color: "red" }}>{errorMessage}</span>
      )}
      {responseMessage && (
        <p
          id="checkD"
          style={{
            color: "#0070D1",
            fontSize: "14px",
          }}
        >
          {responseMessage}
        </p>
      )}
    </div>
  );
};

export default InputField;
