import React, { useEffect, useState } from "react";
import {
  GetUserData,
  GetUserNameEmail,
  ShareDraft,
} from "../../../Services/Service";
import Select from "react-select";
import ToriLogo from "../../../assets/tori_logo.svg";
import { Modal } from "antd";
import { Typography } from "@mui/material";
const FeedbackShareModal = ({ id }) => {
  const [SelectUserName, setSelectUserName] = useState();
  const [UserDetails, setUserDetails] = useState();
  const [loaderDeleteConf, setloaderDeleteConf] = useState(false);
  const [shareDraftMsg, setShareDraftMsg] = useState("");
  const [shareDraftMsgN, setShareDraftMsgN] = useState("");

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const onSelectShareEmail = (e) => {
    setSelectUserName(e.value);
  };

  useEffect(() => {
    updateSidebar();
    GetUserNameEmail().then((res) => {
      setUserDetails(res.data);
    });
  }, []);

  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem("feedbackCount", JSON.stringify(res.data.feedback));
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem("finalReviewCount", JSON.stringify(res.data.teach_tori));
      }
    });
  };

  const onYesClickShare = () => {
    setloaderDeleteConf(true);
    ShareDraft(SelectUserName, id).then((res) => {
      if (res.status) {
        // alert(res.message)
        showModal2();
        setShareDraftMsg(res.message);
        location.reload();
        setloaderDeleteConf(false);
      } else {
        setloaderDeleteConf(false);
        // alert(res.message)
        handleCancel1();
        showModal3();
        setShareDraftMsgN(res.message);
      }
    });
  };
  return (
    <>
      <Typography onClick={showModal1} style={{ width: '100%', fontSize: 14 }}>
        Share
      </Typography>
      {/* SHARE MODAL */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Please Select User
          </h5>
        </div>

        <div>
          <label for="exampleInputEmail1">User Email</label>
          <Select
            onChange={onSelectShareEmail}
            options={UserDetails}
            placeholder="Select or begin typing"
          />
        </div>
        <div className="pt-3 d-flex justify-content-end">
          <div style={{ marginRight: 10 }}>
            <button
              onClick={handleCancel1}
              className="primatybuttonwhite w-inline-block"
            >
              Cancel
            </button>
          </div>
          <div>
           
              <button
                onClick={onYesClickShare}
                className="primatybuttonmodal w-inline-block"
              >
                 {loaderDeleteConf ? (
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : ("Share")}
              </button>
          </div>
        </div>
      </Modal>
      {/* SHARE MESSAGE MODAL */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen2}
        onCancel={handleCancel2}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${shareDraftMsg}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel2}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
      {/* share new message modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen3}
        onCancel={handleCancel3}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${shareDraftMsgN}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel3}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackShareModal;
