import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import "./App.css";
import "./Page2.css";
import { GetProcessHeatmapData, GetSpotlightHeatmapData } from "./Service";
import LoadingSpinner from "./assets/spinnerAdam.gif";
import HeatmapLoader from "./SubComponents/HeatmapLoader";
import DisplayModal from "./Screens/CreateDraft/Components/Modal";
import { Tabs } from "antd";
import ProcessHeatmap from "./SubComponents/Graphs/ProcessHeatmap";
function PageTwo() {
  const [loader, setloader] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  useLayoutEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    setloader(true);
    let root = am5.Root.new("chartdiv");
    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );
    var legend = chart.children.push(am5.Legend.new(root, {}));
    legend.data.setAll(chart.series.values);

    // Create axes and their renderers
    var yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 3,
      inversed: true,
    });

    yRenderer.grid.template.set("visible", false);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: "y",
      })
    );
    yRenderer.labels.template.setAll({
      fontSize: 12,
      fontFamily: " Helvetica Neue,Helvetica,Arial,sans-serif",
    });

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 0,
      opposite: true,
      // rotation: -90
    });
    xRenderer.labels.template.setAll({
      // marginTop:12,
      rotation: -90,
      centerY: am5.percent(10),
      centerX: am5.p0,
      paddingRight: 5,
      fontSize: 12,
      fontFamily: " Helvetica Neue,Helvetica,Arial,sans-serif",
    });
    xRenderer.grid.template.set("visible", false);
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "x",
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "truncate",
      maxHeight: 150,
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color("#FDFFF5"),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "x",
        categoryYField: "y",
        valueField: "heat",
      })
    );
    series.columns.template.setAll({
      tooltipText: "{x}\nvalue: {heat}",
      strokeOpacity: 1,
      strokeWidth: 1,
      width: am5.percent(100),
      height: am5.percent(100),
      // fill: am5.color('#541B0F')
    });
    // legends
    var legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        // centerX: am5.percent(50),
        fontSize: 12,
        fontFamily: " Helvetica Neue,Helvetica,Arial,sans-serif",
        x: am5.percent(50),
        y: am5.percent(-2),
        marginBottom: 8,
      })
    );

    legend.data.setAll([
      {
        name: "  None",
        color: am5.color("#D9D9D9"),
      },
      {
        name: "  Low (1-5)",
        color: am5.color("#9ED7FC"),
      },
      {
        name: "  Medium (5-10)",
        color: am5.color("#00CDFD"),
      },
      {
        name: "  High (10+)",
        color: am5.color("#26EA80"),
      },
    ]);
    // Set up heat rules
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/

    series.columns.template.adapters.add("fill", function (fill, target) {
      const heat = target.dataItem.dataContext.heat;
      if (heat <= 5 && heat > 0) {
        return am5.color("#9ED7FC");
      }
      if (heat <= 10 && heat > 5) {
        return am5.color("#00CDFD");
      }
      if (heat > 10) {
        return am5.color("#26EA80");
      } else {
        return am5.color("#D9D9D9");
      }
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          fill: am5.color("#000000"),
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          fontSize: 11,
          text: "{value}",
        }),
      });
    });
    setTimeout(() => {
      yAxis.children.moveValue(
        am5.Label.new(root, {
          rotation: -90,
          text: "Industries",
          y: am5.p50,
          centerX: am5.p50,
          fontWeight: 600,
          fontSize: 16,
          fontFamily: " Helvetica Neue,Helvetica,Arial,sans-serif",
        }),
        0
      );
    }, 3000);

    GetSpotlightHeatmapData()
      .then((result) => {
        var data = result.Product_Used_Industry;

        series.data.setAll(data);

        let yAxisData = [];
        let xAxisData = [];
        result.Industry_Count.forEach((element) => {
          let name = element.name;
          if (name === "Engineering, Construction, and Operations") {
            name = "EC&O";
          }
          yAxisData.push({ y: name });
        });

        result.Product_Count.forEach((element) => {
          let name = element.name;
          xAxisData.push({ x: name });
        });

        yAxis.data.setAll(yAxisData);
        xAxis.data.setAll(xAxisData);
        setTimeout(() => {
          document.getElementById("loader_id").style.display = "none";
        }, 6000);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        document.getElementById("loader_id").style.display = "none";
      });
    // ProcessHeatmapData()
    return () => {
      root.dispose();
    };
  }, []);
  return (
    <>
      <div id="loader_id" className="overlay">
        <div className="loaderImage-container">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      </div>
      {/* <div id="loader_id">
        <HeatmapLoader />
      </div> */}

      <div id="body">
        <div
          className="pt-3 pb-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <h3 style={{ fontWeight: "bolder" }}>Heat Map</h3>
        </div>
        <Tabs
          type="card"
          defaultActiveKey="1"
          centered
          items={[
            {
              label: "Spotlight",
              key: "1",
              children: (
                <div
                  id="heatmap-wrapper"
                  style={{
                    overflowX: "scroll",
                    marginBottom: 30,
                    backgroundColor: "white",
                    borderRadius: 6,
                  }}
                >
                  <div
                    style={{
                      paddingBlock: 10,
                      width: "5000px",
                      textAlign: "center",
                      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    <p style={{ paddingLeft: "10%" }}>Products</p>
                  </div>
                  <div
                    id="chartdiv"
                    style={{ width: "5000px", height: "1000px" }}
                  ></div>
                </div>
              ),
            },
            {
              label: "Process",
              key: "2",
              children: <ProcessHeatmap />,
            },
          ]}
        />

        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
      </div>
    </>
  );
}

export default PageTwo;
