import React, { useEffect, useState } from "react";
import { GetSpotlightHeatmapData } from "../../Service";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import DisplayModal from "../../Screens/CreateDraft/Components/Modal";

function SpotlightTypeBarCharts() {
  const [loading, setloading] = useState(true);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    GetSpotlightHeatmapData()
      .then(async (result) => {
        let graphDataRender = result.Product_Count.slice(0, 10).map((e) => {
          return e.name;
        });
        let graphDataRender2 = result.Product_Count.slice(0, 10).map((e) => {
          return e.value;
        });
        let IndgraphDataRender = result.Industry_Count.slice(1, 11).map((e) => {
          return e.name;
        });
        let IndgraphDataRender2 = result.Industry_Count.slice(1, 11).map(
          (e) => {
            return e.value;
          }
        );
        const ctx = document
          .getElementById("spotlight-graphs-div")
          .getContext("2d");
        const myChart = new Chart(ctx, {
          type: "horizontalBar",
          data: {
            labels: graphDataRender,
            datasets: [
              {
                label: "Respective Count",
                data: graphDataRender2,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
          },
        });
        const ctx1 = document.getElementById("myChart20").getContext("2d");
        const myChart1 = new Chart(ctx1, {
          type: "horizontalBar",
          data: {
            labels: IndgraphDataRender,
            datasets: [
              {
                label: "Respective Count",
                data: IndgraphDataRender2,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
          },
        });
        document.getElementById("spotlight-barchart-loader").style.display =
          "none";
        document.getElementById("show_spotlight_graph").style.display = "flex";
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        document.getElementById("spotlight-barchart-loader").style.display =
          "none";
      });
  }, []);

  return (
    <div>
      <div className="overlay" id="spotlight-barchart-loader">
        <div className="loaderImage-container">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      </div>
      <div
        style={{
          display: "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="show_spotlight_graph"
      >
        <div className="">
          <div className=""></div>
          <div className="" style={{ marginRight: "310px" }}>
            <div style={{ marginTop: 10 }}>
              <p
                style={{
                  textAlign: "center",
                  paddingTop: 10,
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: "310px",
                }}
              >
                Number of Scripts per Product (Top 10)
              </p>
              <div style={{ width: 600, marginBottom: 60 }}>
                <canvas
                  id="spotlight-graphs-div"
                  width="300"
                  height="300"
                ></canvas>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
        <div className="">
          <div className=""></div>
          <div className="">
            <div style={{ marginTop: 90 }}>
              <p
                style={{
                  textAlign: "center",
                  paddingTop: 10,
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Number of Scripts per Industry (Top 10)
              </p>
              <div style={{ width: 600, marginBottom: 120 }}>
                <canvas id="myChart20" width="300" height="300"></canvas>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
      </div>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </div>
  );
}

export default SpotlightTypeBarCharts;
