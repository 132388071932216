import { useState, useEffect, useRef, useLayoutEffect, useId } from "react";
import { useHistory, useParams } from "react-router";
import { Stack, Tooltip, Typography } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import { MdOutlineContentCopy } from "react-icons/md";
import { Avatar, Divider, Input, notification } from "antd";
import { MdSend } from "react-icons/md";
import {
  AiOutlineReload,
  AiOutlineDislike,
  AiOutlineLike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";

import {
  DislikeGeneratePrompt,
  DislikeWithoutLinks,
  GetDemoChatRefernenceText,
  GetSapWebRefernenceText,
  GetToriChatRefernenceText,
  GetToriHelpRefernenceText,
  RegenerateChatMessage,
  RegenerateFromDemoOrSap,
  ToriChatService,
  UpdateDislikeMessage,
  UpdateDislikeMessageUsingLinkText,
} from "../../Service";
import { scrollToBottom } from "../../../utils/helperfunctions/HelperFunctions";
import {
  DetailedToriChatService,
  GetSessionChatService,
  OptionsToriChatService,
  SaveToriChatService,
  ToriChatDetailedShowHide,
} from "../../Services/Service";
import {
  useGetToriChatSessionsQuery,
  useSaveToriChatMutation,
} from "../../../Redux/Slices/chatSession.slice";

import bg from "../../assets/chatBg.png";
import chatlogo from "../../assets/chatlogo.png";
// import chatlogo from "../../assets/testLogonTori.png";
import beta from "../../assets/betabutton.png";
// import beta from "../../assets/testLogo.png";
import logo from "../../assets/logo.png";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
// import LoadingSpinner from "../../assets/spinnerRedAdam.gif";
import "./ToriChat.css";
import DisplayModal from "../CreateDraft/Components/Modal";
import DislikeModal from "./DislikeModal";
import ComparisonChatModal from "./ComparisonChatModal";
import { useContext } from "react";
import chatSettingContext from "../../../context/ChatSettingContext";
import { Flex, IconButton, InputRightElement, Textarea } from "@chakra-ui/react";
import FeedbackModal from "./FeedbackModal";
import AdditionalInfoModal from "./AdditionalInfoModal";

const ToriChat = () => {
  const { showMoreContext, alternateLinksContext } = useContext(chatSettingContext);
  const history = useHistory();
  const { id } = useParams();
  const bottomRef = useRef(null);

  const [chatArray, setChatArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [newQuestion, setNewQuestion] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showFirstInput, setShowFirstInput] = useState(true);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [baseUrlModal, setBaseUrlModal] = useState("");
  const [model, setModel] = useState("");
  const [askQuery, setAskQuery] = useState();
  const [sessionId, setSessionId] = useState();
  const [chatId, setChatId] = useState();
  const [like, setLike] = useState(false);
  const [disLikeQuestion, setDisLikeQuestion] = useState("false");
  const [clickedIndex, setClickedIndex] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [clickedChatIndex, setClickedChatIndex] = useState("");
  const [detailClickIndex, setDetailClickIndex] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dislikeIndex, setDislikeIndex] = useState(false);
  const [chatText, setchatText] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [fileNameExten, setFileNameExten] = useState("");
  const [regeneratChat, setRegenerateChat] = useState(false);
  const [disLikeMsg, setDislikeMsg] = useState([]);
  const [customModal, setCustomModal] = useState(false);
  const [customModalMessage, setCustomModalMessage] = useState("Given the focus on streamlining AI activities at SAP, the strategic direction moving forward is to transition these capabilities the SIX organization who will be assessing how to bring these capabilities into the tools they offer.\n TORI Chat will be unsupported as of March 15, 2024.\n\nTori will be switched off starting on Dec 1st 2024.\n\nPlease save your work if necessary as Tori will not be available after that date.");
  const [feebackModal, setFeedbackModal] = useState(false);
  const [getFeedback, setGetFeedbacK] = useState(false);
  const [openAdditionalModal, setOpenAdditionalModal] = useState(false);

  // Cory CHat Setting REquirement
  const [alternateLinks, setAlternateLinks] = useState(false);
  const [showMoreChat, setShowMoreChat] = useState(false);
  // const [count, setCount] = useState('');
  let count = 1

  const userName = JSON.parse(localStorage.getItem("userData"));

  // const scrollToBottom = () => {
  //   if (bottomRef.current) {
  //     bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
  //   }
  // };
  // useEffect(() => {
  //   scrollToBottom();
  // }, [chatArray]);

  const questionRefs = {};

  function closeModalComapre() {
    setOpenAdditionalModal(false);
  }

  useEffect(() => {
    if (chatArray.length > 0) {
      const latestQuestionId = chatArray[chatArray.length - 1]?.id;
      scrollToQuestion(latestQuestionId);
    }
  }, [chatArray]);

  const scrollToQuestion = (questionId) => {
    if (questionRefs[questionId]) {
      questionRefs[questionId].scrollIntoView({ behavior: "smooth" });
    }
  };


  useEffect(() => {
    setAlternateLinks(alternateLinksContext);
  }, [alternateLinksContext]);


  useEffect(() => {
    setAlternateLinks(alternateLinksContext);
  }, [alternateLinksContext]);


  useLayoutEffect(() => {
    if (history?.location?.state?.from == "demostore_session") {
      setShowFirstInput(false);
      setBaseUrlModal(
        "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/demostore"
      );
      setchatText(
        "This is very new, TORI has ingested about 100 SAP S/4HANA \n demo scenarios. We are working on expanding as we test."
      );
      setChatArray([]);
      setAskQuery("");
      setModel("demo");
      setFileNameExten("");
    }
    if (history?.location?.state?.from == "sapwebsite_session") {
      setBaseUrlModal(
        "https://j69ns470ge.execute-api.us-west-2.amazonaws.com/query_chat"
      );
      setchatText("TORI has been trained on 250+ Product pages on sap.com.");
      setChatArray([]);
      setAskQuery("");
      setShowFirstInput(false);
      setModel("sap");
      setFileNameExten("");
    }

    if (history?.location?.state?.from == "tori_chat_session") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      setBaseUrlModal(
        "https://chslkhef8l.execute-api.us-west-2.amazonaws.com/query/botFinal"
      );
      setchatText("TORI has been trained on 250+ Product pages on sap.com.");
      setChatArray([]);
      setAskQuery("");
      setShowFirstInput(false);
      setModel("tori_chat");
      setFileNameExten("");
    }

    if (history?.location?.state?.from == "vector_store_session") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      setBaseUrlModal(
        "https://sik1e1lim0.execute-api.us-west-2.amazonaws.com/query/merger1"
      );
      // setchatText("TORI has been trained on 250+ Product pages on sap.com.");
      setChatArray([]);
      setAskQuery("");
      setShowFirstInput(false);
      setModel("vector_store");
      setFileNameExten("");
    }

    if (history?.location?.state?.from == "tori_help_session") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      setBaseUrlModal(
        "https://f9kinyvykb.execute-api.us-west-2.amazonaws.com/query/bot/help"
      );
      // setchatText("TORI has been trained on 250+ Product pages on sap.com.");
      setChatArray([]);
      setAskQuery("");
      setShowFirstInput(false);
      setModel("tori_help");
      setFileNameExten("");
    }

    if (history?.location?.state?.from == "demostore") {
      setBaseUrlModal(
        "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/demostore"
      );
      setchatText(
        "This is very new, TORI has ingested about 100 SAP S/4HANA demo scenarios. \n We are working on expanding as we test."
      );
      setChatArray([]);
      setAskQuery("");
      setModel("demo");
      setShowFirstInput(true);
      setFileNameExten("");
      setChatLoading(false);
    }
    // else {
    //   setShowFirstInput(true);
    // }
    if (history?.location?.state?.from == "sapwebsite") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      // setshowTimeOurError(true);
      setBaseUrlModal(
        "https://j69ns470ge.execute-api.us-west-2.amazonaws.com/query_chat"
      );
      setchatText(" TORI has been trained on 250+ Product pages on sap.com.");
      setChatArray([]);
      setAskQuery("");
      setModel("sap");
      setFileNameExten("");
      setShowFirstInput(true);
    }
    if (history?.location?.state?.from == "tori-chat") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      // setshowTimeOurError(true);
      setBaseUrlModal(
        "https://chslkhef8l.execute-api.us-west-2.amazonaws.com/query/botFinal"
      );
      setchatText(
        "Tori Chat includes 100 S/4HANA Demo Scenarios and 250+ Product pages on sap.com."
      );
      setChatArray([]);
      setAskQuery("");
      setModel("tori_chat");
      setFileNameExten("");
      setShowFirstInput(true);
    }
    if (history?.location?.state?.from == "tori-help") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      setBaseUrlModal(
        "https://37hamwa3n8.execute-api.us-west-2.amazonaws.com/query/SAPHelp"
      );
      setchatText(
        "SAP Help includes help.SAP.com for S/4HANA Public cloud edition English."
      );
      setChatArray([]);
      setAskQuery("");
      setModel("tori_help");
      setFileNameExten("");
      setShowFirstInput(true);
    }

    if (history?.location?.state?.from == "vector-store") {
      // setBaseUrlModal(baseUrl + "/api/demo-steps");
      // setshowTimeOurError(true);
      setBaseUrlModal(
        "https://sik1e1lim0.execute-api.us-west-2.amazonaws.com/query/merger1"
      );
      setchatText(
        "Tori Chat includes 100 S/4HANA Demo Scenarios and 250+ Product pages on sap.com."
      );
      setChatArray([]);
      setAskQuery("");
      setModel("vector_store");
      setFileNameExten("");
      setShowFirstInput(true);
    }
  }, [history?.location?.state?.from]);

  useEffect(() => {
    if (history?.location?.state?.from == "vector_store_session") {
      // setshowTimeOurError(true);
      getChatOfEverySession(id);
    } else {
      setChatArray([]);
      setChatId();
      setSessionId();
      setNewQuestion("");
      setFileNameExten("");
      setAskQuery("");
    }

    if (history?.location?.state?.from == "tori_chat_session") {
      // setshowTimeOurError(true);
      getChatOfEverySession(id);
    } else {
      setChatArray([]);
      setChatId();
      setSessionId();
      setNewQuestion("");
      setFileNameExten("");
      setAskQuery("");
    }

    if (history?.location?.state?.from == "tori_help_session") {
      // setshowTimeOurError(true);
      setModel("tori_help");
      getChatOfEverySession(id);
    } else {
      setChatArray([]);
      setChatId();
      setSessionId();
      setNewQuestion("");
      setFileNameExten("");
      setAskQuery("");
    }

    if (history?.location?.state?.from == "demostore_session") {
      getChatOfEverySession(id);
    } else {
      setChatArray([]);
      setChatId();
      setSessionId();
      setNewQuestion("");
      setFileNameExten("");
      setAskQuery("");
    }
    if (history?.location?.state?.from == "sap_session") {
      // setshowTimeOurError(true);
      getChatOfEverySession(id);
    } else {
      setChatArray([]);
      setChatId();
      setSessionId();
      setNewQuestion("");
      setFileNameExten("");
      setAskQuery("");
    }
  }, [id]);

  useEffect(() => {
    setChatArray([]);
    setAskQuery("");
  }, []);

  const getChatOfEverySession = async (idOfSession) => {
    setChatLoading(true);
    GetSessionChatService({ chat_session_id: idOfSession })
      .then((chat) => {
        if (chat.status) {
          const lastChatInd = chat?.data[chat?.data.length - 1];
          let fileName = lastChatInd?.reference_text?.split(".").pop();

          setFileNameExten(fileName);

          setChatArray(chat?.data);
          setChatId(lastChatInd?.id);
          setSessionId(lastChatInd?.chat_session_id);
          setChatLoading(false);
        } else {
          history.push("/user/tori-chat/help", { from: "tori-help" });
        }
      })
      .catch((error) => {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };

  const [addChat, { data: fetchData, isLoading: LoadingChat }] =
    useSaveToriChatMutation();

  const sendToriChatFirstTime = async (prop) => {
    setShowFirstInput(false);
    setChatLoading(true);
    let data = {
      question: prop, model
    }
    await addChat(data).then((res) => {
      if (res?.data?.status) {
        const newChat = res?.data?.chat;
        setChatArray([...chatArray, newChat]);
        setSessionId(newChat.chat_session_id);
        setChatId(newChat.chat_id);

        let fileName =
          newChat?.reference_text.split(".").pop()
        setFileNameExten(fileName);
        setChatLoading(false);

      } else {
        setChatLoading(false);
        setisSendReqMsg(true);
      }
    }).catch(error => {
      setChatLoading(false);

      // setshowTimeOurError(true);
      if (error.name === "AbortError") {
        setshowTimeOurError(true);
      } else {
        setisSendReqMsg(true);
      }
    });

    // try {

    // if (res?.status) {

    //   const newChat = res.chat;
    //   setChatArray([...chatArray, newChat]);
    //   setSessionId(newChat.chat_session_id);
    //   setChatId(newChat.chat_id);
    //   setChatLoading(false);
    //   // if (history?.location?.state?.from === "demostore") {
    //   //   response = await GetDemoChatRefernenceText(
    //   //     // res.text || res?.responceText
    //   //     prop
    //   //   );
    //   // }
    //   // // if (history?.location?.state?.from === "tori-help") {
    //   // //   response = await GetToriHelpRefernenceText(
    //   // //     // res.text || res?.responceText
    //   // //     res.text || res?.responceText
    //   // //   );
    //   // // }
    //   // if (history?.location?.state?.from === "tori-chat") {
    //   //   response = await GetToriChatRefernenceText(
    //   //     // res.text || res?.responceText
    //   //     res?.Reference_text
    //   //   );
    //   // }

    //   let fileName =
    //     newChat?.reference_text.split(".").pop() ||
    //     // res?.refernceText?.split(".").pop() ||
    //     // res?.refrence?.split(".").pop() ||
    //     // res?.sources?.split(".").pop() ||
    //     // "";

    //     setFileNameExten(fileName);
    //   // const dataToSend = {
    //   //   question: askQuery,
    //   //   answer: res.text || res?.responceText,
    //   //   reference_text:
    //   //     res?.refernceText ||
    //   //     res?.refrence ||
    //   //     response?.text ||
    //   //     res?.Reference ||
    //   //     res?.sources,
    //   //   model,
    //   // };

    //   // addChat(JSON.stringify(dataToSend));
    // } else {
    //   setChatLoading(false);
    //   setisSendReqMsg(true);
    // }
    // } catch (error) {
    //   setChatLoading(false);

    //   // setshowTimeOurError(true);
    //   if (error.name === "AbortError") {
    //     setshowTimeOurError(true);
    //   } else {
    //     setisSendReqMsg(true);
    //   }
    // }
  };

  const sendToriChatDataOnChatSession = async () => {
    setCustomModal(true)
    return
    if (newQuestion && newQuestion.trim().length != 0) {
      if (getFeedback) {
        setFeedbackModal(true)
      }
      setIsLoading(true);
      setChatArray([...chatArray, { question: newQuestion, answer: null, show_question: 1 }]);
      let data = {
        question: newQuestion, model, chat_session_id: sessionId,
      }

      await ToriChatService(data).then((res) => {
        if (res?.status) {
          setGetFeedbacK(res?.get_feedback)
          const newChat = res.chat;
          setChatArray([...chatArray, newChat]);
          setIsLoading(false);
          setNewQuestion("");
          // scrollToBottom();

          let fileName =
            newChat?.reference_text.split(".").pop()
          setFileNameExten(fileName);


        } else {
          setIsLoading(false);
          setisSendReqMsg(true);
        }
      }).catch((error) => {
        setIsLoading(false);
        // setshowTimeOurError(true);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        };


      })
    }
    // if (
    //   history?.location?.state?.from === "demostore" ||
    //   history?.location?.state?.from == "demostore_session"
    // ) {
    //   response = await GetDemoChatRefernenceText(
    //     // res.text || res?.responceText
    //     newQuestion
    //   );
    // }

    // if (
    //   history?.location?.state?.from === "tori-help" ||
    //   history?.location?.state?.from === "tori_help_session"
    // ) {
    //   response = await GetToriHelpRefernenceText(
    //     // res.text || res?.responceText
    //     res.text || res?.responceText
    //   );
    // }

    // if (
    //   history?.location?.state?.from === "tori-chat" ||
    //   history?.location?.state?.from == "tori_chat_session"
    // ) {
    //   response = await GetToriChatRefernenceText(
    //     // res.text || res?.responceText
    //     res?.Reference_text
    //   );
    // }





  }


  const CopyToClipBordPress = (answer) => {
    setOpen(true);
    navigator.clipboard.writeText(answer);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const regenerateMeaage = async (itm, index) => {
    setChatLoading(true);
    setRegenerateChat(true);

    try {
      const res = await RegenerateChatMessage(
        itm?.id
      );
      setCustomModalMessage(res?.message)
      if (res?.status) {

        const newChat = res.chat;
        // setChatArray([...chatArray, newChat]);
        // setIsLoading(false);
        // setNewQuestion("");
        // scrollToBottom();

        // setChatArray([
        //   ...chatArray.slice(0, index),
        //   newChat,
        //   ...chatArray.slice(index + 1),
        // ]);

        // setChatArray([...chatArray, newChat]);
        setChatArray([
          ...chatArray.slice(0, index),
          res?.chat,
          ...chatArray.slice(index + 1),
        ]);
        setChatLoading(false);

        let fileName =
          newChat?.reference_text.split(".").pop()
        setFileNameExten(fileName);
      } else {
        setChatLoading(false);
        setisSendReqMsg(true)
      }
    } catch (error) {
      setChatLoading(false);
      // setshowTimeOurError(true);
      if (error.name === "AbortError") {
        setshowTimeOurError(true);
      };


    }

    // try {
    //   const res = await ToriChatService(
    //     baseUrlModal,
    //     itm?.question + " " + "Regenerate with another response."
    //   );
    //   if (
    //     history?.location?.state?.from === "demostore" ||
    //     history?.location?.state?.from == "demostore_session"
    //   ) {
    //     response = await GetDemoChatRefernenceText(
    //       // res.text || res?.responceText
    //       itm?.question
    //     );
    //   }

    //   // if (
    //   //   history?.location?.state?.from === "tori-help" ||
    //   //   history?.location?.state?.from === "tori_help_session"
    //   // ) {
    //   //   response = await GetToriHelpRefernenceText(
    //   //     // res.text || res?.responceText
    //   //     res.text || res?.responceText
    //   //   );
    //   // }

    //   if (
    //     history?.location?.state?.from === "tori-chat" ||
    //     history?.location?.state?.from == "tori_chat_session"
    //   ) {
    //     response = await GetToriChatRefernenceText(
    //       // res.text || res?.responceText
    //       itm?.Reference_text
    //     );
    //   }

    //   let fileName =
    //     response?.text.split(".").pop() ||
    //     res?.refernceText?.split(".").pop() ||
    //     res?.refrence?.split(".").pop() ||
    //     res?.sources?.split(".").pop() ||
    //     "";
    //   setFileNameExten(fileName);

    //   const data = {
    //     question: itm?.question,
    //     answer: res.text || res?.responceText,
    //     chat_id: itm?.id,
    //     model,
    //     reference_text:
    //       res?.refernceText ||
    //       res?.refrence ||
    //       response?.text ||
    //       res?.metaData?.website_path ||
    //       res?.sources ||
    //       res?.Reference,
    //   };
    //   SaveToriChatService(data)
    //     .then((res) => {
    //       setChatArray([
    //         ...chatArray.slice(0, index),
    //         res?.chat,
    //         ...chatArray.slice(index + 1),
    //       ]);
    //       setChatLoading(false);
    //     })
    //     .catch((error) => {
    //       setChatLoading(false);
    //       setisSendReqMsg(true);
    //       if (error.name === "AbortError") {
    //         setshowTimeOurError(true);
    //       } else {
    //         setshowTimeOurError(true);
    //       }
    //     });
    // } catch (error) {
    //   setIsLoading(false);
    //   setChatLoading(false);
    //   // setshowTimeOurError(true);
    //   if (error.name === "AbortError") {
    //     setshowTimeOurError(true);
    //   } else {
    //     setshowTimeOurError(true);
    //   }
    // }
  };


  let chatlistArray = []

  const changeStateFromChildToParent = async (
    disLikeQuestion,
    data,
    response,
    modal,
    listArray
  ) => {
    if (modal === 'link' || modal === 'text' || modal === 'other') {
      if (modal === "link") {
        for (let ind = 0; ind < listArray.length; ind++) {
          setChatLoading(true);
          const dataToSend = {
            chat_id: disLikeQuestion?.id,
            type: modal,
            value: listArray[ind],
            link_index: ind
          };

          try {
            const saveRes = await UpdateDislikeMessageUsingLinkText(dataToSend);
            setCustomModalMessage("Thanks for the Feedback");

            if (saveRes.status) {
              if (modal === 'other') {
                setCustomModal(true);
              }
              // Concatenate the new chat with the existing chatArray
              setChatArray((prevChatArray) => [...prevChatArray, saveRes?.chat]);

              let fileName =
                newChat?.reference_text.split(".").pop();
              setFileNameExten(fileName);

              setChatLoading(false);
            } else {
              setisSendReqMsg(true);
              setChatLoading(false);
            }
          } catch (error) {
            // setshowTimeOurError(true);
            setChatLoading(false);
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
          }
        }
      } else {
        setChatLoading(true);
        const dataToSend = {
          chat_id: disLikeQuestion?.id,
          type: modal,
          value: response,
        };

        try {
          const saveRes = await UpdateDislikeMessageUsingLinkText(dataToSend);
          setCustomModalMessage("Thanks for the Feedback");

          if (saveRes.status) {
            if (modal === 'other') {
              setCustomModal(true);
              setChatArray((prevChatArray) => [
                ...prevChatArray.slice(0, dislikeIndex),
                saveRes?.chat,
                ...prevChatArray.slice(dislikeIndex + 1),
              ]);
            } else {
              setChatArray((prevChatArray) => [...prevChatArray, saveRes?.chat]);
            }
            // Concatenate the new chat with the existing chatArray
            // setChatArray((prevChatArray) => [
            //   ...prevChatArray.slice(0, dislikeIndex),
            //   saveRes?.chat,
            //   ...prevChatArray.slice(dislikeIndex + 1),
            // ]);

            let fileName =
              newChat?.reference_text.split(".").pop();
            setFileNameExten(fileName);

            closeModal();
            setChatLoading(false);
          } else {
            setisSendReqMsg(true);
            setChatLoading(false);
          }
        } catch (error) {
          // setshowTimeOurError(true);
          setChatLoading(false);
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
        }
      }
    } else {
      setChatLoading(true);
      const dataToSend = {
        new_ans: data?.answer,
        chat_id: disLikeQuestion?.id,
        model,
        new_ref: data?.reference_text,
        new_disc: data?.disclaimer,
      };
      try {
        const saveRes = await UpdateDislikeMessage(dataToSend);
        // Concatenate the new chat with the existing chatArray
        setChatArray((prevChatArray) => [
          ...prevChatArray.slice(0, dislikeIndex),
          saveRes?.chat,
          ...prevChatArray.slice(dislikeIndex + 1),
        ]);

        let fileName =
          newChat?.reference_text.split(".").pop();
        setFileNameExten(fileName);

        setChatLoading(false);
      } catch (error) {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      }
    }
  };

  // const changeStateFromChildToParent = async (
  //   disLikeQuestion,
  //   data,
  //   response,
  //   modal,
  //   listArray
  // ) => {
  //   if (modal === 'link' || modal === 'text' || modal === 'other') {
  //     if (modal === "link") {
  //       for (let ind = 0; ind < listArray.length; ind++) {
  //         setChatLoading(true);
  //         const dataToSend = {
  //           chat_id: disLikeQuestion?.id,
  //           type: modal,
  //           value: listArray[ind],
  //           link_index: ind
  //         };

  //         try {
  //           const saveRes = await UpdateDislikeMessageUsingLinkText(dataToSend);
  //           console.log("🚀 ~ file: ToriChat.js:663 ~ saveRes:", saveRes)
  //           setCustomModalMessage("Thanks for the Feedback")


  //           if (saveRes.status) {
  //             if (modal === 'other') {
  //               setCustomModal(true)
  //             }
  //             chatlistArray.push(saveRes?.chat)
  //             setChatArray([...chatArray, saveRes?.chat]);

  //             let fileName =
  //               newChat?.reference_text.split(".").pop()
  //             setFileNameExten(fileName);

  //             setChatLoading(false);
  //             console.log("🚀 chatArray:" + ind, chatArray)


  //           } else {
  //             setisSendReqMsg(true)
  //             setChatLoading(false);
  //           }


  //         } catch (error) {
  //           // setshowTimeOurError(true);
  //           setChatLoading(false);
  //           if (error.name === "AbortError") {
  //             setshowTimeOurError(true);
  //           }
  //         }
  //       }
  //     } else {
  //       setChatLoading(true);
  //       const dataToSend = {
  //         chat_id: disLikeQuestion?.id,
  //         type: modal,
  //         value: response,
  //       };

  //       try {
  //         const saveRes = await UpdateDislikeMessageUsingLinkText(dataToSend);
  //         console.log("🚀 ~ file: ToriChat.js:647 ~ saveRes:", saveRes)
  //         setCustomModalMessage("Thanks for the Feedback")


  //         if (saveRes.status) {
  //           if (modal === 'other') {
  //             setCustomModal(true)
  //           }
  //           setChatArray([
  //             ...chatArray.slice(0, dislikeIndex),
  //             saveRes?.chat,
  //             ...chatArray.slice(dislikeIndex + 1),
  //           ]);

  //           let fileName =
  //             newChat?.reference_text.split(".").pop()
  //           setFileNameExten(fileName);

  //           closeModal()
  //           setChatLoading(false);


  //         } else {
  //           setisSendReqMsg(true)
  //           setChatLoading(false);
  //         }


  //       } catch (error) {
  //         // setshowTimeOurError(true);
  //         setChatLoading(false);
  //         if (error.name === "AbortError") {
  //           setshowTimeOurError(true);
  //         }
  //       }
  //     }
  //   } else {
  //     setChatLoading(true);
  //     const dataToSend = {
  //       new_ans: data?.answer,
  //       chat_id: disLikeQuestion?.id,
  //       model,
  //       new_ref: data?.reference_text,
  //       new_disc: data?.disclaimer,
  //     };
  //     try {
  //       const saveRes = await UpdateDislikeMessage(dataToSend);
  //       setChatArray([
  //         ...chatArray.slice(0, dislikeIndex),
  //         saveRes?.chat,
  //         ...chatArray.slice(dislikeIndex + 1),
  //       ]);

  //       let fileName =
  //         newChat?.reference_text.split(".").pop()
  //       setFileNameExten(fileName);

  //       setChatLoading(false);
  //     } catch (error) {
  //       setChatLoading(false);
  //       if (error.name === "AbortError") {
  //         setshowTimeOurError(true);
  //       }
  //     }
  //   }

  // };



  const AskYourQuestion = () => {
    setCustomModal(true)
    return
    if (askQuery && askQuery.trim().length != 0) {
      sendToriChatFirstTime(askQuery);
    }
  };
  const handleKeypress = (e) => {
    setCustomModal(true)
    return
    if (e.key === "Enter" && !e.shiftKey) {
      if (askQuery && askQuery.trim().length != 0) {
        resetTextareaSize()
        sendToriChatFirstTime(askQuery);
      }
    }
  };

  const handleKeypressChatSession = (e) => {
    setCustomModal(true)
    return
    if (e.key === "Enter" && !e.shiftKey) {
      resetTextareaSize()
      sendToriChatDataOnChatSession();
    }
  };

  const handleLike = async (itm, index) => {
    // if (variant === "filled") {
    setChatLoading(true);
    // setLike(false);

    // await ToriChatService(baseUrlModal, " I like your response. Thank you.");
    //   openNotification(modelResponse?.text);
    // })

    let data = {
      chat_id: itm?.id,
      question: itm?.question,
      answer: itm?.answer,
      is_liked: 1,
    };
    SaveToriChatService(data)
      .then((res) => {
        // const lastChatInd = res?.data[res?.data.length - 1];

        // setChatArray([...chatArray, res]);
        setChatArray([
          ...chatArray.slice(0, index),
          res?.chat,
          ...chatArray.slice(index + 1),
        ]);
        setChatId(res?.id);
        setSessionId(res?.chat_session_id);
        setChatLoading(false);
      })
      .catch((error) => {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };

  const handleDislike = (itm, index) => {
    let data = {
      chat_id: itm?.id,
    };
    setDisLikeQuestion(itm);
    // if (variant === "filled") {
    if (!itm.options) {
      setOpenAdditionalModal(true)
      DislikeWithoutLinks(data)
        .then((res) => {
          if (res?.status) {
            setDisLikeQuestion(itm);
            setChatArray([
              ...chatArray.slice(0, index),
              res?.chat,
              ...chatArray.slice(index + 1),
            ]);
            setChatId(res?.id);
            setSessionId(res?.chat_session_id);
            setChatLoading(false);
          } else {
            setCustomModal(true);
            setChatLoading(false);
          }
        })
        .catch((error) => {
          setChatLoading(false);
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
        });
      return;
    }
    setChatLoading(true);
    setLike(false);
    setDislikeIndex(index);
    // let dislikeData = {
    //   chat_id: itm?.id,
    //   question: itm?.question,
    //   answer: itm?.answer,
    //   is_disliked: 1,
    // };

    // SaveToriChatService(dislikeData).then((res) => {
    //   // setIsOpen(true);
    //   // setChatArray([...chatArray, res]);

    //   setChatArray([
    //     ...chatArray.slice(0, index),
    //     res?.chat,
    //     ...chatArray.slice(index + 1),
    //   ]);
    //   setChatId(res?.id);
    //   setSessionId(res?.chat_session_id);
    //   // setChatLoading(false);
    // })
    //   .catch((error) => {
    //     setChatLoading(false);
    //     if (error.name === "AbortError") {
    //       setshowTimeOurError(true);
    //     }
    //   });

    setCustomModalMessage("Something Went Wrong")
    DislikeGeneratePrompt(data)
      .then((res) => {
        if (res?.status) {
          // const lastChatInd = res?.data[res?.data.length - 1];
          setIsOpen(true);
          // setChatArray([...chatArray, res]);
          let data = {
            answer: res?.new_ans,
            reference_text: res?.new_ref,
            disclaimer: res?.new_disc

          }
          setDislikeMsg(data)
          setChatArray([
            ...chatArray.slice(0, index),
            res?.chat,
            ...chatArray.slice(index + 1),
          ]);
          setChatId(res?.id);
          setSessionId(res?.chat_session_id);
          setChatLoading(false);
        } else {
          setCustomModal(true);
          setChatLoading(false);
        }
      })
      .catch((error) => {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  };

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const regenerateFromOtherChatBots = async (itm, index, url) => {
    setChatLoading(true);
    setRegenerateChat(true);

    RegenerateFromDemoOrSap(itm?.id).then((res) => {
      if (res?.status) {
        const newChat = res.chat;
        // setChatArray([...chatArray, newChat]);
        // setIsLoading(false);
        // setNewQuestion("");
        // scrollToBottom();

        // setChatArray([
        //   ...chatArray.slice(0, index),
        //   newChat,
        //   ...chatArray.slice(index + 1),
        // ]);

        setChatArray([...chatArray, newChat]);
        setChatLoading(false);

        let fileName =
          newChat?.reference_text.split(".").pop()
        setFileNameExten(fileName);
      } else {
        setIsLoading(false);
        setisSendReqMsg(true);
      }
    }).catch((error) => {
      setIsLoading(false);
      // setshowTimeOurError(true);
      if (error.name === "AbortError") {
        setshowTimeOurError(true);
      };


    })

    let response;
    // try {
    //   const res = await ToriChatService(url, itm?.question);
    //   if (fileNameExten !== "pdf") {
    //     response = await GetDemoChatRefernenceText(itm?.question);
    //   } else {
    //     response = await GetSapWebRefernenceText(itm?.question);
    //   }

    //   let fileName =
    //     response?.text.split(".").pop() ||
    //     res?.refernceText?.split(".").pop() ||
    //     res?.Reference?.split(".").pop() ||
    //     res?.refrence?.split(".").pop();
    //   setFileNameExten(fileName);

    //   const data = {
    //     question: itm?.question,
    //     answer: res.text || res?.responceText,
    //     chat_session_id: itm?.chat_session_id,
    //     model,
    //     reference_text:
    //       res?.refernceText ||
    //       res?.refrence ||
    //       response?.text ||
    //       res?.Reference,
    //     // res?.metaData?.website_path,
    //   };
    //   SaveToriChatService(data)
    //     .then((res) => {
    //       // setChatArray([
    //       //   ...chatArray.slice(0, index),
    //       //   res?.chat,
    //       //   ...chatArray.slice(index + 1),
    //       // ]);
    //       setChatArray([...chatArray, res?.chat]);
    //       setChatLoading(false);
    //     })
    //     .catch((error) => {
    //       setChatLoading(false);
    //       setisSendReqMsg(true);
    //       if (error.name === "AbortError") {
    //         setshowTimeOurError(true);
    //       } else {
    //         setshowTimeOurError(true);
    //       }
    //     });
    // } catch (error) {
    //   setIsLoading(false);
    //   setChatLoading(false);
    //   // setshowTimeOurError(true);
    //   if (error.name === "AbortError") {
    //     setshowTimeOurError(true);
    //   } else {
    //     setshowTimeOurError(true);
    //   }
    // }
  };

  const regenerateVectorStoreFromDemo = async (itm, index, url) => {
    setChatLoading(true);
    setRegenerateChat(true);
    let response;

    let data = {
      question: itm?.question + " " + "Regenerate with another response.", model, chat_id: itm?.id,
    }
    RegenerateFromDemoOrSap(itm?.id).then((res) => {
      if (res?.status) {
        const newChat = res.chat;
        // setChatArray([...chatArray, newChat]);
        // setIsLoading(false);
        // setNewQuestion("");
        // scrollToBottom();

        // setChatArray([
        //   ...chatArray.slice(0, index),
        //   newChat,
        //   ...chatArray.slice(index + 1),
        // ]);

        setChatArray([...chatArray, newChat]);
        setChatLoading(false);

        let fileName =
          newChat?.reference_text.split(".").pop()
        setFileNameExten(fileName);
      } else {
        setIsLoading(false);
        setisSendReqMsg(true);
      }
    }).catch((error) => {
      setIsLoading(false);
      // setshowTimeOurError(true);
      if (error.name === "AbortError") {
        setshowTimeOurError(true);
      };


    })

    // try {
    //   const res = await ToriChatService(url, itm?.question);
    //   // response = await GetDemoChatRefernenceText(itm?.question);

    //   let fileName =
    //     response?.text.split(".").pop() ||
    //     res?.refernceText?.split(".").pop() ||
    //     res?.Reference?.split(".").pop() ||
    //     res?.refrence?.split(".").pop() ||
    //     res?.sources?.split(".").pop() ||
    //     "";
    //   setFileNameExten(fileName);

    //   const data = {
    //     question: itm?.question,
    //     answer: res.text || res?.responceText,
    //     chat_session_id: itm?.chat_session_id,
    //     model,
    //     reference_text:
    //       res?.refernceText ||
    //       res?.refrence ||
    //       response?.text ||
    //       res?.sources ||
    //       res?.Reference,
    //     // res?.metaData?.website_path,
    //   };
    //   SaveToriChatService(data)
    //     .then((res) => {
    //       // setChatArray([
    //       //   ...chatArray.slice(0, index),
    //       //   res?.chat,
    //       //   ...chatArray.slice(index + 1),
    //       // ]);
    //       setChatArray([...chatArray, res?.chat]);
    //       setChatLoading(false);
    //     })
    //     .catch((error) => {
    //       setChatLoading(false);
    //       setisSendReqMsg(true);
    //       if (error.name === "AbortError") {
    //         setshowTimeOurError(true);
    //       } else {
    //         setshowTimeOurError(true);
    //       }
    //     });
    // } catch (error) {
    //   setIsLoading(false);
    //   setChatLoading(false);
    //   // setshowTimeOurError(true);
    //   if (error.name === "AbortError") {
    //     setshowTimeOurError(true);
    //   } else {
    //     setshowTimeOurError(true);
    //   }
    // }
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  let historyState = history?.location?.state?.from;

  const [expandedItems, setExpandedItems] = useState([]);
  const [detailToggler, setDetailToggler] = useState(false);

  const lessDetailHandler = (item) => {
    setDetailToggler(!detailToggler)
    setDetailClickIndex(item?.id)
  }

  const renderTextWithLineBreaks = (text, item, indexOfChat) => {
    const lines = text?.split("\n").filter((line) => line.length >= 5);

    return lines?.map((line, index) => (
      <p key={index}>
        {line}
        {index === lines.length - 1 && showMoreContext && item?.reference_catagory ? (
          <span
            style={{
              paddingLeft: "3px",
              color: "#529BD2",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => detailedToriChatHandler(item, indexOfChat)}
          >
            More detail
          </span>
        ) : null}
      </p>
    ));
  };


  const renderTextWithLineBreaksForLessDetail = (text, item, indexChat) => {
    const lines = text?.split("\n").filter((line) => line.length >= 5);

    return lines?.map((line, index) => (
      <p key={index}>
        {line}
        {index === lines.length - 1 && showMoreContext ? (
          <span
            style={{
              paddingLeft: "3px",
              color: "#529BD2",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => toriChatDetailedShowHide(item, indexChat)}
          >
            Less detail
          </span>
        ) : null}
      </p>
    ));
  };




  const toggleItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const optionToriChatHandler = (itm, ind, indexOfChat) => {
    setChatLoading(true);
    let data = {
      chat_id: itm?.id,
      option_index: ind,
    }
    OptionsToriChatService(data)
      .then((res) => {
        // const lastChatInd = res?.data[res?.data.length - 1];
        setChatArray([...chatArray, res?.chat]);

        // setDislikeMsg(data)
        // setChatArray([
        //   ...chatArray.slice(0, indexOfChat),
        //   res?.chat,
        //   ...chatArray.slice(indexOfChat + 1),
        // ]);
        setChatId(res?.id);
        setSessionId(res?.chat_session_id);
        setChatLoading(false);
      })
      .catch((error) => {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  }

  const detailedToriChatHandler = (itm, index) => {

    if (itm?.answer_detail) {
      toriChatDetailedShowHide(itm, index)
    } else {
      setChatLoading(true);
      let data = {
        chat_id: itm?.id,
      }
      DetailedToriChatService(data)
        .then((res) => {
          // setDetailClickIndex(res?.chat?.id)
          // setDetailToggler(!detailToggler)
          setChatArray((prevChatArray) => [
            ...prevChatArray.slice(0, index),
            res?.chat,
            ...prevChatArray.slice(index + 1),
          ]);

          // setDislikeMsg(data)
          // setChatArray([
          //   ...chatArray.slice(0, indexOfChat),
          //   res?.chat,
          //   ...chatArray.slice(indexOfChat + 1),
          // ]);
          // setChatId(res?.id);
          // setSessionId(res?.chat_session_id);
          setChatLoading(false);
        })
        .catch((error) => {
          setChatLoading(false);
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
        });
    }

  }

  const toriChatDetailedShowHide = (itm, index) => {

    setChatLoading(true);
    let data = {
      chat_id: itm?.id,
    }
    ToriChatDetailedShowHide(data)
      .then((res) => {
        setDetailClickIndex(res?.chat?.id)
        setDetailToggler(!detailToggler)
        setChatArray((prevChatArray) => [
          ...prevChatArray.slice(0, index),
          res?.chat,
          ...prevChatArray.slice(index + 1),
        ]);

        // setDislikeMsg(data)
        // setChatArray([
        //   ...chatArray.slice(0, indexOfChat),
        //   res?.chat,
        //   ...chatArray.slice(indexOfChat + 1),
        // ]);
        // setChatId(res?.id);
        // setSessionId(res?.chat_session_id);
        setChatLoading(false);
      })
      .catch((error) => {
        setChatLoading(false);
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
      });
  }

  const textareaRef = useRef(null);

  const TextAreaAutoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3rem";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight > 220) {
      textarea.style.height = "220px"; // Set a max height of 212px
      textarea.style.overflow = "auto"; // Add scrollbar
    } else {
      textarea.style.height = `${scrollHeight}px`; // Adjust height based on content
      textarea.style.overflow = "hidden"; // Hide scrollbar if not needed
    }
  };

  const resetTextareaSize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "4.5rem";
    textarea.style.overflow = "hidden";
  };

  return (
    <>
      {chatLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      {showFirstInput && (
        <div className="parent-bg">
          <Stack justifyContent="center" width="100%" alignItems={"center"}>
            {/* header */}
            <Stack alignItems={"center"} spacing={2} marginTop="200px">
              <img style={{ width: "70px" }} src={chatlogo} />
              <Stack alignItems="center" spacing={1} direction="row">
                <Typography style={{ fontWeight: 700, fontSize: 18 }}>
                  Tori Chat
                </Typography>
                <img style={{ width: 50 }} src={beta} />
              </Stack>
            </Stack>
            {/* Search Bar */}
            <Stack alignItems={"center"} spacing={1} marginTop="30px">
              {chatText.split("\n").map((line, index) => (
                <Typography
                  style={{
                    color: "#637381",
                    fontSize: "15px",
                    width: "80%",
                    textAlign: "center",
                    margin: "0px",
                  }}
                  key={index}
                >
                  {line}
                </Typography>
              ))}

              <div className="chatInput-inner">
                <textarea
                  ref={textareaRef}
                  className={`custom-textarea`}
                  onChange={(e) => setAskQuery(e.target.value)}
                  value={askQuery}
                  onKeyDown={handleKeypress}
                  onInput={TextAreaAutoResize}
                // disabled={
                //   isLoading ||
                //   chatLoader ||
                //   (momentType == "single" && selectedValue == "")
                // }
                />
                <span
                  className="send-btn"
                // onClick={() => {
                //   !chatInputText
                //     ? console.log("")
                //     : messages && messages.length > 0
                //       ? updateChat()
                //       : createNewChat();
                // }}
                >

                  <IconButton
                    aria-label="Send"
                    onClick={AskYourQuestion}
                    icon={<MdSend />}
                    color="#fff"
                    fontSize="24px"
                    className="custom-icon"
                    backgroundColor={"white"}
                    textColor={"grey"}
                  />
                </span>
              </div>

              <Typography style={{ color: "#637381", fontSize: "11px" }}>
                Tori-Chat Test November 2023, our open AI chat in its test version.
              </Typography>
            </Stack>
          </Stack>
          <img
            width={"100%"}
            height={"500px"}
            style={{
              position: "absolute",
              right: 50,
              zIndex: -999,
              top: 100,
            }}
            src={bg}
          />
        </div>
      )}

      {/* Q & A session */}
      {!showFirstInput && (
        <div>
          <Stack
            paddingTop={5}
            justifyContent="center"
            alignItems="center"
            width="100%"
            style={{ backgroundColor: "white" }}
          >
            <Stack
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                height: "93vh",
              }}
            >
              {/* chat */}
              <Stack
                id="chatbot"
                style={{ width: "100%" }}
                paddingX={10}
                alignItems="center"
                spacing={3}
                overflow={"auto"}

              >
                {/* question */}
                {
                  historyState === 'tori-help' || historyState === 'tori_help_session' ? (chatArray?.map((itm, index) => (
                    <>
                      {itm?.show_question ? (
                        <Stack
                          alignSelf="baseline"
                          width="90%"
                          paddingLeft={"25px"}
                          spacing={3}
                          alignItems="center"
                          direction="row"
                          key={index}
                          ref={(el) => (questionRefs[itm.id] = el)}
                        >
                          <Avatar
                            style={{
                              backgroundColor: "#E7AE3C",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {userName &&
                              userName.name
                                .match(/(\b\S)?/g)
                                .join("")
                                .match(/(^\S|\S$)?/g)
                                .join("")
                                .toUpperCase()}
                          </Avatar>
                          <Tooltip title={<span style={{ fontSize: '13px' }}>{itm?.translated_question}</span>}>
                            <Typography
                              style={{
                                fontSize: 14,
                                color: "#454F5B",
                                lineHeight: "20px",
                              }}
                            >
                              {itm?.question}
                            </Typography>
                          </Tooltip>
                        </Stack>
                      ) : null}
                      {/* answer */}
                      {isLoading &&
                        chatArray.length - 1 === index &&
                        itm?.answer === null ? (
                        <div>
                          <img width={80} height={80} src={LoadingSpinner} />
                        </div>
                      ) : (
                        <Stack
                          alignSelf={"start"}
                          style={{
                            border: "1px solid #C0C0C0",
                            borderRadius: "20px",
                          }}
                          padding={"25px"}
                          width="100%"
                          spacing={3}
                        >
                          <Stack direction="row" spacing={3}>
                            {/*Tori Logo in Chat box*/}

                            <Stack>
                              <img
                                style={{ width: "60px" }}
                                src={chatlogo}
                              />
                            </Stack>
                            {/*Answer In Chat Box*/}
                            <Stack width={'100%'}>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  color: "#454F5B",
                                  lineHeight: "20px",
                                }}
                              >
                                {itm?.show_detailed_answer ? renderTextWithLineBreaksForLessDetail(itm?.answer_detail, itm, index) : renderTextWithLineBreaks(itm?.answer, itm, index)}
                              </Typography>

                              {itm?.reference_label ? (
                                <a
                                  href={itm?.reference_text}
                                  target="_blank"
                                  style={{
                                    color: "#529BD2",
                                    marginBottom: "0px",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    width: "fit-content",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {itm?.chat_regenerated
                                    ? itm?.reference_label
                                    : itm?.reference_category || itm?.reference_catagory + " > " + itm?.reference_label}
                                </a>

                              ) : (
                                showMore && itm?.id === clickedChatIndex ? (
                                  <Stack>
                                    {itm?.reference_text == null || itm?.reference_text == "" || itm?.reference_text === 'There is no reference text.' ? (
                                      <Typography
                                        style={{
                                          color: "#7171e6",
                                          marginBottom: "0px",
                                          fontSize: "13px",
                                          width: "fit-content",
                                        }}
                                      >
                                        There is no reference link for the quoted text
                                      </Typography>
                                    ) : itm?.reference_text.split(".").pop() == "pdf" ? (
                                      <Typography
                                        style={{
                                          color: "#7171e6",
                                          marginBottom: "0px",
                                          fontSize: "13px",
                                          width: "fit-content",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {itm?.reference_text}
                                      </Typography>
                                    ) : (
                                      <a
                                        href={itm?.reference_text}
                                        target="_blank"
                                        style={{
                                          color: "#7171e6",
                                          marginBottom: "0px",
                                          fontSize: "13px",
                                          width: "fit-content",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {itm?.reference_text}
                                      </a>
                                    )}
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        cursor: "pointer",
                                        fontWeight: 600,
                                        marginTop: "0px",
                                        width: "fit-content",
                                      }}
                                      onClick={() => setShowMore(false)}
                                    >
                                      Hide Reference
                                    </Typography>
                                  </Stack>
                                ) : (
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                      fontWeight: 600,
                                    }}
                                    onClick={() => {
                                      setClickedChatIndex(itm?.id);
                                      setShowMore(true);
                                    }}
                                  >
                                    Show Reference
                                  </Typography>
                                )
                              )}


                              <div>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    color: "#454F5B",
                                    lineHeight: "20px",
                                    marginTop: "10px"
                                  }}
                                >
                                  {itm?.disclaimer}
                                </Typography>

                                {!alternateLinks && chatArray.length - 1 === index && <Typography
                                  style={{
                                    fontSize: 14,
                                    color: "#454F5B",
                                    lineHeight: "20px",
                                    marginTop: "10px"
                                  }}
                                >
                                  {itm?.options_subject}
                                </Typography>}
                              </div>
                              {/*Bubbles In Chat Box*/}
                              {!alternateLinks ? (<Stack spacing={8} direction={'row'} mt={2}>
                                <Stack
                                  direction="column"
                                  spacing={1}
                                >
                                  {itm?.options?.map((item, ind) => (
                                    <button
                                      key={ind}
                                      disabled={item?.visited}
                                      style={{
                                        border: '1px solid #D3D3D3',
                                        color: 'gray',
                                        padding: '7px 15px',
                                        borderRadius: '8px',
                                        cursor: item?.visited ? '' : 'pointer',
                                        textAlign: 'start',
                                        width: 'fit-content'
                                      }}
                                      onClick={() => optionToriChatHandler(itm, ind, index)}
                                    >
                                      <span style={{ fontWeight: '500', color: item?.visited ? 'gray' : 'black' }}>{item.Category?.substring(0, 50)}</span><span style={{ fontWeight: '400', fontSize: "12px", color: item?.visited ? 'gray' : 'black' }}>{" " + "(" + item?.Heading + ")"}</span>
                                    </button>
                                  ))}

                                  {/*<div
                                style={{
                                  border: '1px solid #D3D3D3',  // Add a 1-pixel grey border
                                  color: 'gray',             // Set the text color to grey
                                  padding: '5px',            // Add padding as needed
                                  borderRadius: '8px',
                                  cursor: 'pointer'
                                }}
                              >
                                <span style={{ fontWeight: '500' }}>Pricing and Condition</span>
                              </div>*/}
                                </Stack>
                              </Stack>) : null}
                            </Stack>

                            {/*Additional Link In Chat Box */}
                            {alternateLinks ? (
                              <Stack width={'50%'} direction={'row'}>
                                <div style={{ borderLeft: "1px solid #454F4B", height: "100%" }}></div>
                                <Stack

                                  direction="column"
                                  spacing={1}
                                  p={'0px 20px'}
                                >
                                  <Typography fontSize={17} fontWeight={600}>Additional Links</Typography>
                                  {
                                    itm?.options && itm.options.length > 0 ? (
                                      itm.options.map((item, ind) => (
                                        <Stack direction={"row"} spacing={1} alignItems={'center'} key={ind}>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            style={{
                                              color: "#529BD2",
                                              marginBottom: "0px",
                                              fontSize: "15px",
                                              fontWeight: "500",
                                              overflowWrap: 'break-word'
                                            }}
                                          >
                                            {item.Category?.substring(0, 50) + '>' + item?.Heading}
                                          </a>

                                          {item.visited ? (
                                            <div onClick={() => null} disabled={item.visited}>
                                            </div>
                                          ) : (
                                            chatArray.length - 1 === index ? (<Tooltip title={<span style={{ fontSize: '13px' }}>Regenerate response using this link</span>}>
                                              <div onClick={() => optionToriChatHandler(itm, ind, index)} disabled={item.visited}>
                                                <AiOutlineReload style={{ color: "#637381", fontSize: 13, cursor: 'pointer' }} />
                                              </div>
                                            </Tooltip>) : null
                                          )}
                                        </Stack>
                                      ))
                                    ) : (
                                      <p style={{ textAlign: "center", color: "grey", fontWeight: "500" }}>No additional links </p>
                                    )
                                  }


                                  {/*<div
                                style={{
                                  border: '1px solid #D3D3D3',  // Add a 1-pixel grey border
                                  color: 'gray',             // Set the text color to grey
                                  padding: '5px',            // Add padding as needed
                                  borderRadius: '8px',
                                  cursor: 'pointer'
                                }}
                              >
                                <span style={{ fontWeight: '500' }}>Pricing and Condition</span>
                              </div>*/}
                                </Stack>
                              </Stack>
                            ) : null}
                          </Stack>

                          <Stack
                            width="100%"
                            justifyContent={"space-between"}
                            direction="row"
                          >
                            {chatArray.length - 1 === index ? (
                              <Stack
                                onClick={() => regenerateMeaage(itm, index)}
                                // onClick={() => { }}
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                className="send-button"

                              >
                                <AiOutlineReload
                                  style={{ color: "#637381", fontSize: 13 }}
                                />
                                <Typography
                                  style={{
                                    color: "#454F5B",
                                    fontSize: 13,
                                  }}
                                >
                                  Regenerate
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                              ></Stack>
                            )}

                            <Stack
                              alignItems="center"
                              direction="row"
                              spacing={2}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                onClick={() => setClickedIndex(itm?.id)}
                              >
                                {
                                  itm?.is_liked == 0 && itm?.is_disliked == 0 ? (
                                    <Stack direction={"row"} spacing={0.5}>
                                      <AiOutlineLike
                                        style={{
                                          color: like,
                                          fontSize: 18,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleLike(itm, index);
                                        }}
                                      />
                                      <AiOutlineDislike
                                        style={{
                                          color: like,
                                          fontSize: 18,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleDislike(itm, index);
                                        }}
                                      />
                                    </Stack>
                                  ) : itm?.is_liked ? (
                                    <AiFillLike
                                      style={{
                                        color: like,
                                        fontSize: 18,
                                      }}

                                    />
                                  ) : (
                                    <AiFillDislike
                                      style={{
                                        color: like,
                                        fontSize: 18,
                                      }}

                                    />
                                  )

                                }

                              </Stack>
                              <Stack
                                className="send-button"
                                spacing={1}
                                alignItems={"center"}
                                direction="row"
                                onClick={() => CopyToClipBordPress(itm?.answer)}
                              >
                                <Typography
                                  style={{ color: "#637381", fontSize: 16 }}
                                >
                                  Copy
                                </Typography>
                                <MdOutlineContentCopy
                                  style={{ color: "#637381", fontSize: 16 }}
                                />
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </>
                  ))) : (chatArray?.map((itm, index) => (
                    <>
                      <Stack
                        alignSelf="baseline"
                        width="90%"
                        paddingLeft={"25px"}
                        spacing={3}
                        alignItems="center"
                        direction="row"
                        key={index}
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#E7AE3C",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          {userName &&
                            userName.name
                              .match(/(\b\S)?/g)
                              .join("")
                              .match(/(^\S|\S$)?/g)
                              .join("")
                              .toUpperCase()}
                        </Avatar>
                        <Typography
                          style={{
                            fontSize: 14,
                            color: "#454F5B",
                            lineHeight: "20px",
                          }}
                        >
                          {itm?.question}
                        </Typography>
                      </Stack>
                      {/* answer */}
                      {isLoading &&
                        chatArray.length - 1 === index &&
                        itm?.answer === null ? (
                        <div>
                          <img width={80} height={80} src={LoadingSpinner} />
                        </div>
                      ) : (
                        <Stack
                          alignSelf={"baseline"}
                          style={{
                            border: "1px solid #C0C0C0",
                            borderRadius: "20px",
                          }}
                          padding={"25px"}
                          width="100%"
                          spacing={5}
                          alignItems="baseline"
                        >
                          <Stack direction="row" spacing={3}>
                            <img
                              style={{ width: "45px", height: "45px" }}
                              src={chatlogo}
                            />
                            <Stack>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  color: "#454F5B",
                                  lineHeight: "20px",
                                }}
                              >
                                {renderTextWithLineBreaks(itm?.answer)}

                              </Typography>
                              {showMore && itm?.id === clickedChatIndex ? (
                                <Stack>
                                  {itm?.reference_text == null || itm?.reference_text === 'There is no reference text.' ? (
                                    <Typography
                                      style={{
                                        color: "#7171e6",
                                        marginBottom: "0px",
                                        fontSize: "13px",
                                        width: "fit-content",
                                      }}
                                    >
                                      There is no reference link for the quoted
                                      text
                                    </Typography>
                                  ) : itm?.reference_text.split(".").pop() ==
                                    "pdf" ? (
                                    <Typography
                                      style={{
                                        color: "#7171e6",
                                        marginBottom: "0px",
                                        fontSize: "13px",
                                        width: "fit-content",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {itm?.reference_text}
                                    </Typography>
                                  ) : (
                                    <a
                                      href={itm?.reference_text}
                                      target="_blank"
                                      style={{
                                        color: "#7171e6",
                                        marginBottom: "0px",
                                        fontSize: "13px",
                                        width: "fit-content",
                                        wordBreak: "break-all"
                                      }}
                                    >
                                      {itm?.reference_text}
                                    </a>
                                  )}
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                      fontWeight: 600,
                                      marginTop: "0px",
                                      width: "fit-content",
                                    }}
                                    onClick={() => setShowMore(false)}
                                  >
                                    Hide Reference
                                  </Typography>
                                </Stack>
                              ) : (
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    fontWeight: 600,
                                  }}
                                  onClick={() => {
                                    setClickedChatIndex(itm?.id);
                                    setShowMore(true);
                                  }}
                                >
                                  Show Reference
                                </Typography>
                              )}

                              {chatArray.length - 1 === index &&
                                (historyState === "tori-help" ||
                                  historyState === "tori_help_session") ? (
                                <Stack
                                  onClick={() => { }
                                    // regenerateFromOtherChatBots(
                                    //   itm,
                                    //   index,
                                    //   "https://koazvlsylj.execute-api.us-west-2.amazonaws.com/query/sapweb"
                                    // )
                                  }
                                  alignItems="center"
                                  direction="row"
                                  mt='20px'
                                  spacing={1}
                                  className="send-button"
                                >
                                  <button
                                    // onClick={onClickYesConfirm}
                                    style={{
                                      backgroundColor: "#306DCA",
                                      color: "white",
                                      padding: "2px 5px",
                                      borderRadius: "5px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Regenerate from SAP website
                                  </button>
                                </Stack>
                              ) : null}
                            </Stack>
                          </Stack>
                          <Stack
                            width="100%"
                            justifyContent={"space-between"}
                            direction="row"
                          >
                            {chatArray.length - 1 === index ? (
                              <Stack
                                onClick={() => regenerateMeaage(itm, index)}
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                className="send-button"
                              >
                                <AiOutlineReload
                                  style={{ color: "#637381", fontSize: 13 }}
                                />
                                <Typography
                                  style={{
                                    color: "#454F5B",
                                    fontSize: 13,
                                  }}
                                >
                                  Regenerate
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                              ></Stack>
                            )}



                            {chatArray.length - 1 === index &&
                              fileNameExten === "pdf" &&
                              (historyState === "tori-chat" ||
                                historyState === "tori_chat_session") ? (
                              <Stack
                                onClick={() =>
                                  regenerateFromOtherChatBots(
                                    itm,
                                    index,
                                    "https://koazvlsylj.execute-api.us-west-2.amazonaws.com/query/sapweb"
                                  )
                                }
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                className="send-button"
                              >
                                <button
                                  // onClick={onClickYesConfirm}
                                  style={{
                                    backgroundColor: "#306DCA",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Regenerate from SAP website
                                </button>
                              </Stack>
                            ) : null}

                            {chatArray.length - 1 === index &&
                              fileNameExten !== "pdf" &&
                              (userData?.role === "Admin" ||
                                userData?.email === "girish.kini@sap.com" ||
                                userData?.email === "tim.hatcher@sap.com" ||
                                userData?.email === "marcel.lindsay@sap.com" ||
                                userData?.email === "ana.vibancos@sap.com" ||
                                userData?.email === "jorge.ivan.arellano@sap.com" ||
                                userData?.email === "philip.transfeld@sap.com" ||
                                userData?.email === "murali.reddygari@sap.com" ||
                                userData?.email === "tr.smith@sap.com" ||
                                userData?.email === "michele.krom@sap.com" ||
                                userData?.email === "testuserqa@sap.com") &&
                              (historyState === "vector-store" ||
                                historyState === "vector_store_session") ? (
                              <Stack
                                onClick={() =>
                                  regenerateVectorStoreFromDemo(
                                    itm,
                                    index,
                                    "https://9jrlbh3c93.execute-api.us-west-2.amazonaws.com/query/Demostore1"
                                  )
                                }
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                className="send-button"
                              >
                                <button
                                  // onClick={onClickYesConfirm}
                                  style={{
                                    backgroundColor: "#306DCA",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Regenerate from Demo Store
                                </button>
                              </Stack>
                            ) : null}

                            {chatArray.length - 1 === index &&
                              fileNameExten === "pdf" &&
                              (userData?.role === "Admin" ||
                                userData?.email === "girish.kini@sap.com" ||
                                userData?.email === "tim.hatcher@sap.com" ||
                                userData?.email === "marcel.lindsay@sap.com" ||
                                userData?.email === "ana.vibancos@sap.com" ||
                                userData?.email === "jorge.ivan.arellano@sap.com" ||
                                userData?.email === "philip.transfeld@sap.com" ||
                                userData?.email === "murali.reddygari@sap.com" ||
                                userData?.email === "tr.smith@sap.com" ||
                                userData?.email === "michele.krom@sap.com" ||
                                userData?.email === "testuserqa@sap.com") &&
                              (historyState === "vector-store" ||
                                historyState === "vector_store_session") ? (
                              <Stack
                                onClick={() =>
                                  regenerateVectorStoreFromDemo(
                                    itm,
                                    index,
                                    "https://wif4mgaqxe.execute-api.us-west-2.amazonaws.com/query/Website1"
                                  )
                                }
                                alignItems="center"
                                direction="row"
                                spacing={1}
                                className="send-button"
                              >
                                <button
                                  // onClick={onClickYesConfirm}
                                  style={{
                                    backgroundColor: "#306DCA",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Regenerate from SAP website
                                </button>
                              </Stack>
                            ) : null}

                            <Stack
                              alignItems="center"
                              direction="row"
                              spacing={2}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                onClick={() => setClickedIndex(itm?.id)}
                              >
                                {
                                  itm?.is_liked == 0 && itm?.is_disliked == 0 ? (
                                    <Stack direction={"row"} spacing={0.5}>
                                      <AiOutlineLike
                                        style={{
                                          color: like,
                                          fontSize: 18,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleLike(itm, index);
                                        }}
                                      />
                                      <AiOutlineDislike
                                        style={{
                                          color: like,
                                          fontSize: 18,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleDislike(itm, index);
                                        }}
                                      />
                                    </Stack>
                                  ) : itm?.is_liked ? (
                                    <AiFillLike
                                      style={{
                                        color: like,
                                        fontSize: 18,
                                      }}
                                    // onClick={() => {
                                    //   handleLike(itm, "filled");
                                    // }}
                                    />
                                  ) : (
                                    <AiFillDislike
                                      style={{
                                        color: like,
                                        fontSize: 18,
                                      }}
                                    // onClick={() => {
                                    //   handleDislike(itm);
                                    // }}
                                    />
                                  )
                                  //  : (
                                  //   <AiOutlineLike
                                  //     style={{
                                  //       color: like,
                                  //       fontSize: 18,
                                  //       cursor: "pointer",
                                  //     }}
                                  //     onClick={() => {
                                  //       handleLike(itm, "unfilled");
                                  //     }}
                                  //   />
                                  // )
                                }
                                {
                                  //   itm.is_disliked && (
                                  //     <AiFillDislike
                                  //       style={{
                                  //         color: like,
                                  //         fontSize: 18,
                                  //         cursor: "pointer",
                                  //       }}
                                  //       onClick={() => {
                                  //         handleDislike(itm, "filled");
                                  //       }}
                                  //     />
                                  //   )
                                  //  : (
                                  //   <AiOutlineDislike
                                  //     style={{
                                  //       color: like,
                                  //       fontSize: 18,
                                  //       cursor: "pointer",
                                  //     }}
                                  //     onClick={() => {
                                  //       handleDislike(itm, "unfilled");
                                  //     }}
                                  //   />
                                  // )
                                }
                              </Stack>
                              <Stack
                                className="send-button"
                                spacing={1}
                                alignItems={"center"}
                                direction="row"
                                onClick={() => CopyToClipBordPress(itm?.answer)}
                              >
                                <Typography
                                  style={{ color: "#637381", fontSize: 16 }}
                                >
                                  Copy
                                </Typography>
                                <MdOutlineContentCopy
                                  style={{ color: "#637381", fontSize: 16 }}
                                />
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </>
                  )))
                }
              </Stack>

              {/* input */}
              <Stack
                textAlign="center"
                alignItems={"center"}
                justifyContent="center"
                spacing={1}
                style={{ backgroundColor: "#F8FAFC" }}
                paddingX={10}
                paddingY={3}
                width="100%"
              >
                <div className="chatInput-inner">
                  <textarea
                    ref={textareaRef}
                    className={`custom-textarea`}
                    value={newQuestion}
                    disabled={isLoading}
                    onChange={(e) => setNewQuestion(e.target.value)}
                    onKeyDown={handleKeypressChatSession}
                    onInput={TextAreaAutoResize}
                    style={{ backgroundColor: isLoading ? "#f8fafc" : "", color: isLoading ? "#ddd" : "" }}
                  // disabled={
                  //   isLoading ||
                  //   chatLoader ||
                  //   (momentType == "single" && selectedValue == "")
                  // }
                  />
                  <span
                    className="send-btn"
                  // onClick={() => {
                  //   !chatInputText
                  //     ? console.log("")
                  //     : messages && messages.length > 0
                  //       ? updateChat()
                  //       : createNewChat();
                  // }}
                  >

                    <IconButton
                      aria-label="Send"
                      onClick={!isLoading ? sendToriChatDataOnChatSession : null}
                      icon={<MdSend />}
                      color="#fff"
                      fontSize="24px"
                      className="custom-icon"
                      backgroundColor={"white"}
                      textColor={"grey"}
                      style={{ backgroundColor: isLoading ? "#f8fafc" : "" }}
                    />
                  </span>
                </div>
                <Typography style={{ color: "#637381", fontSize: 13 }}>
                  Tori-Chat Test November 2023, our open AI chat in its test version.
                </Typography>
              </Stack>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
              >
                <Alert
                  onClose={() => setOpen(false)}
                  severity="info"
                  sx={{ width: "250px", fontSize: 14 }}
                >
                  Copied to clipboard
                </Alert>
              </Snackbar>
            </Stack>
          </Stack>
        </div>
      )}

      <FeedbackModal
        modalIsOpen={feebackModal}
        closeModal={() => {
          setFeedbackModal(false)
        }}
      />

      <ComparisonChatModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        disLikeQuestion={disLikeQuestion}
        newChatMsg={disLikeMsg}
        changeStateFromChildToParent={changeStateFromChildToParent}
      // multipleResp={multipleResp}
      // multipleRespArrayProducts={multipleRespArray}
      />

      <AdditionalInfoModal
        modalIsOpen={openAdditionalModal}
        closeModal={closeModalComapre}
        disLikeQuestion={disLikeQuestion}
        // newChatMsg={newChatMsg}
        changeStateFromChildToParent={changeStateFromChildToParent} />

      <DisplayModal
        open={isSendReqMsg}
        // onClose={() => {
        //   setisSendReqMsg(false);
        //   if (chatArray.length) {
        //     chatArray.pop();
        //     setShowFirstInput(false);
        //     setNewQuestion(newQuestion);
        //   } else {
        //     setShowFirstInput(true);
        //     setChatArray([]);
        //     setNewQuestion("");
        //     setAskQuery(askQuery);
        //   }
        // }}
        onClose={() => {
          setisSendReqMsg(false);
          if (chatArray.length > 1) {
            if (regeneratChat) {
              setShowFirstInput(false);
              setNewQuestion(newQuestion);
            } else {
              chatArray.splice(chatArray.length - 1);
              setShowFirstInput(false);
              setNewQuestion(newQuestion);
            }
          } else {
            if (regeneratChat) {
              setShowFirstInput(false);
              // setChatArray([]);
              setNewQuestion(newQuestion);
              setAskQuery(askQuery);
            } else {
              setShowFirstInput(true);
              setChatArray([]);
              setNewQuestion("");
              setAskQuery(askQuery);
            }
          }
        }}
        modalMsg={"Something went wrong!"}
      />

      <DisplayModal
        open={customModal}
        onClose={() => {
          setCustomModal(false)
          // setisSendReqMsg(false);
          // if (chatArray.length) {
          //   chatArray.pop();
          //   setShowFirstInput(false);
          //   setNewQuestion(newQuestion);
          // } else {
          //   setShowFirstInput(true);
          //   setChatArray([]);
          //   setNewQuestion("");
          //   setAskQuery(askQuery);
          // }
        }}
        modalMsg={customModalMessage}
      />

      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
          if (chatArray.length > 1) {
            if (regeneratChat) {
              setShowFirstInput(false);
              setNewQuestion(newQuestion);
            } else {
              chatArray.splice(chatArray.length - 1);
              setShowFirstInput(false);
              setNewQuestion(newQuestion);
            }
          } else {
            if (regeneratChat) {
              setShowFirstInput(false);
              // setChatArray([]);
              setNewQuestion(newQuestion);
              setAskQuery(askQuery);
            } else {
              setShowFirstInput(true);
              setChatArray([]);
              setNewQuestion("");
              setAskQuery(askQuery);
            }
          }
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </>
  );
};

export default ToriChat;
