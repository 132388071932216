import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../environment";
import {
  businessOutcomsTooltip,
  demoTooltip,
  roleTooltip,
} from "../../../utils/tooltips/Tooltips";
import info from "../../assets/info.svg";
import InputField from "../../inputfield/InputField";
import BusinessOutcomeDropdown from "../CreateDraft/Components/BusinessOutcomeDropdown";
import Select from "react-select";
import SelectDropdown from "../../reactSelectDropdown/SelectDropdown";
import Form from "react-bootstrap/Form";

const PrcoessDemoSteps = ({
  showBusiness,
  businessLabel,
  businessValue,
  onBlurBO,
  businessOnchange,
  selectTextBO,
  businessOutcomeDropdownData,
  businessPlaceholder,
  businessError,
  roleLabel,
  stepLabel,
  roleValue,
  onBlurStep,
  roleInputDisabled,
  roleOnchange,
  rolePlaceholder,
  demoOnfocus,
  disabledInputsOnNext,
  demoDisable,
  demoPlaceholder,
  demoValue,
  demoOnchange,
  NewDemoFilArrDiv,
  NewDemoFilArr,
  selectNewDemo,
  finishClick,
  isGenerateDemoScript1,
  loading,
  demoNo,
  isSkipDemo,
  isBack,
  regenerateDemo,
  onClickGenerateDemo,
  onClickRegenerateDemo,
  showDemoOptions,
  optionsName,
  onChangeOpt1,
  onChangeOpt2,
  opt1Val,
  opt1Check,
  opt1Id,
  opt2Id,
  onChangeOpt1Check,
  opt2Val,
  bottomRef,
  onClickGenBPF,
  opt2Check,
  onChangeOpt2Check,
  showAddtoscript,
  onClickAddtoScript,
  CallingSaveDraftAPI,
  showButtons,
  onBackClick,
  onSkipClick,
  BPFLoading,
  lastLoading = false,
  disableAllinputs,
  stepValue,
  stepOnchange,
  startingPoint,
  startingPointOnChange,
  stepError,
  startingPointError,
  startingPointLabel,
  startingPointPlaceholder,
  actionsError,
  isDisableDemoScript,
  genDemoLoading,
  startingPointTooltip,
  optional,
  businessOutcomeDropdown,
  processBusinessOutcome,
  isLoading,
}) => {
  const items = useMemo(
    () =>
      businessOutcomeDropdown.map((option) => ({
        value: option.name,
        label: option.name,
        ...option,
      })),
    [businessOutcomeDropdown]
  );

  let defaultBusinessValue = [
    businessValue != null
      ? {
          value: 0,
          label: businessValue && businessValue,
        }
      : null,
  ];
  return (
    <div>
      {showBusiness && (
        <div>
          {/* Role 1 */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              <InputField
                className="InputFields"
                isShowLabel={true}
                isShowLabelIcon={true}
                label={roleLabel}
                tooltipText={roleTooltip}
                onBlur={CallingSaveDraftAPI}
                value={roleValue}
                isDisabled={disableAllinputs}
                onChange={roleOnchange}
                placeholder={rolePlaceholder}
              />
            </div>
            <div className="col-1"></div>
          </div>
          {/* step 1 */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              <InputField
                className="InputFields"
                isShowLabel={true}
                isShowLabelIcon={true}
                onBlur={onBlurStep}
                label={stepLabel}
                value={stepValue}
                onChange={stepOnchange}
                isDisabled={disableAllinputs}
                tooltipText="Enter step for demo"
                placeholder="Enter Step"
                isShowErrorMessage={stepError}
                errorMessage="This field is required"
              />
            </div>
            <div className="col-1"></div>
          </div>
          {/*Business outcome */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              {/*<BusinessOutcomeDropdown
                tooltip={businessOutcomsTooltip}
                disabledInputs={disableAllinputs}
                BusinessOutcomeVal={businessValue}
                onBlurBO={onBlurBO}
                onChangeBO={businessOnchange}
                selectTextBO={selectTextBO}
                placeholder={businessPlaceholder}
                boEr={businessError}
                businessOutcomeDropdownData={businessOutcomeDropdownData}
                businessOutcomeDropdown={businessOutcomeDropdown}
                boName={businessLabel}
                optional={optional}
                CallingSaveDraftAPI={CallingSaveDraftAPI}
                processBusinessOutcome={processBusinessOutcome}
      
              />*/}
              {businessOutcomsTooltip && (
                <div className="row">
                  <div className="col-12 p-0">
                    <div style={{ display: "flex", marginBottom: 13 }}>
                      <span className="pName">{businessLabel}</span>
                      <span style={{ marginLeft: "3px", fontSize: "13px" }}>
                        {optional}
                      </span>
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          <Typography fontSize={14}>
                            {businessOutcomsTooltip}
                          </Typography>
                        }
                      >
                        <img
                          style={{
                            marginLeft: 5,
                            width: 20,
                          }}
                          src={info}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-6"></div>
                </div>
              )}
              {/*<Select
              //   options={businessOutcomeDropdown}
              //   placeholder={"Select or begin typing"}
              //   onChange={businessOnchange}
              //   value={businessValue}
                        // /> */}

              {!isLoading && (
                <SelectDropdown
                  options={items}
                  placeholder={"Select or begin typing"}
                  onChange={businessOnchange}
                  isOptionDisabled={() => {}}
                  disabledInputs={disableAllinputs}
                  onBlur={onBlurBO}
                  defaultValue={defaultBusinessValue}
                />
              )}
              {/*<Form.Select
                aria-label="Default select example"
                className="InputFields"
                style={{ fontSize: "14px" }}
                size="lg"
                onBlur={onBlurBO}
                defaultValue={defaultBusinessValue}
                disabled={disableAllinputs}
                onChange={businessOnchange}
              >
                <option style={{ color: "black" }}>
                  Select Business Outcome...
                </option>
                {items?.map((item) => (
                  <option
                    // style={{ fontSize: "13px", marginBottom: "15px" }}
                    // style={{ color: "black", padding: "40px" }}
                    // className="InputFields"
                    className="option_select"
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))}
                </Form.Select>*/}
            </div>
            <div className="col-1"></div>
          </div>
          {/* starting point */}
          {startingPointLabel !== "What is done previously?" && (
            <div className="row top_pad">
              <div className="col-1"></div>
              <div className="col-10">
                <InputField
                  className="InputFields"
                  isShowLabel={true}
                  isShowLabelIcon={true}
                  value={startingPoint}
                  onChange={startingPointOnChange}
                  isDisabled={disableAllinputs}
                  label={startingPointLabel}
                  tooltipText={startingPointTooltip}
                  placeholder={startingPointPlaceholder}
                  isShowErrorMessage={startingPointError}
                  errorMessage="This field is required"
                  onBlur={CallingSaveDraftAPI}
                />
              </div>
              <div className="col-1"></div>
            </div>
          )}

          {/* action/software features */}
          <div className="row top_pad">
            <div className="col-1"></div>
            <div className="col-10">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div>
                    <span className="pName">{demoNo}</span>
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <Typography fontSize={14}>{demoTooltip}</Typography>
                      }
                    >
                      <img
                        style={{
                          marginLeft: 5,
                          marginTop: -8,
                          width: 20,
                        }}
                        src={info}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="top_input"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <GrammarlyEditorPlugin
                    clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
                  >
                    <textarea
                      rows="3"
                      onBlur={demoOnfocus}
                      disabled={
                        disableAllinputs || disabledInputsOnNext || demoDisable
                      }
                      onChange={demoOnchange}
                      placeholder={demoPlaceholder}
                      type="text"
                      className={
                        disableAllinputs
                          ? "InputFields disable-state"
                          : "InputFields"
                      }
                      value={demoValue}
                    />
                    {actionsError && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )}
                  </GrammarlyEditorPlugin>
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          {/* demo one buttons */}
          {showButtons && (
            <>
              {finishClick && (
                <div id="finishClick">
                  {isGenerateDemoScript1 ? (
                    <div id="dis76546">
                      <div className="row m-0 btn_section">
                        <div className="col-12 p-0">
                          <button
                            onClick={onClickGenerateDemo}
                            className={`primatybutton w-inline-block ${
                              disableAllinputs ? "disable_btn" : ""
                            }`}
                            style={{
                              float: "right",
                            }}
                            disabled={
                              genDemoLoading ||
                              isDisableDemoScript ||
                              disableAllinputs
                            }
                          >
                            {genDemoLoading ? (
                              <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div>Generate Demo Step</div>
                            )}
                          </button>
                          {isSkipDemo && (
                            <button
                              onClick={onSkipClick}
                              className={`primatybutton w-inline-block ${
                                disableAllinputs ? "disable_btn" : ""
                              }`}
                              disabled={
                                disableAllinputs || BPFLoading || genDemoLoading
                              }
                              style={{
                                float: "right",
                                marginRight: 10,
                              }}
                            >
                              {BPFLoading ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div>Skip Demo, Move to Business Process</div>
                              )}
                            </button>
                          )}
                          {isBack && (
                            <button
                              onClick={onBackClick}
                              className={`primatybutton w-inline-block ${
                                disableAllinputs ? "disable_btn" : ""
                              }`}
                              disabled={
                                disableAllinputs ||
                                isDisableDemoScript ||
                                genDemoLoading
                              }
                              style={{
                                float: "right",
                                marginRight: 10,
                              }}
                            >
                              Back
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div id="dis0988">
                      {regenerateDemo && (
                        <div className="row m-0 btn_section">
                          <div className="col-2"></div>
                          <div className="col-9">
                            <button
                              onClick={onClickRegenerateDemo}
                              className={`primatybutton w-inline-block ${
                                disableAllinputs ? "disable_btn" : ""
                              }`}
                              disabled={disableAllinputs || loading}
                              style={{
                                float: "right",
                              }}
                            >
                              {loading ? (
                                <div class="d-flex justify-content-center">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <div>Re Generate Demo Script</div>
                              )}
                            </button>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* demo options */}
      {showDemoOptions && (
        <section id="state1">
          <div style={{ paddingBottom: "30px" }}>
            <div className="row m-0">
              <div className="col-1"></div>
              <div className="col-10">
                <div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span className="pName1">{optionsName}</span>
                  </div>
                  <div className="custom-control custom-radio">
                    <textarea
                      id={opt1Id}
                      rows="8"
                      cols="50"
                      onChange={onChangeOpt1}
                      value={opt1Val == null || "" ? "" : opt1Val}
                      className="left_from"
                      type="text"
                    ></textarea>
                    <input
                      checked={opt1Check}
                      onChange={onChangeOpt1Check}
                      id="female"
                      disabled={loading}
                      type="checkbox"
                      className="cus_radio"
                      value="female"
                      name="gender"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "70px",
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <div className="custom-control custom-radio">
                    <textarea
                      id={opt2Id}
                      rows="8"
                      cols="50"
                      onChange={onChangeOpt2}
                      value={opt2Val == null || "" ? "" : opt2Val}
                      className="left_from"
                      type="text"
                    ></textarea>
                    <input
                      ref={bottomRef}
                      checked={opt2Check}
                      onChange={onChangeOpt2Check}
                      disabled={loading}
                      type="checkbox"
                      className="cus_radio"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "70px",
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          {showAddtoscript && (
            <div className="row m-0 btn_section_next">
              <div className="col-2"></div>
              <div className="col-9">
                <button
                  id="next3None111"
                  onClick={onClickAddtoScript}
                  className={`primatybutton w-inline-block ${
                    disableAllinputs ? "disable_btn" : ""
                  }`}
                  disabled={disableAllinputs}
                  style={{
                    float: "right",
                  }}
                >
                  Add to Script
                </button>
              </div>
              <div className="col-1"></div>
            </div>
          )}
        </section>
      )}
      {/* generate business process flow button */}
      {/* {showGenerateBPF || showReGenBPF ? (
        <div className="row m-0 btn_section_next">
          <div className="col-2"></div>
          <div className="col-9">
            {showGenerateBPF && (
              <button
                id="next3None111"
                onClick={onClickGenBPF}
                className="primatybutton w-inline-block"
                disabled={BPFLoading}
                style={{
                  float: "right",
                }}
              >
                {BPFLoading ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>Generate Business Process</div>
                )}
              </button>
            )}
            {showReGenBPF && (
              <button
                className="primatybutton w-inline-block"
                disabled={disableAllinputs || BPFLoading}
                onClick={onClickGenBPF}
                style={{ float: "right", marginTop: "30px" }}
              >
                {BPFLoading ? (
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>Re Generate Business Process</div>
                )}
              </button>
            )}
          </div>
          <div className="col-1"></div>
        </div>
      ) : null} */}
    </div>
  );
};

export default PrcoessDemoSteps;
