import { baseUrl } from "../../environment";

export function ScrapeDataService(url) {
  const formData = new FormData();
  formData.append("url", url);
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 60000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/scrape-data", {
      method: "POST",
      body: formData,
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetAnswerService(ques, filename) {
  const formData = new FormData();
  formData.append("question", ques);
  formData.append("filename", filename);
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-answer", {
      method: "POST",
      body: formData,
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function DeleteScrapedFileService(filename) {
  const formData = new FormData();
  formData.append("filename", filename);
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/delete-scraped-file", {
      method: "POST",
      body: formData,
      signal: controller.signal,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
