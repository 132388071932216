import React, { useContext, useEffect, useRef, useState } from "react";
import {
  GetUserData,
  // searchDraftTableData,
} from "../../../components/Services/Service";
import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import Sort from "../../assets/icons-sorting.png";
import SortUp from "../../assets/icon_up.png";
import "antd/dist/antd.min.css";
import "./Style.css";
import ActionsMenu from "./ActionsMenu";
import { Checkbox, Pagination, Tooltip, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Input, Select } from "antd";
import DisplayModal from "../CreateDraft/Components/Modal";
import { FiSearch } from "react-icons/fi";
import { FaClone } from "react-icons/fa";
import { BsCloudDownload } from "react-icons/bs";
import DraftContext from "../../../context/DraftTableContext";
function DraftTable(props) {
  const { getDraftTableData, draftsData, searchDraftTableData } =
    useContext(DraftContext);

  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      neutral: {
        main: "#EAF1FF",
        contrastText: "#0A6ED1",
      },
    },
  });
  let history = useHistory();
  const [dataa, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const rowId = useRef();
  const totalDrafts = useRef();
  const [page, setPage] = useState(1);
  const [paginationRows, setPaginationRows] = useState(10);
  const [isCheckedMyDraft, setIsCheckedMyDraft] = useState(false);
  const [isCheckedSharedWithMe, setIsCheckedSharedWithMe] = useState(false);
  const [isCheckedInfactory, setIsCheckedInfactory] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [columnName, setColumnName] = useState();
  const [order, setOrder] = useState();
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [searchText, setsearchText] = useState();
  const [showStateIcon, setShowStateIcon] = useState(false);
  const [showVideoNameIcon, setShowVideoNameIcon] = useState(false);
  const [showIdIcon, setShowIdIcon] = useState(false);
  const [showTypeIcon, setShowTypeIcon] = useState(false);
  const [showIndustryIcon, setShowIndustryIcon] = useState(false);
  const [showLobIcon, setShowLobIcon] = useState(false);
  const [showCreatedAtIcon, setShowCreatedAtIcon] = useState(false);
  const [showOwnerIcon, setShowOwnerIcon] = useState(false);
  const [stateIcon, setstateIcon] = useState(Sort);
  const [ownerIcon, setownerIcon] = useState(Sort);
  const [videoNameIcon, setvideoNameIcon] = useState(Sort);
  const [idIcon, setIdIcon] = useState(Sort);
  const [typeIcon, settypeIcon] = useState(Sort);
  const [createdAtIcon, setcreatedAtIcon] = useState(Sort);
  const [industryIcon, setIndustryIcon] = useState(Sort);
  const [lobIcon, setLobIcon] = useState(Sort);

  const searchData = (text) => {
    setOrder();
    setColumnName();
    setShowCreatedAtIcon(false);
    setShowIdIcon(false);
    setShowIndustryIcon(false);
    setShowOwnerIcon(false);
    setShowLobIcon(false);
    setShowStateIcon(false);
    setShowTypeIcon(false);
    setShowVideoNameIcon(false);
    if (text.length >= 1) {
      setPage(1);
      searchDraftTableData(
        1,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        text
        // columnName,
        // order
      )
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getDraftTableData(
        page,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false
      )
        .then((res) => {
          setData(res.data);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader(false);
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };

  const onRowChange = async (value) => {
    console.log("🚀 ~ file: DraftTable.js:135 ~ onRowChange ~ value:", value);
    setLoader1(true);
    if (
      Math.ceil(totalDrafts.current / value) !=
      Math.ceil(totalDrafts.current / paginationRows)
    ) {
      const oldRatio = page / Math.ceil(totalDrafts.current / paginationRows);
      setPaginationRows(value);
      const newPageNumber = Math.ceil((totalDrafts.current / value) * oldRatio);
      if (searchText) {
        searchDraftTableData(
          newPageNumber,
          value,
          isCheckedMyDraft ? true : false,
          isCheckedSharedWithMe ? true : false,
          isCheckedInfactory ? true : false,
          searchText,
          columnName,
          order
        )
          .then((res) => {
            setPage(newPageNumber);
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(
          newPageNumber,
          value,
          isCheckedMyDraft ? true : false,
          isCheckedSharedWithMe ? true : false,
          isCheckedInfactory ? true : false,
          columnName,
          order
        )
          .then((res) => {
            // setLoader1(true)
            setPage(newPageNumber);
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setPaginationRows(value);
      if (searchText) {
        searchDraftTableData(
          page,
          value,
          isCheckedMyDraft ? true : false,
          isCheckedSharedWithMe ? true : false,
          isCheckedInfactory ? true : false,
          searchText,
          columnName,
          order
        )
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(
          page,
          value,
          isCheckedMyDraft ? true : false,
          isCheckedSharedWithMe ? true : false,
          isCheckedInfactory ? true : false,
          columnName,
          order
        )
          .then((res) => {
            // setLoader1(true)
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }

    updateSidebar();
  };
  const oldRatio = page / Math.ceil(totalDrafts.current / paginationRows);
  const newPageNumber = Math.ceil((totalDrafts.current / 10) * oldRatio);
  const body = JSON.stringify({
    page: newPageNumber,
    drafts: 10,
    user: isCheckedMyDraft ? true : false,
    shared: isCheckedSharedWithMe ? true : false,
    column: columnName,
    order: order,
  });

  const handleChange = (event, value) => {
    setPage(value);
    updateSidebar();
    setLoader1(true);
    if (searchText) {
      searchDraftTableData(
        value,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        searchText,
        columnName,
        order
      )
        .then((res) => {
          if (res.status) {
            setLoader1(false);
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getDraftTableData(
        value,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        columnName,
        order
      )
        .then((res) => {
          // setLoader1(true)
          setData(res.data);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader(false);
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const updateSidebar = () => {
    GetUserData()
      .then((res) => {
        if (res.status) {
          localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
          localStorage.setItem(
            "feedbackCount",
            JSON.stringify(res.data.feedback)
          );
          localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
          localStorage.setItem(
            "finalReviewCount",
            JSON.stringify(res.data.teach_tori)
          );
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoader1(false);
        setLoader(false);
      });
  };
  const data = () => ({
    columns: [],
    rows: dataa,
  });
  const ActionClick = (id) => {
    rowId.current = id;
  };
  const NameClick = (id, type, isScriptProvided) => {
    const payloadObject = {
      searchText,
      isCheckedSharedWithMe,
      isCheckedMyDraft,
      isCheckedInfactory,
      paginationRows,
      page,
      columnName,
      order,
    };
    sessionStorage.setItem("draftTable_payload", JSON.stringify(payloadObject));
    if (isScriptProvided == 1) {
      history.push(`/draft/scriptprovided/${id}`, {
        from: "drafttable",
      });
    } else {
      history.push(`/draft/${type.toLowerCase()}/${id}`, {
        from: "drafttable",
      });
    }
  };
  const showOnlyMyDraft = (e) => {
    setOrder();
    setColumnName();
    setShowCreatedAtIcon(false);
    setShowIdIcon(false);
    setShowIndustryIcon(false);
    setShowOwnerIcon(false);
    setShowLobIcon(false);
    setShowStateIcon(false);
    setShowTypeIcon(false);
    setShowVideoNameIcon(false);
    setLoader1(true);
    setIsCheckedSharedWithMe(false);
    setIsCheckedInfactory(false);
    if (isCheckedMyDraft == false) {
      setIsCheckedMyDraft(true);
      if (searchText) {
        searchDraftTableData(1, paginationRows, true, false, false, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // alert('myonly')
        getDraftTableData(1, paginationRows, true, false, false)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setIsCheckedMyDraft(false);
      if (searchText) {
        searchDraftTableData(1, paginationRows, false, false, false, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(1, paginationRows, false, false, false)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }
  };
  const shareWithMe = (e) => {
    setOrder();
    setColumnName();
    setShowCreatedAtIcon(false);
    setShowIdIcon(false);
    setShowIndustryIcon(false);
    setShowOwnerIcon(false);
    setShowLobIcon(false);
    setShowStateIcon(false);
    setShowTypeIcon(false);
    setShowVideoNameIcon(false);
    setLoader1(true);
    setIsCheckedMyDraft(false);
    setIsCheckedInfactory(false);
    if (isCheckedSharedWithMe == false) {
      setIsCheckedSharedWithMe(true);
      if (searchText) {
        searchDraftTableData(1, paginationRows, false, true, false, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(1, paginationRows, false, true, false)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setIsCheckedSharedWithMe(false);
      if (searchText) {
        searchDraftTableData(1, paginationRows, false, false, false, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(1, paginationRows, false, false, false)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }
  };
  const inFactory = () => {
    setOrder();
    setColumnName();
    setShowCreatedAtIcon(false);
    setShowIdIcon(false);
    setShowIndustryIcon(false);
    setShowOwnerIcon(false);
    setShowLobIcon(false);
    setShowStateIcon(false);
    setShowTypeIcon(false);
    setShowVideoNameIcon(false);
    setLoader1(true);
    setIsCheckedMyDraft(false);
    setIsCheckedSharedWithMe(false);
    if (isCheckedInfactory == false) {
      setIsCheckedInfactory(true);
      if (searchText) {
        searchDraftTableData(1, paginationRows, false, false, true, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(1, paginationRows, false, false, true)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setIsCheckedInfactory(false);
      if (searchText) {
        searchDraftTableData(1, paginationRows, false, false, false, searchText)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getDraftTableData(1, paginationRows, false, false, false)
          .then((res) => {
            setPage(1);
            setData(res.data);
            setLoader(false);
            if (res.data) {
              setLoader1(false);
              totalDrafts.current = res.total_drafts;
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }
  };
  const sortingFunc = (ord, col) => {
    setColumnName(col);
    setOrder(ord);
    if (searchText) {
      searchDraftTableData(
        page,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        searchText,
        col,
        ord
      )
        .then((res) => {
          if (col == "draft_state") {
            setShowStateIcon(true);
            if (ord == "ASC") {
              setstateIcon(SortUp);
            } else {
              setstateIcon(Sort);
            }
          } else {
            setShowStateIcon(false);
          }
          if (col == "request") {
            setShowOwnerIcon(true);
            if (ord == "ASC") {
              setownerIcon(SortUp);
            } else {
              setownerIcon(Sort);
            }
          } else {
            setShowOwnerIcon(false);
          }
          if (col == "video_name") {
            setShowVideoNameIcon(true);
            if (ord == "ASC") {
              setvideoNameIcon(SortUp);
            } else {
              setvideoNameIcon(Sort);
            }
          } else {
            setShowVideoNameIcon(false);
          }
          if (col == "type") {
            setShowTypeIcon(true);
            if (ord == "ASC") {
              settypeIcon(SortUp);
            } else {
              settypeIcon(Sort);
            }
          } else {
            setShowTypeIcon(false);
          }
          if (col == "id") {
            setShowIdIcon(true);
            if (ord == "ASC") {
              setIdIcon(SortUp);
            } else {
              setIdIcon(Sort);
            }
          } else {
            setShowIdIcon(false);
          }
          if (col == "industry") {
            setShowIndustryIcon(true);
            if (ord == "ASC") {
              setIndustryIcon(SortUp);
            } else {
              setIndustryIcon(Sort);
            }
          } else {
            setShowIndustryIcon(false);
          }
          if (col == "created_at") {
            setShowCreatedAtIcon(true);
            if (ord == "ASC") {
              setcreatedAtIcon(SortUp);
            } else {
              setcreatedAtIcon(Sort);
            }
          } else {
            setShowCreatedAtIcon(false);
          }
          if (col == "line_of_business") {
            setShowLobIcon(true);
            if (ord == "ASC") {
              setLobIcon(SortUp);
            } else {
              setLobIcon(Sort);
            }
          } else {
            setShowLobIcon(false);
          }
          if (res.status) {
            setLoader1(false);
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getDraftTableData(
        page,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        col,
        ord
      )
        .then((res) => {
          if (col == "draft_state") {
            setShowStateIcon(true);
            if (ord == "ASC") {
              setstateIcon(SortUp);
            } else {
              setstateIcon(Sort);
            }
          } else {
            setShowStateIcon(false);
          }
          if (col == "request") {
            setShowOwnerIcon(true);
            if (ord == "ASC") {
              setownerIcon(SortUp);
            } else {
              setownerIcon(Sort);
            }
          } else {
            setShowOwnerIcon(false);
          }
          if (col == "video_name") {
            setShowVideoNameIcon(true);
            if (ord == "ASC") {
              setvideoNameIcon(SortUp);
            } else {
              setvideoNameIcon(Sort);
            }
          } else {
            setShowVideoNameIcon(false);
          }
          if (col == "type") {
            setShowTypeIcon(true);
            if (ord == "ASC") {
              settypeIcon(SortUp);
            } else {
              settypeIcon(Sort);
            }
          } else {
            setShowTypeIcon(false);
          }
          if (col == "id") {
            setShowIdIcon(true);
            if (ord == "ASC") {
              setIdIcon(SortUp);
            } else {
              setIdIcon(Sort);
            }
          } else {
            setShowIdIcon(false);
          }
          if (col == "industry") {
            setShowIndustryIcon(true);
            if (ord == "ASC") {
              setIndustryIcon(SortUp);
            } else {
              setIndustryIcon(Sort);
            }
          } else {
            setShowIndustryIcon(false);
          }
          if (col == "created_at") {
            setShowCreatedAtIcon(true);
            if (ord == "ASC") {
              setcreatedAtIcon(SortUp);
            } else {
              setcreatedAtIcon(Sort);
            }
          } else {
            setShowCreatedAtIcon(false);
          }
          if (col == "line_of_business") {
            setShowLobIcon(true);
            if (ord == "ASC") {
              setLobIcon(SortUp);
            } else {
              setLobIcon(Sort);
            }
          } else {
            setShowLobIcon(false);
          }
          setData(res.data);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader(false);
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const clearState = () => {
    setPage(1);
    setPaginationRows(10);
    setsearchText();
    setIsCheckedInfactory(false);
    setIsCheckedMyDraft(false);
    setIsCheckedSharedWithMe(false);
  };
  const linksData = {
    columns: [
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "draft_state");
              } else if (order == "ASC") {
                sortingFunc("DESC", "draft_state");
              } else {
                sortingFunc("ASC", "draft_state");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "120px",
              cursor: "pointer",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>State</span>
            {showStateIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={stateIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "video_name");
              } else if (order == "ASC") {
                sortingFunc("DESC", "video_name");
              } else {
                sortingFunc("ASC", "video_name");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              width: "160px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>Video Name</span>
            {showVideoNameIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={videoNameIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "name",
        width: 120,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "id");
              } else if (order == "ASC") {
                sortingFunc("DESC", "id");
              } else {
                sortingFunc("ASC", "id");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // width: "60px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>ID</span>
            {showIdIcon && (
              <span>
                {" "}
                <img style={{ marginLeft: "5px" }} width="30vw" src={idIcon} />
              </span>
            )}
          </div>
        ),
        field: "Id",
        sort: "disabled",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "type");
              } else if (order == "ASC") {
                sortingFunc("DESC", "type");
              } else {
                sortingFunc("ASC", "type");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              // width: "80px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>Type</span>
            {showTypeIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={typeIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "type",
        sort: "asc",
        width: 80,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "industry");
              } else if (order == "ASC") {
                sortingFunc("DESC", "industry");
              } else {
                sortingFunc("ASC", "industry");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              width: "100px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>Industry</span>
            {showIndustryIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={industryIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "industry",
        width: 100,
      },

      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "line_of_business");
              } else if (order == "ASC") {
                sortingFunc("DESC", "line_of_business");
              } else {
                sortingFunc("ASC", "line_of_business");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              width: "100px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>LOB</span>
            {showLobIcon && (
              <span>
                {" "}
                <img style={{ marginLeft: "5px" }} width="30vw" src={lobIcon} />
              </span>
            )}
          </div>
        ),
        field: "lineOfBusness",
        width: 150,
      },

      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "created_at");
              } else if (order == "ASC") {
                sortingFunc("DESC", "created_at");
              } else {
                sortingFunc("ASC", "created_at");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              width: "100px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>Created</span>
            {showCreatedAtIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={createdAtIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "created_date",
        width: 120,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "request");
              } else if (order == "ASC") {
                sortingFunc("DESC", "request");
              } else {
                sortingFunc("ASC", "request");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              width: "110px",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>Owner</span>
            {showOwnerIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={ownerIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "owner",
        width: 120,
      },
      {
        label: "",
        field: "action",
        width: 80,
      },
    ],
    rows: [
      ...data().rows.map((row, order) => ({
        clickEvent: () => NameClick(row.id, row.type, row.is_script_provided),
        state: (
          <span
            searchvalue={`${row.draft_state && row.draft_state.toLowerCase()}`}
            className={
              row.infactory == 1
                ? ""
                : row.just_cloned == 1
                ? ""
                : row.draft_state == "Editing"
                ? "state_blue"
                : row.draft_state == "Request" || row.draft_state == "Requested"
                ? "state_yellow"
                : "state_purple"
            }
          >
            {row.infactory && row.infactory == 1 ? (
              <div
                style={{
                  display: "flex",
                  maxWidth: "fit-content",
                  paddingTop: "6.8%",
                }}
              >
                <div>
                  <p className="infactory">Editing</p>
                </div>
                <div>
                  <span className="infactory-icon">
                    <BsCloudDownload fontSize="14px" />
                    {/* <img src = {infactory} /> */}
                  </span>
                </div>
              </div>
            ) : row.just_cloned == 1 ? (
              <div
                style={{
                  display: "flex",
                  maxWidth: "fit-content",
                  paddingTop: "6.8%",
                }}
              >
                <div>
                  <p
                    style={{ padding: "0 !important", paddingTop: 1 }}
                    className="state_purple"
                  >
                    Draft
                  </p>
                </div>
                <div>
                  <span className="clone-icon">
                    <FaClone fontSize="12px" />
                    {/* <img src = {infactory} /> */}
                  </span>
                </div>
              </div>
            ) : row.draft_state && row.draft_state == "Request" ? (
              "Requested"
            ) : !row.draft_state ? (
              "Draft"
            ) : (
              row.draft_state
            )}
          </span>
        ),
        request: (
          <span searchvalue={`${row.request && row.request.toLowerCase()}`}>
            {row.request && row.request.length > 10
              ? `${row.request.slice(0, 10)}...`
              : row.request}
          </span>
        ),
        Id: `${row.id}`,
        name:
          row.video_name.length > 14 ? (
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={14}>{row.video_name}</Typography>}
            >
              <span
                style={{ fontWeight: 600 }}
                searchvalue={`${row.video_name.toLowerCase()}`}
              >
                {row.video_name.slice(0, 16)}...
              </span>
            </Tooltip>
          ) : (
            <span
              style={{ fontWeight: 600 }}
              searchvalue={`${row.video_name.toLowerCase()}`}
            >
              {row.video_name}
            </span>
          ),
        industry: (
          <span searchvalue={`${row.industry && row.industry.toLowerCase()}`}>
            {row.industry && row.industry.length > 10
              ? `${row.industry.slice(0, 10)}...`
              : row.industry}
          </span>
        ),
        lineOfBusness: (
          <span
            searchvalue={`${
              row.line_of_business && row.line_of_business.toLowerCase()
            }`}
          >
            {row.line_of_business && row.line_of_business.length > 11
              ? `${row.line_of_business.slice(0, 11)}...`
              : row.line_of_business}
          </span>
        ),
        type:
          row.type_dur && row.type_dur.length > 14 ? (
            <Tooltip
              arrow
              placement="top"
              title={
                <Typography fontSize={14}>
                  {row.type_dur && row.type_dur}
                </Typography>
              }
            >
              <span
                searchvalue={`${row.type_dur && row.type_dur.toLowerCase()}`}
              >
                {row.type_dur && row.type_dur.slice(0, 10)}...
              </span>
            </Tooltip>
          ) : (
            <span searchvalue={`${row.type_dur && row.type_dur.toLowerCase()}`}>
              {row.type_dur && row.type_dur}
            </span>
          ),

        created_date: (
          <span searchvalue={`${row.created_date}`}>{row.created_date}</span>
        ),
        owner:
          row.request.length > 15 ? (
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={15}>{row.request}</Typography>}
            >
              <span searchvalue={`${row.request.toLowerCase()}`}>
                {row.request.slice(0, 15)}...
              </span>
            </Tooltip>
          ) : (
            <span searchvalue={`${row.request.toLowerCase()}`}>
              {row.request}
            </span>
          ),
        action: (
          <div onClick={(e) => e.stopPropagation()}>
            <a
              style={{ color: "#0A6ED1", cursor: "pointer" }}
              onClick={() => ActionClick(row.id)}
            >
              <ActionsMenu setDefaultState={clearState} rowData={row} />
            </a>
          </div>
        ),
      })),
    ],
  };
  useEffect(() => {
    if (draftsData) {
      let res = draftsData;
      setData(res.data);
      totalDrafts.current = res.total_drafts;
      if (res.data) {
        setLoader1(false);
      } else {
        setLoader(false);
        setLoader1(false);
        setsendReqMsg(res.message);
        setisSendReqMsg(true);
      }
      setLoader(false);
    }
  }, [draftsData]);

  useEffect(() => {
    setLoader(true);
    updateSidebar();
    if (sessionStorage.getItem("draftTable_payload")) {
      const newPayload = JSON.parse(
        sessionStorage.getItem("draftTable_payload")
      );

      if (newPayload.searchText) {
        setPage(newPayload.page);
        setPaginationRows(newPayload.paginationRows);
        setsearchText(newPayload.searchText);
        setIsCheckedInfactory(newPayload.isCheckedInfactory);
        setIsCheckedSharedWithMe(newPayload.isCheckedSharedWithMe);
        setIsCheckedMyDraft(newPayload.isCheckedMyDraft);
        setOrder(newPayload.order);
        setColumnName(newPayload.columnName);
        searchDraftTableData(
          newPayload.page,
          newPayload.paginationRows,
          newPayload.isCheckedMyDraft,
          newPayload.isCheckedSharedWithMe,
          newPayload.isCheckedInfactory,
          newPayload.searchText,
          newPayload.columnName,
          newPayload.order
        )
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
              sessionStorage.removeItem("draftTable_payload");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setPage(newPayload.page);
        setPaginationRows(newPayload.paginationRows);
        setsearchText(newPayload.searchText);
        setIsCheckedInfactory(newPayload.isCheckedInfactory);
        setIsCheckedSharedWithMe(newPayload.isCheckedSharedWithMe);
        setIsCheckedMyDraft(newPayload.isCheckedMyDraft);
        setOrder(newPayload.order);
        setColumnName(newPayload.columnName);
        getDraftTableData(
          newPayload.page,
          newPayload.paginationRows,
          newPayload.isCheckedMyDraft,
          newPayload.isCheckedSharedWithMe,
          newPayload.isCheckedInfactory,
          newPayload.columnName,
          newPayload.order
        )
          .then((res) => {
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            if (res.data) {
              setLoader1(false);
              sessionStorage.removeItem("draftTable_payload");
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      getDraftTableData(
        page,
        paginationRows,
        isCheckedMyDraft ? true : false,
        isCheckedSharedWithMe ? true : false,
        isCheckedInfactory ? true : false,
        columnName,
        order
      )
        .then((res) => {
          setData(res.data);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader(false);
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  }, []);
  return (
    <div>
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Scripts</h3>
        <div style={{ height: 36, marginTop: 8 }}>{/* Invite user */}</div>
      </div>
      {loader1 && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div className="box_s">
        <div
          style={{
            paddingLeft: 16,
            paddingRight: 25,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              paddingBlock: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ paddingBlock: 0 }}
                  checked={isCheckedMyDraft}
                  onChange={showOnlyMyDraft}
                  className="filter-checkbox"
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                />
                <label
                  style={{ fontWeight: 400, fontSize: 13 }}
                  class="form-check-label"
                  for="defaultCheck1"
                >
                  Show only my Drafts
                </label>
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ paddingBlock: 0 }}
                  checked={isCheckedSharedWithMe}
                  onChange={shareWithMe}
                  className="filter-checkbox"
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                />
                <label
                  style={{ fontWeight: 400, fontSize: 13 }}
                  class="form-check-label"
                  for="defaultCheck2"
                >
                  Shared with me
                </label>
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  style={{ paddingBlock: 0 }}
                  checked={isCheckedInfactory}
                  onChange={inFactory}
                  className="filter-checkbox"
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                />
                <label
                  style={{ fontWeight: 400, fontSize: 13 }}
                  class="form-check-label"
                  for="defaultCheck2"
                >
                  In Factory
                </label>
              </div>
            </div>
            <Input
              className="table-search"
              style={{ width: "200px", height: "35px", borderRadius: 8 }}
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              onKeyUp={() => searchData(searchText)}
              placeholder="Search..."
              prefix={<FiSearch style={{ color: "#637381", fontSize: 20 }} />}
            />
          </div>
        </div>
        {/* table */}
        <div>
          <MDBDataTable
            noBottomColumns={true}
            fixedHeader
            bordered={false}
            responsive
            entries={50}
            data={linksData}
            className="draft_table"
          />
        </div>
        {/* material pagination */}
        <div className="table-pagination">
          {/* choose rows */}
          <div className="paginationrows-dropdown">
            <label for="cars">Rows per page:</label>
            <Select
              defaultValue={paginationRows}
              onChange={(value) => onRowChange(value)}
              bordered={false}
              options={[
                {
                  value: "10",
                  label: "10",
                },
                {
                  value: "15",
                  label: "15",
                },
                {
                  value: "20",
                  label: "20",
                },
              ]}
            />
          </div>
          {/* pagination row */}
          <ThemeProvider theme={theme}>
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  fontSize: "14px !important",
                },
                ".MuiPaginationItem-root.Mui-selected": {
                  fontWeight: "600 !important",
                },
              }}
              count={Math.ceil(totalDrafts.current / paginationRows)}
              page={page}
              onChange={handleChange}
              color={"neutral"}
            />
          </ThemeProvider>
        </div>
      </div>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
    </div>
  );
}

export default DraftTable;
