import { Tooltip } from "@mui/material";
import { Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";
import "../DraftTable/modals/style.css";
import { FiMoreVertical } from "react-icons/fi";
import DownloadModal from "./DownloadModal";
import ApprovedDraftDeleteModal from "./ApprovedDraftDeleteModal";
const ApprovedActionsDropdown = ({ rowId }) => {

  const menu = (
    <Menu
      items={[
        {
          label: <DownloadModal id={rowId} />,
          key: "0",
        },
        {
          label: <ApprovedDraftDeleteModal id={rowId} />,
          key: "1",
        }
      ]}
    />
  );
  return (
    <>
    <Dropdown overlay={menu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <FiMoreVertical style={{ color: "black", fontSize: 18 }} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default ApprovedActionsDropdown;
