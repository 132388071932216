import { values } from "lodash";
const token = localStorage.getItem("token");
import {
  baseUrl,
  PROCESS_OUTRO_MODEL_API_KEY,
  SPOTLIGHT_MODEL_API_KEY,
} from "../../src/environment";

let demoModelName = "davinci:ft-ai-dev-lab-2023-01-10-16-22-39";
export function scriptOne(
  pName,
  pName2,
  useCase,
  Indursty,
  Protagonist,
  extra,
  Lob1,
  demoModelName,
  BO1
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  const finalSentence =
    Protagonist &&
    Protagonist.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  let finalDemo =
    extra == null
      ? "nan"
      : extra.length === 0
        ? "nan"
        : extra[0].trim().length === 0
          ? "nan"
          : extra;
  let PNAME = finalProduct.trim();
  let USECASE = useCase && useCase.trim();
  let INDUSRTY = Indursty;
  let PROTAGONIST = finalSentence && finalSentence.trim();

  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var PN =
    PROTAGONIST == null || PROTAGONIST.trim().length === 0
      ? "nan"
      : PROTAGONIST;

  var new1 =
    Lob1 && Lob1.slice(0, 1)[0] == null ? "nan" : Lob1 && Lob1.slice(0, 1)[0];
  var new2 =
    Lob1 && Lob1.slice(1, 2)[0] == null ? "nan" : Lob1 && Lob1.slice(1, 2)[0];
  var new3 =
    Lob1 && Lob1.slice(2, 3)[0] == null ? "nan" : Lob1 && Lob1.slice(2, 3)[0];
  const businessOutcome = BO1 == null ? "nan" : BO1;

  // var body = JSON.stringify({
  //   prompt: `{${JSON.stringify("Product Name")}: ${JSON.stringify(
  //     PNAME
  //   )},${JSON.stringify("Industry")}: ${JSON.stringify(IN)},${JSON.stringify(
  //     "Summary"
  //   )}: ${JSON.stringify(US)},${JSON.stringify(
  //     "Line of Business 1"
  //   )}: ${JSON.stringify(new1)},${JSON.stringify(
  //     "Line of Business 2"
  //   )}: ${JSON.stringify(new2)},${JSON.stringify(
  //     "Line of Business 3"
  //   )}: ${JSON.stringify(new3)},${JSON.stringify(
  //     "Protagonist"
  //   )}: ${JSON.stringify(PN)},${JSON.stringify(
  //     "Business Outcome"
  //   )}: ${JSON.stringify(businessOutcome)},${JSON.stringify(
  //     "Demo"
  //   )}:${JSON.stringify(finalDemo)}}`,
  // });

  var body = JSON.stringify({
    prompt: `PN: ${PNAME} $ Industry: ${IN} $ Summary: ${US} $ Line of Business 1: ${new1} $ Line of Business 2: ${new2} $ Line of Business 3: ${new3} $ Protagonist: ${PN} $ BO: ${businessOutcome} $ Demo: ${finalDemo === 'nan' ? 'Enhance the storage, processing, and flow of information and insights across your organization.' : finalDemo}\n\n###\n\n`,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/generate-spotlight-demo", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // "OpenAI-Organization": "org-9EamczpLjZMGjhUTPZIEojS8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function scriptTwo(
  pName,
  pName2,
  useCase,
  Indursty,
  Protagonist,
  extra2,
  Lob2,
  demoModelName,
  SBO2
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  const finalSentence =
    Protagonist &&
    Protagonist.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  let finalDemo =
    extra2 == null
      ? "nan"
      : extra2.length === 0
        ? "nan"
        : extra2[0].trim().length === 0
          ? "nan"
          : extra2;
  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase && useCase.trim();
  let INDUSRTY = Indursty;
  let PROTAGONIST = finalSentence && finalSentence.trim();

  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var PN =
    PROTAGONIST == null || PROTAGONIST.trim().length === 0
      ? "nan"
      : PROTAGONIST;
  var new1 = Lob2.slice(0, 1)[0] == null ? "nan" : Lob2.slice(0, 1)[0];
  var new2 = Lob2.slice(1, 2)[0] == null ? "nan" : Lob2.slice(1, 2)[0];
  var new3 = Lob2.slice(2, 3)[0] == null ? "nan" : Lob2.slice(2, 3)[0];
  const businessOutcome = SBO2 == null ? "nan" : SBO2;
  // var body = JSON.stringify({
  //   // prompt:
  //   //   "PN:" +
  //   //   " " +
  //   //   PNAME +
  //   //   " " +
  //   //   "$ Industry:" +
  //   //   " " +
  //   //   IN +
  //   //   " " +
  //   //   "$ Summary:" +
  //   //   " " +
  //   //   US +
  //   //   " " +
  //   //   "$ Line of Business 1:" +
  //   //   " " +
  //   //   new1 +
  //   //   " " +
  //   //   "$ Line of Business 2:" +
  //   //   " " +
  //   //   new2 +
  //   //   " " +
  //   //   "$ Line of Business 3:" +
  //   //   " " +
  //   //   new3 +
  //   //   " " +
  //   //   "$ Protagonist:" +
  //   //   " " +
  //   //   PN +
  //   //   " " +
  //   //   "$ BO: " +
  //   //   SBO2 +
  //   //   " " +
  //   //   "$ Demo:" +
  //   //   " " +
  //   //   finalDemo +
  //   //   " " +
  //   //   "\n\n###\n\n",
  //   // prompt: `{'Product Name': '${PNAME}','Industry': '${IN}','Summary:':'${US}','Line of Business 1': '${new1}','Line of Business 2': '${new2}','Line of Business 3': '${new3}','Protagonist': '${PN}','Business Outcome': '${SBO2}','Demo':'${finalDemo}'}`,
  //   prompt: `{${JSON.stringify("Product Name")}: ${JSON.stringify(
  //     PNAME
  //   )},${JSON.stringify("Industry")}: ${JSON.stringify(IN)},${JSON.stringify(
  //     "Summary"
  //   )}: ${JSON.stringify(US)},${JSON.stringify(
  //     "Line of Business 1"
  //   )}: ${JSON.stringify(new1)},${JSON.stringify(
  //     "Line of Business 2"
  //   )}: ${JSON.stringify(new2)},${JSON.stringify(
  //     "Line of Business 3"
  //   )}: ${JSON.stringify(new3)},${JSON.stringify(
  //     "Protagonist"
  //   )}: ${JSON.stringify(PN)},${JSON.stringify(
  //     "Business Outcome"
  //   )}: ${JSON.stringify(businessOutcome)},${JSON.stringify(
  //     "Demo"
  //   )}:${JSON.stringify(finalDemo)}}`,
  //   model: demoModelName,
  //   temperature: 0.8,
  //   max_tokens: 1000,
  //   best_of: 3,
  //   stop: "END",
  //   n: 2,
  //   frequency_penalty: 1,
  // });

  var body = JSON.stringify({
    prompt: `PN: ${PNAME} $ Industry: ${IN} $ Summary: ${US} $ Line of Business 1: ${new1} $ Line of Business 2: ${new2} $ Line of Business 3: ${new3} $ Protagonist: ${PN} $ BO: ${businessOutcome} $ Demo: ${finalDemo === 'nan' ? 'Enhance the storage, processing, and flow of information and insights across your organization.' : finalDemo}\n\n###\n\n`,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/generate-spotlight-demo", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // "OpenAI-Organization": "org-9EamczpLjZMGjhUTPZIEojS8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function scriptThree(
  pName,
  pName2,
  useCase,
  Indursty,
  Protagonist,
  extra3,
  Lob3,
  demoModelName,
  SBO3
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  const finalSentence =
    Protagonist &&
    Protagonist.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  let finalDemo =
    extra3 == null
      ? "nan"
      : extra3.length === 0
        ? "nan"
        : extra3[0].trim().length === 0
          ? "nan"
          : extra3;

  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase && useCase.trim();
  let INDUSRTY = Indursty;
  let PROTAGONIST = finalSentence && finalSentence.trim();

  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var PN =
    PROTAGONIST == null || PROTAGONIST.trim().length === 0
      ? "nan"
      : PROTAGONIST;

  var new1 = Lob3.slice(0, 1)[0] == null ? "nan" : Lob3.slice(0, 1)[0];
  var new2 = Lob3.slice(1, 2)[0] == null ? "nan" : Lob3.slice(1, 2)[0];
  var new3 = Lob3.slice(2, 3)[0] == null ? "nan" : Lob3.slice(2, 3)[0];
  const businessOutcome = SBO3 == null ? "nan" : SBO3;

  // var body = JSON.stringify({
  //   // prompt:
  //   //   "PN:" +
  //   //   " " +
  //   //   PNAME +
  //   //   " " +
  //   //   "$ Industry:" +
  //   //   " " +
  //   //   IN +
  //   //   " " +
  //   //   "$ Summary:" +
  //   //   " " +
  //   //   US +
  //   //   " " +
  //   //   "$ Line of Business 1:" +
  //   //   " " +
  //   //   new1 +
  //   //   " " +
  //   //   "$ Line of Business 2:" +
  //   //   " " +
  //   //   new2 +
  //   //   " " +
  //   //   "$ Line of Business 3:" +
  //   //   " " +
  //   //   new3 +
  //   //   " " +
  //   //   "$ Protagonist:" +
  //   //   " " +
  //   //   PN +
  //   //   " " +
  //   //   "$ BO: " +
  //   //   SBO3 +
  //   //   " " +
  //   //   "$ Demo:" +
  //   //   " " +
  //   //   finalDemo +
  //   //   " " +
  //   //   "\n\n###\n\n",
  //   prompt: `{${JSON.stringify("Product Name")}: ${JSON.stringify(
  //     PNAME
  //   )},${JSON.stringify("Industry")}: ${JSON.stringify(IN)},${JSON.stringify(
  //     "Summary"
  //   )}: ${JSON.stringify(US)},${JSON.stringify(
  //     "Line of Business 1"
  //   )}: ${JSON.stringify(new1)},${JSON.stringify(
  //     "Line of Business 2"
  //   )}: ${JSON.stringify(new2)},${JSON.stringify(
  //     "Line of Business 3"
  //   )}: ${JSON.stringify(new3)},${JSON.stringify(
  //     "Protagonist"
  //   )}: ${JSON.stringify(PN)},${JSON.stringify(
  //     "Business Outcome"
  //   )}: ${JSON.stringify(businessOutcome)},${JSON.stringify(
  //     "Demo"
  //   )}:${JSON.stringify(finalDemo)}}`,
  //   model: demoModelName,
  //   temperature: 0.8,
  //   max_tokens: 1000,
  //   best_of: 3,
  //   stop: "END",
  //   n: 2,
  //   frequency_penalty: 1,
  // });

  var body = JSON.stringify({
    prompt: `PN: ${PNAME} $ Industry: ${IN} $ Summary: ${US} $ Line of Business 1: ${new1} $ Line of Business 2: ${new2} $ Line of Business 3: ${new3} $ Protagonist: ${PN} $ BO: ${businessOutcome} $ Demo: ${finalDemo === 'nan' ? 'Enhance the storage, processing, and flow of information and insights across your organization.' : finalDemo}\n\n###\n\n`,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/generate-spotlight-demo", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        // "OpenAI-Organization": "org-9EamczpLjZMGjhUTPZIEojS8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function scriptFour(
  pName,
  pName2,
  useCase,
  Indursty,
  Protagonist,
  SBO3,
  extra3,
  Lob4
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  const finalSentence =
    Protagonist &&
    Protagonist.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  let finalDemo = extra3 == null ? "nan" : extra3;

  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase && useCase.trim();
  let INDUSRTY = Indursty;
  let PROTAGONIST = finalSentence && finalSentence.trim();

  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var PN = PROTAGONIST == null ? "nan" : PROTAGONIST;

  var new1 = Lob4.slice(0, 1)[0] == null ? "nan" : Lob4.slice(0, 1)[0];
  var new2 = Lob4.slice(1, 2)[0] == null ? "nan" : Lob4.slice(1, 2)[0];
  var new3 = Lob4.slice(2, 3)[0] == null ? "nan" : Lob4.slice(2, 3)[0];

  var body = JSON.stringify({
    prompt:
      "PN:" +
      " " +
      PNAME +
      " " +
      "$ Industry:" +
      " " +
      IN +
      " " +
      "$ Summary:" +
      " " +
      US +
      " " +
      "$ Line of Business 1:" +
      " " +
      new1 +
      " " +
      "$ Line of Business 2:" +
      " " +
      new2 +
      " " +
      "$ Line of Business 3:" +
      " " +
      new3 +
      " " +
      "$ Protagonist:" +
      " " +
      PN +
      " " +
      "$ BO: " +
      SBO3 +
      " " +
      "$ Demo:" +
      " " +
      finalDemo +
      " " +
      "\n\n###\n\n",
    model: demoModelName,
    temperature: 0.8,
    max_tokens: 1000,
    best_of: 3,
    stop: "END",
    n: 2,
    frequency_penalty: 1,
  });
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + "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",
        "OpenAI-Organization": "org-9EamczpLjZMGjhUTPZIEojS8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function FinalOutPut(
  pName1,
  pName2,
  useCase1,
  Indursty1,
  Protagonist1,
  BO1,
  SBO2,
  SBO3,
  SBO4,
  extra1,
  demo12,
  demo13,
  extra2,
  demo22,
  demo23,
  extra3,
  demo32,
  demo33,
  extra4,
  demo42,
  demo43,
  Lob1,
  // ApiKey,
  introModelName
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName1 == null ? "nan" : pName1 + productNameTwo;

  let finalDemo1 = extra1 == null ? "nan" : extra1;
  let finalDemo2 = extra2 == null ? "nan" : extra2;
  let finalDemo3 = extra3 == null ? "nan" : extra3;
  let finalDemo4 = extra4 == null ? "nan" : extra4;
  // let finalDemo2 = extra2 + " " + demo22 + " " + demo23
  // let finalDemo3 = extra3 + " " + demo32 + " " + demo33
  // let finalDemo4 = extra4 + " " + demo42 + " " + demo43

  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase1 && useCase1.trim();
  let INDUSRTY = Indursty1;
  let Bo1 = BO1 && BO1.trim();
  let Bo2 = SBO2 && SBO2.trim();
  let Bo3 = SBO3 && SBO3.trim();
  let Bo4 = SBO4 && SBO4.trim();
  let D1 = finalDemo1 && finalDemo1.trim();
  let D2 = finalDemo2 && finalDemo2.trim();
  let D3 = finalDemo3 && finalDemo3.trim();
  let D4 = finalDemo4 && finalDemo4.trim();

  var PN = PNAME == null ? "nan" : PNAME;
  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var BoONE = Bo1 == null ? "nan" : Bo1;
  var BoTWO = Bo2 == null ? "nan" : Bo2;
  var BoThree = Bo3 == null ? "nan" : Bo3;
  var BoFour = Bo4 == null ? "nan" : Bo4;
  var DEMO1 = D1 == null ? "nan" : D1;
  var DEMO2 = D2 == null ? "nan" : D2;
  var DEMO3 = D3 == null ? "nan" : D3;
  var DEMO4 = D4 == null ? "nan" : D4;

  var newDEmo1 = DEMO1 == null ? "nan" : DEMO1;
  var newDEmo2 = DEMO2 == null ? "nan" : DEMO2;
  var newDEmo3 = DEMO3 == null ? "nan" : DEMO3;

  var new1 =
    Lob1 != ""
      ? Lob1.slice(0, 1)[0] == null
        ? "nan"
        : Lob1.slice(0, 1)[0]
      : "nan";
  var new2 =
    Lob1 != ""
      ? Lob1.slice(1, 2)[0] == null
        ? "nan"
        : Lob1.slice(1, 2)[0]
      : "nan";
  var new3 =
    Lob1 != ""
      ? Lob1.slice(2, 3)[0] == null
        ? "nan"
        : Lob1.slice(2, 3)[0]
      : "nan";

  var body = JSON.stringify({
    // prompt:
    // "PN:" +
    // " " +
    // PN +
    // " " +
    // "$ Industry:" +
    // " " +
    // IN +
    // " $ Summary:" +
    // " " +
    // US +
    // " " +
    // "$ BO-1:" +
    // " " +
    // BoONE +
    // " " +
    // "$ BO-2:" +
    // " " +
    // BoTWO +
    // " " +
    // "$ BO-3: " +
    // BoThree +
    // " \n\n###\n\n",
    prompt: `{'Product Name': '${PN}','Industry': '${IN}','Summary:':'${US}','Business Outcome 1': '${BoONE}','Business Outcome 2': '${BoTWO}','Business Outcome 3': '${BoThree}'}`,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/generate-spotlight-intro", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function FinalOutro(pName1, pName2, useCase1, intro, outroModelName) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName1 == null ? "nan" : pName1 + productNameTwo;

  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase1 && useCase1.trim();

  var PN = PNAME == null ? "nan" : PNAME;
  var US = USECASE == null ? "nan" : USECASE;
  var IN = intro == null ? "nan" : intro;

  // var body = JSON.stringify({
  //   // prompt:
  //   //   "PN: " +
  //   //   PN +
  //   //   " " +
  //   //   "$ Summary: " +
  //   //   US +
  //   //   " " +
  //   //   "$ Intro: " +
  //   //   IN +
  //   //   " \n\n###\n\n",
  //   prompt: `{'Product Name': '${PN}','Summary:':'${US}','Intro': '${IN}}`,
  // });

  var body = JSON.stringify({
    // prompt:
    //   "PN: " +
    //   PN +
    //   " " +
    //   "$ Summary: " +
    //   US +
    //   " " +
    //   "$ Intro: " +
    //   IN +
    //   " \n\n###\n\n",
    prompt: `{PN: ${PN}  $ Use Case: ${US}  $ Intro: ${IN} &&&}`,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/generate-spotlight-outro", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function gettingDemo(pName, pName2, Indursty, Lob1) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  var new1 = Lob1.slice(0, 1)[0] == null ? "nan" : Lob1.slice(0, 1)[0];

  var IN = Indursty == null ? "X-Industry" : Indursty;
  var PN = finalProduct == "" ? "nan" : finalProduct;

  var body = JSON.stringify({
    PN,
    IN,
    new1,
    // "Product_Industry_lob": "SAP Experience Management_Utilities_nan"
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + '/api/generate-keypoints', {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function verbs(BO1) {
  var body = JSON.stringify({
    BO: BO1,
  });
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(
      "https://oe0golgl24.execute-api.us-west-2.amazonaws.com/beta/sap_bo",
      {
        method: "POST",
        body: body,
        signal: controller.signal,
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GettingSummaryDonePreviously(previousStep, previousDemo) {
  var body = JSON.stringify({
    previous_step: previousStep,
    previous_Demo: previousDemo,
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(
      baseUrl + '/api/previous-summary',
      {
        method: "POST",
        body: body,
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function ToriChatService({ question, model, chat_session_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/save-tori-chat", {
      method: "POST",
      body: JSON.stringify({ question, model, chat_session_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function ToriChatForSapHelpService({ question, model, chat_session_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/chat-new/categories", {
      method: "POST",
      body: JSON.stringify({ question, model, chat_session_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function RegenerateChatMessage(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/regenerate-simple/${id}`, {
      method: "GET",
      // body: JSON.stringify({ question, model, chat_session_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function RegenerateFromDemoOrSap(id) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/regenerate-other-model/${id}`, {
      method: "GET",
      // body: JSON.stringify({ question, model, chat_session_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function DislikeGeneratePrompt({ chat_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/dislike-answer`, {
      method: "POST",
      body: JSON.stringify({ chat_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function DislikeWithoutLinks({ chat_id }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/dislike-answer-without-links`, {
      method: "POST",
      body: JSON.stringify({ chat_id }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function UpdateDislikeMessage({ chat_id, new_ans, new_ref, new_disc }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/update-disliked`, {
      method: "POST",
      body: JSON.stringify({ chat_id, new_ans, new_ref, new_disc }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function UpdateDislikeMessageUsingLinkText({ chat_id, type, value, link_index }) {
  let token = localStorage.getItem("token");

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 40000);

  return new Promise((resolve, reject) => {
    fetch(baseUrl + `/api/no-answer-accepted`, {
      method: "POST",
      body: JSON.stringify({ chat_id, type, value, link_index }),
      signal: controller.signal,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
          localStorage.clear()
          sessionStorage.clear()
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
  // let data = text;
  // // if (
  // //   baseUrlModal ===
  // //   "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/bot"
  // // ) {
  // //   data =
  // //     "Answer should be from the document provided and give answer in well mannered and presentable way." +
  // //     text;
  // // }
  // const controller = new AbortController();
  // const tid = setTimeout(() => controller.abort(), 30000);
  // return new Promise((resolve, reject) => {
  //   fetch(`${baseUrlModal}?text=${data}`, {
  //     method: "GET",
  //     signal: controller.signal,
  //     // headers: { "Content-type": "application/json" }
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       resolve(responseJson);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  // clearTimeout(tid);
}

export function GetDemoChatRefernenceText(text) {
  let data = new FormData();
  data.append("text", text);
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/reference`,
      {
        method: "POST",
        body: data,
        signal: controller.signal,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetSapWebRefernenceText(text) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://890cee4pnd.execute-api.us-west-2.amazonaws.com/query/ReferenceWeb?text=${text}`,
      {
        method: "GET",
        signal: controller.signal,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetToriHelpRefernenceText(text) {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://o9oik3h5h9.execute-api.us-west-2.amazonaws.com/query/ReferenceHelp?text=${text}`,
      {
        method: "GET",
        signal: controller.signal,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function GetToriChatRefernenceText(text) {
  // let data = new FormData();
  // data.append("text", text);
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://tvjev7yjr9.execute-api.us-west-2.amazonaws.com/query/reference?text=${text}`,
      {
        method: "Get",
        signal: controller.signal,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function MultipleResponsesService(baseUrlModal, text) {
  let data = new FormData();
  data.append("text", text);
  // if (modal === "link") {
  //   data.append("url", response);
  // } else {
  //   data.append("key_point", response);
  // }

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(`${baseUrlModal}`, {
      method: "POST",
      body: data,
      signal: controller.signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function AdditionalInfoWithLinkOrQuery(
  baseUrlModal,
  text,
  response,
  modal
) {
  // var body = JSON.stringify({
  //   text: text,
  //   url: response,
  // });
  let data = new FormData();
  data.append("text", text);
  if (modal === "link") {
    data.append("url", response);
  } else {
    data.append("key_point", response);
  }

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(`${baseUrlModal}`, {
      method: "POST",
      body: data,
      signal: controller.signal,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function SaveScripts(
  pName,
  pName2,
  useCase,
  Indursty,
  Protagonist,
  BO1,
  SBO2,
  SBO3,
  SBO4,
  Demo1,
  Demo2,
  Demo3,
  Demo4,
  protagnist2,
  protagnist3,
  protagnist4,
  extra,
  extra2,
  extra3,
  extra4,
  newIntro,
  extrasss5,
  newOutro,
  extrasss,
  extrasss2,
  extrasss3,
  extrasss4,
  extrasss6,
  Lob1
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  var new1 =
    Lob1 != ""
      ? Lob1.slice(0, 1)[0] == null
        ? "nan"
        : Lob1.slice(0, 1)[0]
      : "";
  var new2 =
    Lob1 != ""
      ? Lob1.slice(1, 2)[0] == null
        ? "nan"
        : Lob1.slice(1, 2)[0]
      : "";
  var new3 =
    Lob1 != ""
      ? Lob1.slice(2, 3)[0] == null
        ? "nan"
        : Lob1.slice(2, 3)[0]
      : "";

  var body = JSON.stringify({
    Ind: Indursty == null ? "X-Industry" : Indursty,
    PN: finalProduct.trim(),
    Summary: useCase.trim(),
    LOB1: new1,
    LOB2: new2,
    LOB3: new3,
    Intro: extrasss5 == "" ? newIntro.trim() : extrasss5.trim(),
    Protagonist_1: Protagonist.trim(),
    BO_1: BO1.trim(),
    Demo_1: extrasss == "" ? extra.trim() : extrasss.trim(),
    Demo_1_key_points: Demo1.trim(),
    Protagonist_2: protagnist2.trim(),
    BO_2: SBO2.trim(),
    Demo_2: extrasss2 == "" ? extra2.trim() : extrasss2.trim(),
    Demo_2_key_points: Demo2.trim(),
    Protagonist_3: protagnist3.trim(),
    BO_3: SBO3.trim(),
    Demo_3: extrasss3 == "" ? extra3.trim() : extrasss3.trim(),
    Demo_3_key_points: Demo3.trim(),
    Protagonist_4: protagnist4.trim(),
    BO_4: SBO4.trim(),
    Demo_4: extrasss4 == "" ? extra4.trim() : extrasss4.trim(),
    Demo_4_key_points: Demo4.trim(),
    Outro: extrasss6 == "" ? newOutro.trim() : extrasss6.trim(),
  });
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch("/api/storeScripts", {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: { "Content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetSpotlightHeatmapData() {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-script-data", {
      method: "GET",
      signal: controller.signal,
      headers: { "Content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
export function GetProcessHeatmapData() {
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/api/get-process-script-data", {
      method: "GET",
      signal: controller.signal,
      headers: { "Content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}

// generate step  modal
export function generateStepModal(
  pName,
  pName2,
  useCase,
  Indursty,
  domain,
  Protagonist,
  BO1,
  startingPoint,
  Lob1,
  stepValue,
  actionFeature,
  businessOutcomesPrompt
) {
  const step1ModalName = "davinci:ft-ai-dev-lab-2023-03-16-08-17-39";
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName == null ? "nan" : pName + productNameTwo;

  const finalSentence =
    Protagonist &&
    Protagonist.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  let finalDemo = startingPoint == null ? "nan" : startingPoint;
  let featureAction = actionFeature == null ? "nan" : actionFeature;

  let PNAME = finalProduct.trim();
  let USECASE = useCase && useCase.trim();
  let StepValue = stepValue && stepValue.trim();

  let INDUSRTY = Indursty;
  let PROTAGONIST = finalSentence && finalSentence.trim();

  var US = USECASE == null || USECASE.trim().length === 0 ? "nan" : USECASE;
  var SV =
    StepValue == null || StepValue.trim().length === 0 ? "nan" : StepValue;

  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var domainValue =
    domain == null ? "nan" : domain == undefined ? "nan" : domain;
  var PN =
    PROTAGONIST == null || PROTAGONIST.trim().length === 0
      ? "nan"
      : PROTAGONIST;

  var new1 =
    Lob1 && Lob1.slice(0, 1)[0] == null ? "" : Lob1 && Lob1.slice(0, 1)[0];
  var new2 =
    Lob1 && Lob1.slice(1, 2)[0] == null ? "" : Lob1 && Lob1.slice(1, 2)[0];
  var new3 =
    Lob1 && Lob1.slice(2, 3)[0] == null ? "" : Lob1 && Lob1.slice(2, 3)[0];
  var LOB = "";
  if (new2 == "" && new3 == "") {
    LOB = new1;
  } else if (new2 && new3 == "") {
    LOB = new1 + ", " + new2;
  } else {
    LOB = new1 + ", " + new2 + ", " + new3;
  }
  var BusinessOutcome = BO1 ? BO1 : businessOutcomesPrompt;
  // var body = JSON.stringify({
  //   // prompt: `{'Product Name': '${PNAME}','Industry': '${IN}','Description:':'${US}','Line of Business': '${LOB}','Role': '${PN}','Step': '${SV}','Business Outcomes': '${BusinessOutcome}','Start-Point': '${finalDemo}','Actions/Features':'${featureAction}'}`,
  //   prompt: `{${JSON.stringify("Product Name")}: ${JSON.stringify(
  //     PNAME
  //   )},${JSON.stringify("Industry")}: ${JSON.stringify(IN)},${JSON.stringify(
  //     "Description"
  //   )}: ${JSON.stringify(US)},${JSON.stringify(
  //     "Line of Business"
  //   )}: ${JSON.stringify(LOB)},${JSON.stringify(
  //     "Domain/RBA Process"
  //   )}: ${JSON.stringify(domainValue)},${JSON.stringify(
  //     "Role"
  //   )}: ${JSON.stringify(PN)},${JSON.stringify("Step")}: ${JSON.stringify(
  //     SV
  //   )},${JSON.stringify("Business Outcomes")}: ${JSON.stringify(
  //     BusinessOutcome
  //   )},${JSON.stringify("Start-Point")}:${finalDemo},${JSON.stringify(
  //     "Actions/Features"
  //   )}:${JSON.stringify(actionFeature)}}`,
  //   model: step1ModalName,
  //   temperature: 0.7,
  //   max_tokens: 1100,
  //   top_p: 0.8,
  //   stop: " END",
  //   frequency_penalty: 0.5,
  //   presence_penalty: 0.0,
  //   n: 2,
  // });

  var body = JSON.stringify({
    prompt: `PN: ${PNAME} $ Industry: ${IN} $ Description: ${US} $ LOB: ${LOB} $ Role: ${PN}  $ Step: ${SV} $ BO: ${BusinessOutcome} $ Start-Point : ${finalDemo} $ Features/Actions: ${featureAction}`,
  });



  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + '/api/generate-process-demo', {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// Business Process Flow
export function BusinessProcessFlow(
  pName1,
  pName2,
  Indursty,
  description,
  Lob1,
  role1,
  stepValue1,
  BO1,
  role2,
  stepValue2,
  SBO2,
  role3,
  stepValue3,
  SBO3,
  role4,
  stepValue4,
  SBO4,
  role5,
  stepValue5,
  SBO5,
  role6,
  stepValue6,
  SBO6,
  role7,
  stepValue7,
  SBO7
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName1 == null ? "nan" : pName1 + productNameTwo;
  let USECASE = description && description.trim();
  let INDUSRTY = Indursty;
  let PNAME = finalProduct && finalProduct.trim();

  let Bo1 = BO1 && BO1.trim();
  let Bo2 = SBO2 && SBO2.trim();
  let Bo3 = SBO3 && SBO3.trim();
  let Bo4 = SBO4 && SBO4.trim();
  let Bo5 = SBO5 && SBO5.trim();
  let Bo6 = SBO6 && SBO6.trim();
  let Bo7 = SBO7 && SBO7.trim();

  let S1 = stepValue1 && stepValue1.trim();
  let S2 = stepValue2 && stepValue2.trim();
  let S3 = stepValue3 && stepValue3.trim();
  let S4 = stepValue4 && stepValue4.trim();
  let S5 = stepValue5 && stepValue5.trim();
  let S6 = stepValue6 && stepValue6.trim();
  let S7 = stepValue7 && stepValue7.trim();

  var new1 =
    Lob1 && Lob1.slice(0, 1)[0] == null ? "" : Lob1 && Lob1.slice(0, 1)[0];
  var new2 =
    Lob1 && Lob1.slice(1, 2)[0] == null ? "" : Lob1 && Lob1.slice(1, 2)[0];
  var new3 =
    Lob1 && Lob1.slice(2, 3)[0] == null ? "" : Lob1 && Lob1.slice(2, 3)[0];
  var LOB = "";
  if (new2 == "" && new3 == "") {
    LOB = new1;
  } else if (new2 && new3 == "") {
    LOB = new1 + ", " + new2;
  } else {
    LOB = new1 + ", " + new2 + ", " + new3;
  }
  // final values
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;
  var US = USECASE == null ? "nan" : USECASE;
  var PN = PNAME == null ? "nan" : PNAME;
  let Role1 = role1 == null || role1.trim().length === 0 ? "nan" : role1.trim();
  let Role2 = role2 == null || role2.trim().length === 0 ? "nan" : role2.trim();
  let Role3 = role3 == null || role3.trim().length === 0 ? "nan" : role3.trim();
  let Role4 = role4 == null || role4.trim().length === 0 ? "nan" : role4.trim();
  let Role5 = role5 == null || role5.trim().length === 0 ? "nan" : role5.trim();
  let Role6 = role6 == null || role6.trim().length === 0 ? "nan" : role6.trim();
  let Role7 = role7 == null || role7.trim().length === 0 ? "nan" : role7.trim();

  var Step1 = S1 == null ? "nan" : S1;
  var Step2 = S2 == null ? "nan" : S2;
  var Step3 = S3 == null ? "nan" : S3;
  var Step4 = S4 == null ? "nan" : S4;
  var Step5 = S5 == null ? "nan" : S5;
  var Step6 = S6 == null ? "nan" : S6;
  var Step7 = S7 == null ? "nan" : S7;

  var BoONE = Bo1 == null || Bo1.trim().length === 0 ? "nan" : Bo1;
  var BoTWO = Bo2 == null || Bo2.trim().length === 0 ? "nan" : Bo2;
  var BoThree = Bo3 == null || Bo3.trim().length === 0 ? "nan" : Bo3;
  var BoFour = Bo4 == null || Bo4.trim().length === 0 ? "nan" : Bo4;
  var BoFive = Bo5 == null || Bo5.trim().length === 0 ? "nan" : Bo5;
  var BoSix = Bo6 == null || Bo6.trim().length === 0 ? "nan" : Bo6;
  var BoSeven = Bo7 == null || Bo7.trim().length === 0 ? "nan" : Bo7;

  var body = JSON.stringify({
    prompt:
      "PN:" +
      " " +
      PN +
      " " +
      "$ Industry:" +
      " " +
      IN +
      " " +
      "$ Description:" +
      " " +
      US +
      " " +
      "$ LOB:" +
      " " +
      LOB +
      " " +
      "$ Role-1:" +
      " " +
      Role1 +
      " " +
      "$ Step-1:" +
      " " +
      Step1 +
      " " +
      "$ BO-1:" +
      " " +
      BoONE +
      " " +
      "$ Role-2:" +
      " " +
      Role2 +
      " " +
      "$ Step-2:" +
      " " +
      Step2 +
      " " +
      "$ BO-2:" +
      " " +
      BoTWO +
      " " +
      "$ Role-3:" +
      " " +
      Role3 +
      " " +
      "$ Step-3:" +
      " " +
      Step3 +
      " " +
      "$ BO-3:" +
      " " +
      BoThree
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + '/api/generate-business-process', {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// Business Process Flow Intro

export function BPFIntro(
  pName1,
  pName2,
  Indursty1,
  useCase1,
  Lob1,
  BusinessOutcomes,
  Process
) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName1 == null ? "nan" : pName1 + productNameTwo;

  var new1 =
    Lob1 && Lob1.slice(0, 1)[0] == null ? "" : Lob1 && Lob1.slice(0, 1)[0];
  var new2 =
    Lob1 && Lob1.slice(1, 2)[0] == null ? "" : Lob1 && Lob1.slice(1, 2)[0];
  var new3 =
    Lob1 && Lob1.slice(2, 3)[0] == null ? "" : Lob1 && Lob1.slice(2, 3)[0];
  var LOB = "";
  if (new2 == "" && new3 == "") {
    LOB = new1;
  } else if (new2 && new3 == "") {
    LOB = new1 + ", " + new2;
  } else {
    LOB = new1 + ", " + new2 + ", " + new3;
  }
  let PNAME = finalProduct && finalProduct.trim();
  let BusiOut = BusinessOutcomes && BusinessOutcomes.trim();
  let BPflow = Process && Process.trim();

  let USECASE = useCase1 && useCase1.trim();
  let INDUSRTY = Indursty1;

  var PN = PNAME == null ? "nan" : PNAME;
  var BO = BusiOut == (null || "") ? "nan" : BusiOut;
  var BPF = BPflow == null ? "nan" : BPflow;

  var US = USECASE == null ? "nan" : USECASE;
  var IN = INDUSRTY == null ? "X-Industry" : INDUSRTY;

  var body = JSON.stringify({
    prompt:
      "PN:" +
      " " +
      PN +
      " " +
      "$ Industry:" +
      " " +
      IN +
      " $ Description:" +
      " " +
      US +
      " " +
      "$ LOB:" +
      " " +
      LOB +
      " " +
      "$ Business Outcomes:" +
      " " +
      BO +
      " " +
      "$ Process: " +
      BPF
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(baseUrl + '/api/generate-process-intro', {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
// Business Process Flow Outro

export function BPFOutro(pName1, pName2, useCase1, intro) {
  let productNameTwo = pName2 == null ? "" : " and " + pName2;
  let finalProduct = pName1 == null ? "nan" : pName1 + productNameTwo;

  let PNAME = finalProduct && finalProduct.trim();
  let USECASE = useCase1 && useCase1.trim();

  var PN = PNAME == null ? "nan" : PNAME;
  var US = USECASE == null ? "nan" : USECASE;
  var IN = intro == null ? "nan" : intro;

  var body = JSON.stringify({
    prompt:
      "PN: " +
      PN +
      " " +
      "$ Description:" +
      US +
      " " +
      "$ Intro: " +
      IN +
      " \n\n###\n\n",
  });

  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 20000);
  return new Promise((resolve, reject) => {
    fetch(baseUrl + '/api/generate-process-outro', {
      method: "POST",
      body: body,
      signal: controller.signal,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
  clearTimeout(tid);
}
