import React from "react";
import LoadingSpinner from "../assets/spinnerAdam.gif";
const HeatmapLoader = () => {
  return (
    <div
      className="load"
      style={{
        width: "100vw",
        height: "200vh",
        zIndex: "99999999",
        position: "absolute",
        background: "white",
      }}
    >
      {" "}
      <div
        id="loader_id"
        style={{
          marginTop: "50vh",
          marginLeft: "35vw",
        }}
      >
        <img width={150} height={150} src={LoadingSpinner} />
      </div>
    </div>
  );
};

export default HeatmapLoader;
