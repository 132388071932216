export const dummyData = [
  {
    text: "Analytics",
    nodes: [
      { text: "SAP Analytics Cloud", isSelected: true },
      { text: "SAP Analytics Hub", isSelected: true },
      { text: "SAP BusinessObjects Business Intelligence", isSelected: true },
      { text: "SAP BusinessObjects Dashboards", isSelected: true },
      { text: "SAP BusinessObjects Explorer", isSelected: true },
      { text: "SAP BusinessObjects Intercompany", isSelected: true },
      { text: "SAP BusinessObjects Web Intelligence", isSelected: true },
      { text: "SAP Crystal Reports", isSelected: true },
      { text: "SAP Crystal Server", isSelected: true },
      { text: "SAP Digital Boardroom", isSelected: true },
      { text: "SAP Global Trade Services", isSelected: true },
      { text: "SAP IT Operation Analytics", isSelected: true },
      { text: "SAP Lumira", isSelected: true },
      { text: "SAP Predictive Analytics", isSelected: true },
      { text: "SAP Roambi", isSelected: true },
      { text: "SAP Sailing Analytics", isSelected: true },
      { text: "SAP Watch List Screening", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Analytics Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Analytics Cloud", isSelected: true },
          { text: "SAP Analytics Cloud, embedded edition", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Analytics Hub",
        isSelected: false,
        nodes: [{ text: "SAP Analytics Hub", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP BusinessObjects Business Intelligence",
        isSelected: false,
        nodes: [
          {
            text: "BI rapid-configuration package for SAP HANA",
            isSelected: true,
          },
          {
            text: "SAP BusinessObjects BI Location Intelligence by Galigeo",
            isSelected: true,
          },
          {
            text: "SAP BusinessObjects BI, Edge edition, version with data integration (not for connection to SAP ERP)",
            isSelected: true,
          },
          {
            text: "SAP BusinessObjects Business Intelligence platform",
            isSelected: true,
          },
          {
            text: "SAP BusinessObjects Business Intelligence, Edge edition, standard package",
            isSelected: true,
          },
          { text: "SAP BusinessObjects Mobile", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP BusinessObjects Explorer",
        isSelected: false,
        nodes: [{ text: "SAP BusinessObjects Explorer", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP BusinessObjects Intercompany",
        isSelected: false,
        nodes: [{ text: "SAP BusinessObjects Intercompany", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Lumira",
        isSelected: false,
        nodes: [
          { text: "SAP BusinessObjects Design Studio", isSelected: true },
          { text: "SAP Lumira, desktop edition", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Predictive Analytics",
        isSelected: false,
        nodes: [
          { text: "SAP InfiniteInsight", isSelected: true },
          { text: "SAP Predictive Analytics", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Vehicle Insights",
        isSelected: false,
        nodes: [{ text: "SAP Vehicle Insights", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Watch List Screening",
        isSelected: false,
        nodes: [{ text: "SAP Watch List Screening", isSelected: true }],
      },
    ],
  },

  {
    text: "Topic",
    nodes: [{ text: "SAP Digital Supplier Network", isSelected: true }],
  },
  { text: "", nodes: [{ text: "SAP Retail", isSelected: true }] },
  {
    text: "",
    nodes: [
      { text: "SAP Industrial Machinery and Components", isSelected: true },
    ],
  },
  { text: "", nodes: [{ text: "SAP Insurance", isSelected: true }] },
  {
    text: "",
    nodes: [{ text: "Zero Waste for Perishable Products", isSelected: true }],
  },
  {
    text: "",
    nodes: [
      {
        text: "Bundle",
        isSelected: false,
        nodes: [{ text: "RISE with SAP", isSelected: true }],
      },
    ],
  },

  {
    text: "Content and Collaboration",
    nodes: [
      {
        text: "SAP Content Management for Microsoft SharePoint by OpenText",
        isSelected: true,
      },
      { text: "SAP Digital Content Processing by OpenText", isSelected: true },
      { text: "SAP Document Center", isSelected: true },
      { text: "SAP Document Presentment by OpenText", isSelected: true },
      { text: "SAP Exchange Media", isSelected: true },
      {
        text: "SAP Extended Enterprise Content Management by OpenText",
        isSelected: true,
      },
      { text: "SAP Jam", isSelected: true },
      { text: "SAP Knowledge Workspace", isSelected: true },
      { text: "SAP Mobile Documents", isSelected: true },
      { text: "SAP People Connect 365", isSelected: true },
      { text: "SAP Portal Content Management By OpenText", isSelected: true },
      { text: "SAP Portal Site Management by OpenText", isSelected: true },
      { text: "SAP Signature Management by DocuSign", isSelected: true },
      { text: "localization services", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Content Management for Microsoft SharePoint by OpenText",
        isSelected: false,
        nodes: [
          {
            text: "SAP Content Management for Microsoft SharePoint by OpenText",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Content Stream by Skillsoft",
        isSelected: false,
        nodes: [{ text: "SAP Content Stream by Skillsoft", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Document Presentment by OpenText",
        isSelected: false,
        nodes: [
          { text: "SAP Document Presentment by OpenText", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Exchange Media",
        isSelected: false,
        nodes: [{ text: "SAP Exchange Media", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Jam",
        isSelected: false,
        nodes: [
          { text: "SAP Jam Collaboration", isSelected: true },
          { text: "SAP Jam Communities", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Knowledge Workspace",
        isSelected: false,
        nodes: [{ text: "SAP Knowledge Workspace", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP People Connect 365",
        isSelected: false,
        nodes: [{ text: "SAP People Connect 365", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Portal Content Management by OpenText",
        isSelected: false,
        nodes: [
          {
            text: "SAP Portal Content Management by OpenText",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Portal Site Management By OpenText",
        isSelected: false,
        nodes: [
          { text: "SAP Portal Site Management By OpenText", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "localization services",
        isSelected: false,
        nodes: [
          {
            text: "SAP Localization Hub, advanced compliance reporting service",
            isSelected: true,
          },
          { text: "SAP Localization Hub, tax service", isSelected: true },
        ],
      },
    ],
  },

  {
    text: "Customer Relationship Management",
    nodes: [
      { text: "Adobe Marketing Solutions from SAP", isSelected: true },
      { text: "SAP Capital Markets Trading by Calypso", isSelected: true },
      { text: "SAP Claims Management", isSelected: true },
      { text: "SAP Cloud for Customer", isSelected: true },
      { text: "SAP Commerce", isSelected: true },
      { text: "SAP Commerce Cloud", isSelected: true },
      {
        text: "SAP Commerce Marketplace Management by Mirakl",
        isSelected: true,
      },
      { text: "SAP Consumer Sales Intelligence", isSelected: true },
      { text: "SAP Contact Center", isSelected: true },
      { text: "SAP CRM Sales", isSelected: true },
      { text: "SAP Customer Activity Repository", isSelected: true },
      { text: "SAP Customer Analytics for Retail", isSelected: true },
      { text: "SAP Customer Checkout", isSelected: true },
      { text: "SAP Customer Data Cloud", isSelected: true },
      { text: "SAP Customer Experience solutions", isSelected: true },
      { text: "SAP Customer Insight", isSelected: true },
      { text: "SAP Customer Order Sourcing", isSelected: true },
      { text: "SAP Customer Relationship Management", isSelected: true },
      { text: "SAP Dealer Business Management", isSelected: true },
      { text: "SAP Dynamic Pricing by GK", isSelected: true },
      { text: "SAP E-Commerce", isSelected: true },
      { text: "SAP Emarsys Customer Engagement", isSelected: true },
      { text: "SAP Enterprise Point-of-Sale", isSelected: true },
      { text: "SAP Event Ticketing", isSelected: true },
      { text: "SAP Field Service Management", isSelected: true },
      { text: "SAP Health", isSelected: true },
      {
        text: "SAP Incentive Administration by Vistex and SAP Paybacks and Chargebacks by Vistex",
        isSelected: true,
      },
      { text: "SAP Litmos", isSelected: true },
      { text: "SAP Marketing", isSelected: true },
      { text: "SAP Marketing Cloud", isSelected: true },
      { text: "SAP Mobile Consumer Assistant by GK", isSelected: true },
      { text: "SAP Omnichannel Point-of-Sale by GK", isSelected: true },
      { text: "SAP Omnichannel Promotion Pricing", isSelected: true },
      { text: "SAP Order Management", isSelected: true },
      { text: "SAP Precision Marketing", isSelected: true },
      { text: "SAP Price and Margin Management by Vendavo", isSelected: true },
      { text: "SAP Real Estate Management", isSelected: true },
      { text: "SAP Sales Cloud", isSelected: true },
      { text: "SAP Service Cloud", isSelected: true },
      { text: "SAP Signature Management by DocuSign", isSelected: true },
      { text: "SAP Store Management by GK", isSelected: true },
      { text: "SAP Student Lifecycle Management", isSelected: true },
      { text: "SAP Tempo Box by OpenText", isSelected: true },
      { text: "SAP Trade Management", isSelected: true },
      { text: "SAP Trade Promotion Management", isSelected: true },
      { text: "SAP Upscale Commerce", isSelected: true },
      { text: "SAP Utilities Customer Engagement", isSelected: true },
      { text: "SAP Web Channel Experience Management", isSelected: true },
      { text: "Sybase Mobile Sales for SAP CRM", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud for Customer",
        isSelected: false,
        nodes: [{ text: "SAP Cloud for Customer", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Commerce",
        isSelected: false,
        nodes: [{ text: "SAP Commerce", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Commerce Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Commerce Cloud", isSelected: true },
          { text: "SAP Product Content Hub", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Commerce Marketplace Management by Mirakl",
        isSelected: false,
        nodes: [
          {
            text: "SAP Commerce Marketplace Management by Mirakl",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Consumer Sales Intelligence",
        isSelected: false,
        nodes: [{ text: "SAP Consumer Sales Intelligence", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Activity Repository",
        isSelected: false,
        nodes: [{ text: "SAP Customer Activity Repository", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Analytics for Retail",
        isSelected: false,
        nodes: [
          { text: "SAP Customer Analytics for Retail", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Checkout",
        isSelected: false,
        nodes: [{ text: "SAP Customer Checkout", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Data Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Customer Data Cloud", isSelected: true },
          { text: "SAP Customer Data Platform", isSelected: true },
          { text: "SAP Customer Identity", isSelected: true },
          {
            text: "SAP Digital Asset Management by OpenText",
            isSelected: true,
          },
          {
            text: "SAP Digital Asset Management Cloud by OpenText",
            isSelected: true,
          },
          { text: "SAP Digital Documents by OpenText", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Relationship Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Customer Relationship Management, add-on for service industries",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Dealer Business Management",
        isSelected: false,
        nodes: [{ text: "SAP Dealer Business Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Dynamic Pricing by GK",
        isSelected: false,
        nodes: [
          {
            text: "SAP Dynamic Pricing by GK, cloud edition",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Enterprise Point-of-Sale",
        isSelected: false,
        nodes: [
          { text: "SAP Enterprise Point-of-Sale", isSelected: true },
          { text: "SAP Enterprise Point-of-Sale Base", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Event Ticketing",
        isSelected: false,
        nodes: [
          { text: "SAP Event Ticketing, cloud edition", isSelected: true },
          { text: "SAP Event Ticketing, on-premise edition", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Incentive Administration by Vistex and SAP Paybacks and Chargebacks by Vistex",
        isSelected: false,
        nodes: [
          {
            text: "SAP Incentive Administration by Vistex and SAP Paybacks and Chargebacks by Vistex",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Litmos",
        isSelected: false,
        nodes: [{ text: "SAP Litmos Training", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Marketing",
        isSelected: false,
        nodes: [{ text: "SAP Marketing", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Marketing Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Loyalty Marketing", isSelected: true },
          { text: "SAP Marketing Cloud", isSelected: true },
          { text: "SAP Mobile Consumer Assistant by GK", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Omnichannel Point-of-Sale by GK",
        isSelected: false,
        nodes: [
          {
            text: "SAP Omnichannel Point-of-Sale by GK, cloud edition",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Omnichannel Promotion Pricing",
        isSelected: false,
        nodes: [
          { text: "SAP Omnichannel Promotion Pricing", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Precision Marketing",
        isSelected: false,
        nodes: [{ text: "SAP Precision Marketing", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Price and Margin Management by Vendavo",
        isSelected: false,
        nodes: [
          {
            text: "SAP Price and Margin Management by Vendavo",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Sales Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Agent Performance Management", isSelected: true },
          { text: "SAP Commissions", isSelected: true },
          { text: "SAP Convergent Charging", isSelected: true },
          { text: "SAP Flexible Solution Billing", isSelected: true },
          { text: "SAP Intelligent Sales Execution", isSelected: true },
          { text: "SAP Sales Cloud, starter edition", isSelected: true },
          { text: "SAP Variant Configuration and Pricing", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Service Cloud",
        isSelected: false,
        nodes: [
          { text: "SAP Customer Engagement Center", isSelected: true },
          { text: "SAP Knowledge Central by NICE", isSelected: true },
          {
            text: "SAP Self-Service Accelerator for Utilities by SEW",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Signature Management by DocuSign",
        isSelected: false,
        nodes: [
          { text: "SAP Signature Management by DocuSign", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Social Media Analytics by NetBase",
        isSelected: false,
        nodes: [
          { text: "SAP Social Media Analytics by NetBase", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Store Management by GK",
        isSelected: false,
        nodes: [
          { text: "SAP Label and Poster Printing by GK", isSelected: true },
          { text: "SAP Offline Mobile Store by GK", isSelected: true },
          { text: "SAP Omnichannel Point-of-Sale by GK", isSelected: true },
          { text: "SAP Open Scale Management by GK", isSelected: true },
          { text: "SAP Store Device Control by GK", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Student Lifecycle Management",
        isSelected: false,
        nodes: [{ text: "SAP Student Lifecycle Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Trade Management",
        isSelected: false,
        nodes: [{ text: "SAP Trade Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Upscale Commerce",
        isSelected: false,
        nodes: [{ text: "SAP Upscale Commerce", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Utilities Customer Engagement",
        isSelected: false,
        nodes: [
          { text: "SAP Utilities Customer Engagement", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Web Channel Experience Management",
        isSelected: false,
        nodes: [
          { text: "SAP Web Channel Experience Management", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Sybase Mobile Sales for SAP CRM",
        isSelected: false,
        nodes: [{ text: "Sybase Mobile Sales for SAP CRM", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Experience Foundation",
        isSelected: false,
        nodes: [
          { text: "SAP Customer Experience Foundation", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Customer Experience solutions",
        isSelected: false,
        nodes: [
          { text: "SAP Sales Cloud", isSelected: true },
          { text: "SAP Service Cloud", isSelected: true },
        ],
      },
    ],
  },

  {
    text: "Data Management",
    nodes: [
      { text: "SAP Adaptive Server Enterprise", isSelected: true },
      {
        text: "SAP Advanced Data Migration by BackOffice Associates",
        isSelected: true,
      },
      { text: "SAP Agile Data Preparation", isSelected: true },
      { text: "SAP BW/4HANA", isSelected: true },
      { text: "SAP Data Custodian", isSelected: true },
      { text: "SAP Data Encryption by CipherCloud", isSelected: true },
      { text: "SAP Data Integrator", isSelected: true },
      { text: "SAP Data Intelligence", isSelected: true },
      { text: "SAP Data Maintenance for SAP ERP by Vistex", isSelected: true },
      { text: "SAP Data Mapping and Protection by BigID", isSelected: true },
      { text: "SAP Data Quality Management", isSelected: true },
      { text: "SAP Data Services", isSelected: true },
      {
        text: "SAP Enterprise Digital Rights Management by NextLabs",
        isSelected: true,
      },
      { text: "SAP Event Stream Processor", isSelected: true },
      { text: "SAP Information Steward", isSelected: true },
      { text: "SAP Invoice Management by OpenText", isSelected: true },
      { text: "SAP IQ", isSelected: true },
      { text: "SAP Landscape Transformation", isSelected: true },
      { text: "SAP Master Data Governance", isSelected: true },
      { text: "SAP Meter Data Management by Siemens", isSelected: true },
      { text: "SAP PowerBuilder", isSelected: true },
      { text: "SAP PowerDesigner", isSelected: true },
      { text: "SAP Privacy Management by BigID", isSelected: true },
      { text: "SAP Rapid Marts", isSelected: true },
      { text: "SAP Replication Server", isSelected: true },
      { text: "SAP SQL Anywhere", isSelected: true },
      { text: "SAP Sybase RAP", isSelected: true },
      { text: "SAP Test Data Migration Server", isSelected: true },
      { text: "SAP Vora", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Adaptive Server Enterprise",
        isSelected: false,
        nodes: [{ text: "SAP Adaptive Server Enterprise", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP BW/4HANA",
        isSelected: false,
        nodes: [{ text: "SAP BW/4HANA", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Integrator",
        isSelected: false,
        nodes: [{ text: "SAP Data Integrator", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Intelligence",
        isSelected: false,
        nodes: [
          { text: "SAP Data Intelligence", isSelected: true },
          { text: "SAP Data Intelligence Cloud", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Quality Management",
        isSelected: false,
        nodes: [
          { text: "SAP Data Quality Management", isSelected: true },
          {
            text: "SAP Data Quality Management for SAP Netweaver MDM",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Services",
        isSelected: false,
        nodes: [{ text: "SAP Data Services", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Event Stream Processor",
        isSelected: false,
        nodes: [{ text: "SAP Event Stream Processor", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Information Steward",
        isSelected: false,
        nodes: [
          { text: "SAP Information Steward", isSelected: true },
          {
            text: "SAP Information Steward Accelerator by Syniti",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Invoice Management by OpenText",
        isSelected: false,
        nodes: [
          { text: "SAP Invoice Management by OpenText", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP IQ",
        isSelected: false,
        nodes: [{ text: "SAP IQ", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Landscape Transformation",
        isSelected: false,
        nodes: [{ text: "SAP Landscape Transformation", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Master Data Governance",
        isSelected: false,
        nodes: [
          { text: "SAP Master Data Governance", isSelected: true },
          {
            text: "SAP Master Data Governance, enterprise asset management extension by Utopia",
            isSelected: true,
          },
          {
            text: "SAP Master Data Governance, retail and fashion management extension by Utopia for SAP S/4HANA",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Meter Data Management by Siemens",
        isSelected: false,
        nodes: [
          { text: "SAP Meter Data Management by Siemens", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP PowerBuilder",
        isSelected: false,
        nodes: [{ text: "SAP PowerBuilder", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP PowerDesigner",
        isSelected: false,
        nodes: [{ text: "SAP PowerDesigner", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Privacy Management by BigID",
        isSelected: false,
        nodes: [{ text: "SAP Privacy Management by BigID", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Replication Server",
        isSelected: false,
        nodes: [{ text: "SAP Replication Server", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP SQL Anywhere",
        isSelected: false,
        nodes: [{ text: "SAP SQL Anywhere", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Test Data Migration Server",
        isSelected: false,
        nodes: [{ text: "SAP Test Data Migration Server", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Vora",
        isSelected: false,
        nodes: [{ text: "SAP Vora", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Maintenance by Vistex",
        isSelected: false,
        nodes: [{ text: "SAP Data Maintenance by Vistex", isSelected: true }],
      },
    ],
  },

  {
    text: "Digital Manufacturing",
    nodes: [{ text: "Digital Manufacturing", isSelected: true }],
  },
  {
    text: "",
    nodes: [
      {
        text: "Digital Manufacturing",
        isSelected: false,
        nodes: [
          { text: "SAP Digital Manufacturing Cloud", isSelected: true },
          { text: "SAP Digital Manufacturing Insights", isSelected: true },
        ],
      },
    ],
  },

  {
    text: "Enterprise Management",
    nodes: [
      { text: "Manufacturing", isSelected: true },
      { text: "SAP Service and Asset Manager", isSelected: true },
      { text: "SAP Business ByDesign", isSelected: true },
      { text: "SAP Business One", isSelected: true },
      { text: "SAP Change Impact Analysis by Tricentis", isSelected: true },
      { text: "SAP Digital Vehicle Suite", isSelected: true },
      { text: "SAP Enterprise Architecture Designer", isSelected: true },
      { text: "SAP Enterprise Asset Management", isSelected: true },
      {
        text: "SAP Enterprise Continuous Testing by Tricentis",
        isSelected: true,
      },
      {
        text: "SAP Enterprise Performance Testing by Tricentis",
        isSelected: true,
      },
      { text: "SAP Entitlement Management", isSelected: true },
      { text: "SAP ERP", isSelected: true },
      { text: "SAP Load Testing by Tricentis", isSelected: true },
      { text: "SAP R/3", isSelected: true },
      { text: "SAP R/3 add-on for high tech", isSelected: true },
      { text: "SAP S/4HANA", isSelected: true },
      { text: "SAP S/4HANA Cloud", isSelected: true },
      { text: "SAP StormRunner Load by Micro Focus", isSelected: true },
      { text: "SAP Teamcenter by Siemens", isSelected: true },
      {
        text: "SAP Upstream Field Activity Management by OIS",
        isSelected: true,
      },
      { text: "SAP Work Manager", isSelected: true },
      {
        text: "SAP Workforce Forecasting and Scheduling by WorkForce Software",
        isSelected: true,
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Manufacturing",
        isSelected: false,
        nodes: [
          { text: "SAP Manufacturing Execution", isSelected: true },
          {
            text: "SAP Manufacturing Integration and Intelligence",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Asset Information Workbench by Utopia",
        isSelected: false,
        nodes: [
          {
            text: "SAP Asset Information Workbench by Utopia",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Business ByDesign",
        isSelected: false,
        nodes: [{ text: "SAP Business ByDesign", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Business One",
        isSelected: false,
        nodes: [
          { text: "SAP Business One", isSelected: true },
          {
            text: "SAP Business One analytics powered by SAP HANA",
            isSelected: true,
          },
          { text: "SAP Business One Cloud", isSelected: true },
          { text: "SAP Business One, version for SAP HANA", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Change Impact Analysis by Tricentis",
        isSelected: false,
        nodes: [
          { text: "SAP Change Impact Analysis by Tricentis", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Enterprise Continuous Testing by Tricentis",
        isSelected: false,
        nodes: [
          {
            text: "SAP Enterprise Continuous Testing by Tricentis",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP ERP",
        isSelected: false,
        nodes: [
          {
            text: "SAP Business All-in-One, manufacturing edition",
            isSelected: true,
          },
          { text: "SAP ERP", isSelected: true },
          {
            text: "SAP ERP add-on for financial accounting and operations",
            isSelected: true,
          },
          { text: "SAP ERP add-on for retail", isSelected: true },
          { text: "SAP ERP Central Component", isSelected: true },
          {
            text: "SAP ERP for Business All-in-One, manufacturing edition",
            isSelected: true,
          },
          { text: "SAP ERP, localization extensions", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Load Testing by Tricentis",
        isSelected: false,
        nodes: [{ text: "SAP Load Testing by Tricentis", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP R/3",
        isSelected: false,
        nodes: [
          { text: "SAP R/3", isSelected: true },
          {
            text: "SAP R/3 add-on for aerospace and defense",
            isSelected: true,
          },
          { text: "SAP R/3 add-on for automotive", isSelected: true },
          { text: "SAP R/3 add-on for banking", isSelected: true },
          { text: "SAP R/3 add-on for Consumer Products", isSelected: true },
          {
            text: "SAP R/3 add-on for engineering, construction, and operations",
            isSelected: true,
          },
          { text: "SAP R/3 add-on for media", isSelected: true },
          { text: "SAP R/3 add-on for Mill Products", isSelected: true },
          { text: "SAP R/3 add-on for mining", isSelected: true },
          { text: "SAP R/3 add-on for Oil and Gas", isSelected: true },
          { text: "SAP R/3 add-on for public sector", isSelected: true },
          { text: "SAP R/3 add-on for telecommunications", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP S/4HANA",
        isSelected: false,
        nodes: [
          { text: "SAP S/4HANA", isSelected: true },
          { text: "SAP S/4HANA Cloud", isSelected: true },
          { text: "SAP S/4HANA Finance", isSelected: true },
          {
            text: "SAP S/4HANA for agreement profitability and negotiation by gicom",
            isSelected: true,
          },
          { text: "SAP S/4HANA for central procurement", isSelected: true },
          {
            text: "SAP S/4HANA for rights and royalty management by Vistex",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP S/4HANA Cloud",
        isSelected: false,
        nodes: [
          {
            text: "SAP S/4HANA Cloud for central procurement",
            isSelected: true,
          },
          {
            text: "SAP S/4HANA Cloud for projects, project collaboration",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP StormRunner Load by Micro Focus",
        isSelected: false,
        nodes: [
          { text: "SAP StormRunner Load by Micro Focus", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Work Manager",
        isSelected: false,
        nodes: [{ text: "SAP Work Manager by Syclo", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Workforce Forecasting and Scheduling by WorkForce Software",
        isSelected: false,
        nodes: [
          {
            text: "SAP Workforce Forecasting and Scheduling by WorkForce Software",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Service and Asset Manager",
        isSelected: false,
        nodes: [{ text: "SAP Service and Asset Manager", isSelected: true }],
      },
    ],
  },

  {
    text: "Financial Management",
    nodes: [
      { text: "Banking services from SAP", isSelected: true },
      { text: "SAP Access Control", isSelected: true },
      {
        text: "SAP Access Violation Management by Greenlight",
        isSelected: true,
      },
      {
        text: "SAP Account Substantiation and Automation by BlackLine",
        isSelected: true,
      },
      {
        text: "SAP Archiving and Document Access by OpenText",
        isSelected: true,
      },
      { text: "SAP assurance and compliance software", isSelected: true },
      { text: "SAP Bank Analyzer", isSelected: true },
      { text: "SAP Bank Communication Management", isSelected: true },
      { text: "SAP Business Planning and Consolidation", isSelected: true },
      {
        text: "SAP Central Finance Data Harmonization by Magnitude for SAP S/4HANA",
        isSelected: true,
      },
      {
        text: "SAP Central Finance Transaction Replication by Magnitude",
        isSelected: true,
      },
      { text: "SAP Cloud for Financials", isSelected: true },
      { text: "SAP Cloud for Real Estate", isSelected: true },
      { text: "SAP Cloud for Travel and Expense", isSelected: true },
      { text: "SAP Commodity Management", isSelected: true },
      { text: "SAP Disclosure Management", isSelected: true },
      {
        text: "SAP Dynamic Authorization Management by NextLabs",
        isSelected: true,
      },
      { text: "SAP Electronic Invoicing for Brazil", isSelected: true },
      { text: "SAP Financial Consolidation", isSelected: true },
      { text: "SAP Financial Information Management", isSelected: true },
      { text: "SAP Global Trade Services", isSelected: true },
      { text: "SAP Intercompany Financial Hub by BlackLine", isSelected: true },
      { text: "SAP Invoice Processing", isSelected: true },
      { text: "SAP Lease Administration by Nakisa", isSelected: true },
      { text: "SAP Notes Management", isSelected: true },
      { text: "SAP Process Control", isSelected: true },
      { text: "SAP Profitability and Cost Management", isSelected: true },
      {
        text: "SAP Profitability and Performance Management",
        isSelected: true,
      },
      { text: "SAP Regulation Management by Greenlight", isSelected: true },
      { text: "SAP Risk Management", isSelected: true },
      { text: "SAP Spend Performance Management", isSelected: true },
      { text: "SAP Strategy Management", isSelected: true },
      { text: "SAP Sustainability Performance Management", isSelected: true },
      {
        text: "SAP Technical Data Export Compliance by NextLabs",
        isSelected: true,
      },
      { text: "SAP Trade Repository Reporting by Virtusa", isSelected: true },
      { text: "SAP Travel and Expense Management", isSelected: true },
      { text: "SAP Travel Receipts Management by OpenText", isSelected: true },
      { text: "SAP Treasury and Risk Management", isSelected: true },
      { text: "SAP U.S. Payroll Tax Calculation by BSI", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Banking services from SAP",
        isSelected: false,
        nodes: [
          {
            text: "Analytical banking extension for banking services from SAP",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Access Control",
        isSelected: false,
        nodes: [
          { text: "SAP Access Control", isSelected: true },
          { text: "SAP Access Control for SAP S/4HANA", isSelected: true },
          {
            text: "SAP GRC Access Enforcer for SAP NetWeaver",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Access Violation Management by Greenlight",
        isSelected: false,
        nodes: [
          {
            text: "SAP Access Violation Management by Greenlight",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Account Substantiation and Automation by BlackLine",
        isSelected: false,
        nodes: [
          {
            text: "SAP Account Substantiation and Automation by BlackLine",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Archiving and Document Access by OpenText",
        isSelected: false,
        nodes: [
          {
            text: "SAP Archiving by OpenText and SAP Document Access by OpenText",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP assurance and compliance software",
        isSelected: false,
        nodes: [
          { text: "SAP assurance and compliance software", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Bank Analyzer",
        isSelected: false,
        nodes: [{ text: "SAP Bank Analyzer", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Central Finance Data Harmonization by Magnitude for SAP S/4HANA",
        isSelected: false,
        nodes: [
          {
            text: "SAP Central Finance Data Harmonization by Magnitude for SAP S/4HANA",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud for Financials",
        isSelected: false,
        nodes: [{ text: "SAP Cloud for Financials", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud for Real Estate",
        isSelected: false,
        nodes: [{ text: "SAP Cloud for Real Estate", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud for Travel and Expense",
        isSelected: false,
        nodes: [{ text: "SAP Cloud for Travel and Expense", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Dynamic Authorization Management by NextLabs",
        isSelected: false,
        nodes: [
          {
            text: "SAP Dynamic Authorization Management by NextLabs",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Electronic Invoicing for Brazil",
        isSelected: false,
        nodes: [
          { text: "SAP Electronic Invoicing for Brazil", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Financial Consolidation",
        isSelected: false,
        nodes: [
          {
            text: "SAP BusinessObjects Financial Consolidation",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Financial Information Management",
        isSelected: false,
        nodes: [
          { text: "SAP Financial Information Management", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Intercompany Financial Hub by BlackLine",
        isSelected: false,
        nodes: [
          {
            text: "SAP Intercompany Financial Hub by BlackLine",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Invoice Processing",
        isSelected: false,
        nodes: [{ text: "SAP Concur Invoice", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Lease Administration by Nakisa",
        isSelected: false,
        nodes: [
          { text: "SAP Lease Administration by Nakisa", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Notes Management",
        isSelected: false,
        nodes: [{ text: "SAP Notes Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Process Control",
        isSelected: false,
        nodes: [{ text: "SAP Process Control", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Profitability and Performance Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Profitability and Performance Management",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Regulation Management by Greenlight",
        isSelected: false,
        nodes: [
          {
            text: "SAP Regulation Management by Greenlight, cyber governance edition",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Risk Management",
        isSelected: false,
        nodes: [
          { text: "SAP Risk Management", isSelected: true },
          { text: "SAP Risk Management for SAP S/4HANA", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Spend Performance Management",
        isSelected: false,
        nodes: [{ text: "SAP Spend Performance Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Strategy Management",
        isSelected: false,
        nodes: [{ text: "SAP Strategy Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Sustainability Performance Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Sustainability Performance Management",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Technical Data Export Compliance by NextLabs",
        isSelected: false,
        nodes: [
          {
            text: "SAP Technical Data Export Compliance by NextLabs",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Travel and Expense Management",
        isSelected: false,
        nodes: [
          { text: "SAP Concur Expense", isSelected: true },
          { text: "SAP Concur Travel", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Travel Receipts Management by OpenText",
        isSelected: false,
        nodes: [
          {
            text: "SAP Travel Receipts Management by OpenText",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Treasury and Risk Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Treasury and Risk Management, version for the United States",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Billing and Revenue Innovation Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Convergent Mediation by DigitalRoute",
            isSelected: true,
          },
          { text: "SAP CPQ", isSelected: true },
          { text: "SAP Subscription Billing", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Regulation Management by Pathlock",
        isSelected: false,
        nodes: [
          { text: "SAP Regulation Management by Pathlock", isSelected: true },
        ],
      },
    ],
  },

  {
    text: "Human Capital Management",
    nodes: [
      { text: "manager self-services add-on", isSelected: true },
      { text: "Qualtrics EmployeeXM for IT", isSelected: true },
      { text: "SAP Assessment Management by Questionmark", isSelected: true },
      { text: "SAP Employee File Management by OpenText", isSelected: true },
      { text: "SAP Enable Now", isSelected: true },
      { text: "SAP Fieldglass Time Entry", isSelected: true },
      { text: "SAP Knowledge Acceleration", isSelected: true },
      { text: "SAP Learning Hub", isSelected: true },
      { text: "SAP Organizational Visualization by Nakisa", isSelected: true },
      { text: "SAP Productivity Composer by ANCILE", isSelected: true },
      { text: "SAP Productivity Pak by ANCILE", isSelected: true },
      { text: "SAP Qualtrics 360 Feedback", isSelected: true },
      { text: "SAP Qualtrics Employee Benefits Optimizer", isSelected: true },
      { text: "SAP Qualtrics Employee Engagement", isSelected: true },
      { text: "SAP Qualtrics Employee Lifecycle", isSelected: true },
      { text: "SAP Sports One", isSelected: true },
      { text: "SAP SuccessFactors HCM Core", isSelected: true },
      { text: "SAP SuccessFactors HXM Suite", isSelected: true },
      { text: "SAP Talent Visualization by Nakisa", isSelected: true },
      {
        text: "SAP Time and Attendance Management by WorkForce Software",
        isSelected: true,
      },
      { text: "SAP Time Management by Kronos", isSelected: true },
      {
        text: "SAP U.S. Benefits Management by Benefitfocus",
        isSelected: true,
      },
      { text: "SAP Workforce Management", isSelected: true },
      {
        text: "SAP Workforce Scheduling and Optimization by ClickSoftware",
        isSelected: true,
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "manager self-services add-on",
        isSelected: false,
        nodes: [{ text: "manager self-services add-on", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Absence and Leave Management by WorkForce Software",
        isSelected: false,
        nodes: [
          {
            text: "SAP Absence and Leave Management by WorkForce Software",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Employee File Management by OpenText",
        isSelected: false,
        nodes: [
          {
            text: "SAP Employee File Management by OpenText",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Organizational Visualization by Nakisa",
        isSelected: false,
        nodes: [
          {
            text: "SAP Organizational Visualization by Nakisa",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Productivity Composer by ANCILE",
        isSelected: false,
        nodes: [
          { text: "SAP Productivity Composer by ANCILE", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Productivity Pak by ANCILE",
        isSelected: false,
        nodes: [{ text: "SAP Productivity Pak by ANCILE", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP SuccessFactors HXM Suite",
        isSelected: false,
        nodes: [
          { text: "SAP SuccessFactors Compensation", isSelected: true },
          {
            text: "SAP SuccessFactors Document Management Core by Open Text",
            isSelected: true,
          },
          { text: "SAP SuccessFactors Employee Central", isSelected: true },
          {
            text: "SAP SuccessFactors Employee Central Payroll",
            isSelected: true,
          },
          {
            text: "SAP SuccessFactors Employee Central Service Center",
            isSelected: true,
          },
          {
            text: "SAP SuccessFactors Extended Enterprise Content Management by OpenText",
            isSelected: true,
          },
          { text: "SAP SuccessFactors HXM Core", isSelected: true },
          { text: "SAP SuccessFactors HXM Suite", isSelected: true },
          { text: "SAP SuccessFactors Learning", isSelected: true },
          { text: "SAP SuccessFactors Learning Marketplace", isSelected: true },
          { text: "SAP SuccessFactors Mobile", isSelected: true },
          { text: "SAP SuccessFactors Onboarding", isSelected: true },
          {
            text: "SAP SuccessFactors Opportunity Marketplace",
            isSelected: true,
          },
          { text: "SAP SuccessFactors People Analytics", isSelected: true },
          { text: "SAP SuccessFactors People Central Hub", isSelected: true },
          { text: "SAP SuccessFactors Performance & Goals", isSelected: true },
          { text: "SAP SuccessFactors platform", isSelected: true },
          { text: "SAP SuccessFactors Recruiting", isSelected: true },
          { text: "SAP SuccessFactors Recruiting Posting", isSelected: true },
          {
            text: "SAP SuccessFactors Succession & Development",
            isSelected: true,
          },
          { text: "SAP SuccessFactors Time Tracking", isSelected: true },
          {
            text: "SAP SuccessFactors Visa and Permits Management",
            isSelected: true,
          },
          { text: "SAP SuccessFactors Work-Life", isSelected: true },
          { text: "SAP SuccessFactors Workforce Analytics", isSelected: true },
          { text: "SAP SuccessFactors Workforce Planning", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Talent Visualization by Nakisa",
        isSelected: false,
        nodes: [
          { text: "SAP Talent Visualization by Nakisa", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Time Management by Kronos",
        isSelected: false,
        nodes: [{ text: "SAP Time Management by Kronos", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Workforce Management",
        isSelected: false,
        nodes: [{ text: "SAP Workforce Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Workforce Scheduling and Optimization by ClickSoftware",
        isSelected: false,
        nodes: [
          {
            text: "SAP Workforce Scheduling and Optimization by ClickSoftware",
            isSelected: true,
          },
        ],
      },
    ],
  },

  {
    text: "Product Lifecycle Management",
    nodes: [
      { text: "Climate 21", isSelected: true },
      { text: "recycling administration", isSelected: true },
      { text: "SAP 3D Visual Enterprise", isSelected: true },
      { text: "SAP Apparel and Footwear", isSelected: true },
      { text: "SAP Auto-ID Infrastructure", isSelected: true },
      { text: "SAP Commercial Project Management", isSelected: true },
      { text: "SAP Complex Assembly Manufacturing", isSelected: true },
      { text: "SAP Dairy Management by msg", isSelected: true },
      { text: "SAP Easy Document Management", isSelected: true },
      { text: "SAP EHS Regulatory Content", isSelected: true },
      { text: "SAP Engineering Control Center", isSelected: true },
      { text: "SAP Enterprise Product Development", isSelected: true },
      { text: "SAP Enterprise Product Engineering", isSelected: true },
      {
        text: "SAP Environment, Health, and Safety Management",
        isSelected: true,
      },
      { text: "SAP Environmental Compliance", isSelected: true },
      { text: "SAP Fashion Management", isSelected: true },
      { text: "SAP Innovation Management", isSelected: true },
      { text: "SAP Integrated Exploration and Production", isSelected: true },
      { text: "SAP Intelligent Product Design", isSelected: true },
      { text: "SAP Intercompany Data Exchange", isSelected: true },
      { text: "SAP Loss Prevention by Fujitsu", isSelected: true },
      { text: "SAP Management of Change", isSelected: true },
      { text: "SAP Meat Management by msg", isSelected: true },
      { text: "SAP Multiresource Scheduling", isSelected: true },
      { text: "SAP PLM interface", isSelected: true },
      { text: "SAP Portfolio and Project Management", isSelected: true },
      { text: "SAP Process Mining by Celonis", isSelected: true },
      { text: "SAP Product Definition", isSelected: true },
      { text: "SAP Product Lifecycle Costing", isSelected: true },
      { text: "SAP Product Lifecycle Management", isSelected: true },
      { text: "SAP Product Stewardship Network", isSelected: true },
      { text: "SAP Project Intelligence Network", isSelected: true },
      { text: "SAP REACH Compliance", isSelected: true },
      { text: "SAP Recipe Development", isSelected: true },
      { text: "SAP Visual Information for Plants by NRX", isSelected: true },
      {
        text: "SAP Waste and Recycling solutions by PROLOGA",
        isSelected: true,
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Circular Economy",
        isSelected: false,
        nodes: [
          { text: "SAP Responsible Design and Production", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Climate 21",
        isSelected: false,
        nodes: [{ text: "SAP Product Footprint Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "recycling administration",
        isSelected: false,
        nodes: [{ text: "recycling administration", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Apparel and Footwear",
        isSelected: false,
        nodes: [{ text: "SAP Apparel and Footwear", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Auto-ID Infrastructure",
        isSelected: false,
        nodes: [{ text: "SAP Auto-ID Infrastructure", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Commercial Project Management",
        isSelected: false,
        nodes: [
          { text: "SAP Commercial Project Management", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Easy Document Management",
        isSelected: false,
        nodes: [{ text: "SAP Easy Document Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP EHS Regulatory Content",
        isSelected: false,
        nodes: [
          {
            text: "SAP EHS Regulatory Content dangerous goods package",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Engineering Control Center",
        isSelected: false,
        nodes: [{ text: "SAP Engineering Control Center", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Environment, Health, and Safety Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Environment, Health, and Safety Management",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Environmental Compliance",
        isSelected: false,
        nodes: [{ text: "SAP Environmental Compliance", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Fashion Management",
        isSelected: false,
        nodes: [{ text: "SAP Fashion Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Innovation Management",
        isSelected: false,
        nodes: [{ text: "SAP Innovation Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Integrated Exploration and Production",
        isSelected: false,
        nodes: [
          {
            text: "SAP Integrated Exploration and Production",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Intelligent Product Design",
        isSelected: false,
        nodes: [{ text: "SAP Intelligent Product Design", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Loss Prevention by Fujitsu",
        isSelected: false,
        nodes: [{ text: "SAP Loss Prevention by Fujitsu", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Management of Change",
        isSelected: false,
        nodes: [{ text: "SAP Management of Change", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Meat and Fish Management by msg",
        isSelected: false,
        nodes: [
          { text: "SAP Meat and Fish Management by msg", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Multiresource Scheduling",
        isSelected: false,
        nodes: [{ text: "SAP Multiresource Scheduling", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP REACH Compliance",
        isSelected: false,
        nodes: [{ text: "SAP REACH Compliance", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Recipe Development",
        isSelected: false,
        nodes: [{ text: "SAP Recipe Development", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Visual Information for Plants by NRX",
        isSelected: false,
        nodes: [
          {
            text: "SAP Visual Information for Plants by NRX",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Waste and Recycling solutions by PROLOGA",
        isSelected: false,
        nodes: [
          {
            text: "SAP waste and recycling solutions by PROLOGA",
            isSelected: true,
          },
        ],
      },
    ],
  },

  {
    text: "Supplier Relationship Management",
    nodes: [
      { text: "Ariba Cloud Integration", isSelected: true },
      { text: "SAP Business Network", isSelected: true },
      { text: "SAP Ariba Buying", isSelected: true },
      { text: "SAP Ariba Buying and Invoicing", isSelected: true },
      { text: "SAP Ariba Catalog", isSelected: true },
      { text: "SAP Ariba Cloud Integration Gateway", isSelected: true },
      { text: "SAP Ariba Commerce Automation", isSelected: true },
      { text: "SAP Ariba Contracts", isSelected: true },
      { text: "SAP Ariba Discount Management", isSelected: true },
      { text: "SAP Ariba Discovery", isSelected: true },
      { text: "SAP Ariba Invoice Management", isSelected: true },
      { text: "SAP Ariba Payables", isSelected: true },
      { text: "SAP Ariba Procurement", isSelected: true },
      { text: "SAP Ariba Services", isSelected: true },
      { text: "SAP Ariba Snap", isSelected: true },
      { text: "SAP Ariba Sourcing", isSelected: true },
      { text: "SAP Ariba Spend Analysis", isSelected: true },
      { text: "SAP Ariba Spot Buy Catalog", isSelected: true },
      { text: "SAP Ariba Start Sourcing", isSelected: true },
      {
        text: "SAP Ariba Supplier Information and Performance Management",
        isSelected: true,
      },
      {
        text: "SAP Ariba Supplier Lifecycle and Performance",
        isSelected: true,
      },
      { text: "SAP Ariba Supplier Risk", isSelected: true },
      { text: "SAP Ariba Supply Chain", isSelected: true },
      {
        text: "SAP Ariba Supply Chain Collaboration for Buyers",
        isSelected: true,
      },
      { text: "SAP Contract Invoicing", isSelected: true },
      { text: "SAP Distributed Manufacturing", isSelected: true },
      {
        text: "SAP Fieldglass Contingent Workforce Management",
        isSelected: true,
      },
      { text: "SAP Fieldglass Services Procurement", isSelected: true },
      { text: "SAP Fieldglass Vendor Management System", isSelected: true },
      { text: "SAP Fieldglass Assignment Management", isSelected: true },
      { text: "SAP Fieldglass Worker Profile Management", isSelected: true },
      { text: "SAP Rural Sourcing Management", isSelected: true },
      {
        text: "SAP Sourcing and Contract Lifecycle Management",
        isSelected: true,
      },
      { text: "SAP Strategic Sourcing Suite", isSelected: true },
      { text: "SAP Supplier InfoNet", isSelected: true },
      { text: "SAP Supplier Lifecycle Management", isSelected: true },
      { text: "SAP Supplier Relationship Management", isSelected: true },
      { text: "SAP Supply Base Optimization", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Distributed Manufacturing",
        isSelected: false,
        nodes: [{ text: "SAP Distributed Manufacturing", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Fieldglass Vendor Management System",
        isSelected: false,
        nodes: [
          { text: "SAP Fieldglass Vendor Management System", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Rural Sourcing Management",
        isSelected: false,
        nodes: [{ text: "SAP Rural Sourcing Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Sourcing and Contract Lifecycle Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Sourcing and Contract Lifecycle Management",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supplier InfoNet",
        isSelected: false,
        nodes: [{ text: "SAP Supplier InfoNet", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supplier Lifecycle Management",
        isSelected: false,
        nodes: [
          { text: "SAP Supplier Lifecycle Management", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supplier Relationship Management",
        isSelected: false,
        nodes: [
          { text: "SAP Supplier Relationship Management", isSelected: true },
          {
            text: "Supplier relationship management procurement for public sector",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supply Base Optimization",
        isSelected: false,
        nodes: [{ text: "SAP Supply Base Optimization", isSelected: true }],
      },
    ],
  },

  {
    text: "Supply Chain Management",
    nodes: [
      { text: "SAP Advanced Planning and Optimization", isSelected: true },
      { text: "SAP Business Network for Logistics", isSelected: true },
      {
        text: "SAP Advanced Track and Trace for Pharmaceuticals",
        isSelected: true,
      },
      { text: "SAP Demand Management", isSelected: true },
      { text: "SAP Digital Supply Chain", isSelected: true },
      {
        text: "SAP Enterprise Inventory and Service-Level Optimization",
        isSelected: true,
      },
      { text: "SAP Event Management", isSelected: true },
      { text: "SAP Excise Tax Management", isSelected: true },
      { text: "SAP Extended Warehouse Management", isSelected: true },
      { text: "SAP Farm Management by Vistex", isSelected: true },
      { text: "SAP Financial Supply Chain Management", isSelected: true },
      { text: "SAP Forecasting and Replenishment", isSelected: true },
      { text: "SAP Global Batch Traceability", isSelected: true },
      { text: "SAP Global Track and Trace", isSelected: true },
      {
        text: "SAP Grower Management for Perishables by Vistex",
        isSelected: true,
      },
      {
        text: "SAP Integrated Business Planning for Supply Chain",
        isSelected: true,
      },
      { text: "SAP Intelligent Asset Management", isSelected: true },
      { text: "SAP Inventory Manager", isSelected: true },
      { text: "SAP S/4HANA Supply Chain", isSelected: true },
      { text: "SAP Sales and Operations Planning", isSelected: true },
      { text: "SAP Supply Chain Info Center", isSelected: true },
      { text: "SAP Supply Chain Management", isSelected: true },
      {
        text: "SAP Supply Chain Response Management by icon-scm",
        isSelected: true,
      },
      { text: "SAP Supply Network Collaboration", isSelected: true },
      { text: "SAP Transportation Management", isSelected: true },
      { text: "SAP Yard Logistics", isSelected: true },
      { text: "service parts management", isSelected: true },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Advanced Planning and Optimization",
        isSelected: false,
        nodes: [
          { text: "SAP Advanced Planning and Optimization", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Advanced Track and Trace for Pharmaceuticals",
        isSelected: false,
        nodes: [
          {
            text: "SAP Advanced Track and Trace for Pharmaceuticals",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Connected Goods",
        isSelected: false,
        nodes: [{ text: "SAP Connected Goods", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Demand Management",
        isSelected: false,
        nodes: [{ text: "SAP Demand Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Digital Supply Chain",
        isSelected: false,
        nodes: [{ text: "SAP Internet of Things", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Enterprise Inventory and Service-Level Optimization",
        isSelected: false,
        nodes: [
          {
            text: "SAP Enterprise Inventory and Service-Level Optimization",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Event Management",
        isSelected: false,
        nodes: [{ text: "SAP Event Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Excise Tax Management",
        isSelected: false,
        nodes: [{ text: "SAP Excise Tax Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Extended Warehouse Management",
        isSelected: false,
        nodes: [
          { text: "SAP Extended Warehouse Management", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Global Track and Trace",
        isSelected: false,
        nodes: [{ text: "SAP Global Track and Trace", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Integrated Business Planning for Supply Chain",
        isSelected: false,
        nodes: [
          {
            text: "SAP Integrated Business Planning for Supply Chain",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Intelligent Asset Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Asset Strategy and Performance Management",
            isSelected: true,
          },
          {
            text: "SAP Mobile Asset Management for utilities",
            isSelected: true,
          },
          { text: "SAP Predictive Asset Insights", isSelected: true },
          { text: "SAP Predictive Engineering Insights", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Inventory Manager",
        isSelected: false,
        nodes: [{ text: "SAP Inventory Manager by Syclo", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Sales and Operations Planning",
        isSelected: false,
        nodes: [
          { text: "SAP Sales and Operations Planning", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supply Chain Info Center",
        isSelected: false,
        nodes: [{ text: "SAP Supply Chain Info Center", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supply Chain Management",
        isSelected: false,
        nodes: [{ text: "SAP Supply Chain Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supply Chain Response Management by icon-scm",
        isSelected: false,
        nodes: [
          {
            text: "SAP Supply Chain Response Management by icon-scm",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Supply Network Collaboration",
        isSelected: false,
        nodes: [{ text: "SAP Supply Network Collaboration", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Transportation Management",
        isSelected: false,
        nodes: [{ text: "SAP Transportation Management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Yard Logistics",
        isSelected: false,
        nodes: [{ text: "SAP Yard Logistics", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "service parts management",
        isSelected: false,
        nodes: [{ text: "service parts management", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Business Network for Logistics",
        isSelected: false,
        nodes: [
          { text: "SAP Business Network for Logistics", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Intelligent Asset Management",
        isSelected: false,
        nodes: [
          {
            text: "SAP Business Network for Asset Management",
            isSelected: true,
          },
        ],
      },
    ],
  },

  {
    text: "Technology Platform",
    nodes: [
      { text: "accelerated application delivery", isSelected: true },
      { text: "business process transformation", isSelected: true },
      { text: "Qualtrics BrandXM", isSelected: true },
      { text: "Qualtrics CoreXM", isSelected: true },
      { text: "Qualtrics CustomerXM", isSelected: true },
      { text: "Qualtrics Developer Platform", isSelected: true },
      { text: "Qualtrics EmployeeXM", isSelected: true },
      { text: "Qualtrics ProductXM", isSelected: true },
      { text: "Qualtrics Text iQ", isSelected: true },
      { text: "Qualtrics XM Platform", isSelected: true },
      { text: "SAP Advanced Data Migration by Syniti", isSelected: true },
      { text: "SAP Afaria", isSelected: true },
      { text: "SAP AI Business Services", isSelected: true },
      { text: "SAP AI Core", isSelected: true },
      { text: "SAP AI Launchpad", isSelected: true },
      { text: "SAP API Management", isSelected: true },
      { text: "SAP AppGyver", isSelected: true },
      { text: "SAP Application Interface Framework", isSelected: true },
      { text: "SAP Application Visualization by iRise", isSelected: true },
      { text: "SAP Build", isSelected: true },
      { text: "SAP Business Application Studio", isSelected: true },
      { text: "SAP Business Process Automation by Redwood", isSelected: true },
      { text: "SAP Business Technology Platform", isSelected: true },
      { text: "SAP Business Warehouse Accelerator", isSelected: true },
      { text: "SAP Cloud ALM", isSelected: true },
      { text: "SAP Cloud Appliance Library", isSelected: true },
      { text: "SAP Cloud Identity Access Governance", isSelected: true },
      { text: "SAP Composition Environment", isSelected: true },
      { text: "SAP Conversational AI", isSelected: true },
      { text: "SAP CoPilot", isSelected: true },
      { text: "SAP Data Warehouse Cloud", isSelected: true },
      { text: "SAP Edge Services", isSelected: true },
      {
        text: "SAP Enterprise Data Integrity Testing by Tricentis",
        isSelected: true,
      },
      { text: "SAP Enterprise Modeling by Software AG", isSelected: true },
      { text: "SAP Extended Diagnostics by CA", isSelected: true },
      { text: "SAP Extension Suite", isSelected: true },
      { text: "SAP Fiori", isSelected: true },
      { text: "SAP Fortify by Micro Focus", isSelected: true },
      { text: "SAP Geographical Enablement Framework", isSelected: true },
      { text: "SAP HANA", isSelected: true },
      { text: "SAP HANA Cloud Services", isSelected: true },
      { text: "SAP HANA Live", isSelected: true },
      { text: "SAP Identity Management", isSelected: true },
      { text: "SAP Information Lifecycle Management", isSelected: true },
      { text: "SAP Intelligent Robotic Process Automation", isSelected: true },
      { text: "SAP Internet of Things", isSelected: true },
      { text: "SAP IT Process Automation by Cisco", isSelected: true },
      { text: "SAP Landscape Management", isSelected: true },
      { text: "SAP Leonardo", isSelected: true },
      { text: "SAP LoadRunner by Micro Focus", isSelected: true },
      { text: "SAP Mobile App Protection by Mocana", isSelected: true },
      { text: "SAP Mobile Platform", isSelected: true },
      { text: "SAP Mobile Secure", isSelected: true },
      { text: "SAP NetWeaver", isSelected: true },
      { text: "SAP NetWeaver Mobile", isSelected: true },
      { text: "SAP Operational Process Intelligence", isSelected: true },
      { text: "SAP Process Orchestration", isSelected: true },
      { text: "SAP Quality Center by Micro Focus", isSelected: true },
      { text: "SAP Screen Personas", isSelected: true },
      { text: "SAP Service Virtualization by Micro Focus", isSelected: true },
      { text: "SAP Single Sign-On", isSelected: true },
      { text: "SAP Smart Business", isSelected: true },
      { text: "SAP SMS 365", isSelected: true },
      { text: "SAP Solution Manager", isSelected: true },
      { text: "SAP SuccessFactors Work Zone", isSelected: true },
      { text: "SAP Translation Hub", isSelected: true },
      { text: "SAP User Experience Management by Knoa", isSelected: true },
      { text: "SAP Visual Business", isSelected: true },
      { text: "SAP Web IDE", isSelected: true },
      { text: "SAPUI5", isSelected: true },
      {
        text: "Sybase Mobile Workflow for SAP Business Suite",
        isSelected: true,
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "ABAP platform",
        isSelected: false,
        nodes: [{ text: "ABAP platform", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Accelerated application delivery",
        isSelected: false,
        nodes: [
          {
            text: "Accelerated application delivery for SAP NetWeaver",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Afaria",
        isSelected: false,
        nodes: [{ text: "SAP Afaria", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP AI Business Services",
        isSelected: false,
        nodes: [
          { text: "Business Entity Recognition", isSelected: true },
          { text: "Data Attribute Recommendation", isSelected: true },
          { text: "Document Classification", isSelected: true },
          { text: "Document Information Extraction", isSelected: true },
          { text: "Invoice Object Recommendation", isSelected: true },
          { text: "Service Ticket Intelligence", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP API Management",
        isSelected: false,
        nodes: [
          { text: "SAP API Management, on-premise edition", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Application Interface Framework",
        isSelected: false,
        nodes: [
          { text: "SAP Application Interface Framework", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Application Visualization by iRise",
        isSelected: false,
        nodes: [
          { text: "SAP Application Visualization by iRise", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Business Process Automation by Redwood",
        isSelected: false,
        nodes: [
          {
            text: "SAP Business Process Automation by Redwood",
            isSelected: true,
          },
          {
            text: "SAP Central Process Scheduling by Redwood",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Business Technology Platform",
        isSelected: false,
        nodes: [
          { text: "API Management", isSelected: true },
          { text: "business rules", isSelected: true },
          { text: "Hyperledger Fabric on SAP BTP", isSelected: true },
          { text: "Identity Authentication", isSelected: true },
          { text: "Identity Provisioning", isSelected: true },
          { text: "MultiChain on SAP BTP", isSelected: true },
          { text: "Open Connectors", isSelected: true },
          { text: "process visibility", isSelected: true },
          {
            text: "SAP Alert Notification service for SAP BTP",
            isSelected: true,
          },
          { text: "SAP Automation Pilot", isSelected: true },
          { text: "SAP Big Data Services", isSelected: true },
          { text: "SAP BTP, ABAP environment", isSelected: true },
          { text: "SAP BTP, Kyma runtime", isSelected: true },
          { text: "SAP Business Technology Platform", isSelected: true },
          { text: "SAP Cloud Integration for data services", isSelected: true },
          { text: "SAP Cloud Portal service", isSelected: true },
          { text: "SAP Cloud Transport Management", isSelected: true },
          { text: "SAP Connectivity service", isSelected: true },
          { text: "SAP Credential Store", isSelected: true },
          { text: "SAP Document Management service", isSelected: true },
          { text: "SAP Enterprise Messaging", isSelected: true },
          { text: "SAP Extension Suite", isSelected: true },
          { text: "SAP Forms service by Adobe", isSelected: true },
          { text: "SAP HANA service for SAP BTP", isSelected: true },
          { text: "SAP Integration Suite", isSelected: true },
          { text: "SAP IoT services for SAP BTP", isSelected: true },
          { text: "SAP Launchpad service", isSelected: true },
          {
            text: "SAP Master Data service for business partners",
            isSelected: true,
          },
          { text: "SAP Mobile Services", isSelected: true },
          {
            text: "SAP Rapid Application Development by Mendix",
            isSelected: true,
          },
          { text: "SAP Web Analytics", isSelected: true },
          { text: "SAP Web Analytics (pilot)", isSelected: true },
          { text: "SAP Workflow Management", isSelected: true },
          { text: "workflow", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud Appliance Library",
        isSelected: false,
        nodes: [{ text: "SAP Cloud Appliance Library", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Cloud Identity Access Governance",
        isSelected: false,
        nodes: [
          { text: "SAP Cloud Identity Access Governance", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Conversational AI",
        isSelected: false,
        nodes: [{ text: "SAP Conversational AI", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP CoPilot",
        isSelected: false,
        nodes: [{ text: "SAP CoPilot", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Data Warehouse Cloud",
        isSelected: false,
        nodes: [{ text: "SAP Data Warehouse Cloud", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Edge Services",
        isSelected: false,
        nodes: [{ text: "SAP Edge Services", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Extended Diagnostics by CA",
        isSelected: false,
        nodes: [{ text: "SAP Extended Diagnostics by CA", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Fiori",
        isSelected: false,
        nodes: [{ text: "SAP Fiori Cloud", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Fortify by Micro Focus",
        isSelected: false,
        nodes: [{ text: "SAP Fortify by Micro Focus", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Geographical Enablement Framework",
        isSelected: false,
        nodes: [
          { text: "SAP Geographical Enablement Framework", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP HANA",
        isSelected: false,
        nodes: [
          { text: "SAP HANA accelerator for SAP ASE", isSelected: true },
          { text: "SAP HANA geospatial content", isSelected: true },
          { text: "SAP HANA IoT Connector by OSIsoft", isSelected: true },
          { text: "SAP HANA spatial services", isSelected: true },
          { text: "SAP HANA streaming analytics", isSelected: true },
          { text: "SAP HANA, express edition", isSelected: true },
          { text: "SAP HANA, platform edition", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP HANA Cloud Services",
        isSelected: false,
        nodes: [{ text: "SAP HANA Cloud", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP HANA Live",
        isSelected: false,
        nodes: [
          {
            text: "SAP HANA Live for SAP Business Suite foundation component",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Intelligent Robotic Process Automation",
        isSelected: false,
        nodes: [
          {
            text: "SAP Intelligent Robotic Process Automation",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP IT Process Automation by Cisco",
        isSelected: false,
        nodes: [
          { text: "SAP IT Process Automation by Cisco", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Leonardo",
        isSelected: false,
        nodes: [
          { text: "SAP Cash Application", isSelected: true },
          {
            text: "SAP Leonardo Machine Learning Foundation",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Mobile App Protection by Mocana",
        isSelected: false,
        nodes: [
          { text: "SAP Mobile App Protection by Mocana", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP NetWeaver",
        isSelected: false,
        nodes: [
          { text: "Duet Enterprise", isSelected: true },
          { text: "Gateway to SAP NetWeaver Mobile", isSelected: true },
          { text: "SAP Business Information Warehouse", isSelected: true },
          { text: "SAP Business Warehouse Accelerator", isSelected: true },
          { text: "SAP Composition Application Framework", isSelected: true },
          { text: "SAP Composition Environment", isSelected: true },
          {
            text: "SAP Enterprise Portal, enterprise workspaces",
            isSelected: true,
          },
          { text: "SAP NetWeaver", isSelected: true },
          {
            text: "SAP NetWeaver Adaptive Computing Controller",
            isSelected: true,
          },
          { text: "SAP NetWeaver Application Server", isSelected: true },
          { text: "SAP NetWeaver Master Data Management", isSelected: true },
          { text: "SAP Process Integration", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Operational Process Intelligence",
        isSelected: false,
        nodes: [
          { text: "SAP Operational Process Intelligence", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Quality Center by Micro Focus",
        isSelected: false,
        nodes: [
          {
            text: "SAP Quality Center by Micro Focus, enterprise edition",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Screen Personas",
        isSelected: false,
        nodes: [{ text: "SAP Screen Personas", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Service Virtualization by Micro Focus",
        isSelected: false,
        nodes: [
          {
            text: "SAP Service Virtualization by Micro Focus",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Single Sign-On",
        isSelected: false,
        nodes: [{ text: "SAP Single Sign-On", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Smart Business",
        isSelected: false,
        nodes: [
          {
            text: "SAP Smart Business for SAP Information Lifecycle Management",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP SuccessFactors Work Zone",
        isSelected: false,
        nodes: [
          { text: "SAP SuccessFactors Work Zone", isSelected: true },
          { text: "SAP Work Zone", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP Translation Hub",
        isSelected: false,
        nodes: [{ text: "SAP Translation Hub", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAP User Experience Management by Knoa",
        isSelected: false,
        nodes: [
          { text: "SAP User Experience Management by Knoa", isSelected: true },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "SAPUI5",
        isSelected: false,
        nodes: [{ text: "SAPUI5", isSelected: true }],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "Sybase Mobile Workflow for SAP Business Suite",
        isSelected: false,
        nodes: [
          {
            text: "Sybase Mobile Workflow for SAP Business Suite",
            isSelected: true,
          },
        ],
      },
    ],
  },
  {
    text: "",
    nodes: [
      {
        text: "business process transformation",
        isSelected: false,
        nodes: [
          {
            text: "SAP Process Collaboration Hub by Signavio",
            isSelected: true,
          },
          { text: "SAP Process Intelligence by Signavio", isSelected: true },
          { text: "SAP Process Manager by Signavio", isSelected: true },
          { text: "SAP Signavio Journey Modeler", isSelected: true },
          { text: "SAP Signavio Process Collaboration Hub", isSelected: true },
          { text: "SAP Signavio Process Governance", isSelected: true },
          { text: "SAP Signavio Process Insights", isSelected: true },
          { text: "SAP Signavio Process Intelligence", isSelected: true },
          { text: "SAP Signavio Process Manager", isSelected: true },
          { text: "SAP Workflow Accelerator by Signavio", isSelected: true },
        ],
      },
    ],
  },
];

// export const BusinessOutcomeData = [
//     {
//         value: "0",
//         label: "Improve IP inventory data"
//     },
//     {
//         value: "1",
//         label: "Improve P card rebates and float"
//     },
//     {
//         value: "2",
//         label: "Improve application deployment frequency"
//     },
//     {
//         value: "3",
//         label: "Improve control over program budgets"
//     },
//     {
//         value: "4",
//         label: "Improve customer profitability analysis"
//     },
//     {
//         value: "5",
//         label: "Improve discount achievement"
//     },
//     {
//         value: "6",
//         label: "Improve discount achievement for external workforce and services spend"
//     },
//     {
//         value: "7",
//         label: "Improve display advertising"
//     },
//     {
//         value: "8",
//         label: "Improve environmental safety and compliance"
//     },
//     {
//         value: "9",
//         label: "Improve incremental discounts"
//     },
//     {
//         value: "10",
//         label: "Improve on-time delivery performance"
//     },
// ]
export const BusinessOutcomeData =
 [
  {
    text: "business outcome",
    nodes: [
      { text: "SAP Analytics Cloud" },
      { text: "SAP Analytics Hub" },
      { text: "SAP BusinessObjects Business Intelligence" },
      { text: "SAP BusinessObjects Dashboards" },
      { text: "SAP BusinessObjects Explorer" },
      { text: "SAP BusinessObjects Intercompany" },
      { text: "SAP BusinessObjects Web Intelligence" },
      { text: "SAP Crystal Reports" },
      { text: "SAP Crystal Server" },
      { text: "SAP Digital Boardroom" },
      { text: "SAP Global Trade Services" },
      { text: "SAP IT Operation Analytics" },
      { text: "SAP Lumira" },
      { text: "SAP Predictive Analytics" },
      { text: "SAP Roambi" },
      { text: "SAP Sailing Analytics" },
      { text: "SAP Watch List Screening" },
    ],
  },
];