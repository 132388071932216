import React, { createContext, useEffect, useState } from "react";
import {
  ApprovedDraftList,
  DeleteDraftFinalReview,
  searchToriTrainedData,
} from "../components/Services/Service";

const ApprovedContext = createContext();
export function ApprovedDraftContext({ children }) {
  const [draftsData, setdraftsData] = useState();
  const getApprovedTableData = async (pageNum, drafts, columnName, order) => {
    const response = await ApprovedDraftList(
      pageNum,
      drafts,
      columnName,
      order
    );
    setdraftsData(response);
    return response;
  };
  const searchApprovedTableData = async (
    pageNum,
    drafts,
    searchText,
    columnName,
    order
  ) => {
    const response = await searchToriTrainedData(
      pageNum,
      drafts,
      searchText,
      columnName,
      order
    );
    setdraftsData(response);
    return response;
  };
  const removeDraft = async (id) => {
    const res = await DeleteDraftFinalReview(id);
    if (res.status) {
      setdraftsData((prevState) => {
        const newData = prevState.data.filter((draft) => draft.id !== id);
        return {
          ...prevState,
          data: newData,
          total_drafts: prevState.total_drafts,
        };
      });
    }
    return res;
  };
  return (
    <ApprovedContext.Provider
      value={{
        getApprovedTableData,
        draftsData,
        removeDraft,
        searchApprovedTableData,
      }}
    >
      {children}
    </ApprovedContext.Provider>
  );
}

export default ApprovedContext;
