import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import "./ToriChat.css";
import ComparisonChatModal from "./ComparisonChatModal";
import { useState } from "react";
import { AdditionalInfoWithLinkOrQuery, ToriChatService } from "../../Service";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
// import LoadingSpinner from "../../assets/spinnerRedAdam.gif";
import DisplayModal from "../CreateDraft/Components/Modal";
import imageLogo from "../../assets/logo.png";
// import imageLogo from "../../assets/testLogonTori.png";
import plus from "../../assets/appPlus.png";
import Minus from "../../assets/minus.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "26px",
    width: "40%",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    overflow: "unset",
  },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AdditionalInfoModal = ({
  modalIsOpen,
  closeModal,
  disLikeQuestion,
  changeStateFromChildToParent,
}) => {
  console.log("🚀 ~ disLikeQuestion:", disLikeQuestion)
  const [modalIsOpenCompare, setIsOpenComapre] = useState(false);
  const [response, setResponse] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [hideTextArea, setHideTextArea] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [hideIncorrect, setHideIncorrect] = useState(false);
  const [baseUrlModal, setBaseUrlModal] = useState("");
  const [modal, setModal] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [listArray, setListArray] = useState([]);


  const [chatLinkArray, setChatLinkArray] = useState([
    { name: "" },
  ]);
  console.log("🚀 ~ file: ToriChat.js:647 ~ response:", listArray)

  function closeModalComapre() {
    setIsOpenComapre(false);
  }
  function openModalCompare() {
    setIsOpenComapre(true);
  }

  const handleAdditionalInfo = () => {

    if (!response) setErrorMessage(true);
    if (response && response.trim().length != 0 || listArray.length) {
      // if (modal === 'other') {
      //   closeModal();
      //   setErrorMessage(false);
      //   setIsOpenComapre(true);
      //   setChatLoading(false);
      //   setHideTextArea(false);
      //   setHideInput(false);
      //   setLinkError(false);
      // } else {
      changeStateFromChildToParent(disLikeQuestion, {}, response, modal, listArray);
      setErrorMessage(false);
      setIsOpenComapre(true);
      setChatLoading(false);
      setHideTextArea(false);
      setHideInput(false);
      setLinkError(false);
      setHideIncorrect(false)
      closeModal();
      setChatLinkArray([
        { name: "" },
      ])
      // }

      // setChatLoading(true);
      // AdditionalInfoWithLinkOrQuery(
      //   baseUrlModal,
      //   disLikeQuestion?.question,
      //   response,
      //   modal
      // )
      //   .then((res) => {
      //     setResponse("");
      //     if (modal === "link") {
      //       changeStateFromChildToParent(disLikeQuestion, res, response);
      //     } else {
      //       changeStateFromChildToParent(disLikeQuestion, res);
      //     }
      //     setIsOpenComapre(true);
      //     closeModal();

      //     setChatLoading(false);
      //     setHideTextArea(false);
      //     setHideInput(false);
      //     setLinkError(false);
      //   })
      //   .catch((err) => {
      //     setLinkError(true);
      //     setChatLoading(false);
      //     if (err.name === "AbortError") {
      //       setshowTimeOurError(true);
      //     }
      //   });
    }
  };

  const handleClose = () => {
    closeModal();
    setHideTextArea(false);
    setHideInput(false);
    setLinkError(false);
    setResponse("");
    setErrorMessage(false);
  };

  const OnChangeBusinessOutcomeArray = (e, name, index) => {
    const list = [...chatLinkArray];
    list[index][name] = e;

    const listArray = list.map((item) => item.name);

    setChatLinkArray(list);
    setListArray(listArray)
    // setBusinessOutcomesLength(listArray);
    // setBusinessOutcomesPrompt(listArray.join("\n"));
    // setBusinessOutcomesString(listArray.join(", "));
    // CallingSaveDraftAPI({
    //   business_outcomes_array: [...list],
    //   right_business_outcomes: listArray.join(", "),
    // });
  };

  // handle click event of the Remove button
  const HandleRemoveBO = (index) => {
    const list = [...chatLinkArray];
    list.splice(index, 1);
    const listArray = list.map((item) => item.name);
    setListArray(listArray);
    // setBusinessOutcomesPrompt(listArray.join("\n"));
    // setBusinessOutcomesString(listArray.join(","));
    setChatLinkArray(list);
    // CallingSaveDraftAPI({
    //   business_outcomes_array: [...list],
    //   right_business_outcomes: listArray.join(","),
    // });
  };

  // handle click event of the Add button
  const HandleAddBO = () => {
    setChatLinkArray([...chatLinkArray, { name: "" }]);
  };

  return (
    <div>
      {chatLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        // onRequestClose={() => {
        //   closeModal();
        //   setHideTextArea(false);
        //   setHideInput(false);
        // }}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="hide_scroll">
          <AiOutlineClose
            onClick={handleClose}
            style={{
              fontSize: 18,
              cursor: "pointer",
              position: "absolute",
              top: 25,
              right: 20,
            }}
          />

          <div
            style={{
              padding: "0px 30px",
            }}
          >
            <div style={{}}>
              <Stack
                direction="row"
                spacing={1}
                marginTop={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={imageLogo}
                  alt="imageLogo"
                  style={{ width: "40px", height: "40px" }}
                />

                <Typography
                  variant="h5"
                  fontWeight={600}
                  fontSize={18}
                  textAlign={"center"}
                  marginTop={1}
                >
                  Additional Information
                </Typography>
              </Stack>
            </div>

            <FormControl fullWidth>
              <RadioGroup
                // defaultValue={"link"}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {/*<FormControlLabel
                  value="large"
                  onChange={() => {
                    setResponse(
                      "please give me a shorter answer to the last query."
                    );
                    setHideTextArea(false);
                  }}
                  style={{ marginBottom: "-15px" }}
                  control={<Radio />}
                  // label="Access to Tori Chat"
                  label={
                    <span style={{ fontSize: "12px" }}>
                      This response is too long.
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />
                <FormControlLabel
                  value="small"
                  onChange={() => {
                    setResponse(
                      "please give me a longer answer to the last query."
                    );
                    setHideTextArea(false);
                  }}
                  style={{ marginBottom: "-15px" }}
                  control={<Radio />}
                  // label="Access to Script factory"
                  label={
                    <span style={{ fontSize: "12px" }}>
                      This response is too short.
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                />*/}

                <FormControlLabel
                  value="link"
                  onChange={() => {
                    setHideTextArea(false);
                    setHideInput(true);
                    setHideIncorrect(false)
                    setBaseUrlModal(
                      "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/url"
                    );
                    setModal("link");
                    setErrorMessage(false);
                  }}
                  style={{ marginBottom: "-15px" }}
                  control={<Radio />}
                  // label="Access to Script factory"
                  label={
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Link is wrong
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 19,
                    },
                  }}
                />



                {hideInput && (
                  // <div style={{ marginTop: "10px" }}>
                  //   <input
                  //     type="text"
                  //     placeholder="Please provide correct link"
                  //     className="InputFields"
                  //     onChange={(e) => setResponse(e.target.value)}
                  //   />
                  //   {linkError && (
                  //     <div style={{ color: "red", marginTop: "5px" }}>
                  //       Invalid Link
                  //     </div>
                  //   )}
                  //   {errorMessage && (
                  //     <div style={{ color: "red", marginTop: "5px" }}>
                  //       Link is Required
                  //     </div>
                  //   )}
                  // </div>


                  <Stack spacing={1}>
                    {chatLinkArray &&
                      chatLinkArray?.map((x, i) => {
                        return (
                          <Stack
                            width="100%"
                            direction="row"
                            justifyContent={"space-between"}
                            mt={2}
                            key={i}
                          >
                            <div style={{ width: "85%" }}>
                              <input
                                type="text"
                                value={x.name}
                                placeholder="Please provide correct link"
                                className="InputFields"
                                // onChange={(e) => setResponse(e.target.value)}
                                onChange={(e) => OnChangeBusinessOutcomeArray(e.target.value, "name", i)}
                              />
                            </div>
                            {chatLinkArray?.length !== 1 && (
                              <Stack
                                style={{
                                  alignSelf: "center",
                                }}
                              >
                                <img
                                  onClick={() => HandleRemoveBO(i)}
                                  src={Minus}
                                  style={{
                                    cursor: "pointer",
                                    width: 24,
                                    height: 24,
                                  }}
                                />
                              </Stack>
                            )}
                            {chatLinkArray?.length - 1 === i &&
                              chatLinkArray?.length < 3 && (
                                <Stack
                                  style={{
                                    alignSelf: "center",
                                  }}
                                >
                                  <img
                                    onClick={HandleAddBO}
                                    src={plus}
                                    style={{
                                      cursor: "pointer",
                                      width: 24,
                                      height: 24,
                                    }}
                                  />
                                </Stack>
                              )}
                          </Stack>
                        );
                      })}
                  </Stack>

                )}

                <FormControlLabel
                  value="text"
                  onChange={() => {
                    setHideTextArea(false);
                    setHideInput(false);
                    setHideIncorrect(true)
                    setBaseUrlModal(
                      "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/url"
                    );
                    setModal("text");
                    setErrorMessage(false);
                  }}
                  style={{ marginBottom: "-15px" }}
                  control={<Radio />}
                  // label="Access to Script factory"
                  label={
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Poorly/Incorrectly Written
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 19,
                    },
                  }}
                />



                {hideIncorrect && (
                  // <div style={{ marginTop: "10px" }}>
                  //   <input
                  //     type="text"
                  //     placeholder="Please provide your suggested answer"
                  //     className="InputFields"
                  //     onChange={(e) => setResponse(e.target.value)}
                  //   />
                  //   {linkError && (
                  //     <div style={{ color: "red", marginTop: "5px" }}>
                  //       Invalid Link
                  //     </div>
                  //   )}
                  //   {errorMessage && (
                  //     <div style={{ color: "red", marginTop: "5px" }}>
                  //       Link is Required
                  //     </div>
                  //   )}
                  // </div>

                  <div style={{ marginTop: "10px", marginBottom: "-10px" }}>
                    <textarea
                      row="10"
                      //   onBlur={() => CallingSaveDraftAPI({})}
                      //   value={summary}
                      //   disabled={disableDemo1Fields}
                      onChange={(e) => setResponse(e.target.value)}
                      className={"InputFields"}
                      placeholder="Please provide your suggested answer"
                      type="text"
                    />
                    {errorMessage && (
                      <div style={{ color: "red", marginTop: "5px" }}>
                        Text is Required
                      </div>
                    )}
                  </div>
                )}




                <FormControlLabel
                  value="other"
                  onChange={() => {
                    setHideTextArea(true);
                    setHideIncorrect(false)
                    setResponse();
                    setHideInput(false);
                    setBaseUrlModal(
                      "https://ires2y7ps0.execute-api.us-west-2.amazonaws.com/query/keypoint"
                    );
                    setModal("other");
                    setLinkError(false);
                    setErrorMessage(false);
                  }}
                  style={{ marginBottom: "-15px" }}
                  control={<Radio />}
                  // label="Access to Script factory"
                  label={
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Other
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 19,
                    },
                  }}
                />
                {hideTextArea && (
                  <div style={{ marginTop: "10px" }}>
                    <textarea
                      row="10"
                      //   onBlur={() => CallingSaveDraftAPI({})}
                      //   value={summary}
                      //   disabled={disableDemo1Fields}
                      onChange={(e) => setResponse(e.target.value)}
                      className={"InputFields"}
                      placeholder="What was the issue with the response ? How could it be improved?"
                      type="text"
                    />
                    {errorMessage && (
                      <div style={{ color: "red", marginTop: "5px" }}>
                        Text is Required
                      </div>
                    )}
                  </div>
                )}
              </RadioGroup>
            </FormControl>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={handleAdditionalInfo}
                // disabled={response === "" ? true : false}
                style={{
                  color: "white",
                  backgroundColor: "#1A7F64",
                  marginTop: "30px",
                  padding: "7px 9px",
                  borderRadius: "5px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
          // setShowFirstInput(true);
          // history.push("/user/tori-chat/demostore", { from: "demostore" });
          // setAskQuery("");
        }}
        modalMsg={"Request Timeout Try Again."}
      />
    </div>
  );
};

export default AdditionalInfoModal;
