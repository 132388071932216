import React, { useEffect, useState } from "react";
import { Modal, Radio, Space } from "antd";
import { Stack, Typography, Divider } from "@mui/material";
import surveyLogo from "../../assets/surveyLogo.png";
import "./SurveyModal.css";
export default function CreateDraftSurvey({
  showCreateDraftSurvey,
  handleCancel,
  startDraft,
  onChangeVideoTutorial,
  videoTutorial,
  onChangeInputFields,
  inputField,
  draftType,
  spotlightLoader,
  processLoader,
  loader
}) {
  return (
    <div className="modal_approval">
      <Modal
        centered
        open={showCreateDraftSurvey}
        width={800}
        zIndex={999}
        footer={[]}
        onCancel={handleCancel}
        bodyStyle={{ padding: 24, borderRadius: "6px" }}
      >
        <Stack>
          <Typography className="modal-heading">Create Draft</Typography>
          <Stack paddingLeft={'15px'} alignItems={"center"} direction="row" spacing={8}>
            <Typography className="description">
              <span>
                Looks like you're all dressed up and ready for our first date! I
                mean, first draft.
              </span>{" "}
              But don't worry, no pressure. I just want to make sure we have a
              good time and that you impress me. Just like a date, but with less
              small talk and more sentence structure.
            </Typography>
            <img className="logo-img" src={surveyLogo} />
          </Stack>
          <Typography className="getting-started">
            Please review the below before getting started:
          </Typography>
          <Stack paddingLeft={'15px'} marginBlock={"5px"} spacing={0.4}>
            <Typography className="question">
              Did you watch the Video Tutorial?
            </Typography>
            <Typography className="watch-video">
              It only take 5 minutes! But it’s important ...{" "}
              <span>
                <a
                  href="https://play.goconsensus.com/bcbdb960a"
                  target="_blank"
                >
                  watch it here
                </a>
              </span>
            </Typography>
          </Stack>
          <Radio.Group
            className="radiogroup"
            size="large"
            onChange={onChangeVideoTutorial}
            value={videoTutorial}
          >
            <Space direction="vertical">
              <Radio
                className="radiobutton-text"
                value={"Yes, I'll follow the rules, like a boss"}
              >
                “Yes, I'll follow the rules, like a boss”
              </Radio>
              <Radio
                className="radiobutton-text"
                value={"No, I'm a wild card, can't be tamed"}
              >
                “No, I'm a wild card, can't be tamed”
              </Radio>
              <Radio
                className="radiobutton-text"
                value={"I'll take my chances"}
              >
                “I'll take my chances”
              </Radio>
            </Space>
          </Radio.Group>
          <Stack paddingLeft={'15px'} marginTop={"18px"}>
            <Typography className="question">
              All input fields for generating a script are crucial...
            </Typography>
          </Stack>
          <Radio.Group
            className="radiogroup"
            size="large"
            onChange={onChangeInputFields}
            value={inputField}
          >
            <Space direction="vertical">
              <Radio
                className="radiobutton-text"
                value={
                  "Absolutely, I treat every input field like a firstborn child - with love and care."
                }
              >
                “Absolutely, I treat every input field like a firstborn child -
                with love and care.”
              </Radio>
              <Radio
                className="radiobutton-text"
                value={"Input fields? Meh, they're optional"}
              >
                “Input fields? Meh, they're optional”
              </Radio>
            </Space>
          </Radio.Group>
          <Divider
            sx={{ bgcolor: "secondary.light" }}
            style={{ marginBlock: 14 }}
          />
          <Stack
          paddingLeft={'15px'}
            alignItems="center"
            direction="row"
            justifyContent={"space-between"}
          >
            <Typography className="footer-text">
              *ACCURATE COMPLETION{" "}
              <span>
                of the above fields is required to proceed with draft creation.
              </span>
            </Typography>
            <Stack direction="row" spacing={2}>
              <button onClick={handleCancel} className="cancel-button">
                Cancel
              </button>
              <button
                onClick={()=>startDraft(true,draftType)}
                className={`primatybutton w-inline-block ${
                  !videoTutorial || !inputField? "disable_btn" : ""
                }`}
                disabled={loader || (!videoTutorial || !inputField)}
              >
                {loader? (
                  <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>Start Draft</div>
                )}
              </button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
