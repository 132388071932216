import React, { useState, useEffect } from "react";
import "../NewPassword/NewPassword.css";
import { SetPassword, CheckLink } from "../../Services/Service";
import { useHistory } from "react-router-dom";
import Footer from "../../SubComponents/Footer/Footer";
// import ToriLogo from
import LoadingSpinner from "../../assets/spinnerAdam.gif";

import Modal from "react-modal";

import ToriLogo from "../../assets/tori_logo.svg";

import home_page_img from "../../assets/home_page_img.svg";
import "../../SubComponents/SideBar/Style.css";
import homeBg from "../../assets/home_bg.png";
import arrow from "../../assets/arrow.svg";
import logo from "../../assets/torisidebar_logo.svg";
import View from "../../assets/view.png";
import Hide from "../../assets/hide.png";

import Header from "../Header/Header";
import DisplayModal from "../CreateDraft/Components/Modal";

function NewPassword(props) {
  let history = useHistory();
  const [LinkData, setLinkData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    CheckLink().then((res) => {
      if (res.status) {
        setLoading(false);
        setLinkData(res);
      } else {
        setLoading(false);
        setLinkData(res);
      }
    });
  }, []);

  const [password, setpassword] = useState();
  const [confirmPassword, setconfirmPassword] = useState();
  const [loader, setloader] = useState(false);
  const [modalState, setmodalState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassowrd] = useState(false);
  const [ErrorMesg, setErrorMesg] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      overflow: "inherit",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "30%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  };

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  localStorage.setItem("token2", token);

  const onChangePass = (e) => {
    setpassword(e.target.value);
  };
  const onChangeCPass = (e) => {
    setconfirmPassword(e.target.value);
  };

  const OnsubmitClick = () => {
    setloader(true);
    SetPassword(password, confirmPassword)
      .then((res) => {
        if (res.status) {
          setloader(false);
          history.push("/");
        } else {
          console.log("dds");
          setsendReqMsg(res.message);
          setisSendReqMsg(true);
          //   setErrorMesg(res.message);
          setloader(false);
          //   setmodalState(true);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setloader(false);
      });
  };
  const OnGoToClick = () => {
    history.push("/user/home");
  };

  return (
    <div>
      <div>
        <div className="container-fluid page-body-wrapper pad-0 mrg-0">
          <div className="row">
            <div
              style={{ padding: "10px" }}
              className="col-lg-2 col-md-12 col-sm-12 pr-0 "
            >
              <a id="menu-toggle" href="#">
                <img src={logo} />
                <span
                  style={{ display: "none" }}
                  id="main_icon"
                  class="glyphicon glyphicon-align-justify"
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "82vh",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            padding: "0 20px",
          }}
        >
          <span
            style={{
              fontSize: 34,
              color: "#000",
              lineHeight: "32px",
            }}
          >
            Welcome to Tori
          </span>
          <div className="mt-5">
            <div className="form-card card">
              {loading == true ? (
                <div className="text-center">
                  <img width={150} height={150} src={LoadingSpinner} />
                </div>
              ) : (
                <div>
                  {LinkData && LinkData.message == "Link Expired" ? (
                    <div>
                      <div
                        class="modal-header"
                        style={{
                          borderBottom: 0,
                          padding: 40,
                          paddingBottom: 10,
                          paddingTop: 15,
                        }}
                      >
                        <h5
                          class="modal-title"
                          id="staticBackdropLabel"
                          style={{
                            position: "absolute",
                            left: 25,
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          Tori says
                        </h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <img width={30} height={30} src={ToriLogo} />
                        <h5 style={{ marginLeft: "20px" }}>
                          Link is expired please send access request again
                        </h5>
                      </div>

                      <div
                        className="pt-3"
                        style={{ float: "right", display: "flex" }}
                      >
                        <button
                          onClick={() => {
                            setmodalState(false);
                            history.push("/");
                          }}
                          style={{
                            height: "34px",
                            fontWeight: "bold",
                          }}
                          className="btn btn-primary"
                        >
                          Go To Login
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h2 className="text-center mb-5">New Password</h2>
                      <div className="input_single">
                        <input
                          onChange={onChangePass}
                          className="mb-4"
                          type={showPassword ? "text" : "password"}
                          placeholder="New Password"
                        />
                        <div>
                          <img
                            onClick={() => setShowPassword(!showPassword)}
                            className="view_set"
                            width={"20vw"}
                            src={showPassword ? Hide : View}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <div className="input_single">
                          <input
                            onChange={onChangeCPass}
                            className="mb-4"
                            type={showConfirmPassowrd ? "text" : "password"}
                            placeholder="Re Enter Passowrd"
                          />
                          <div>
                            <img
                              onClick={() =>
                                setShowConfirmPassowrd(!showConfirmPassowrd)
                              }
                              className="view_set"
                              width={"20vw"}
                              src={showConfirmPassowrd ? Hide : View}
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        onClick={OnsubmitClick}
                        className="btn btn-primary"
                        style={{
                          width: "100%",
                          color: "white",
                          backgroundColor: "#0A6ED1",
                          padding: 10,
                        }}
                      >
                        {loader ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div>Submit</div>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="pr-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "24%",
                  position: "absolute",
                  top: 170,
                }}
                src={home_page_img}
              />
              <img style={{ width: "60%" }} src={homeBg} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modalState}
          onRequestClose={() => {
            setmodalState(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{ErrorMesg && ErrorMesg}</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setmodalState(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
        <DisplayModal
          open={isSendReqMsg}
          onClose={() => {
            setisSendReqMsg(false);
          }}
          modalMsg={sendReqMsg}
        />
      </div>
      <Footer />
    </div>
  );
}
export default NewPassword;
