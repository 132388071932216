// import React, { useEffect, useState } from 'react';
import Header from "../../SubComponents/MainHeader/MainHeader";
// import { MDBDataTable } from 'mdbreact';
import { GetUserData } from "../../../components/Services/Service";
// import $ from 'jquery'
import "../DraftTable/Style.css";
import React, { useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import Sort from "../../assets/icons-sorting.png";
import SortUp from "../../assets/icon_up.png";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import { FiSearch } from "react-icons/fi";
import ApprovedActionsDropdown from "./ApprovedActionsDropdown";
import { Input, Select } from "antd";
import DisplayModal from "../CreateDraft/Components/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Pagination, Tooltip, Typography } from "@mui/material";
import { useContext } from "react";
import ApprovedContext from "../../../context/ApprovedTableContext";
function Approved(props) {
  const { getApprovedTableData, draftsData, searchApprovedTableData } =
    useContext(ApprovedContext);
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      neutral: {
        main: "#EAF1FF",
        contrastText: "#0A6ED1",
      },
    },
  });
  let history = useHistory();
  const [dataa, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [paginationRows, setPaginationRows] = useState(10);
  const [page, setPage] = useState(1);
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [columnName, setColumnName] = useState();
  const [order, setOrder] = useState();
  const [isUp, setIsup] = useState(false);
  const [searchText, setsearchText] = useState();
  const [showStateIcon, setShowStateIcon] = useState(false);
  const [showOwnerIcon, setShowOwnerIcon] = useState(false);
  const [showVideoNameIcon, setShowVideoNameIcon] = useState(false);
  const [showHubleadIcon, setShowHubleadIcon] = useState(false);
  const [showTypeIcon, setShowTypeIcon] = useState(false);
  const [showCraetedAtIcon, setShowCraetedAtIcon] = useState(false);
  const [showLastEditedIcon, setShowLastEditedIcon] = useState(false);
  const [stateIcon, setstateIcon] = useState(Sort);
  const [ownerIcon, setownerIcon] = useState(Sort);
  const [videoNameIcon, setvideoNameIcon] = useState(Sort);
  const [HubleadIcon, setHubleadIcon] = useState(Sort);
  const [typeIcon, settypeIcon] = useState(Sort);
  const [createdAtIcon, setcreatedAtIcon] = useState(Sort);
  const [lastEditedIcon, setlastEditedIcon] = useState(Sort);
  const rowId = useRef();
  const totalDrafts = useRef();
  const ActionClick = (id) => {
    rowId.current = id;
  };
  const searchData = (text) => {
    setOrder();
    setColumnName();
    setShowStateIcon(false);
    setShowOwnerIcon(false);
    setShowVideoNameIcon(false);
    setShowTypeIcon(false);
    setShowHubleadIcon(false);
    setShowCraetedAtIcon(false);
    setShowLastEditedIcon(false);
    setShowTypeIcon(false);
    if (text.length >= 1) {
      setPage(1);
      searchApprovedTableData(1, paginationRows, text)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getApprovedTableData(page, paginationRows)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          } else {
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const onRowChange = async (value) => {
    setLoader1(true);
    if (
      Math.ceil(totalDrafts.current / value) !=
      Math.ceil(totalDrafts.current / paginationRows)
    ) {
      const oldRatio = page / Math.ceil(totalDrafts.current / paginationRows);
      setPaginationRows(value);
      const newPageNumber = Math.ceil((totalDrafts.current / value) * oldRatio);
      if (searchText) {
        searchApprovedTableData(
          newPageNumber,
          value,
          searchText,
          columnName,
          order
        )
          .then((res) => {
            setPage(newPageNumber);
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            setLoader1(false);
            console.log(error);
          });
      } else {
        getApprovedTableData(newPageNumber, value, columnName, order)
          .then((res) => {
            setPage(newPageNumber);
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setPaginationRows(value);

      if (searchText) {
        searchApprovedTableData(page, value, searchText, columnName, order)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalDrafts.current = res.total_drafts;
            }
          })
          .catch((error) => {
            setLoader1(false);
            console.log(error);
          });
      } else {
        getApprovedTableData(page, value, columnName, order)
          .then((res) => {
            // setLoader1(true)
            setData(res.data);
            totalDrafts.current = res.total_drafts;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }

    updateSidebar();
  };
  const handleChange = (event, value) => {
    setPage(value);
    updateSidebar();
    setLoader1(true);

    if (searchText) {
      searchApprovedTableData(
        value,
        paginationRows,
        searchText,
        columnName,
        order
      )
        .then((res) => {
          if (res.status) {
            setLoader1(false);
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          setLoader1(false);
          console.log(error);
        });
    } else {
      getApprovedTableData(value, paginationRows, columnName, order)
        .then((res) => {
          setData(res.data);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
            setLoader(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    updateSidebar();
    setLoader(true);
    getApprovedTableData(page, paginationRows, columnName, order).then(
      (res) => {
        setLoader(false);
        if (res.status) {
          setData(res.data);
          setLoader1(false);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      }
    );
    // GetUserNameEmail().then((res) => {
    //   console.log("dsds", res);
    //   setUserDetails(res.data);

    //   setLoader(false);
    // });
  }, []);
  useEffect(() => {
    if (draftsData) {
      let res = draftsData;
      setData(res.data);
      totalDrafts.current = res.total_drafts;
      if (res.data) {
        setLoader1(false);
      } else {
        setLoader(false);
        setLoader1(false);
        setsendReqMsg(res.message);
        setisSendReqMsg(true);
      }
      setLoader(false);
    }
  }, [draftsData]);

  const NameClick = (id, type) => {
    history.push(`/draft/${type.toLowerCase()}/${id}`, {
      from: "drafttable",
    });
  };
  const data = () => ({
    columns: [],
    rows: dataa,
  });
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };
  const sortingFunc = (ord, col) => {
    setColumnName(col);
    setOrder(ord);
    if (searchText) {
      searchApprovedTableData(page, paginationRows, searchText, col, ord)
        .then((res) => {
          if (col == "draft_state") {
            setShowStateIcon(true);
            if (ord == "ASC") {
              setstateIcon(SortUp);
            } else {
              setstateIcon(Sort);
            }
          } else {
            setShowStateIcon(false);
          }
          if (col == "request") {
            setShowOwnerIcon(true);
            if (ord == "ASC") {
              setownerIcon(SortUp);
            } else {
              setownerIcon(Sort);
            }
          } else {
            setShowOwnerIcon(false);
          }
          if (col == "video_name") {
            setShowVideoNameIcon(true);
            if (ord == "ASC") {
              setvideoNameIcon(SortUp);
            } else {
              setvideoNameIcon(Sort);
            }
          } else {
            setShowVideoNameIcon(false);
          }
          if (col == "hub_lead") {
            setShowHubleadIcon(true);
            if (ord == "ASC") {
              setHubleadIcon(SortUp);
            } else {
              setHubleadIcon(Sort);
            }
          } else {
            setShowHubleadIcon(false);
          }
          if (col == "type") {
            setShowTypeIcon(true);
            if (ord == "ASC") {
              settypeIcon(SortUp);
            } else {
              settypeIcon(Sort);
            }
          } else {
            setShowTypeIcon(false);
          }
          if (col == "created_at") {
            setShowCraetedAtIcon(true);
            if (ord == "ASC") {
              setcreatedAtIcon(SortUp);
            } else {
              setcreatedAtIcon(Sort);
            }
          } else {
            setShowCraetedAtIcon(false);
          }
          if (col == "updated_at") {
            setShowLastEditedIcon(true);
            if (ord == "ASC") {
              setlastEditedIcon(SortUp);
            } else {
              setlastEditedIcon(Sort);
            }
          } else {
            setShowLastEditedIcon(false);
          }
          if (res.status) {
            setData(res.data);
            totalDrafts.current = res.total_drafts;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getApprovedTableData(page, paginationRows, col, ord).then((res) => {
        if (col == "draft_state") {
          setShowStateIcon(true);
          if (ord == "ASC") {
            setstateIcon(SortUp);
          } else {
            setstateIcon(Sort);
          }
        } else {
          setShowStateIcon(false);
        }
        if (col == "request") {
          setShowOwnerIcon(true);
          if (ord == "ASC") {
            setownerIcon(SortUp);
          } else {
            setownerIcon(Sort);
          }
        } else {
          setShowOwnerIcon(false);
        }
        if (col == "video_name") {
          setShowVideoNameIcon(true);
          if (ord == "ASC") {
            setvideoNameIcon(SortUp);
          } else {
            setvideoNameIcon(Sort);
          }
        } else {
          setShowVideoNameIcon(false);
        }
        if (col == "hub_lead") {
          setShowHubleadIcon(true);
          if (ord == "ASC") {
            setHubleadIcon(SortUp);
          } else {
            setHubleadIcon(Sort);
          }
        } else {
          setShowHubleadIcon(false);
        }
        if (col == "type") {
          setShowTypeIcon(true);
          if (ord == "ASC") {
            settypeIcon(SortUp);
          } else {
            settypeIcon(Sort);
          }
        } else {
          setShowTypeIcon(false);
        }
        if (col == "created_at") {
          setShowCraetedAtIcon(true);
          if (ord == "ASC") {
            setcreatedAtIcon(SortUp);
          } else {
            setcreatedAtIcon(Sort);
          }
        } else {
          setShowCraetedAtIcon(false);
        }
        if (col == "updated_at") {
          setShowLastEditedIcon(true);
          if (ord == "ASC") {
            setlastEditedIcon(SortUp);
          } else {
            setlastEditedIcon(Sort);
          }
        } else {
          setShowLastEditedIcon(false);
        }
        setLoader(false);
        if (res.status) {
          setData(res.data);
          setLoader1(false);
          totalDrafts.current = res.total_drafts;
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      });
    }
  };
  const linksData = {
    columns: [
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "draft_state");
              } else if (order == "ASC") {
                sortingFunc("DESC", "draft_state");
              } else {
                sortingFunc("ASC", "draft_state");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "135px",
              cursor: "pointer",
              color: "#637381",
              fontWeight: 600,
            }}
          >
            <span>State</span>
            {showStateIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={stateIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "state",
        sort: "asc",
        width: 100,
      },
      ,
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "request");
              } else if (order == "ASC") {
                sortingFunc("DESC", "request");
              } else {
                sortingFunc("ASC", "request");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "100px",
            }}
          >
            <span>Owner</span>
            {showOwnerIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={ownerIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "request",
        sort: "asc",
        width: 10,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "video_name");
              } else if (order == "ASC") {
                sortingFunc("DESC", "video_name");
              } else {
                sortingFunc("ASC", "video_name");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "150px",
            }}
          >
            <span>Video Name</span>
            {showVideoNameIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={videoNameIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "name",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "hub_lead");
              } else if (order == "ASC") {
                sortingFunc("DESC", "hub_lead");
              } else {
                sortingFunc("ASC", "hub_lead");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "140px",
            }}
          >
            <span>Hub Lead</span>
            {showHubleadIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={HubleadIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "hubLead",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "type");
              } else if (order == "ASC") {
                sortingFunc("DESC", "type");
              } else {
                sortingFunc("ASC", "type");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "100px",
            }}
          >
            <span>Type</span>
            {showTypeIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={typeIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "type",
        sort: "asc",
        width: 180,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "created_at");
              } else if (order == "ASC") {
                sortingFunc("DESC", "created_at");
              } else {
                sortingFunc("ASC", "created_at");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "100px",
            }}
          >
            <span>Created</span>
            {showCraetedAtIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={createdAtIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "created_date",
        sort: "asc",
        width: 100,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "updated_at");
              } else if (order == "ASC") {
                sortingFunc("DESC", "updated_at");
              } else {
                sortingFunc("ASC", "updated_at");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "100px",
            }}
          >
            <span>Last</span>
            <span>&nbsp;</span>
            <span>Edited</span>
            {showLastEditedIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={lastEditedIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "lastEditor",
        sort: "asc",
        width: 100,
      },
      {
        label: "",
        field: "action",
      },
    ],
    rows: [
      ...data().rows.map((row, order) => ({
        clickEvent: () => NameClick(row.id, row.type),
        state: (
          <span
            searchvalue={`${row.draft_state && row.draft_state.toLowerCase()}`}
            className={
              row.draft_state == "Ready For Training" || "error"
                ? "state_purple"
                : "state_blue"
            }
          >
            {row.draft_state && row.draft_state == "error"
              ? "Ready For Training"
              : row.draft_state}
          </span>
        ),
        request:
          row.request && row.request.length > 18
            ? `${row.request.slice(0, 18)}...`
            : row.request,
        name:
          row.video_name && row.video_name.length > 14 ? (
            <Tooltip
              arrow
              placement="top"
              title={<Typography fontSize={14}>{row.video_name}</Typography>}
            >
              <span
                style={{ fontWeight: 600, fontSize: 14 }}
                searchvalue={`${
                  row.video_name && row.video_name.toLowerCase()
                }`}
              >
                {row.video_name.slice(0, 16)}...
              </span>
            </Tooltip>
          ) : (
            <span
              style={{ fontWeight: 600, fontSize: 14 }}
              searchvalue={`${row.video_name && row.video_name.toLowerCase()}`}
            >
              {row.video_name}
            </span>
          ),
        type: `${row.type}`,
        hubLead:
          row.hub_lead && row.hub_lead.length > 1 ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                row.hub_lead &&
                row.hub_lead.map((name) => (
                  <Typography fontSize={14}>{name}</Typography>
                ))
              }
            >
              <div style={{ display: "flex", maxWidth: "fit-content" }}>
                {row.hub_lead && row.hub_lead[0].length > 12 ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{`${row.hub_lead[0].slice(0, 12)}...`}</div>
                    <div className=" ms-2 hublead-count">
                      {`+${row.hub_lead.length - 1}`}
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{row.hub_lead && row.hub_lead[0]}</div>
                    <div className=" ms-2 hublead-count">
                      {`+${row.hub_lead.length - 1}`}
                    </div>
                  </div>
                )}
              </div>
            </Tooltip>
          ) : row.hub_lead && row.hub_lead[0].length > 12 ? (
            <Tooltip
              arrow
              placement="bottom"
              title={row.hub_lead.map((name) => (
                <Typography fontSize={14}>{name}</Typography>
              ))}
            >
              <div style={{ maxWidth: "fit-content" }}>
                {`${row.hub_lead[0].slice(0, 12)}...`}
              </div>
            </Tooltip>
          ) : (
            row.hub_lead
          ),
        created_date: `${row.created_date}`,
        lastEditor: `${row.updated_date}`,
        action: (
          <div onClick={(e) => e.stopPropagation()}>
            <a
              style={{ color: "#0A6ED1", cursor: "pointer" }}
              onClick={() => ActionClick(row.id)}
            >
              <ApprovedActionsDropdown rowId={row.id} />
            </a>
          </div>
        ),
      })),
    ],
  };

  return (
    <div>
      {loader1 && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Tori Trained</h3>
        <div style={{ height: 36, marginTop: 8 }}>{/* Invite user */}</div>
      </div>
      {/* {loader1 ? (
        <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div className="box_s">
        {/* search bar */}
        <div
          style={{
            paddingRight: 25,
            paddingBlock: 20,
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Input
            className="table-search"
            style={{ width: "200px", height: "35px", borderRadius: 8 }}
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
            onKeyUp={() => searchData(searchText)}
            placeholder="Search..."
            prefix={<FiSearch style={{ color: "#637381", fontSize: 20 }} />}
          />
        </div>
        {/* table */}
        <div>
          <MDBDataTable
            noBottomColumns={true}
            fixedHeader
            bordered={false}
            responsive
            entries={50}
            data={linksData}
            materialSearch
            className="your-custom-styles"
          />
        </div>
        {/* material pagination */}
        <div className="table-pagination">
          {/* choose rows */}
          <div className="paginationrows-dropdown">
            <label for="cars">Rows per page:</label>
            <Select
              defaultValue={paginationRows}
              onChange={(value) => onRowChange(value)}
              bordered={false}
              options={[
                {
                  value: "10",
                  label: "10",
                },
                {
                  value: "15",
                  label: "15",
                },
                {
                  value: "20",
                  label: "20",
                },
              ]}
            />
          </div>
          {/* pagination row */}
          <ThemeProvider theme={theme}>
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  fontSize: "14px !important",
                },
                ".MuiPaginationItem-root.Mui-selected": {
                  fontWeight: "600 !important",
                },
              }}
              count={Math.ceil(totalDrafts.current / paginationRows)}
              page={page}
              color={"neutral"}
              onChange={handleChange}
            />
          </ThemeProvider>
        </div>
      </div>
      {/* )} */}
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={isSendReqMsg}
        onClose={() => {
          setisSendReqMsg(false);
        }}
        modalMsg={sendReqMsg}
      />
    </div>
  );
}

export default Approved;
