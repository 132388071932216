import React, { useState } from "react";
import "../DraftTable/modals/style.css";
import DeleteDraftModal from "./modals/DeleteDraftModal";
import FeedbackShareModal from "./modals/FeedbackShareModal";
import ToriLogo from "../../assets/tori_logo.svg";
import { MovetoEditing } from "../../Services/Service";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Menu, Modal, Popover, Space } from "antd";
import { Tooltip, Typography } from "@mui/material";
import { FiMoreVertical } from "react-icons/fi";
const FeedbackActions = ({ rowId }) => {
  const history = useHistory();
  const [moveEditMsg, setMoveEditMsg] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const onClickMoveToEdit = () => {
    MovetoEditing(rowId).then((res) => {
      if (res.status) {
        // updateSidebar();
        history.push("/user/editing");
      } else {
        showModal1();
        setMoveEditMsg(res.message);
      }
    });
  };
  const menu = (
    <Menu
      items={[
        {
          label: <FeedbackShareModal id={rowId} />,
          key: "0",
        },
        {
          label: (
            <Typography onClick={onClickMoveToEdit} style={{ width: "100%", fontSize: 14 }}>
              Move to Copywriter
            </Typography>
          ),
          key: "1",
        },
        {
          label: <DeleteDraftModal id={rowId} />,
          key: "2",
        },
      ]}
    />
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <FiMoreVertical style={{ color: "black", fontSize: 18 }} />
          </Space>
        </a>
      </Dropdown>
      {/* moveEditToCopywriter Error Message Modal */}
      <Modal
        width={400}
        style={{ borderRadius: "20px" }}
        centered
        footer={[]}
        open={isModalOpen1}
        onCancel={handleCancel1}
      >
        <div
          class="modal-header"
          style={{
            borderBottom: 0,
            padding: 40,
            paddingBottom: 10,
            paddingTop: 15,
          }}
        >
          <h5
            class="modal-title"
            id="staticBackdropLabel"
            style={{
              position: "absolute",
              left: 25,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Tori says
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img width={30} height={30} src={ToriLogo} />
          <h5 style={{ marginLeft: "20px" }}>{`${moveEditMsg}`}</h5>
        </div>

        <div className="pt-3 d-flex justify-content-end">
          <button
            onClick={handleCancel1}
            style={{
              height: "34px",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
            className="btn btn-primary primaryButton"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackActions;
