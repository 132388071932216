import { Tooltip } from "@mui/material";
import { Dropdown, Menu, Space } from "antd";
// import { Button, Popover } from "antd";
import React, { useState } from "react";
import "../DraftTable/modals/style.css";
import ChangePermissionModal from "./ChangePermissionModal";
import DeleteAdminModal from "./DeleteAdminModal";
import ActivateUser from "./ActivateUser";
import EditNameModal from "./EditNameModal";
import { FiMoreVertical } from "react-icons/fi";
import ChangeRoleModal from "./ChangeRole";
const AdminMenu = ({ rowData,roles }) => {

  const menu = (
    <Menu
      items={[
        {
          label: <EditNameModal id={rowData.id} name={rowData.name} />,
          key: "0",
        },
        {
          label: <ChangePermissionModal id={rowData.id} />,
          key: "1",
        },
        {
          label: <ChangeRoleModal roles={roles} id={rowData.id} />,
          key: "2",
        },
        {
          label: <DeleteAdminModal id={rowData.id} />,
          key: "3",
        },
        rowData.status != "Active" && {
          label: (
            <ActivateUser
              textLabel={
                rowData.status == "Inactive" ? "Activate" : "Resend Invite"
              }
              id={rowData.id}
            />
          ),
          key: "4",
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <FiMoreVertical style={{ color: "black", fontSize: 18 }} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default AdminMenu;
