import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import "./ToriChat.css";
import ComparisonChatModal from "./ComparisonChatModal";
import { useState } from "react";
import { AdditionalInfoWithLinkOrQuery, ToriChatService } from "../../Service";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import DisplayModal from "../CreateDraft/Components/Modal";
import imageLogo from "../../assets/logo.png";
// import imageLogo from "../../assets/testLogonTori.png";
import plus from "../../assets/appPlus.png";
import Minus from "../../assets/minus.png";
import {
    AiOutlineReload,
    AiOutlineDislike,
    AiOutlineLike,
    AiFillLike,
    AiFillDislike,
} from "react-icons/ai";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "26px",
        width: "35%",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        overflow: "unset",
    },
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const FeedbackModal = ({
    modalIsOpen,
    closeModal,
}) => {

    const handleClose = () => {
        closeModal();
        // setHideTextArea(false);
        // setHideInput(false);
        // setLinkError(false);
        // setResponse("");
        // setErrorMessage(false);
    };

    return (
        <div>
            {/* {chatLoading && (
          <div className="overlay">
            <div className="loaderImage-container">
              <img width={150} height={150} src={LoadingSpinner} />
            </div>
          </div>
        )} */}
            <Modal
                isOpen={modalIsOpen}
                // onRequestClose={() => {
                //   closeModal();
                //   setHideTextArea(false);
                //   setHideInput(false);
                // }}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <Stack direction="row" alignItems={'center'}
                    spacing={1}>
                    <AiOutlineClose
                        onClick={handleClose}
                        style={{
                            fontSize: 18,
                            cursor: "pointer",
                            position: "absolute",
                            top: 25,
                            right: 20,
                        }}
                    />


                    <Stack
                        direction="row"
                        spacing={1}
                        marginTop={3}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <img
                            src={imageLogo}
                            alt="imageLogo"
                            style={{ width: "40px", height: "40px" }}
                        />

                        <Typography
                            variant="h5"
                            fontWeight={600}
                            fontSize={18}
                            textAlign={"center"}
                            marginTop={1}
                        >
                            Share Feedback
                        </Typography>
                    </Stack>

                </Stack>

                <Stack mt={3} mr={1} ml={1}>
                    <Typography style={{ fontSize: 18 }}>
                        TORI needs your feedback. Help make TORI better with a <AiOutlineLike /> or a <AiOutlineDislike /> on every response.

                    </Typography>
                </Stack>

                <Stack
                    mt={2}
                    w={'50%'} // Set the width to 40%
                    justifyContent="center" // Center the content horizontally
                    textAlign="center" // Center the content vertically
                    direction={'row'}
                >
                    <Button onClick={handleClose} style={{ backgroundColor: '#dd362b', color: 'white', fontSize: '13px' }}>
                        Acknowledged
                    </Button>
                </Stack>

            </Modal>
        </div>
    );
};

export default FeedbackModal;