import React, { useEffect, useState, useRef } from "react";
import ToriLogo from "../../assets/tori_logo.svg";
import Sort from "../../assets/icons-sorting.png";
import SortUp from "../../assets/icon_up.png";
import {
  InviteUser,
  GetUserData,
  GetUserRoles,
} from "../../../components/Services/Service";
import { MDBDataTable } from "mdbreact";
import "../DraftTable/Style.css";
import Modal from "react-modal";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import AdminMenu from "./AdminMenu";
import { Button, Pagination, Tooltip, Typography } from "@mui/material";
import SelectDropdown from "../../reactSelectDropdown/SelectDropdown";
import { Input, Select } from "antd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DisplayModal from "../CreateDraft/Components/Modal";
import { FiSearch } from "react-icons/fi";
import { useContext } from "react";
import {baseUrl} from '../../../environment.js'
import AdminContext from "../../../context/AdminTableContext";
function Admin(props) {
  const { getAdminTableData, draftsData, searchAdminTableData } =
    useContext(AdminContext);
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      neutral: {
        main: "#EAF1FF",
        contrastText: "#0A6ED1",
      },
    },
  });

  const [dataa, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loaderInviteUser, isloadingInvite] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modalMessage1, setModalMessage1] = useState("");
  const [errorMessage, setErrorMesssage] = useState("");
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [lastName, setLastName] = useState();
  const [role, setRole] = useState();
  const [permission, setPermission] = useState([]);
  const [paginationRows, setPaginationRows] = useState(10);
  const [page, setPage] = useState(1);
  const totalUsers = useRef();
  const selectedRowId = useRef();
  const [showTimeOurError, setshowTimeOurError] = useState(false);
  const [sendReqMsg, setsendReqMsg] = useState("");
  const [isSendReqMsg, setisSendReqMsg] = useState(false);
  const [searchText, setsearchText] = useState();
  const [columnName, setColumnName] = useState();
  const [order, setOrder] = useState();
  const [showNameIcon, setShowNameIcon] = useState(false);
  const [showEmailIcon, setShowEmailIcon] = useState(false);
  const [showRoleIcon, setShowRoleIcon] = useState(false);
  const [showPermissionIcon, setShowPermissionIcon] = useState(false);
  const [showStatusIcon, setShowStatusIcon] = useState(false);
  const [nameIcon, setNameIcon] = useState(Sort);
  const [emailIcon, setemailIcon] = useState(Sort);
  const [roleIcon, setroleIcon] = useState(Sort);
  const [permissionIcon, setpermissionIcon] = useState(Sort);
  const [statusIcon, setstatusIcon] = useState(Sort);
  const [userRoles, setRoles] = useState();
  const onRowChange = async (value) => {
    setLoader1(true);
    if (
      Math.ceil(totalUsers.current / value) !=
      Math.ceil(totalUsers.current / paginationRows)
    ) {
      const oldRatio = page / Math.ceil(totalUsers.current / paginationRows);
      setPaginationRows(value);
      const newPageNumber = Math.ceil((totalUsers.current / value) * oldRatio);
      if (searchText) {
        searchAdminTableData(
          newPageNumber,
          value,
          searchText,
          columnName,
          order
        )
          .then((res) => {
            setPage(newPageNumber);
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalUsers.current = res.total_users;
            }
          })
          .catch((error) => {
            setLoader1(false);
          });
      } else {
        getAdminTableData(newPageNumber, value, columnName, order)
          .then((res) => {
            // setLoader1(true)
            setPage(newPageNumber);
            setData(res.data);
            totalUsers.current = res.total_users;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader(false);
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    } else {
      setPaginationRows(value);
      if (searchText) {
        searchAdminTableData(page, value, searchText, columnName, order)
          .then((res) => {
            if (res.status) {
              setLoader1(false);
              setData(res.data);
              totalUsers.current = res.total_users;
            }
          })
          .catch((error) => {
            setLoader1(false);
          });
      } else {
        getAdminTableData(page, value, columnName, order)
          .then((res) => {
            // setLoader1(true)
            setData(res.data);
            totalUsers.current = res.total_users;
            if (res.data) {
              setLoader1(false);
            } else {
              setLoader1(false);
              setsendReqMsg(res.message);
              setisSendReqMsg(true);
            }
            setLoader(false);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              setshowTimeOurError(true);
            }
            setLoader1(false);
            setLoader(false);
          });
      }
    }

    updateSidebar();
  };
  const handleChange = (event, value) => {
    setPage(value);
    updateSidebar();
    setLoader1(true);
    if (searchText) {
      searchAdminTableData(value, paginationRows, searchText, columnName, order)
        .then((res) => {
          if (res.status) {
            setLoader1(false);
            setData(res.data);
            totalUsers.current = res.total_users;
          }
        })
        .catch((error) => {
          setLoader1(false);
        });
    } else {
      getAdminTableData(value, paginationRows, columnName, order)
        .then((res) => {
          setData(res.data);
          totalUsers.current = res.total_users;
          if (res.data) {
            setLoader1(false);
          } else {
            setLoader1(false);
            setsendReqMsg(res.message);
            setisSendReqMsg(true);
            setLoader(false);
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const searchData = (text) => {
    setOrder();
    setShowNameIcon(false);
    setShowEmailIcon(false);
    setShowRoleIcon(false);
    setShowPermissionIcon(false);
    setShowStatusIcon(false);
    if (text.length >= 1) {
      setPage(1);
      searchAdminTableData(1, paginationRows, text)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalUsers.current = res.total_users;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getAdminTableData(page, paginationRows)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            totalUsers.current = res.total_users;
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          } else {
            setLoader(false);
            if (res.data) {
              setLoader1(false);
            }
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoader1(false);
          setLoader(false);
        });
    }
  };
  const onChangeUser = (e) => {
    setUserName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  useEffect(() => {
    sessionStorage.removeItem("draftTable_payload");
    updateSidebar();
    setLoader(true);
    GetUserRoles().then((res) => {
      if (res.status) {
        setRoles(res.data);
      }
    });
    getAdminTableData(page, paginationRows, columnName, order)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          totalUsers.current = res.total_users;
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setLoader1(false);
        setLoader(false);
      });
  }, []);
  useEffect(() => {
    if (draftsData) {
      let res = draftsData;
      setData(res.data);
      totalUsers.current = res.total_users;
      if (res.data) {
        setLoader1(false);
      } else {
        setLoader(false);
        setLoader1(false);
        setsendReqMsg(res.message);
        setisSendReqMsg(true);
      }
      setLoader(false);
    }
  }, [draftsData]);

  function closeModal() {
    setIsOpen(false);
    setRole("");
    setErrorMesssage("");
  }
  function openModal() {
    setIsOpen(true);
  }
  const onSendInvite = () => {
    isloadingInvite(true);
    InviteUser(userName, email, lastName, role, permission)
      .then((res) => {
        if (res.status) {
          setErrorMesssage("");
          isloadingInvite(false);
          setIsOpen(false);
          setModal1(true);
          setModalMessage1(res.message);
        } else {
          isloadingInvite(false);
          setErrorMesssage(res.message);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          setshowTimeOurError(true);
        }
        setIsOpen(false);
        isloadingInvite(true);
      });
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "26px",
      width: "30%",
      borderRadius: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      overflow: "unset",
    },
  };
  const data = () => ({
    columns: [],
    rows: dataa,
  });
  const ActionClick = (id) => {
    selectedRowId.current = id;
  };
  const updateSidebar = () => {
    GetUserData().then((res) => {
      if (res.status) {
        localStorage.setItem("draftCount", JSON.stringify(res.data.drafts));
        localStorage.setItem(
          "feedbackCount",
          JSON.stringify(res.data.feedback)
        );
        localStorage.setItem("copyWriteCount", JSON.stringify(res.data.edit));
        localStorage.setItem(
          "finalReviewCount",
          JSON.stringify(res.data.teach_tori)
        );
      }
    });
  };
  const sortingFunc = (ord, col) => {
    setColumnName(col);
    setOrder(ord);
    if (searchText) {
      searchAdminTableData(page, paginationRows, searchText, col, ord)
        .then((res) => {
          if (col == "name") {
            setShowNameIcon(true);
            if (ord == "ASC") {
              setNameIcon(SortUp);
            } else {
              setNameIcon(Sort);
            }
          } else {
            setShowNameIcon(false);
          }
          if (col == "email") {
            setShowEmailIcon(true);
            if (ord == "ASC") {
              setemailIcon(SortUp);
            } else {
              setemailIcon(Sort);
            }
          } else {
            setShowEmailIcon(false);
          }
          if (col == "role") {
            setShowRoleIcon(true);
            if (ord == "ASC") {
              setroleIcon(SortUp);
            } else {
              setroleIcon(Sort);
            }
          } else {
            setShowRoleIcon(false);
          }
          if (col == "permission") {
            setShowPermissionIcon(true);
            if (ord == "ASC") {
              setpermissionIcon(SortUp);
            } else {
              setpermissionIcon(Sort);
            }
          } else {
            setShowPermissionIcon(false);
          }
          if (col == "status") {
            setShowStatusIcon(true);
            if (ord == "ASC") {
              setstatusIcon(SortUp);
            } else {
              setstatusIcon(Sort);
            }
          } else {
            setShowStatusIcon(false);
          }
          if (res.status) {
            setData(res.data);
            totalUsers.current = res.total_users;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getAdminTableData(page, paginationRows, col, ord).then((res) => {
        if (col == "name") {
          setShowNameIcon(true);
          if (ord == "ASC") {
            setNameIcon(SortUp);
          } else {
            setNameIcon(Sort);
          }
        } else {
          setShowNameIcon(false);
        }
        if (col == "email") {
          setShowEmailIcon(true);
          if (ord == "ASC") {
            setemailIcon(SortUp);
          } else {
            setemailIcon(Sort);
          }
        } else {
          setShowEmailIcon(false);
        }
        if (col == "role") {
          setShowRoleIcon(true);
          if (ord == "ASC") {
            setroleIcon(SortUp);
          } else {
            setroleIcon(Sort);
          }
        } else {
          setShowRoleIcon(false);
        }
        if (col == "permission") {
          setShowPermissionIcon(true);
          if (ord == "ASC") {
            setpermissionIcon(SortUp);
          } else {
            setpermissionIcon(Sort);
          }
        } else {
          setShowPermissionIcon(false);
        }
        if (col == "status") {
          setShowStatusIcon(true);
          if (ord == "ASC") {
            setstatusIcon(SortUp);
          } else {
            setstatusIcon(Sort);
          }
        } else {
          setShowStatusIcon(false);
        }
        setLoader(false);
        if (res.status) {
          setData(res.data);
          setLoader1(false);
          totalUsers.current = res.total_users;
          if (res.data) {
            setLoader1(false);
          }
        } else {
          setLoader(false);
          if (res.data) {
            setLoader1(false);
          }
        }
      });
    }
  };
  const linksData = {
    columns: [
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "name");
              } else if (order == "ASC") {
                sortingFunc("DESC", "name");
              } else {
                sortingFunc("ASC", "name");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "160px",
            }}
          >
            <span>Name</span>
            {showNameIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={nameIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "name",
        sort: "asc",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "email");
              } else if (order == "ASC") {
                sortingFunc("DESC", "email");
              } else {
                sortingFunc("ASC", "email");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "250px",
            }}
          >
            <span>Email</span>
            {showEmailIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={emailIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "email",
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "role");
              } else if (order == "ASC") {
                sortingFunc("DESC", "role");
              } else {
                sortingFunc("ASC", "role");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "120px",
            }}
          >
            <span>Role</span>
            {showRoleIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={roleIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "role",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "permission");
              } else if (order == "ASC") {
                sortingFunc("DESC", "permission");
              } else {
                sortingFunc("ASC", "permission");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "250px",
            }}
          >
            <span>Permission</span>
            {showPermissionIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={permissionIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "permission",
        sort: "asc",
        width: 80,
      },
      {
        label: (
          <div
            onClick={() => {
              if (order == "DESC") {
                sortingFunc("ASC", "status");
              } else if (order == "ASC") {
                sortingFunc("DESC", "status");
              } else {
                sortingFunc("ASC", "status");
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              alignItems: "center",
              color: "#637381",
              width: "120px",
            }}
          >
            <span>Status</span>
            {showStatusIcon && (
              <span>
                {" "}
                <img
                  style={{ marginLeft: "5px" }}
                  width="30vw"
                  src={statusIcon}
                />
              </span>
            )}
          </div>
        ),
        field: "status",
      },
      {
        label: (
          <div
            style={{
              width: "80px",
            }}
          ></div>
        ),
        field: "dot",
      },
    ],
    rows: [
      ...data().rows.map((row, order) => ({
        name: (
          <span searchvalue={`${row.name && row.name.toLowerCase()}`}>
            {row.name && row.name.length > 15
              ? `${row.name.slice(0, 15)}...`
              : row.name}
          </span>
        ),
        email: (
          <span searchvalue={`${row.email && row.email.toLowerCase()}`}>
            {row.email && row.email.length > 25
              ? `${row.email.slice(0, 25)}...`
              : row.email}
          </span>
        ),

        role:
          row.role && row.role.length > 18 ? (
            <Tooltip
              arrow
              placement="top"
              title={
                <Typography fontSize={14}>{row.role && row.role}</Typography>
              }
            >
              <span
                className="user-role"
                searchvalue={`${row.role && row.role.toLowerCase()}`}
              >
                {row.role && row.role.slice(0, 18)}...
              </span>
            </Tooltip>
          ) : (
            <span
              className="user-role"
              searchvalue={`${row.role && row.role.toLowerCase()}`}
            >
              {row.role && row.role.length > 18
                ? `${row.role.slice(0, 18)}...`
                : row.role}
            </span>
          ),
        permission: (
          <span
            searchvalue={`${row.permission && row.permission.toLowerCase()}`}
          >
            {row.permission && row.permission.length > 30
              ? `${row.permission.slice(0, 30)}...`
              : row.permission}
          </span>
        ),
        status: (
          <span
            searchvalue={`${row.status && row.status.toLowerCase()}`}
            className={row.status == "Active" ? "ActiveClass" : "InActiveClass"}
            style={{ textDecoration: "none", color: "#000" }}
          >
            {row.status}
          </span>
        ),
        dot: (
          <a style={{ cursor: "pointer" }} onClick={() => ActionClick(row.id)}>
            <AdminMenu roles={userRoles} rowData={row} />
          </a>
        ),
      })),
    ],
  };
  const options = [
    { value: 0, label: "Edit" },
    { value: 1, label: "Create Draft & Comment" },
    { value: 2, label: "Close Comment" },
  ];
  const optionsForRole = [
    { value: 0, label: "Admin" },
    { value: 1, label: "Editor" },
    { value: 2, label: "Hub Lead" },
    { value: 3, label: "SME" },
  ];
  // const defaultValue = [{ value: 1, label: "Comment Only & Create Draft" }]
  var multiArr = [];
  const onSelectMultiPermission = (e) => {
    var val = e;
    val.map((a) => {
      multiArr.push(a.label);
    });
    setPermission(multiArr);
  };
  const onSelectMultiRole = (e) => {
    var val = e;
    setRole(val.label);
  };
  const handleExportCsv = () => {
    const url = `${baseUrl}/users-list` // Replace this with your desired URL
    window.open(url, '_blank'); // Opens the URL in a new window or tab
  };
  return (
    <div>
      {loader1 && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div
        className="pt-3 pb-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <h3 style={{ fontWeight: "bolder" }}>Users</h3>
        <div>
        <button onClick={openModal} className="primatybutton w-inline-block">
          Invite user
        </button>
        <button onClick={handleExportCsv}  className="primatybutton w-inline-block ms-3">
          Export CSV
        </button>
        </div>
      </div>
      {/* {loader1 ? (
        <div className="loader_center">
          <img width={150} height={150} src={LoadingSpinner} />
        </div>
      ) : ( */}
      <div className="box_s">
        {/* search bar */}
        <div
          style={{
            paddingRight: 25,
            paddingBlock: 20,
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Input
            className="table-search"
            style={{ width: "200px", height: "35px", borderRadius: 8 }}
            value={searchText}
            onChange={(e) => setsearchText(e.target.value)}
            onKeyUp={() => searchData(searchText)}
            placeholder="Search..."
            prefix={<FiSearch style={{ color: "#637381", fontSize: 20 }} />}
          />
        </div>
        {/* table */}
        <div>
          <MDBDataTable
            noBottomColumns={true}
            fixedHeader
            bordered={false}
            entries={50}
            data={linksData}
            responsive
            className="your-custom-styles"
          />
        </div>
        {/* material pagination */}
        <div className="table-pagination">
          {/* choose rows */}
          <div className="paginationrows-dropdown">
            <label for="cars">Rows per page:</label>
            <Select
              defaultValue={paginationRows}
              onChange={(value) => onRowChange(value)}
              bordered={false}
              options={[
                {
                  value: "10",
                  label: "10",
                },
                {
                  value: "15",
                  label: "15",
                },
                {
                  value: "20",
                  label: "20",
                },
              ]}
            />
          </div>
          {/* pagination row */}
          <ThemeProvider theme={theme}>
            <Pagination
              sx={{
                ".MuiPaginationItem-root": {
                  fontSize: "14px !important",
                },
                ".MuiPaginationItem-root.Mui-selected": {
                  fontWeight: "600 !important",
                },
              }}
              count={Math.ceil(totalUsers.current / paginationRows)}
              page={page}
              color={"neutral"}
              onChange={handleChange}
            />
          </ThemeProvider>
        </div>
      </div>
      {/* )} */}

      {/* invite user modal */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="hide_scroll">
            <div
              class="modal-header"
              style={{
                borderBottom: 0,
                padding: 40,
                paddingBottom: 10,
                paddingTop: 15,
              }}
            >
              <h5
                class="modal-title"
                id="staticBackdropLabel"
                style={{
                  position: "absolute",
                  left: 25,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Invite User
              </h5>
            </div>
            <div style={{ padding: 20 }}>
              <div className="row p-0">
                <div className="col-6 pl-0" style={{ paddingLeft: 0 }}>
                  <div>
                    <label for="exampleInputEmail1">First Name</label>
                    <input
                      type="email"
                      onChange={onChangeUser}
                      style={{ height: 40 }}
                      class="form-control form-control-lg"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
                <div className="col-6" style={{ paddingRight: 0 }}>
                  <div>
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="email"
                      onChange={onChangeLastName}
                      style={{ height: 40 }}
                      class="form-control form-control-lg"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  onChange={onChangeEmail}
                  style={{ height: 40 }}
                  class="form-control form-control-lg"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                />
              </div>
              <div>
                <label for="exampleInputEmail1">Role</label>
                <SelectDropdown
                  onChange={onSelectMultiRole}
                  options={userRoles}
                  isOptionDisabled={() => {}}
                  placeholder="Select or begin typing"
                />
              </div>
              {role !== "Chat User" ? (
                <div>
                  <div>
                    <label for="exampleInputEmail1">Permission</label>
                    <SelectDropdown
                      onChange={onSelectMultiPermission}
                      options={options}
                      isMulti={true}
                      isOptionDisabled={() => {}}
                      placeholder="Select or begin typing"
                    />
                  </div>
                </div>
              ) : null}
              {errorMessage && (
                <div style={{ marginTop: 10, color: "red" }}>
                  {errorMessage}
                </div>
              )}
            </div>

            <div
              className="pt-3"
              style={{ float: "right", display: "flex", alignItems: "center" }}
            >
              <div style={{ marginRight: 10 }}>
                <button
                  onClick={closeModal}
                  className="primatybuttonwhite w-inline-block"
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  onClick={onSendInvite}
                  className="primatybutton w-inline-block"
                >
                  {loaderInviteUser ? (
                    <div class="spinner-border text-dark" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Send Invite"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {/* invite user error messsage modal */}
      <div>
        <Modal
          isOpen={modal1}
          onRequestClose={() => {
            setModal1(false);
          }}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div
            class="modal-header"
            style={{
              borderBottom: 0,
              padding: 40,
              paddingBottom: 10,
              paddingTop: 15,
            }}
          >
            <h5
              class="modal-title"
              id="staticBackdropLabel"
              style={{
                position: "absolute",
                left: 25,
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Tori says
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img width={30} height={30} src={ToriLogo} />
            <h5 style={{ marginLeft: "20px" }}>{`${modalMessage1}`}</h5>
          </div>

          <div className="pt-3" style={{ float: "right", display: "flex" }}>
            <button
              onClick={() => {
                setModal1(false);
              }}
              style={{
                height: "34px",
                fontWeight: "bold",
                backgroundColor: "red",
              }}
              className="btn btn-primary primaryButton"
            >
              Close
            </button>
          </div>
        </Modal>
        <DisplayModal
          open={showTimeOurError}
          onClose={() => {
            setshowTimeOurError(false);
          }}
          modalMsg={"Request Timeout Try Again."}
        />
        <DisplayModal
          open={isSendReqMsg}
          onClose={() => {
            setisSendReqMsg(false);
          }}
          modalMsg={sendReqMsg}
        />
      </div>
    </div>
  );
}

export default Admin;
