import React, { useEffect, useState } from "react";
import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Document,
  HeadingLevel,
  TextRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  ImageRun,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { fontWeight } from "@mui/system";
import { size } from "lodash";
import "./Style.css";
import Download from "../../assets/download_iocn.svg";
import { DownloadFile } from "../../Services/Service";
import DisplayModal from "../CreateDraft/Components/Modal";
import { Typography } from "@mui/material";

function HomeScreen(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [showTimeOurError, setshowTimeOurError] = useState(false);

  let dummyArray = ["sentence 1", "sentence 2", "sentence 3"];

  const generateFromUrl = async () => {
    const blob = await fetch(
      "https://raw.githubusercontent.com/dolanmiu/docx/master/demo/images/cat.jpg"
    ).then((r) => r.blob());

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph("Hello World"),
            new Paragraph({
              children: [
                new ImageRun({
                  data: blob,
                  transformation: {
                    width: 100,
                    height: 100,
                  },
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, "example.docx");
      console.log("Document created successfully");
    });
  };

  const BUTTON = async () => {
    props.autoSaved();
    const blob = await fetch(
      "https://raw.githubusercontent.com/Bill92005/images/main/Group%2024.png"
    ).then((r) => r.blob());
    setLoading(true);
    setTimeout(() => {
      DownloadFile(props.draftId)
        .then((res) => {
          const logo = new Paragraph({
            children: [
              new ImageRun({
                data: blob,
                transformation: {
                  width: 130,
                  height: 50,
                },
              }),
            ],
          });

          let demo1TextArray =
            res.data.demo_steps[0].right_demo != null
              ? res.data.demo_steps[0].right_demo.split(".")
              : null;
          let demo2TextArray =
            res.data.demo_steps[1].right_demo != null
              ? res.data.demo_steps[1].right_demo.split(".")
              : null;
          let demo3TextArray =
            res.data.demo_steps[2].right_demo != null
              ? res.data.demo_steps[2].right_demo.split(".")
              : null;
          //   let introTextArray =
          //     res.data.intro_solution != null
          //       ? res.data.intro_solution.split(".")
          //       : null;
          //   let outroTextArray =
          //     res.data.outro != null ? res.data.outro.split(".") : null;
          const demo1Array =
            demo1TextArray && demo1TextArray.filter((text) => text != "");
          const demo2Array =
            demo2TextArray && demo2TextArray.filter((text) => text != "");
          const demo3Array =
            demo3TextArray && demo3TextArray.filter((text) => text != "");

          console.log("demo3Array:::>", demo3Array);
          //   const introArray =
          //     introTextArray && introTextArray.filter((text) => text != "");
          //   const outroArray =
          //     outroTextArray && outroTextArray.filter((text) => text != "");
          //   console.log(" demo1Arrayxxxxx", demo1Array);

          const table4 = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Key Demo Information",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "D9D9D9",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Video Name",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({ text: res && res.data.video_name }),
                    ],
                    width: { size: 50, type: WidthType.PERCENTAGE },
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Product Name",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.first_product_name,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Product URL",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.first_product_url,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "2ND Product Name (if applicable)",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.second_product_name,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "2ND Product URL (if applicable)",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.second_product_url,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Industry",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.industry,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Line of Business",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.line_of_business,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Domain/RBA Process",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.domain,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Summary/Description",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.description,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Draft Owner",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.created_by,
                      }),
                    ],
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.1),
              bottom: convertInchesToTwip(0.1),
              right: convertInchesToTwip(0.1),
              left: convertInchesToTwip(0.1),
            },
          });
          const tableHeader = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    shading: {
                      fill: "D9D9D9",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Voice Over",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    shading: {
                      fill: "D9D9D9",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Visuals",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const grabberHeader = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Grabber",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "4FB81C",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const grabberTable = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.intro,
                      }),
                    ],
                    width: { size: 50, type: WidthType.PERCENTAGE },
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const introductionHeader = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Introduction",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "70AD47",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const introTable = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.intro_solution,
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit1Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Benefit #1",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "008FD3",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit1Table = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text:
                          res && res.data.demo_steps[0].right_business_outcome,
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
                width: { size: 50, type: WidthType.PERCENTAGE },
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo1Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Demo # 1",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "E35500",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo1Table = new Table({
            rows:
              demo1Array == null
                ? [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    }),
                  ]
                : demo1Array.map((text) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: text && text.trim() + ".",
                            }),
                          ],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    });
                  }),

            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit2Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Benefit #2",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "008FD3",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit2Table = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text:
                          res && res.data.demo_steps[1].right_business_outcome,
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
                width: { size: 50, type: WidthType.PERCENTAGE },
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo2Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Demo # 2",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "E35500",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo2Table = new Table({
            rows:
              demo2Array == null
                ? [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    }),
                  ]
                : demo2Array.map((text) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: text && text.trim() + ".",
                            }),
                          ],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    });
                  }),
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit3Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Benefit #3",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "008FD3",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const benifit3Table = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text:
                          res && res.data.demo_steps[2].right_business_outcome,
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
                width: { size: 50, type: WidthType.PERCENTAGE },
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo3Header = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Demo # 3",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "E35500",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const demo3Table = new Table({
            rows:
              demo3Array == null
                ? [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    }),
                  ]
                : demo3Array.map((text) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: text && text.trim() + ".",
                            }),
                          ],
                        }),
                        new TableCell({
                          children: [new Paragraph("")],
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                    });
                  }),
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const outroHeader = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Outro",
                            style: "strong",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                    shading: {
                      fill: "970A82",
                      type: ShadingType.CLEAR,
                      color: "auto",
                    },
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const outroTable = new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: res && res.data.outro,
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [new Paragraph("")],
                  }),
                ],
              }),
            ],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            margins: {
              top: convertInchesToTwip(0.08),
              bottom: convertInchesToTwip(0.08),
              right: convertInchesToTwip(0.08),
              left: convertInchesToTwip(0.08),
            },
          });
          const table6 = new Table({
            rows: dummyArray.map(
              () =>
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: res && res.data.demo_steps[0].selected_demo,
                        }),
                      ],
                    }),
                    new TableCell({
                      children: [new Paragraph("")],
                    }),
                  ],
                })
            ),
          });
          // const table5 = new Table({
          //   rows: [
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           shading: {
          //             fill: "D9D9D9",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Voice Over",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           shading: {
          //             fill: "D9D9D9",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Visuals",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Grabber",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "4FB81C",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.intro,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),
          //     // dummyArray.map((id)=>{
          //     // console.log
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Introduction",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "70AD47",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     // }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.intro_solution,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Benefit #1",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "008FD3",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.right_business_outcomes,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Demo # 1",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "E35500",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.demo_steps[0].selected_demo,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Benefit #2",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "008FD3",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.right_business_outcomes_two,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Demo # 2",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "E35500",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.demo_steps[1].selected_demo,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Benefit #3",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "008FD3",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.right_business_outcomes_three,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Demo # 3",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "E35500",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.demo_steps[2].selected_demo,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),

          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               alignment: AlignmentType.CENTER,
          //               children: [
          //                 new TextRun({
          //                   text: "Outro",
          //                   style: "strong",
          //                   bold: true,
          //                 }),
          //               ],
          //             }),
          //           ],
          //           columnSpan: 2,
          //           shading: {
          //             fill: "970A82",
          //             type: ShadingType.CLEAR,
          //             color: "auto",
          //           },
          //         }),
          //       ],
          //     }),
          //     new TableRow({
          //       children: [
          //         new TableCell({
          //           children: [
          //             new Paragraph({
          //               text: res && res.data.outro,
          //             }),
          //           ],
          //         }),
          //         new TableCell({
          //           children: [new Paragraph("")],
          //         }),
          //       ],
          //     }),
          //   ],

          //   width: {
          //     size: 100,
          //     type: WidthType.PERCENTAGE,
          //   },
          //   margins: {
          //     top: convertInchesToTwip(0.08),
          //     bottom: convertInchesToTwip(0.08),
          //     right: convertInchesToTwip(0.08),
          //     left: convertInchesToTwip(0.08),
          //   },
          // });

          const doc = new Document({
            sections: [
              {
                children: [
                  logo,
                  new Paragraph({
                    spacing: {
                      after: 300,
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "SPOTLIGHT Demo Video Script Template*",
                        style: "strong",
                        bold: true,
                        size: 26,
                      }),
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                      new TextRun({
                        rightToLeft: true,
                        text: "ID: ",
                        bold: true,
                        size: 26,
                      }),
                      new TextRun({
                        rightToLeft: true,
                        text: res && res.data.id,
                        bold: true,
                        size: 26,
                      }),
                    ],
                  }),
                  table4,
                  new Paragraph({
                    spacing: {
                      after: 300,
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "To ensure 2-minutes or less, please ensure the script section has 270 words or less.For 5 minutes or less, please use 750 words or less.",
                        style: "strong",
                        bold: false,
                        size: 22,
                      }),
                    ],
                  }),
                  new Paragraph({
                    spacing: {
                      after: 300,
                    },
                  }),
                  tableHeader,
                  grabberHeader,
                  grabberTable,
                  introductionHeader,
                  introTable,
                  benifit1Header,
                  benifit1Table,
                  demo1Header,
                  demo1Table,
                  benifit2Header,
                  benifit2Table,
                  demo2Header,
                  demo2Table,
                  benifit3Header,
                  benifit3Table,
                  demo3Header,
                  demo3Table,
                  outroHeader,
                  outroTable,
                  new Paragraph({
                    spacing: {
                      after: 300,
                    },
                  }),

                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "*These spotlight demo videos follow a benefit/demo, benefit/demo format.  This release of the script combines the highlight, deep dive and release highlight into a single template",
                        style: "strong",
                        bold: false,
                        size: 22,
                      }),
                    ],
                  }),
                ],
              },
            ],
          });

          Packer.toBlob(doc).then((blob) => {
            console.log(blob);
            saveAs(blob, res && res.data.video_name);
            console.log("Document created successfully");
          });
          setLoading(false);
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setshowTimeOurError(true);
          }
          setLoading(false);
        });
    }, 2000);
  };

  return props.isFromActionMenu ? (
    <div>
      <div
        disabled={props.isDownloadDisable}
        // className="download_button"
        onClick={BUTTON}
      >
        {loading == true ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Typography style={{ fontSize: 14, padding: "5px 16px" }}>
            Download Draft
          </Typography>
        )}
      </div>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Time out."}
      />
    </div>
  ) : (
    <div>
      <button
        disabled={props.isDownloadDisable}
        className="download_button"
        onClick={BUTTON}
      >
        {loading == true ? (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="download_btn_img">
            <div>
              <img src={Download} />
            </div>
            <div>Download</div>
          </div>
        )}
      </button>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setshowTimeOurError(false);
        }}
        modalMsg={"Request Time out."}
      />
    </div>
  );
}

export default HomeScreen;
