import React, { useState } from "react";
import threeDotsertical from "../../assets/three-dots-vertical.svg";
import "../DraftTable/modals/style.css";
import { MoveToFeefBack, MoveToFeefFinalReview } from "../../Services/Service";
import { useHistory } from "react-router-dom";
import DeleteEditingDraftModal from "./DeleteEditingDraftModal";
import { Button, Dropdown, Menu, Popover, Space } from "antd";
import { Tooltip, Typography } from "@mui/material";
import { FiMoreVertical } from "react-icons/fi";
const EditingActions = ({ rowId }) => {
  const history = useHistory();

  const MoveToForwardHandler = () => {
    console.log(rowId);
    MoveToFeefBack(rowId).then((res) => {
      if (res.status) {
        history.push("/user/feedBack");
      } else {
        alert(res.message);
      }
    });
  };

  const MoveToFinalReviewHandler = () => {
    MoveToFeefFinalReview(rowId).then((res) => {
      if (res.status) {
        history.push("/user/approved");
      } else {
        alert(res.message);
      }
    });
  };
  const menu = (
    <Menu
      items={[
        {
          label: <a onClick={MoveToForwardHandler}>Move to Feedback</a>,
          key: "0",
        },
        // {
        //   label: <Typography  style={{fontSize:14}} onClick={MoveToFinalReviewHandler}>Move to Final Review</Typography>,

        //   key: "1",
        // },
        {
          label: <DeleteEditingDraftModal id={rowId} />,
          key: "2",
        },
      ]}
    />
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <FiMoreVertical style={{ color: "black", fontSize: 18 }} />
          </Space>
        </a>
      </Dropdown>
      {/* <div style={{ width: '50px' }}>
      <Dropdown>
        {/* <Button variant="success">Split Button</Button> */}

      {/* <Dropdown.Toggle style={{ backgroundColor: 'transparent', border: 'none', color: 'black' }} className='dropdownyyyy' split variant="success" id="dropdown-split-basic" /> */}

      {/* <Dropdown.Menu >
          <Dropdown.Item href="#/action-1"><h6 onClick={MoveToForwardHandler} style={{ width: '100%', fontSize: 14 }}>Move to Feedback</h6></Dropdown.Item>
          <Dropdown.Item href="#/action-2"><h6 onClick={MoveToFinalReviewHandler} style={{ width: '100%', fontSize: 14 }}>Move to Final Review</h6></Dropdown.Item>
          <Dropdown.Item href="#/action-3"><DeleteEditingDraftModal id={rowId} /></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      {/* </div>  */}
    </>
  );
};

export default EditingActions;
