export const scrollToBottom = () => {
  setTimeout(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, 200);
};
export const scrollToTop = () => {
  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });
};

export const getTokens = () => {
 return localStorage.getItem("token");
};
