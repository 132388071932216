export const videoNameTooltip =
  "This is the final name of the video It should contain the Solution name, or if it is covering multiple solution you may name if Source to Pay in Retail.";
export const productNameTooltip =
  "Add all the solutions covered in the video and their product page from sap.com. This will improve the messaging and make me (Tori) a lot smarter.";
export const industryTooltip = "Add an industry, if applicable.";
export const lineOfBusinessTooltip =
  "Add the lines of businesses that are covered in the video.";
export const domainTooltip =
  "Please select the Reference Business Architecture Process that is covered in your video. This is another one that is making me (Tori) smarter.";
export const summaryTooltip =
  "This will end up being the description of the video once published, it also helps guide Tori on key thoughts and the ideal end state of the video. Do not worry, it doesn’t have to be perfect, copywriting will refine it as needed.";
export const businessOutcomsTooltip =
  "What is the value that the customer/company or end user will gain from the solution?";
export const roleTooltip =
  "In our videos, we give context to the demo by identifying the role of the person that does the task.  For example, As a Customer Service Representative, you…";
export const demoTooltip =
  "This should be the steps/topics that you cover in the short demo to back up the business outcome - prove it here..";
export const approvalSmeMsg =
  "Once the request has been approved by the DHL the Digital Factory will be notified and start the process of editing and preparing production.";
  export const approvalHubleadmsg=
  "The Factory has been notified of your approval.  They will create a Card in Planner, associate the script and provide you a link to upload the recordings."
export const startingPointTooltip = "Tell us how the solution is available to the end user. For Example, as a  Mobile Application/System Dashboard/Web Application/Software setup or any other."