import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { DraftSlice, ChatApi } from './Redux/Slices'
// import { ChatSlice } from './Redux/Slices/chatSession.slice'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [DraftSlice.reducerPath]: DraftSlice.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(DraftSlice.middleware, ChatApi.middleware),

})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)