import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import EditModal from "./modals/EditModal";
import FeedbackModal from "./modals/FeedbackModal";
import ShareModal from "./modals/ShareModal";
import DeleteModal from "./modals/DeleteModal";
import { Dropdown, Menu, Space } from "antd";
import MoveToFactoryModal from "./modals/MoveToFactoryModal";
import { Typography } from "@mui/material";
import DownloadSamplerData from "../Sampler/DownloadSamplerData";
import WordFile from "../../Screens/ProcessType/WordFile";
import WorldFile from "../../Screens/HomeScreen/WorldFile";
import DownloadScriptProvided from "../../Screens/ScriptProvided/DownloadScriptProvided";
import CloneDraftModal from "./modals/CloneDraftModal";

const ActionMenu = ({ setDefaultState,rowData }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const menu = (
    <Menu
      items={[
        {
          label: (
            <ShareModal
              isFromActionMenu={true}
              id={rowData.id}
              type={
                rowData.type == "Spotlight"
                  ? "spotlight"
                  : rowData.type == "Explainer"
                    ? "explainer"
                    : rowData.type == "Task"
                      ? "task"
                      : rowData?.type == "Sampler"
                        ? "sampler"
                        : rowData.is_script_provided == "1"
                          ? "scriptprovided"
                          : "process"
              }
            />
          ),
          key: "1",
        },
        {
          label: <DeleteModal id={rowData.id} />,
          key: "4",
        },
        userData.role == "Admin" &&
          rowData.infactory == "0" &&
          rowData.draft_state == "Editing" &&
          (rowData?.type == "Explainer" || rowData.type == "Task")
          ? {
            label: <MoveToFactoryModal id={rowData.id} />,
            key: "5",
          }
          : "",
        rowData.type == "Spotlight" ||
          rowData.type == "Sampler" ||
          rowData?.type == "Process" ||
          rowData.is_script_provided == "1"
          ? {
            label:
              // <Typography
              //   style={{ fontSize: 14, padding: "5px 16px" }}
              //   onClick={() => downloadDraft(rowData)}
              // >
              //   Download Draft
              // </Typography>
              rowData.type == "Spotlight" ? (
                <WorldFile
                  draftId={rowData.id}
                  autoSaved={() => { }}
                  isFromActionMenu={true}
                />
              ) : rowData?.type == "Sampler" ? (
                <DownloadSamplerData
                  isDownloadDisable={false}
                  draftId={rowData?.id}
                  autoSaved={() => { }}
                  isFromActionMenu={true}
                />
              ) : rowData.is_script_provided == "1" ? (
                <DownloadScriptProvided
                  isDownloadDisable={false}
                  isFromActionMenu={true}
                  draftId={rowData.id}
                  autoSaved={() => { }}
                />
              ) : (
                <WordFile
                  draftId={rowData.id}
                  autoSaved={() => { }}
                  isFromActionMenu={true}
                />
              ),
            key: "6",
          }
          : "",
          rowData.type == "Spotlight" ||
          rowData.type == "Sampler" ||
          rowData?.type == "Process" ||
          rowData?.type == "Task" ||
          rowData?.type == "Explainer" 
           ? {
          label: <CloneDraftModal setDefaultState={setDefaultState} rowData={rowData} />,
          key: "5",
        } : ""
      ]}
    />
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <FiMoreVertical style={{ color: "black", fontSize: 18 }} />
        </Space>
      </a>
    </Dropdown>
  );
};

export default ActionMenu;
