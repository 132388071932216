import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { Alert, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { REACT_APP_GRAMMARLY_CLIENT_ID } from "../../../environment";
import logo from "../../assets/logo.png";
import arrowIcon from "../../assets/arrowIcon.png";
import productretrievel from "../../assets/productretrievel.png";
import info from "../../assets/info.svg";
import toriImg from "../../assets/tori.svg";
import LoadingSpinner from "../../assets/spinnerAdam.gif";
import { BiSelectMultiple } from "react-icons/bi";
import { useState } from "react";
import {
  DeleteScrapedFileService,
  GetAnswerService,
  ScrapeDataService,
} from "../../Services/GptIndexServices";
import DisplayModal from "../../Screens/CreateDraft/Components/Modal";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
const ProductInfoRetrievel = () => {
  const history = useHistory();

  const [loading, setLoading] = useState();
  const [isDisableQueryField, setIsDisableQueryField] = useState(true);
  const [productLink, setProductLink] = useState();
  const [query, setQuery] = useState();
  const [fileName, setFileName] = useState();
  const [response, setResponse] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTimeOurError, setShowTimeOurError] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const ScrapeDataHandler = () => {
    const sapDomain = "https://www.sap.com";
    if (!productLink || !productLink.includes(sapDomain)) {
      if (!productLink) {
        setEmptyFieldError("Link is required");
      } else {
        setEmptyFieldError("This webpage does not exist under sap.com domain");
      }
    } else {
      setEmptyFieldError();
      setLoading(true);
      ScrapeDataService(productLink)
        .then((res) => {
          if (res.statusCode == "ready") {
            setIsDisableQueryField(false);
            setLoading(false);
            setFileName(res.filename);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setShowTimeOurError(true);
          } else {
            setShowErrorModal(true);
            setModalMessage(error);
          }
          setLoading(false);
        });
    }
  };
  const GetAnswer = () => {
    if (!query || (query && query.trim().length === 0)) {
      setEmptyFieldError("Query is required");
    } else {
      setEmptyFieldError();
      setLoading(true);
      GetAnswerService(query, fileName)
        .then((res) => {
          if (res.Result) {
            setResponse(res.Result.trim());
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setShowTimeOurError(true);
          } else {
            setShowErrorModal(true);
            setModalMessage(error);
          }
          setLoading(false);
        });
    }
  };
  const DeleteScrapedFileHandler = () => {
    if (!fileName) {
      history.goBack();
    } else {
      DeleteScrapedFileService(fileName)
        .then((res) => {
          history.goBack();
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            setShowTimeOurError(true);
          }
          setLoading(false);
        });
    }
  };
  const CopyToClipBordPress = () => {
    // setOpen(true);
    navigator.clipboard.writeText(response);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  useEffect(() => {
    if (history.location.state !== null) {
      setProductLink(history.location.state);
    }
  }, []);

  return (
    <div>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={150} height={150} src={LoadingSpinner} />
          </div>
        </div>
      )}
      {/* header */}
      <div className="header">
        <Stack
          alignItems={"center"}
          direction="row"
          justifyContent="space-between"
        >
          <img
            onClick={DeleteScrapedFileHandler}
            style={{ cursor: "pointer", height: 48, width: 48 }}
            src={logo}
          />
          <Typography style={{ fontSize: 24, fontWeight: 700 }}>
            Product Information Retrieval
          </Typography>
          <div></div>
          {/* <button
            onClick={DeleteScrapedFileHandler}
            className="close_btn"
            style={{ marginLeft: "10px" }}
          >
            Back
          </button> */}
        </Stack>
      </div>
      {/* input fields */}
      <Stack
        direction="row"
        justifyContent={"space-between"}
        className="fields-section"
      >
        <Stack spacing={3} width="50%">
          <Stack spacing={1}>
            <div className="input-label">
              <div>
                <span className="pName">Link</span>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <Typography fontSize={14}>
                      Kindly paste the valid webpage link here. This link will
                      be used to extract the webpage knowledge.
                    </Typography>
                  }
                >
                  <img
                    style={{
                      marginLeft: 5,
                      width: 20,
                      marginTop: -6,
                    }}
                    src={info}
                  />
                </Tooltip>
              </div>
            </div>
            <Stack
              width="100%"
              direction="row"
              justifyContent={"space-between"}
            >
              <GrammarlyEditorPlugin
                style={{ width: "89.4%" }}
                clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
              >
                <input
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                  className={"InputFields product-link"}
                  placeholder={
                    "Paste the product webpage link here. For example, 'https://www.sap.com/products/scm/resource-scheduling.html'"
                  }
                  type="text"
                />
                {emptyFieldError && emptyFieldError == "Link is required" ? (
                  <span style={{ color: "red" }}>Link is required</span>
                ) : emptyFieldError &&
                  emptyFieldError ==
                    "This webpage does not exist under sap.com domain" ? (
                  <span style={{ color: "red" }}>
                    This webpage does not exist under sap.com domain
                  </span>
                ) : null}
              </GrammarlyEditorPlugin>
              <div
                onClick={ScrapeDataHandler}
                className="arrow-icon"
                style={{
                  backgroundColor: "#1371dc",
                  width: 45,
                  height: 36,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                }}
              >
                <img style={{ width: 18 }} src={arrowIcon} />
              </div>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            {" "}
            <div className="input-label">
              <div>
                <span className="pName">Query</span>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <Typography fontSize={14}>
                      This is the question or query field. The system will
                      search through the extracted product knowledge against the
                      asked query.
                    </Typography>
                  }
                >
                  <img
                    style={{
                      marginLeft: 5,
                      width: 20,
                      marginTop: -6,
                    }}
                    src={info}
                  />
                </Tooltip>
              </div>
            </div>
            <Stack
              width="100%"
              direction="row"
              justifyContent={"space-between"}
            >
              <GrammarlyEditorPlugin
                style={{ width: "82%" }}
                clientId={REACT_APP_GRAMMARLY_CLIENT_ID}
              >
                <input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  disabled={isDisableQueryField}
                  className={"InputFields product-link"}
                  placeholder={
                    "Ask something about the extracted product knowledge. For Example, 'List the product capabilities'"
                  }
                  type="text"
                />
                {emptyFieldError && emptyFieldError == "Query is required" ? (
                  <span style={{ color: "red" }}>Query is required</span>
                ) : null}
              </GrammarlyEditorPlugin>

              <button
                style={{ height: 36 }}
                disabled={isDisableQueryField}
                className="primatybutton w-inline-block"
                onClick={GetAnswer}
              >
                Submit
              </button>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            {" "}
            <div className="input-label">
              <div>
                <span className="pName">Response</span>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <Typography fontSize={14}>
                      This field shows the search results against the asked
                      query.
                    </Typography>
                  }
                >
                  <img
                    style={{
                      marginLeft: 5,
                      width: 20,
                      marginTop: -6,
                    }}
                    src={info}
                  />
                </Tooltip>
              </div>
            </div>
            <GrammarlyEditorPlugin clientId={REACT_APP_GRAMMARLY_CLIENT_ID}>
              <textarea
                disabled={isDisableQueryField}
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                rows="8"
                cols="40"
                className={
                  isDisableQueryField
                    ? "response-field product-link disable-state"
                    : "response-field product-link"
                }
                placeholder="The query response will appear here..."
                type="text"
              />
            </GrammarlyEditorPlugin>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width1: "100%",
                marginBottom: 10,
              }}
            >
              {isCopied ? (
                <Typography style={{ fontSize: 16 }}>
                  <BiSelectMultiple style={{ fontSize: 18 }} /> Copied!
                </Typography>
              ) : (
                <button
                  disabled={isDisableQueryField}
                  onClick={CopyToClipBordPress}
                  className="primatybutton w-inline-block"
                >
                  Copy
                </button>
              )}
            </div>
          </Stack>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={() => setOpen(false)}
            open={open}
          >
            <Alert
              onClose={() => setOpen(false)}
              severity="info"
              sx={{ width: "250px", fontSize: 14 }}
            >
              Copied to clipboard
            </Alert>
          </Snackbar>
        </Stack>
        <div>
          <img style={{ width: 470 }} src={toriImg} />
        </div>
      </Stack>
      <DisplayModal
        open={showTimeOurError}
        onClose={() => {
          setShowTimeOurError(false);
        }}
        modalMsg={"Request Timeout Try Again."}
      />
      <DisplayModal
        open={showErrorModal}
        onClose={() => {
          setShowErrorModal(false);
        }}
        modalMsg={modalMessage}
      />
    </div>
  );
};

export default ProductInfoRetrievel;
